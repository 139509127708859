<template>
  <div>
    <v-dialog v-model="visible" width="500">
      <v-card>
        <v-card-title> Add Asset </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <h1 class="text-gray-600 font-semibold text-[11px] mb-4">
              1. Choose an asset type
            </h1>
            <v-select
              hide-details
              class="text-[13px]"
              :items="asset_types"
              label="Asset Type"
              v-model="selected_asset_type"
              dense
              outlined
            ></v-select>
          </div>

          <h1
            class="text-gray-600 font-semibold text-[11px] my-4"
            v-if="selected_asset_type"
          >
            2. Fill up asset properties
          </h1>
          <div v-if="selected_asset_type === 'After Effects (Zip)'">
            <AfterEffects @changed="handleOnChange" />
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="isBtnDisabled"
            text
            @click="handleSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AfterEffects from "@/components/tools/acg/step2/assets/AfterEffects.vue";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  data() {
    return {
      visible: false,
      asset_types: ["After Effects (Zip)"].sort(),
      selected_asset_type: null,
      asset_data: null,
    };
  },
  methods: {
    handleSubmit() {
      this.visible = false;
      this.asset_data.id = (Math.random() + 1).toString(36).substring(3);
      this.$emit("submitted", this.asset_data);
      this.asset_data = null;
      this.selected_asset_type = null;
    },
    handleOnChange(data) {
      this.asset_data = data;
    },
  },
  components: {
    AfterEffects,
  },
  computed: {
    isBtnDisabled() {
      if (this.selected_asset_type) {
        if (this.selected_asset_type === "After Effects (Zip)") {
          if (!this.asset_data) {
            return true;
          }
          for (var key in this.asset_data) {
            if (this.asset_data[key] == null) return true;
          }
        }
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
