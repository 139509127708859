<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between mb-6">
        <h1 class="font-semibold text-[18px]">Network Configuration</h1>
        <v-btn color="secondary" text @click="addNewConfiguration"
          >Add New Configuration</v-btn
        >
      </div>
      <div>
        <v-select
          hide-details
          class="text-[13px]"
          :items="platforms"
          item-text="title"
          item-value="id"
          label="Platform"
          dense
          v-model="selected_platform"
          outlined
        ></v-select>
      </div>
      <div class="mt-5">
        <v-select
          hide-details
          class="text-[13px]"
          :items="game_data"
          item-text="title"
          item-value="id"
          label="Game"
          dense
          v-model="selected_game"
          outlined
          :disabled="!selected_platform"
          :loading="gamesLoading"
        ></v-select>
      </div>
      <div v-if="selected_game && selected_platform">
        <div v-if="fetchConfigurationsLoading">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
        <div v-else class="mt-5">
          <div
            v-for="(configuration, index) in networkConfigurations"
            :key="index"
            class="border rounded-[3px] hover:bg-gray-50 p-4 mb-3"
          >
            <h1 class="font-semibold text-[12px] mb-2">
              Configuration - {{ index + 1 }}
            </h1>

            <div
              class="mt-3"
              v-if="['Facebook', 'Tiktok'].includes(selected_platform)"
            >
              <div>
                <v-text-field
                  label="Title"
                  outlined
                  v-model="configuration.title"
                  dense
                  hide-details
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-combobox
                  outlined
                  v-model="configuration.modified_adset_id"
                  clearable
                  deletable-chips
                  small-chips
                  dense
                  chips
                  :multiple="selected_platform !== 'Tiktok'"
                  hide-details
                  class="text-[13px]"
                  :label="
                    selected_platform === 'Tiktok' ? 'Adgroup ID' : 'Adset ID'
                  "
                ></v-combobox>
              </div>
              <div class="mt-3">
                <v-text-field
                  :label="
                    selected_platform === 'Tiktok'
                      ? 'Comparison Adgroup ID'
                      : 'Comparison Adset ID'
                  "
                  outlined
                  v-model="configuration.comparison_adset_id"
                  dense
                  hide-details
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  :label="
                    selected_platform === 'Tiktok'
                      ? 'TikTok Account ID'
                      : 'Facebook Account ID'
                  "
                  outlined
                  v-model="configuration.act_id"
                  hide-details
                  dense
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  label="Ad Text"
                  outlined
                  v-model="configuration.ad_text"
                  hide-details
                  dense
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  label="CTA Text"
                  outlined
                  v-model="configuration.cta_text"
                  hide-details
                  dense
                  class="text-[13px]"
                  disabled
                ></v-text-field>
              </div>
              <div class="mt-3" v-if="selected_platform === 'Tiktok'">
                <v-text-field
                  label="Identity ID"
                  outlined
                  v-model="configuration.tiktok_identity_id"
                  hide-details
                  dense
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="flex items-center justify-end mt-3">
                <div class="mr-3">
                  <v-btn
                    color="error"
                    text
                    @click="deleteSelectedNetwork(index)"
                    :loading="configuration.delete_btn_loading"
                    >Delete</v-btn
                  >
                </div>
                <div>
                  <v-btn
                    color="success"
                    @click="updateConfiguration(index)"
                    :disabled="isUpdateBtnDisabled(index)"
                    :loading="configuration.update_btn_loading"
                    >Update</v-btn
                  >
                </div>
              </div>
            </div>

            <div class="mt-3" v-if="['unity'].includes(selected_platform)">
              <div>
                <v-text-field
                  label="Title"
                  outlined
                  v-model="configuration.title"
                  dense
                  hide-details
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  outlined
                  v-model="configuration.modified_adset_id"
                  dense
                  hide-details
                  class="text-[13px]"
                  label="Test Campaign ID"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  label="Comparison Campaign ID"
                  outlined
                  v-model="configuration.comparison_adset_id"
                  dense
                  hide-details
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  label="Campaign Set ID"
                  outlined
                  v-model="configuration.extra_data.campaign_set_id"
                  hide-details
                  dense
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  label="Rule: Number of Impressions"
                  outlined
                  v-model="configuration.extra_data.number_of_impressions"
                  hide-details
                  dense
                  class="text-[13px]"
                  type="number"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  label="Rule: Number of Max Creatives"
                  outlined
                  v-model="
                    configuration.extra_data.number_of_max_creative_numbers
                  "
                  hide-details
                  dense
                  class="text-[13px]"
                  type="number"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  label="Endcard Pair Creative ID"
                  outlined
                  v-model="configuration.extra_data.endcard_id"
                  hide-details
                  dense
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="flex items-center justify-end mt-3">
                <div class="mr-3">
                  <v-btn
                    color="error"
                    text
                    @click="deleteSelectedNetwork(index)"
                    :loading="configuration.delete_btn_loading"
                    >Delete</v-btn
                  >
                </div>
                <div>
                  <v-btn
                    color="success"
                    @click="updateConfiguration(index)"
                    :disabled="isUpdateBtnDisabled(index)"
                    :loading="configuration.update_btn_loading"
                    >Update</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected_platform: null,
      platforms: [
        {
          id: "Tiktok",
          title: "TikTok",
        },
        {
          id: "Facebook",
          title: "Facebook",
        },
        {
          id: "unity",
          title: "Unity",
        },
      ],
      game_data: [],
      selected_game: null,
      gamesLoading: false,
      networkConfigurations: [],
      fetchConfigurationsLoading: false,
    };
  },
  methods: {
    isUpdateBtnDisabled(index) {
      if (this.networkConfigurations[index]) {
        const data = this.networkConfigurations[index];
        if (["Tiktok", "Facebook"].includes(this.selected_platform)) {
          if (!data.title) return true;
          else if (!data.comparison_adset_id) return true;
          else if (!data.cta_text) return true;
          else if (!data.act_id) return true;
          else if (!this.selected_game) return true;
          else if (!this.selected_platform) return true;

          if (this.selected_platform === "Tiktok") {
            if (!data.tiktok_identity_id) return true;
          }
          if (this.selected_platform === "Facebook") {
            if (!data.modified_adset_id.length) {
              return true;
            }
          }
        }

        if (["unity"].includes(this.selected_platform)) {
          if (!data.title) return true;
          if (!data.modified_adset_id) return true;
          if (!data.comparison_adset_id) return true;
          if (!data.extra_data.campaign_set_id) return true;
          if (!data.extra_data.number_of_impressions) return true;
          if (!data.extra_data.number_of_max_creative_numbers) return true;
          if (!data.extra_data.endcard_id) return true;
        }

        return false;
      }
      return true;
    },
    async updateConfiguration(index) {
      if (this.networkConfigurations[index]) {
        const data = this.networkConfigurations[index];
        try {
          // It means it is fetched already from backend so we'll need to send request to backend to update...
          var requestData = {};

          if (["Facebook", "Tiktok"].includes(this.selected_platform)) {
            requestData = {
              title: data.title,
              adset_id:
                this.selected_platform === "Facebook"
                  ? JSON.stringify(data.modified_adset_id)
                  : data.modified_adset_id,
              comparison_adset_id: data.comparison_adset_id,
              ad_text: data.ad_text,
              cta_text: data.cta_text,
              act_id: data.act_id,
              game_id: this.selected_game,
              selected_platform: this.selected_platform,
              tiktok_identity_id:
                this.selected_platform === "Facebook"
                  ? null
                  : data.tiktok_identity_id,
            };
          }

          if (["unity"].includes(this.selected_platform)) {
            requestData = {
              title: data.title,
              adset_id: data.modified_adset_id,
              comparison_adset_id: data.comparison_adset_id,
              extra_data: JSON.stringify(data.extra_data),
              game_id: this.selected_game,
              selected_platform: this.selected_platform,
            };
          }

          if (data.id) {
            requestData.network_id = data.id;
            this.update_btn_loading = true;
            const response = await this.$api.put(
              `act/network/update`,
              requestData
            );
            if (response.status === 200) {
              this.$toast.success(
                "Selected network has been successfully updated.",
                {
                  timeout: 5000,
                  position: "bottom-center",
                  icon: false,
                }
              );
            }
          } else {
            this.update_btn_loading = true;
            const response = await this.$api.post(
              `act/network/create`,
              requestData
            );
            if (response.status === 200) {
              this.$toast.success(
                "Selected network has been successfully updated.",
                {
                  timeout: 5000,
                  position: "bottom-center",
                  icon: false,
                }
              );
              this.networkConfigurations[index].id = response.data.id;
            }
          }
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.update_btn_loading = false;
        }
      }
    },
    async deleteSelectedNetwork(index) {
      if (this.networkConfigurations[index]) {
        const data = this.networkConfigurations[index];
        // It means it is fetched already from backend so we'll need to send request to backend to delete...
        if (data.id) {
          try {
            this.delete_btn_loading = true;
            const response = await this.$api.post(
              `act/network/delete?network_id=${data.id}`
            );
            if (response.status === 200) {
              this.$toast.success(
                "Selected network has been successfully deleted.",
                {
                  timeout: 5000,
                  position: "bottom-center",
                  icon: false,
                }
              );
              this.fetchConfigurations();
            }
          } catch (error) {
            this.errorHandler(error);
          } finally {
            this.delete_btn_loading = false;
          }
        } else {
          this.networkConfigurations.splice(index, 1);
        }
      }
    },
    addNewConfiguration() {
      if (["Tiktok", "Facebook"].includes(this.selected_platform)) {
        const template = {
          title: "",
          modified_adset_id: this.selected_platform === "Facebook" ? [] : "",
          comparison_adset_id: "",
          act_id: "",
          ad_text: "",
          cta_text:
            this.selected_platform === "Facebook" ? "PLAY_NOW" : "PLAY_GAME",
          delete_btn_loading: false,
          update_btn_loading: false,
        };

        if (this.selected_platform === "Tiktok") {
          template.tiktok_identity_id = "";
        }

        this.networkConfigurations.push(template);
      }

      if (["unity"].includes(this.selected_platform)) {
        const template = {
          title: "",
          modified_adset_id: "",
          comparison_adset_id: "",
          delete_btn_loading: false,
          update_btn_loading: false,
          extra_data: {
            campaign_set_id: "",
            number_of_impressions: 0,
            number_of_max_creative_numbers: 0,
            endcard_id: "",
          },
        };
        this.networkConfigurations.push(template);
      }
    },
    async fetchGames() {
      try {
        this.gamesLoading = true;
        const response = await this.$api.get(
          "games/all?only_actives=True&platform=android"
        );
        this.game_data = response.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.gamesLoading = false;
      }
    },
    async fetchConfigurations() {
      this.fetchConfigurationsLoading = true;
      try {
        const response = await this.$api.get(
          `act/network/all?type=${this.selected_platform}&selected_game=${this.selected_game}`
        );
        this.networkConfigurations = response.data.map((x) => ({
          ...x,
          delete_btn_loading: false,
          update_btn_loading: false,
          extra_data: x.extra_data ? JSON.parse(x.extra_data) : null,
        }));

        console.log(this.networkConfigurations);
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.fetchConfigurationsLoading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  mounted() {
    this.fetchGames();
  },
  watch: {
    selected_game(newValue) {
      if (newValue) {
        this.fetchConfigurations();
      }
    },
    selected_platform() {
      this.selected_game = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
