<template>
  <div>
    <div class="bg-white p-6 w-full">
      <div v-if="loading">
        <v-skeleton-loader v-bind="attrs" type="article"></v-skeleton-loader>

        <v-skeleton-loader
          v-bind="attrs"
          type="article, actions"
        ></v-skeleton-loader>
      </div>
      <div class="w-full" v-if="streamlitSessionToken">
        <iframe
          class="w-full h-[780px]"
          :src="`${computedURL}?token=${streamlitSessionToken}`"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    computedURL() {
      return process.env.VUE_APP_STREAMLIT_URL;
    },
  },
  data() {
    return {
      loading: false,
      streamlitSessionToken: null,
    };
  },
  methods: {
    getOrCreateToken() {
      this.loading = true;
      this.$api
        .post("utils/streamlit/create-token")
        .then((response) => {
          this.streamlitSessionToken = response.data.token;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getOrCreateToken();
  },
};
</script>

<style lang="scss" scoped></style>
