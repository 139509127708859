<template>
  <div>
    <v-dialog v-model="visible" persistent width="600">
      <v-card v-if="rows" :loading="loading">
        <v-card-title class="text-h5"> Summary </v-card-title>

        <v-card-text>
          <div v-for="(row, index) in computedRows" :key="index">
            {{ row.id }} - {{ row.title }}
          </div>
          <div v-if="computedRows.length > 0" class="mt-6 font-semibold">
            {{ computedRows.length }} item(s) will be deleted.
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn
              color="red darken-1"
              :disabled="loading"
              text
              @click="visible = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click="deleteCreatives"
              :loading="loading"
              text
            >
              Delete
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      result: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.result = null;
      }
    },
  },
  computed: {
    computedRows() {
      if (this.rows) {
        return this.rows.filter((x) => [1, 2].includes(x.status_id));
      }
      return [];
    },
  },
  methods: {
    deleteCreatives() {
      this.loading = true;
      const requestData = {
        creatives: this.rows.map((x) => x.id),
      };
      console.log(requestData);
      this.$api
        .post("act/general-creatives/delete-list", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("deleted");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
