var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'SurfaceResourceData[]')?_c('SurfaceResourceEditor',{attrs:{"variableValue":_vm.value,"show":_vm.show,"valueStructure":_vm.valueStructure,"maxLength":_vm.maxLength},on:{"closed":_vm.handleEditorClosed,"surfaceValueChanged":_vm.handleOnSurfaceValueChanged}}):_vm._e(),_c('div',{staticClass:"flex items-center"},[(_vm.parameterLabel === 'colors' && _vm.colorsValid)?_c('div',{staticClass:"colors mr-2",style:({
        background: `linear-gradient(${_vm.firstColor}, ${_vm.secondColor})`,
      })}):_vm._e(),(_vm.type.toLowerCase() === 'color')?_c('div',{staticClass:"colors mr-2",style:({
        background: `#${_vm.value}`,
      })}):_vm._e(),(
        _vm.type === 'int' ||
        _vm.type === 'string' ||
        _vm.type === 'int[]' ||
        _vm.type === 'float[]' ||
        _vm.type === 'float' ||
        _vm.type === 'Color[]' ||
        _vm.type.toLowerCase() === 'color'
      )?_c('v-text-field',{staticClass:"text-[13px]",attrs:{"label":_vm.parameterLabel,"outlined":"","readonly":_vm.disable_all,"dense":"","type":_vm.type === 'int' ? 'number' : 'text',"hide-details":"","disabled":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):(_vm.type === 'bool')?_c('v-checkbox',{attrs:{"hide-details":"","readonly":_vm.disable_all,"dense":"","label":_vm.parameterLabel,"disabled":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):(_vm.type === 'SurfaceResourceData[]')?_c('div',{staticClass:"h-[40px] border-gray-400 border rounded w-full flex items-center justify-center text-[12px] cursor-pointer hover:text-sky-600 hover:border-sky-600",on:{"click":_vm.showResourceEditor}},[_vm._v(" Edit Surface Data ")]):_c('v-select',{staticClass:"text-[13px]",attrs:{"hide-details":"","items":_vm.availableValues,"readonly":_vm.disable_all,"multiple":_vm.type === 'int[]',"dense":"","label":_vm.parameterLabel,"outlined":"","disabled":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }