<template>
  <div>
    <v-dialog v-model="visible" width="1400">
      <v-card>
        <v-card-title class="text-h5"> Summary </v-card-title>

        <v-card-text>
          <div
            class="summary-section"
            v-if="
              computedAddedPlacements.length ||
              computedDeletedPlacements.length ||
              computedDisabledPlacements.length ||
              computedUpdatedPlacements.length
            "
          >
            <div
              v-if="computedAddedPlacements.length"
              class="p-4 border rounded mt-3"
            >
              <div
                class="font-semibold text-green-900 p-3 rounded-[3px] bg-green-100 text-[11px]"
              >
                {{ computedAddedPlacements.length }} placements will be added.
              </div>
              <div class="mt-3">
                <v-data-table
                  :items-per-page="-1"
                  :headers="summaryHeaders"
                  :items="computedAddedPlacements"
                  sort-by="network"
                  :hide-default-footer="true"
                  dense
                >
                  <template v-slot:[`item.network`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ mappedNetworkName(item.network) }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_app_id`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_app_id ? item.ad_network_app_id : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_app_key`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_app_key ? item.ad_network_app_key : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_ad_unit_id
                          ? item.ad_network_ad_unit_id
                          : "-"
                      }}
                    </span>
                  </template>
                  <template
                    v-slot:[`item.ad_network_optional_placement_id`]="{ item }"
                  >
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_optional_placement_id
                          ? item.ad_network_optional_placement_id
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.cpm`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ item.cpm ? item.cpm : "-" }}
                    </span>
                  </template>
                  <template v-slot:[`item.countries`]="{ item }">
                    <div v-if="item.countries.length">
                      <span
                        class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                        v-for="(country, index) in item.countries"
                        :key="index"
                      >
                        {{ country }}
                      </span>
                    </div>

                    <div v-else>
                      <span class="font-semibold text-[11px]"> - </span>
                    </div>
                  </template>
                  <template v-slot:[`item.country_type`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ item.country_type ? item.country_type : "-" }}
                    </span>
                  </template>
                </v-data-table>
              </div>
            </div>

            <div
              v-if="computedDeletedPlacements.length"
              class="p-4 border rounded mt-3"
            >
              <div
                class="font-semibold text-rose-900 p-3 rounded-[3px] bg-rose-100 text-[11px]"
              >
                {{ computedDeletedPlacements.length }} placements will be
                deleted.
              </div>
              <div class="mt-3">
                <v-data-table
                  :items-per-page="-1"
                  :headers="summaryHeaders"
                  :items="computedDeletedPlacements"
                  sort-by="network"
                  :hide-default-footer="true"
                  dense
                >
                  <template v-slot:[`item.network`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ mappedNetworkName(item.network) }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_app_id`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_app_id ? item.ad_network_app_id : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_app_key`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_app_key ? item.ad_network_app_key : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_ad_unit_id
                          ? item.ad_network_ad_unit_id
                          : "-"
                      }}
                    </span>
                  </template>
                  <template
                    v-slot:[`item.ad_network_optional_placement_id`]="{ item }"
                  >
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_optional_placement_id
                          ? item.ad_network_optional_placement_id
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.cpm`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ item.cpm ? item.cpm : "-" }}
                    </span>
                  </template>
                  <template v-slot:[`item.countries`]="{ item }">
                    <div v-if="item.countries.length">
                      <span
                        class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                        v-for="(country, index) in item.countries"
                        :key="index"
                      >
                        {{ country }}
                      </span>
                    </div>

                    <div v-else>
                      <span class="font-semibold text-[11px]"> - </span>
                    </div>
                  </template>
                  <template v-slot:[`item.country_type`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ item.country_type ? item.country_type : "-" }}
                    </span>
                  </template>
                </v-data-table>
              </div>
            </div>

            <div
              v-if="computedDisabledPlacements.length"
              class="p-4 border rounded mt-3"
            >
              <div
                class="font-semibold text-yellow-900 p-3 rounded-[3px] bg-yellow-100 text-[11px]"
              >
                {{ computedDisabledPlacements.length }} placements will be
                disabled.
              </div>
              <div class="mt-3">
                <v-data-table
                  :items-per-page="-1"
                  :headers="summaryHeaders"
                  :items="computedDisabledPlacements"
                  sort-by="network"
                  :hide-default-footer="true"
                  dense
                >
                  <template v-slot:[`item.network`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ mappedNetworkName(item.network) }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_app_id`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_app_id ? item.ad_network_app_id : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_app_key`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_app_key ? item.ad_network_app_key : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_ad_unit_id
                          ? item.ad_network_ad_unit_id
                          : "-"
                      }}
                    </span>
                  </template>
                  <template
                    v-slot:[`item.ad_network_optional_placement_id`]="{ item }"
                  >
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_optional_placement_id
                          ? item.ad_network_optional_placement_id
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.cpm`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ item.cpm ? item.cpm : "-" }}
                    </span>
                  </template>
                  <template v-slot:[`item.countries`]="{ item }">
                    <div v-if="item.countries.length">
                      <span
                        class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                        v-for="(country, index) in item.countries"
                        :key="index"
                      >
                        {{ country }}
                      </span>
                    </div>

                    <div v-else>
                      <span class="font-semibold text-[11px]"> - </span>
                    </div>
                  </template>
                  <template v-slot:[`item.country_type`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ item.country_type ? item.country_type : "-" }}
                    </span>
                  </template>
                </v-data-table>
              </div>
            </div>

            <div
              v-if="computedUpdatedPlacements.length"
              class="p-4 border rounded mt-3"
            >
              <div
                class="font-semibold text-sky-900 p-3 rounded-[3px] bg-sky-100 text-[11px]"
              >
                {{ computedUpdatedPlacements.length }} placements will be
                updated.
              </div>
              <div class="mt-3">
                <v-data-table
                  :items-per-page="-1"
                  :headers="summaryHeaders"
                  :items="computedUpdatedPlacements"
                  sort-by="network"
                  :hide-default-footer="true"
                  dense
                >
                  <template v-slot:[`item.network`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ mappedNetworkName(item.network) }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_app_id`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_app_id ? item.ad_network_app_id : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_app_key`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_app_key ? item.ad_network_app_key : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_ad_unit_id
                          ? item.ad_network_ad_unit_id
                          : "-"
                      }}
                    </span>
                  </template>
                  <template
                    v-slot:[`item.ad_network_optional_placement_id`]="{ item }"
                  >
                    <span class="font-semibold text-[10px]">
                      {{
                        item.ad_network_optional_placement_id
                          ? item.ad_network_optional_placement_id
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.cpm`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ item.cpm ? item.cpm : "-" }}
                    </span>
                  </template>
                  <template v-slot:[`item.countries`]="{ item }">
                    <div v-if="item.countries.length">
                      <span
                        class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                        v-for="(country, index) in item.countries"
                        :key="index"
                      >
                        {{ country }}
                      </span>
                    </div>

                    <div v-else>
                      <span class="font-semibold text-[11px]"> - </span>
                    </div>
                  </template>
                  <template v-slot:[`item.country_type`]="{ item }">
                    <span class="font-semibold text-[10px]">
                      {{ item.country_type ? item.country_type : "-" }}
                    </span>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
          <div class="summary-section mt-5 text-center" v-else>
            There is no change in data.
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-end">
            <v-btn
              color="blue darken-1"
              text
              @click="submitPreview"
              :disabled="
                !computedAddedPlacements.length &&
                !computedDeletedPlacements.length &&
                !computedDisabledPlacements.length &&
                !computedUpdatedPlacements.length
              "
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { NETWORK_MAPS } from "@/helper/utils";

export default {
  data() {
    return {
      summaryHeaders: [
        { text: "Network", value: "network" },
        { text: "App ID", value: "ad_network_app_id" },
        { text: "App Key", value: "ad_network_app_key" },
        { text: "Ad Unit ID", value: "ad_network_ad_unit_id" },
        {
          text: "Optional Placement ID",
          value: "ad_network_optional_placement_id",
        },
        { text: "CPM", value: "cpm" },
        { text: "Countries", value: "countries", sortable: false },
        { text: "Country Type", value: "country_type" },
      ],
      visible: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.file = null;
      }
    },
  },
  computed: {
    computedAddedPlacements() {
      return this.data
        ? this.data.filter(
            (x) =>
              x.mode === "add" ||
              (x.mode === "off" && x.originalData.mode === "add")
          )
        : [];
    },
    computedUpdatedPlacements() {
      const data = Array.from(this.data);
      var returnData = [];

      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        // Check differences
        if (!["delete"].includes(element.mode)) {
          if (
            element.ad_network_app_id !== element.originalData.ad_network_app_id
          )
            returnData.push(element);
          else if (
            element.ad_network_app_key !==
            element.originalData.ad_network_app_key
          )
            returnData.push(element);
          else if (element.country_type !== element.originalData.country_type)
            returnData.push(element);
          else if (element.cpm !== element.originalData.cpm)
            returnData.push(element);
          else if (
            element.ad_network_ad_unit_id !==
            element.originalData.ad_network_ad_unit_id
          )
            returnData.push(element);
          else if (
            !_.isEqual(
              element.countries.sort().map((x) => x.toLowerCase()),
              element.originalData.countries.sort().map((x) => x.toLowerCase())
            )
          )
            returnData.push(element);
        }
      }

      return returnData;
    },
    computedDeletedPlacements() {
      return this.data ? this.data.filter((x) => x.mode === "delete") : [];
    },
    computedDisabledPlacements() {
      return this.data
        ? this.data.filter(
            (x) => x.disabled && x.mode !== "delete" && !x.originalData.disabled
          )
        : [];
    },
    computedNetworkMaps() {
      return NETWORK_MAPS;
    },
  },
  methods: {
    mappedNetworkName(network) {
      const idx = this.computedNetworkMaps.findIndex(
        (x) => x.value === network
      );
      if (idx > -1) {
        return this.computedNetworkMaps[idx].label;
      }
      return network;
    },
    submitPreview() {
      this.$emit("submitted");
    },
  },
};
</script>

<style lang="scss">
.summary-section {
  td {
    border-bottom: none !important;
  }
}
</style>
