<template>
  <div v-if="getUserProfile">
    <div class="p-6">
      <div class="p-6 bg-white rounded">
        <div v-if="getUserProfile.department.id === 100">
          <v-alert
            icon="mdi-account-alert"
            border="left"
            text
            prominent
            type="light-blue"
            class="text-[13px] font-semibold"
          >
            You are not enrolled in any tool and department.
          </v-alert>
        </div>
        <div>
          <v-alert
            icon="mdi-bullhorn"
            border="left"
            text
            prominent
            type="teal"
            class="text-[13px] font-semibold"
          >
            You can start using your tools by clicking the related link in the
            sidebar. If you can not see any tool, it means you are not placed in
            any tool.
          </v-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeView",
  computed: {
    ...mapGetters(["getUserProfile"]),
  },
};
</script>
