import CreateGameView from "@/views/tools/ccc/CreateGameView.vue";

export const ccc_router = [
  {
    path: "/ccc/create-game",
    name: "ccc-create-game",
    component: CreateGameView,
    meta: {
      title: "Create a new game",
    },
  },
];
