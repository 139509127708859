<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">Facebook Campaign Creator</h1>
    <div>
      <p class="font-semibold text-[13px] text-gray-500">
        1. Choose from Facebook apps
      </p>
      <v-autocomplete
        auto-select-first
        clearable
        placeholder="Pick a game from the list..."
        rounded
        filled
        dense
        class="text-[13px]"
        v-model="selected_game"
        :items="computed_game_data"
        item-text="name"
        return-object
      >
      </v-autocomplete>
    </div>
    <div v-if="selected_game">
      <p class="font-semibold text-[13px] text-gray-500">
        2. Connect a Gaia Game
      </p>
      <v-autocomplete
        v-model="selected_gaia_game"
        :items="gaia_game_list"
        placeholder="Pick a Gaia Game"
        item-text="title"
        return-object
        outlined
        auto-select-first
        clearable
        dense
        class="text-[12px]"
      >
      </v-autocomplete>
      <v-alert text v-if="show_similarity_error" prominent color="warning">
        <div class="text-[12px] font-semibold">
          Be careful! Connected game doesn't seem similar.
        </div>
      </v-alert>
    </div>
    <div v-if="selected_gaia_game">
      <p class="font-semibold text-[13px] text-gray-500">3. Choose video(s)</p>
      <v-file-input
        multiple
        show-size
        accept="video/mp4"
        label="Choose video(s) that will be used in advertisement..."
        class="text-[13px]"
        v-model="videos"
      ></v-file-input>
    </div>
    <div
      class="mt-4"
      v-if="videos && videos.length > 0 && computedErrors.length === 0"
    >
      <VideoSlider :videos="videos" @delete_video="handleOnDeleteVideo" />
    </div>
    <div class="flex items-center justify-end">
      <v-btn
        :disabled="!videos || videos.length === 0 || computedErrors.length > 0"
        color="primary"
        @click="showPreviewStep"
      >
        Next
      </v-btn>
    </div>

    <ErrorDialog
      :videos="videos"
      :show="regex_errors.length > 0 || dimension_errors.length > 0"
      :errors="computedErrors"
      @closed="handleErrorDialogClosed"
    ></ErrorDialog>
    <PreviewDialog
      :show="show_preview"
      @closed="show_preview = false"
      :data="selected_game"
      :videos="videos"
      :selected_gaia_game="selected_gaia_game ? selected_gaia_game.id : null"
      @approved="handleOnPreviewApprove"
    ></PreviewDialog>
    <ResultDialog
      :request_data="request_data"
      :show="show_result"
      @closed="handleResultDialogClosed"
    ></ResultDialog>
  </div>
</template>

<script>
import ErrorDialog from "@/components/tools/ccc/facebook/campaign-create-view/ErrorDialog.vue";
import PreviewDialog from "@/components/tools/ccc/facebook/campaign-create-view/PreviewDialog.vue";
import VideoSlider from "@/components/tools/ccc/facebook/campaign-create-view/VideoSlider.vue";
import ResultDialog from "@/components/tools/ccc/facebook/campaign-create-view/ResultDialog.vue";
import { mapGetters } from "vuex";
import { stringSimilarity } from "string-similarity-js";

export default {
  name: "CampaignCreateView",
  data() {
    return {
      selected_game: null,
      dimension_errors: [],
      regex_errors: [],
      videos: null,
      show_preview: false,
      show_result: false,
      request_data: null,
      gaia_game_list: [],
      selected_gaia_game: null,
      show_similarity_error: false,
    };
  },
  computed: {
    ...mapGetters(["getApps"]),
    computed_game_data() {
      if (this.getApps) {
        return this.getApps.filter((x) => {
          if ("object_store_urls" in x) {
            if ("itunes" in x.object_store_urls) {
              return true;
            }
          }
        });
      }
      return null;
    },
    computedErrors() {
      return [...this.regex_errors, ...this.dimension_errors];
    },
  },
  components: { ErrorDialog, PreviewDialog, VideoSlider, ResultDialog },
  methods: {
    handleErrorDialogClosed() {
      this.videos = null;
      this.regex_errors = [];
      this.dimension_errors = [];
    },
    setDimensionErrors() {
      if (this.videos && this.videos.length > 0) {
        for (let i = 0; i < this.videos.length; i++) {
          const file = this.videos[i];
          const url = URL.createObjectURL(file);
          const $video = document.createElement("video");
          $video.src = url;

          const pushDimensionError = (error) => {
            this.dimension_errors.push(error);
          };

          $video.addEventListener("loadedmetadata", function () {
            const ratio = 4 / 5;
            const video_ratio = this.videoWidth / this.videoHeight;
            if (video_ratio !== ratio) {
              pushDimensionError({
                detail: `<span class='font-semibold'>${file.name}</span> isimli dosya önerilen en-boy oranına sahip değil.`,
                solution: `En-boy oranı 4x5 olmalıdır. Örneğin 1080x1350...`,
              });
            }
          });
        }
      }
    },
    setRegexErros() {
      if (this.videos && this.videos.length > 0) {
        for (let i = 0; i < this.videos.length; i++) {
          const file_name = this.videos[i].name;
          const regex = new RegExp(
            /(.*_4x5_[a-zZA-Z]+[1-9].[a-zA-Z||1-9]+)$/gim
          );
          if (!regex.test(file_name)) {
            this.regex_errors.push({
              detail: `<b>${file_name}</b> dosya ismi önerilen formata uygun değil.`,
              solution: `Dosya isimleri <span class='font-semibold'>"(OyunAdı)_(Genişlik)x(Boy)_(Fail[1-9] ya da Win[1-9])"</span> formatına uygun olmalıdır. <br> <br> <span class='font-semibold'>Örneğin:</span> CarpetRoller_4x5_Win1...`,
            });
          }
        }
      }
    },
    showPreviewStep() {
      this.show_preview = true;
    },
    handleOnDeleteVideo(name) {
      this.videos = this.videos.filter((x) => x.name !== name);
    },
    handleOnPreviewApprove(data) {
      this.show_result = true;
      this.request_data = data;
    },
    handleResultDialogClosed() {
      this.show_result = false;
      this.show_preview = false;
      this.selected_game = null;
    },
    fetchGaiaGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          if (response.status === 200) {
            this.gaia_game_list = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    videos(newValue) {
      this.setDimensionErrors(newValue);
      this.setRegexErros(newValue);
    },
    selected_game() {
      this.videos = null;
      this.dimension_errors = [];
      this.regex_errors = [];
    },
    selected_gaia_game(newValue) {
      const similarity = stringSimilarity(
        newValue.title,
        this.selected_game.name
      );
      this.show_similarity_error = similarity < 0.5 ? true : false;
    },
  },
  mounted() {
    this.fetchGaiaGames();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__slot {
  label {
    font-size: 13px;
  }
}
</style>
