<template>
  <div>
    <h1 class="font-semibold text-[18px]">Server Settings</h1>
    <div>
      <div class="pt-5">
        <div class="text-gray-600 font-semibold text-[13px]">
          You can change server settings here.
        </div>
        <div>
          <v-switch
            v-model="maintenance_mode"
            label="Maintenance Mode"
            color="indigo"
            hide-details
            @click="updateMaintenanceMode"
            :loading="loading"
          ></v-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maintenance_mode: false,
      loading: false,
    };
  },
  methods: {
    updateMaintenanceMode() {
      this.loading = true;
      const requestData = {
        maintenance_mode: this.maintenance_mode,
      };
      this.$api
        .put(`admin/settings/update`, requestData)
        .then((response) => {
          this.maintenance_mode = response.data.maintenance_mode;
          this.loading = false;
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Server settings has been successfully updated.",
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    fetchMaintenanceMode() {
      this.loading = true;
      this.$api
        .get(`admin/settings`)
        .then((response) => {
          this.maintenance_mode = response.data.maintenance_mode;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.fetchMaintenanceMode();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__slot {
  input {
    font-size: 13px;
  }
}

::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

::v-deep .v-list-item__title {
  font-size: 12px;
}

::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba($color: #000, $alpha: 0.12);
}
</style>
