<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Preview Selected Creative </v-card-title>

        <v-card-text>
          <div class="grid grid-cols-12 grid-2">
            <div class="col-span-5" v-if="preview_video_url">
              <h1 class="font-semibold text-[12px] text-gray-700 my-3">
                Creative Media
              </h1>
              <video
                style="height: 600px"
                :src="preview_video_url"
                height="600px"
                controls
              ></video>
            </div>
            <div class="col-span-7" v-if="playable_url && getBackendURL">
              <h1 class="font-semibold text-[12px] text-gray-700 my-3">
                Playable Asset
              </h1>
              <iframe
                width="100%"
                height="600px"
                :src="`${getBackendURL}${playable_url}`"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    gaia_id: {
      type: String,
      default: null,
    },
    playable_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.playable_url = "";
        this.preview_video_url = "";
      }
    },
    playable_id(newValue) {
      if (newValue) {
        this.fetchPlayableUrl();
      }
    },
    gaia_id(newValue) {
      if (newValue) {
        this.fetchFacebookMedia();
      }
    },
  },
  data() {
    return {
      visible: false,
      playable_url: "",
      preview_video_url: "",
    };
  },
  methods: {
    async fetchPlayableUrl() {
      try {
        this.playable_url = "";
        if (this.playable_id) {
          const requestData = {
            unity_playable_id: this.playable_id,
          };
          const response = await this.$api.post(
            "utils/prepare/playable/unity",
            requestData
          );
          this.playable_url = response.data.url;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchFacebookMedia() {
      try {
        if (this.gaia_id) {
          const response = await this.$api.get(
            `utils/preview/media/fb?gaia_id=${this.gaia_id}`
          );

          this.preview_video_url = response.data.url;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    getBackendURL() {
      return process.env.VUE_APP_GAIA_BACKEND_URL || null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
