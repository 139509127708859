<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card v-if="errors">
        <v-card-title class="text-h5"> Error </v-card-title>

        <v-card-text>
          <div v-if="errors.length > 0">
            <div v-for="(item, index) in errors" :key="index">
              <div class="red--text text--darken-1 font-semibold mt-4">
                Problem
              </div>
              <div v-html="item.detail"></div>

              <div class="green--text text--darken-1 font-semibold mt-4">
                Solution
              </div>
              <div v-html="item.solution"></div>
              <v-divider class="mt-4"></v-divider>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="visible = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    videos: {
      type: Array,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
