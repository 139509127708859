<template>
  <div v-if="surfaceValue">
    <v-dialog v-model="visible" width="700">
      <v-card>
        <v-card-title> Edit Surface Resource </v-card-title>

        <v-card-text>
          <div
            v-for="(key, index) in Object.keys(surfaceValue)"
            :key="index"
            class="mt-3"
          >
            <h1 class="font-semibold text-[12px] text-sky-800">
              Surface - {{ key }}
            </h1>
            <div class="grid grid-cols-5 gap-2 mt-2">
              <div
                v-for="(subKey, subIndex) in Object.keys(surfaceValue[key])"
                :key="subIndex"
              >
                <div>
                  <v-text-field
                    v-if="
                      [
                        'moneySpawnCount',
                        'moneyValue',
                        'spawnChance',
                        'amount',
                      ].includes(subKey)
                    "
                    class="text-[12px]"
                    dense
                    :label="subKey"
                    outlined
                    hide-details
                    v-model="surfaceValue[key][subKey]"
                  />
                </div>
                <div>
                  <v-select
                    v-if="['moneyTypeToSpawn', 'resourceType'].includes(subKey)"
                    class="text-[12px]"
                    dense
                    :label="subKey"
                    outlined
                    :disabled="subKey === 'resourceType'"
                    :items="
                      subKey === 'moneyTypeToSpawn'
                        ? valueStructure['MoneyTypeEnum'][
                            'variablePossibilities'
                          ]
                        : [
                            'Dirt1',
                            'Dirt2',
                            'Dirt3',
                            'Dirt4',
                            'SmallGoldOre',
                            'SmallDiamondOre',
                            'SmallAmberOre',
                            'SmallAmethystOre',
                            'SmallEmeraldOre',
                            'SmallRubyOre',
                            'Gold',
                            'Diamond',
                            'Amber',
                            'Amethyst',
                            'Emerald',
                            'Ruby',
                            'Dummy',
                            'SmallStone',
                          ]
                    "
                    hide-details
                    v-model="surfaceValue[key][subKey]"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="visible = false"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    variableValue: {
      type: Object,
      default: () => {},
    },
    valueStructure: {
      default: null,
    },
    maxLength: {
      default: null,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    variableValue: {
      handler(newValue) {
        this.surfaceValue = newValue;
      },
      deep: true,
      immediate: true,
    },
    surfaceValue: {
      handler(newValue) {
        this.$emit("surfaceValueChanged", newValue);
      },
      deep: true,
    },
  },
  data() {
    return {
      visible: false,
      surfaceValue: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
