<template>
  <div>
    <div class="bg-white p-6 w-full">
      <h1 class="font-semibold text-[18px] pb-6">Max Waterfall Operator</h1>
      <div class="mb-5">
        <h1 class="font-semibold text-[11px] mb-3">Choose operation type</h1>
        <v-radio-group
          style="font-size: 12px"
          v-model="operation_purpose"
          row
          dense
          hide-details
        >
          <v-radio label="Creating A/B Test" value="creating-ab-test"></v-radio>
          <v-radio label="Update Waterfall" value="update-waterfall"></v-radio>
        </v-radio-group>
      </div>
      <div>
        <div>
          <v-file-input
            show-size
            accept=".xlsx"
            label="Upload Excel"
            class="text-[13px]"
            outlined
            dense
            hide-details
            v-model="excelFile"
            :loading="adUnitsLoading"
            :disabled="adUnitsLoading"
          ></v-file-input>
        </div>

        <div v-if="skeletonLoading">
          <div>
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </div>
        </div>

        <div
          class="mt-5"
          v-if="excelFile && Object.keys(excelData).length && !skeletonLoading"
        >
          <h1 class="mb-3 font-semibold text-[13px]">Summary</h1>
          <div>
            <v-tabs v-model="selectedTab">
              <v-tab
                v-for="(key, keyIndex) in Object.keys(excelData).filter(
                  (x) => x !== 'info'
                )"
                :key="keyIndex"
                >{{ key }}</v-tab
              >
            </v-tabs>
            <v-tabs-items v-model="selectedTab">
              <v-tab-item
                v-for="key in Object.keys(excelData).filter(
                  (x) => x !== 'info'
                )"
                :key="key"
              >
                <div class="p-3">
                  <v-checkbox
                    v-model="showAllInSingleTable"
                    dense
                    hide-details
                    :label="`Show all changes in a single table. (Not working yet)`"
                    disabled
                  ></v-checkbox>
                </div>
                <div
                  class="summary-section"
                  v-if="
                    !showAllInSingleTable &&
                    (computedAddedPlacements(excelData[key].data).length ||
                      computedDeletedPlacements(excelData[key].data).length ||
                      computedDisabledPlacements(excelData[key].data).length ||
                      computedUpdatedPlacements(excelData[key].data).length)
                  "
                >
                  <div
                    v-if="computedAddedPlacements(excelData[key].data).length"
                    class="p-4 border rounded mt-3"
                  >
                    <div
                      class="font-semibold text-green-900 p-3 rounded-[3px] bg-green-100 text-[11px]"
                    >
                      {{ computedAddedPlacements(excelData[key].data).length }}
                      placements will be added.
                    </div>
                    <div class="mt-3">
                      <v-data-table
                        :items-per-page="-1"
                        :headers="summaryHeaders"
                        :items="computedAddedPlacements(excelData[key].data)"
                        sort-by="network"
                        :hide-default-footer="true"
                        dense
                      >
                        <template v-slot:[`item.network`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ mappedNetworkName(item.network) }}
                          </span>
                        </template>
                        <template v-slot:[`item.ad_network_app_id`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_app_id
                                ? item.ad_network_app_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.ad_network_app_key`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_app_key
                                ? item.ad_network_app_key
                                : "-"
                            }}
                          </span>
                        </template>
                        <template
                          v-slot:[`item.ad_network_ad_unit_id`]="{ item }"
                        >
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_ad_unit_id
                                ? item.ad_network_ad_unit_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template
                          v-slot:[`item.ad_network_optional_placement_id`]="{
                            item,
                          }"
                        >
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_optional_placement_id
                                ? item.ad_network_optional_placement_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.cpm`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ item.cpm ? item.cpm : "-" }}
                          </span>
                        </template>
                        <template v-slot:[`item.countries`]="{ item }">
                          <div v-if="item.countries.length">
                            <span
                              class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                              v-for="(country, index) in item.countries"
                              :key="index"
                            >
                              {{ country }}
                            </span>
                          </div>

                          <div v-else>
                            <span class="font-semibold text-[11px]"> - </span>
                          </div>
                        </template>
                        <template v-slot:[`item.country_type`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ item.country_type ? item.country_type : "-" }}
                          </span>
                        </template>
                      </v-data-table>
                    </div>
                  </div>

                  <div
                    v-if="computedDeletedPlacements(excelData[key].data).length"
                    class="p-4 border rounded mt-3"
                  >
                    <div
                      class="font-semibold text-rose-900 p-3 rounded-[3px] bg-rose-100 text-[11px]"
                    >
                      {{
                        computedDeletedPlacements(excelData[key].data).length
                      }}
                      placements will be deleted.
                    </div>
                    <div class="mt-3">
                      <v-data-table
                        :items-per-page="-1"
                        :headers="summaryHeaders"
                        :items="computedDeletedPlacements(excelData[key].data)"
                        sort-by="network"
                        :hide-default-footer="true"
                        dense
                      >
                        <template v-slot:[`item.network`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ mappedNetworkName(item.network) }}
                          </span>
                        </template>
                        <template v-slot:[`item.ad_network_app_id`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_app_id
                                ? item.ad_network_app_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.ad_network_app_key`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_app_key
                                ? item.ad_network_app_key
                                : "-"
                            }}
                          </span>
                        </template>
                        <template
                          v-slot:[`item.ad_network_ad_unit_id`]="{ item }"
                        >
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_ad_unit_id
                                ? item.ad_network_ad_unit_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template
                          v-slot:[`item.ad_network_optional_placement_id`]="{
                            item,
                          }"
                        >
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_optional_placement_id
                                ? item.ad_network_optional_placement_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.cpm`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ item.cpm ? item.cpm : "-" }}
                          </span>
                        </template>
                        <template v-slot:[`item.countries`]="{ item }">
                          <div v-if="item.countries.length">
                            <span
                              class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                              v-for="(country, index) in item.countries"
                              :key="index"
                            >
                              {{ country }}
                            </span>
                          </div>

                          <div v-else>
                            <span class="font-semibold text-[11px]"> - </span>
                          </div>
                        </template>
                        <template v-slot:[`item.country_type`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ item.country_type ? item.country_type : "-" }}
                          </span>
                        </template>
                      </v-data-table>
                    </div>
                  </div>

                  <div
                    v-if="
                      computedDisabledPlacements(excelData[key].data).length
                    "
                    class="p-4 border rounded mt-3"
                  >
                    <div
                      class="font-semibold text-yellow-900 p-3 rounded-[3px] bg-yellow-100 text-[11px]"
                    >
                      {{
                        computedDisabledPlacements(excelData[key].data).length
                      }}
                      placements will be disabled.
                    </div>
                    <div class="mt-3">
                      <v-data-table
                        :items-per-page="-1"
                        :headers="summaryHeaders"
                        :items="computedDisabledPlacements(excelData[key].data)"
                        sort-by="network"
                        :hide-default-footer="true"
                        dense
                      >
                        <template v-slot:[`item.network`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ mappedNetworkName(item.network) }}
                          </span>
                        </template>
                        <template v-slot:[`item.ad_network_app_id`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_app_id
                                ? item.ad_network_app_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.ad_network_app_key`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_app_key
                                ? item.ad_network_app_key
                                : "-"
                            }}
                          </span>
                        </template>
                        <template
                          v-slot:[`item.ad_network_ad_unit_id`]="{ item }"
                        >
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_ad_unit_id
                                ? item.ad_network_ad_unit_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template
                          v-slot:[`item.ad_network_optional_placement_id`]="{
                            item,
                          }"
                        >
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_optional_placement_id
                                ? item.ad_network_optional_placement_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.cpm`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ item.cpm ? item.cpm : "-" }}
                          </span>
                        </template>
                        <template v-slot:[`item.countries`]="{ item }">
                          <div v-if="item.countries.length">
                            <span
                              class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                              v-for="(country, index) in item.countries"
                              :key="index"
                            >
                              {{ country }}
                            </span>
                          </div>

                          <div v-else>
                            <span class="font-semibold text-[11px]"> - </span>
                          </div>
                        </template>
                        <template v-slot:[`item.country_type`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ item.country_type ? item.country_type : "-" }}
                          </span>
                        </template>
                      </v-data-table>
                    </div>
                  </div>

                  <div
                    v-if="computedUpdatedPlacements(excelData[key].data).length"
                    class="p-4 border rounded mt-3"
                  >
                    <div
                      class="font-semibold text-sky-900 p-3 rounded-[3px] bg-sky-100 text-[11px]"
                    >
                      {{
                        computedUpdatedPlacements(excelData[key].data).length
                      }}
                      placements will be updated.
                    </div>
                    <div class="mt-3">
                      <v-data-table
                        :items-per-page="-1"
                        :headers="summaryHeaders"
                        :items="computedUpdatedPlacements(excelData[key].data)"
                        sort-by="network"
                        :hide-default-footer="true"
                        dense
                      >
                        <template v-slot:[`item.network`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ mappedNetworkName(item.network) }}
                          </span>
                        </template>
                        <template v-slot:[`item.ad_network_app_id`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_app_id
                                ? item.ad_network_app_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.ad_network_app_key`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_app_key
                                ? item.ad_network_app_key
                                : "-"
                            }}
                          </span>
                        </template>
                        <template
                          v-slot:[`item.ad_network_ad_unit_id`]="{ item }"
                        >
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_ad_unit_id
                                ? item.ad_network_ad_unit_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template
                          v-slot:[`item.ad_network_optional_placement_id`]="{
                            item,
                          }"
                        >
                          <span class="font-semibold text-[10px]">
                            {{
                              item.ad_network_optional_placement_id
                                ? item.ad_network_optional_placement_id
                                : "-"
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.cpm`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ item.cpm ? item.cpm : "-" }}
                          </span>
                        </template>
                        <template v-slot:[`item.countries`]="{ item }">
                          <div v-if="item.countries.length">
                            <span
                              class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                              v-for="(country, index) in item.countries"
                              :key="index"
                            >
                              {{ country }}
                            </span>
                          </div>

                          <div v-else>
                            <span class="font-semibold text-[11px]"> - </span>
                          </div>
                        </template>
                        <template v-slot:[`item.country_type`]="{ item }">
                          <span class="font-semibold text-[10px]">
                            {{ item.country_type ? item.country_type : "-" }}
                          </span>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>

          <div class="mt-5 flex items-center justify-end">
            <v-btn
              :ripple="false"
              color="primary"
              :loading="submitBtnLoading"
              depressed
              @click="handleSubmit"
              :disabled="isSubmitBtnDisabled"
            >
              Submit
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      networks: [],
      adUnitsLoading: false,
      operation_purpose: "creating-ab-test",
      selectedTab: null,
      excelData: {},
      excelFile: null,
      loading: false,
      games: [],
      loadingGames: false,
      selectedGame: null,
      skeletonLoading: false,
      summaryHeaders: [
        { text: "Network", value: "network" },
        { text: "App ID", value: "ad_network_app_id" },
        { text: "App Key", value: "ad_network_app_key" },
        { text: "Ad Unit ID", value: "ad_network_ad_unit_id" },
        {
          text: "Optional Placement ID",
          value: "ad_network_optional_placement_id",
        },
        { text: "CPM", value: "cpm" },
        { text: "Countries", value: "countries", sortable: false },
        { text: "Country Type", value: "country_type" },
      ],
      hasError: false,
      showAllInSingleTable: false,
      submitBtnLoading: false,
    };
  },
  methods: {
    async fetchNetworks() {
      try {
        const response = await this.$api.get("umai-networks/all");
        this.networks = response.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    hasDuplicateNetworkAndCpm(data, sheetName) {
      const seen = {};

      for (const entry of data) {
        const key = entry.network + "_" + entry.cpm;

        if (seen[key]) {
          // Duplicate found
          this.$toast.error(
            `${sheetName} - ${entry.network} üzerinde aynı CPM (${entry.cpm}) ile birden fazla placement mevcut.`,
            {
              timeout: 0,
              position: "bottom-center",
              icon: false,
            }
          );
          return true;
        }

        seen[key] = true;
      }

      // No duplicate found
      return false;
    },
    computedLogs(excelSheetData) {
      var data = JSON.parse(JSON.stringify(excelSheetData));

      if (data.length) {
        var logs = [];

        for (let i = 0; i < data.length; i++) {
          const placement = data[i];

          // Find added placements.
          if (placement.mode === "add") {
            logs.push(
              `New placement added. Network: ${placement.network}, ID: ${
                placement.ad_network_ad_unit_id
              }, CPM: ${placement.cpm}, Countries: ${JSON.stringify(
                placement.countries
              )}, Country Type: ${placement.country_type}`
            );
          }

          // Find deleted placements.
          if (
            placement.mode === "delete" &&
            placement.originalData.mode !== "add"
          ) {
            logs.push(
              `Placement (${placement.originalData.ad_network_ad_unit_id}) (${placement.originalData.network}) marked for deletion.`
            );
          }

          // Find off placements.
          // if (
          //   placement.mode === "off" &&
          //   placement.originalData.mode !== "off"
          // ) {
          //   logs.push(
          //     `Placement (${placement.originalData.ad_network_ad_unit_id}) (${placement.originalData.network}) marked for deletion.`
          //   );
          // }

          // Find added but disabled placements.
          if (
            placement.mode === "off" &&
            placement.originalData.mode === "add"
          ) {
            logs.push(
              `New placement added. Network: ${placement.network}, ID: ${
                placement.ad_network_ad_unit_id
              }, CPM: ${placement.cpm}, Countries: ${JSON.stringify(
                placement.countries
              )}, Country Type: ${placement.country_type}`
            );
          }

          // Find updated placements
          if (
            placement.ad_network_app_id !==
            placement.originalData.ad_network_app_id
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) app id changed from ${placement.originalData.ad_network_app_id} to ${placement.ad_network_app_id}`
            );
          if (
            placement.ad_network_app_key !==
            placement.originalData.ad_network_app_key
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) app key changed from ${placement.originalData.ad_network_app_key} to ${placement.ad_network_app_key}`
            );
          if (placement.country_type !== placement.originalData.country_type)
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) country type changed from ${placement.originalData.country_type} to ${placement.country_type}`
            );
          if (placement.cpm !== placement.originalData.cpm)
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) CPM changed from ${placement.originalData.cpm} to ${placement.cpm}`
            );
          if (
            placement.ad_network_ad_unit_id !==
            placement.originalData.ad_network_ad_unit_id
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) ID changed from ${placement.originalData.ad_network_ad_unit_id} to ${placement.ad_network_ad_unit_id}`
            );
          if (
            !_.isEqual(
              placement.countries.sort().map((x) => x.toLowerCase()),
              placement.originalData.countries
                .sort()
                .map((x) => x.toLowerCase())
            )
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${
                placement.network
              }) countries changed from ${placement.originalData.countries.join()} to ${placement.countries.join()}`
            );
        }

        return logs;
      }
      return [];
    },
    computedDefaultDisabledNetworks(networkSettings) {
      var networks = [];
      networkSettings.ad_network_settings.forEach((item) => {
        const network = Object.keys(item)[0];
        if (item[network].disabled) {
          networks.push(network);
        }
      });

      return networks;
    },
    async handleSubmit() {
      const keys = Object.keys(this.excelData);
      if (this.operation_purpose === "creating-ab-test") {
        if (!this.excelData["info"]) {
          console.log("no info on excel data");
          return;
        }

        const nullExperiments = this.excelData.info.filter(
          (x) => !x.experiment_name
        );
        if (nullExperiments.length) {
          console.log("no experiment name in one row");
          return;
        }
      }

      var requestData = [];
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key !== "info") {
          const testSheet = this.excelData.info.find(
            (x) => x.sheet_name === key
          );

          if (testSheet) {
            if (this.operation_purpose === "creating-ab-test") {
              const result = this.prepareTestRequestData(
                this.excelData[key],
                this.excelData[key]["networkSettings"],
                testSheet.experiment_name,
                testSheet.segment,
                testSheet.sheet_name
              );
              requestData.push(result);
            } else if (this.operation_purpose === "update-waterfall") {
              const result = this.prepareUpdateRequestData(
                this.excelData[key],
                this.excelData[key]["networkSettings"],
                testSheet.sheet_name,
                testSheet.segment
              );
              requestData.push(result);
            }
          }
        }
      }

      switch (this.operation_purpose) {
        case "creating-ab-test":
          await this.createAbTest(requestData);
          break;

        case "update-waterfall":
          await this.updateWaterfall(requestData);
          break;

        default:
          break;
      }
    },
    async createAbTest(requestData) {
      try {
        if (requestData && requestData.length) {
          this.submitBtnLoading = true;

          const request = await this.$api.post(
            "applovin/ad-units/umai/experiment/bulk-create",
            {
              data: requestData,
            }
          );
          if (request.status === 200) {
            for (let i = 0; i < request.data.length; i++) {
              const data = request.data[i];
              if (data.success) {
                this.$toast.success(data.message, {
                  timeout: 0,
                  position: "bottom-center",
                  icon: false,
                });
              } else {
                this.$toast.error(data.message, {
                  timeout: 0,
                  position: "bottom-center",
                  icon: false,
                });
              }
            }
          }
        } else {
          console.log("no length");
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.submitBtnLoading = false;
        this.excelFile = null;
      }
    },
    async updateWaterfall(requestData) {
      try {
        if (requestData && requestData.length) {
          this.submitBtnLoading = true;

          const request = await this.$api.post(
            "applovin/ad-units/umai/bulk-update",
            {
              data: requestData,
            }
          );
          if (request.status === 200) {
            for (let i = 0; i < request.data.length; i++) {
              const data = request.data[i];
              if (data.success) {
                this.$toast.success(data.message, {
                  timeout: 0,
                  position: "bottom-center",
                  icon: false,
                });
              } else {
                this.$toast.error(data.message, {
                  timeout: 0,
                  position: "bottom-center",
                  icon: false,
                });
              }
            }
          }
        } else {
          console.log("no length");
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.submitBtnLoading = false;
        this.excelFile = null;
      }
    },
    computedNetworkMetadata(networkSettings) {
      if (
        networkSettings &&
        networkSettings.ad_network_settings &&
        networkSettings.ad_network_settings.length
      ) {
        var modifiedData = {};

        networkSettings.ad_network_settings.forEach((item) => {
          const network = Object.keys(item)[0];
          if (!modifiedData[network]) {
            modifiedData[network] = {
              extraParameters: item[network]?.extraParameters || null,
              targets: item[network]?.targets || {},
            };
          }
        });

        return modifiedData;
      }
      return null;
    },
    computedNetworkData(adUnitDetail) {
      if (
        adUnitDetail &&
        adUnitDetail.ad_network_settings &&
        adUnitDetail.ad_network_settings.length
      ) {
        var modifiedData = [];

        adUnitDetail.ad_network_settings.forEach((item) => {
          const network = Object.keys(item)[0];
          const ad_unit_id = adUnitDetail.id;

          item[network].ad_network_ad_units.forEach((waterfall) => {
            const data = {
              network,
              ad_unit_id,
              countries: waterfall.countries?.values || [],
              country_type: waterfall.countries?.type || null,
              ad_network_ad_unit_id: waterfall.ad_network_ad_unit_id || null,
              ad_network_optional_placement_id:
                waterfall.ad_network_optional_placement_id || null,
              cpm: parseFloat(waterfall.cpm) || null,
              ad_network_app_id: item[network]?.ad_network_app_id || null,
              ad_network_app_key: item[network]?.ad_network_app_key || null,
              disabled: waterfall.disabled,
              extraParameters: waterfall.extraParameters || null,
            };
            modifiedData.push({
              ...data,
              editable: false,
              mode: "",
              originalData: JSON.parse(JSON.stringify(data)),
            });
          });
        });

        return modifiedData;
      }
      return [];
    },
    getAbTestLogs(excelSheetData) {
      if (excelSheetData && excelSheetData.length) {
        var logs = [];

        for (let i = 0; i < excelSheetData.length; i++) {
          const placement = excelSheetData[i];

          // Find added placements.
          if (placement.mode === "add") {
            logs.push(
              `New placement added. Network: ${placement.network}, ID: ${
                placement.ad_network_ad_unit_id
              }, CPM: ${placement.cpm}, Countries: ${JSON.stringify(
                placement.countries
              )}, Country Type: ${placement.country_type}`
            );
          }

          // Find added but disabled placements.
          if (
            placement.mode === "off" &&
            placement.originalData.mode === "add"
          ) {
            logs.push(
              `New placement added. Network: ${placement.network}, ID: ${
                placement.ad_network_ad_unit_id
              }, CPM: ${placement.cpm}, Countries: ${JSON.stringify(
                placement.countries
              )}, Country Type: ${placement.country_type}`
            );
          }

          // Find deleted placements.
          if (
            placement.mode === "delete" &&
            placement.originalData.mode !== "add"
          ) {
            logs.push(
              `Placement (${placement.originalData.ad_network_ad_unit_id}) (${placement.originalData.network}) marked for deletion.`
            );
          }

          // Find off placements.
          // if (
          //   placement.mode === "off" &&
          //   placement.originalData.mode !== "off"
          // ) {
          //   logs.push(
          //     `Placement (${placement.originalData.ad_network_ad_unit_id}) (${placement.originalData.network}) marked to turn off.`
          //   );
          // }

          // Find updated placements
          if (
            placement.ad_network_app_id !==
            placement.originalData.ad_network_app_id
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) app id changed from ${placement.originalData.ad_network_app_id} to ${placement.ad_network_app_id}`
            );
          if (
            placement.ad_network_app_key !==
            placement.originalData.ad_network_app_key
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) app key changed from ${placement.originalData.ad_network_app_key} to ${placement.ad_network_app_key}`
            );
          if (placement.country_type !== placement.originalData.country_type)
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) country type changed from ${placement.originalData.country_type} to ${placement.country_type}`
            );
          if (placement.cpm !== placement.originalData.cpm)
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) CPM changed from ${placement.originalData.cpm} to ${placement.cpm}`
            );
          if (
            placement.ad_network_ad_unit_id !==
            placement.originalData.ad_network_ad_unit_id
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) ID changed from ${placement.originalData.ad_network_ad_unit_id} to ${placement.ad_network_ad_unit_id}`
            );
          if (
            !_.isEqual(
              placement.countries.sort().map((x) => x.toLowerCase()),
              placement.originalData.countries
                .sort()
                .map((x) => x.toLowerCase())
            )
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${
                placement.network
              }) countries changed from ${placement.originalData.countries.join()} to ${placement.countries.join()}`
            );
        }

        return logs;
      }
      return [];
    },
    mappedNetworkName(network) {
      const idx = this.computedNetworkMaps.findIndex(
        (x) => x.value === network
      );
      if (idx > -1) {
        return this.computedNetworkMaps[idx].label;
      }
      return network;
    },
    prepareUpdateRequestData(
      excelSheetData,
      networkSettings,
      sheetName,
      segment
    ) {
      var ad_network_settings = null;

      const disabledNetworks = excelSheetData
        .filter((x) => x.operation && x.operation.toLowerCase() === "off")
        .map((x) => x.network);

      const data = this.convertDataToModifiedData(
        excelSheetData.data.filter((x) => x.mode !== "delete"),
        [
          ...new Set([
            ...disabledNetworks,
            ...this.computedDefaultDisabledNetworks(networkSettings),
          ]),
        ],
        networkSettings
      );

      ad_network_settings = data;

      if (!ad_network_settings) {
        this.$toast.error("Property ad_network_settings is empty.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return null;
      }

      var logs = this.computedLogs(excelSheetData.data);

      logs = [
        ...logs,
        ...disabledNetworks.map((x) => `Network ${x} set to disabled.`),
      ];

      const requestData = {
        id: networkSettings.id,
        name: networkSettings.name,
        platform: networkSettings.platform,
        ad_format: networkSettings.ad_format,
        package_name: networkSettings.package_name,
        disabled: networkSettings.disabled,
        ad_network_settings: JSON.stringify(ad_network_settings),
        logs: logs,
        sheetName,
        segment,
      };

      return requestData;
    },
    prepareTestRequestData(
      excelSheetData,
      networkSettings,
      testTitle,
      segment,
      sheetName
    ) {
      const temp = excelSheetData.data.filter((x) => x.mode !== "delete");
      const disabledNetworks = excelSheetData
        .filter((x) => x.operation && x.operation.toLowerCase() === "off")
        .map((x) => x.network);

      const ad_network_settings = this.convertDataToModifiedData(temp, [
        ...this.computedDefaultDisabledNetworks(networkSettings),
        ...disabledNetworks,
      ]);

      var logs = this.getAbTestLogs(excelSheetData.data);

      logs = [
        ...logs,
        ...disabledNetworks.map((x) => `Network ${x} set to disabled.`),
      ];

      const requestData = {
        id: networkSettings.id,
        title: testTitle,
        ad_network_settings: JSON.stringify(ad_network_settings),
        frequency_capping_settings: JSON.stringify(
          networkSettings?.frequency_capping_settings || []
        ),
        bid_floors: JSON.stringify(networkSettings?.bid_floors || []),
        logs: logs,
        segment: segment,
        sheetName: sheetName,
      };

      return requestData;
    },
    handleCsvSubmit(csvData, adUnitDetail) {
      const structure = [
        "operation",
        "network",
        "ad_network_app_id",
        "ad_network_app_key",
        "ad_network_ad_unit_id",
        "ad_network_optional_placement_id",
        "cpm",
        "countries",
        "country_type",
      ];

      const isStructureVerified = this.$isArrayStructureVerified(
        csvData,
        structure
      );

      if (!isStructureVerified) {
        this.$toast.error("Yüklenen CSV dosyasının formatı uyumlu değil.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        this.showCsvUpload = false;
        return;
      } else {
        this.showCsvUpload = false;
        for (let i = 0; i < csvData.length; i++) {
          const element = csvData[i];
          if (
            ["add", "remove"].includes(element.operation.toLowerCase()) &&
            !element.ad_network_ad_unit_id
          ) {
            this.$toast.error(
              `ad_network_ad_unit_id can not be empty on ${element.network} (${element.operation})`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );
            return;
          }

          csvData[i].countries = csvData[i].countries
            ? csvData[i].countries.split(",").map((x) => x.trim())
            : [];
        }

        const modifiedData = this.updateCsvData(csvData, adUnitDetail);
        return modifiedData;
      }
    },
    convertDataToModifiedData(data, disabledNetworks, networkSettings) {
      const modifiedData = [];

      // Create an object to store the mapping of network names to their corresponding data
      const networkDataMap = {};

      // Loop through the data array and group the data by network name
      data.forEach((item) => {
        const networkName = item.network;

        if (!networkDataMap[networkName]) {
          // Initialize the network data object if it doesn't exist
          networkDataMap[networkName] = {
            disabled: false,
            targets: this.computedNetworkMetadata(networkSettings)
              ? this.computedNetworkMetadata(networkSettings)[networkName]
                  ?.targets || null
              : null,
            ad_network_ad_units: [],
            extraParameters: this.computedNetworkMetadata(networkSettings)
              ? this.computedNetworkMetadata(networkSettings)[networkName]
                  ?.extraParameters || null
              : null,
          };
        }

        if (networkName === "FACEBOOK_NATIVE_BIDDING") {
          networkDataMap[networkName].placement_type = "native";
        }

        if (disabledNetworks) {
          for (let i = 0; i < disabledNetworks.length; i++) {
            const network = disabledNetworks[i];
            if (networkDataMap[network]) {
              networkDataMap[network].disabled = true;
            }
          }
        }

        // Create an ad_network_ad_unit object for each item in data
        const adUnit = {
          ad_network_ad_unit_id: item.ad_network_ad_unit_id,
          extraParameters: item.extraParameters || {},
          disabled: networkDataMap[networkName].disabled
            ? true
            : item.disabled
            ? true
            : false,
        };

        if (item.ad_network_optional_placement_id) {
          adUnit.extraParameters["ad_network_optional_placement_id"] =
            item.ad_network_optional_placement_id;
        }

        if (item.cpm) {
          adUnit.cpm = String(item.cpm);
        }

        if (item.countries) {
          adUnit.countries = {
            type: item.country_type || "INCLUDE",
            values: item.countries || [],
          };
        }

        // Add the adUnit to the network's ad_network_ad_units array
        networkDataMap[networkName].ad_network_ad_units.push(adUnit);

        // Set the ad_network_app_id if it exists
        if (item.ad_network_app_id) {
          networkDataMap[networkName].ad_network_app_id =
            item.ad_network_app_id;
        }

        if (item.ad_network_app_key) {
          networkDataMap[networkName].ad_network_app_key =
            item.ad_network_app_key;
        }
      });

      // Convert the networkDataMap into the final modifiedData format
      for (const networkName in networkDataMap) {
        const networkData = {};
        networkData[networkName] = networkDataMap[networkName];
        modifiedData.push(networkData);
      }

      return modifiedData;
    },

    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchGames() {
      try {
        this.loadingGames = true;
        const response = await this.$api.get("games/all?only_actives=True");
        this.games = response.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingGames = false;
      }
    },
    async fetchAdUnits() {
      try {
        this.adUnitsLoading = true;
        const response = await this.$api.get(`applovin/ad-units`);
        this.adUnits = response.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.adUnitsLoading = false;
      }
    },
    async fetchAdUnitDetail(ad_unit_id, waterfall) {
      try {
        const response = await this.$api.get(
          `applovin/ad-units/detail?ad_unit_id=${ad_unit_id}&waterfall=${waterfall}`
        );

        return response.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    updateCsvData(data, adUnitDetail) {
      if (!data.length) return [];
      else {
        var modifiedData = JSON.parse(
          JSON.stringify(this.computedNetworkData(adUnitDetail))
        );

        for (let i = 0; i < data.length; i++) {
          var csvData = data[i];
          csvData.editable = false;
          csvData.mode = "";
          csvData.cpm = csvData.cpm ? parseFloat(csvData.cpm) : null;
          csvData.originalData = JSON.parse(JSON.stringify(csvData));

          const placementIndex = modifiedData.findIndex(
            (x) =>
              x.ad_network_ad_unit_id === csvData.ad_network_ad_unit_id &&
              x.network === csvData.network
          );

          if (csvData.operation.toLowerCase() === "add") {
            if (placementIndex > -1) {
              // Zaten böyle bir placement olduğu anlamına gelir.
              csvData.originalData = modifiedData[placementIndex].originalData;

              if (!csvData.originalData.ad_network_app_key) {
                csvData.originalData.ad_network_app_key = null;
              }

              if (!csvData.originalData.ad_network_app_id) {
                csvData.originalData.ad_network_app_id = null;
              }

              modifiedData[placementIndex] = {
                ...csvData,
                mode: "update",
              };
            } else {
              // Böyle bir placement olmadığı için modified data üzerine pushlanır.
              const temp = {
                ...csvData,
                mode: "add",
              };

              temp.originalData.mode = "add";

              modifiedData.push(temp);
            }
          }

          if (csvData.operation.toLowerCase() === "remove") {
            if (placementIndex > -1) {
              // Zaten böyle bir placement olduğu anlamına gelir.

              if (csvData.countries.length) {
                var countries = [];
                modifiedData[placementIndex].countries.forEach((country) => {
                  var isFound = false;
                  csvData.countries.forEach((csvCountry) => {
                    if (country.toLowerCase() === csvCountry.toLowerCase()) {
                      isFound = true;
                    }
                  });

                  if (!isFound) {
                    countries.push(country);
                  }
                });

                // Eğer ülkelerde bir değişiklik varsa update;
                if (
                  modifiedData[placementIndex].countries.length !==
                  countries.length
                ) {
                  modifiedData[placementIndex].countries = countries;
                  modifiedData[placementIndex].mode = "update";
                }
                // Yoksa delete olsun.
                else {
                  modifiedData[placementIndex].mode = "delete";
                }
              } else {
                modifiedData[placementIndex].mode = "delete";
              }
            } else {
              const err_msg = `Silmek istediğiniz placement, mevcut placementler arasında bulunamadı. \nNetwork: ${csvData.network} - Placement ID: ${csvData.ad_network_ad_unit_id}`;

              console.error(err_msg);
              this.$toast.error(err_msg, {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              });
              return;
            }
          }
        }

        // Off işlemi
        for (let i = 0; i < data.length; i++) {
          const csvData = data[i];
          if (csvData.operation.toLowerCase() === "off") {
            for (let y = 0; y < modifiedData.length; y++) {
              const element = modifiedData[y];
              if (
                csvData.network.toUpperCase() === element.network.toUpperCase()
              ) {
                modifiedData[y].disabled = true;
                modifiedData[y].mode = "off";
              }
            }
          }
        }

        return modifiedData;
      }
    },
    computedAddedPlacements(excelModifiedData) {
      return excelModifiedData
        ? excelModifiedData.filter(
            (x) =>
              x.mode === "add" ||
              (x.mode === "off" && x.originalData.mode === "add")
          )
        : [];
    },
    computedUpdatedPlacements(excelModifiedData) {
      const data = JSON.parse(JSON.stringify(excelModifiedData));
      var returnData = [];

      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        // Check differences
        if (!["delete"].includes(element.mode)) {
          if (
            element.ad_network_app_id !== element.originalData.ad_network_app_id
          )
            returnData.push(element);
          else if (
            element.ad_network_app_key !==
            element.originalData.ad_network_app_key
          )
            returnData.push(element);
          else if (element.country_type !== element.originalData.country_type)
            returnData.push(element);
          else if (element.cpm !== element.originalData.cpm)
            returnData.push(element);
          else if (
            element.ad_network_ad_unit_id !==
            element.originalData.ad_network_ad_unit_id
          )
            returnData.push(element);
          else if (
            !_.isEqual(
              element.countries.sort().map((x) => x.toLowerCase()),
              element.originalData.countries.sort().map((x) => x.toLowerCase())
            )
          )
            returnData.push(element);
        }
      }

      return returnData;
    },
    computedDeletedPlacements(excelModifiedData) {
      return excelModifiedData
        ? excelModifiedData.filter((x) => x.mode === "delete")
        : [];
    },
    computedDisabledPlacements(excelModifiedData) {
      return excelModifiedData
        ? excelModifiedData.filter(
            (x) => x.disabled && x.mode !== "delete" && !x.originalData.disabled
          )
        : [];
    },
  },
  computed: {
    computedExcelData() {
      if (this.excelData && Object.keys(this.excelData).length) {
        var copied = JSON.parse(JSON.stringify(this.excelData));
        if (copied["info"] !== undefined) {
          delete copied["info"];
        }
        return copied;
      }
      return null;
    },
    computedNetworkMaps() {
      return this.$sortArrayByProperty(this.networks, ["label"], ["asc"]);
    },

    isSubmitBtnDisabled() {
      const keys = Object.keys(this.excelData);
      if (keys.length <= 1) {
        return true;
      }

      if (this.operation_purpose === "creating-ab-test") {
        if (!this.excelData["info"]) {
          return true;
        }

        const nullExperiments = this.excelData.info.filter(
          (x) => !x.experiment_name
        );

        if (nullExperiments.length) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    async excelFile(newValue) {
      if (newValue) {
        try {
          const timer = (ms) => new Promise((res) => setTimeout(res, ms));
          const data = await this.$parseExcel(newValue);
          this.excelData = data;

          this.skeletonLoading = true;
          this.hasError = false;

          if (this.excelData["info"] !== undefined) {
            for (let i = 0; i < this.excelData.info.length; i++) {
              const infoSheet = this.excelData.info[i];

              for (let y = 0; y < this.adUnits.length; y++) {
                const adUnit = this.adUnits[y];

                if (
                  adUnit.package_name === infoSheet.package_name &&
                  adUnit.platform === infoSheet.platform.toLowerCase() &&
                  adUnit.ad_format.toLowerCase() ===
                    infoSheet.ad_type.toLowerCase()
                ) {
                  var wf = "default";
                  if (adUnit["segments"]) {
                    const segment = adUnit["segments"].find(
                      (x) => x.name === infoSheet.waterfall
                    );
                    if (segment) {
                      wf = segment.id;
                    }
                  }

                  this.excelData.info[i]["segment"] = wf;

                  const adUnitDetail = await this.fetchAdUnitDetail(
                    adUnit.id,
                    wf
                  );

                  await timer(1000);

                  this.excelData[infoSheet.sheet_name]["data"] = [];
                  this.excelData[infoSheet.sheet_name]["networkSettings"] =
                    JSON.parse(JSON.stringify(adUnitDetail));

                  const csvData = this.handleCsvSubmit(
                    JSON.parse(
                      JSON.stringify(this.excelData[infoSheet.sheet_name])
                    ),
                    adUnitDetail
                  );

                  if (csvData) {
                    this.excelData[infoSheet.sheet_name]["data"] = JSON.parse(
                      JSON.stringify(csvData)
                    );

                    const hasDuplicateNetworkAndCpm =
                      this.hasDuplicateNetworkAndCpm(
                        this.excelData[infoSheet.sheet_name]["data"],
                        infoSheet.sheet_name
                      );

                    if (hasDuplicateNetworkAndCpm) {
                      this.excelFile = null;
                      return;
                    }
                  } else {
                    this.$toast.error(
                      `${infoSheet.sheet_name} has no CSV data.`,
                      {
                        timeout: 0,
                        position: "bottom-center",
                        icon: false,
                      }
                    );
                    this.excelData = {};
                    return;
                  }
                }
              }
            }
          }
        } catch (error) {
          this.errorHandler(error);
          this.excelData = {};
        } finally {
          this.skeletonLoading = false;
        }
      } else {
        this.excelData = {};
      }
    },
  },
  mounted() {
    this.fetchNetworks();
    this.fetchAdUnits();
    this.fetchGames();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}

::v-deep .v-input--selection-controls {
  margin-top: 5px !important;
}

.different {
  ::v-deep fieldset {
    border-color: #4db2ec !important;
  }
}

.table-input {
  outline: none;
  padding: 4px;
  display: inline-block;
}
</style>
