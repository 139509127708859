<template>
  <v-dialog v-model="visible" width="600">
    <v-card>
      <v-card-title class="text-h5"> Create a placement </v-card-title>

      <v-card-text>
        <div class="mt-5">
          <div>
            <div class="mb-2.5">
              <v-autocomplete
                outlined
                v-model="insertBidderObject.network"
                dense
                hide-details
                :items="networks"
                item-value="value"
                item-text="label"
                class="text-[13px]"
                label="Network"
              ></v-autocomplete>
            </div>
            <div class="mb-2.5">
              <v-text-field
                outlined
                v-model="insertBidderObject.ad_network_app_id"
                dense
                hide-details
                class="text-[13px]"
                label="App ID"
              ></v-text-field>
            </div>
            <div class="mb-2.5">
              <v-text-field
                outlined
                v-model="insertBidderObject.ad_network_app_key"
                dense
                hide-details
                class="text-[13px]"
                label="App Key"
              ></v-text-field>
            </div>
            <div class="mb-2.5">
              <v-text-field
                outlined
                v-model="insertBidderObject.ad_network_ad_unit_id"
                dense
                hide-details
                class="text-[13px]"
                label="Ad Unit ID"
              ></v-text-field>
            </div>
            <div class="mb-2.5">
              <v-text-field
                outlined
                v-model="insertBidderObject.ad_network_optional_placement_id"
                dense
                hide-details
                class="text-[13px]"
                label="Optional Placement ID"
              ></v-text-field>
            </div>
            <div class="mb-2.5">
              <v-text-field
                outlined
                v-model="insertBidderObject.cpm"
                dense
                hide-details
                class="text-[13px]"
                label="CPM"
                type="number"
              ></v-text-field>
            </div>
            <div class="mb-2.5">
              <v-autocomplete
                outlined
                v-model="insertBidderObject.countries"
                dense
                hide-details
                :items="countries"
                item-value="code"
                item-text="name"
                class="text-[13px]"
                label="Countries"
                multiple
              ></v-autocomplete>
            </div>
            <div>
              <v-select
                outlined
                v-model="insertBidderObject.country_type"
                dense
                hide-details
                :items="['INCLUDE', 'EXCLUDE']"
                class="text-[13px]"
                label="Country Type"
              ></v-select>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="flex items-center justify-end">
          <v-btn
            color="blue darken-1"
            text
            :disabled="
              !insertBidderObject.network ||
              !insertBidderObject.ad_network_ad_unit_id
            "
            @click="submitPlacement"
          >
            Submit
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      insertBidderObject: {
        network: "",
        ad_network_app_id: "",
        ad_network_app_key: "",
        ad_network_optional_placement_id: "",
        ad_network_ad_unit_id: "",
        countries: [],
        country_type: "INCLUDE",
        cpm: null,
        editable: false,
      },
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    networks: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.file = null;
        this.resetBidderObject();
      }
    },
  },
  methods: {
    submitPlacement() {
      this.$emit("submitted", this.insertBidderObject);
    },
    resetBidderObject() {
      this.insertBidderObject = {
        network: "",
        ad_network_app_id: "",
        ad_network_app_key: "",
        ad_network_optional_placement_id: "",
        ad_network_ad_unit_id: "",
        countries: [],
        country_type: "INCLUDE",
        cpm: null,
        editable: false,
        is_deleted: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
