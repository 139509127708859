<template>
  <div>
    <v-dialog v-model="visible" width="820">
      <v-card v-if="template_information">
        <v-card-title class="text-h5"> Taslak Detayı </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <div>
              <div class="mt-5">
                <v-select
                  dense
                  clearable
                  label="Oyun Seç"
                  outlined
                  auto-select-first
                  class="text-[12px]"
                  v-model="selected_game"
                  :items="games"
                  item-text="title"
                  item-value="id"
                  hide-details
                >
                </v-select>
              </div>
              <div class="mt-5">
                <v-autocomplete
                  clearable
                  dense
                  flat
                  label="Müşteri Seç"
                  v-model="selected_customer"
                  outlined
                  auto-select-first
                  class="text-[12px]"
                  :items="
                    customers.map((x) => {
                      return { ...x.attributes, id: x.id };
                    })
                  "
                  item-text="name"
                  hide-details
                  return-object
                >
                </v-autocomplete>
              </div>
              <div class="my-5">
                <v-autocomplete
                  clearable
                  flat
                  label="Ürün / Hizmet"
                  dense
                  v-model="selected_parasut_product"
                  outlined
                  auto-select-first
                  class="text-[12px]"
                  :items="
                    products.map((x) => {
                      return { ...x.attributes, id: x.id };
                    })
                  "
                  item-text="name"
                  return-object
                  hide-details
                >
                </v-autocomplete>
              </div>

              <div class="mt-5">
                <v-select
                  dense
                  clearable
                  label="KDV"
                  outlined
                  auto-select-first
                  class="text-[12px]"
                  v-model="selected_tax"
                  :items="taxes"
                  item-text="title"
                  item-value="id"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <span>% {{ item }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="text-[12px]">% {{ item }}</span>
                  </template>
                </v-select>
              </div>

              <div class="mb-5">
                <h1
                  class="text-gray-600 font-semibold mt-5 text-[12px] uppercase"
                >
                  İRSALİYE
                </h1>
                <v-radio-group dense hide-details v-model="is_dispatch" row>
                  <v-radio label="İrsaliyeli Fatura" :value="true"></v-radio>
                  <v-radio label="İrsaliyesiz Fatura" :value="false"></v-radio>
                </v-radio-group>
              </div>

              <div class="mb-5">
                <h1
                  class="text-gray-600 font-semibold mt-5 text-[12px] uppercase"
                >
                  TAHSİLAT DURUMU
                </h1>
                <v-radio-group dense hide-details v-model="is_collected" row>
                  <v-radio label="Tahsil Edilecek" :value="false"></v-radio>
                  <v-radio label="Tahsil Edildi" :value="true"></v-radio>
                </v-radio-group>
              </div>
              <div class="mb-5">
                <v-autocomplete
                  v-model="selected_categories"
                  :items="
                    categories.map((x) => {
                      return { ...x.attributes, id: x.id };
                    })
                  "
                  attach
                  chips
                  outlined
                  label="Kategori"
                  item-text="name"
                  small-chips
                  deletable-chips
                  auto-select-first
                  hide-details
                  dense
                  item-value="id"
                ></v-autocomplete>
              </div>
              <div class="mb-5">
                <v-autocomplete
                  v-model="selected_tags"
                  :items="
                    tags.map((x) => {
                      return { ...x.attributes, id: x.id };
                    })
                  "
                  attach
                  chips
                  outlined
                  label="Etiketler"
                  item-text="name"
                  small-chips
                  multiple
                  deletable-chips
                  auto-select-first
                  hide-details
                  item-value="id"
                  dense
                ></v-autocomplete>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="flex items-center justify-between w-full">
            <v-btn
              :elevation="0"
              :ripple="false"
              color="error"
              @click="deleteTemplate"
              :loading="loadingDeleteButton"
              :disabled="loadingDeleteButton"
            >
              Sil
            </v-btn>
          </div>
          <div>
            <v-btn
              :elevation="0"
              :ripple="false"
              color="success"
              @click="updateTemplate"
              :disabled="isUpdateBtnDisabled || loadingUpdateButton"
              :loading="loadingUpdateButton"
            >
              Güncelle
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("tr");

export default {
  data() {
    return {
      visible: false,
      loading: false,
      template_information: null,
      selected_game: null,
      selected_customer: null,
      selected_parasut_product: null,
      taxes: [20, 10, 1, 0],
      is_dispatch: null,
      is_collected: null,
      selected_tax: null,
      selected_categories: null,
      selected_tags: [],
      loadingDeleteButton: false,
      loadingUpdateButton: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_data: {
      type: Object,
      default: null,
    },
    games: {
      type: Array,
      default: () => [],
    },
    customers: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      immediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    row_data: {
      handler(newValue) {
        this.defaultSetter(newValue);
      },
      immediate: false,
    },
  },
  methods: {
    defaultSetter(newValue) {
      if (this.customers && this.products && this.row_data) {
        this.template_information = newValue;
        this.selected_game = newValue.game.id;
        const temp_json_data = JSON.parse(newValue.data);

        const temp_customer = this.customers.filter(
          (x) => x.id === newValue.parasut_customer
        );
        if (temp_customer.length > 0) {
          this.selected_customer = {
            ...temp_customer[0].attributes,
            id: temp_customer[0].id,
          };
        }

        const temp_product = this.products.filter(
          (x) => x.id === newValue.parasut_product
        );

        if (temp_product.length > 0) {
          this.selected_parasut_product = {
            ...temp_product[0].attributes,
            id: temp_product[0].id,
          };
        }

        this.selected_tax = newValue.parasut_tax;
        this.is_dispatch = temp_json_data.is_dispatch;
        this.is_collected = temp_json_data.is_collected;

        this.selected_categories = temp_json_data.categories;
        this.selected_tags = temp_json_data.tags;
      }
    },
    updateTemplate() {
      this.loadingUpdateButton = true;
      const data = {
        is_dispatch: this.is_dispatch,
        is_collected: this.is_collected,
        tags: this.selected_tags,
        categories: this.selected_categories,
        parasut_product: this.selected_parasut_product.id,
        parasut_product_title: this.selected_parasut_product.name,
        parasut_customer: this.selected_customer.id,
        parasut_customer_title: this.selected_customer.name,
        parasut_tax: this.selected_tax,
      };

      const requestData = {
        data: JSON.stringify(data),
        game_id: this.selected_game,
        parasut_product: this.selected_parasut_product.id,
        parasut_product_title: this.selected_parasut_product.name,
        parasut_customer: this.selected_customer.id,
        parasut_customer_title: this.selected_customer.name,
        parasut_tax: this.selected_tax,
        template_id: this.row_data.id,
      };

      this.$api
        .put("parasut/templates/update", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("updated");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingUpdateButton = false;
        });
    },
    deleteTemplate() {
      this.loadingDeleteButton = true;
      const requestData = {
        data: {
          template_id: this.row_data.id,
        },
      };

      this.$api
        .delete("parasut/templates/delete", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("deleted");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingDeleteButton = false;
        });
    },
  },
  computed: {
    isUpdateBtnDisabled() {
      if (this.template_information && this.row_data) {
        if (
          this.is_dispatch === undefined ||
          this.is_dispatch === null ||
          this.is_collected === undefined ||
          this.is_collected === null ||
          this.selected_tags.length === 0 ||
          !this.selected_categories ||
          !this.selected_parasut_product ||
          !this.selected_customer ||
          !this.selected_game
        ) {
          return true;
        } else if (
          this.selected_tax === undefined ||
          this.selected_tax === null
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
