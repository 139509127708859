var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Edit Bundle Data ")]),_c('v-card-text',[_c('div',_vm._l((_vm.surfaceValue.length),function(item,index){return _c('div',{key:index,staticClass:"d-inline-block px-3 py-2 cursor-pointer hover:bg-blue-50 rounded hover:text-blue-700 text-[11px] mr-2 font-semibold mb-1",class:_vm.getSelectedItem === index ? 'bg-blue-50 text-blue-700' : '',on:{"click":function($event){return _vm.selectIndex(index)}}},[_c('div',{staticClass:"flex items-center"},[_c('span',[_vm._v(" Options "+_vm._s(item)+" ")]),(_vm.getSelectedItem === index)?_c('span',{staticClass:"ml-1 hover:text-red-700",on:{"click":function($event){return _vm.deleteItem(index)}}},[_c('svg',{staticClass:"icon icon-tabler icon-tabler-trash",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18","height":"18","viewBox":"0 0 24 24","stroke-width":"1.25","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"stroke":"none","d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M4 7l16 0"}}),_c('path',{attrs:{"d":"M10 11l0 6"}}),_c('path',{attrs:{"d":"M14 11l0 6"}}),_c('path',{attrs:{"d":"M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"}}),_c('path',{attrs:{"d":"M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"}})])]):_vm._e()])])}),0),_c('div',{staticClass:"flex justify-end items-center my-2"},[_c('v-btn',{attrs:{"color":"primary","ripple":false,"depressed":"","text":"","loading":_vm.addBtnLoading},on:{"click":_vm.addNewSurface}},[_vm._v(" Add New ")])],1),(
            _vm.surfaceValue &&
            _vm.surfaceValue.length &&
            _vm.getSelectedItem !== null &&
            _vm.surfaceValue[_vm.getSelectedItem]
          )?_c('div',_vm._l((Object.keys(_vm.surfaceValue[_vm.getSelectedItem])),function(key){return _c('div',{key:key,staticClass:"mt-3"},[_c('h1',{staticClass:"font-semibold text-[12px] text-sky-800 mb-3"},[_vm._v(" "+_vm._s(key)+" ")]),_c('div',[(
                  _vm.$isObj(_vm.surfaceValue[_vm.getSelectedItem][key]) &&
                  Object.keys(_vm.surfaceValue[_vm.getSelectedItem][key]).length
                )?_c('div',_vm._l((Object.keys(
                    _vm.surfaceValue[_vm.getSelectedItem][key]
                  )),function(subKey,subIndex){return _c('div',{key:subIndex,staticClass:"mb-2 w-full"},[_c('div',{staticClass:"w-full"},[(['botClothingChoiceIndex'].includes(key))?_c('v-text-field',{staticClass:"text-[12px] w-full",attrs:{"dense":"","label":subKey,"outlined":"","hide-details":""},model:{value:(_vm.surfaceValue[_vm.getSelectedItem][key][subKey]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[_vm.getSelectedItem][key], subKey, $$v)},expression:"surfaceValue[getSelectedItem][key][subKey]"}}):(['selectionPositionTypes'].includes(key))?_c('v-autocomplete',{staticClass:"text-[12px] w-full",attrs:{"dense":"","label":subKey,"outlined":"","items":['ABC', 'ACB', 'BAC', 'BCA', 'CAB', 'CBA'],"hide-details":""},model:{value:(_vm.surfaceValue[_vm.getSelectedItem][key][subKey]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[_vm.getSelectedItem][key], subKey, $$v)},expression:"surfaceValue[getSelectedItem][key][subKey]"}}):(['customFeedbackOrder'].includes(key))?_c('v-autocomplete',{staticClass:"text-[12px] w-full",attrs:{"dense":"","label":subKey,"outlined":"","items":_vm.computedFeedbackValues,"hide-details":"","disabled":!_vm.surfaceValue[_vm.getSelectedItem]['showFeedback']},model:{value:(_vm.surfaceValue[_vm.getSelectedItem][key][subKey]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[_vm.getSelectedItem][key], subKey, $$v)},expression:"surfaceValue[getSelectedItem][key][subKey]"}}):_vm._e()],1)])}),0):_vm._e(),(!_vm.$isObj(_vm.surfaceValue[_vm.getSelectedItem][key]))?_c('div',[_c('div',{staticClass:"w-full"},[(['level'].includes(key))?_c('v-text-field',{staticClass:"text-[12px] w-full",attrs:{"dense":"","label":key,"outlined":"","type":['level'].includes(key) ? 'number' : 'text',"hide-details":""},model:{value:(_vm.surfaceValue[_vm.getSelectedItem][key]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[_vm.getSelectedItem], key, $$v)},expression:"surfaceValue[getSelectedItem][key]"}}):(
                      [
                        'cameraPositionX',
                        'cameraPositionY',
                        'cameraPositionZ',
                        'cameraRotationX',
                        'cameraRotationY',
                        'cameraRotationZ',
                      ].includes(key)
                    )?_c('v-text-field',{staticClass:"text-[12px] w-full",attrs:{"dense":"","label":key,"outlined":"","type":[
                        'cameraPositionX',
                        'cameraPositionY',
                        'cameraPositionZ',
                        'cameraRotationX',
                        'cameraRotationY',
                        'cameraRotationZ',
                      ].includes(key)
                        ? 'number'
                        : 'text',"hide-details":"","disabled":_vm.surfaceValue[_vm.getSelectedItem]['cameraAngleType'] !==
                      'Custom'},model:{value:(_vm.surfaceValue[_vm.getSelectedItem][key]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[_vm.getSelectedItem], key, $$v)},expression:"surfaceValue[getSelectedItem][key]"}}):(
                      ['showFeedback', 'playFeedbackSound'].includes(key)
                    )?_c('v-checkbox',{attrs:{"dense":"","label":key,"hide-details":"","disabled":key === 'playFeedbackSound'},model:{value:(_vm.surfaceValue[_vm.getSelectedItem][key]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[_vm.getSelectedItem], key, $$v)},expression:"surfaceValue[getSelectedItem][key]"}}):_vm._e()],1),_c('div',{staticClass:"w-full"},[(['customConceptType'].includes(key))?_c('v-autocomplete',{staticClass:"text-[12px] w-full",attrs:{"dense":"","label":key,"outlined":"","items":_vm.computedOutfitData,"hide-details":""},model:{value:(_vm.surfaceValue[_vm.getSelectedItem][key]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[_vm.getSelectedItem], key, $$v)},expression:"surfaceValue[getSelectedItem][key]"}}):(['cameraAngleType'].includes(key))?_c('v-select',{staticClass:"text-[12px] w-full",attrs:{"dense":"","label":key,"outlined":"","items":['Front', 'Default', 'Custom'],"hide-details":""},model:{value:(_vm.surfaceValue[_vm.getSelectedItem][key]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[_vm.getSelectedItem], key, $$v)},expression:"surfaceValue[getSelectedItem][key]"}}):_vm._e()],1)]):_vm._e()])])}),0):_vm._e()]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" Submit ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }