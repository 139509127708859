export default {
  state: {
    extra_data: {
      headline_text: "",
      headline_text_color: "",
      headline_text_font: "",
      cta_text: "",
      cta_text_color: "",
      cta_shows_at_ms: null,
      action_button_text: "",
      action_button_color: "",
      background_color: "",
      style: null,
      theme: null,
      main_character: null,
      triggering_emotion: null,
      voice_over_status: null,
      pointer: null,
      point_of_view: null,
      keywords: null,
      game_action: null,
    },
  },
  getters: {
    getExtraData(state) {
      return state.extra_data;
    },
  },
  mutations: {
    handleSetExtraData(state, payload) {
      state.extra_data = payload;
    },
  },
  actions: {
    setExtraData({ commit }, payload) {
      commit("handleSetExtraData", payload);
    },
  },
};
