import CreateCreativeView from "@/views/tools/acg/CreateCreativeView.vue";
import CreatePlayRecordView from "@/views/tools/acg/CreatePlayRecordView.vue";
import UploadBuild from "@/views/tools/acg/UploadBuild.vue";
import DrillCollectUploadLevelView from "@/views/tools/acg/Drill_Collect/UploadLevelView.vue";
import ImportAeProjectView from "@/views/tools/acg/ImportAeProjectView.vue";

export const acg_router = [
  {
    path: "/acg/create-combination",
    name: "acg-create-combination-view",
    component: CreateCreativeView,
    meta: {
      title: "Create Combination",
    },
  },
  {
    path: "/acg/create-and-play-record",
    name: "acg-create-play-record-view",
    component: CreatePlayRecordView,
    meta: {
      title: "Create or Play Records",
    },
  },
  {
    path: "/acg/import-ae-project",
    name: "acg-import-ae-project-view",
    component: ImportAeProjectView,
    meta: {
      title: "Import After Effects Project",
    },
  },
  {
    path: "/acg/upload-build",
    name: "acg-upload-build-view",
    component: UploadBuild,
    meta: {
      title: "Upload a new build",
    },
  },
  {
    path: "/acg/drill-collect/upload-level",
    name: "acg-drill-collect-upload-level",
    component: DrillCollectUploadLevelView,
    meta: {
      title: "Upload a new level for DnC",
    },
  },
];
