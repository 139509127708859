<template>
  <div>
    <div v-if="videos && videos.length > 0">
      <hooper style="height: auto" :settings="hooperSettings">
        <slide
          v-for="(video, index) in videos"
          :key="index"
          style="height: 100%"
        >
          <div class="p-4 h-full main-section">
            <div class="video-section">
              <div class="flex items-center justify-between">
                <div
                  class="font-semibold text-center w-full text-[12px] text-gray-600"
                >
                  {{ video.name }}
                </div>
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="delete-section hover:bg-gray-200 rounded-full transition duration-200"
                        v-bind="attrs"
                        v-on="on"
                        @click="$emit('delete_video', video.name)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </div>
                    </template>
                    <span class="text-[12px]">Click to remove</span>
                  </v-tooltip>
                </div>
              </div>
              <video-player
                class="video-player-box vjs-big-play-centered"
                ref="videoPlayer"
                :options="computedOptions(video)"
                :playsinline="true"
              >
              </video-player>
            </div>
          </div>
        </slide>

        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      loading: true,
      hooperSettings: {
        wheelControl: false,
        breakpoints: {
          1200: {
            centerMode: false,
            itemsToShow: 4,
          },
          992: {
            centerMode: false,
            itemsToShow: 3,
          },
          768: {
            centerMode: false,
            itemsToShow: 2,
          },
        },
      },
    };
  },
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    computedVideos() {
      return this.videos;
    },
  },
  methods: {
    getVideoSrc(file) {
      return URL.createObjectURL(file);
    },
    computedOptions(file) {
      return {
        sources: [
          {
            type: "video/mp4",
            src: this.getVideoSrc(file),
          },
        ],
        language: "en",
        width: "100%",
        height: "220px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.video-section {
  .delete-section {
    height: 45px;
    width: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .video-player {
  div {
    width: auto !important;
  }
}
</style>
