var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-white p-6 w-full"},[_c('h1',{staticClass:"font-semibold text-[18px] pb-6"},[_vm._v("Audit Logs")]),_c('div',{staticClass:"grid grid-cols-6 gap-2"},[_c('div',[_c('v-select',{staticClass:"text-[13px]",attrs:{"label":"User","outlined":"","items":_vm.users,"dense":"","hide-details":"","item-text":"full_name","item-value":"id","clearable":""},model:{value:(_vm.filterData.user),callback:function ($$v) {_vm.$set(_vm.filterData, "user", $$v)},expression:"filterData.user"}})],1),_c('div',[_c('v-select',{staticClass:"text-[13px]",attrs:{"label":"Tool","outlined":"","items":_vm.tools,"dense":"","hide-details":"","item-text":"title","item-value":"title","clearable":""},model:{value:(_vm.filterData.tool),callback:function ($$v) {_vm.$set(_vm.filterData, "tool", $$v)},expression:"filterData.tool"}})],1)]),_c('div',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.auditLogs,"loading":_vm.loading,"dense":"","loading-text":"Please wait...","items-per-page":_vm.limit,"page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.totalCount,"footer-props":{
          itemsPerPageOptions: [20, 30, 40, 50, -1],
        },"expanded":_vm.expanded,"show-expand":"","single-expand":"","item-key":"id"},on:{"pagination":_vm.handlePaginationChange,"update:sort-by":_vm.handleSortBy,"update:sort-desc":_vm.handleSortDesc,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:`item.id`,fn:function({ item }){return [_c('span',{staticClass:"text-[12px]"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:`item.tool`,fn:function({ item }){return [_c('span',{staticClass:"text-[11px] uppercase inline-block px-[7px] py-[2px] rounded-full",class:{
              'bg-green-100 text-green-700':
                _vm.computedTool(item.tool.toLowerCase()) === 'act',
              'bg-indigo-100 text-indigo-700':
                _vm.computedTool(item.tool.toLowerCase()) === 'acg',
              'bg-gray-100 text-gray-700':
                _vm.computedTool(item.tool.toLowerCase()) === 'general',
              'bg-yellow-100 text-yellow-700':
                _vm.computedTool(item.tool.toLowerCase()) === 'burcha',
              'bg-blue-100 text-blue-700':
                _vm.computedTool(item.tool.toLowerCase()) === 'parasut',
              'bg-rose-100 text-rose-700':
                _vm.computedTool(item.tool.toLowerCase()) === 'persephone',
              'bg-teal-100 text-teal-700':
                _vm.computedTool(item.tool.toLowerCase()) === 'a/b tests',
            }},[_vm._v(" "+_vm._s(item.tool.toLowerCase())+" ")])]}},{key:`item.description`,fn:function({ item }){return [(item.tool.toLowerCase() === 'act')?_c('div',[(item.type === 'start_test')?_c('div',[_vm._v(" User started test on "),_c('span',{staticClass:"text-rose-700 capitalize"},[_vm._v(_vm._s(item.message.selected_network.channel))]),_vm._v(" with selected creatives: "),_vm._l((item.message.data.created_tests),function(tag,idx){return _c('span',{key:idx,staticClass:"inline-block px-1 bg-sky-200 text-sky-700 mx-1 rounded-[3px] font-semibold text-[10px]"},[_vm._v(" "+_vm._s(tag.title))])})],2):_vm._e()]):(item.tool.toLowerCase() === 'burcha')?_c('div',[_c('div',[_vm._v(" User created a job on "),_c('span',{staticClass:"text-rose-700 capitalize"},[_vm._v(_vm._s(_vm.burchaTypeToName(item.type)))]),_vm._v(". ")])]):_c('span',{staticClass:"text-[12px]"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:`item.__user_name`,fn:function({ item }){return [_c('span',{staticClass:"text-[12px] uppercase inline-block"},[_vm._v(" "+_vm._s(item.__user_name)+" ")])]}},{key:`item.created_at`,fn:function({ item }){return [_c('span',{staticClass:"text-[12px]"},[_vm._v(" "+_vm._s(_vm.$formatUnix(item.created_at))+" ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[(item.tool === 'burcha')?_c('div',[(_vm.burchaTypeToName(item.type) === 'Facebook')?_c('div',{staticClass:"grid grid-cols-4 gap-2.5 p-2"},_vm._l((item.message.data),function(info,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',[_vm._v("Campaign: "+_vm._s(info.campaign))]),_c('div',[_vm._v("Adset: "+_vm._s(info.adset))]),_c('div',[_vm._v("Creative: "+_vm._s(info.creative))]),_c('div',[_vm._v(" Type: "),_c('span',{class:{
                        'text-green-600': info.type === 'insert',
                        'text-red-600': info.type === 'delete',
                      }},[_vm._v(_vm._s(info.type))])])])}),0):_vm._e(),(_vm.burchaTypeToName(item.type) === 'Unity')?_c('div',{staticClass:"grid grid-cols-4 gap-2.5 p-2"},_vm._l((item.message.data),function(info,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',[_vm._v("Campaign: "+_vm._s(info.campaign))]),_c('div',[_vm._v("Creative: "+_vm._s(info.creative))]),_c('div',[_vm._v(" Playable: "+_vm._s(info.playable ? info.playable : "-")+" ")]),_c('div',[_vm._v(" Type: "),_c('span',{class:{
                        'text-green-600': info.type === 'insert',
                        'text-red-600': info.type === 'delete',
                      }},[_vm._v(_vm._s(info.type))])])])}),0):_vm._e(),(_vm.burchaTypeToName(item.type) === 'Google')?_c('div',{staticClass:"p-2"},[_c('div',[_vm._v("Campaign: "+_vm._s(item.message.campaign_name))]),_c('div',[_vm._v("Adgroup: "+_vm._s(item.message.adgroup_name))]),(JSON.parse(item.message.descriptions).length)?_c('v-divider',{staticClass:"mt-2"}):_vm._e(),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.descriptions
                    )),function(description,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Description Changes - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v("Old: "+_vm._s(description.old_value))]),_c('div',[_vm._v("New: "+_vm._s(description.new_value))])])}),0),(JSON.parse(item.message.headlines).length)?_c('v-divider',{staticClass:"mt-2"}):_vm._e(),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.headlines
                    )),function(headline,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Headline Changes - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v("Old: "+_vm._s(headline.old_value))]),_c('div',[_vm._v("New: "+_vm._s(headline.new_value))])])}),0),(
                    JSON.parse(item.message.inserted_images).length ||
                    JSON.parse(item.message.deleted_images).length
                  )?_c('v-divider',{staticClass:"mt-2"}):_vm._e(),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.deleted_images
                    )),function(image,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Deleted Image - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v(_vm._s(image.id))])])}),0),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.inserted_images
                    )),function(image,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Inserted Image - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v(_vm._s(image))])])}),0),(
                    JSON.parse(item.message.inserted_videos).length ||
                    JSON.parse(item.message.deleted_videos).length
                  )?_c('v-divider',{staticClass:"mt-2"}):_vm._e(),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.inserted_videos
                    )),function(video,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Inserted Video - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v(_vm._s(video))])])}),0),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.deleted_videos
                    )),function(video,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Deleted Video - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v(_vm._s(video.id))])])}),0),(JSON.parse(item.message.deleted_playables).length)?_c('v-divider',{staticClass:"mt-2"}):_vm._e(),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.deleted_playables
                    )),function(playable,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Deleted Playable - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v(_vm._s(playable.id))])])}),0)],1):_vm._e(),(_vm.burchaTypeToName(item.type) === 'Mintegral')?_c('div',{staticClass:"p-2"},[_c('div',[_vm._v("Campaign: "+_vm._s(item.message.campaign_name))]),_c('div',[_vm._v("Offer: "+_vm._s(item.message.offer_name))]),(JSON.parse(item.message.selected_creatives).length)?_c('v-divider',{staticClass:"mt-2"}):_vm._e(),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.selected_creatives
                    )),function(creative,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Selected Creative - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v(_vm._s(creative.ad_name))])])}),0),(JSON.parse(item.message.deleted_videos).length)?_c('v-divider',{staticClass:"mt-2"}):_vm._e(),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.deleted_videos
                    )),function(video,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Deleted Video - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v(_vm._s(video.creative_name))])])}),0)],1):_vm._e(),(_vm.burchaTypeToName(item.type) === 'Applovin')?_c('div',{staticClass:"p-2"},[_c('div',[_vm._v("Campaign: "+_vm._s(item.message.campaign_name))]),(JSON.parse(item.message.inserted_creatives).length)?_c('v-divider',{staticClass:"mt-2"}):_vm._e(),_c('div',{staticClass:"grid grid-cols-4 gap-2.5 mt-2"},_vm._l((JSON.parse(
                      item.message.inserted_creatives
                    )),function(creative,idx){return _c('div',{key:idx,staticClass:"bg-gray-50 p-2 rounded hover:bg-gray-100"},[_c('div',{staticClass:"font-semibold text-[11px] mb-1"},[_vm._v(" Inserted Creative - "+_vm._s(idx + 1)+" ")]),_c('div',[_vm._v(_vm._s(creative.gaia_id))])])}),0)],1):_vm._e()]):_vm._e(),(item.tool === 'umai')?_c('div',[_c('div',[_c('div',{staticClass:"grid grid-cols-2 gap-1.5 p-2"},_vm._l((item.message.data),function(info,idx){return _c('div',{key:idx,staticClass:"p-2 bg-gray-50 hover:bg-gray-100 rounded"},[_c('div',[_vm._v("Ad Unit ID: "+_vm._s(info.row.ad_unit_id))]),_c('div',[_vm._v("Ad Unit Name: "+_vm._s(info.row.ad_unit_name))]),_c('div',{staticClass:"mb-2 capitalize"},[_vm._v(" Segment: "+_vm._s(info.row.segment)+" ")]),_c('h1',{staticClass:"font-semibold text-[11px] mb-2"},[_vm._v("Logs")]),_vm._l((info.row.logs),function(log,logIdx){return _c('div',{key:logIdx},[_vm._v(" "+_vm._s(log)+" ")])})],2)}),0)])]):_vm._e(),(item.tool === 'cpa sheet')?_c('div',_vm._l((item.message.data),function(message){return _c('div',{key:message.uuid,staticClass:"p-[4px]"},[(item.description === 'Update')?_c('div',[_vm._v(" Row: "),_c('span',{staticClass:"text-rose-600"},[_vm._v(_vm._s(message.uuid))]),_vm._v(", "+_vm._s(message.message)+" ")]):_vm._e(),(item.description === 'Delete')?_c('div',[_vm._v(" "+_vm._s(message)+" ")]):_vm._e(),(item.description === 'Insert')?_c('div',[_vm._v(" Row: "),_c('span',{staticClass:"text-rose-600"},[_vm._v(_vm._s(message.uuid))]),_vm._v(" added by user. ")]):_vm._e()])}),0):_vm._e()])]}}],null,true)})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }