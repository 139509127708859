<template>
  <div v-if="getUserProfile">
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Combination Detail</h1>
      <div class="pb-4">
        <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-folder-play </v-icon>
        <span class="ml-4"> Medias </span>
      </h1>

      <div
        v-if="data.general_creative.general_medias"
        class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3"
      >
        <div
          class="p-5 hover:bg-gray-100 text-center"
          v-for="item in data.general_creative.general_medias"
          :key="item.id"
        >
          <div class="font-semibold text-[12px] mb-3">{{ item.title }}</div>
          <video-player
            class="video-player-box vjs-big-play-centered w-full"
            ref="videoPlayer"
            :options="
              computedOptions(
                item.signed_url ? item.signed_url : item.blob_info
              )
            "
            :playsinline="true"
          >
          </video-player>
        </div>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-code-json </v-icon>
        <span class="ml-4"> Game Parameters </span>
      </h1>

      <div>
        <!-- eslint-disable -->
        <pre class="p-4 bg-gray-100 rounded hover:bg-gray-200 text-[11px]">{{
          JSON.stringify(JSON.parse(data.params.replace(" ", "")), null, 4)
        }}</pre>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-tag-multiple </v-icon>
        <span class="ml-4">Combination Metadata </span>
      </h1>

      <div v-if="data.general_creative.extra_data">
        <div class="grid md:grid-cols-3 grid-cols-2 gap-5">
          <div>
            <v-text-field
              label="Headline Text"
              v-model="data.general_creative.extra_data.headline_text"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Headline Text Color"
              v-model="data.general_creative.extra_data.headline_text_color"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Headline Text Font"
              v-model="data.general_creative.extra_data.headline_text_font"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="CTA Text"
              v-model="data.general_creative.extra_data.cta_text"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="CTA Text Color"
              v-model="data.general_creative.extra_data.cta_text_color"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="CTA Shows At MS"
              v-model="data.general_creative.extra_data.cta_shows_at_ms"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Action Button Text"
              v-model="data.general_creative.extra_data.action_button_text"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Action Button Color"
              v-model="data.general_creative.extra_data.action_button_color"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Background Color"
              v-model="data.general_creative.extra_data.background_color"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.general_creative.extra_data.style"
              disabled
              label="Style"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.general_creative.extra_data.theme"
              disabled
              label="Theme"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.general_creative.extra_data.main_character"
              disabled
              label="Main Character"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.general_creative.extra_data.triggering_emotion"
              disabled
              label="Triggering Emotion"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-text-field
              clearable
              dense
              outlined
              hide-details
              :value="
                data.general_creative.extra_data.voice_over_status
                  ? 'Existent'
                  : 'Non Existent'
              "
              disabled
              label="Voice Over Status"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              hide-details
              class="text-[13px]"
              label="Pointer"
              disabled
              item-text="name"
              item-value="filename"
              outlined
              dense
              v-model="data.general_creative.extra_data.pointer"
            />
          </div>
          <div>
            <v-text-field
              hide-details
              class="text-[13px]"
              label="Point of View"
              disabled
              outlined
              dense
              v-model="data.general_creative.extra_data.point_of_view"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.general_creative.extra_data.keywords"
              disabled
              label="Keywords"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.general_creative.extra_data.game_action"
              disabled
              label="Game Action"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.general_creative.extra_data.game_genre"
              disabled
              label="Game Genre"
              class="text-[13px]"
            />
          </div>
        </div>
        <div class="my-4 flex items-center justify-end">
          <v-btn disabled color="primary">Update</v-btn>
        </div>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-palette </v-icon>
        <span class="ml-4"> Creative Color Palette </span>
      </h1>

      <div
        v-if="
          data.general_creative.extra_data &&
          data.general_creative.extra_data.color_palette
        "
      >
        <div
          v-for="(item, index) in data.general_creative.extra_data
            .color_palette"
          :key="index"
          class="inline-block mr-5"
        >
          <div class="flex flex-col items-center justify-center">
            <div class="font-semibold mb-1 text-gray-600 text-[12px]">
              {{ item.percent }}%
            </div>
            <div
              class="colorbox"
              :style="{ backgroundColor: `rgb(${item.color})` }"
            ></div>
          </div>
        </div>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-music </v-icon>
        <span class="ml-4"> Sound & General Information </span>
      </h1>

      <div>
        <div
          class="text-[12px]"
          v-if="
            data.general_creative.extra_data &&
            data.general_creative.extra_data.duration_ms
          "
        >
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Creative Duration:</span
          >
          <span class="text-gray-500"
            >{{
              Math.round(data.general_creative.extra_data.duration_ms / 1000)
            }}
            seconds</span
          >
        </div>
        <div class="text-[12px] mt-2" v-if="data.general_creative.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Language:</span
          >
          <span class="text-gray-500">{{
            data.general_creative.extra_data.language
              ? data.general_creative.extra_data.language
              : "-"
          }}</span>
        </div>
        <div class="text-[12px] mt-2" v-if="data.general_creative.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Background Sound ID:</span
          >
          <span class="text-gray-500">{{
            data.general_creative.extra_data.background_sound
              ? data.general_creative.extra_data.background_sound.id
              : "-"
          }}</span>
        </div>
        <div class="text-[12px] mt-2" v-if="data.general_creative.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Background Sound URL:</span
          >
          <a
            class="text-gray-500"
            :href="
              data.general_creative.extra_data.background_sound
                ? data.general_creative.extra_data.background_sound.sound_url
                : '#'
            "
            target="_blank"
            >{{
              data.general_creative.extra_data.background_sound
                ? data.general_creative.extra_data.background_sound.sound_url
                : "-"
            }}</a
          >
        </div>
        <div class="text-[12px] mt-2" v-if="data.general_creative.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Background Sound Tags:</span
          >
          <span class="text-gray-500">{{
            data.general_creative.extra_data.background_sound &&
            data.general_creative.extra_data.background_sound.tags
              ? data.general_creative.extra_data.background_sound.tags.join(
                  ", "
                )
              : "-"
          }}</span>
        </div>
        <div class="text-[12px] mt-2" v-if="data.general_creative.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Reaction Sound ID:</span
          >
          <span class="text-gray-500">{{
            data.general_creative.extra_data.reaction_sound
              ? data.general_creative.extra_data.reaction_sound.id
              : "-"
          }}</span>
        </div>
        <div class="text-[12px] mt-2" v-if="data.general_creative.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Reaction Sound URL:</span
          >
          <a
            :href="
              data.general_creative.extra_data.reaction_sound
                ? data.general_creative.extra_data.reaction_sound.sound_url
                : '#'
            "
            target="_blank"
            class="text-gray-500"
            >{{
              data.general_creative.extra_data.reaction_sound
                ? data.general_creative.extra_data.reaction_sound.sound_url
                : "-"
            }}</a
          >
        </div>
        <div class="text-[12px] mt-2" v-if="data.general_creative.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Reaction Sound Tags:</span
          >
          <span class="text-gray-500">{{
            data.general_creative.extra_data.reaction_sound
              ? data.general_creative.extra_data.reaction_sound.tags.join(", ")
              : "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManageAssetsView",
  data() {
    return {
      data: null,
      loading: false,
      crumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: "/",
          exact: true,
        },
        {
          text: "Manage Creatives",
          disabled: false,
          to: "/act/manage-assets",
          exact: true,
        },
        {
          text: "Combination Detail",
          disabled: true,
        },
      ],
      show_edit_asset: false,
      asset_data: null,
    };
  },
  methods: {
    fetchAssetDetail() {
      this.loading = true;
      this.$api
        .get(`act/general-creatives/${this.$route.params.id}`)
        .then((response) => {
          this.data = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    computedOptions(url) {
      return {
        sources: [
          {
            type: "video/mp4",
            src: url,
          },
        ],
        language: "en",
        width: "100%",
        height: "220px",
      };
    },
    fetchCombinationDetail() {
      this.loading = true;
      this.$api
        .get(`acg/tasks/${this.$route.params.id}`)
        .then((response) => {
          this.data = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
  },
  mounted() {
    this.fetchCombinationDetail();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/sass/variables.scss";

::v-deep .v-breadcrumbs {
  padding: 0;
  li {
    font-size: 12px !important;
    font-weight: 600;
  }
}
::v-deep .custom-pointer {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
::v-deep .video-js {
  width: 100% !important;
}
.colorbox {
  height: 80px;
  width: 80px;
  display: inline-block;
  border-radius: 4px;
}

pre {
  font-family: $log-font-family;
}
</style>
