v
<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card>
        <v-card-title class="text-h5"> Create Point of View </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <h1 class="font-semibold text-gray-800">1. Title</h1>

            <div class="mt-4">
              <v-text-field
                placeholder="Title"
                outlined
                dense
                hide-details
                v-model="title"
                class="text-[12px]"
              ></v-text-field>
            </div>
          </div>

          <div class="mt-5">
            <h1 class="font-semibold text-gray-800">2. Type</h1>

            <div class="mt-4">
              <v-select
                hide-details
                class="text-[12px]"
                :items="types"
                label="Select Type"
                v-model="point_type"
                dense
                outlined
              ></v-select>
            </div>
          </div>

          <div class="mt-5">
            <h1 class="font-semibold text-gray-800">3. File</h1>

            <div class="mt-4">
              <v-file-input
                show-size
                accept="image/png"
                label="Choose File"
                class="text-[12px]"
                outlined
                dense
                hide-details
                v-model="pointer_file"
              >
              </v-file-input>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text> Cancel </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!title || !point_type || !pointer_file"
              @click="submitForm"
              text
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      title: null,
      point_type: null,
      types: ["Photo", "Cartoon"],
      pointer_file: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.result = null;
      }
    },
  },
  methods: {
    submitForm() {
      const requestData = {
        title: this.title,
        point_type: this.point_type,
        pointer_file: this.pointer_file,
      };
      this.$emit("submitted", requestData);
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
