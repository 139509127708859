<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Update Store Page</h1>
      <div>
        <v-select
          hide-details
          class="text-[13px]"
          item-text="game_name"
          item-value="id"
          label="Choose a store page"
          outlined
          dense
          :loading="input_loading"
          :disabled="input_loading"
          :items="storePages"
          v-model="selectedStorePage"
          return-object
        ></v-select>
      </div>
      <div class="mt-5" v-if="selectedStorePage && selectedStorePage.id">
        <div>
          <div>
            <v-autocomplete
              v-model="gaia_game_id"
              :items="gaia_game_list"
              placeholder="Pick a Gaia Game"
              item-text="title"
              item-value="id"
              :loading="input_loading"
              :disabled="input_loading"
              outlined
              auto-select-first
              clearable
              dense
              class="text-[12px]"
            >
            </v-autocomplete>
          </div>
          <div class="mt-2">
            <v-text-field
              label="Subtitle"
              outlined
              v-model="ios_subtitle"
              class="text-[13px]"
              maxlength="25"
              counter
              dense
              :loading="input_loading"
              :disabled="input_loading"
              :rules="[rules.required]"
            ></v-text-field>
          </div>
          <div class="mt-2">
            <v-textarea
              label="Long Description"
              outlined
              dense
              v-model="long_desc"
              class="text-[13px]"
              maxlength="500"
              :loading="input_loading"
              :disabled="input_loading"
              :rules="[rules.required]"
              counter
            ></v-textarea>
          </div>
          <div v-if="!base_icon">
            <v-file-input
              v-model="icon"
              label="Select an icon"
              outlined
              :show-size="1000"
              dense
              hide-details
              prepend-icon=""
              accept="image/*"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
          <div class="mt-2" v-if="icon">
            <div class="mt-2">
              <div class="image-section inline-block">
                <div @click="icon = null">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="delete-section hover:bg-gray-200 rounded-full transition duration-200"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-close</v-icon>
                      </div>
                    </template>
                    <span class="text-[12px]">Click to remove</span>
                  </v-tooltip>
                </div>
                <img
                  class="rounded"
                  height="160"
                  width="160"
                  :src="computedSrc(icon)"
                />
              </div>
            </div>
          </div>
          <div class="mt-2" v-if="!icon && base_icon">
            <div class="mt-0">
              <div class="image-section inline-block">
                <div @click="base_icon = null">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="delete-section hover:bg-gray-200 rounded-full transition duration-200"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-close</v-icon>
                      </div>
                    </template>
                    <span class="text-[12px]">Click to remove</span>
                  </v-tooltip>
                </div>
                <img
                  class="rounded"
                  height="160"
                  width="160"
                  :src="base_icon"
                />
              </div>
            </div>
          </div>
          <div class="mt-4">
            <v-file-input
              v-model="screenshots"
              label="Add More Screenshots"
              outlined
              dense
              multiple
              :show-size="1000"
              prepend-icon=""
              counter
              accept="image/*"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary" dark label small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
          <div v-if="screenshots.length > 0">
            <div>
              <hooper style="height: auto" :settings="hooperSettings">
                <slide
                  v-for="(image, index) in screenshots"
                  :key="index"
                  style="height: 100%"
                >
                  <div class="p-4 h-full main-section">
                    <div class="image-section">
                      <div @click="removeFromScreenshot(image)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="delete-section hover:bg-gray-200 rounded-full transition duration-200"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-close</v-icon>
                            </div>
                          </template>
                          <span class="text-[12px]">Click to remove</span>
                        </v-tooltip>
                      </div>
                      <img height="270" :src="computedSrc(image)" />
                    </div>
                  </div>
                </slide>

                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
            </div>
          </div>

          <div v-if="base_screenshots.length > 0">
            <h1 class="text-[13px] font-semibold mb-4">All Screenshots:</h1>
            <div>
              <hooper style="height: auto" :settings="hooperSettings2">
                <slide
                  v-for="(image, index) in base_screenshots"
                  :key="index"
                  style="height: 100%"
                >
                  <div class="p-4 h-full main-section">
                    <div class="image-section">
                      <div @click="removeFromScreenshot(image)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="delete-section hover:bg-gray-200 rounded-full transition duration-200"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-close</v-icon>
                            </div>
                          </template>
                          <span class="text-[12px]">Click to remove</span>
                        </v-tooltip>
                      </div>
                      <img :src="image" />
                    </div>
                  </div>
                </slide>
                <slide
                  v-for="(image, index) in screenshots"
                  :key="index"
                  style="height: 100%"
                >
                  <div class="p-4 h-full main-section">
                    <div class="image-section">
                      <div @click="removeFromScreenshot(image)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="delete-section hover:bg-gray-200 rounded-full transition duration-200"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-close</v-icon>
                            </div>
                          </template>
                          <span class="text-[12px]">Click to remove</span>
                        </v-tooltip>
                      </div>
                      <img height="270" :src="computedSrc(image)" />
                    </div>
                  </div>
                </slide>

                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between mt-4">
          <div>
            <v-radio-group row v-model="is_active">
              <v-radio color="error" :label="`Pasif`" :value="false"></v-radio>
              <v-radio color="success" :label="`Aktif`" :value="true"></v-radio>
            </v-radio-group>
          </div>
          <div>
            <!-- <v-btn
              color="error"
              class="mr-4"
              :loading="delete_loading"
              :disabled="delete_loading"
              @click="deleteStorePage"
            >
              Delete
            </v-btn> -->
            <v-menu
              v-model="menu"
              left
              offset-x
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="delete_loading"
                  :disabled="delete_loading"
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4"
                >
                  Delete
                </v-btn>
              </template>

              <v-card>
                <div class="p-4 text-[12px] font-semibold">
                  This operation will delete this store page.
                </div>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    style="font-size: 11px; font-weight: 600"
                    text
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    style="font-size: 11px; font-weight: 600"
                    color="primary"
                    text
                    @click="deleteStorePage"
                  >
                    Continue
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-btn
              :disabled="isBtnDisabled || loading"
              color="primary"
              @click="updateStorePage"
              :loading="loading"
            >
              Update
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "CreateStorePage",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      ios_subtitle: "",
      long_desc: "",
      icon: null,
      base_icon: null,
      base_screenshots: [],
      gaia_game_list: [],
      gaia_game_id: null,
      screenshots: [],
      hooperSettings: {
        wheelControl: false,
        itemsToShow: 3,
        breakpoints: {
          1200: {
            centerMode: false,
            itemsToShow: 12,
          },
          992: {
            centerMode: false,
            itemsToShow: 6,
          },
          768: {
            centerMode: false,
            itemsToShow: 4,
          },
        },
      },
      hooperSettings2: {
        wheelControl: false,
        itemsToShow: 3,
        breakpoints: {
          1200: {
            centerMode: false,
            itemsToShow: 8,
          },
          992: {
            centerMode: false,
            itemsToShow: 6,
          },
          768: {
            centerMode: false,
            itemsToShow: 4,
          },
        },
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      storePages: null,
      selectedStorePage: null,
      selectedPageData: null,
      input_loading: false,
      is_active: null,
      loading: false,
      delete_loading: false,
      menu: false,
    };
  },
  methods: {
    computedSrc(image) {
      return URL.createObjectURL(image);
    },
    updateStorePage() {
      if (!this.isBtnDisabled) {
        this.loading = true;
        let formData = new FormData();
        if (this.screenshots.length > 0) {
          for (let i = 0; i < this.screenshots.length; i++) {
            formData.append("screenshots", this.screenshots[i]);
          }
        }
        formData.append("gaia_game_id", this.gaia_game_id);
        formData.append("ios_subtitle", this.ios_subtitle);
        formData.append("long_desc", this.long_desc);
        formData.append("base_icon", this.base_icon);

        if (this.icon) {
          formData.append("icon", this.icon);
        }

        formData.append(
          "base_screenshots",
          JSON.stringify(this.base_screenshots)
        );
        formData.append("platforms", JSON.stringify(["ios"]));

        this.$api
          .put(
            `ccc/tiktok/store-page/${this.selectedStorePage.id}/update`,
            formData
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                type: "success_",
                group: "success_",
                text: response.data.detail,
              });
              this.ios_subtitle = "";
              this.long_desc = "";
              this.icon = null;
              this.base_icon = null;
              this.base_screenshots = [];
              this.gaia_game_list = [];
              this.gaia_game_id = null;
              this.screenshots = [];
              this.selectedStorePage = null;
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    fetchStorePages() {
      this.$api
        .get("ccc/tiktok/store-page/all")
        .then((response) => {
          this.storePages = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeFromScreenshot(src) {
      if (this.screenshots.includes(src)) {
        this.screenshots = this.screenshots.filter((x) => x !== src);
      }
      if (this.base_screenshots.includes(src)) {
        this.base_screenshots = this.base_screenshots.filter((x) => x !== src);
      }
    },
    fetchGaiaGames(id) {
      this.$api
        .get("games/all")
        .then((response) => {
          if (response.status === 200) {
            this.gaia_game_list = response.data;
            console.log(response.data);
            if (response.data.length > 0) {
              response.data.forEach((element) => {
                if (id === element.id) {
                  this.gaia_game_id = id;
                }
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteStorePage() {
      this.delete_loading = true;
      this.menu = false;
      this.$api
        .delete(`ccc/tiktok/store-page/${this.selectedStorePage.id}/delete`)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: response.data.detail,
            });
            this.ios_subtitle = "";
            this.long_desc = "";
            this.icon = null;
            this.base_icon = null;
            this.base_screenshots = [];
            this.gaia_game_list = [];
            this.gaia_game_id = null;
            this.screenshots = [];
            this.selectedStorePage = null;
            this.fetchStorePages();
          }
          this.delete_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.delete_loading = false;
        });
    },
  },
  watch: {
    icon(newValue) {
      if (newValue) {
        var img = new Image();
        const url = URL.createObjectURL(newValue);

        const printNotify = (message) => {
          this.$notify({
            text: message,
            group: "error_",
            type: "error_",
          });
        };

        const reset = () => {
          this.icon = null;
        };

        img.onload = function () {
          var height = img.height;
          var width = img.width;

          if (height % width !== 0) {
            printNotify("Icon dimensions must be a square.");
            reset();
          }

          if (height < 256 || width < 256) {
            printNotify("Icon size can not be less than 256 x 256.");
            reset();
          }
        };

        img.src = url;
      }
    },
    screenshots: {
      handler(newValue, oldValue) {
        if (newValue.length > 0) {
          const printNotify = (message) => {
            this.$notify({
              text: message,
              group: "error_",
              type: "error_",
              duration: 8000,
            });
          };

          const reset = () => {
            this.screenshots = oldValue;
          };

          for (let i = 0; i < newValue.length; i++) {
            const image = newValue[i];

            var img = new Image();
            const url = URL.createObjectURL(image);
            const ratio = 9 / 16;
            img.src = url;

            img.onload = function () {
              var height = this.height;
              var width = this.width;
              const img_ratio = width / height;
              if (img_ratio !== ratio) {
                printNotify(
                  `Screenshot dimensions must be 9w x 16h for ${image.name}`
                );
                reset();
              }

              if (width < 720 || height < 1280) {
                printNotify(
                  `Screenshot size must be at least 720 x 1080. ${image.name}`
                );
                reset();
              }
            };
          }
        }
      },
    },
    selectedStorePage(newValue) {
      if (newValue) {
        this.input_loading = true;
        this.$api
          .get(`ccc/tiktok/store-page/${newValue.id}`)
          .then((response) => {
            if (response.status === 200) {
              this.game_name = response.data.game_name;
              this.ios_subtitle = response.data.ios_subtitle;
              this.long_desc = response.data.long_desc;
              this.base_icon = response.data.icon;
              this.is_active = response.data.is_active;

              if (response.data.screenshots) {
                this.base_screenshots = JSON.parse(response.data.screenshots);
              }

              this.fetchGaiaGames(newValue.game_id);

              this.input_loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.input_loading = false;
          });
      }
    },
    is_active(newVal, oldValue) {
      if (oldValue !== null) {
        this.input_loading = true;
        this.$api
          .put(`ccc/tiktok/store-page/${this.selectedStorePage.id}/status`, {
            status: newVal,
          })
          .then((response) => {
            this.ios_subtitle = response.data.ios_subtitle;
            this.long_desc = response.data.long_desc;
            this.base_icon = response.data.icon;
            this.is_active = response.data.is_active;

            if (response.data.screenshots) {
              this.base_screenshots = JSON.parse(response.data.screenshots);
            }

            this.$notify({
              type: "success_",
              group: "success_",
              text: `Store page is now ${newVal ? "active" : "passive"}.`,
            });

            this.input_loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.input_loading = false;
          });
      }
    },
  },
  computed: {
    isBtnDisabled() {
      if (!this.icon && !this.base_icon) {
        return true;
      }
      if ([...this.base_screenshots, ...this.screenshots].length < 4) {
        return true;
      }
      if (!this.gaia_game_id) {
        return true;
      }
      if (!this.ios_subtitle) {
        return true;
      }
      if (!this.long_desc) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.fetchStorePages();
  },
};
</script>

<style lang="scss" scoped>
::v-deep label {
  font-size: 13px !important;
}

::v-deep
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #e0e0e0;
}

.icon-upload {
  display: inline-block;
}

.image-section {
  position: relative;
  .delete-section {
    height: 45px;
    width: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
