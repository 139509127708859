export default {
  state: {
    selected_network: null,
    current_page: null,
    sortBy: null,
    sortDesc: null,
    limit: null,
    filterDates: null,
  },
  getters: {
    getSelectedNetwork(state) {
      return state.selected_network;
    },
    getCurrentPage(state) {
      return state.current_page;
    },
    getLimit(state) {
      return state.limit;
    },
    getFilterDates(state) {
      return state.filterDates;
    },
    getSortDesc(state) {
      return state.sortDesc;
    },
    getSortBy(state) {
      return state.sortBy;
    },
  },
  mutations: {
    handleSelectedNetwork(state, payload) {
      state.selected_network = payload;
    },
    handleCurrentPage(state, payload) {
      state.current_page = payload;
    },
    handleLimit(state, payload) {
      state.limit = payload;
    },
    handleFilterDates(state, payload) {
      state.filterDates = payload;
    },
    handleSortBy(state, payload) {
      state.sortBy = payload;
    },
    handleSortDesc(state, payload) {
      state.sortDesc = payload;
    },
  },
  actions: {
    setSelectedNetwork({ commit }, payload) {
      commit("handleSelectedNetwork", payload);
    },
    setCurrentPage({ commit }, payload) {
      commit("handleCurrentPage", payload);
    },
    setLimit({ commit }, payload) {
      commit("handleLimit", payload);
    },
    setFilterDates({ commit }, payload) {
      commit("handleFilterDates", payload);
    },
    setSortBy({ commit }, payload) {
      commit("handleSortBy", payload);
    },
    setSortDesc({ commit }, payload) {
      commit("handleSortDesc", payload);
    },
  },
};
