<template>
  <div>
    <v-dialog v-model="visible" width="572">
      <v-card>
        <v-card-title class="text-h5"> Event Details </v-card-title>

        <div v-if="data" class="mt-2 p-4">
          <div class="mt-2.5">
            <v-text-field
              label="Event Name"
              outlined
              :value="data.name"
              dense
              hide-details
              class="text-[13px]"
              disabled
            ></v-text-field>
          </div>

          <div class="mt-2.5">
            <v-text-field
              label="Alias"
              outlined
              :value="data.alias"
              dense
              hide-details
              class="text-[13px]"
              disabled
            ></v-text-field>
          </div>

          <div class="mt-2.5">
            <v-textarea
              label="Description"
              :value="data.description"
              dense
              outlined
              auto-grow
              :rows="3"
              hide-details
              class="text-[13px]"
              disabled
            ></v-textarea>
          </div>

          <div class="mt-2.5">
            <v-text-field
              label="Type"
              outlined
              :value="data.type"
              dense
              hide-details
              class="text-[13px]"
              disabled
            ></v-text-field>
          </div>

          <div class="mt-2.5">
            <v-text-field
              label="Theme"
              outlined
              :value="data.theme"
              item-text="title"
              item-value="id"
              dense
              hide-details
              class="text-[13px]"
              disabled
            ></v-text-field>
          </div>

          <div class="flex items-center gap-2 mt-2.5">
            <v-text-field
              label="ID"
              outlined
              disabled
              :value="data.id"
              dense
              hide-details
              class="text-[13px]"
            ></v-text-field>
          </div>

          <div class="flex items-center gap-2 mt-2.5">
            <div class="w-full">
              <v-text-field
                label="Min. Version"
                outlined
                :value="data.min_version"
                dense
                hide-details
                class="text-[13px]"
                type="number"
                :min="0"
                disabled
              ></v-text-field>
            </div>
          </div>

          <div class="mt-2.5">
            <h1 class="font-semibold text-[13px] mb-1.5">Payload:</h1>
            <JsonEditorVue
              :mainMenuBar="false"
              :navigationBar="false"
              :mode="'text'"
              :value="data.payload"
              readOnly
            />
          </div>
        </div>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="error" text @click="visible = false"> Cancel </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import JsonEditorVue from "json-editor-vue";

export default {
  data() {
    return {
      visible: false,
      eventData: null,
      loading: false,
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
    event_id: {
      type: String,
      default: null,
    },
    eventTypes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  components: {
    JsonEditorVue,
  },
};
</script>

<style lang="scss"></style>
