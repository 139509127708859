var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-white p-6"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('h1',{staticClass:"font-semibold text-[18px]"},[_vm._v("Live Events")]),_c('div',[_c('v-btn',{attrs:{"ripple":false,"color":"secondary","depressed":"","disabled":!_vm.selected_game || !_vm.selected_game.applovin_android_package_name},on:{"click":_vm.clickCreateEventBtn}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create Live Event ")],1)],1)]),_c('div',{staticClass:"mt-5 flex items-center w-full"},[_c('div',{staticClass:"w-[270px]"},[_c('v-autocomplete',{staticClass:"text-[12px]",attrs:{"clearable":"","flat":"","label":"Choose Game","dense":"","outlined":"","hide-details":"","items":_vm.games,"item-text":"title","return-object":""},model:{value:(_vm.selected_game),callback:function ($$v) {_vm.selected_game=$$v},expression:"selected_game"}})],1),_c('div',{staticClass:"pl-4 flex items-center"},[_c('v-checkbox',{staticStyle:{"margin":"0"},attrs:{"dense":"","label":"Include Archive","hide-details":""},model:{value:(_vm.include_archive),callback:function ($$v) {_vm.include_archive=$$v},expression:"include_archive"}})],1)]),_c('div',{staticClass:"mt-3"},[_c('v-data-table',{attrs:{"headers":_vm.eventTableHeaders,"items":_vm.include_archive ? _vm.events : _vm.events.filter((x) => !x.removed),"loading":_vm.tableLoading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.id`,fn:function({ item }){return [_c('span',{staticClass:"font-semibold text-[11px]",class:{
              'text-gray-400': item.removed,
            }},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:`item.name`,fn:function({ item }){return [_c('span',{staticClass:"font-semibold text-[11px]",class:{
              'text-gray-400': item.removed,
            }},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.theme_name`,fn:function({ item }){return [_c('span',{staticClass:"font-semibold text-[11px]",class:{
              'text-gray-400': item.removed,
            }},[_vm._v(" "+_vm._s(item.theme_name)+" ")])]}},{key:`item.alias`,fn:function({ item }){return [_c('span',{staticClass:"font-semibold text-[11px]",class:{
              'text-gray-400': item.removed,
            }},[_vm._v(" "+_vm._s(item.alias)+" ")])]}},{key:`item.description`,fn:function({ item }){return [_c('span',{staticClass:"font-semibold text-[11px]",class:{
              'text-gray-400': item.removed,
            }},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:`item.type_name`,fn:function({ item }){return [_c('div',{staticClass:"inline-block"},[(!item.removed)?_c('div',{staticClass:"font-semibold text-[11px] px-1.5 py-1 flex items-center",class:_vm.computedLiveOpsType[item.type.slug].color},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"color":"inherit"},attrs:{"small":""}},[_vm._v("mdi-"+_vm._s(_vm.computedLiveOpsType[item.type.slug].icon))]),_vm._v(" "+_vm._s(item.type_name)+" ")],1):_c('div',{staticClass:"font-semibold text-[11px] px-1.5 py-1 flex items-center text-gray-400"},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"color":"inherit"},attrs:{"small":""}},[_vm._v("mdi-"+_vm._s(_vm.computedLiveOpsType[item.type.slug].icon))]),_vm._v(" "+_vm._s(item.type_name)+" ")],1)])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"flex items-center"},[_c('v-btn',{staticClass:"text-[11px] font-semibold",attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.showEventUpdateWarningDialog(
                  'warningFirst',
                  item,
                  'Editing an event can have serious consequences. If you are sure that the event you want to edit is one that has not yet been published into production, you can continue. Otherwise, it is recommended to only change the event name or description. If fields such as type, theme, payload are to be changed, it is safer to create a new event and use it.'
                )}}},[_vm._v(" Edit ")]),(!item.removed)?_c('v-btn',{staticClass:"text-[11px] font-semibold",attrs:{"color":"warning","text":"","small":""},on:{"click":function($event){return _vm.deleteEventBtn(item)}}},[_vm._v(" Archive ")]):(item.removed)?_c('v-btn',{staticClass:"text-[11px] font-semibold",attrs:{"color":"success","text":"","small":""},on:{"click":function($event){return _vm.unarchiveEvent(item)}}},[_vm._v(" Unarchive ")]):_vm._e()],1)]}}],null,true)})],1)]),_c('UpdateGameEventDialog',{attrs:{"show":_vm.showInsertUpdateDialog,"data":_vm.showInsertUpdateDialogData,"mode":_vm.showInsertUpdateDialogMode,"games":_vm.games,"eventTypes":_vm.eventTypes},on:{"closed":_vm.handleOnUpdateDialogOnClose,"update":function($event){return _vm.showEventUpdateWarningDialog(
        $event,
        _vm.item,
        'In order for the edit to affect the calendars, a new deployment must be sent to the relevant environments. Otherwise, it will not be possible to serve the edited event because the calendar is kept statically.'
      )},"insert":_vm.handleOnEventInsert}}),_c('DeleteEventDialog',{attrs:{"show":_vm.showDeleteEventConfirmation,"eventData":_vm.deleteEventConfirmationData},on:{"closed":_vm.closeDeleteEventDialog,"submit":_vm.handleOnEventDelete}}),_c('ConfirmationDialogEventUpdate',{attrs:{"show":_vm.showEventUpdateWarning,"message":_vm.confirmationDialogMessage,"trigger":_vm.confirmationDialogTrigger,"title":'Warning!'},on:{"closed":function($event){_vm.showEventUpdateWarning = false},"submit":_vm.handleOnConfirmationSubmit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }