<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Summary </v-card-title>

        <v-card-text>
          <div>
            <div>
              <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
                Selected creatives will be
                <span class="text-lime-600">added</span> to these offers:
              </h1>
              <div class="mt-2">
                <div v-for="item in data" :key="item.id">
                  <div
                    v-if="
                      item.selectedCreativesForAdding.filter((x) => x.selected)
                        .length
                    "
                    class="border rounded-[3px] border-gray-200 p-2.5 mb-2"
                  >
                    <div class="font-semibold text-[11px] text-sky-600 mb-2.5">
                      {{ item.campaign_name }} (Campaign) ->
                      {{ item.offer_name }} (Offer)
                    </div>
                    <v-chip
                      small
                      color="default"
                      class="mr-1.5"
                      v-for="(
                        ad, adIdx
                      ) in item.selectedCreativesForAdding.filter(
                        (x) => x.selected
                      )"
                      :key="adIdx"
                    >
                      <span class="text-[11px] font-semibold">
                        {{
                          ad.is_playable && ad.selected_playable
                            ? `${ad.ad_name} (Video + Playable)`
                            : ad.ad_name
                        }}
                      </span>
                    </v-chip>
                  </div>
                </div>
              </div>

              <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
                Selected videos will be
                <span class="text-rose-600">deleted</span> from these offers:
              </h1>

              <div class="mt-2">
                <div v-for="item in data" :key="item.id">
                  <div
                    class="border rounded-[3px] border-gray-200 p-2.5 mb-2"
                    v-if="
                      item.creativesModified.videos.filter((x) => !x.selected)
                        .length
                    "
                  >
                    <div class="font-semibold text-[11px] text-sky-600 mb-2.5">
                      {{ item.campaign_name }} (Campaign) ->
                      {{ item.offer_name }} (Offer)
                    </div>
                    <v-chip
                      small
                      color="default"
                      class="mr-1.5"
                      v-for="(
                        video, videoIdx
                      ) in item.creativesModified.videos.filter(
                        (x) => !x.selected
                      )"
                      :key="videoIdx"
                    >
                      <span class="text-[11px] font-semibold">
                        {{ video.creative_name }}
                      </span>
                    </v-chip>
                  </div>
                </div>
              </div>

              <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
                Selected playables will be
                <span class="text-rose-600">deleted</span> from these offers:
              </h1>

              <div class="mt-2">
                <div v-for="item in data" :key="item.id">
                  <div
                    class="border rounded-[3px] border-gray-200 p-2.5 mb-2"
                    v-if="
                      item.creativesModified.playables.filter(
                        (x) => !x.selected
                      ).length
                    "
                  >
                    <div class="font-semibold text-[11px] text-sky-600 mb-2.5">
                      {{ item.campaign_name }} (Campaign) ->
                      {{ item.offer_name }} (Offer)
                    </div>
                    <v-chip
                      small
                      color="default"
                      class="mr-1.5"
                      v-for="(
                        playable, playableIdx
                      ) in item.creativesModified.playables.filter(
                        (x) => !x.selected
                      )"
                      :key="playableIdx"
                    >
                      <span class="text-[11px] font-semibold">
                        {{ playable.creative_name }}
                      </span>
                    </v-chip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submitSummaryForTasks">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    submitSummaryForTasks() {
      this.$emit("submitted");
    },
  },
};
</script>

<style lang="scss" scoped></style>
