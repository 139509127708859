<template>
  <div>
    <v-dialog persistent v-model="visible" width="600">
      <v-card v-if="data">
        <v-card-title class="text-h5"> Result </v-card-title>

        <v-card-text>
          <div v-if="!loading">
            <div
              v-if="result.detail && result.detail.length > 0"
              class="p-4 mt-4 result-container bg-gray-100 rounded text-[12px]"
            >
              <div
                v-for="(item, index) in result.detail"
                :key="index"
                class="mb-2 text-gray-800"
              >
                <div v-if="item.prep_text" class="font-semibold mb-1">
                  {{ item.prep_text }}
                </div>
                <span
                  class="green--text text--darken-1 font-semibold inline-block mr-2"
                  v-if="item.success"
                  >Success :</span
                >
                <span
                  class="red--text text--darken-1 font-semibold inline-block mr-2"
                  v-else
                  >Failed :</span
                >
                {{ item.detail }}
              </div>
            </div>
          </div>
          <div v-if="loading">
            <div class="flex items-center justify-center flex-col pt-6">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <span class="mt-4 font-semibold"
                >The campaign is being created. Please wait...</span
              >
              <span class="mt-4 font-semibold"
                >Calculated time : {{ calculatedTime }}</span
              >
              <span class="text-[11px] mt-4 text-red-700"
                >It may takes longer/shorter according to your upload
                speed.</span
              >
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            text
            @click="visible = false"
          >
            Kapat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      result: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;

        if (newValue) {
          this.createCampaign();
        }
      },
      immediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  computed: {
    calculatedTime() {
      let time = this.data.videos.length * 115;
      let minutes = Math.floor(time / 60);

      return `${minutes} minutes, ${time - minutes * 60} seconds`;
    },
  },
  methods: {
    createCampaign() {
      if (this.data.videos && this.data.videos.length > 0) {
        this.loading = true;
        let formData = new FormData();
        for (let i = 0; i < this.data.videos.length; i++) {
          formData.append("videos", this.data.videos[i]);
        }
        formData.append("store_page_id", this.data.store_page_id);
        formData.append("campaign_title", this.data.campaign_title);
        formData.append("adgroup_title", this.data.campaign_title);
        formData.append("budget", this.data.budget);

        this.$api
          .post("ccc/tiktok/campaigns/create", formData)
          .then((response) => {
            this.result = response.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/sass/variables.scss";
.result-container {
  font-family: $log-font-family;
}
</style>
