var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.surfaceValue)?_c('div',[_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Edit Surface Resource ")]),_c('v-card-text',_vm._l((Object.keys(_vm.surfaceValue)),function(key,index){return _c('div',{key:index,staticClass:"mt-3"},[_c('h1',{staticClass:"font-semibold text-[12px] text-sky-800"},[_vm._v(" Surface - "+_vm._s(key)+" ")]),_c('div',{staticClass:"grid grid-cols-5 gap-2 mt-2"},_vm._l((Object.keys(_vm.surfaceValue[key])),function(subKey,subIndex){return _c('div',{key:subIndex},[_c('div',[(
                    [
                      'moneySpawnCount',
                      'moneyValue',
                      'spawnChance',
                      'amount',
                    ].includes(subKey)
                  )?_c('v-text-field',{staticClass:"text-[12px]",attrs:{"dense":"","label":subKey,"outlined":"","hide-details":""},model:{value:(_vm.surfaceValue[key][subKey]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[key], subKey, $$v)},expression:"surfaceValue[key][subKey]"}}):_vm._e()],1),_c('div',[(['moneyTypeToSpawn', 'resourceType'].includes(subKey))?_c('v-select',{staticClass:"text-[12px]",attrs:{"dense":"","label":subKey,"outlined":"","disabled":subKey === 'resourceType',"items":subKey === 'moneyTypeToSpawn'
                      ? _vm.valueStructure['MoneyTypeEnum'][
                          'variablePossibilities'
                        ]
                      : [
                          'Dirt1',
                          'Dirt2',
                          'Dirt3',
                          'Dirt4',
                          'SmallGoldOre',
                          'SmallDiamondOre',
                          'SmallAmberOre',
                          'SmallAmethystOre',
                          'SmallEmeraldOre',
                          'SmallRubyOre',
                          'Gold',
                          'Diamond',
                          'Amber',
                          'Amethyst',
                          'Emerald',
                          'Ruby',
                          'Dummy',
                          'SmallStone',
                        ],"hide-details":""},model:{value:(_vm.surfaceValue[key][subKey]),callback:function ($$v) {_vm.$set(_vm.surfaceValue[key], subKey, $$v)},expression:"surfaceValue[key][subKey]"}}):_vm._e()],1)])}),0)])}),0),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" Submit ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }