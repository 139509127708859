import axios from "axios";
import Vue from "vue";
import Cookies from "js-cookie";
import router from "../router/index";

const mode = process.env.NODE_ENV;

const baseURL =
  mode === "development"
    ? "http://localhost:8000/api/"
    : "https://gaia-api.apps.com.tr/api/";

const base = axios.create({
  baseURL,
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  },
});

const access_token = Cookies.get("gaia_sid") || null;

if (access_token) {
  base.defaults.headers.authorization = `Bearer ${Cookies.get("gaia_sid")}`;
}

base.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      router.push({
        name: "login",
      });
    }
    return Promise.reject(error);
  }
);

export default {
  install() {
    Vue.prototype.$api = base;
  },
};
