export default {
  state: {
    tools: null,
  },
  getters: {
    getTools(state) {
      return state.tools;
    },
  },
  mutations: {
    handleSetTools(state, payload) {
      state.tools = payload;
    },
  },
  actions: {
    setTools({ commit }, payload) {
      commit("handleSetTools", payload);
    },
  },
};
