import CampaignCreateView from "@/views/tools/ccc/facebook/CampaignCreateView.vue";

export const facebook_router = [
  {
    path: "/facebook/create-campaign",
    name: "fb-campaign-create-view",
    component: CampaignCreateView,
    meta: {
      title: "Facebook - Create New Campaign",
    },
  },
];
