<!-- eslint-disable -->
<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">
        Burcha - Upload Google Image
      </h1>

      <div class="mt-5">
        <div>
          <v-autocomplete
            hide-details
            class="text-[13px]"
            label="Select Game"
            outlined
            clearable
            dense
            :items="games.filter((x) => x.google_adc_id)"
            v-model="selected_game"
            :loading="loadingGames"
            item-text="title"
            return-object
          >
          </v-autocomplete>
        </div>
        <div v-if="selected_game">
          <div class="mt-3">
            <v-file-input
              :value="input_images"
              color="primary"
              label="Your Images"
              multiple
              prepend-icon="mdi-paperclip"
              outlined
              accept="image/*"
              :show-size="1000"
              hide-details
              dense
              @change="handleInputOnChange"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary" dark label x-small>
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
                  +{{ input_images.length - 2 }}
                  File(s)
                </span>
              </template>
            </v-file-input>
          </div>
          <div v-if="input_images_modified.length" class="mt-3">
            <v-divider class="mb-3"></v-divider>
            <v-tooltip
              bottom
              v-for="(image, imageIdx) in input_images_modified"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="p-2 hover:bg-gray-100 rounded-[3px] inline-block"
                  :key="imageIdx"
                  v-bind="attrs"
                  v-on="on"
                >
                  <div
                    v-for="(variant, variantIdx) in image.variants.filter(
                      (x) => x.isIncluded
                    )"
                    class="inline-block p-[3px] cursor-pointer"
                    :key="variantIdx"
                    @click="showImageCropPreview(imageIdx, variantIdx)"
                  >
                    <img
                      class="rounded-[3px]"
                      :src="variant.image"
                      :height="112"
                      :width="112 * variant.ratio"
                    />
                    <div
                      class="text-[10px] font-semibold text-gray-500 mt-1 w-full text-center"
                    >
                      {{ variant.title }}
                    </div>
                  </div>
                </div>
              </template>
              <span>{{ image.baseImage }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>

      <div class="mt-5 flex items-center justify-end">
        <v-btn
          color="primary"
          depressed
          class="font-semibold"
          :disabled="!selected_game || !numberOfIncludedVariants"
          :loading="btnLoading"
          @click="uploadImages"
        >
          Submit
        </v-btn>
      </div>
    </div>

    <ImageCropperDialog
      :show="showImageCropper"
      :data="imageCropperData"
      :selectedVariantIdx="selectedVariantIdx"
      @closed="handleOnCroppedDialogClose"
      @submit="handleOnCroppedDialogSubmit"
    />
  </div>
</template>

<script>
import ImageCropperDialog from "@/components/tools/campaign-manager/networks/google/ImageCropperDialog.vue";

export default {
  data() {
    return {
      btnLoading: false,
      games: [],
      selected_game: null,
      input_images: [],
      input_images_modified: [],
      showImageCropper: false,
      imageCropperData: null,
      selectedVariantIdx: null,
      selectedImageIdx: null,
      loadingGames: false,
    };
  },
  methods: {
    async uploadImages() {
      let formData = new FormData();
      const variantsWithImages = this.extractVariantsWithImages(
        this.input_images_modified
      );

      for (let i = 0; i < variantsWithImages.length; i++) {
        const file = this.$base64ToFile(
          variantsWithImages[i].image,
          variantsWithImages[i].filename,
          "image/jpeg"
        );

        formData.append("images", file);
      }

      formData.append("game_id", this.selected_game.id);

      try {
        this.btnLoading = true;
        const response = await this.$api.post(
          "campaign-manager/upload-google-images",
          formData
        );
        if (response.status === 200) {
          this.$toast.success("Images are successfully uploaded.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          this.input_images = [];
          this.input_images_modified = [];
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.btnLoading = false;
      }
    },
    extractVariantsWithImages(inputData) {
      const outputArray = [];

      inputData.forEach((item) => {
        item.variants.forEach((variant) => {
          if (variant.isIncluded) {
            outputArray.push({ ...variant });
          }
        });
      });

      return outputArray;
    },
    showImageCropPreview(imageIndex, variantIdx) {
      const image = this.input_images_modified[imageIndex];

      this.selectedImageIdx = imageIndex;
      this.imageCropperData = image;
      this.selectedVariantIdx = variantIdx;
      this.showImageCropper = true;
    },
    handleOnCroppedDialogClose() {
      this.showImageCropper = false;
      this.imageCropperData = null;
      this.selectedVariantIdx = null;
      this.selectedImageIdx = null;
    },
    handleOnCroppedDialogSubmit(data) {
      const includedLength = data.variants.filter((x) => x.isIncluded).length;
      if (includedLength === 0) {
        this.input_images.splice(this.selectedImageIdx, 1);
        this.input_images_modified.splice(this.selectedImageIdx, 1);
        this.handleOnCroppedDialogClose();
        return;
      }

      this.$set(this.input_images_modified, this.selectedImageIdx, data);
      this.handleOnCroppedDialogClose();
    },
    async handleInputOnChange($event) {
      const ratios = [1.91, 1, 0.8];
      this.input_images = $event;
      this.input_images_modified = [];
      var base64URL = null;
      const loadImage = (baseImage) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(baseImage);
          reader.onload = (e) => {
            base64URL = e.target.result;
            const image = new Image();
            image.src = base64URL;
            image.onload = () => {
              resolve(image);
            };
          };
        });
      };
      for (let i = 0; i < $event.length; i++) {
        const baseImage = $event[i];
        const image = await loadImage(baseImage);
        var tempData = {
          baseImage: baseImage.name,
          baseImageData: base64URL,
          baseImageWidth: image.width,
          baseImageHeight: image.height,
          variants: [],
        };
        for (let z = 0; z < ratios.length; z++) {
          const ratio = ratios[z];
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.width / ratio;
          const context = canvas.getContext("2d");
          context.drawImage(
            image,
            0,
            0,
            image.width,
            image.width / ratio,
            0,
            0,
            image.width,
            image.width / ratio
          );
          const previewImage = canvas.toDataURL("image/jpeg");
          var title =
            ratio === 1.91
              ? "1.91:1"
              : ratio === 1
              ? "1:1"
              : ratio === 0.8
              ? "4:5"
              : "-";
          tempData.variants.push({
            title,
            image: previewImage,
            ratio,
            coords: { top: 0, left: 0 },
            isIncluded: true,
            filename: `${baseImage.name.split(".")[0]}_${title
              .replace(":", "_")
              .replace(".", "_")}.jpeg`,
          });
        }
        this.input_images_modified.push(tempData);
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    fetchGames() {
      this.loadingGames = true;
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data;
          this.loadingGames = false;
        })
        .catch((err) => {
          this.errorHandler(err);
          this.loadingGames = false;
        });
    },
  },
  watch: {},
  computed: {
    numberOfIncludedVariants() {
      var length = 0;

      for (let i = 0; i < this.input_images_modified.length; i++) {
        const image = this.input_images_modified[i];
        for (let y = 0; y < image.variants.length; y++) {
          const variant = image.variants[y];
          if (variant.isIncluded) length++;
        }
      }

      return length;
    },
  },
  mounted() {
    this.fetchGames();
  },
  components: { ImageCropperDialog },
};
</script>

<style lang="scss" scoped></style>
