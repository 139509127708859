<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Create Game</h1>
      <div>
        <v-text-field
          label="Game Name"
          outlined
          v-model="game_name"
          hide-details
          maxlength="70"
          class="text-[13px]"
        ></v-text-field>
      </div>
      <div class="mt-4" v-if="game_name && game_name.length >= 3">
        <v-autocomplete
          clearable
          multiple
          outlined
          hide-details
          small-chips
          hide-selected
          deletable-chips
          auto-select-first
          v-model="genres"
          :items="computedGameGenreTags"
          label="Game Genre(s)"
          class="text-[13px]"
        >
        </v-autocomplete>
      </div>
      <div class="mt-4 flex items-center justify-end">
        <v-btn
          :disabled="genres.length === 0"
          color="primary"
          @click="createGame"
        >
          Create
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateGameView",
  data() {
    return {
      game_name: "",
      loading: false,
      genres: [],
      tags: [],
    };
  },
  methods: {
    createGame() {
      if (
        this.game_name &&
        this.game_name.length >= 3 &&
        this.genres.length > 0
      ) {
        this.$api
          .post("ccc/games/create", {
            game_name: this.game_name,
            genres: JSON.stringify(this.genres),
          })
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                type: "success_",
                group: "success_",
                text: "Game successfully added.",
              });
              this.game_name = "";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fetchTags() {
      this.$api
        .get("tags/all")
        .then((response) => {
          this.tags = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    computedGameGenreTags() {
      if (this.tags && this.tags.length > 0) {
        let game_genres = this.tags.some((x) => x.name === "game_genre");

        if (game_genres) {
          let tags = this.tags.filter((x) => x.name === "game_genre")[0];
          if (tags.info) {
            return JSON.parse(tags.info);
          }
        }
        return null;
      }
      return null;
    },
  },
  mounted() {
    this.fetchTags();
  },
};
</script>

<style lang="scss" scoped></style>
