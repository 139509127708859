<template>
  <div v-if="getUserProfile">
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Manage Assets</h1>

      <div class="grid grid-cols-12 mb-5 gap-3">
        <div class="col-span-12 mb-3">
          <v-text-field
            filled
            hide-details
            rounded
            placeholder="Search..."
            class="text-[12px] w-full"
            dense
            v-model="keyword"
          />
        </div>
        <div class="md:col-span-3 col-span-6">
          <v-select
            hide-details
            class="text-[13px] w-full"
            :items="computedConfigs"
            item-text="base_title"
            return-object
            label="Config"
            dense
            v-model="selected_config"
            outlined
          ></v-select>
        </div>
        <div class="md:col-span-3 col-span-6">
          <v-menu left v-model="menu1" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                label="Date Range"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
                v-model="dates"
                class="text-[13px] w-full"
                hide-details
                outlined
                @click:clear="dates = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              @change="menu1 = false"
              range
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- <div>
            <v-select
              hide-details
              class="text-[13px] w-full"
              :items="creative_types"
              label="Creative Type"
              dense
              v-model="selected_creative_type"
              outlined
            ></v-select>
          </div> -->
        <div class="md:col-span-2 col-span-6">
          <v-autocomplete
            hide-details
            class="text-[13px] w-full"
            :items="references"
            label="Language"
            item-text="name"
            item-value="iso_lower"
            dense
            v-model="selectedLanguage"
            outlined
            clearable
          ></v-autocomplete>
        </div>
      </div>

      <div class="flex items-center justify-end">
        <v-btn color="primary" text @click="showMetadataFilterDialog = true">
          Filter by Metadata
        </v-btn>
      </div>

      <div class="mt-5" v-if="selected_config">
        <v-alert
          dense
          dismissible
          prominent
          text
          type="info"
          v-if="numberOfCombinations"
        >
          <div class="m-0 p-0 text-[12px]">
            There are {{ numberOfCombinations }} combinations waiting to be
            tested.
          </div>
        </v-alert>

        <v-data-table
          :show-select="[1, 7].includes(getUserProfile.department.id)"
          v-model="selectedRows"
          :headers="headers"
          :expanded.sync="expanded"
          show-expand
          :items="
            creative_data.items.map((x) => ({
              ...x,
              isSelectable:
                selected_config.type === 'unity'
                  ? x.duration_ms <= 60000
                  : true,
            }))
          "
          :loading="loading"
          loading-text="Please wait..."
          :items-per-page="getLimit ? getLimit : limit"
          :page="getCurrentPage ? getCurrentPage : currentPage"
          :sort-by="getSortBy ? getSortBy : sortBy"
          :sort-desc="getSortDesc ? getSortDesc : sortDesc"
          @pagination="handlePaginationChange"
          @update:sort-by="handleSortBy"
          @update:sort-desc="handleSortDesc"
          :server-items-length="creative_data.count"
          :footer-props="{
            itemsPerPageOptions: [15, 25, 50, -1],
          }"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div
                v-if="selected_config.type.toLowerCase() === 'facebook'"
                class="w-full"
              >
                <div
                  v-for="creative in $sortArrayByProperty(
                    item.facebook_creatives,
                    ['id'],
                    ['desc']
                  )"
                  :key="creative.id"
                  class="w-full"
                >
                  ID: {{ creative.id }}, Title: {{ creative.title }}, Status:
                  {{ networkStatusToText(creative.status_id).text }}
                </div>
              </div>
              <div
                v-else-if="selected_config.type.toLowerCase() === 'unity'"
                class="w-full"
              >
                <h1 class="font-semibold text-[12px] my-3">Asset Creatives</h1>
                <div
                  v-for="creative in $sortArrayByProperty(
                    item.unity_creatives,
                    ['id'],
                    ['desc']
                  )"
                  :key="creative.id"
                  class="mb-1 p-3 border border-black/10 hover:border-black/20 rounded-[5px] first:mt-2 last:!mb-2 text-[12px]"
                >
                  <span class="font-semibold"> ID: </span>
                  <span> {{ creative.id }} </span>,
                  <span class="font-semibold"> Title:</span>
                  {{ creative.title }},
                  <span class="font-semibold">Status:</span>
                  <span
                    :style="{
                      color: networkStatusToText(creative.status_id).color,
                    }"
                  >
                    {{ networkStatusToText(creative.status_id).text }}
                  </span>
                </div>
              </div>
            </td>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <router-link
              class="font-semibold flex items-center"
              :to="{
                name: 'act-manage-assets-detail',
                params: { id: item.id },
              }"
            >
              {{ item.title }}

              <span
                v-if="item.duration_ms && item.duration_ms >= 120000"
                class="pl-1"
                >(+120 secs)</span
              >

              <span
                v-else-if="item.duration_ms && item.duration_ms >= 60000"
                class="pl-1"
                >(+60 secs)</span
              >

              <span
                v-else-if="item.duration_ms && item.duration_ms >= 45000"
                class="pl-1"
                >(+45 secs)</span
              >

              <span
                v-else-if="item.duration_ms && item.duration_ms >= 20000"
                class="pl-1"
                >(+20 secs)</span
              >

              <span
                v-else-if="item.duration_ms && item.duration_ms >= 10000"
                class="pl-1"
                >(+10 secs)</span
              >
            </router-link>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ $formatUnix(item.created_at) }}
          </template>
          <template v-slot:[`item.status_id`]="{ item }">
            <div>
              <span
                class="font-semibold text-[11px]"
                :style="{ color: statusToText(item.status_id).color }"
              >
                {{ statusToText(item.status_id).text }}
              </span>
            </div>
          </template>

          <template v-slot:[`item.network_status`]="{ item }">
            <div
              class="font-semibold text-[11px]"
              :style="{
                color: networkStatusToText(computedNetworkStatus(item)).color,
              }"
            >
              {{ networkStatusToText(computedNetworkStatus(item)).text }}
            </div>
          </template>

          <template v-slot:[`item.user`]="{ item }">
            <div class="flex items-center">
              <v-avatar v-bind="attrs" v-on="on" color="primary" size="28">
                <span class="text-white font-semibold text-[10px]">
                  {{
                    item.user.full_name
                      .split(" ")
                      .map((x) => x[0])
                      .join("")
                  }}
                </span>
              </v-avatar>
              <div class="ml-2">
                {{ item.user.full_name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 p-2 rounded-full"
                  @click="deleteAsset(item.id)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Click to delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>

      <div
        class="flex items-center justify-between mt-4"
        v-if="selected_config && [1, 7].includes(getUserProfile.department.id)"
      >
        <div class="w-full">
          <v-btn
            color="error"
            depressed
            text
            slot="reference"
            class="font-semibold"
            :disabled="selectedRows.length === 0"
            @click="show_delete = true"
          >
            Delete Selected
          </v-btn>

          <v-btn
            color="secondary"
            depressed
            class="font-semibold"
            @click="showCopyToDriveDialog = true"
            text
            :loading="uploadLoading"
            :disabled="uploadLoading || !selectedRows.length"
          >
            Copy to Drive
          </v-btn>
        </div>
        <div class="flex items-center gap-2">
          <v-btn
            color="primary"
            depressed
            class="font-semibold"
            :disabled="computedSelectedRows.length === 0"
            @click="navigateBurcha"
          >
            Navigate to Burcha for {{ computedSelectedRows.length }} creatives
          </v-btn>

          <v-btn
            color="primary"
            @click="show_preview = true"
            depressed
            class="font-semibold"
            :disabled="selectedRows.length === 0"
          >
            Create test for {{ selectedRows.length }} item(s)
          </v-btn>
        </div>
      </div>
    </div>
    <UACreateTestPreview
      :rows="selectedRows"
      :network="selected_config"
      :show="show_preview"
      @closed="show_preview = false"
      @testCreated="handleOnTestCreated"
      from_manage_assets
    />
    <UADeletePreview
      :rows="selectedRows"
      :show="show_delete"
      @closed="show_delete = false"
      @deleted="handleOnDeleted"
    />

    <MetadataFilterDialog
      :show="showMetadataFilterDialog"
      @closed="showMetadataFilterDialog = false"
      :tags="tags"
      @submit="handleOnSubmitMetadataFilter"
    />

    <CopyToDriveDialog
      @closed="showCopyToDriveDialog = false"
      :show="showCopyToDriveDialog"
      :selectedGame="selected_config ? selected_config.game : null"
      @submitted="copyCreativesToDrive"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import UACreateTestPreview from "@/components/tools/act/UACreateTestPreview.vue";
import UADeletePreview from "@/components/tools/act/UADeletePreview.vue";
import _ from "lodash";
import MetadataFilterDialog from "@/components/tools/act/MetadataFilterDialog.vue";
import CopyToDriveDialog from "@/components/tools/act/CopyToDriveDialog.vue";

export default {
  name: "ManageAssetsView",
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getSelectedNetwork",
      "getLimit",
      "getCurrentPage",
      "getFilterDates",
      "getSortBy",
      "getSortDesc",
    ]),
    computedSelectedRows() {
      if (this.selectedRows.length) {
        return this.selectedRows.filter((x) => x.status_id === 2);
      }
      return [];
    },
    computedConfigs() {
      if (this.configs) {
        return this.configs
          .filter((x) => x.type !== "Tiktok")
          .map((x) => ({
            id: x.id,
            type: x.type,
            game_title: x.game.title,
            game_id: x.game.id,
            base_title: `${x.type} - ${x.game.title} - ${x.title}`
              .split(" ")
              .map((str) => {
                const word = str.toLowerCase();
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(" "),
            adset_id: x.adset_id,
            comparison_adset_id: x.comparison_adset_id,
            cta_text: x.cta_text,
            act_id: x.act_id,
            extra_data: x.extra_data ? JSON.parse(x.extra_data) : null,
            game: x.game,
            title: x.title,
          }));
      }
      return null;
    },
    headers() {
      var columns = [
        { text: "ID", value: "id" },
        { text: "Title", value: "title" },
        { text: "Created By", value: "user", align: "center" },
        { text: "Created At", value: "created_at", align: "center" },
        { text: "Status", value: "status_id", align: "center" },
        // { text: "Preview", value: "preview", sortable: false, align: "center" },
      ];
      if (this.selected_config) {
        columns.push({
          text: "Network Status",
          value: "network_status",
          align: "center",
        });
      }
      if (this.getUserProfile.department.id !== 7) {
        columns.push({ text: "Actions", value: "actions", align: "center" });
      }

      //   if (this.selected_combination_status !== "Don't include") {
      //     columns.splice(7, 0, {
      //       text: "Combination Status",
      //       value: "combination_status_id",
      //       align: "center",
      //     });
      //   }

      //   if (this.selected_config.type == "Facebook") {
      //     columns.splice(7, 0, {
      //       text: "Status (2nd Test)",
      //       value: "status_id_2",
      //       align: "center",
      //     });
      //   }

      return columns;
    },
  },
  data() {
    return {
      selected_network: null,
      expanded: [],
      references: [],
      selectedLanguage: null,
      uploadLoading: false,
      metadataFilter: [],
      tags: [],
      showCopyToDriveDialog: false,
      showMetadataFilterDialog: false,
      show_delete: false,
      creative_data: {
        items: [],
        count: 0,
      },
      menu1: false,
      dates: null,
      configs: [],
      loading: false,
      keyword: null,
      limit: 25,
      currentPage: 1,
      sortDesc: false,
      sortBy: null,
      selected_config: null,
      selectedRows: [],
      status: [
        "All",
        "Not Tested",
        "In queue",
        "Testing",
        "Tested (insights not saved)",
        "Tested",
        "Error",
      ],
      selected_status: "All",
      show_preview: false,
      generators: ["ACG", "ACT", "ACG & ACT"],
      selected_generator: "ACG & ACT",
      creative_types: ["Only Base Creatives", "Only Combinations", "All"],
      selected_creative_type: "All",
      fav: true,
      menu: false,
      message: false,
      hints: true,
      combination_statuses: [
        "Don't include",
        "All",
        "Not Tested",
        "Testing",
        "Tested",
      ],
      selected_combination_status: "All",
      numberOfCombinations: null,
      games: [],
    };
  },
  methods: {
    ...mapActions([
      "setSelectedNetwork",
      "setLimit",
      "setCurrentPage",
      "setFilterDates",
      "setSortBy",
      "setSortDesc",
    ]),
    statusToText(id) {
      let data = {
        text: null,
        color: null,
      };

      if (id === 2) {
        data.text = "Ready to use";
        data.color = "#ca8a04";
      } else {
        data.text = "Error";
        data.color = "#dc2626";
      }

      return data;
    },
    computedNetworkStatus(item) {
      // Initialize an empty object to track unique status IDs of creatives
      let ids = {};

      // Check if the selected configuration type is "unity" and if the item has unity creatives
      if (
        this.selected_config.type.toLowerCase() === "unity" &&
        item.unity_creatives
      ) {
        // Populate 'ids' object with the unique status IDs of unity creatives
        item.unity_creatives.forEach((creative) => {
          if (!ids[creative.status_id]) {
            ids[creative.status_id] = true;
          }
        });

        // Determine priority based on the presence of specific status IDs
        if (ids[50]) return 50; // High priority status
        if (ids[12]) return 12; // Mid-high priority status
        if (ids[3]) return 3; // Mid priority status
        if (ids[4]) return 4; // Mid-Low priority status
        if (ids[5]) return 5; // Low priority status

        // Default return for 'unity' creatives if no priority matches
        if (!item.unity_creatives.length) {
          // Not tested
          return 1;
        }

        return 100;
      }

      // Check if the selected configuration type is "facebook" and if the item has facebook creatives
      else if (
        this.selected_config.type.toLowerCase() === "facebook" &&
        item.facebook_creatives
      ) {
        // Populate 'ids' object with the unique status IDs of facebook creatives
        item.facebook_creatives.forEach((creative) => {
          if (!ids[creative.status_id]) {
            ids[creative.status_id] = true;
          }
        });

        // Determine priority based on the presence of specific status IDs
        if (ids[1]) return 1; // Highest priority for facebook
        if (ids[2]) return 2; // Mid-high priority for facebook
        if (ids[3]) return 3; // Mid-high priority
        if (ids[4]) return 4; // Mid priority
        if (ids[5]) return 5; // Low-mid priority
        if (ids[6]) return 6; // Low priority

        if (!item.facebook_creatives.length) {
          // Not tested
          return 200;
        }

        // Default return for 'facebook' creatives if no priority matches
        return 100;
      }
    },
    fetchReferences() {
      this.$api
        .get("references/all")
        .then((response) => {
          this.references = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async copyCreativesToDrive(networks) {
      try {
        const requestData = {
          folders: networks.map((x) => ({
            title: x.title,
            value: x.value,
          })),
          creatives: this.selectedRows.map((x) => x.id),
        };

        this.uploadLoading = true;
        const response = await this.$api.post(
          "utils/copy-creatives",
          requestData
        );
        if (response.status === 200) {
          this.$toast.success(
            "Selected creatives successfully uploaded Drive. You MUST have received success messages on Slack.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.uploadLoading = false;
      }
    },
    handleOnSubmitMetadataFilter(data) {
      this.metadataFilter = [];
      this.metadataFilter = data;

      this.showMetadataFilterDialog = false;

      this.fetchAssets();
    },
    async fetchTags() {
      try {
        const response = await this.$api.get("tags/all");
        this.tags = response.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    navigateBurcha() {
      this.$router.push({
        name: "campaign-manager-all-campaigns-view",
        params: {
          selectedCreatives: this.selectedRows.map((x) => ({
            ...x,
            gaia_id: x.id,
            ad_name: x.title,
          })),
          game_id: this.selected_config.game_id,
        },
      });
    },
    fetchNumberOfCombinations() {
      return [];
      //   this.$api
      //     .get(
      //       `act/general-creatives/number-of-combinations/${this.selected_config.id}`
      //     )
      //     .then((response) => {
      //       if (response.status === 200) {
      //         this.numberOfCombinations = response.data.message;
      //       } else {
      //         this.numberOfCombinations = null;
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.numberOfCombinations = null;
      //     });
    },
    handleOnDeleted() {
      this.show_delete = false;
      this.selectedRows = [];
      this.fetchAssets();
    },
    handleOnTestCreated() {
      this.selectedRows = [];
      this.fetchAssets();
    },
    fetchAssets() {
      if (this.selected_config) {
        this.creative_data.items = [];
        this.loading = true;
        let url = `act/assets/all?limit=${this.limit}&page=${this.currentPage}&selected_game_id=${this.selected_config.game_id}`;

        if (this.keyword) {
          url += `&title=${this.keyword}`;
        }
        if (this.dates) {
          url += `&dates=${JSON.stringify(this.dates)}`;
        }
        if (this.getSortBy) {
          url += `&sortBy=${this.getSortBy}`;
        }
        if (this.getSortDesc) {
          url += `&sortDesc=${this.getSortDesc}`;
        }
        if (this.sortBy && !this.getSortDesc) {
          url += `&sortBy=${this.sortBy}`;
        }
        if (this.sortDesc !== undefined && !this.getSortDesc) {
          url += `&sortDesc=${this.sortDesc}`;
        }
        if (this.selected_status) {
          if (this.selected_config.type == "Facebook") {
            url += `&status=${this.selected_status}`;
          }
          if (this.selected_config.type == "Tiktok") {
            url += `&tiktok_status=${this.selected_status}`;
          }
          if (this.selected_config.type == "unity") {
            url += `&unity_status=${this.selected_status}`;
          }
        }

        if (this.selected_generator) {
          url += `&generator=${this.selected_generator}`;
        }

        if (this.selected_combination_status !== "Don't include") {
          url += `&selected_combination_status=${this.selected_combination_status}`;
        }

        if (this.selected_creative_type) {
          url += `&selected_creative_type=${this.selected_creative_type}`;
        }

        if (this.selectedLanguage) {
          url += `&language=${this.selectedLanguage.toLowerCase()}`;
        }

        if (this.metadataFilter.length) {
          url += `&metadataFilter=${JSON.stringify(
            this.metadataFilter.map((x) => ({
              condition: x.condition.toLowerCase(),
              target: x.target.key,
              value: x.value,
            }))
          )}`;
        }

        this.$api
          .get(url)
          .then((response) => {
            this.creative_data = response.data;
            this.fetchNumberOfCombinations();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    deleteAsset(id) {
      this.loading = true;
      this.$api
        .delete(`act/assets/${id}`)
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: response.data.detail,
            });
            this.fetchAssets();
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    handlePaginationChange(event) {
      this.limit = event.itemsPerPage;
      this.currentPage = event.page;
    },
    handleSortBy(sortBy) {
      this.sortBy = sortBy;
      if (sortBy === "status_id") {
        if (this.selected_config.type === "Tiktok") {
          this.sortBy = "tiktok_status_id";
        }
      }
      if (sortBy === "ad_started_at") {
        if (this.selected_config.type === "Tiktok") {
          this.sortBy = "tiktok_ad_started_at";
        }
      }
      if (sortBy === "finish_at") {
        if (this.selected_config.type === "Tiktok") {
          this.sortBy = "tiktok_ad_finish_at";
        }
      }
      this.setSortBy(this.sortBy);
      this.fetchAssets();
    },
    handleSortDesc(sortDesc) {
      this.sortDesc = sortDesc;
      this.setSortDesc(this.sortDesc);
      this.fetchAssets();
    },

    networkStatusToText(id) {
      let data = {
        text: null,
        color: null,
      };

      if (this.selected_config.type.toLowerCase() === "unity") {
        switch (id) {
          case 1:
            data.text = "Not Tested";
            data.color = "#dc2626";
            break;

          case 12:
          case 50:
            data.text = "In Queue";
            data.color = "#7c3aed";
            break;

          case 5:
            data.text = "Done";
            data.color = "#15803d";
            break;

          case 4:
            data.text = "Not saved yet";
            data.color = "#65a30d";
            break;

          case 3:
            data.text = "Testing";
            data.color = "#ca8a04";
            break;

          case 100:
            data.text = "Error";
            data.color = "#f43f5e";
            break;
          default:
            data.text = "Error";
            data.color = "#f43f5e";
            break;
        }
      } else if (this.selected_config.type.toLowerCase() === "facebook") {
        switch (id) {
          case 1:
          case 2:
            data.text = "In Queue";
            data.color = "#7c3aed";
            break;

          case 3:
            data.text = "Processing";
            data.color = "#65a30d";
            break;

          case 4:
            data.text = "Testing";
            data.color = "#65a30d";
            break;

          case 5:
            data.text = "Tested but not saved";
            data.color = "#ca8a04";
            break;

          case 6:
            data.text = "Done";
            data.color = "#ca8a04";
            break;

          case 100:
            data.text = "Error";
            data.color = "#f43f5e";
            break;

          case 200:
            data.text = "Not Tested";
            data.color = "#dc2626";
            break;

          default:
            data.text = "Error";
            data.color = "#f43f5e";
            break;
        }
      }

      return data;
    },
    combinationStatusToText(id) {
      let data = {
        text: null,
        color: null,
      };

      if (id === 1) {
        data.text = "Not Tested (Combinations)";
        data.color = "#dc2626";
      }

      if (id === 2) {
        data.text = "Testing (Combinations)";
        data.color = "#ca8a04";
      }

      if (id === 3) {
        data.text = "Tested (Combinations)";
        data.color = "#2E7D32";
      }

      return data;
    },
    fetchConfigurations() {
      this.$api.get("act/network/all").then((response) => {
        this.configs = response.data;
      });
    },
    formatDateForURL(unix) {
      return moment.unix(unix).utcOffset("GMT-00:00").format("YYYY-MM-DD");
    },
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.fetchReferences();
    this.fetchTags();
    this.fetchGames();
    this.fetchConfigurations();

    if (this.getLimit) {
      this.limit = this.getLimit;
    }

    if (this.getCurrentPage) {
      this.currentPage = this.getCurrentPage;
    }

    if (this.getFilterDates) {
      this.dates = this.getFilterDates;
    }

    if (this.getSelectedNetwork) {
      this.selected_config = this.getSelectedNetwork;
    }
  },
  watch: {
    selectedLanguage() {
      this.fetchAssets();
    },
    dates(newValue) {
      if (this.dates && this.dates.length === 2) {
        this.fetchAssets();
      }
      if (!this.dates) {
        this.fetchAssets();
      }
      this.setFilterDates(newValue);
    },
    limit(newValue) {
      this.setLimit(newValue);
      this.fetchAssets();
    },
    currentPage(newValue) {
      this.setCurrentPage(newValue);
      this.fetchAssets();
    },
    selected_config(newValue) {
      this.selectedRows = [];
      this.fetchAssets();
      this.setSelectedNetwork(newValue);
    },
    selected_status() {
      this.selectedRows = [];
      this.fetchAssets();
    },
    selected_generator() {
      this.selectedRows = [];
      this.fetchAssets();
    },
    selected_creative_type() {
      this.selectedRows = [];
      this.fetchAssets();
    },
    keyword: _.debounce(function () {
      this.fetchAssets();
    }, 1000),
    selected_combination_status() {
      this.selectedRows = [];
      this.fetchAssets();
    },
  },
  components: {
    UACreateTestPreview,
    UADeletePreview,
    MetadataFilterDialog,
    CopyToDriveDialog,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}

.popper {
  box-shadow: rgba(58, 58, 58, 0.3) 0 0 10px 0;
  padding: 20px;
}
</style>
