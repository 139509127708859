import ConfigurationView from "@/views/tools/icon-tester/ConfigurationView.vue";
import CreateTestView from "@/views/tools/icon-tester/CreateTestView.vue";
import ResultsView from "@/views/tools/icon-tester/ResultsView.vue";

export const icon_tester_router = [
  {
    path: "/icon-tester/configuration",
    name: "icon-tester-config-view",
    component: ConfigurationView,
    meta: {
      title: "Icon & Screenshoot Tester - Config",
    },
  },
  {
    path: "/icon-tester/create",
    name: "icon-tester-create-view",
    component: CreateTestView,
    meta: {
      title: "Icon & Screenshoot Tester - Create Test",
    },
  },
  {
    path: "/icon-tester/results",
    name: "icon-tester-result-view",
    component: ResultsView,
    meta: {
      title: "Icon & Screenshoot Tester - Results",
    },
  },
];
