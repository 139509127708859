import CreateInvoiceView from "@/views/tools/parasut/CreateInvoiceView.vue";
import CreateTemplateView from "@/views/tools/parasut/CreateTemplateView.vue";
import AllInvoicesView from "@/views/tools/parasut/AllInvoicesView.vue";
import ManageTemplatesView from "@/views/tools/parasut/ManageTemplatesView.vue";

export const parasut_router = [
  {
    path: "/parasut/create-template",
    name: "create-template-view",
    component: CreateTemplateView,
    meta: {
      title: "Taslak Oluştur",
    },
  },
  {
    path: "/parasut/create-invoice",
    name: "create-invoice-view",
    component: CreateInvoiceView,
    meta: {
      title: "Paraşüt Faturası Oluştur",
    },
  },
  {
    path: "/parasut/invoices",
    name: "all-invoices-view",
    component: AllInvoicesView,
    meta: {
      title: "Faturaları Yönet",
    },
  },
  {
    path: "/templates/invoices",
    name: "manage-templates-view",
    component: ManageTemplatesView,
    meta: {
      title: "Taslakları Yönet",
    },
  },
];
