<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">
        Create Creative Combination
      </h1>
      <div>
        <div class="mt-5">
          <v-autocomplete
            hide-details
            class="text-[13px]"
            item-text="title"
            return-object
            label="Choose a game"
            outlined
            dense
            :items="games"
            v-model="selected_game"
          >
          </v-autocomplete>
        </div>
        <div class="mt-5" v-if="selected_game">
          <v-autocomplete
            hide-details
            class="text-[13px]"
            item-text="search_title"
            item-value="id"
            return-object
            label="Choose a base creative"
            :loading="loading"
            :disabled="loading"
            outlined
            dense
            :items="data"
            v-model="selected_creative"
          >
          </v-autocomplete>
        </div>
      </div>

      <div v-if="render_results.length" class="mt-5">
        <h1 class="font-semibold text-[14px] mb-5">Render Result</h1>
        <div class="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5">
          <div
            v-for="(result, index) in render_results"
            :key="index"
            class="text-center"
          >
            <div class="font-semibold text-[10px] mb-2">{{ result.title }}</div>
            <div>
              <video-player
                class="video-player-box vjs-big-play-centered"
                ref="renderPlayer"
                :options="videoOptions(result)"
                :playsinline="true"
              >
              </video-player>
            </div>
          </div>
        </div>
      </div>

      <div
        class="pt-5 flex justify-between items-center"
        v-if="selected_creative"
      >
        <h1 class="font-semibold text-[14px]">Creative Information</h1>
        <v-btn
          color="error"
          depressed
          text
          class="font-semibold"
          :disabled="
            [computedCreativeStatusIds.RENDERED].includes(
              selected_creative.status_id
            )
          "
          :loading="delete_gameplay_loading"
          @click="deleteGameplay"
        >
          Delete Gameplay
        </v-btn>
      </div>

      <div
        class="mt-5"
        v-if="selected_creative && selected_creative.status_id === 6"
      >
        <v-alert
          icon="mdi-alert-circle-outline"
          border="left"
          text
          prominent
          type="warning"
          dismissible
          class="text-[11px] font-semibold"
        >
          This creative is in queue now for testing! You are not allowed to
          edit.
        </v-alert>
      </div>

      <div
        class="mt-5"
        v-if="selected_creative && selected_creative.status_id === 5"
      >
        <v-alert
          icon="mdi-alert-circle-outline"
          border="left"
          text
          prominent
          type="info"
          dismissible
          class="text-[11px] font-semibold"
        >
          This creative needs to be updated for testing. Visit
          <router-link :to="{ name: 'act-upload-assets-view' }"
            >here</router-link
          >
          to update.
        </v-alert>
      </div>

      <div class="grid grid-cols-12 gap-12 mt-5" v-if="selected_creative">
        <div class="lg:col-span-8 col-span-12 video-section" v-if="signed_url">
          <video-player
            class="video-player-box vjs-big-play-centered"
            ref="videoPlayer"
            :options="computedOptions"
            :playsinline="true"
          >
          </video-player>
          <!-- <div>
            <div class="mt-5">
              <v-btn
                color="blue-grey"
                depressed
                v-ripple="false"
                class="white--text text-[13px]"
                @click="downloadVideo"
              >
                Download
                <v-icon right dark> mdi-download </v-icon>
              </v-btn>
            </div>
          </div> -->
        </div>
        <div
          class="lg:col-span-4 col-span-12 flex flex-col justify-between items-center"
        >
          <div v-if="computedParameterData.length > 0">
            <div class="flex w-full items-center justify-end mb-4">
              <v-btn
                color="light-blue"
                depressed
                v-ripple="false"
                class="white--text"
                @click="applyLastParameters"
                :disabled="!lastParameterData.length"
              >
                Paste Last Values
                <v-icon right dark> mdi-content-paste </v-icon>
              </v-btn>
            </div>
            <div
              class="border border-gray-150 rounded hover:border-gray-400 hover:shadow-lg"
            >
              <v-expansion-panels focusable accordion>
                <v-expansion-panel
                  v-for="(item, index) in computedParameterData.filter(
                    (x) => x.combinability
                  )"
                  :key="index"
                >
                  <v-expansion-panel-header>
                    <span
                      class="text-[11px] font-semibold"
                      :class="
                        colorizeCombinationColor(item)
                          ? 'text-yellow-600'
                          : colorize(item)
                          ? 'text-green-600'
                          : 'text-gray-400'
                      "
                    >
                      {{ item.name }}
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="px-4 pb-4 text-[10px] text-gray-600 pt-0">
                      <div class="flex items-center justify-end mt-2">
                        <span
                          class="font-semibold text-[10px] text-green-600 hover:underline cursor-pointer mr-3"
                          v-if="
                            ['cameraPosition', 'cameraRotation'].includes(
                              item.name
                            )
                          "
                          @click="addCoordData(item.name)"
                        >
                          Add
                        </span>
                        <span
                          @click="resetParameter(item)"
                          class="font-semibold text-[10px] text-red-600 hover:underline cursor-pointer"
                          >Reset</span
                        >
                      </div>
                      <!-- Fashion Battle -->
                      <FB_DynamicParameters
                        v-if="
                          selected_game.title.toLowerCase() === 'fashion battle'
                        "
                        :variableType="item.type"
                        :choosableValues="item.variablePossibilities"
                        :gameplayValue="item.baseCreativeValue"
                        :defaultValue="item.defaultValue"
                        :valueStructures="item.valueStructures"
                        @changed="
                          handleOnParameterValueChange($event, item.name)
                        "
                      />
                      <!-- Drill & Collect -->
                      <DC_DynamicParameters
                        v-if="
                          selected_game.title.toLowerCase() ===
                          'drill and collect'
                        "
                        :variableType="item.type"
                        :choosableValues="item.variablePossibilities"
                        :gameplayValue="item.baseCreativeValue"
                        :defaultValue="item.defaultValue"
                        :valueStructures="item.valueStructures"
                        :disabled="
                          ['cameraPosition', 'cameraRotation'].includes(
                            item.name
                          )
                        "
                        @changed="
                          handleOnParameterValueChange($event, item.name)
                        "
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div class="text-center w-full">
            <v-progress-linear
              v-if="loadingCombinations"
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <span
              v-if="numberOfCombinations"
              class="text-[11px] font-semibold text-gray-700"
            >
              Number of combinations:
              {{ numberOfCombinations * computedAssetLength }}
            </span>
          </div>
          <div class="w-full">
            <div
              class="border border-gray-150 rounded hover:border-gray-400 hover:shadow-lg"
            >
              <v-expansion-panels v-model="asset_panel" focusable accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <h1 class="text-[13px] font-semibold flex items-center">
                      <v-icon class="mr-3"> mdi-tag-text-outline </v-icon>
                      Assets
                    </h1>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      class="p-6 text-center text-gray-400 text-[12px]"
                      v-if="
                        assets.length === 0 && !selected_creative.base_asset
                      "
                    >
                      No asset added.
                    </div>
                    <div v-else>
                      <div>
                        <div
                          class="p-2 mb-2 hover:bg-gray-100 cursor-pointer"
                          v-if="selected_creative && computedBaseAsset"
                        >
                          <div class="flex items-center justify-between w-full">
                            <div
                              class="font-semibold text-gray-600 text-[12px] w-full pl-4 flex items-center"
                            >
                              {{ computedBaseAsset }}
                              (after-effects)
                            </div>
                            <div class="flex items-center">
                              <v-icon class="mr-3" @click="removeBaseAsset">
                                mdi-delete-outline
                              </v-icon>
                            </div>
                          </div>
                        </div>
                        <div v-for="item in assets" :key="item.id">
                          <div
                            class="p-2 mb-2 hover:bg-gray-100 cursor-pointer"
                          >
                            <div
                              class="flex items-center justify-between w-full"
                            >
                              <div
                                class="font-semibold text-gray-600 text-[12px] w-full pl-4 flex items-center"
                              >
                                {{ item.asset_file.name }} ({{ item.type }})
                              </div>
                              <div class="flex items-center">
                                <v-icon
                                  class="mr-3"
                                  @click="removeFromAsset(item.id)"
                                >
                                  mdi-delete-outline
                                </v-icon>
                                <v-icon> mdi-pencil-outline </v-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <div
                class="text-center font-semibold text-gray-500 text-[12px] p-4 border border-dashed border-gray-150 mt-0 border-b-0 border-r-0 border-l-0 hover:border-gray-500 hover:text-gray-800 cursor-pointer"
                @click="addAsset"
                v-if="[7, 8].includes(selected_creative.status_id)"
              >
                Add Asset
              </div>
            </div>
          </div>

          <div class="w-full" v-if="selected_creative">
            <div>
              <v-btn
                color="blue-grey"
                depressed
                v-ripple="false"
                class="white--text w-full"
                :loading="loadingResult"
                @click="validateCombinations"
                :disabled="
                  ![7, 8].includes(selected_creative.status_id) ||
                  !(numberOfCombinations * computedAssetLength) ||
                  loadingResult
                "
              >
                Create Combinations
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selected_creative">
        <h1 class="font-semibold text-[14px] pt-5">Generated Combinations</h1>

        <div class="mt-6">
          <v-data-table
            :headers="headers"
            :items="queueData"
            :loading="queueDataLoading"
            show-select
            v-model="selectedRows"
            :items-per-page="10"
            dense
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:[`item.id`]="{ item }">
              <span class="font-semibold text-[11px]">
                {{ item.id }}
              </span>
            </template>
            <template v-slot:[`item.combination_title`]="{ item }">
              <span class="font-semibold text-[11px]">
                {{ item.base_creative_task_creator.base_creative_title }} -
                {{ item.index }}
              </span>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <span class="font-semibold text-[11px]">
                {{ $formatUnix(item.created_at) }}
              </span>
            </template>
            <template v-slot:[`item.finish_at`]="{ item }">
              <span class="font-semibold text-[11px]">
                {{ item.finish_at ? $formatUnix(item.finish_at) : "-" }}
              </span>
            </template>
            <template v-slot:[`item.status_id`]="{ item }">
              <span class="font-semibold text-[11px]">
                {{
                  item.has_tested
                    ? "Tested"
                    : item.status_id === 100
                    ? "Failed"
                    : "In Queue"
                }}
              </span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div>
                  <!-- eslint-disable -->
                  <pre class="p-4 text-[10px]">{{
                    JSON.stringify(
                      JSON.parse(item.params.replace(" ", "")),
                      null,
                      4
                    )
                  }}</pre>
                </div>
              </td>
            </template>
          </v-data-table>
        </div>

        <div class="flex items-center justify-between">
          <v-btn
            color="error"
            depressed
            text
            class="font-semibold"
            :disabled="selectedRows.length === 0"
            @click="deleteCombinations"
            :loading="delete_loading"
          >
            Delete {{ computedSelectedRows.length }} combination(s)
          </v-btn>
        </div>
      </div>
    </div>
    <AddAsset
      @closed="show_add_asset_modal = false"
      @submitted="handleOnAssetSubmitted"
      :show="show_add_asset_modal"
      v-if="available_parameters"
    />
    <ValidateCombinations
      :show="show_validate_modal"
      @closed="show_validate_modal = false"
      :number_of_combinations="numberOfCombinations * computedAssetLength"
      @submitted="createCombinations"
    />
  </div>
</template>

<script>
import AddAsset from "@/components/tools/acg/step2/AddAsset.vue";
import FB_DynamicParameters from "@/components/tools/acg/step2/Fashion_Battle/FB_DynamicParameters.vue";
import DC_DynamicParameters from "@/components/tools/acg/step2/Drill_Collect/DC_DynamicParameters.vue";
import ValidateCombinations from "@/components/tools/acg/step2/ValidateCombinations.vue";
import { ACG_GAMEPLAY_STATUS_IDS } from "@/helper/utils";
import _ from "lodash";

export default {
  data() {
    return {
      expanded: [],
      delete_gameplay_loading: false,
      delete_loading: false,
      selectedRows: [],
      data: [],
      loading: false,
      selected_creative: null,
      available_parameters: {},
      used_parameters: null,
      signed_url: null,
      gameParameterData: {},
      parameter_panel: null,
      asset_panel: 0,
      assets: [],
      show_add_asset_modal: false,
      show_edit_parameters_modal: false,
      computedParameterData: [],
      numberOfCombinations: null,
      timer: null,
      loadingCombinations: false,
      loadingResult: false,
      default_game_tags: [],
      extra_data: null,
      other_data: null,
      show_validate_modal: false,
      queueData: [],
      queueDataLoading: false,
      render_results: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Title", value: "combination_title", sortable: false },
        { text: "Created At", value: "created_at" },
        { text: "Finish At", value: "finish_at" },
        { text: "Status", value: "status_id" },
        { text: "", value: "data-table-expand" },
      ],
      selected_game: null,
      games: [],
      lastParameterData: [],
      cameraPositionData: [],
      cameraRotationData: [],
      loading_levels: false,
      levels: [],
    };
  },
  methods: {
    deleteCameraData(name, index) {
      if (name === "cameraPosition") {
        const cord = this.cameraPositionData.slice()[index];

        for (let i = 0; i < this.computedParameterData.length; i++) {
          const element = this.computedParameterData[i];
          if (element.name === name) {
            for (let y = 0; y < element.baseCreativeValue.length; y++) {
              const valuesSplitted = element.baseCreativeValue[y]
                .split(",")
                .map((x) => parseFloat(x));

              if (
                valuesSplitted[0] === parseFloat(cord.x) &&
                valuesSplitted[1] === parseFloat(cord.y) &&
                valuesSplitted[2] === parseFloat(cord.z)
              ) {
                this.computedParameterData[i].baseCreativeValue.splice(y, 1);
                this.cameraPositionData.splice(index, 1);
              }
            }
          }
        }
      }

      if (name === "cameraRotation") {
        const cord = this.cameraRotationData.slice()[index];

        for (let i = 0; i < this.computedParameterData.length; i++) {
          const element = this.computedParameterData[i];
          if (element.name === name) {
            for (let y = 0; y < element.baseCreativeValue.length; y++) {
              const valuesSplitted = element.baseCreativeValue[y]
                .split(",")
                .map((x) => parseFloat(x));

              if (
                valuesSplitted[0] === parseFloat(cord.x) &&
                valuesSplitted[1] === parseFloat(cord.y) &&
                valuesSplitted[2] === parseFloat(cord.z)
              ) {
                this.computedParameterData[i].baseCreativeValue.splice(y, 1);
                this.cameraRotationData.splice(index, 1);
              }
            }
          }
        }
      }
    },
    applyCameraData(cord, name, index) {
      for (let i = 0; i < this.computedParameterData.length; i++) {
        const element = this.computedParameterData[i];
        if (name === element.name) {
          this.computedParameterData[i].baseCreativeValue.push(
            `${cord.x}, ${cord.y}, ${cord.z}`
          );
          if (name === "cameraPosition") {
            this.cameraPositionData[index].disabled = true;
          }
          if (name === "cameraRotation") {
            this.cameraRotationData[index].disabled = true;
          }
        }
      }
    },
    isConfirmBtnDisabled(cord, name) {
      if (cord.x === null) {
        return true;
      }
      if (cord.y === null) {
        return true;
      }
      if (cord.z === null) {
        return true;
      }

      const hasValue = this.computedParameterData.some((x) => {
        if (x.name === name) {
          for (let y = 0; y < x.baseCreativeValue.length; y++) {
            const element = x.baseCreativeValue[y];

            // Example: "-4, -3, 3" -> These are strings. So we convert it into: [-4.0, -3.0, 3.0] to compare.
            const elementValues = element.split(",").map((x) => parseFloat(x));

            if (
              elementValues[0] === parseFloat(cord.x) &&
              elementValues[1] === parseFloat(cord.y) &&
              elementValues[2] === parseFloat(cord.z)
            )
              return true;
          }
        }
      });

      if (hasValue) return true;

      return false;
    },
    addCoordData(key) {
      if (key === "cameraPosition") {
        this.cameraPositionData.push({
          x: null,
          y: null,
          z: null,
          disabled: false,
        });
      }
      if (key === "cameraRotation") {
        this.cameraRotationData.push({
          x: null,
          y: null,
          z: null,
          disabled: false,
        });
      }
    },
    applyLastParameters() {
      this.computedParameterData = this.lastParameterData.slice();
    },
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    videoOptions(video) {
      return {
        sources: [
          {
            type: "video/mp4",
            src: video.signed_url,
          },
        ],
        language: "en",
        height: "220px",
        width: "100%",
      };
    },
    fetchRenderResults(id) {
      this.$api
        .get(`acg/base-creatives/${id}/render-results`)
        .then((response) => {
          if (response.status === 200) {
            this.render_results = response.data;
          } else {
            this.render_results = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteGameplay() {
      this.delete_gameplay_loading = true;
      const requestData = {
        creative_id: this.selected_creative.id,
      };

      this.$api
        .delete("acg/base-creatives/delete", {
          data: requestData,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Gameplay has been successfuly deleted.",
            });
            this.selected_creative = null;
            this.fetchBaseCreatives();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.delete_gameplay_loading = false;
        });
    },
    deleteCombinations() {
      this.delete_loading = true;
      const requestData = {
        combinations: this.computedSelectedRows,
      };
      this.$api
        .delete("acg/tasks/delete", {
          data: requestData,
        })
        .then((response) => {
          if (response.status === 200) {
            this.fetchCreativeTasks(this.selected_creative.id);
            this.selectedRows = [];
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    removeBaseAsset() {
      if (this.selected_creative.base_asset) {
        this.selected_creative.base_asset = null;
      }
    },
    fetchBaseCreatives() {
      this.loading = true;
      let url = `acg/base-creatives/all`;
      if (this.selected_game) url += `?game_id=${this.selected_game.id}`;
      this.$api
        .get(url)
        .then((response) => {
          this.data = response.data;
          if (this.selected_game.title.toLowerCase() === "drill and collect") {
            this.fetchLevels();
          } else {
            this.selected_creative = this.data.filter(
              (x) => x.id === this.$route.query.id
            )[0];
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    fetchBaseCreativeDetail(id) {
      this.$api
        .get(`acg/base-creatives/detail?id=${id}`)
        .then((response) => {
          this.signed_url = response.data.signed_url;
          this.used_parameters = JSON.parse(response.data.used_parameters);
          this.gameParameterData = this.$sortObjByKey(this.used_parameters);
          this.available_parameters = JSON.parse(
            response.data.available_parameters
          );

          if (this.selected_game.title.toLowerCase() === "drill and collect") {
            if (!this.levels.length) {
              console.log("There is no level data.");
              return;
            }
            this.available_parameters = {
              undergroundDesignCombinationData: {
                variableType: "undergroundDesignCombinationEnum",
                variableValue: [],
                variablePossibilities: [],
                combinability: true,
              },
              ...this.available_parameters,
            };
          }

          this.setComputedParameterData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchLevels() {
      this.loading_levels = true;
      this.$api
        .get("acg/dnc-levels/all")
        .then((response) => {
          this.levels = response.data;
          this.selected_creative = this.data.filter(
            (x) => x.id === this.$route.query.id
          )[0];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_levels = false;
        });
    },
    fetchCreativeTasks(id) {
      this.queueDataLoading = true;
      this.$api
        .get(`acg/tasks/base-creative/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.queueData = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.queueDataLoading = false;
        });
    },
    componentProperties(key) {
      if (
        this.available_parameters &&
        this.gameParameterData &&
        this.used_parameters
      ) {
        return {
          type: this.available_parameters[key].variableType,
          parameterLabel: key,
          defaultValue: this.gameParameterData[key],
          availableValues: this.available_parameters[key].variablePossibilities,
          disable_all: true,
        };
      }
      return null;
    },
    testIt(data) {
      if (
        this.available_parameters[data.variableName] &&
        this.available_parameters[data.variableName].variableValue !==
          data.value
      )
        this.gameParameterData[data.variableName] = data.value;
    },
    handleOnAssetSubmitted(data) {
      this.show_add_asset_modal = false;
      this.assets.push(data);
    },
    handleOnParameterSubmitted(data) {
      this.show_edit_parameters_modal = false;
      this.gameParameterData = data;
    },
    removeFromAsset(id) {
      this.assets = this.assets.filter((x) => x.id !== id);
    },
    getVariablePossibilities(key) {
      if (this.available_parameters && this.used_parameters) {
        const possibilities =
          this.available_parameters[key].variablePossibilities;

        if (possibilities) {
          return possibilities;
        } else {
          const originalType = this.available_parameters[key].variableType;

          if (originalType === "bool") {
            return ["false", "true"];
          } else return [];
        }
      }
    },
    getDefaultValue(key, for_base_creative_value) {
      const data = [];
      if (this.available_parameters && this.used_parameters) {
        if (key in this.used_parameters && for_base_creative_value) {
          const usedValue = this.used_parameters[key].variableValue;
          // Eğer gameplay kaydında kullanılan değer bir Obje ise string'e çevirilmesin.
          if (this.$isObj(usedValue)) {
            data.push(usedValue);
          } else {
            // Diğer tipler (boolean, number, array vb. string'e dönüştürülsün.)
            data.push(usedValue.toString());
          }
          return data;
        } else {
          // Eğer gameplay kaydında kullanılan değer zaten default değer ise ve Obje ise (Bundle değişkenler gibi) direkt olarak default değeri döndürsün. Eğer obje değil ise tip olarak String'e dönüştürülsün.
          if (this.$isObj(this.available_parameters[key].variableValue)) {
            data.push(this.available_parameters[key].variableValue);
          } else {
            if (key === "undergroundDesignCombinationData") {
              return data;
            } else {
              data.push(
                this.available_parameters[key].variableValue.toString()
              );
            }
          }
          return data;
        }
      }
    },
    setComputedParameterData() {
      this.computedParameterData = [];
      const defaultKeys = Object.keys(this.available_parameters);
      for (let i = 0; i < defaultKeys.length; i++) {
        const key = defaultKeys[i];
        if (!["undergroundDesignData"].includes(key)) {
          const variablePossibilities = this.getVariablePossibilities(key);
          const baseCreativeValue = this.getDefaultValue(key, true);
          const defaultValue = this.getDefaultValue(key, false);

          this.computedParameterData.push({
            name: key,
            type: this.available_parameters[key].variableType,
            variablePossibilities: variablePossibilities,
            valueStructures: this.available_parameters[key].valueStructures
              ? this.available_parameters[key].valueStructures
              : null,
            baseCreativeValue: baseCreativeValue,
            defaultValue: defaultValue,
            combinability: this.available_parameters[key].combinability
              ? true
              : false,
            show: false,
            include: false,
          });
        }
      }
    },
    colorizeCombinationColor(item) {
      // Eğer editör değeri, mevcut gameplay'de oynanmış değerden farklı ise true döndür.
      return !_.isEqual(
        item.baseCreativeValue.slice().sort(),
        this.getDefaultValue(item.name, true).slice().sort()
      );
    },
    colorize(item) {
      // Eğer editör değeri, build JSON'dan dönen default değerden farklı ise true döndür.
      return !_.isEqual(
        item.baseCreativeValue.slice().sort(),
        item.defaultValue.slice().sort()
      );
    },
    handleOnParameterValueChange(data, key) {
      console.log(data);
      for (var i = 0; i < this.computedParameterData.length; i++) {
        if (key === this.computedParameterData[i].name) {
          this.computedParameterData[i].baseCreativeValue = data;
        }
      }
    },
    resetParameter(item) {
      const defaultValue = this.getDefaultValue(item.name, true);
      for (var i = 0; i < this.computedParameterData.length; i++) {
        if (item.name === this.computedParameterData[i].name) {
          this.computedParameterData[i].baseCreativeValue = defaultValue;
        }
      }

      this.cameraPositionData = [];
      this.cameraRotationData = [];
    },
    fetchNumberOfCombinations() {
      if (this.computedRequestData) {
        this.loadingCombinations = true;
        var requestData = JSON.parse(JSON.stringify(this.computedRequestData));
        delete requestData.undergroundDesignCombinationData;
        delete requestData.levelString;

        this.$api
          .get(
            `utils/number-of-combinations?data=${JSON.stringify(requestData)}`
          )
          .then((response) => {
            if (response.status === 200) {
              this.loadingCombinations = false;

              this.numberOfCombinations = response.data.number;

              if (this.computedRequestData.undergroundDesignCombinationData) {
                this.numberOfCombinations *=
                  this.computedRequestData.undergroundDesignCombinationData
                    .length + 1;
              }
            }
          })
          .catch((err) => {
            this.loadingCombinations = false;
            console.log(err);
          });
      }
    },
    addAsset() {
      if (this.selected_creative) {
        if (this.selected_creative.status_id === 6) {
          return;
        } else {
          this.show_add_asset_modal = true;
        }
      }
    },
    validateCombinations() {
      this.show_validate_modal = true;
    },

    async createCombinations() {
      var requestData = JSON.parse(JSON.stringify(this.computedRequestData));

      let formData = new FormData();
      if (this.assets && this.assets.length > 0) {
        for (let i = 0; i < this.assets.length; i++) {
          formData.append("assets", this.assets[i].asset_file);
        }
      }

      formData.append("base_creative_id", this.selected_creative.id);
      formData.append("combination_params", JSON.stringify(requestData));

      if (this.selected_creative.base_asset) {
        formData.append("base_asset", this.selected_creative.base_asset);
      }

      this.loadingResult = true;

      this.$api
        .post("acg/base-creatives/combinations/create", formData)
        .then((response) => {
          this.selected_creative = response.data;
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Combinations are successfully queued!",
            });
            this.lastParameterData = this.computedParameterData.slice();
          }
        })
        .catch((err) => {
          this.errorHandler(err);
          console.log(err);
        })
        .finally(() => {
          this.loadingResult = false;
        });
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  watch: {
    selected_game(newValue) {
      if (newValue) {
        this.fetchBaseCreatives();
      }
    },
    selected_creative: {
      handler(newValue) {
        this.render_results = [];
        if (newValue) {
          this.fetchBaseCreativeDetail(newValue.id);
          this.fetchCreativeTasks(newValue.id);
          if ([7, 8].includes(newValue.status_id)) {
            this.fetchRenderResults(newValue.id);
          }
        }
      },
      immediate: true,
    },
    computedParameterData: {
      handler: _.debounce(function () {
        this.fetchNumberOfCombinations();
      }, 200),
      deep: true,
    },
  },
  computed: {
    computedCreativeStatusIds() {
      return ACG_GAMEPLAY_STATUS_IDS;
    },
    computedSelectedRows() {
      return this.selectedRows.filter((x) => !x.has_tested).map((x) => x.id);
    },
    computedBaseAsset() {
      if (this.selected_creative.base_asset) {
        return this.selected_creative.base_asset.split("/").pop();
      }
      return null;
    },
    computedAssetLength() {
      if (this.selected_creative) {
        let length = 0;
        length += this.assets.length;
        length += this.selected_creative.base_asset ? 1 : 0;
        return length;
      }
      return 0;
    },
    computedOptions() {
      if (this.selected_creative) {
        return {
          sources: [
            {
              type: "video/mp4",
              src: this.signed_url,
            },
          ],
          language: "en",
        };
      }
      return null;
    },
    computedRequestData() {
      if (this.computedParameterData) {
        let data = {};

        for (let i = 0; i < this.computedParameterData.length; i++) {
          const element = this.computedParameterData[i];

          if (
            element.defaultValue.slice().sort().toString() !==
              element.baseCreativeValue.slice().sort().toString() &&
            element.baseCreativeValue.length !== 0
          ) {
            if (element.type === "bool") {
              data[element.name] = [...element.baseCreativeValue].map((x) =>
                JSON.parse(x)
              );
            } else if (element.type === "int") {
              data[element.name] = [...element.baseCreativeValue].map((x) =>
                parseInt(x)
              );
            } else if (element.type === "int[]") {
              data[element.name] = [...element.baseCreativeValue].map((x) =>
                x.split(",").map((y) => parseInt(y))
              );
            } else if (element.type === "float[]") {
              data[element.name] = [...element.baseCreativeValue].map((x) =>
                x.split(",").map((y) => parseInt(y))
              );
            } else {
              data[element.name] = element.baseCreativeValue;
            }
          }
        }

        return data;
      }
      return null;
    },
  },
  mounted() {
    this.fetchGames();
  },
  components: {
    AddAsset,
    FB_DynamicParameters,
    ValidateCombinations,
    DC_DynamicParameters,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/sass/variables.scss";
::v-deep .video-js {
  width: 100% !important;
}

pre {
  font-family: $log-font-family;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
  max-height: 350px;
  overflow-y: auto;
}
::v-deep .v-btn__content {
  font-size: 11px !important;
  text-transform: capitalize;
  letter-spacing: normal;
}
.edit-section {
  ::v-deep .v-btn__content {
    font-size: 13px !important;
    font-weight: 600;
    text-transform: capitalize;
  }
}
::v-deep .vjs-time-control {
  display: block !important;
}
::v-deep .vjs-remaining-time {
  display: block !important;
}

::v-deep .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible;
}

.video-section {
  ::v-deep .video-js {
    position: relative !important;
    width: 100% !important;
    height: 650px !important;
  }
}

::v-deep .v-expansion-panels {
  max-height: 335px;
  overflow: auto;
}

::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 40px;
  padding: 0 16px;
}
</style>
