<template>
  <div v-if="getUserProfile">
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Asset Detail</h1>
      <div class="pb-4">
        <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
      </div>

      <div v-if="data && data.is_base">
        <div class="my-4 flex items-center justify-between">
          <h1 class="font-semibold text-[14px] flex items-center">
            <v-icon> mdi-source-branch-refresh </v-icon>
            <span class="ml-4"> Combinations </span>
          </h1>
          <div>
            <v-select
              hide-details
              class="text-[13px]"
              :items="networks"
              item-text="type"
              return-object
              label="Network Type"
              dense
              v-model="selected_config"
              outlined
            ></v-select>
          </div>
        </div>

        <div class="combination-table">
          <v-data-table
            :show-select="[1, 7].includes(getUserProfile.department.id)"
            v-model="selectedRows"
            :headers="headers"
            :items="combination_data"
            :loading="table_loading"
            loading-text="Please wait..."
            :items-per-page="15"
            :footer-props="{
              itemsPerPageOptions: [15, 25, 50, -1],
            }"
          >
            <template v-slot:[`item.title`]="{ item }">
              <router-link
                class="font-semibold"
                :to="{
                  name: 'act-combination-detail',
                  params: { id: item.general_creative.id },
                }"
              >
                {{ item.general_creative.title }}
              </router-link>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ $formatUnix(item.general_creative.created_at) }}
            </template>

            <template v-slot:[`item.user`]="{ item }">
              <div class="flex items-center">
                <img
                  :src="item.general_creative.user.photo"
                  width="24"
                  class="rounded mr-2"
                />
                <div>
                  {{ item.general_creative.user.full_name }}
                </div>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 p-2 rounded-full"
                    @click="deleteAsset(item.general_creative.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Click to delete</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.finish_at`]="{ item }">
              <span
                v-if="selected_config && selected_config.type === 'Facebook'"
              >
                {{
                  item.general_creative.finish_at
                    ? $formatUnix(item.general_creative.finish_at)
                    : "-"
                }}
              </span>
              <span v-if="selected_config && selected_config.type === 'Tiktok'">
                {{
                  item.general_creative.tiktok_ad_finish_at
                    ? $formatUnix(item.general_creative.tiktok_ad_finish_at)
                    : "-"
                }}
              </span>
            </template>

            <!-- <template v-slot:[`item.creative_power`]="{}">
              <div>
                <v-chip
                  label
                  small
                  class="ma-2 font-semibold"
                  color="green"
                  text-color="white"
                >
                  -
                </v-chip>
              </div>
            </template> -->

            <template v-slot:[`item.status_id`]="{ item }">
              <div
                v-if="selected_config && selected_config.type === 'Facebook'"
              >
                <span
                  class="text-[11px] font-semibold"
                  :style="{
                    color: statusToText(item.general_creative.status_id).color,
                  }"
                >
                  {{ statusToText(item.general_creative.status_id).text }}
                </span>

                <v-tooltip
                  v-if="
                    [
                      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
                      111,
                    ].includes(item.general_creative.status_id)
                  "
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>{{
                    item.general_creative.status_text
                      ? item.general_creative.status_text
                      : "There is no info."
                  }}</span>
                </v-tooltip>
              </div>
              <div v-if="selected_config && selected_config.type === 'Tiktok'">
                <span
                  class="text-[11px] font-semibold"
                  :style="{
                    color: statusToText(item.general_creative.tiktok_status_id)
                      .color,
                  }"
                >
                  {{
                    statusToText(item.general_creative.tiktok_status_id).text
                  }}
                </span>

                <v-tooltip
                  v-if="
                    [
                      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
                      111,
                    ].includes(item.general_creative.tiktok_status_id)
                  "
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>{{
                    item.general_creative.status_text
                      ? item.general_creative.status_text
                      : "There is no info."
                  }}</span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:[`item.status_id_2`]="{ item }">
              <span
                class="text-[11px] font-semibold"
                :style="{
                  color: statusToText(item.general_creative.status_id_2).color,
                }"
              >
                {{ statusToText(item.general_creative.status_id_2).text }}
              </span>

              <v-tooltip
                v-if="
                  [
                    100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111,
                  ].includes(item.general_creative.status_id_2)
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>{{
                  item.general_creative.status_text_2
                    ? item.general_creative.status_text_2
                    : "There is no info."
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.ad_started_at`]="{ item }">
              <span
                v-if="selected_config && selected_config.type === 'Facebook'"
              >
                {{
                  item.general_creative.ad_started_at
                    ? $formatUnix(item.general_creative.ad_started_at)
                    : "-"
                }}
              </span>
              <span v-if="selected_config && selected_config.type === 'Tiktok'">
                {{
                  item.general_creative.tiktok_ad_started_at
                    ? $formatUnix(item.general_creative.tiktok_ad_started_at)
                    : "-"
                }}
              </span>
            </template>

            <template v-slot:[`item.preview`]="{ item }">
              <div v-if="selected_config && selected_config.type == 'Facebook'">
                <a
                  target="_blank"
                  class="font-semibold text-[11px] hover:underline"
                  v-if="item.general_creative.status_id === 5"
                  :href="`${computedGaiaURL}#/act/creative-insights?id=${item.base_creative_fb_id}`"
                  >Gaia</a
                >

                <a
                  class="ml-2 font-semibold text-[11px] hover:underline"
                  target="_blank"
                  v-if="
                    item.general_creative.ad_fb_id &&
                    item.general_creative.network_act_id
                  "
                  :href="`https://business.facebook.com/adsmanager/manage/ads/edit?act=${item.general_creative.network_act_id}&business_id=166522864946756&global_scope_id=166522864946756&nav_entry_point=am_local_scope_selector&columns=name%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cattribution_setting%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cactions%3Aapp_install%2Cactions%3Amobile_app_install%2Cactions%3Alink_click%2Cactions%3Avideo_view%2Cvideo_avg_percent_watched_actions%3Avideo_view%2Cvideo_avg_time_watched_actions%3Avideo_view&attribution_windows=default&selected_ad_ids=${item.general_creative.ad_fb_id}&is_reload_from_account_change`"
                  >Facebook</a
                >

                <span
                  class="text-gray-700 font-semibold text-[11px]"
                  v-if="![3, 4, 5].includes(item.general_creative.status_id)"
                >
                  Not available
                </span>
              </div>
              <div v-if="selected_config && selected_config.type == 'Tiktok'">
                <a
                  target="_blank"
                  class="font-semibold text-[11px] hover:underline"
                  v-if="item.general_creative.tiktok_status_id === 5"
                  :href="`https://datastudio.google.com/reporting/00f50cbf-7dac-4f45-9f71-a621e1ad2725/page/p_1zp4ayc61c?params={&quot;tiktok.start_date_param&quot;:&quot;${formatDateForURL(
                    item.general_creative.tiktok_ad_started_at
                  )}&quot;,&quot;tiktok.end_date_param&quot;:&quot;${formatDateForURL(
                    item.general_creative.tiktok_ad_finish_at
                  )}&quot;,&quot;tiktok.ad_id_param&quot;:&quot;${
                    item.general_creative.tiktok_ad_id
                  }&quot;}`"
                  >Data Studio</a
                >

                <a
                  class="ml-2 font-semibold text-[11px] hover:underline"
                  target="_blank"
                  v-if="
                    item.general_creative.tiktok_ad_id &&
                    item.general_creative.network_act_id
                  "
                  :href="`https://business.facebook.com/adsmanager/manage/ads/edit?act=${item.general_creative.network_act_id}&business_id=166522864946756&global_scope_id=166522864946756&nav_entry_point=am_local_scope_selector&columns=name%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cattribution_setting%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cactions%3Aapp_install%2Cactions%3Amobile_app_install%2Cactions%3Alink_click%2Cactions%3Avideo_view%2Cvideo_avg_percent_watched_actions%3Avideo_view%2Cvideo_avg_time_watched_actions%3Avideo_view&attribution_windows=default&selected_ad_ids=${item.general_creative.ad_fb_id}&is_reload_from_account_change`"
                  >Facebook</a
                >

                <span
                  class="text-gray-700 font-semibold text-[11px]"
                  v-if="
                    ![3, 4, 5].includes(item.general_creative.tiktok_status_id)
                  "
                >
                  Not available
                </span>
              </div>
            </template>
          </v-data-table>
        </div>

        <div
          class="flex items-center justify-end mt-4"
          v-if="
            selected_config && [1, 7].includes(getUserProfile.department.id)
          "
        >
          <v-btn
            color="primary"
            @click="show_preview = true"
            depressed
            class="font-semibold"
            :disabled="computedSelectedRows.length === 0"
          >
            Create test for
            {{ computedSelectedRows.length }} item(s)
          </v-btn>
        </div>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-folder-play </v-icon>
        <span class="ml-4"> Medias </span>
      </h1>
      <div
        v-if="data.asset_medias"
        class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3"
      >
        <div
          class="p-5 hover:bg-gray-100 text-center"
          v-for="item in data.asset_medias"
          :key="item.id"
        >
          <div class="font-semibold text-[12px] mb-3">{{ item.title }}</div>
          <video-player
            class="video-player-box vjs-big-play-centered w-full"
            ref="videoPlayer"
            :options="
              computedOptions(
                item.signed_url ? item.signed_url : item.blob_info
              )
            "
            :playsinline="true"
          >
          </video-player>
        </div>
      </div>
      <!-- <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-tag-multiple </v-icon>
        <span class="ml-4">Base Creative Metadata </span>
      </h1>
      <div v-if="data.extra_data">
        <div class="grid md:grid-cols-3 grid-cols-2 gap-5">
          <div>
            <v-text-field
              label="Headline Text"
              v-model="data.extra_data.headline_text"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Headline Text Color"
              v-model="data.extra_data.headline_text_color"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Headline Text Font"
              v-model="data.extra_data.headline_text_font"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="CTA Text"
              v-model="data.extra_data.cta_text"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="CTA Text Color"
              v-model="data.extra_data.cta_text_color"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="CTA Shows At MS"
              v-model="data.extra_data.cta_shows_at_ms"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Action Button Text"
              v-model="data.extra_data.action_button_text"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Action Button Color"
              v-model="data.extra_data.action_button_color"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              label="Background Color"
              v-model="data.extra_data.background_color"
              hide-details
              outlined
              disabled
              class="text-[13px]"
              dense
            ></v-text-field>
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.extra_data.style"
              disabled
              label="Style"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.extra_data.theme"
              disabled
              label="Theme"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.extra_data.main_character"
              disabled
              label="Main Character"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.extra_data.triggering_emotion"
              disabled
              label="Triggering Emotion"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-text-field
              clearable
              dense
              outlined
              hide-details
              :value="
                data.extra_data.voice_over_status ? 'Existent' : 'Non Existent'
              "
              disabled
              label="Voice Over Status"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              hide-details
              class="text-[13px]"
              label="Pointer"
              disabled
              item-text="name"
              item-value="filename"
              outlined
              dense
              v-model="data.extra_data.pointer"
            />
          </div>
          <div>
            <v-text-field
              hide-details
              class="text-[13px]"
              label="Point of View"
              disabled
              outlined
              dense
              v-model="data.extra_data.point_of_view"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.extra_data.keywords"
              disabled
              label="Keywords"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.extra_data.game_action"
              disabled
              label="Game Action"
              class="text-[13px]"
            />
          </div>
          <div>
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="data.extra_data.game_genre"
              disabled
              label="Game Genre"
              class="text-[13px]"
            />
          </div>
        </div>
        <div class="my-4 flex items-center justify-end">
          <v-btn disabled color="primary">Update</v-btn>
        </div>
      </div> -->

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-pencil </v-icon>
        <span class="ml-4">Edit Creative Title</span>
      </h1>

      <div>
        <div>
          <v-text-field
            label="Creative Title"
            v-model="data.title"
            hide-details
            outlined
            class="text-[13px]"
            dense
            :loading="loading"
            :disabled="loading"
          ></v-text-field>
        </div>
        <div
          class="mt-2"
          v-for="(media, index) in data.asset_medias"
          :key="index"
        >
          <v-text-field
            :label="`Media Title (${media.type})`"
            v-model="media.title_without_extension"
            hide-details
            outlined
            class="text-[13px]"
            dense
            :loading="loading"
            :disabled="loading"
          ></v-text-field>
        </div>
        <div class="flex items-center justify-end mt-2.5">
          <v-btn
            color="primary"
            @click="updateTitles"
            :loading="updateTitleButtonLoading"
            :disabled="updateTitleButtonLoading"
            >Update</v-btn
          >
        </div>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-pencil </v-icon>
        <span class="ml-4">Update Input Replay</span>
      </h1>

      <div v-if="data.replay_file" class="text-[12px] mb-4">
        <span class="text-blue-700">Current:</span>
        <span class="text-black/60 inline-block ml-1">{{
          data.replay_file
        }}</span>
      </div>

      <div>
        <div>
          <v-file-input
            show-size
            accept=".bin"
            label="Choose Bin file"
            class="text-[13px]"
            outlined
            :loading="inputReplayLoading"
            :disabled="inputReplayLoading"
            dense
            hide-details
            v-model="replayFile"
          >
          </v-file-input>
        </div>
        <div class="flex items-center justify-end mt-2.5">
          <v-btn
            color="primary"
            @click="handleSignedUpload"
            :loading="inputReplayLoading"
            :disabled="!replayFile"
            >Update</v-btn
          >
        </div>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-palette </v-icon>
        <span class="ml-4"> Creative Color Palette </span>
      </h1>

      <div v-if="data.extra_data && data.extra_data.color_palette">
        <div
          v-for="(item, index) in data.extra_data.color_palette"
          :key="index"
          class="inline-block mr-5"
        >
          <div class="flex flex-col items-center justify-center">
            <div class="font-semibold mb-1 text-gray-600 text-[12px]">
              {{ item.percent }}%
            </div>
            <div
              class="colorbox"
              :style="{ backgroundColor: `rgb(${item.color})` }"
            ></div>
          </div>
        </div>
      </div>

      <h1 class="font-semibold text-[14px] my-4 flex items-center">
        <v-icon> mdi-music </v-icon>
        <span class="ml-4"> Sound & General Information </span>
      </h1>

      <div>
        <div
          class="text-[12px]"
          v-if="data.extra_data && data.extra_data.duration_ms"
        >
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Creative Duration:</span
          >
          <span class="text-gray-500"
            >{{ Math.round(data.extra_data.duration_ms / 1000) }} seconds</span
          >
        </div>
        <div class="text-[12px] mt-2" v-if="data.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Language:</span
          >
          <span class="text-gray-500">{{
            data.extra_data.language ? data.extra_data.language : "-"
          }}</span>
        </div>
        <div class="text-[12px] mt-2" v-if="data.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Background Sound ID:</span
          >
          <span class="text-gray-500">{{
            data.extra_data.background_sound
              ? data.extra_data.background_sound.id
              : "-"
          }}</span>
        </div>
        <div class="text-[12px] mt-2" v-if="data.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Background Sound URL:</span
          >
          <a
            class="text-gray-500"
            :href="
              data.extra_data.background_sound
                ? data.extra_data.background_sound.sound_url
                : '#'
            "
            target="_blank"
            >{{
              data.extra_data.background_sound
                ? data.extra_data.background_sound.sound_url
                : "-"
            }}</a
          >
        </div>
        <div class="text-[12px] mt-2" v-if="data.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Background Sound Tags:</span
          >
          <span class="text-gray-500">{{
            data.extra_data.background_sound &&
            data.extra_data.background_sound.tags
              ? data.extra_data.background_sound.tags.join(", ")
              : "-"
          }}</span>
        </div>
        <div class="text-[12px] mt-2" v-if="data.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Reaction Sound ID:</span
          >
          <span class="text-gray-500">{{
            data.extra_data.reaction_sound
              ? data.extra_data.reaction_sound.id
              : "-"
          }}</span>
        </div>
        <div class="text-[12px] mt-2" v-if="data.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Reaction Sound URL:</span
          >
          <a
            :href="
              data.extra_data.reaction_sound
                ? data.extra_data.reaction_sound.sound_url
                : '#'
            "
            target="_blank"
            class="text-gray-500"
            >{{
              data.extra_data.reaction_sound
                ? data.extra_data.reaction_sound.sound_url
                : "-"
            }}</a
          >
        </div>
        <div class="text-[12px] mt-2" v-if="data.extra_data">
          <span class="font-semibold inline-block mr-1 text-gray-700"
            >Reaction Sound Tags:</span
          >
          <span class="text-gray-500">{{
            data.extra_data.reaction_sound
              ? data.extra_data.reaction_sound.tags.join(", ")
              : "-"
          }}</span>
        </div>
      </div>
    </div>
    <UACreateTestPreview
      :rows="computedSelectedRows"
      :network="selected_config"
      :show="show_preview"
      @closed="show_preview = false"
      @testCreated="handleOnTestCreated"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import UACreateTestPreview from "@/components/tools/act/UACreateTestPreview.vue";

export default {
  name: "ManageAssetsView",
  data() {
    return {
      replayFile: null,
      data: null,
      loading: false,
      crumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: "/",
          exact: true,
        },
        {
          text: "Manage Assets",
          disabled: false,
          to: "/act/manage-assets",
          exact: true,
        },
        {
          text: "Asset Detail",
          disabled: true,
        },
      ],
      show_edit_asset: false,
      asset_data: null,
      combination_data: [],
      selectedRows: [],
      selected_config: null,
      show_preview: false,
      networks: [],
      table_loading: false,
      inputReplayLoading: false,
      updateTitleButtonLoading: false,
    };
  },
  methods: {
    async updateCreativeReplayInformation() {
      try {
        const response = await this.$api.post(
          `act/general-creatives/update-replay-files?replayFileName=${this.replayFile.name}&creative_id=${this.$route.params.id}`
        );

        if (response.status === 200) {
          this.$toast.success("Creative successfully updated.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          this.fetchAssetDetail();
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },

    async handleSignedUpload() {
      try {
        if (this.replayFile) {
          this.inputReplayLoading = true;
          const signedUrl = await this.createSignedUrl(
            this.replayFile.name,
            this.$route.params.id
          );
          const uploadResult = await this.uploadToSignedUrl(
            signedUrl,
            this.replayFile
          );

          if (uploadResult) {
            await this.updateCreativeReplayInformation();
          }
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.inputReplayLoading = false;
      }
    },
    async createSignedUrl(fileName, creative_id) {
      // eslint-disable-next-line
      try {
        const response = await this.$api.post(
          `utils/signed-url?filename=${fileName}&creative_id=${creative_id}`
        );
        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        throw error;
      }
    },

    async uploadToSignedUrl(signed_url, file) {
      // eslint-disable-next-line
      try {
        const response = await this.$makeXMLRequest(signed_url, file);
        if (response) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        throw error;
      }
    },

    async updateTitles() {
      const requestData = {
        id: this.data.id,
        title: this.data.title,
        asset_medias: this.data.asset_medias.map((x) => ({
          title: x.title_without_extension,
          id: x.id,
        })),
      };

      try {
        this.updateTitleButtonLoading = true;
        const response = await this.$api.post(
          `act/general-creatives/update-titles`,
          requestData
        );
        if (response.status === 200) {
          this.fetchAssetDetail();
          this.$toast.success("Title successfuly updated.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.updateTitleButtonLoading = false;
      }
    },

    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    fetchAssetDetail() {
      this.loading = true;
      this.$api
        .get(`act/general-creatives/${this.$route.params.id}`)
        .then((response) => {
          this.data = response.data;
          this.loading = false;
          if (this.data.is_base) {
            this.fetchCombinations();
            this.fetchConfigurations();
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    randomPower(min, max) {
      return Math.random() * (max - min) + min;
    },
    handleClick(event) {
      this.asset_data = event;
      this.show_edit_asset = true;
    },
    handleOnClosed() {
      this.asset_data = null;
      this.show_edit_asset = false;
    },
    computedOptions(url) {
      return {
        sources: [
          {
            type: "video/mp4",
            src: url,
          },
        ],
        language: "en",
        width: "100%",
        height: "220px",
      };
    },
    fetchCombinations() {
      if (this.data) {
        this.table_loading = true;
        this.$api
          .get(`act/general-creatives/${this.$route.params.id}/combinations`)
          .then((response) => {
            console.log(response.data);
            this.combination_data = response.data;
            this.table_loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.table_loading = false;
          });
      }
    },
    deleteAsset(id) {
      this.loading = true;
      this.$api
        .delete(`act/general-creatives/${id}`)
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: response.data.detail,
            });
            this.fetchCombinations();
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    statusToText(id) {
      let data = {
        text: null,
        color: null,
      };

      if (id === 2) {
        data.text = "Not Tested";
        data.color = "#dc2626";
      }
      if (id === 3) {
        data.text = "Testing";
        data.color = "#ca8a04";
      }
      if (id === 4) {
        data.text = "Tested (Not saved yet)";
        data.color = "#0284c7";
      }
      if (id === 5) {
        data.text = "Tested";
        data.color = "#65a30d";
      }

      if (id === 10) {
        data.text = "In queue";
        data.color = "#7c3aed";
      }
      if (id === 11) {
        data.text = "In queue";
        data.color = "#7c3aed";
      }

      if (id === 12) {
        data.text = "In queue";
        data.color = "#7c3aed";
      }

      if (id === 20) {
        data.text = "Rejected";
        data.color = "#dc2626";
      }
      if (id === 21) {
        data.text = "Rejected";
        data.color = "#dc2626";
      }
      if (
        [100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111].includes(
          id
        )
      ) {
        data.text = "Error";
        data.color = "#dc2626";
      }

      return data;
    },
    formatDateForURL(unix) {
      return moment.unix(unix).utcOffset("GMT-00:00").format("YYYY-MM-DD");
    },
    handleOnTestCreated() {
      this.fetchAssetDetail();
      this.selectedRows = [];
    },
    fetchConfigurations() {
      this.$api
        .get(`act/network/all?selected_game=${this.data.game_id}`)
        .then((response) => {
          if (response.status === 200) {
            this.networks = response.data;
            this.selected_config = response.data.find(
              (x) => x.type === "Facebook"
            );
          }
        });
    },
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
    computedSelectedRows() {
      if (this.selected_config) {
        if (this.selected_config.type === "Facebook") {
          return this.selectedRows.filter(
            (x) => x.general_creative.status_id === 2
          );
        }
        if (this.selected_config.type === "Tiktok") {
          return this.selectedRows.filter(
            (x) => x.general_creative.tiktok_status_id === 2
          );
        }
        if (this.selected_config.type === "unity") {
          return this.selectedRows.filter(
            (x) => x.general_creative.unity_status_id === 2
          );
        }
      }
      return [];
    },
    headers() {
      var columns = [
        { text: "ID", value: "id" },
        { text: "Title", value: "title" },
        { text: "Creator", value: "user" },
        { text: "Created At", value: "created_at" },
      ];

      if (this.selected_config) {
        columns.push(
          { text: "Ad Started At", value: "ad_started_at" },
          { text: "Finish At", value: "finish_at" }
        );
      }
      columns.push({ text: "Status", value: "status_id" });

      if (this.selected_config && this.selected_config.type === "Facebook") {
        columns.push({ text: "Status (2nd Test)", value: "status_id_2" });
      }
      // columns.push({ text: "Creative Power", value: "creative_power" });
      columns.push({ text: "Preview", value: "preview" });

      if (this.getUserProfile.department.id !== 7) {
        columns.push({ text: "Actions", value: "actions" });
      }
      return columns;
    },
    computedGaiaURL() {
      return process.env.VUE_APP_GAIA_PANEL_URL;
    },
  },
  mounted() {
    this.fetchAssetDetail();
  },
  components: {
    UACreateTestPreview,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-breadcrumbs {
  padding: 0;
  li {
    font-size: 12px !important;
    font-weight: 600;
  }
}
::v-deep .custom-pointer {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
::v-deep .video-js {
  width: 100% !important;
}
.colorbox {
  height: 80px;
  width: 80px;
  display: inline-block;
  border-radius: 4px;
}

.combination-table {
  ::v-deep .v-list-item {
    min-height: 32px !important;
  }
}
</style>
