var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-white p-6 w-full"},[_c('div',{staticClass:"flex items-center justify-between pb-6"},[_c('h1',{staticClass:"font-semibold text-[18px]"},[_vm._v("CPA Logs")]),_c('div',{staticClass:"flex items-center justify-end"},[_c('v-btn',{staticClass:"font-semibold mr-2",attrs:{"color":"success","small":"","depressed":"","text":""},on:{"click":_vm.exportAsCSV}},[_vm._v(" Export as CSV ")]),_c('v-btn',{staticClass:"font-semibold mr-2",attrs:{"color":"success","small":"","depressed":"","text":""},on:{"click":_vm.insertNewRow}},[_vm._v(" New ")]),_c('v-btn',{staticClass:"font-semibold",attrs:{"color":"primary","small":"","depressed":"","loading":_vm.btnLoading,"disabled":_vm.btnLoading || _vm.isApplyBtnDisabled},on:{"click":_vm.applyChanges}},[_vm._v(" Apply Changes ")])],1)]),(
        !_vm.tableLoading &&
        !_vm.networksLoading &&
        !_vm.countriesLoading &&
        !_vm.gamesLoading &&
        !_vm.conversionEventsLoading
      )?_c('div',[_c('ag-grid-vue',{staticClass:"ag-theme-quartz",staticStyle:{"width":"100%","height":"900px"},attrs:{"columnDefs":_vm.columnDefs,"rowData":_vm.cpaData,"gridOptions":_vm.gridOptions},on:{"grid-ready":_vm.onGridReady,"cellClicked":_vm.onCellEditingStarted,"cellValueChanged":_vm.onCellValueChanged}}),(!_vm.cpaData.length)?_c('div',{staticClass:"px-4 pt-6 text-gray-500 text-[12px] flex items-center justify-center"},[_vm._v(" There is nothing to show. ")]):_vm._e()],1):_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }