<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Summary </v-card-title>

        <v-card-text>
          <div>
            <div>
              <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
                Selected creatives will be
                <span class="text-lime-600">added</span> into these adsets:
              </h1>
              <div class="mt-2">
                <div
                  v-for="(data, idx) in computedImportedDataForSummary"
                  :key="idx"
                  class="mb-1.5"
                >
                  <div class="font-semibold text-[11px] flex items-center">
                    <span class="text-sky-600">
                      {{ data.creative_name }}
                    </span>
                    <span class="ml-2 mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-arrow-narrow-right"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        stroke-width="1.75"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5 12l14 0"></path>
                        <path d="M15 16l4 -4"></path>
                        <path d="M15 8l4 4"></path>
                      </svg>
                    </span>
                    <span
                      v-for="adgroup in data.adgroup_data"
                      :key="adgroup.adgroup_id"
                      class="text-gray-700 p-1 rounded-[3px] bg-gray-100 ml-1 hover:bg-gray-200"
                    >
                      {{ adgroup.adgroup_name }}
                    </span>
                  </div>
                </div>
              </div>

              <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
                These creatives will be completely
                <span class="text-rose-600">deleted</span> from these adsets:
              </h1>

              <div class="mt-2">
                <div v-for="adgroup in adgroupData" :key="adgroup.id">
                  <div v-if="adgroup.selectedAdsForDeleting.length">
                    <div
                      class="font-semibold text-[11px] flex items-center mb-1.5"
                      v-for="(ad, adIdx) in adgroup.selectedAdsForDeleting"
                      :key="adIdx"
                    >
                      <span class="text-sky-600">
                        {{ ad.name }}
                      </span>
                      <span class="ml-2 mr-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-arrow-narrow-right"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          stroke-width="1.75"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M5 12l14 0"></path>
                          <path d="M15 16l4 -4"></path>
                          <path d="M15 8l4 4"></path>
                        </svg>
                      </span>
                      <span
                        class="text-gray-700 p-1 rounded-[3px] bg-gray-100 ml-1 hover:bg-gray-200"
                      >
                        {{ adgroup.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submitSummaryForTasks">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    adgroupData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    computedImportedDataForSummary() {
      var data = [];

      for (let i = 0; i < this.selectedCreatives.length; i++) {
        var temp = {
          adgroup_data: [],
          creative_id: this.selectedCreatives[i].ad_id,
          creative_name: this.selectedCreatives[i].ad_name,
        };

        const selectedCreative = this.selectedCreatives[i];
        for (let y = 0; y < this.adgroupData.length; y++) {
          const adgroupData = this.adgroupData[y];

          for (let z = 0; z < adgroupData.selectedAdsForAdding.length; z++) {
            const selectedAdForAdding = adgroupData.selectedAdsForAdding[z];
            if (
              selectedAdForAdding.ad_id === selectedCreative.ad_id &&
              selectedAdForAdding.selected
            ) {
              temp.adgroup_data.push({
                adgroup_name: adgroupData.name,
                adgroup_id: adgroupData.id,
              });
            }
          }
        }

        data.push(temp);
      }

      return data;
    },
  },
  methods: {
    submitSummaryForTasks() {
      this.$emit("submitted");
    },
  },
};
</script>

<style lang="scss" scoped></style>
