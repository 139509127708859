var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white p-6"},[_c('h1',{staticClass:"font-semibold text-[18px] pb-6"},[_vm._v("Upload Build")]),(_vm.file)?_c('div',{staticClass:"mt-5"},[_c('v-alert',{attrs:{"text":"","prominent":"","type":"warning","icon":"mdi-alert-circle-outline"}},[_c('div',{staticClass:"text-[12px] font-semibold"},[_vm._v(" Please be sure to choose the right build! ")])])],1):_vm._e(),(_vm.servers.length)?_c('div',{staticClass:"mt-5"},_vm._l((_vm.servers),function(server,index){return _c('div',{key:server.id,staticClass:"border p-4 rounded border-gray-150 mb-2"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center text-[12px] font-semibold"},[_c('span',{staticClass:"text-gray-700 mr-2"},[_vm._v("Server:")]),_c('span',{staticClass:"text-gray-500"},[_vm._v(_vm._s(server.name))])]),_c('div',{staticClass:"flex items-center text-[12px] font-semibold mt-1"},[_c('span',{staticClass:"text-gray-700 mr-2"},[_vm._v("Status:")]),_c('span',{class:['TERMINATED', 'STAGING', 'STOPPING'].includes(server.status)
                  ? 'text-red-500'
                  : 'text-green-500'},[_vm._v(_vm._s(server.status))])])]),_c('v-btn',{staticClass:"font-semibold",attrs:{"color":"primary","small":"","depressed":"","text":"","loading":server.loading,"disabled":server.loading || server.status === 'RUNNING'},on:{"click":function($event){return _vm.updateInstanceStatus(server.name, index)}}},[_vm._v(" Turn on ")])],1)])}),0):_vm._e(),_c('div',{staticClass:"mt-5"},[_c('div',[_c('v-select',{staticClass:"text-[13px]",attrs:{"hide-details":"","item-text":"title","item-value":"id","label":"Choose a game","loading":_vm.is_uploading,"disabled":_vm.is_uploading,"outlined":"","dense":"","items":_vm.games},model:{value:(_vm.selected_game),callback:function ($$v) {_vm.selected_game=$$v},expression:"selected_game"}})],1)]),_c('div',{staticClass:"mt-4"},[_c('v-text-field',{staticClass:"text-[13px]",attrs:{"label":"Build Title","outlined":"","dense":"","hide-details":"","loading":_vm.is_uploading},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),(_vm.selected_game)?_c('div',{staticClass:"mt-5"},[_c('v-file-input',{staticClass:"text-[13px]",attrs:{"chips":"","hide-details":"","small-chips":"","truncate-length":"32","outlined":"","dense":"","placeholder":"Game build (zip)","accept":"application/zip"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1):_vm._e(),(_vm.selected_game)?_c('div',{staticClass:"mt-5"},[_c('v-file-input',{staticClass:"text-[13px]",attrs:{"chips":"","hide-details":"","truncate-length":"32","outlined":"","dense":"","placeholder":"Choose the JSON belongs this build","accept":"application/JSON"},model:{value:(_vm.parameter_file),callback:function ($$v) {_vm.parameter_file=$$v},expression:"parameter_file"}})],1):_vm._e(),_c('div',{staticClass:"flex items-center justify-end mt-4"},[_c('v-btn',{attrs:{"depressed":"","disabled":_vm.is_uploading ||
        !_vm.file ||
        !_vm.selected_game ||
        !this.title ||
        _vm.isBtnDisabled,"color":"success","loading":_vm.is_uploading},on:{"click":_vm.uploadBuild}},[_vm._v(" Upload ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }