<template>
  <div v-if="getUserProfile">
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Creative Insights</h1>

      <div class="mb-5 grid grid-cols-12 gap-3">
        <div class="md:col-span-2 col-span-12">
          <div>
            <v-autocomplete
              clearable
              flat
              label="Choose Game"
              v-model="selected_game"
              dense
              outlined
              rounded
              class="text-[12px]"
              hide-details
              :items="games"
              item-text="title"
              item-value="id"
            >
            </v-autocomplete>
          </div>
        </div>

        <div class="md:col-span-2 col-span-12">
          <div>
            <v-select
              flat
              label="Channel"
              v-model="selected_channel"
              dense
              outlined
              rounded
              class="text-[12px]"
              hide-details
              :items="['Facebook', 'Tiktok', 'Unity']"
            >
            </v-select>
          </div>
        </div>
        <div class="md:col-span-2 col-span-12">
          <div>
            <v-select
              flat
              label="Creative Type"
              v-model="selected_creative_type"
              dense
              outlined
              rounded
              class="text-[12px]"
              hide-details
              :items="['Only base creatives', 'Only combinations', 'Show all']"
            >
            </v-select>
          </div>
        </div>
        <div class="md:col-span-2 col-span-12">
          <v-menu
            ref="show_date_filter"
            v-model="show_date_filter"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="date_filter_formatted"
                label="Filter Date"
                class="text-[13px]"
                hide-details
                dense
                v-bind="attrs"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_filter"
              color="primary"
              range
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="md:col-span-4 col-span-12">
          <v-text-field
            filled
            hide-details
            rounded
            placeholder="Search..."
            class="text-[12px] w-full"
            dense
            v-model="keyword"
          />
        </div>
      </div>
      <div>
        <v-data-table
          :loading="data_loading"
          :headers="headers"
          :items="creative_data"
          :show-select="
            getUserProfile.tools.some((x) => x.id === tools.CAMPAIGN_MANAGER)
          "
          item-key="ad_id"
          v-model="selectedRows"
        >
          <template v-slot:[`item.ad_name`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ item.ad_name }}
            </span>
          </template>
          <template v-slot:[`item.end_date`]="{ item }">
            <span class="text-gray-600 text-[12px]">
              {{ item.end_date }}
            </span>
          </template>
          <template v-slot:[`item.impressions`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ item.impressions.toLocaleString("de-DE") }}
            </span>
          </template>
          <template v-slot:[`item.cpi`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              $ {{ Math.round(item.cpi * 10000) / 10000 }}
            </span>
          </template>
          <template v-slot:[`item.cpm`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              $ {{ Math.round(item.cpm * 1000) / 1000 }}
            </span>
          </template>
          <template v-slot:[`item.ctr`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ Math.round(item.ctr * 100) / 100 }} %
            </span>
          </template>
          <template v-slot:[`item.ipm`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ Math.round(item.ipm * 100) / 100 }}
            </span>
          </template>
          <template v-slot:[`item.ref_impressions`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ item.ref_impressions.toLocaleString("de-DE") }}
            </span>
          </template>
          <template v-slot:[`item.ref_cpi`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              $ {{ Math.round(item.ref_cpi * 10000) / 10000 }}
            </span>
          </template>
          <template v-slot:[`item.ref_cpm`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              $ {{ Math.round(item.ref_cpm * 1000) / 1000 }}
            </span>
          </template>
          <template v-slot:[`item.ref_ctr`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ Math.round(item.ref_ctr * 100) / 100 }} %
            </span>
          </template>
          <template v-slot:[`item.ref_ipm`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ Math.round(item.ref_ipm * 100) / 100 }}
            </span>
          </template>
          <template v-slot:[`item.weight`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ Math.round(item.weight * 100) / 100 }}
            </span>
          </template>
          <template v-slot:[`item.creative_power`]="{ item }">
            <span class="text-gray-800 text-[12px] font-semibold">
              {{ Math.round(item.creative_power * 100) / 100 }}
            </span>
          </template>
          <template v-slot:[`item.improvement`]="{ item }">
            <div
              class="flex items-center"
              :class="colorizeImprovement(item.improvement)"
            >
              <span v-if="item.improvement > 0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-trending-up"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 17l6 -6l4 4l8 -8"></path>
                  <path d="M14 7l7 0l0 7"></path>
                </svg>
              </span>
              <span v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-trending-down"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 7l6 6l4 -4l8 8"></path>
                  <path d="M21 10l0 7l-7 0"></path>
                </svg>
              </span>
              <span class="text-[12px] font-semibold ml-2">
                {{ Math.round(item.improvement * 100) / 100 }} %
              </span>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div
              class="flex items-center text-gray-400 hover:text-blue-600 cursor-pointer"
              @click="showDetails(item)"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-file-description"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                  <path
                    d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
                  ></path>
                  <path d="M9 17h6"></path>
                  <path d="M9 13h6"></path>
                </svg>
              </span>
              <span class="ml-2 text-[12px]"> Details </span>
            </div>
          </template>
        </v-data-table>
        <div
          class="flex items-center justify-between mt-5"
          v-if="
            getUserProfile.tools.some((x) => x.id === tools.CAMPAIGN_MANAGER)
          "
        >
          <v-btn
            color="secondary"
            depressed
            class="font-semibold"
            @click="copyToDrive"
            text
            :loading="uploadLoading"
            :disabled="uploadLoading || !selectedRows.length"
          >
            Copy to Drive
          </v-btn>

          <v-btn
            color="primary"
            depressed
            :disabled="!selectedRows.length"
            class="font-semibold"
            @click="navigateBurcha"
          >
            Choose for Burcha
          </v-btn>
        </div>
      </div>
    </div>

    <CreativeInsightDetail
      :row="clickedRow"
      :show="show_detail"
      @closed="handleDetailClose"
    />

    <CopyToDriveDialog
      @closed="showCopyToDriveDialog = false"
      :show="showCopyToDriveDialog"
      :selectedGame="games.find((x) => x.id === selected_game)"
      @submitted="copyCreativesToDrive"
    />
  </div>
</template>

<script>
import CreativeInsightDetail from "@/components/tools/act/CreativeInsightDetail.vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import { tools } from "@/helper/tools";
import CopyToDriveDialog from "@/components/tools/act/CopyToDriveDialog.vue";

export default {
  name: "CreativeInsightsView",
  data() {
    return {
      selected_channel: "Facebook",
      selected_creative_type: "Only base creatives",
      creative_data: [],
      creative_data_copy: null,
      data_loading: false,
      date_filter: [],
      date_filter_formatted: null,
      show_date_filter: false,
      headers: [
        {
          text: "Title",
          value: "ad_name",
          class: "text-blue-400",
          width: "230px",
        },
        {
          text: "Improvement",
          value: "improvement",
          align: "center",
          width: "130px",
        },
        {
          text: "End Date",
          value: "end_date",
          width: "120px",
        },
        {
          text: "Impressions",
          value: "impressions",
          align: "center",
          width: "120px",
        },
        {
          text: "CPI",
          value: "cpi",
          align: "center",
          width: "120px",
        },
        {
          text: "CPM",
          value: "cpm",
          align: "center",
          width: "120px",
        },
        {
          text: "CTR",
          value: "ctr",
          align: "center",
          width: "120px",
        },
        {
          text: "IPM",
          value: "ipm",
          align: "center",
          width: "120px",
        },
        {
          text: "Ref. Impression",
          value: "ref_impressions",
          align: "center",
          width: "140px",
        },
        {
          text: "Ref. CPI",
          value: "ref_cpi",
          align: "center",
          width: "120px",
        },
        {
          text: "Ref. CPM",
          value: "ref_cpm",
          align: "center",
          width: "120px",
        },
        {
          text: "Ref. CTR",
          value: "ref_ctr",
          align: "center",
          width: "120px",
        },
        {
          text: "Ref. IPM",
          value: "ref_ipm",
          align: "center",
          width: "120px",
        },

        {
          text: "Weight",
          value: "weight",
          align: "center",
          width: "130px",
        },
        {
          text: "Power",
          value: "creative_power",
          align: "center",
          width: "130px",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
      show_detail: false,
      clickedRow: null,
      keyword: "",
      games: [],
      selected_game: null,
      selectedRows: [],
      tools,
      showCopyToDriveDialog: false,
      uploadLoading: false,
    };
  },
  methods: {
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async copyCreativesToDrive(networks) {
      try {
        const requestData = {
          folders: networks.map((x) => ({
            title: x.title,
            value: x.value,
          })),
          creatives: this.selectedRows.map((x) => x.gaia_id),
        };

        this.uploadLoading = true;
        const response = await this.$api.post(
          "utils/copy-creatives",
          requestData
        );
        if (response.status === 200) {
          this.$toast.success(
            "Selected creatives successfully uploaded Drive. You MUST have received success messages on Slack.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.uploadLoading = false;
      }
    },
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDetailClose() {
      this.show_detail = false;
      this.clickedRow = null;
    },
    fetchInsightData() {
      if (this.selected_game) {
        this.data_loading = true;
        var url = `act/general-creatives/insights?game_id=${this.selected_game}&type=${this.selected_creative_type}&channel=${this.selected_channel}`;
        if (this.date_filter_formatted) {
          const parsedDate = this.date_filter_formatted.split("~");
          url = `${url}&end_date_min=${parsedDate[0]}&end_date_max=${parsedDate[1]}`;
        }
        this.$api
          .get(url)
          .then((response) => {
            this.creative_data = response.data.map((item, index) => ({
              ...item,
              power:
                Math.round(
                  Math.pow(item.ipm, 3) * (item.improvement / 100 + 1) * 100
                ) / 100,
              index: index,
            }));
            this.creative_data_copy = [...this.creative_data];
            if (this.$route.query.id) {
              this.keyword = this.$route.query.id;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.data_loading = false;
          });
      }
    },
    getCPI(spend, mobile_app_install) {
      return spend / mobile_app_install;
    },
    getCPM(spend, impressions) {
      return Math.round(((spend * 1000) / impressions) * 1000) / 1000;
    },
    getIPM(mobile_app_install, impressions) {
      return (
        Math.round(((mobile_app_install * 1000) / impressions) * 100) / 100
      );
    },
    getImprovement(creative_ipm, reference_ipm) {
      return (
        Math.round(
          ((creative_ipm - reference_ipm) / reference_ipm) * 100 * 100
        ) / 100
      );
    },
    getCTR(clicks, impressions) {
      return Math.round((clicks / impressions) * 100 * 100) / 100;
    },
    colorizeImprovement(improvement) {
      if (improvement > 0 && improvement < 15) {
        return "text-green-800";
      }
      if (improvement >= 15 && improvement < 30) {
        return "text-green-700";
      }
      if (improvement >= 30 && improvement < 50) {
        return "text-green-600";
      }
      if (improvement >= 50) {
        return "text-green-500";
      }
      if (improvement < 0 && improvement > -15) {
        return "text-red-800";
      }
      if (improvement <= 15 && improvement > -30) {
        return "text-red-700";
      }
      if (improvement <= 30 && improvement > -50) {
        return "text-red-600";
      }
      if (improvement <= 50) {
        return "text-red-500";
      }
    },
    showDetails(item) {
      this.show_detail = true;
      this.clickedRow = item;
    },
    navigateBurcha() {
      this.$router.push({
        name: "campaign-manager-all-campaigns-view",
        params: {
          selectedCreatives: this.selectedRows,
          game_id: this.selected_game,
        },
      });
    },

    copyToDrive() {
      this.showCopyToDriveDialog = true;
    },
  },
  mounted() {
    this.fetchGames();
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
  },
  watch: {
    date_filter(newValue) {
      this.date_filter_formatted = null;
      if (newValue && newValue.length === 2) {
        var tempFilterDate = [...newValue];
        var minDateStr = tempFilterDate[0];
        var maxDateStr = tempFilterDate[1];

        const minDate = new Date(minDateStr);
        const maxDate = new Date(maxDateStr);

        if (minDate > maxDate) {
          tempFilterDate[0] = maxDateStr;
          tempFilterDate[1] = minDateStr;
        }

        this.date_filter_formatted = tempFilterDate.join("~");
        if (this.selected_game) {
          this.fetchInsightData();
        }
      }
    },

    date_filter_formatted(newValue) {
      if (!newValue) {
        this.date_filter = [];
        this.fetchInsightData();
      }
    },
    selected_game(newValue) {
      if (newValue) this.fetchInsightData();
    },
    selected_channel(newValue) {
      if (newValue) this.fetchInsightData();
    },
    selected_creative_type() {
      if (this.selected_game) {
        this.fetchInsightData();
      }
    },
    keyword: _.debounce(function (newValue) {
      if (newValue) {
        this.creative_data = this.creative_data_copy.filter((x) => {
          const regex = new RegExp(newValue, "gmi");
          return regex.test(x.ad_name) || regex.test(x.ad_id);
        });
      } else {
        this.creative_data = this.creative_data_copy;
      }
    }, 1000),
  },
  components: { CreativeInsightDetail, CopyToDriveDialog },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}

::v-deep tbody {
  tr {
    td {
      border-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
}

.popper {
  box-shadow: rgba(58, 58, 58, 0.3) 0 0 10px 0;
  padding: 20px;
}

::v-deep .v-data-table {
  overflow-x: auto !important;
}
</style>
