<template>
  <div>
    <div class="bg-white p-6 w-full">
      <h1 class="font-semibold text-[18px] pb-6">Applovin Placement Refresh</h1>

      <div>
        <div>
          <v-file-input
            show-size
            accept=".csv"
            label="Choose CSV"
            class="text-[13px]"
            outlined
            dense
            hide-details
            v-model="csv"
          >
          </v-file-input>
        </div>

        <div class="flex items-center justify-end mt-3">
          <v-btn
            :ripple="false"
            color="primary"
            :loading="loading"
            :disabled="!csv"
            depressed
            @click="updatePlacement"
          >
            Submit
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      csv: null,
      loading: false,
    };
  },
  methods: {
    async updatePlacement() {
      try {
        this.loading = true;
        let formData = new FormData();
        formData.append("csv_file", this.csv);

        const request = await this.$api.post(
          "applovin/placement-csv",
          formData
        );

        if (request.status === 200) {
          this.$toast.success(request.data.detail, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          this.csv = null;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
