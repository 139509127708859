<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Create Store Page</h1>
      <div>
        <div>
          <v-autocomplete
            v-model="gaia_game"
            :items="gaia_game_list"
            placeholder="Pick a Gaia Game"
            item-text="title"
            return-object
            outlined
            auto-select-first
            clearable
            dense
            class="text-[12px]"
          >
          </v-autocomplete>
        </div>
        <div class="mt-2">
          <v-text-field
            label="Subtitle"
            outlined
            v-model="ios_subtitle"
            class="text-[13px]"
            maxlength="25"
            dense
            counter
            :rules="[rules.required]"
          ></v-text-field>
        </div>
        <div class="mt-2">
          <v-textarea
            label="Long Description"
            outlined
            v-model="long_desc"
            dense
            class="text-[13px]"
            maxlength="500"
            :rules="[rules.required]"
            counter
          ></v-textarea>
        </div>
        <div>
          <v-file-input
            v-model="icon"
            label="Select an icon"
            outlined
            :show-size="1000"
            dense
            hide-details
            prepend-icon=""
            accept="image/*"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="deep-purple accent-4"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
        <div class="mt-2" v-if="icon">
          <div class="mt-2">
            <img :src="computedSrc(icon)" height="160" width="160" />
          </div>
        </div>
        <div class="mt-4">
          <v-file-input
            v-model="screenshots"
            label="Select screenshots"
            outlined
            multiple
            :show-size="1000"
            prepend-icon=""
            counter
            dense
            accept="image/*"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" color="primary" dark label small>
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
        <div v-if="screenshots.length > 0">
          <div>
            <hooper style="height: auto" :settings="hooperSettings">
              <slide
                v-for="(image, index) in screenshots"
                :key="index"
                style="height: 100%"
              >
                <div class="p-4 h-full main-section">
                  <div class="image-section">
                    <div class="flex items-center justify-between">
                      <div class="font-semibold text-center w-full">
                        {{ image.name }}
                      </div>
                      <div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="delete-section hover:bg-gray-200 rounded-full transition duration-200"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-close</v-icon>
                            </div>
                          </template>
                          <span class="text-[12px]">Click to remove</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <img height="270" :src="computedSrc(image)" />
                  </div>
                </div>
              </slide>

              <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end mt-4">
        <v-btn
          :disabled="isBtnDisabled || loading"
          :loading="loading"
          color="primary"
          @click="createStorePage"
        >
          Create
        </v-btn>
      </div>
    </div>
    <StorePagePreview
      @closed="handlePreviewClosed"
      :show="show_preview"
      :data="preview_data"
      @approved="handleOnPreviewApprove"
    ></StorePagePreview>
    <StorePageResult
      :show="show_result"
      :data="preview_data"
      @closed="handleOnResultClosed"
    ></StorePageResult>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
import StorePagePreview from "@/components/tools/ccc/tiktok/StorePagePreview.vue";
import StorePageResult from "@/components/tools/ccc/tiktok/StorePageResult.vue";

export default {
  name: "CreateStorePage",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    StorePagePreview,
    StorePageResult,
  },
  data() {
    return {
      gaia_game: null,
      ios_subtitle: "",
      long_desc: "",
      icon: null,
      screenshots: [],
      hooperSettings: {
        wheelControl: false,
        breakpoints: {
          1200: {
            centerMode: false,
            itemsToShow: 4,
          },
          992: {
            centerMode: false,
            itemsToShow: 3,
          },
          768: {
            centerMode: false,
            itemsToShow: 2,
          },
        },
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      gaia_game_list: [],
      loading: false,
      show_preview: false,
      show_result: false,
    };
  },
  methods: {
    computedSrc(image) {
      return URL.createObjectURL(image);
    },
    createStorePage() {
      if (!this.isBtnDisabled) {
        this.show_preview = true;
      }
    },
    fetchGaiaGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          if (response.status === 200) {
            this.gaia_game_list = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlePreviewClosed() {
      this.show_preview = false;
    },
    handleOnPreviewApprove() {
      this.show_result = true;
    },
    handleOnResultClosed() {
      this.show_result = false;
      (this.screenshots = []),
        (this.gaia_game = null),
        (this.ios_subtitle = ""),
        (this.long_desc = ""),
        (this.icon = null);
    },
  },
  watch: {
    icon(newValue) {
      if (newValue) {
        var img = new Image();
        const url = URL.createObjectURL(newValue);

        const printNotify = (message) => {
          this.$notify({
            text: message,
            group: "error_",
            type: "error_",
          });
        };

        const reset = () => {
          this.icon = null;
        };

        img.onload = function () {
          var height = img.height;
          var width = img.width;

          if (height % width !== 0) {
            printNotify("Icon dimensions must be a square.");
            reset();
          }

          if (height < 256 || width < 256) {
            printNotify("Icon size can not be less than 256 x 256.");
            reset();
          }
        };

        img.src = url;
      }
    },
    screenshots: {
      handler(newValue) {
        if (newValue.length > 0) {
          const printNotify = (message) => {
            this.$notify({
              text: message,
              group: "error_",
              type: "error_",
              duration: 8000,
            });
          };

          const reset = () => {
            this.screenshots = [];
          };

          for (let i = 0; i < newValue.length; i++) {
            const image = newValue[i];

            var img = new Image();
            const url = URL.createObjectURL(image);
            const ratio = 9 / 16;
            img.src = url;

            img.onload = function () {
              var height = this.height;
              var width = this.width;
              const img_ratio = width / height;
              if (img_ratio !== ratio) {
                printNotify(
                  `Screenshot dimensions must be 9w x 16h for ${image.name}`
                );
                reset();
              }

              if (width < 720 || height < 1280) {
                printNotify(
                  `Screenshot size must be at least 720 x 1080. ${image.name}`
                );
                reset();
              }
            };
          }
        }
      },
    },
  },
  computed: {
    isBtnDisabled() {
      if (!this.icon) {
        return true;
      }
      if (this.screenshots.length < 4 || this.screenshots.length > 5) {
        return true;
      }
      if (!this.gaia_game) {
        return true;
      }
      if (!this.ios_subtitle) {
        return true;
      }
      if (!this.long_desc) {
        return true;
      }
      return false;
    },
    preview_data() {
      return {
        screenshots: this.screenshots,
        gaia_game: this.gaia_game,
        ios_subtitle: this.ios_subtitle,
        long_desc: this.long_desc,
        icon: this.icon,
      };
    },
  },
  mounted() {
    this.fetchGaiaGames();
  },
};
</script>

<style lang="scss" scoped>
.icon-upload {
  display: inline-block;
}

.image-section {
  .delete-section {
    height: 45px;
    width: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
