<template>
  <v-app>
    <v-main>
      <notifications
        classes="error-notification"
        group="error_"
        :duration="3000"
        position="top right"
      />

      <notifications
        classes="success-notification"
        group="success_"
        :duration="3000"
        position="top right"
      />

      <div>
        <div v-if="getUserProfile">
          <CustomSidebar @closed="handleSidebarClose" :drawer="show_drawer" />
        </div>
        <div v-if="getUserProfile">
          <CustomMenu @menu_clicked="handleMenuClicked" />
        </div>
        <div class="router-section pt-[60px]">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import CustomSidebar from "./components/shared/CustomSidebar.vue";
import CustomMenu from "./components/shared/CustomMenu.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    CustomSidebar,
    CustomMenu,
  },
  data() {
    return {
      show_drawer: false,
    };
  },
  methods: {
    ...mapActions(["setUserProfile", "setApps"]),
    fetchUserProfile() {
      this.$api
        .get("users/profile/")
        .then((response) => {
          this.setUserProfile(response.data);
          if (process.env.NODE_ENV === "production") {
            this.fetchAppData();
          } else {
            this.setApps(this.computedApps);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchAppData() {
      if (this.getUserProfile) {
        if (this.getUserProfile.tools.some((x) => x.id === 1)) {
          this.$api
            .get("ccc/facebook/apps/all")
            .then((response) => {
              if (response.data) {
                this.setApps(response.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    handleMenuClicked() {
      this.show_drawer = true;
    },
    handleSidebarClose() {
      this.show_drawer = false;
    },
  },
  mounted() {
    this.fetchUserProfile();
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
    computedApps() {
      return [
        {
          id: "458920335209116",
          name: "Insights API App",
          link: "https://www.facebook.com/games/?app_id=458920335209116",
          object_store_urls: {
            instant_game:
              "http://www.facebook.com/gaming/play/458920335209116/",
          },
        },
        {
          id: "785350856170585",
          name: "Gaia",
          link: "https://www.facebook.com/games/?app_id=785350856170585",
          object_store_urls: {
            instant_game:
              "http://www.facebook.com/gaming/play/785350856170585/",
          },
        },
        {
          id: "1209898812808612",
          name: "AppsSoftLaunch",
          link: "https://honeybadgermobile.com/",
          object_store_urls: {
            fb_canvas: "https://honeybadgermobile.com/",
            instant_game:
              "http://www.facebook.com/gaming/play/1209898812808612/",
          },
        },
        {
          id: "567369787728120",
          name: "Lydia",
          link: "https://www.facebook.com/games/?app_id=567369787728120",
          object_store_urls: {
            instant_game:
              "http://www.facebook.com/gaming/play/567369787728120/",
          },
        },
        {
          id: "807879500083789",
          name: "Nuke Test App",
          link: "https://www.facebook.com/games/?app_id=807879500083789",
          object_store_urls: {
            google_play:
              "http://play.google.com/store/apps/details?id=tr.com.apps.nuketest",
            instant_game:
              "http://www.facebook.com/gaming/play/807879500083789/",
          },
        },
        {
          id: "2935311156783020",
          name: "How Many",
          link: "https://www.facebook.com/games/?app_id=2935311156783020",
          object_store_urls: {
            google_play:
              "http://play.google.com/store/apps/details?id=tr.com.apps.how.many",
            itunes: "http://itunes.apple.com/app/id1588853290",
            instant_game:
              "http://www.facebook.com/gaming/play/2935311156783020/",
          },
        },
        {
          id: "1094251217708161",
          name: "Draw Cubes",
          link: "https://www.facebook.com/games/?app_id=1094251217708161",
          object_store_urls: {
            google_play:
              "http://play.google.com/store/apps/details?id=tr.com.apps.draw.cubes2",
            itunes: "http://itunes.apple.com/app/id1551811520",
            instant_game:
              "http://www.facebook.com/gaming/play/1094251217708161/",
          },
        },
        {
          id: "473863090293322",
          name: "Trivia Race 3D (APAC)",
          link: "https://www.facebook.com/games/?app_id=473863090293322",
          object_store_urls: {
            itunes: "http://itunes.apple.com/app/id1552194390",
            instant_game:
              "http://www.facebook.com/gaming/play/473863090293322/",
          },
        },
        {
          id: "253826002784099",
          name: "Carpet Roller",
          link: "https://www.facebook.com/games/?app_id=253826002784099",
          object_store_urls: {
            google_play:
              "http://play.google.com/store/apps/details?id=tr.com.apps.carpet.roller",
            itunes: "http://itunes.apple.com/app/id1552851426",
            instant_game:
              "http://www.facebook.com/gaming/play/253826002784099/",
          },
        },
        {
          id: "253826002784099",
          name: "Drill & Collect",
          link: "https://www.facebook.com/games/?app_id=253826002784099",
          object_store_urls: {
            google_play:
              "http://play.google.com/store/apps/details?id=tr.com.apps.carpet.roller",
            itunes: "http://itunes.apple.com/app/id1552851426",
            instant_game:
              "http://www.facebook.com/gaming/play/253826002784099/",
          },
        },
        {
          id: "155206929802163",
          name: "Fashion Battle",
          link: "https://www.facebook.com/games/?app_id=155206929802163",
          object_store_urls: {
            google_play:
              "http://play.google.com/store/apps/details?id=tr.com.apps.fashion.battle",
            itunes: "http://itunes.apple.com/app/id1560663843",
            instant_game:
              "http://www.facebook.com/gaming/play/155206929802163/",
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.vue-notification-wrapper {
  overflow: initial;
}
.error-notification {
  // styling
  padding: 20px 10px;
  font-size: 12px;
  color: #ef5350;
  margin-top: 10px;
  margin-right: 10px;
  font-weight: 600;

  border-left: 5px solid #ff8a80;
  background-color: #ffcdd2 !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.success-notification {
  // styling
  padding: 20px 10px;
  font-size: 12px;
  color: #2e7d32;
  margin-top: 10px;
  margin-right: 10px;
  font-weight: 600;

  border-left: 5px solid #9ccc65;
  background-color: #c5e1a5 !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
