<template>
  <div>
    <div class="bg-white p-6 w-full">
      <h1 class="font-semibold text-[18px] pb-6">Waterfall Test Creator</h1>

      <div
        class="flex items-center"
        :class="!adUnits.length ? 'justify-end' : 'justify-between'"
      >
        <v-btn
          color="success"
          :ripple="false"
          depressed
          text
          :disabled="!this.platform"
          @click="exportAsExcel"
          v-if="adUnits.length"
        >
          <span> Export </span>
          <v-icon size="20" class="ml-2">mdi-file-export</v-icon>
        </v-btn>
        <v-btn
          color="info"
          text
          :ripple="false"
          depressed
          @click="insertAddUnit"
        >
          Add Ad Unit
        </v-btn>
      </div>

      <div class="mt-4" v-if="error_message">
        <v-alert
          border="left"
          text
          dense
          dismissible
          prominent
          type="error"
          class="text-[12px]"
        >
          <div>
            {{ error_message }}
          </div>
        </v-alert>
      </div>
      <div class="mt-4">
        <div>
          <v-text-field
            label="Name"
            outlined
            v-model="name"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-3">
          <v-select
            hide-details
            class="text-[13px]"
            label="Ad Format"
            outlined
            dense
            :items="available_ad_formats"
            v-model="ad_format"
          >
          </v-select>
        </div>

        <div class="mt-3">
          <v-text-field
            label="Experiment Name"
            outlined
            v-model="experiment_name"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>

        <div class="mt-3">
          <v-select
            label="Platform"
            outlined
            :items="available_platforms"
            v-model="platform"
            dense
            hide-details
            class="text-[13px]"
          ></v-select>
        </div>

        <div class="mt-3">
          <v-text-field
            label="Package Name"
            outlined
            v-model="package_name"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>

        <div class="mt-3">
          <v-text-field
            label="Ad Unit ID"
            outlined
            v-model="ad_unit_id"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
      </div>

      <div class="mt-4">
        <div
          class="text-center text-gray-500 border rounded border-gray-200 px-4 py-8 border-dashed"
          v-if="!adUnits.length"
        >
          There is no ad unit to show.
        </div>
        <div v-else class="grid grid-cols-4 gap-3">
          <div
            v-for="(unit, index) in adUnits"
            :key="index"
            class="p-4 border rounded border-gray-100 hover:border-blue-400 hover:shadow-lg"
          >
            <div class="flex items-center justify-between mb-3">
              <h1 class="text-[12px] font-semibold">
                {{ index + 1 }}. Ad Unit
              </h1>
              <div class="flex items-center justify-end">
                <div
                  class="text-indigo-500 text-[11px] font-semibold cursor-pointer hover:bg-indigo-100 rounded-[5px] hover:text-indigo-600 px-3 py-1 mr-1"
                  @click="copyAdUnit(index)"
                >
                  Copy
                </div>
                <div
                  class="text-rose-500 text-[11px] font-semibold cursor-pointer hover:bg-rose-100 rounded-[5px] hover:text-rose-600 px-3 py-1"
                  @click="deleteAdUnit(index)"
                >
                  Delete
                </div>
              </div>
            </div>
            <div class="mb-3">
              <v-select
                hide-details
                class="text-[13px]"
                item-text="title"
                item-value="value"
                label="Choose a network"
                outlined
                dense
                :items="available_networks"
                v-model="unit.network"
              >
              </v-select>
            </div>
            <div class="mb-3">
              <v-text-field
                label="Network Ad Unit ID"
                outlined
                v-model="unit.ad_network_ad_unit_id"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mb-3">
              <v-text-field
                label="CPM"
                outlined
                type="number"
                v-model="unit.cpm"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mb-3">
              <v-text-field
                label="Countries"
                outlined
                v-model="unit.countries"
                dense
                placeholder="Parse with comma. Ex: US, TR"
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mb-3">
              <v-select
                hide-details
                class="text-[13px]"
                label="Country Type"
                outlined
                dense
                :items="available_countries_types"
                v-model="unit.countries_type"
              >
              </v-select>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="!adUnits.length">
        <div>
          <v-file-input
            show-size
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            label="Choose Excel"
            class="text-[13px]"
            outlined
            dense
            hide-details
            v-model="csvFile"
          ></v-file-input>
        </div>
      </div>

      <div class="flex items-center justify-end mt-4">
        <v-btn
          color="primary"
          :ripple="false"
          depressed
          @click="createTest"
          :disabled="isBtnDisabled || loading"
          :loading="loading"
        >
          Create Test
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      adUnits: [],
      available_networks: [
        {
          title: "Google",
          value: "GOOGLE_AD_MANAGER_NETWORK",
        },
        {
          title: "Unity",
          value: "UNITY_NETWORK",
        },
      ],
      available_countries_types: ["INCLUDE", "EXCLUDE"],
      available_ad_formats: ["INTER", "BANNER", "REWARD"],
      available_platforms: ["android", "ios"],
      csvFile: null,
      name: "",
      ad_format: "",
      experiment_name: "",
      ad_unit_id: "",
      package_name: "",
      platform: "",
      loading: false,
      error_message: "",
    };
  },
  methods: {
    insertAddUnit() {
      this.adUnits.push({
        network: "",
        ad_network_ad_unit_id: "",
        cpm: null,
        countries: "",
        countries_type: "EXCLUDE",
      });
    },
    deleteAdUnit(index) {
      this.adUnits.splice(index, 1);
    },
    // exportAsExcel() {
    //   const titleKeys = Object.keys(this.adUnits[0]);
    //   const refinedData = [];

    //   refinedData.push(titleKeys);

    //   this.adUnits.forEach((item) => {
    //     refinedData.push(Object.values(item));
    //   });

    //   let csvContent = "";

    //   refinedData.forEach((row) => {
    //     csvContent += row.join(";") + "\n";
    //   });

    //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
    //   const objUrl = URL.createObjectURL(blob);
    //   const link = document.createElement("a");
    //   link.setAttribute("href", objUrl);
    //   link.setAttribute(
    //     "download",
    //     `Ad Units - ${Math.round(+new Date() / 1000)}`
    //   );
    //   link.click();
    // },
    exportAsExcel() {
      var ws = XLSX.utils.json_to_sheet(this.adUnits);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, this.platform);
      XLSX.writeFile(wb, `Ad Units - ${Math.round(+new Date() / 1000)}.xlsx`);
    },

    copyAdUnit(index) {
      const data = { ...this.adUnits[index] };
      this.adUnits.push(data);
    },

    clearForm() {
      this.adUnits = [];
      this.csvFile = null;
      this.name = "";
      this.ad_format = "BANNER";
      this.experiment_name = "";
    },

    createTest() {
      this.loading = true;
      // Create with Excel
      var formData = new FormData();
      formData.append("name", this.name);
      formData.append("ad_format", this.ad_format);
      formData.append("experiment_name", this.experiment_name);
      formData.append("package_name", this.package_name);
      formData.append("platform", this.platform);
      formData.append("ad_unit_id", this.ad_unit_id);

      if (this.csvFile && !this.adUnits.length) {
        formData.append("file", this.csvFile);
      } else {
        formData.append("json_str_data", JSON.stringify(this.adUnits));
      }

      this.$api
        .post("applovin/create-test", formData)
        .then((response) => {
          if (response && response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Waterfall test has successfully created.",
            });
            this.clearForm();
          } else {
            if (response?.response?.data?.detail) {
              this.error_message = response?.response?.data?.detail;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    adUnits: {
      deep: true,
      handler(newValue) {
        if (newValue.length) {
          this.csvFile = null;
        }
      },
    },
  },
  computed: {
    isBtnDisabled() {
      if (!this.name) return true;
      if (!this.ad_format) return true;
      if (!this.experiment_name) return true;
      if (!this.package_name) return true;
      if (!this.platform) return true;
      if (!this.ad_unit_id) return true;
      if (!this.adUnits.length && !this.csvFile) return true;

      for (let i = 0; i < this.adUnits.length; i++) {
        const element = this.adUnits[i];

        if (!element.network) return true;
        if (!element.ad_network_ad_unit_id) return true;
        if (!element.cpm) return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
