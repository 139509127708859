<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Summary </v-card-title>

        <v-card-text>
          <div>
            <div>
              <div
                v-for="(data, index) in requestData"
                :key="index"
                class="mt-2.5"
              >
                <h1 class="text-green-600 text-[12px] font-semibold">
                  {{ data.campaign_name }}
                </h1>

                <div class="text-[11px]">
                  Creatives will be inserted:
                  <span
                    v-for="creative in JSON.parse(data.inserted_creatives)"
                    :key="creative.gaia_id"
                    class="inline-block font-semibold text-indigo-600 px-1 py-0.5 bg-indigo-50 mr-1.5 rounded-[3px]"
                  >
                    {{ creative.creative_name }}</span
                  >
                </div>

                <div class="text-[11px] mt-2">
                  Creatives will be deleted:
                  <span
                    v-for="creative in JSON.parse(data.deleted_creatives)"
                    :key="creative.ironsource_id"
                    class="inline-block font-semibold text-indigo-600 px-1 py-0.5 bg-indigo-50 mr-1.5 rounded-[3px]"
                  >
                    {{ creative.creative_name }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submitSummaryForTasks">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    requestData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    computedImportedDataForSummary() {
      var data = [];

      for (let i = 0; i < this.selectedCreatives.length; i++) {
        var temp = {
          adgroup_data: [],
          creative_id: this.selectedCreatives[i].ad_id,
          creative_name: this.selectedCreatives[i].ad_name,
        };

        const selectedCreative = this.selectedCreatives[i];
        for (let y = 0; y < this.adgroupData.length; y++) {
          const adgroupData = this.adgroupData[y];

          for (let z = 0; z < adgroupData.selectedAdsForAdding.length; z++) {
            const selectedAdForAdding = adgroupData.selectedAdsForAdding[z];
            if (
              selectedAdForAdding.ad_id === selectedCreative.ad_id &&
              selectedAdForAdding.selected
            ) {
              temp.adgroup_data.push({
                adgroup_name: adgroupData.name,
                adgroup_id: adgroupData.id,
              });
            }
          }
        }

        data.push(temp);
      }

      return data;
    },
  },
  methods: {
    submitSummaryForTasks() {
      this.$emit("submitted");
    },
  },
};
</script>

<style lang="scss" scoped></style>
