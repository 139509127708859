export const departments = {
  ADMIN: 1,
  GAME_DEVELOPERS: 2,
  GAME_ARTISTS: 3,
  GAME_DESIGNERS: 4,
  MARKETING_ARTISTS: 5,
  SOFTWARE_DEVELOPERS: 6,
  UA_TEAM: 7,
  DATA_TOOL: 8,
  USER: 100,
};
