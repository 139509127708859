<template>
  <div>
    <div class="bg-white p-6 w-full">
      <h1 class="font-semibold text-[18px] pb-6">Audit Logs</h1>

      <div class="grid grid-cols-6 gap-2">
        <div>
          <v-select
            label="User"
            outlined
            v-model="filterData.user"
            :items="users"
            dense
            hide-details
            item-text="full_name"
            item-value="id"
            class="text-[13px]"
            clearable
          ></v-select>
        </div>
        <div>
          <v-select
            label="Tool"
            outlined
            v-model="filterData.tool"
            :items="tools"
            dense
            hide-details
            item-text="title"
            item-value="title"
            class="text-[13px]"
            clearable
          ></v-select>
        </div>
      </div>

      <div class="mt-5">
        <v-data-table
          :headers="headers"
          :items="auditLogs"
          :loading="loading"
          dense
          loading-text="Please wait..."
          :items-per-page="limit"
          :page="currentPage"
          @pagination="handlePaginationChange"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="handleSortBy"
          @update:sort-desc="handleSortDesc"
          :server-items-length="totalCount"
          :footer-props="{
            itemsPerPageOptions: [20, 30, 40, 50, -1],
          }"
          :expanded.sync="expanded"
          show-expand
          single-expand
          item-key="id"
        >
          <template v-slot:[`item.id`]="{ item }">
            <span class="text-[12px]">
              {{ item.id }}
            </span>
          </template>
          <template v-slot:[`item.tool`]="{ item }">
            <span
              class="text-[11px] uppercase inline-block px-[7px] py-[2px] rounded-full"
              :class="{
                'bg-green-100 text-green-700':
                  computedTool(item.tool.toLowerCase()) === 'act',
                'bg-indigo-100 text-indigo-700':
                  computedTool(item.tool.toLowerCase()) === 'acg',
                'bg-gray-100 text-gray-700':
                  computedTool(item.tool.toLowerCase()) === 'general',
                'bg-yellow-100 text-yellow-700':
                  computedTool(item.tool.toLowerCase()) === 'burcha',
                'bg-blue-100 text-blue-700':
                  computedTool(item.tool.toLowerCase()) === 'parasut',
                'bg-rose-100 text-rose-700':
                  computedTool(item.tool.toLowerCase()) === 'persephone',
                'bg-teal-100 text-teal-700':
                  computedTool(item.tool.toLowerCase()) === 'a/b tests',
              }"
            >
              {{ item.tool.toLowerCase() }}
            </span>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div v-if="item.tool.toLowerCase() === 'act'">
              <div v-if="item.type === 'start_test'">
                User started test on
                <span class="text-rose-700 capitalize">{{
                  item.message.selected_network.channel
                }}</span>
                with selected creatives:
                <span
                  v-for="(tag, idx) in item.message.data.created_tests"
                  :key="idx"
                  class="inline-block px-1 bg-sky-200 text-sky-700 mx-1 rounded-[3px] font-semibold text-[10px]"
                >
                  {{ tag.title }}</span
                >
              </div>
            </div>
            <div v-else-if="item.tool.toLowerCase() === 'burcha'">
              <div>
                User created a job on
                <span class="text-rose-700 capitalize">{{
                  burchaTypeToName(item.type)
                }}</span
                >.
              </div>
            </div>
            <span v-else class="text-[12px]">
              {{ item.description }}
            </span>
          </template>
          <template v-slot:[`item.__user_name`]="{ item }">
            <span class="text-[12px] uppercase inline-block">
              {{ item.__user_name }}
            </span>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span class="text-[12px]">
              {{ $formatUnix(item.created_at) }}
            </span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div v-if="item.tool === 'burcha'">
                <div
                  v-if="burchaTypeToName(item.type) === 'Facebook'"
                  class="grid grid-cols-4 gap-2.5 p-2"
                >
                  <div
                    v-for="(info, idx) in item.message.data"
                    :key="idx"
                    class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                  >
                    <div>Campaign: {{ info.campaign }}</div>
                    <div>Adset: {{ info.adset }}</div>
                    <div>Creative: {{ info.creative }}</div>
                    <div>
                      Type:
                      <span
                        :class="{
                          'text-green-600': info.type === 'insert',
                          'text-red-600': info.type === 'delete',
                        }"
                        >{{ info.type }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="burchaTypeToName(item.type) === 'Unity'"
                  class="grid grid-cols-4 gap-2.5 p-2"
                >
                  <div
                    v-for="(info, idx) in item.message.data"
                    :key="idx"
                    class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                  >
                    <div>Campaign: {{ info.campaign }}</div>
                    <div>Creative: {{ info.creative }}</div>
                    <div>
                      Playable: {{ info.playable ? info.playable : "-" }}
                    </div>
                    <div>
                      Type:
                      <span
                        :class="{
                          'text-green-600': info.type === 'insert',
                          'text-red-600': info.type === 'delete',
                        }"
                        >{{ info.type }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="burchaTypeToName(item.type) === 'Google'"
                  class="p-2"
                >
                  <div>Campaign: {{ item.message.campaign_name }}</div>
                  <div>Adgroup: {{ item.message.adgroup_name }}</div>

                  <v-divider
                    class="mt-2"
                    v-if="JSON.parse(item.message.descriptions).length"
                  ></v-divider>
                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(description, idx) in JSON.parse(
                        item.message.descriptions
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Description Changes - {{ idx + 1 }}
                      </div>
                      <div>Old: {{ description.old_value }}</div>
                      <div>New: {{ description.new_value }}</div>
                    </div>
                  </div>

                  <v-divider
                    class="mt-2"
                    v-if="JSON.parse(item.message.headlines).length"
                  ></v-divider>

                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(headline, idx) in JSON.parse(
                        item.message.headlines
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Headline Changes - {{ idx + 1 }}
                      </div>
                      <div>Old: {{ headline.old_value }}</div>
                      <div>New: {{ headline.new_value }}</div>
                    </div>
                  </div>

                  <v-divider
                    class="mt-2"
                    v-if="
                      JSON.parse(item.message.inserted_images).length ||
                      JSON.parse(item.message.deleted_images).length
                    "
                  ></v-divider>
                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(image, idx) in JSON.parse(
                        item.message.deleted_images
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Deleted Image - {{ idx + 1 }}
                      </div>
                      <div>{{ image.id }}</div>
                    </div>
                  </div>
                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(image, idx) in JSON.parse(
                        item.message.inserted_images
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Inserted Image - {{ idx + 1 }}
                      </div>
                      <div>{{ image }}</div>
                    </div>
                  </div>

                  <v-divider
                    class="mt-2"
                    v-if="
                      JSON.parse(item.message.inserted_videos).length ||
                      JSON.parse(item.message.deleted_videos).length
                    "
                  ></v-divider>

                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(video, idx) in JSON.parse(
                        item.message.inserted_videos
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Inserted Video - {{ idx + 1 }}
                      </div>
                      <div>{{ video }}</div>
                    </div>
                  </div>
                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(video, idx) in JSON.parse(
                        item.message.deleted_videos
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Deleted Video - {{ idx + 1 }}
                      </div>
                      <div>{{ video.id }}</div>
                    </div>
                  </div>

                  <v-divider
                    class="mt-2"
                    v-if="JSON.parse(item.message.deleted_playables).length"
                  ></v-divider>
                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(playable, idx) in JSON.parse(
                        item.message.deleted_playables
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Deleted Playable - {{ idx + 1 }}
                      </div>
                      <div>{{ playable.id }}</div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="burchaTypeToName(item.type) === 'Mintegral'"
                  class="p-2"
                >
                  <div>Campaign: {{ item.message.campaign_name }}</div>
                  <div>Offer: {{ item.message.offer_name }}</div>
                  <v-divider
                    class="mt-2"
                    v-if="JSON.parse(item.message.selected_creatives).length"
                  ></v-divider>
                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(creative, idx) in JSON.parse(
                        item.message.selected_creatives
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Selected Creative - {{ idx + 1 }}
                      </div>
                      <div>{{ creative.ad_name }}</div>
                    </div>
                  </div>

                  <v-divider
                    class="mt-2"
                    v-if="JSON.parse(item.message.deleted_videos).length"
                  ></v-divider>
                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(video, idx) in JSON.parse(
                        item.message.deleted_videos
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Deleted Video - {{ idx + 1 }}
                      </div>
                      <div>{{ video.creative_name }}</div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="burchaTypeToName(item.type) === 'Applovin'"
                  class="p-2"
                >
                  <div>Campaign: {{ item.message.campaign_name }}</div>
                  <v-divider
                    class="mt-2"
                    v-if="JSON.parse(item.message.inserted_creatives).length"
                  ></v-divider>
                  <div class="grid grid-cols-4 gap-2.5 mt-2">
                    <div
                      class="bg-gray-50 p-2 rounded hover:bg-gray-100"
                      v-for="(creative, idx) in JSON.parse(
                        item.message.inserted_creatives
                      )"
                      :key="idx"
                    >
                      <div class="font-semibold text-[11px] mb-1">
                        Inserted Creative - {{ idx + 1 }}
                      </div>
                      <div>{{ creative.gaia_id }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="item.tool === 'umai'">
                <div>
                  <div class="grid grid-cols-2 gap-1.5 p-2">
                    <div
                      v-for="(info, idx) in item.message.data"
                      :key="idx"
                      class="p-2 bg-gray-50 hover:bg-gray-100 rounded"
                    >
                      <div>Ad Unit ID: {{ info.row.ad_unit_id }}</div>
                      <div>Ad Unit Name: {{ info.row.ad_unit_name }}</div>
                      <div class="mb-2 capitalize">
                        Segment: {{ info.row.segment }}
                      </div>

                      <h1 class="font-semibold text-[11px] mb-2">Logs</h1>
                      <div v-for="(log, logIdx) in info.row.logs" :key="logIdx">
                        {{ log }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="item.tool === 'cpa sheet'">
                <div
                  v-for="message in item.message.data"
                  :key="message.uuid"
                  class="p-[4px]"
                >
                  <div v-if="item.description === 'Update'">
                    Row: <span class="text-rose-600">{{ message.uuid }}</span
                    >, {{ message.message }}
                  </div>
                  <div v-if="item.description === 'Delete'">
                    {{ message }}
                  </div>
                  <div v-if="item.description === 'Insert'">
                    Row:
                    <span class="text-rose-600">{{ message.uuid }}</span> added
                    by user.
                  </div>
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: [],
      limit: 20,
      auditLogs: [],
      users: [],
      tools: [],
      filterData: {
        user: null,
        tool: null,
        minDate: null,
        maxDate: null,
      },
      loading: false,
      currentPage: 1,
      totalCount: null,
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: "Actor", value: "__user_name", sortable: true },
        { text: "Tool", value: "tool", sortable: true },
        { text: "Description", value: "description", sortable: false },
        { text: "Created At", value: "created_at", sortable: true },
        { text: "", value: "data-table-expand" },
      ],
      sortDesc: true,
      sortBy: "id",
      usersLoading: false,
      toolsLoading: false,
    };
  },
  methods: {
    burchaTypeToName(type) {
      if (type === "create_applovin_task") return "Applovin";
      if (type === "create_facebook_task") return "Facebook";
      if (type === "create_google_task") return "Google";
      if (type === "create_mintegral_task") return "Mintegral";
      if (type === "create_unity_task") return "Unity";
      return type;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    computedTool(tool) {
      if (tool === "act") {
        return "act";
      }

      if (
        [
          "burcha_applovin",
          "burcha_google",
          "burcha_mintegral",
          "burcha_unity",
          "burcha_facebook",
        ].includes(tool)
      ) {
        return "burcha";
      }

      if (["persephone_configuration", "persephone_test"].includes(tool)) {
        return "persephone";
      }

      if (["parasut_invoices"].includes(tool)) {
        return "parasut";
      }

      if (["acg"].includes(tool)) {
        return "acg";
      }

      if (["firebase_ab_tests"].includes(tool)) return "a/b tests";
      else {
        return tool;
      }
    },
    async fetchAuditLogs() {
      try {
        this.loading = true;
        var url = `audit-logs/all?limit=${this.limit}&page=${this.currentPage}`;

        if (this.filterData.user) {
          url += `&user_id=${this.filterData.user}`;
        }

        if (this.filterData.tool) {
          url += `&tool=${this.filterData.tool}`;
        }

        if (this.sortBy) {
          url += `&sortBy=${this.sortBy}`;
        }

        if (this.sortDesc) {
          url += `&sortDesc=${this.sortDesc}`;
        }

        const response = await this.$api.get(url);

        this.auditLogs = response.data.data.map((x) => ({
          ...x,
          __user_name: x.user.full_name,
          __user_id: x.user.id,
        }));
        this.totalCount = response.data.count;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    handlePaginationChange(event) {
      if (
        this.limit !== event.itemsPerPage ||
        this.currentPage !== event.page
      ) {
        this.limit = event.itemsPerPage;
        this.currentPage = event.page;
        this.fetchAuditLogs();
      }
    },
    handleSortBy(sortBy) {
      this.sortBy = sortBy;
      this.fetchAuditLogs();
    },
    handleSortDesc(sortDesc) {
      this.sortDesc = sortDesc;
      this.fetchAuditLogs();
    },
    async fetchUsers() {
      try {
        this.usersLoading = true;
        const response = await this.$api.get("admin/users/");
        this.users = response.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.usersLoading = false;
      }
    },
    async fetchTools() {
      try {
        this.usersLoading = true;
        const response = await this.$api.get("admin/tools/all");
        this.tools = response.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.toolsLoading = false;
      }
    },
  },

  mounted() {
    this.fetchAuditLogs();
    this.fetchUsers();
    this.fetchTools();
  },

  watch: {
    filterData: {
      handler() {
        this.fetchAuditLogs();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
