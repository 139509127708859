<template>
  <div>
    <div class="bg-white p-2">
      <div>
        <v-text-field
          label="Search "
          outlined
          v-model="keyword"
          dense
          hide-details
          class="text-[13px] w-[240px]"
          clearable
        ></v-text-field>
      </div>
      <div class="my-2"><v-divider></v-divider></div>
      <div class="p-1 max-h-[320px] overflow-scroll min-h-[200px]">
        <div v-for="(item, index) in choices.data" :key="index">
          <div
            class="hover:bg-sky-100 text-[12px] rounded-[3px] min-h-[32px] flex items-center justify-start pl-2 capitalize cursor-pointer"
            :class="{
              'font-semibold bg-sky-100': choices.value
                ? item[choices.value] === value
                : item === value,
            }"
            @click="setValue(item)"
          >
            <span>
              {{ choices.label ? item[choices.label] : item }}
            </span>
          </div>
        </div>

        <div
          v-if="!choices.data.length"
          class="p-2 text-[11px] text-gray-400 text-center"
        >
          There is no data.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      keyword: false,
      tempChoices: [],
      choices: [],
      mapped: null,
      value: null,
    };
  },
  methods: {
    getValue() {
      return this.value;
    },

    isPopup() {
      return true;
    },

    filterChoices: _.debounce(function (value) {
      {
        this.choices = { ...this.tempChoices };

        if (value) {
          this.choices.data = this.choices.data.filter((x) => {
            const regex = new RegExp(value, "gmi");
            return regex.test(
              this.choices.label
                ? `${x[this.choices.label]}${x[this.choices.value]}`
                : x
            );
          });
        }
      }
    }, 100),

    // onClick(keyword) {
    //   this.setkeyword(keyword);
    //   this.params.stopEditing();
    // },

    // onKeyDown(event) {
    //   const key = event.key;
    //   if (
    //     key == "ArrowLeft" || // left
    //     key == "ArrowRight"
    //   ) {
    //     // right
    //     this.toggleMood();
    //     event.stopPropagation();
    //   }
    // },

    setValue(item) {
      this.value =
        this.choices.label && this.choices.value
          ? item[this.choices.value]
          : item;
      this.params.stopEditing();
    },
  },
  watch: {
    keyword(newValue) {
      this.filterChoices(newValue);
    },
  },
  created() {
    this.keyword = this.params.value;
    this.value = this.params.value;
    this.choices = JSON.parse(JSON.stringify(this.params.values));
    this.tempChoices = JSON.parse(JSON.stringify(this.choices));
  },
};
</script>

<style lang="scss" scoped></style>
