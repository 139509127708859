<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">
        Icon & Screenshoot Tester - New Test
      </h1>
      <div class="flex items-center justify-end mb-5">
        <v-btn
          color="primary"
          :ripple="false"
          depressed
          text
          @click="insertVariant"
        >
          Add New Variant
        </v-btn>
      </div>

      <v-alert
        dense
        dismissible
        prominent
        text
        type="info"
        v-if="variants.filter((x) => !x.gaia_id).length > 0"
      >
        <div class="m-0 p-0 text-[12px]">
          You must confirm all variants to create the test.
        </div>
      </v-alert>
      <div>
        <v-autocomplete
          clearable
          dense
          outlined
          hide-details
          hide-selected
          auto-select-first
          item-value="id"
          item-text="game_title"
          v-model="selected_config"
          :items="configurations"
          label="Choose a configuration"
          class="text-[13px]"
        >
        </v-autocomplete>
      </div>
      <div>
        <div class="mt-4">
          <v-text-field
            label="Title"
            outlined
            v-model="test_name"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
      </div>
      <div v-if="variants.length">
        <div
          v-for="(variant, variant_index) in variants"
          class="p-5 border rounded-[5px] border-gray-200 mt-5 border-dashed hover:shadow-lg transition duration-200"
          :key="variant.id"
        >
          <div class="flex items-center justify-between">
            <div class="flex flex-col w-full">
              <h1
                class="text-[12px] font-semibold"
                :class="variant.gaia_id ? 'text-green-600' : 'text-red-600'"
              >
                Variant - {{ variant_index + 1 }}
              </h1>
              <span
                class="mt-1 text-[10px]"
                :class="variant.gaia_id ? 'text-green-600' : 'text-red-600'"
              >
                {{ variant.gaia_id ? "Confirmed" : "Unconfirmed" }}
              </span>
            </div>
            <div class="flex items-center">
              <div class="mr-2">
                <v-btn
                  color="error"
                  :ripple="false"
                  depressed
                  text
                  @click="deleteVariant(variant.id)"
                >
                  Delete
                </v-btn>
              </div>
              <div>
                <v-btn
                  color="success"
                  :ripple="false"
                  depressed
                  :loading="variant.loading"
                  text
                  @click="verifyVariant(variant_index)"
                  :disabled="isVariantDisabled(variant_index)"
                >
                  {{ variant.gaia_id ? "Confirmed" : "Confirm" }}
                </v-btn>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <v-text-field
              label="Title"
              v-model="variant.title"
              dense
              outlined
              :disabled="variant.gaia_id ? true : false"
              hide-details
              class="text-[13px]"
            ></v-text-field>
          </div>
          <div class="mt-4">
            <v-text-field
              label="Subtitle"
              v-model="variant.subtitle"
              dense
              outlined
              :disabled="variant.gaia_id ? true : false"
              hide-details
              class="text-[13px]"
            ></v-text-field>
          </div>
          <div class="mt-4">
            <v-textarea
              label="Long Description"
              v-model="variant.long_desc"
              dense
              outlined
              :disabled="variant.gaia_id ? true : false"
              auto-grow
              :rows="2"
              hide-details
              class="text-[13px]"
            ></v-textarea>
          </div>
          <div class="mt-4">
            <v-file-input
              v-model="variant.icon"
              label="Icon"
              outlined
              :disabled="variant.gaia_id ? true : false"
              :show-size="1000"
              dense
              hide-details
              accept="image/*"
            >
            </v-file-input>
          </div>
          <div class="mt-4">
            <v-file-input
              v-model="variant.screenshots"
              label="Screenshots"
              outlined
              :disabled="variant.gaia_id ? true : false"
              multiple
              :show-size="1000"
              dense
              hide-details
              accept="image/*"
            >
            </v-file-input>
          </div>
          <div class="mt-4">
            <v-file-input
              v-model="variant.videos"
              label="Videos"
              outlined
              :disabled="variant.gaia_id ? true : false"
              multiple
              :show-size="1000"
              dense
              hide-details
              accept="video/mp4"
            >
            </v-file-input>
          </div>
          <div
            class="mt-4 mb-2 text-[12px] font-semibold text-sky-700"
            v-if="variant.screenshots.length"
          >
            1. Screenshot Order
          </div>
          <div v-if="variant.screenshots.length">
            <draggable
              v-model="variant.screenshots"
              @start="drag = true"
              @end="drag = false"
              ghostClass="dropArea"
            >
              <img
                v-for="(screenshot, sc_index) in variant.screenshots"
                class="cursor-move select-none h-[180px] rounded-[5px] mr-3 inline-block"
                :key="sc_index"
                :src="computedSrc(screenshot)"
              />
            </draggable>
          </div>

          <div
            class="mt-4 mb-2 text-[12px] font-semibold text-sky-700"
            v-if="variant.videos.length"
          >
            2. Video Order
          </div>
          <div v-if="variant.videos.length">
            <draggable
              v-model="variant.videos"
              @start="drag = true"
              @end="drag = false"
              ghostClass="dropArea"
            >
              <div
                class="cursor-move rounded-[5px] mr-3 inline-block"
                v-for="(video, vd_index) in variant.videos"
                :key="vd_index"
              >
                <div class="flex flex-col items-center justify-center">
                  <span class="mb-2 text-[11px] font-semibold">{{
                    video.name
                  }}</span>
                  <video-player
                    class="video-player-box vjs-big-play-centered w-full"
                    ref="videoPlayer"
                    :options="computedOptions(video)"
                    :playsinline="true"
                  >
                  </video-player>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end mt-5">
        <v-btn
          color="primary"
          :ripple="false"
          depressed
          :disabled="isBtnDisabled"
          @click="createTest"
          :loading="loading_btn"
        >
          Create Test
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "CreateTestView",
  data() {
    return {
      selected_config: null,
      configurations: [],
      test_name: "",
      variants: [],
      loading_btn: false,
    };
  },
  methods: {
    isVariantDisabled(index) {
      const variant = this.variants[index];

      if (variant.gaia_id) return true;
      if (!variant.title) return true;
      if (!variant.subtitle) return true;
      if (!variant.long_desc) return true;
      if (!variant.icon) return true;
      if (!variant.screenshots.length) return true;
      return false;
    },
    computedOptions(file) {
      return {
        sources: [
          {
            type: "video/mp4",
            src: this.computedSrc(file),
          },
        ],
        language: "en",
        height: "220px",
      };
    },
    computedSrc(file) {
      return URL.createObjectURL(file);
    },
    computedSrcVid(variant_index, index) {
      const variant = this.variants[variant_index];

      return URL.createObjectURL(variant.videos[index]);
    },
    verifyVariant(index) {
      let formData = new FormData();

      for (let i = 0; i < this.variants[index].screenshots.length; i++) {
        formData.append("screenshots", this.variants[index].screenshots[i]);
      }

      formData.append("icon", this.variants[index].icon);
      formData.append("title", this.variants[index].title);
      formData.append("subtitle", this.variants[index].subtitle);
      formData.append("long_desc", this.variants[index].long_desc);
      formData.append("config_id", this.selected_config);

      if (this.variants[index].videos && this.variants[index].videos.length) {
        for (let i = 0; i < this.variants[index].videos.length; i++) {
          formData.append("videos", this.variants[index].videos[i]);
        }
      }

      this.variants[index].loading = true;

      this.$api
        .post(`icon-tester/variants/create`, formData)
        .then((response) => {
          if (response.status === 200) {
            this.variants[index].gaia_id = response.data.id;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.variants[index].loading = false;
        });
    },
    createTest() {
      this.loading_btn = true;
      const requestData = {
        variants: this.variants.map((x) => x.gaia_id),
        config_id: this.selected_config,
        title: this.test_name,
      };

      this.$api
        .post("icon-tester/tests/create", requestData)
        .then((response) => {
          if (response && response.status === 200) {
            this.variants = [];
            this.test_name = "";
            this.selected_config = null;
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Test successfully created.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_btn = false;
        });
    },
    fetchConfigurations() {
      this.$api
        .get("icon-tester/configuration/all")
        .then((response) => {
          this.configurations = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    insertVariant() {
      const variantType = {
        id: (Math.random() + 1).toString(36).substring(7),
        icon: null,
        screenshots: [],
        videos: [],
        title: "",
        subtitle: "",
        long_desc: "",
        gaia_id: null,
        loading: false,
      };
      this.$set(this.variants, this.variants.length, variantType);
    },
    deleteVariant(id) {
      this.variants = this.variants.filter((x) => x.id !== id);
    },
  },
  mounted() {
    this.fetchConfigurations();
  },
  computed: {
    isBtnDisabled() {
      if (!this.selected_config) {
        return true;
      }
      if (!this.test_name) {
        return true;
      }

      if (!this.variants.length) {
        return true;
      }

      for (let i = 0; i < this.variants.length; i++) {
        const element = this.variants[i];

        if (!element.icon) {
          return true;
        }

        if (element.screenshots.length < 3) {
          return true;
        }

        if (!element.title) {
          return true;
        }

        if (!element.subtitle) {
          return true;
        }

        if (!element.long_desc) {
          return true;
        }

        if (!element.gaia_id) {
          return true;
        }
      }

      return false;
    },
  },
  components: {
    draggable,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}

.dropArea {
  position: relative;
}
.dropArea::before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ebebeb;
}

::v-deep .v-file-input__text {
  font-size: 12px;
}
</style>
