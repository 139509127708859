<template>
  <div>
    <SurfaceResourceEditor
      @closed="handleEditorClose"
      :show="showDataEditor"
      :valueStructures="valueStructures"
      :variableValue="value"
      :defaultValue="defaultValue"
      v-if="
        [
          'Area1OreSpawnData[]',
          'SurfaceResourceData[]',
          'OrderData[]',
        ].includes(variableType)
      "
    />
    <div class="mt-4">
      <div
        v-if="
          [
            'Area1OreSpawnData[]',
            'SurfaceResourceData[]',
            'OrderData[]',
          ].includes(variableType)
        "
      >
        <div
          class="h-[40px] border-gray-400 border rounded w-full flex items-center justify-center text-[12px] cursor-pointer hover:text-sky-600 hover:border-sky-600"
          @click="showDataEditor = true"
        >
          Edit Data
        </div>
      </div>
      <div v-else-if="variableType === 'undergroundDesignCombinationEnum'">
        <v-file-input
          multiple
          chips
          hide-details
          small-chips
          truncate-length="32"
          outlined
          class="text-[13px]"
          dense
          label="Level Data (Json)"
          accept="application/json"
          :disabled="disabled"
          v-model="levelData"
        ></v-file-input>
        <!-- 
        <v-autocomplete
          hide-details
          class="text-[13px]"
          label="Choose Possibilities"
          outlined
          multiple
          small-chips
          deletable-chips
          :items="choosableValues"
          v-model="value"
          :disabled="disabled"
        >
        </v-autocomplete> -->
      </div>
      <div
        v-else-if="
          variableType !== 'int[]' &&
          variableType !== 'string[]' &&
          variableType !== 'int' &&
          variableType !== 'string' &&
          variableType !== 'Color[]' &&
          variableType !== 'float[]' &&
          variableType !== 'float' &&
          variableType.toLowerCase() !== 'color'
        "
      >
        <v-select
          hide-details
          class="text-[13px]"
          label="Choose Possibilities"
          outlined
          multiple
          small-chips
          deletable-chips
          :items="choosableValues"
          v-model="value"
          :disabled="disabled"
        >
        </v-select>
      </div>
      <div v-else>
        <v-combobox
          multiple
          outlined
          small-chips
          dense
          hide-details
          deletable-chips
          label="Values"
          v-model="value"
          class="text-[13px]"
          :disabled="disabled"
        ></v-combobox>
      </div>
      <div
        v-if="['Color[]', 'Color', 'color'].includes(variableType)"
        class="mt-2 flex items-center"
      >
        <div v-for="(item, index) in [...value]" :key="index">
          <div
            class="colors"
            v-if="variableType === 'Color[]' && isColorValid(item)"
            :style="{
              background: `linear-gradient(${firstColor(item)}, ${secondColor(
                item
              )})`,
            }"
          ></div>

          <div
            class="colors"
            v-if="variableType.toLowerCase() === 'color'"
            :style="{
              background: `#${item}`,
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SurfaceResourceEditor from "./SurfaceResourceEditor.vue";

export default {
  data() {
    return {
      value: null,
      showDataEditor: false,
      levelData: [],
    };
  },
  props: {
    variableType: {
      type: String,
      default: null,
    },
    choosableValues: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    gameplayValue: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valueStructures: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    gameplayValue: {
      handler(newValue) {
        this.value = newValue;
      },
      deep: true,
      immediate: true,
    },
    async value(newValue) {
      this.$emit("changed", newValue);
    },
    levelData: {
      deep: true,
      handler(newValue) {
        let dataArr = [];
        for (let i = 0; i < newValue.length; i++) {
          const file = newValue[i];
          this.$readJsonFile(file, true)
            .then((data) => {
              dataArr.push(data);
            })
            .catch((err) => {
              throw new Error(err);
            });
        }

        setTimeout(() => {
          // this.levelData = JSON.parse(JSON.stringify(dataArr));
          // console.log(dataArr);
          this.$emit("changed", dataArr);
        }, 250);
      },
    },
  },
  methods: {
    handleFileChange($event) {
      let dataArr = [];
      for (let i = 0; i < $event.length; i++) {
        const file = $event[i];
        this.$readJsonFile(file, true)
          .then((data) => {
            dataArr.push(data);
          })
          .catch((err) => {
            throw new Error(err);
          });
      }

      setTimeout(() => {
        if (!this.levelData.length && !dataArr.length) {
          console.log("pass");
        } else {
          this.levelData = JSON.parse(JSON.stringify(dataArr));
          this.$emit("changed", dataArr);
        }
      }, 250);
    },
    handleEditorClose() {
      this.showDataEditor = false;
    },
    isColorValid(colors) {
      // const pattern = /^([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
      // return pattern.test(color);
      const colors_arr = colors.split(",");
      if (colors_arr.length) {
        for (let i = 0; i < colors_arr.length; i++) {
          const color = colors_arr[i];
          if (color.length !== 6) {
            return false;
          }
          const pattern = /^([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
          const is_valid = pattern.test(color);
          if (!is_valid) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    firstColor(color) {
      return `#${color.split(",")[0]}`;
    },
    secondColor(color) {
      return `#${color.split(",")[1]}`;
    },
  },
  components: { SurfaceResourceEditor },
};
</script>

<style lang="scss" scoped>
::v-deep .v-chip__content {
  font-size: 10px;
  font-weight: 600;
  color: #191919;
}

.colors {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 6px;
}
</style>
