<template>
  <div>
    <v-dialog v-model="visible" width="600" persistent>
      <v-card v-if="experimentData && !loading">
        <v-card-title class="text-h5">
          Event Schedule A/B Experiment
        </v-card-title>

        <v-card-text>
          <div class="pt-5">
            <div>
              <v-text-field
                class="text-[13px]"
                v-model="experimentData.title"
                label="Experiment Title"
                outlined
                dense
                hide-details
                :disabled="env === 'prod' ? true : false"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                class="text-[13px]"
                v-model="experimentData.description"
                label="Experiment Description"
                outlined
                dense
                hide-details
                :disabled="env === 'prod' ? true : false"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-select
                class="text-[13px]"
                v-model="experimentData.platform"
                label="Platform"
                outlined
                dense
                hide-details
                clearable
                :items="['android', 'ios', 'both']"
                :disabled="env === 'prod' ? true : false"
              ></v-select>
            </div>
            <div class="mt-3">
              <h1 class="font-semibold text-[11] mb-5 text-gray-900">
                Rollout (%)
              </h1>
              <v-slider
                v-model="experimentData.rollout"
                thumb-label="always"
                hide-details
                :min="0"
                :max="100"
                :disabled="env === 'prod' ? true : false"
              ></v-slider>
            </div>
            <h1 class="font-semibold text-[11px] mt-5 mb-2 text-gray-900">
              Optional Filters
            </h1>
            <div class="mt-3">
              <v-autocomplete
                class="text-[13px]"
                v-model="experimentData.countries"
                label="Countries (Optional)"
                outlined
                dense
                hide-details
                clearable
                multiple
                :items="computedCountries"
                item-text="name"
                item-value="code"
                :disabled="env === 'prod' ? true : false"
              ></v-autocomplete>
            </div>
            <div class="mt-3 flex items-center gap-2">
              <div class="w-8/12">
                <v-text-field
                  class="text-[13px]"
                  v-model="experimentData.version"
                  label="Version (Optional)"
                  outlined
                  dense
                  hide-details
                  :disabled="env === 'prod' ? true : false"
                ></v-text-field>
              </div>
              <div class="w-4/12">
                <v-select
                  class="text-[13px]"
                  v-model="experimentData.version_condition"
                  label="Condition"
                  outlined
                  dense
                  hide-details
                  clearable
                  :items="computedVersionConditions"
                  item-text="title"
                  item-value="value"
                  :disabled="env === 'prod' ? true : false"
                ></v-select>
              </div>
            </div>

            <div class="mt-3">
              <v-divider></v-divider>
            </div>

            <div class="mt-3">
              <v-menu
                v-model="start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="text-[13px]"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    v-model="experimentData.start_date"
                    :disabled="env === 'prod' ? true : false"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="start_date_menu = false"
                  v-model="experimentData.start_date"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="mt-3">
              <v-menu
                v-model="end_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="text-[13px]"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    v-model="experimentData.end_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="end_date_menu = false"
                  v-model="experimentData.end_date"
                  :allowed-dates="allowedDates"
                ></v-date-picker>
              </v-menu>
            </div>

            <h1 class="font-semibold text-[11px] mt-5 mb-2 text-gray-900">
              Variants
            </h1>

            <div class="mt-3 gap-3 flex flex-col">
              <div
                v-for="(variant, index) in experimentData.variants"
                :key="index"
              >
                <fieldset class="p-4 border rounded">
                  <legend class="text-[10px] font-semibold px-2">
                    {{ index !== 0 ? `Variant ${variant.group}` : "Default" }}
                  </legend>
                  <div
                    class="hover:border-gray-300 flex items-center justify-between"
                  >
                    <div class="w-full">
                      <input
                        type="text"
                        class="customInput w-[230px] hover:bg-gray-100 focus:bg-gray-100 transition duration-200 border border-gray-200 rounded-[5px] p-3 text-[12px] outline-0"
                        style="border-style: solid"
                        :value="variant.group"
                        :placeholder="accessibleGroups[index]"
                        :disabled="env === 'prod' ? true : false"
                      />
                    </div>
                    <div class="flex items-center gap-2">
                      <v-btn
                        v-if="env === 'test'"
                        color="blue darken-1"
                        text
                        small
                        :ripple="false"
                        @click="showVariantEditDialog(index)"
                      >
                        Edit Variant
                      </v-btn>
                      <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        small
                        :ripple="false"
                        @click="showVariantEditDialog(index)"
                      >
                        Preview Variant
                      </v-btn>
                    </div>
                  </div>

                  <div class="my-3" v-if="env === 'test'">
                    <v-divider></v-divider>
                  </div>
                  <div v-if="env === 'test'">
                    <v-combobox
                      label="Tracker Tokens"
                      outlined
                      dense
                      class="text-[12px] w-full"
                      v-model="variant.tracker_tokens"
                      clearable
                      multiple
                      chips
                      small-chips
                      hide-details
                    ></v-combobox>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="isConfirmBtnDisabled"
              :loading="loadingButton"
              @click="updateExperiment"
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>

      <v-card v-else-if="loading">
        <div>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
        <div>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
        <div>
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </div>
      </v-card>

      <v-card v-else> An error occured while fetching the data. </v-card>

      <VariantEditDialog
        :show="showVariantEdit"
        @closed="showVariantEdit = false"
        :data="selectedVariantData"
        :title="selectedVariantTitle"
        :games="games"
        :eventTypes="eventTypes"
        :events="events"
        :index="selectedVariantIndex"
        @submit="submitVariant"
        :liveEventsUnmodified="JSON.parse(JSON.stringify(selectedVariantData))"
        :readOnly="env === 'prod' ? true : false"
      ></VariantEditDialog>
    </v-dialog>
  </div>
</template>

<script>
import { COUNTRIES } from "@/helper/utils";
import moment from "moment";
import VariantEditDialog from "./VariantEditDialog.vue";
import {
  LIVE_OPS_DRAFT_TYPE_COLORS,
  LIVE_OPS_TYPE_COLORS,
} from "@/helper/utils";
export default {
  data() {
    return {
      loadingButton: false,
      accessibleGroups: ["A", "B", "C", "D", "E"],
      visible: false,
      selectedVariantData: [],
      selectedUnmodifiedVariantData: [],
      selectedVariantTitle: null,
      start_date_menu: false,
      end_date_menu: false,
      showVariantEdit: false,
      selectedVariantIndex: null,
      loading: false,
      experimentData: null,
    };
  },
  methods: {
    submitVariant(index, data) {
      this.experimentData.variants[index].liveEvents = JSON.parse(
        JSON.stringify(data)
      );
    },
    allowedDates(val) {
      if (moment(val) <= moment()) return false;
      return true;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchExperimentDetail() {
      if (!this.experiment_id || !this.env) return;

      try {
        this.loading = true;
        const request = await this.$api.get(
          `game-backend/experiments/${this.experiment_id}?env=${this.env}`
        );
        this.experimentData = request.data;
        this.experimentData.start_date = this.experimentData.start_date
          ? moment(this.experimentData.start_date).format("YYYY-MM-DD")
          : null;
        this.experimentData.end_date = moment(
          this.experimentData.end_date
        ).format("YYYY-MM-DD");

        for (let i = 0; i < this.experimentData.variants.length; i++) {
          this.experimentData.variants[i].liveEvents = this.transformData(
            this.experimentData.variants[i].calendarData
          );
          this.experimentData.variants[i].liveEventsUnmodified = JSON.parse(
            JSON.stringify(this.experimentData.variants[i].calendarData)
          );
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    modifyVariantData(variant) {
      return {
        id: variant.id,
        group: variant.group,
        created_at: variant.created_at,
        experiment_id: variant.experiment_id,
        calendarData: this.modifyCalendarData(
          variant.liveEvents,
          variant.liveEventsUnmodified
        ),
        tracker_tokens: variant.tracker_tokens,
      };
    },
    modifyCalendarData(data, unmodifiedData) {
      const modifiedData = data
        .filter((x) => !x.delete)
        .filter((x) => !x.copied)
        .map((x) => {
          const event = this.events.find((event) => event.id === x.event_id);
          const liveEventUnmodified = unmodifiedData.find(
            (event) => event.id === x.event_id
          );

          return {
            schedule_id: x.id ? x.id : null,
            event_id: x.event_id,
            start_date: x.start_date,
            end_date: x.end_date,
            isRecurring: x.isRecurring ? x.isRecurring : false,
            recurringOptions: x.recurringOptions ? x.recurringOptions : null,
            delete: false,
            deleteWithRecurrings: x.deleteWithRecurrings ? true : false,
            event_type: event ? event.type.slug : liveEventUnmodified.type,
            event_theme: event ? event.theme.slug : liveEventUnmodified.theme,
            payload: event
              ? event.payload
                ? JSON.stringify(event.payload)
                : null
              : liveEventUnmodified.payload,
            min_version: event
              ? event.min_version
              : liveEventUnmodified.min_version,
            event_name: x.event_name,
            event_alias: x.alias,
            event_description: event ? event.description : x.event_description,
            updated: x.updated ? x.updated : false,
          };
        });

      return modifiedData;
    },
    async updateExperiment() {
      try {
        this.loadingButton = true;
        const requestData = {
          ...this.experimentData,
          variants: this.experimentData.variants.map((x) =>
            this.modifyVariantData(x)
          ),
          start_date: moment(this.experimentData.start_date).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          end_date: moment(this.experimentData.end_date).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
        };

        await this.$api.put(
          `game-backend/experiments/update?env=${this.env}`,
          requestData
        );

        this.$emit("success");
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingButton = false;
      }
    },
    showVariantEditDialog(index) {
      const variant = this.experimentData.variants[index];

      this.selectedVariantData = JSON.parse(JSON.stringify(variant.liveEvents));

      this.selectedUnmodifiedVariantData = JSON.parse(
        JSON.stringify(variant.liveEventsUnmodified)
      );

      this.selectedVariantTitle = variant.id;
      this.showVariantEdit = true;
      this.selectedVariantIndex = index;
    },
    transformData(data) {
      if (!this.events) {
        return;
      }

      const result = [];

      data.forEach((item) => {
        const event = this.events.find((x) => x.id === item.id);
        if (!event) {
          item.schedule.forEach((schedule) => {
            result.push({
              id: schedule.id,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              event_id: item.id,
              event_name: item.name ? item.name : item.type.replace("-", " "),
              alias: item.alias ? item.alias : item.theme.replace("-", " "),
              type_icon: this.computedColors[item.type].icon,
              color: this.computedColors[item.type].color,
              type_id: null,
              vuetify_color: this.computedColors[item.type].vuetify_color,
            });
          });
        } else {
          const type = event.type;

          item.schedule.forEach((schedule) => {
            result.push({
              id: schedule.id,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              event_id: event.id,
              event_name: event.name,
              alias: event.alias,
              type_icon: this.computedColors[type.slug].icon,
              color: this.computedColors[type.slug].color,
              type_id: type.id,
              vuetify_color: this.computedColors[type.slug].vuetify_color,
            });
          });
        }
      });

      return result;
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    experiment_id: {
      type: String,
      default: null,
    },
    env: {
      type: String,
      default: null,
    },
    games: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    eventTypes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    experiment_id(newValue) {
      if (newValue) this.fetchExperimentDetail();
    },
    env(newValue) {
      if (newValue) this.fetchExperimentDetail();
    },
  },
  computed: {
    computedColors() {
      return LIVE_OPS_TYPE_COLORS;
    },
    computedDraftColors() {
      return LIVE_OPS_DRAFT_TYPE_COLORS;
    },
    computedVersionConditions() {
      return [
        {
          title: "Lower than",
          value: "lower than",
        },
        {
          title: "Greater than",
          value: "greater than",
        },
        {
          title: "Equal",
          value: "equal",
        },
        {
          title: "Equal or lower than",
          value: "equal or lower than",
        },
        {
          title: "Equal or greater than",
          value: "equal or greater than",
        },
      ];
    },
    computedCountries() {
      return COUNTRIES;
    },
    isConfirmBtnDisabled() {
      // Zorunlu alanları kontrol et.
      if (!this.env) return true;

      if (this.env === "test") {
        if (!this.experimentData.title) return true;
        if (!this.experimentData.description) return true;
        if (!this.experimentData.platform) return true;
        if (
          this.experimentData.version &&
          !this.experimentData.version_condition
        )
          return true;
        if (!this.experimentData.start_date) return true;
        if (!this.experimentData.end_date) return true;
        if (!this.experimentData.rollout) return true;

        // Başlangıç tarihi bitişten daha kısa olamaz.
        if (
          moment(this.experimentData.start_date) >
          moment(this.experimentData.end_date)
        )
          return true;

        // Grup isimleri yoksa buton kapalı olmalı.
        for (let i = 0; i < this.experimentData.variants.length; i++) {
          const variant = this.experimentData.variants[i];
          if (!variant.group) return true;
        }

        // Tüm şartlar sağlanıyorsa;
        return false;
      } else if (this.env === "prod") {
        if (!this.experimentData.start_date) return true;
        if (!this.experimentData.end_date) return true;

        // Başlangıç tarihi bitişten daha kısa olamaz.
        if (
          moment(this.experimentData.start_date) >
          moment(this.experimentData.end_date)
        )
          return true;

        // Tüm şartlar sağlanıyorsa;
        return false;
      }

      return true;
    },
  },
  components: {
    VariantEditDialog,
  },
};
</script>

<style lang="scss" scoped>
.customInput {
  &::placeholder {
    color: rgba($color: #000000, $alpha: 0.45);
  }

  color: rgba($color: #000000, $alpha: 0.9);
}
</style>
