<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">TikTok Campaign Creator</h1>
    <div>
      <p class="font-semibold text-[13px] text-gray-500">
        1. Choose a store page
      </p>
      <v-select
        hide-details
        class="text-[13px]"
        item-text="game_name"
        item-value="id"
        label="Choose a store page"
        outlined
        dense
        :items="storePages"
        v-model="selectedStorePage"
        return-object
      ></v-select>
    </div>
    <div class="mt-4">
      <v-text-field
        label="Budget"
        outlined
        v-model="budget"
        type="number"
        :min="20"
        disabled
        dense
        hide-details
        class="text-[13px]"
        prefix="$"
      ></v-text-field>
    </div>
    <div class="mt-4" v-if="selectedStorePage">
      <p class="font-semibold text-[13px] text-gray-500">2. Choose video(s)</p>
      <v-file-input
        multiple
        show-size
        accept="video/mp4"
        label="Choose video(s) that will be used in advertisement..."
        class="text-[13px]"
        v-model="videos"
      ></v-file-input>
    </div>
    <div
      class="mt-4"
      v-if="videos && videos.length > 0 && computedErrors.length === 0"
    >
      <VideoSlider :videos="videos" @delete_video="handleOnDeleteVideo" />
    </div>
    <div class="mt-4 flex items-center justify-end">
      <v-btn
        :loading="loading"
        color="primary"
        @click="createCampaign"
        :disabled="
          !selectedStorePage ||
          videos.length === 0 ||
          computedErrors.length > 0 ||
          loading
        "
      >
        Create
      </v-btn>
    </div>
    <ErrorDialog
      :videos="videos"
      :show="computedErrors.length > 0"
      :errors="computedErrors"
      @closed="handleErrorDialogClosed"
    ></ErrorDialog>
    <CampaignPreview
      @approved="handleOnPreviewApproved"
      :videos="videos"
      :data="computedData"
      :show="show_preview"
    />
    <CampaignResultDialog
      :data="request_data"
      :show="show_result"
      @closed="handleResultDialogClosed"
    />
  </div>
</template>

<script>
// import moment from "moment";
import ErrorDialog from "@/components/tools/ccc/facebook/campaign-create-view/ErrorDialog.vue";
import VideoSlider from "@/components/tools/ccc/facebook/campaign-create-view/VideoSlider.vue";
import CampaignPreview from "@/components/tools/ccc/tiktok/CampaignPreview.vue";
import CampaignResultDialog from "@/components/tools/ccc/tiktok/CampaignResultDialog.vue";

export default {
  name: "CampaignCreateView",
  data() {
    return {
      selectedStorePage: null,
      storePages: [],
      dimension_errors: [],
      regex_errors: [],
      size_errors: [],
      videos: [],
      loading: false,
      budget: 50,
      show_preview: false,
      show_result: false,
      request_data: null,
    };
  },
  methods: {
    handleOnPreviewApproved(data) {
      this.request_data = data;
      this.show_result = true;
      this.show_preview = false;
    },
    setDimensionErrors() {
      if (this.videos && this.videos.length > 0) {
        for (let i = 0; i < this.videos.length; i++) {
          const file = this.videos[i];
          const url = URL.createObjectURL(file);
          const $video = document.createElement("video");
          $video.src = url;
          const pushDimensionError = (error) => {
            this.dimension_errors.push(error);
          };
          $video.addEventListener("loadedmetadata", function () {
            const ratio = 9 / 16;
            const video_ratio = this.videoWidth / this.videoHeight;
            if (video_ratio !== ratio) {
              pushDimensionError({
                detail: `<span class='font-semibold'>${file.name}</span> is not within the recommended dimensions.`,
                solution: `Tiktok vids must be 9x16. Ex: 720 x 1080, 1080 x 1920...`,
              });
            }
          });
        }
      }
    },
    setRegexErros() {
      if (this.videos && this.videos.length > 0) {
        for (let i = 0; i < this.videos.length; i++) {
          const file_name = this.videos[i].name;
          const regex = new RegExp(
            /([a-zA-Z]+_[1-9]+x[1-9]+_[a-zZA-Z]+[1-9]+.mp4)$/gim
          );
          if (!regex.test(file_name)) {
            this.regex_errors.push({
              detail: `<b>${file_name}</b> is not within the suggested name format or extension.`,
              solution: `File name must be in following format and all vids must be an mp4. <span class='font-semibold'>(GameName)_(Width)x(Height)_(Fail[1-9] or Win[1-9]).mp4</span><br> <br> <span class='font-semibold'>Ex:</span> CarpetRoller_9x16_Win1.mp4`,
            });
          }
        }
      }
    },
    setFileSizeError() {
      if (this.videos && this.videos.length > 0) {
        for (let i = 0; i < this.videos.length; i++) {
          const file = this.videos[i];
          const size = file.size;
          const size_in_mb = Math.round(size * 0.000001 * 100) / 100;
          if (size_in_mb > 10) {
            this.size_errors.push({
              detail: `<b>${file.name}</b> is not within the suggested file size.`,
              solution: `TikTok vids must be smaller than 10 mb, shorter than 60 secs and longer than 5 secs.`,
            });
          }
        }
      }
    },
    fetchStorePages() {
      this.$api
        .get("ccc/tiktok/store-page/all?only_actives=True")
        .then((response) => {
          this.storePages = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createCampaign() {
      this.show_preview = true;
    },
    handleErrorDialogClosed() {
      this.videos = [];
      this.regex_errors = [];
      this.dimension_errors = [];
      this.size_errors = [];
    },
    handleOnDeleteVideo(name) {
      this.videos = this.videos.filter((x) => x.name !== name);
    },
    handleResultDialogClosed() {
      this.videos = [];
      this.selectedStorePage = null;
    },
  },
  mounted() {
    this.fetchStorePages();
  },
  watch: {
    videos(newValue) {
      this.setDimensionErrors(newValue);
      this.setRegexErros(newValue);
      this.setFileSizeError(newValue);
    },
  },
  components: {
    ErrorDialog,
    VideoSlider,
    CampaignPreview,
    CampaignResultDialog,
  },
  computed: {
    computedErrors() {
      return [
        ...this.regex_errors,
        ...this.dimension_errors,
        ...this.size_errors,
      ];
    },
    computedData() {
      if (this.videos.length !== 0 && this.computedErrors.length === 0) {
        return {
          videos: this.videos,
          selectedStorePage: this.selectedStorePage,
          budget: this.budget,
        };
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__slot {
  label {
    font-size: 13px;
  }
}
</style>
