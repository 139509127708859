<template>
  <div>
    <v-dialog v-model="visible" width="700">
      <v-card>
        <v-card-title> Fatura Özeti </v-card-title>

        <v-card-text>
          <div v-if="summaryData.length">
            <div
              class="flex flex-col p-2 bg-orange-100 hover:orange-200 rounded-[3px] mb-3"
            >
              <div class="flex items-center">
                <span class="text-gray-700 font-semibold text-[12px]">
                  Toplam Tutar (USD Faturalar):
                </span>
                <span class="text-[12px] font-semibold text-orange-700 ml-2">
                  {{ computedUsdInvoices.toLocaleString("de-DE") }} $
                </span>
              </div>
              <div class="flex items-center mt-1">
                <span class="text-gray-700 font-semibold text-[12px]">
                  Toplam Tutar (TRY Faturalar):
                </span>
                <span class="text-[12px] font-semibold text-orange-700 ml-2">
                  {{ computedTrlInvoices.toLocaleString("de-DE") }} ₺
                </span>
              </div>
              <div class="flex items-center mt-1">
                <span class="text-gray-700 font-semibold text-[12px]">
                  Toplam Tutar (EUR Faturalar):
                </span>
                <span class="text-[12px] font-semibold text-orange-700 ml-2">
                  {{ computedEuroInvoices.toLocaleString("de-DE") }} €
                </span>
              </div>
            </div>
            <div
              v-for="(invoice, index) in summaryData"
              :key="index"
              class="p-2 mb-1 hover:bg-gray-100 rounded-[3px]"
            >
              <div class="flex flex-col">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <span class="font-semibold text-gray-800 text-[13px]">
                      {{ invoice.parasut_customer_title }}
                    </span>
                    <span
                      class="ml-2 px-1 py-0 text-[10px] bg-sky-800 text-white font-semibold rounded-[3px]"
                    >
                      {{ invoice.category_title }}
                    </span>
                    <div
                      class="px-1 bg-rose-800 text-white font-semibold text-[10px] rounded-[3px] ml-2"
                    >
                      KDV: {{ invoice.parasut_tax }} %
                    </div>
                    <div
                      class="px-1 bg-lime-700 text-white font-semibold text-[10px] rounded-[3px] ml-2"
                    >
                      {{ invoice.game_title }}
                    </div>
                  </div>
                  <span class="font-semibold">
                    {{ invoice.total_price.toLocaleString("de-DE") }}
                    {{ computedCurrency(invoice.product_currency) }}
                  </span>
                </div>
                <div class="mt-1 flex items-center justify-between">
                  <span>
                    {{ invoice.parasut_product_title }}
                  </span>
                  <span
                    class="text-[11px] text-gray-900 bg-gray-200 rounded-[3px] px-1"
                  >
                    {{ invoice.issue_date }}
                  </span>
                </div>
                <div class="mt-2">
                  <div class="flex items-center">
                    <div class="font-semibold text-[11px]">
                      Adet Fiyat:
                      {{
                        invoice.product_price_per_unit.toLocaleString("de-DE")
                      }}
                      {{ computedCurrency(invoice.product_currency) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="createInvoice"> Onayla </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    data: {
      handler(newValue) {
        this.summaryData = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      visible: false,
      summaryData: [],
    };
  },
  methods: {
    createInvoice() {
      this.$emit("approved");
      this.visible = false;
    },
    computedCurrency(currency) {
      if (currency === "USD") return "$";
      if (currency === "TRL") return "₺";
      if (currency === "EUR") return "€";
      return null;
    },
  },
  computed: {
    computedUsdInvoices() {
      if (this.summaryData && this.summaryData.length) {
        const filtered = this.summaryData.filter(
          (x) => x.product_currency === "USD"
        );
        var sum = 0;

        for (let i = 0; i < filtered.length; i++) {
          const element = filtered[i];
          sum += element.total_price;
        }

        return sum;
      }
      return 0;
    },
    computedTrlInvoices() {
      if (this.summaryData && this.summaryData.length) {
        const filtered = this.summaryData.filter(
          (x) => x.product_currency === "TRL"
        );
        var sum = 0;

        for (let i = 0; i < filtered.length; i++) {
          const element = filtered[i];
          sum += element.total_price;
        }

        return sum;
      }
      return 0;
    },
    computedEuroInvoices() {
      if (this.summaryData && this.summaryData.length) {
        const filtered = this.summaryData.filter(
          (x) => x.product_currency === "EUR"
        );
        var sum = 0;

        for (let i = 0; i < filtered.length; i++) {
          const element = filtered[i];
          sum += element.total_price;
        }

        return sum;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
