export default {
  state: {
    selectedNetwork: "facebook",
  },
  getters: {
    getBurchaSelectedNetwork(state) {
      return state.selectedNetwork;
    },
  },
  mutations: {
    handleSetBurchaSelectedNetwork(state, payload) {
      state.selectedNetwork = payload;
    },
  },
  actions: {
    setBurchaSelectedNetwork({ commit }, payload) {
      commit("handleSetBurchaSelectedNetwork", payload);
    },
  },
};
