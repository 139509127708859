<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between pb-3">
        <div class="flex items-center">
          <h1 class="font-semibold text-[18px]">Game Live Events</h1>

          <div class="pl-4">
            <v-autocomplete
              hide-details
              class="text-[13px]"
              item-text="title"
              return-object
              label="Game"
              outlined
              dense
              :items="games"
              v-model="selected_game"
              style="width: 230px"
            >
            </v-autocomplete>
          </div>
          <div class="pl-4">
            <v-select
              hide-details
              class="text-[13px]"
              item-text="title"
              item-value="id"
              label="Deployment"
              outlined
              dense
              :items="deployments"
              v-model="selected_deployment"
              style="width: 230px"
              :disabled="loading || !selected_game || deploymentsLoading"
              :loading="deploymentsLoading"
            >
            </v-select>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <div class="flex items-center justify-between mb-3">
          <h1 class="font-semibold text-[14px] w-full">
            <span v-if="currentDate" class="mb-2.5 text-[14px] font-semibold">
              {{ returnMonth(currentDate.start.date) }}
            </span>
          </h1>
          <div
            class="flex items-center"
            v-if="
              selected_game &&
              selected_game.applovin_android_package_name &&
              !loading
            "
          >
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <v-calendar
          v-if="
            selected_game &&
            selected_game.applovin_android_package_name &&
            selected_deployment &&
            !loading
          "
          style="min-height: 600px"
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="
            liveEvents.map((x) => ({ ...x, end_date: minusDate(x.end_date) }))
          "
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-ripple="false"
          :event-text-color="''"
          event-end="end_date"
          event-start="start_date"
          @change="handleOnChangeCalendar"
          @click:event="handleOnCalendarEvent"
        >
          <template v-slot:event="{ event }">
            <div>
              <div>
                <div
                  class="flex items-center px-1.5"
                  style="line-height: normal"
                >
                  <v-icon
                    size="18"
                    class="ml-1.5 mr-0"
                    :color="event.vuetify_color"
                  >
                    {{ `mdi-${event.type_icon}` }}
                  </v-icon>
                  <div
                    class="ml-1.5 font-semibold text-[11px] flex items-center"
                  >
                    <span>
                      {{ event.event_name }}
                    </span>
                    <span class="ml-1.5" v-if="event.alias">
                      ({{ event.alias }})</span
                    >
                  </div>

                  <span class="ml-1.5 text-[11px]" v-if="!event.id">
                    (Draft)
                  </span>

                  <span
                    class="ml-1.5 text-[11px] font-semibold"
                    v-if="event.isRecurring"
                  >
                    Recurring - For each
                    {{ event.recurringOptions.period.value }}
                    {{ event.recurringOptions.period.mode.toLowerCase() }} -
                    {{ event.recurringOptions.amount }} times
                  </span>
                </div>
              </div>
            </div>
          </template>

          <!-- <template v-slot:day-label="{ day }">
            <div class="flex items-center justify-center">
              <div
                class="font-semibold text-[10px] hover:bg-gray-100 min-w-[40px] min-h-[40px] rounded-full leading-[40px]"
              >
                {{ day }}
              </div>
            </div>
          </template> -->
        </v-calendar>

        <div v-if="loading">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>

        <div
          v-if="!loading && !selected_game"
          class="text-[12px] p-4 rounded-[5px] bg-rose-50 text-rose-700 hover:bg-rose-100"
        >
          You need to choose a game first.
        </div>
      </div>
    </div>

    <PreviewCalendarLiveEventDetailsDialog
      :show="showEventPreviewDetails"
      @closed="showEventPreviewDetails = false"
      :data="eventPreviewData"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { LIVE_OPS_TYPE_COLORS } from "@/helper/utils";
import PreviewCalendarLiveEventDetailsDialog from "@/components/tools/game_backend/PreviewCalendarLiveEventDetailsDialog.vue";

export default {
  data() {
    return {
      showEventPreviewDetails: false,
      eventPreviewData: null,
      env: "prod",
      deployments: [],
      selected_deployment: null,
      currentMonth: null,
      currentDate: null,
      games: [],
      tableHeaders: [
        {
          text: "ID",
          value: "id",
          sortable: true,
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
        {
          text: "Type",
          value: "type_id",
          sortable: true,
        },
      ],
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      events: [],
      selected_game: null,
      loading: false,
      liveEvents: [],
      eventTypes: [],
      latestDeploymentId: null,
      liveEventsUnmodified: [],
      deploymentsLoading: false,
    };
  },
  methods: {
    ...mapActions(["setCopiedEventData"]),
    handleOnCalendarEvent({ event }) {
      this.eventPreviewData = this.liveEventsUnmodified.find(
        (x) => x.id === event.event_id
      );

      this.showEventPreviewDetails = true;
    },
    minusDate(date) {
      return moment(date).subtract(1, "seconds").format("YYYY-MM-DDTHH:mm:ss");
    },
    plusDate(date) {
      return moment(date).add(1, "seconds").format("YYYY-MM-DDTHH:mm:ss");
    },
    dateToUnix(date, hour, minute, second, mil) {
      return moment(date, "YYYY-MM-DD")
        .set({
          hour: hour,
          minute: minute,
          second: second,
          millisecond: mil,
        })
        .utc(false)
        .valueOf();
    },

    returnMonth(dateString) {
      const date = new Date(dateString);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Get the month index from the date object (0-based index)
      const monthIndex = date.getMonth();

      // Return the month name corresponding to the month index
      return `${monthNames[monthIndex]} - ${date.getFullYear()}`;
    },

    handleOnChangeCalendar(event) {
      this.currentDate = event;
    },

    setDeletedColor(index) {
      this.$set(
        this.liveEvents[index],
        "color",
        `bg-gray-100 border-gray-300 text-gray-900 border border-dashed rounded hover:bg-gray-200`
      );
      this.$set(this.liveEvents[index], "vuetify_color", `grey darken-4`);
    },

    async fetchLiveEventTypes() {
      try {
        const request = await this.$api.get(
          `game-backend/live-event-types/all`
        );
        this.eventTypes = request.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },

    async fetchDeployments(setLatestDeployment) {
      try {
        this.deploymentsLoading = true;

        if (!this.selected_game) return;
        const response = await this.$api.get(
          `game-backend/deployments/all?package_name=${this.selected_game.applovin_android_package_name}&env=${this.env}`
        );
        if (response.data && response.data.length) {
          this.deployments = response.data.map((x) => ({
            ...x,
            title: `${x.id} - ${this.$formatUnix(x.created_at)}`,
          }));

          if (!this.selected_deployment || setLatestDeployment) {
            this.selected_deployment =
              this.deployments[this.deployments.length - 1].id;

            this.deployments[this.deployments.length - 1].title = `${
              this.deployments[this.deployments.length - 1].id
            } - Latest`;
          }
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.deploymentsLoading = false;
      }
    },

    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },

    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data.filter(
            (x) => x.applovin_android_package_name
          );
        })
        .catch((err) => {
          this.errorHandler(err);
        });
    },

    transformData(data) {
      if (!this.events) {
        return;
      }

      const result = [];

      data.forEach((item) => {
        const event = this.events.find((x) => x.id === item.id);
        if (!event) {
          item.schedule.forEach((schedule) => {
            result.push({
              id: schedule.id,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              event_id: item.id,
              event_name: item.name ? item.name : item.type.replace("-", " "),
              alias: item.alias ? item.alias : item.theme.replace("-", " "),
              type_icon: this.computedColors[item.type].icon,
              color: this.computedColors[item.type].color,
              type_id: null,
              vuetify_color: this.computedColors[item.type].vuetify_color,
            });
          });
        } else {
          const type = event.type;

          item.schedule.forEach((schedule) => {
            result.push({
              id: schedule.id,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              event_id: event.id,
              event_name: event.name,
              alias: event.alias,
              type_icon: this.computedColors[type.slug].icon,
              color: this.computedColors[type.slug].color,
              type_id: type.id,
              vuetify_color: this.computedColors[type.slug].vuetify_color,
            });
          });
        }
      });

      return result;
    },

    async fetchDeploymentSchedule() {
      try {
        if (!this.selected_deployment) return;
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/schedules/all?deployment_id=${this.selected_deployment}&package_name=${this.selected_game.applovin_android_package_name}&env=${this.env}`
        );

        this.liveEvents = this.transformData(request.data.events);
        this.liveEventsUnmodified = JSON.parse(
          JSON.stringify(request.data.events)
        );
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchLiveEvents() {
      try {
        if (!this.selected_game) return;
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/events/all?&package_name=${this.selected_game.applovin_android_package_name}`
        );

        this.events = request.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    getEventColor(event) {
      if (event.isClicked) {
        return "bg-rose-100 border border-rose-300 border-dashed text-rose-300";
      } else {
        return `border border-transparent ${event.customColor}`;
      }
    },

    getHoursFromDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("HH:mm");
    },
  },
  mounted() {
    this.fetchGames();
    this.fetchLiveEventTypes();
  },
  watch: {
    selected_game: {
      async handler(newValue) {
        if (newValue) {
          await this.fetchLiveEvents();
          await this.fetchDeployments(true);
        }
      },
      deep: true,
    },

    selected_deployment: {
      async handler(newValue) {
        if (newValue) {
          await this.fetchDeploymentSchedule();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["getCopiedEventData"]),
    computedColors() {
      return LIVE_OPS_TYPE_COLORS;
    },
    computedTableData() {
      var data = [];
      if (this.liveEventsUnmodified.length && this.currentDate) {
        for (let i = 0; i < this.liveEventsUnmodified.length; i++) {
          const event = this.liveEventsUnmodified[i];
          const start_date = this.dateToUnix(
            this.currentDate.start.date,
            0,
            0,
            0,
            0
          );
          const end_date = this.dateToUnix(
            this.currentDate.end.date,
            0,
            0,
            0,
            0
          );

          const hasValidDate = event.schedule.some(
            (x) => start_date >= x.start_date && start_date <= end_date
          );
          if (hasValidDate) {
            data.push({
              id: event.live_event.id,
              name: event.live_event.name,
              description: event.live_event.description,
              type_id: event.live_event.type.id,
              type_name: event.live_event.type.name,
              icon: event.live_event.type.icon,
              color: event.live_event.type.color,
              vuetify_color: event.live_event.type.vuetify_color,
            });
          }
        }
      }
      return data;
    },
  },
  components: {
    PreviewCalendarLiveEventDetailsDialog,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-calendar-weekly__day-label {
  margin: 0 !important;
}

::v-deep .v-calendar-weekly__day-label.draggable {
  background-color: rgba($color: #4a80de, $alpha: 0.1);
}
::v-deep .v-event {
  display: flex;
  align-items: center;
  .v-event-drag-bottom {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    top: 0;
    cursor: ew-resize;

    &::after {
      display: none;
      position: absolute;
      right: 4px;
      top: 50%;
      border-top: 2px solid white;
      border-bottom: 2px solid white;
      width: 8px;
      margin-left: -8px;
      height: 7px;
      transform: translate(0, -50%);
      opacity: 0.8;
      content: "";
    }

    &:hover::after {
      display: block;
    }
  }
}

::v-deep .v-event.primary {
  background-color: inherit !important;
}
</style>
