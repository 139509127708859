<template>
  <div>
    <div class="border rounded">
      <v-expansion-panels
        accordion
        focusable
        flat
        v-model="selectedModifiedDataPanel"
      >
        <v-expansion-panel v-for="(offer, index) in modifiedData" :key="index">
          <v-expansion-panel-header>
            <div class="flex items-center">
              <v-icon
                size="12"
                class="mr-2"
                :color="offer.status === 'PAUSED' ? 'red' : 'green'"
              >
                mdi-circle
              </v-icon>
              <span
                class="font-semibold text-[11px]"
                :class="
                  offer.status === 'PAUSED' ? 'text-gray-400' : 'text-blue-900'
                "
              >
                {{ offer.campaign_name }}
              </span>
              <span class="mx-2 font-semibold text-[10px]"> > </span>
              <span
                class="font-semibold text-[11px]"
                :class="
                  offer.status === 'PAUSED' ? 'text-gray-400' : 'text-blue-900'
                "
              >
                {{ offer.offer_name }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h1
              class="mt-4 mb-0 font-semibold text-[12px] capitalize text-gray-900 flex items-center"
            >
              <span> 1. Select creatives to add </span>
            </h1>
            <div class="pt-3">
              <div class="w-full mb-5">
                <div class="grid grid-cols-4 gap-3">
                  <div
                    v-for="creative in offer.selectedCreativesForAdding"
                    :key="creative.ad_id"
                    class="rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100"
                    :class="{
                      selected: creative.selected,
                    }"
                    @click="creative.selected = !creative.selected"
                  >
                    <div class="flex items-center justify-between mb-2">
                      <div class="flex items-center">
                        <span
                          class="text-[11px]"
                          :class="
                            creative.selected
                              ? 'text-gray-700 font-semibold'
                              : 'text-gray-400'
                          "
                        >
                          {{ creative.ad_name }}
                        </span>
                        <span
                          class="text-[10px] ml-2"
                          v-if="creative.improvement"
                          :class="{
                            'font-semibold': creative.selected,
                            'text-gray-400': !creative.selected,
                            'text-lime-700':
                              creative.improvement >= 0 && creative.selected,
                            'text-rose-700':
                              creative.improvement < 0 && creative.selected,
                          }"
                        >
                          {{ creative.improvement.toFixed(2) }}%
                        </span>
                      </div>
                      <span
                        class="text-[9px] uppercase font-semibold p-1 rounded-[3px] hover:bg-gray-300 cursor-pointer select-none"
                        @click="
                          handlePreviewBtn(
                            $event,
                            creative.gaia_id,
                            creative.selected_playable
                          )
                        "
                        :class="
                          creative.selected
                            ? 'text-gray-700'
                            : 'text-gray-400 pointer-events-none'
                        "
                      >
                        Preview
                      </span>
                    </div>

                    <div class="mt-2">
                      <v-checkbox
                        dense
                        v-model="creative.is_playable"
                        hide-details
                        label="Add playable asset"
                        class="text-[10px]"
                        @click.stop
                        :disabled="!creative.selected"
                      >
                      </v-checkbox>
                    </div>

                    <div class="mt-2" @click.stop>
                      <v-autocomplete
                        clearable
                        label="Choose an asset"
                        outlined
                        auto-select-first
                        dense
                        class="text-[12px]"
                        v-model="creative.selected_playable"
                        :items="mintegralAssetData"
                        hide-details
                        :disabled="!creative.is_playable"
                        item-text="creative_name"
                        item-value="creative_md5"
                        return-object
                      >
                      </v-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <v-divider class="my-5"></v-divider> -->
              <!-- <h1
                class="mb-2 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
              >
                <span>2. Select Creatives to Replace </span>
              </h1> -->

              <!-- <h1 class="font-semibold text-[12px] mb-2.5">1. Images</h1>
              <div class="grid grid-cols-12 gap-2">
                <div
                  v-for="image in offer.creativesModified.images"
                  :key="image.creative_id"
                  class="flex items-center justify-center flex-col"
                >
                  <div
                    class="text-center text-[11px] max-w-[120px] overflow-hidden text-ellipsis inline-block mb-1"
                  >
                    {{ image.creative_name }}
                  </div>
                  <div
                    class="rounded-[3px] mr-1.5 cursor-pointer w-[120px] h-[120px] thumbnail"
                    :style="{
                      backgroundImage: `url(${image.preview_url})`,
                    }"
                    :class="{
                      'contrast-[0.1]': !image.selected,
                      'hover:shadow-lg hover:contrast-75': image.selected,
                    }"
                    @click="image.selected = !image.selected"
                  ></div>
                </div>
              </div> -->
              <!-- <div class="my-4">
                <v-file-input
                  v-model="offer.insertedImages"
                  color="primary"
                  label="Your Images"
                  multiple
                  prepend-icon="mdi-paperclip"
                  outlined
                  accept="image/*"
                  :show-size="1000"
                  dense
                  hide-details
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label small>
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ offer.insertedImages.length - 2 }}
                      File(s)
                    </span>
                  </template>
                </v-file-input>
              </div> -->
              <div v-if="offer.insertedImages.length" class="mt-3">
                <v-divider class="mb-3"></v-divider>
                <div
                  class="grid lg:grid-cols-12 md:grid-cols-6 sm:grid-cols-3 grid-cols-2 gap-3"
                >
                  <div
                    v-for="(image, imageIdx) in offer.insertedImages"
                    :key="imageIdx"
                  >
                    <div
                      class="rounded-[3px] mr-1.5 w-[120px] h-[120px] thumbnail"
                      :style="{
                        backgroundImage: `url(${computedSrc(image)})`,
                      }"
                    ></div>
                  </div>
                </div>
              </div>

              <h1 class="font-semibold text-[12px] mb-2.5 mt-4">2. Videos</h1>
              <h1 class="text-gray-800 font-semibold text-[11px] mb-3">
                Average IPM: {{ computedAverageIpm(offer, "VIDEO") }}
              </h1>
              <div>
                <div
                  v-for="video in $sortArrayByProperty(
                    offer.creativesModified.videos,
                    ['insights.ipm'],
                    ['desc']
                  )"
                  :key="video.creative_id"
                  class="flex items-center flex-col p-2 hover:bg-gray-100 rounded-[3px] cursor-pointer mb-1"
                  :class="!video.selected ? 'bg-gray-200' : ''"
                  @click="video.selected = !video.selected"
                >
                  <div class="flex items-center w-full">
                    <div
                      class="rounded-[3px] mr-1.5 bg-lime-100 h-[26px] w-[26px] flex items-center justify-center"
                    >
                      <span class="text-lime-500 play-icon rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-player-play-filled"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          stroke-width="1.75"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path
                            d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z"
                            stroke-width="0"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div class="text-[11px] w-full inline-block font-semibold">
                      {{ video.creative_name }}
                    </div>
                  </div>

                  <div
                    class="flex items-center justify-start w-full mt-[3px]"
                    v-if="Object.keys(video.insights).length"
                  >
                    <div
                      class="text-[11px] mr-1.5"
                      :class="{
                        'text-red-500':
                          computedAverageIpm(offer, 'VIDEO') >
                          video.insights.ipm,
                      }"
                    >
                      <span class="font-semibold">IPM:</span>
                      <span class="ml-[3px]">{{
                        video.insights.ipm.toFixed(2)
                      }}</span>
                    </div>
                    <div class="text-[11px] mr-1.5">
                      <span class="font-semibold">Impression:</span>
                      <span class="ml-[3px]">{{
                        video.insights.impression.toFixed(2)
                      }}</span>
                    </div>
                    <div class="text-[11px]">
                      <span class="font-semibold">Install:</span>
                      <span class="ml-[3px]">{{
                        video.insights.install.toFixed(2)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <h1 class="font-semibold text-[12px] mb-2.5 mt-4">
                3. Playables
              </h1>
              <h1 class="text-gray-800 font-semibold text-[11px] mb-3">
                Average IPM: {{ computedAverageIpm(offer, "PLAYABLE") }}
              </h1>
              <div>
                <div
                  v-for="playable in offer.creativesModified.playables"
                  :key="playable.creative_id"
                  class="flex flex-col p-2 hover:bg-gray-100 rounded-[3px] cursor-pointer mb-1 p-2"
                  :class="!playable.selected ? 'bg-gray-200' : ''"
                  @click="playable.selected = !playable.selected"
                >
                  <div class="flex items-center">
                    <div>
                      <span class="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-file-type-html"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          stroke-width="1.75"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                          <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4"></path>
                          <path d="M2 21v-6"></path>
                          <path d="M5 15v6"></path>
                          <path d="M2 18h3"></path>
                          <path d="M20 15v6h2"></path>
                          <path d="M13 21v-6l2 3l2 -3v6"></path>
                          <path d="M7.5 15h3"></path>
                          <path d="M9 15v6"></path>
                        </svg>
                      </span>
                    </div>
                    <div class="text-[11px] w-full font-semibold ml-1">
                      {{ playable.creative_name }}
                    </div>
                  </div>

                  <div
                    class="w-full mt-[3px] flex items-center"
                    v-if="Object.keys(playable.insights).length"
                  >
                    <div
                      class="text-[11px] mr-1"
                      :class="{
                        'text-red-500':
                          computedAverageIpm(offer, 'PLAYABLE') >
                          playable.insights.ipm,
                      }"
                    >
                      <span class="font-semibold">IPM:</span>
                      <span class="ml-[3px]">{{
                        playable.insights.ipm.toFixed(2)
                      }}</span>
                    </div>
                    <div class="text-[11px] mr-1">
                      <span class="font-semibold">Impression:</span>
                      <span class="ml-[3px]">{{
                        playable.insights.impression.toFixed(2)
                      }}</span>
                    </div>
                    <div class="text-[11px]">
                      <span class="font-semibold">Install:</span>
                      <span class="ml-[3px]">{{
                        playable.insights.install.toFixed(2)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- <div v-else-if="unityReportsLoading">
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </div> -->

    <div class="flex justify-end items-center my-3">
      <v-btn
        color="primary"
        depressed
        class="font-semibold"
        @click="showSummary = true"
      >
        Replace Creatives
      </v-btn>
    </div>

    <SummaryDialog
      :data="modifiedData"
      :selectedCreatives="selectedCreatives"
      :show="showSummary"
      @closed="handleSummaryDialogClose"
      @submitted="replaceCreatives"
    />
  </div>
</template>

<script>
// import PreviewDialog from "./PreviewDialog.vue";
import SummaryDialog from "./SummaryDialog.vue";

export default {
  props: {
    selectedCampaigns: {
      type: Array,
      default: () => [],
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    game_id: {
      type: Number,
      default: null,
    },
    platform: {
      type: String,
      default: null,
    },
  },
  watch: {
    selectedCampaigns: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.length && this.selectedCreatives.length) {
          this.modifiedData = JSON.parse(
            JSON.stringify(this.mintegralDataModifier(newValue))
          );
        }
      },
    },
  },
  data() {
    return {
      selectedAds: [],
      loading: false,
      adData: [],
      modifiedData: [],
      mintegralAssetData: [],
      loadingBtn: false,
      showPreview: false,
      preview_selected_gaia_id: null,
      preview_selected_playable: null,
      showSummary: false,
      selectedModifiedDataPanel: null,
    };
  },
  methods: {
    computedSrc(image) {
      return URL.createObjectURL(image);
    },
    computedAverageIpm(offer, type) {
      if (!offer.creatives.length) {
        return null;
      }

      var total = 0;
      for (let y = 0; y < offer.creatives.length; y++) {
        const creative = offer.creatives[y];
        if (creative.creative_type === type)
          total += creative.insights.ipm
            ? parseFloat(creative.insights.ipm)
            : 0;
      }

      const length = offer.creatives.filter(
        (x) => Object.keys(x.insights).length && x.creative_type === type
      ).length;
      return length ? Math.round((total / length) * 100) / 100 : 0;
    },
    handleSummaryDialogClose() {
      this.showSummary = false;
    },
    handlePreviewDialogClose() {
      this.showPreview = false;
      this.preview_selected_gaia_id = null;
      this.preview_selected_playable = null;
    },
    handlePreviewBtn($event, gaia_id, playable_id) {
      $event.stopPropagation();
      this.preview_selected_gaia_id = gaia_id;
      this.preview_selected_playable = playable_id;
      this.showPreview = true;
    },
    async replaceCreatives() {
      this.showSummary = false;

      let has_error = false;
      for (let i = 0; i < this.modifiedData.length; i++) {
        const modifiedData = this.modifiedData[i];
        var requestData = this.computedRequestData(modifiedData);

        if (requestData) {
          const requestDataMapped = {
            ...requestData,
            deleted_images: requestData.deleted_images.map((x) => ({
              apply_in_area: x.apply_in_area,
              creative_id: x.creative_id,
              creative_name: x.creative_name,
              creative_set_id: x.creative_set_id,
              creative_set_name: x.creative_set_name,
              creative_type: x.creative_type,
            })),

            deleted_playables: requestData.deleted_playables.map((x) => ({
              apply_in_area: x.apply_in_area,
              creative_id: x.creative_id,
              creative_name: x.creative_name,
              creative_set_id: x.creative_set_id,
              creative_set_name: x.creative_set_name,
              creative_type: x.creative_type,
            })),

            deleted_videos: requestData.deleted_videos.map((x) => ({
              apply_in_area: x.apply_in_area,
              creative_id: x.creative_id,
              creative_name: x.creative_name,
              creative_set_id: x.creative_set_id,
              creative_set_name: x.creative_set_name,
              creative_type: x.creative_type,
            })),

            selected_creatives: requestData.selected_creatives.map((x) => ({
              gaia_id: x.gaia_id,
              ad_name: x.ad_name,
              selected_playable: x.selected_playable,
            })),
          };

          let formData = new FormData();
          formData.append(
            "deleted_videos",
            JSON.stringify(requestDataMapped.deleted_videos)
          );
          formData.append("game_id", requestDataMapped.game_id);
          formData.append(
            "deleted_images",
            JSON.stringify(requestDataMapped.deleted_images)
          );
          formData.append(
            "deleted_playables",
            JSON.stringify(requestDataMapped.deleted_playables)
          );
          formData.append("offer_id", requestDataMapped.offer_id);
          formData.append("offer_name", requestDataMapped.offer_name);
          formData.append("campaign_id", requestDataMapped.campaign_id);
          formData.append("campaign_name", requestDataMapped.campaign_name);
          formData.append("target_ad_type", requestDataMapped.target_ad_type);
          formData.append(
            "selected_creatives",
            JSON.stringify(requestDataMapped.selected_creatives)
          );

          for (let i = 0; i < requestDataMapped.inserted_images.length; i++) {
            formData.append(
              "inserted_images",
              requestDataMapped.inserted_images[i]
            );
          }

          try {
            const request = await this.$api.post(
              `burcha/mintegral/tasks/create`,
              formData
            );
            if (request.status === 200) {
              this.$toast.success(
                `Task created for ${requestDataMapped.offer_name}.`,
                {
                  timeout: 5000,
                  position: "bottom-center",
                  icon: false,
                }
              );
            }
          } catch (error) {
            has_error = true;
            this.errorHandler(error);
          }
        }
        continue;
      }

      if (!has_error) {
        this.$router.push({ name: "home" });
      }
      // await this.createUnityTask(requestData);
    },
    async createUnityTask(data) {
      try {
        this.loadingBtn = true;
        const request = await this.$api.post(
          `burcha/unity/tasks/create/create-task`,
          data
        );

        if (request.status === 200) {
          this.$toast.success(
            "Tasks successfully created. You will be notified on Slack when tasks run.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          this.$router.push({ name: "home" });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingBtn = false;
      }
    },
    mintegralDataModifier(data) {
      if (!data.length) return [];
      var modifiedData = [];

      for (let i = 0; i < data.length; i++) {
        var campaign = data[i];
        for (let y = 0; y < campaign.offers.length; y++) {
          var offer = campaign.offers[y];
          offer.selectedCreativesForAdding = JSON.parse(
            JSON.stringify(
              this.selectedCreatives.map((x) => ({
                ...x,
                selected: true,
                is_playable: false,
                selected_playable: null,
              }))
            )
          );

          offer.selectedCreativesForDeleting = [];

          var creatives = {
            images: [],
            videos: [],
            playables: [],
          };

          if (offer.creatives && offer.creatives.length) {
            offer.creatives.forEach((creative) => {
              if (creative.creative_type === "IMAGE")
                creatives.images.push({ ...creative, selected: true });
              if (creative.creative_type === "VIDEO")
                creatives.videos.push({ ...creative, selected: true });
              if (creative.creative_type === "PLAYABLE")
                creatives.playables.push({ ...creative, selected: true });
            });
            offer.creativesModified = JSON.parse(JSON.stringify(creatives));
            offer.insertedImages = [];
          }

          if (offer.status !== "STOPPED") {
            modifiedData.push(offer);
          }
        }
      }

      return modifiedData;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchPlayableAssets() {
      try {
        const request = await this.$api.get(
          `burcha/playables/mintegral/all?game_id=${this.game_id}`
        );
        if (request) {
          this.mintegralAssetData = request.data;
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    computedRequestData(data) {
      const selectedCreatives = data.selectedCreativesForAdding.filter(
        (x) => x.selected
      );
      // if (!selectedCreatives.length) return null;

      var requestData = {
        deleted_videos: [
          ...data.creativesModified.videos.filter((x) => !x.selected),
        ],
        deleted_images: [
          ...data.creativesModified.images.filter((x) => !x.selected),
        ],
        inserted_images: [...data.insertedImages],
        deleted_playables: [
          ...data.creativesModified.playables.filter((x) => !x.selected),
        ],
        offer_id: data.offer_id,
        offer_name: data.offer_name,
        campaign_id: data.campaign_id,
        target_ad_type: data.target_ad_type,
        campaign_name: data.campaign_name,
        selected_creatives: [...selectedCreatives],
        game_id: this.game_id,
      };

      requestData.inserted_images = data.insertedImages;

      return requestData;
    },
  },
  async mounted() {
    await this.fetchPlayableAssets();
    // this.unityReportsLoading = true;
    // this.unityRequestTimeout = setTimeout(await this.fetchUnityReports, 2000);
  },
  beforeDestroy() {
    clearInterval(this.unityRequestTimeout);
  },
  components: {
    //   PreviewDialog,
    SummaryDialog,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-footer {
  border-top: none !important;
}
::v-deep table {
  border: 1px solid rgba($color: #000000, $alpha: 0.15);
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.selectedAdSection {
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 2px 4px;
}

.selectedAdSection.selected {
  box-shadow: rgba(63, 216, 16, 0.4) 0px 0px 0px 1px,
    rgba(22, 93, 0, 0.1) 0px 2px 4px;
}

::v-deep .v-label {
  font-size: 10px !important;
  font-weight: 600;
}

.thumbnail {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.name-section {
  position: relative;

  .play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
