export default {
  state: {
    instance_status: null,
  },
  getters: {
    getAcgInstanceStatus(state) {
      return state.instance_status;
    },
  },
  mutations: {
    handleSetAcgInstanceStatus(state, payload) {
      state.instance_status = payload;
    },
  },
  actions: {
    setAcgInstanceStatus({ commit }, payload) {
      commit("handleSetAcgInstanceStatus", payload);
    },
  },
};
