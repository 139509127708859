import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import GAuth from "vue-google-oauth2";
import Notifications from "vue-notification";
import VueVideoPlayer from "vue-video-player";
import VJsoneditor from "v-jsoneditor/src/index";
import VueApexCharts from "vue-apexcharts";
import Toast from "vue-toastification";
import VuetifyDateTime from "vuetify-datetime";
import { VueMaskDirective } from "v-mask";
import InputFacade from "vue-input-facade";

// require videojs style
import "video.js/dist/video-js.css";

// Axios
import api from "./plugins/axios";
import functions from "@/helper/functions";

Vue.config.productionTip = false;

// Styling
import "./assets/styles/tailwind.css";
import "./assets/styles/global.scss";
import "vue-toastification/dist/index.css";
import "vue-advanced-cropper/dist/style.css";

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "profile email",
  prompt: "select_account",
};

Vue.directive("mask", VueMaskDirective);
Vue.use(GAuth, gauthOption);
Vue.use(api);
Vue.use(functions);
Vue.use(Notifications);
Vue.use(VueVideoPlayer);
Vue.use(VJsoneditor);
Vue.use(VueApexCharts);
Vue.use(Toast, {});
Vue.use(VuetifyDateTime);
Vue.use(InputFacade);

Vue.component("apex-chart", VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
