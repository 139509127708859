var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-white p-2"},[_c('div',[_c('v-text-field',{staticClass:"text-[13px] w-[310px]",attrs:{"label":"Search ","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('div',{staticClass:"my-2"},[_c('v-divider')],1),(!_vm.loading)?_c('div',{staticClass:"p-1 max-h-[320px] overflow-scroll min-h-[200px] max-w-[310px]"},[_vm._l((_vm.choices.data),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"hover:bg-sky-100 text-[12px] rounded-[3px] min-h-[32px] flex items-center justify-start px-2 capitalize cursor-pointer",class:{
            'font-semibold bg-sky-100':
              _vm.params.values.type === 'campaign'
                ? item.campaign_name === _vm.value
                : item.adgroup_name === _vm.value,
          },on:{"click":function($event){return _vm.setValue(
              _vm.params.values.type === 'campaign'
                ? item.campaign_name
                : item.adgroup_name
            )}}},[_c('span',[_vm._v(" "+_vm._s(_vm.params.values.type === "campaign" ? item.campaign_name : item.adgroup_name)+" ")])])])}),(!_vm.choices.data.length)?_c('div',{staticClass:"p-2 text-[11px] text-gray-400 text-center"},[_vm._v(" There is no data. ")]):_vm._e()],2):_c('div',{staticClass:"p-1 max-h-[320px] overflow-scroll min-h-[200px] max-w-[310px] mt-5"},[_c('div',{staticClass:"flex items-center justify-center"},[_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":20,"width":2}})],1),_c('span',{staticClass:"pl-2 text-[11px] font-semibold"},[_vm._v("Please wait...")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }