import CreateTestView from "@/views/tools/firebase_ab_tests/CreateTestView.vue";
import AllFormsView from "@/views/tools/firebase_ab_tests/AllFormsView.vue";

export const firebase_router = [
  {
    path: "/firebase/ab-tests/create-form",
    name: "create-form-view",
    component: CreateTestView,
    meta: {
      title: "Create a form",
    },
  },
  {
    path: "/firebase/ab-tests/all",
    name: "all-forms-view",
    component: AllFormsView,
    meta: {
      title: "All forms",
    },
  },
];
