<template>
  <div>
    <SurfaceResourceEditor
      v-if="type === 'SurfaceResourceData[]'"
      :variableValue="value"
      :show="show"
      @closed="handleEditorClosed"
      :valueStructure="valueStructure"
      :maxLength="maxLength"
      @surfaceValueChanged="handleOnSurfaceValueChanged"
    />
    <div class="flex items-center">
      <div
        class="colors mr-2"
        v-if="parameterLabel === 'colors' && colorsValid"
        :style="{
          background: `linear-gradient(${firstColor}, ${secondColor})`,
        }"
      ></div>

      <div
        class="colors mr-2"
        v-if="type.toLowerCase() === 'color'"
        :style="{
          background: `#${value}`,
        }"
      ></div>
      <v-text-field
        v-if="
          type === 'int' ||
          type === 'string' ||
          type === 'int[]' ||
          type === 'float[]' ||
          type === 'float' ||
          type === 'Color[]' ||
          type.toLowerCase() === 'color'
        "
        :label="parameterLabel"
        outlined
        v-model="value"
        :readonly="disable_all"
        dense
        :type="type === 'int' ? 'number' : 'text'"
        hide-details
        class="text-[13px]"
        disabled
      ></v-text-field>
      <v-checkbox
        v-else-if="type === 'bool'"
        v-model="value"
        hide-details
        :readonly="disable_all"
        dense
        :label="parameterLabel"
        disabled
      ></v-checkbox>
      <div
        v-else-if="type === 'SurfaceResourceData[]'"
        @click="showResourceEditor"
        class="h-[40px] border-gray-400 border rounded w-full flex items-center justify-center text-[12px] cursor-pointer hover:text-sky-600 hover:border-sky-600"
      >
        Edit Surface Data
      </div>
      <v-select
        v-else
        hide-details
        class="text-[13px]"
        :items="availableValues"
        :readonly="disable_all"
        :multiple="type === 'int[]'"
        dense
        :label="parameterLabel"
        v-model="value"
        outlined
        disabled
      ></v-select>
    </div>
  </div>
</template>

<script>
import SurfaceResourceEditor from "./SurfaceResourceEditor.vue";

export default {
  props: {
    type: {
      type: String,
      default: null,
    },
    parameterLabel: {
      type: String,
      default: null,
    },
    defaultValue: {
      default: null,
    },
    availableValues: {
      default: () => [],
      type: Array,
    },
    disable_all: {
      default: false,
      type: Boolean,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    valueStructure: {
      default: null,
    },
    maxLength: {
      default: null,
    },
  },
  data() {
    return {
      value: null,
      show: false,
    };
  },
  methods: {
    handleEditorClosed() {
      this.show = false;
    },
    showResourceEditor() {
      this.show = true;
    },
    updateValue(val) {
      this.value = val;
    },
    handleOnSurfaceValueChanged(value) {
      this.value = value;
    },
    isColorValid(color) {
      const pattern = /^([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
      return pattern.test(color);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (this.type === "int[]") {
          if (typeof newValue === "string") {
            newValue = newValue.split(",").map((x) => parseInt(x));
          }
        }
        if (this.type === "float[]") {
          if (typeof newValue === "string") {
            newValue = newValue.split(",").map((x) => parseFloat(x));
          }
        }
        if (this.type === "Color") {
          if (typeof newValue === "string") {
            if (newValue.startsWith("#")) {
              newValue = newValue.replace("#", "");
              this.value = newValue;
            }
          }
        }
        if (this.type === "int") {
          newValue = parseInt(newValue);
          if (this.min && newValue < this.min) {
            newValue = this.min;
            this.$nextTick(() => {
              this.value = newValue;
            });
          }
          if (this.max && newValue > this.max) {
            newValue = this.max;
            this.$nextTick(() => {
              this.value = newValue;
            });
          }
        }
        if (this.type === "float") {
          newValue = parseFloat(newValue);
          if (this.min && newValue < this.min) {
            newValue = this.min;
            this.$nextTick(() => {
              this.value = newValue;
            });
          }
          if (this.max && newValue > this.max) {
            newValue = this.max;
            this.$nextTick(() => {
              this.value = newValue;
            });
          }
        }
        this.$emit("changed", {
          variableName: this.parameterLabel,
          value: newValue,
        });
      },
      deep: true,
    },
    defaultValue: {
      handler(newValue) {
        this.value = newValue;
      },
      immediate: true,
    },
  },
  computed: {
    colorsValid() {
      if (this.parameterLabel === "colors") {
        const colors = this.value.split(",");
        if (colors.length !== 2) {
          return false;
        }
        const color1 = colors[0];
        const color2 = colors[1];

        const is_color1_valid = this.isColorValid(color1);
        const is_color2_valid = this.isColorValid(color2);

        if (!is_color1_valid || !is_color2_valid) {
          return false;
        }

        return true;
      }
      return false;
    },
    firstColor() {
      return `#${this.value.split(",")[0]}`;
    },
    secondColor() {
      return `#${this.value.split(",")[1]}`;
    },
  },
  components: {
    SurfaceResourceEditor,
  },
};
</script>

<style lang="scss" scoped>
.colors {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
</style>
