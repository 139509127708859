<template>
  <div>
    <h1 class="font-semibold text-[18px]">Networks</h1>
    <div>
      <div class="pt-5">
        <div class="flex items-center justify-between pb-5">
          <h1 class="text-[11px] font-semibold">
            Total Networks: {{ networks.length }}
          </h1>
          <div>
            <v-btn
              color="primary"
              @click="showNetworkDialog = true"
              small
              depressed
              class="font-semibold"
            >
              Add New Network
            </v-btn>
          </div>
        </div>
        <v-text-field
          placeholder="Search network"
          filled
          rounded
          dense
          v-model="keyword"
          append-icon="mdi-magnify"
          v-if="networks.length > 0"
        ></v-text-field>
        <div>
          <div>
            <v-data-table
              :headers="headers"
              :items="networks"
              :loading="loading"
              :items-per-page="10"
            >
              <template v-slot:[`item.label`]="{ item }">
                <span class="font-semibold text-[11px]">
                  {{ item.label }}
                </span>
              </template>

              <template v-slot:[`item.value`]="{ item }">
                <span class="font-semibold text-[11px]">
                  {{ item.value }}
                </span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <span
                  class="text-red-700 hover:text-red-500 cursor-pointer"
                  @click="deleteNetwork(item)"
                >
                  Delete
                </span>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>

    <InsertNetworkDialog
      :show="showNetworkDialog"
      @inserted="handleOnNetworkInsert"
      @closed="showNetworkDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InsertNetworkDialog from "./InsertNetworkDialog.vue";
import _ from "lodash";

export default {
  data() {
    return {
      networks: [],
      loading: false,
      keyword: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Label", value: "label" },
        { text: "Value", value: "value" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      showNetworkDialog: false,
    };
  },
  methods: {
    async deleteNetwork(row) {
      try {
        const response = await this.$api.delete(
          `umai-networks/delete?id=${row.id}`
        );
        if (response.status === 200) {
          this.fetchNetworks(this.keyword);
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async fetchNetworks(title) {
      this.loading = true;
      let url = "umai-networks/all";
      if (title) {
        url += `?title=${title}`;
      }
      try {
        const request = await this.$api.get(url);
        this.networks = request.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    handleOnNetworkInsert() {
      this.showNetworkDialog = false;
      this.$toast.success("Network has successfully inserted.", {
        timeout: 5000,
        position: "bottom-center",
        icon: false,
      });

      this.fetchNetworks(this.keyword);
    },
  },
  computed: {
    ...mapGetters(["getApps"]),
    computedGames() {
      if (this.getApps) {
        let data = this.getApps.filter((x) => {
          if ("object_store_urls" in x) {
            if ("itunes" in x.object_store_urls) {
              return true;
            }
            if ("google_play" in x.object_store_urls) {
              return true;
            }
          }
        });
        return data;
      }
      return [];
    },
  },
  mounted() {
    this.fetchNetworks();
  },
  watch: {
    keyword: _.debounce(function (newValue) {
      this.fetchNetworks(newValue);
    }, 200),
  },
  components: { InsertNetworkDialog },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__slot {
  input {
    font-size: 13px;
  }
}

::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

::v-deep .v-list-item__title {
  font-size: 12px;
}

::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba($color: #000, $alpha: 0.12);
}
</style>
