<template>
  <div>
    <v-dialog persistent v-model="visible" width="600">
      <v-card v-if="request_data">
        <v-card-title class="text-h5"> Result </v-card-title>

        <v-card-text>
          <div v-if="!loading">
            <div
              v-if="result.detail && result.detail.length > 0"
              class="p-4 mt-4 result-container bg-gray-100 rounded text-[12px]"
            >
              <div
                v-for="(item, index) in result.detail"
                :key="index"
                class="mb-2 text-gray-800"
              >
                <div v-if="item.prep_text" class="font-semibold mb-1">
                  {{ item.prep_text }}
                </div>
                <span
                  class="green--text text--darken-1 font-semibold inline-block mr-2"
                  v-if="item.success"
                  >Success :</span
                >
                <span
                  class="red--text text--darken-1 font-semibold inline-block mr-2"
                  v-else
                  >Failed :</span
                >
                {{ item.detail }}
              </div>
            </div>
          </div>
          <div v-if="loading">
            <div class="flex items-center justify-center flex-col pt-6">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <span class="mt-4 font-semibold"
                >The campaign is being created. Please wait...</span
              >
              <span class="mt-4 font-semibold"
                >Calculated time : {{ calculatedTime }}</span
              >
              <span class="text-[11px] mt-4 text-red-700"
                >It may takes longer/shorter according to your upload
                speed.</span
              >
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            text
            @click="visible = false"
          >
            Kapat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      result: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    request_data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;

        if (newValue) {
          this.createCampaign();
        }
      },
      immediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  computed: {
    computedStartDate() {
      let date = moment()
        .utcOffset("+0100")
        .add("1", "day")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      return date;
    },
    computedEndDate() {
      if (this.computedStartDate) {
        let end_date = moment(this.computedStartDate);
        end_date.add("6", "days");
        return end_date;
      }
      return null;
    },
    calculatedTime() {
      let time = this.request_data.videos.length * 95;
      let minutes = Math.floor(time / 60);

      return `${minutes} minutes, ${time - minutes * 60} seconds`;
    },
  },
  methods: {
    createCampaign() {
      if (this.request_data.videos && this.request_data.videos.length > 0) {
        this.loading = true;
        let formData = new FormData();
        for (let i = 0; i < this.request_data.videos.length; i++) {
          formData.append("videos", this.request_data.videos[i]);
        }
        formData.append("selected_game_id", this.request_data.application_id);
        formData.append(
          "selected_gaia_game",
          this.request_data.selected_gaia_game
        );
        formData.append("object_store_url", this.request_data.object_store_url);
        formData.append("android_url", this.request_data.android_url);
        formData.append("campaign_name", this.request_data.campaign_name);
        formData.append("adset_name", this.request_data.adset_name);
        formData.append("ad_name", this.request_data.ad_name);
        formData.append("game_name", this.request_data.game_name);

        this.$api
          .post("ccc/facebook/campaigns/create", formData)
          .then((response) => {
            this.result = response.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/sass/variables.scss";
.result-container {
  font-family: $log-font-family;
}
</style>
