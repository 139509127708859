export default {
  state: {
    customers: null,
    products: null,
    tags: null,
    categories: null,
  },
  getters: {
    getParasutCustomers(state) {
      return state.customers;
    },
    getParasutProducts(state) {
      return state.products;
    },
    getParasutTags(state) {
      return state.tags;
    },
    getParasutCategories(state) {
      return state.categories;
    },
  },
  mutations: {
    handleSetParasutCustomers(state, payload) {
      state.customers = payload;
    },
    handleSetParasutProducts(state, payload) {
      state.products = payload;
    },
    handleSetParasutTags(state, payload) {
      state.tags = payload;
    },
    handleSetParasutCategories(state, payload) {
      state.categories = payload;
    },
  },
  actions: {
    setParasutCustomers({ commit }, payload) {
      commit("handleSetParasutCustomers", payload);
    },
    setParasutProducts({ commit }, payload) {
      commit("handleSetParasutProducts", payload);
    },
    setParasutTags({ commit }, payload) {
      commit("handleSetParasutTags", payload);
    },
    setParasutCategories({ commit }, payload) {
      commit("handleSetParasutCategories", payload);
    },
  },
};
