import CreateStorePage from "@/views/tools/ccc/tiktok/CreateStorePage.vue";
import UpdateStorePage from "@/views/tools/ccc/tiktok/UpdateStorePage.vue";
import CreateCampaignView from "@/views/tools/ccc/tiktok/CreateCampaignView.vue";

export const tiktok_router = [
  {
    path: "/tiktok/create-store-page",
    name: "tk-create-store-page-view",
    component: CreateStorePage,
    meta: {
      title: "TikTok - Create Store Page",
    },
  },
  {
    path: "/tiktok/update-store-page",
    name: "tk-update-store-page-view",
    component: UpdateStorePage,
    meta: {
      title: "TikTok - Update Store Page",
    },
  },
  {
    path: "/tiktok/create-campaign",
    name: "tk-create-campaign-view",
    component: CreateCampaignView,
    meta: {
      title: "TikTok - Create New Campaign",
    },
  },
];
