<template>
  <div>
    <v-dialog persistent v-model="visible" width="600">
      <v-card v-if="data">
        <v-card-title class="text-h5"> Result </v-card-title>

        <v-card-text>
          <div v-if="loading">
            <div class="flex items-center justify-center flex-col pt-6">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <span class="mt-4 font-semibold"
                >The store page is being created. Please wait...</span
              >
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            text
            @click="visible = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      result: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;

        if (newValue) {
          this.createStorePage();
        }
      },
      immediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    createStorePage() {
      if (
        this.data.screenshots &&
        this.data.screenshots.length >= 4 &&
        this.data.screenshots.length <= 5 &&
        this.data.icon
      ) {
        this.loading = true;
        let formData = new FormData();
        for (let i = 0; i < this.data.screenshots.length; i++) {
          formData.append("screenshots", this.data.screenshots[i]);
        }
        formData.append("gaia_game_id", this.data.gaia_game.id);
        formData.append("ios_subtitle", this.data.ios_subtitle);
        formData.append("long_desc", this.data.long_desc);
        formData.append("icon", this.data.icon);
        formData.append("platforms", JSON.stringify(["ios"]));

        this.$api
          .post(`ccc/tiktok/store-page/create`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.loading = false;
              this.visible = false;
              this.$notify({
                text: "Store page has been successfully created.",
                group: "success_",
                type: "success_",
              });
              this.$router.push({ name: "home" });
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/sass/variables.scss";
.result-container {
  font-family: $log-font-family;
}
</style>
