import IapPricingView from "@/views/tools/iap-pricing-ui/IapPricingView.vue";

export const iap_pricing_ui_router = [
  {
    path: "/adjust-iap-prices",
    name: "adjust-iap-pricing-ui",
    component: IapPricingView,
    meta: {
      title: "Adjust IAP Prices",
    },
  },
];
