import CpaLogModifier from "@/views/tools/cpa-logs/CpaLogModifier.vue";
import ConversionEventCreator from "@/views/tools/utility/ConversionEventView.vue";
import { departments } from "@/helper/departments";
import { tools } from "@/helper/tools";

export const cpa_log_router = [
  {
    path: "/cpa-logs",
    name: "cpa-log-modifier-view",
    component: CpaLogModifier,
    meta: {
      title: "CPA Logs",
      departmentsAllowed: [departments.ADMIN, departments.UA_TEAM],
      tool: tools.CPA_SHEET,
    },
  },
  {
    path: "/conversion-events/create",
    name: "conversion-event-creator",
    component: ConversionEventCreator,
    meta: {
      title: "Create Conversion Event",
      departmentsAllowed: [departments.ADMIN, departments.DATA_TOOL],
      tool: tools.CPA_SHEET,
    },
  },
];
