<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between mb-6">
        <h1 class="font-semibold text-[18px] w-full">Drive Settings</h1>
        <div class="flex items-center">
          <v-btn
            @click="oauthSignIn"
            :disabled="computedUserCredential"
            color="primary"
            depressed
            ><v-icon left dark> mdi-google-drive </v-icon>Connect Drive</v-btn
          >
        </div>
      </div>
      <div class="mt-5" v-if="computedUserCredential">
        <div class="flex items-center justify-end mb-2">
          <v-btn color="secondary" text @click="addNewSetting"
            >New Setting</v-btn
          >
        </div>
        <v-select
          hide-details
          class="text-[13px]"
          :items="game_data"
          item-text="title"
          return-object
          label="Game"
          dense
          v-model="selected_game"
          outlined
          :loading="gamesLoading"
        ></v-select>

        <div v-if="selected_game" class="mt-3">
          <div
            v-for="(setting, index) in settings"
            :key="index"
            class="flex items-center w-full gap-2 mt-2"
          >
            <div class="w-full">
              <v-text-field
                label="Title"
                outlined
                v-model="setting.title"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="w-full">
              <v-text-field
                label="Value (Folder ID)"
                outlined
                v-model="setting.value"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <span
              class="font-semibold text-[10px] text-red-600 w-[100px] text-center hover:underline cursor-pointer"
              @click="deleteSetting(index)"
            >
              Delete
            </span>
          </div>

          <div class="flex items-center justify-end mt-5">
            <v-btn
              :disabled="isSaveBtnDisabled || loading"
              color="success"
              @click="updateChanges"
              :loading="loading"
              >Save Changes</v-btn
            >
          </div>
        </div>
      </div>

      <div v-else>
        <v-alert text type="warning" prominent border="left">
          <div class="text-[12px] p-2">
            You need to connect your drive account first.
          </div>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      game_data: [],
      selected_game: null,
      gamesLoading: false,
      settings: [],
      loading: false,
      credentials: [],
    };
  },
  methods: {
    async fetchCredentials() {
      try {
        const response = await this.$api.get("utils/drive-credentials");
        this.credentials = response.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    oauthSignIn() {
      const gaia_sid = Cookies.get("gaia_sid");
      if (!gaia_sid) {
        window.reload();
      }

      // Google's OAuth 2.0 endpoint for requesting an access token
      var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      var form = document.createElement("form");
      form.setAttribute("method", "GET"); // Send as a GET request.
      form.setAttribute("action", oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
      var params = {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_OAUTH_DRIVE_REDIRECT_URL,
        response_type: "code",
        scope: "https://www.googleapis.com/auth/drive",
        include_granted_scopes: true,
        state: Cookies.get("gaia_sid"),
        access_type: "offline",
        prompt: "consent select_account",
      };

      // Add form parameters as hidden input values.
      for (var p in params) {
        var input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", p);
        input.setAttribute("value", params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    },
    addNewSetting() {
      const temp = {
        title: "",
        value: "",
      };

      this.settings.push(temp);
    },
    deleteSetting(index) {
      this.settings.splice(index, 1);
    },
    async fetchGames() {
      try {
        this.gamesLoading = true;
        const response = await this.$api.get(
          "games/all?only_actives=True&platform=android"
        );
        this.game_data = response.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.gamesLoading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async updateChanges() {
      try {
        const requestData = {
          game_id: this.selected_game.id,
          drive_settings: this.settings,
        };

        this.loading = true;
        const response = await this.$api.post(
          "games/update/drive-settings",
          requestData
        );
        if (response.status === 200) {
          this.$toast.success("Settings have been successfuly updated.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["getUserProfile", "getExtraData"]),
    computedUserCredential() {
      if (!this.getUserProfile) return null;
      else {
        const user_id = this.getUserProfile.id;
        return this.credentials.some((x) => x.user_id === user_id);
      }
    },
    isSaveBtnDisabled() {
      for (let i = 0; i < this.settings.length; i++) {
        const setting = this.settings[i];
        if (!setting.title || !setting.value) return true;
      }

      return false;
    },
  },
  mounted() {
    this.fetchGames();
    this.fetchCredentials();
  },
  watch: {
    selected_game(newValue) {
      if (newValue && newValue.external_drive_settings) {
        this.settings = JSON.parse(newValue.external_drive_settings);
      } else {
        this.settings = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
