<template>
  <div>
    <div class="bg-white p-2">
      <div>
        <v-text-field
          label="Search "
          outlined
          v-model="keyword"
          dense
          hide-details
          class="text-[13px] w-[310px]"
          clearable
        ></v-text-field>
      </div>
      <div class="my-2"><v-divider></v-divider></div>
      <div
        class="p-1 max-h-[320px] overflow-scroll min-h-[200px] max-w-[310px]"
        v-if="!loading"
      >
        <div v-for="(item, index) in choices.data" :key="index">
          <div
            class="hover:bg-sky-100 text-[12px] rounded-[3px] min-h-[32px] flex items-center justify-start px-2 capitalize cursor-pointer"
            :class="{
              'font-semibold bg-sky-100':
                params.values.type === 'campaign'
                  ? item.campaign_name === value
                  : item.adgroup_name === value,
            }"
            @click="
              setValue(
                params.values.type === 'campaign'
                  ? item.campaign_name
                  : item.adgroup_name
              )
            "
          >
            <span>
              {{
                params.values.type === "campaign"
                  ? item.campaign_name
                  : item.adgroup_name
              }}
            </span>
          </div>
        </div>

        <div
          v-if="!choices.data.length"
          class="p-2 text-[11px] text-gray-400 text-center"
        >
          There is no data.
        </div>
      </div>

      <div
        class="p-1 max-h-[320px] overflow-scroll min-h-[200px] max-w-[310px] mt-5"
        v-else
      >
        <div class="flex items-center justify-center">
          <div>
            <v-progress-circular
              indeterminate
              color="primary"
              :size="20"
              :width="2"
            ></v-progress-circular>
          </div>
          <span class="pl-2 text-[11px] font-semibold">Please wait...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      keyword: "",
      tempChoices: [],
      choices: [],
      mapped: null,
      value: null,
      loading: false,
    };
  },
  methods: {
    async fetchGameCampaignData(game) {
      try {
        this.loading = true;
        const request = await this.$api.get(
          `cpa-sheet/campaigns?game=${game}&keyword=${
            this.keyword ? this.keyword : ""
          }`
        );

        if (request.status === 200) {
          this.choices.data = request.data;
        }
      } catch (error) {
        this.errorHandler(error);
        this.choices = { ...this.tempChoices };
      } finally {
        this.loading = false;
      }
    },

    async fetchGameAdgroupData(game) {
      try {
        this.loading = true;
        const request = await this.$api.get(
          `cpa-sheet/adgroups?game=${game}&keyword=${
            this.keyword ? this.keyword : ""
          }`
        );

        if (request.status === 200) {
          this.choices.data = request.data;
        }
      } catch (error) {
        this.errorHandler(error);
        this.choices = { ...this.tempChoices };
      } finally {
        this.loading = false;
      }
    },

    async fetchData() {
      if (this.params.values.type === "campaign") {
        await this.fetchGameCampaignData(this.params.data.game);
      } else if (this.params.values.type === "adgroup") {
        await this.fetchGameAdgroupData(this.params.data.game);
      }
    },

    getValue() {
      return this.value;
    },

    isPopup() {
      return true;
    },

    filterChoices: _.debounce(async function () {
      {
        await this.fetchData();
      }
    }, 500),

    setValue(item) {
      this.value = item;
      this.params.stopEditing();
    },
  },
  watch: {
    keyword(newValue) {
      this.filterChoices(newValue);
    },
  },
  created() {
    this.keyword = this.params.value;
    this.value = this.params.value;
    this.choices = JSON.parse(JSON.stringify(this.params.values));
    this.tempChoices = JSON.parse(JSON.stringify(this.choices));
  },
};
</script>

<style lang="scss" scoped></style>
