<template>
  <div>
    <div class="border rounded" v-if="!unityReportsLoading">
      <v-expansion-panels
        accordion
        focusable
        flat
        v-model="selectedAdsetPanel"
        v-if="unityReportsData"
      >
        <v-expansion-panel v-for="(data, index) in modifiedData" :key="index">
          <v-expansion-panel-header>
            <div class="flex items-center">
              <span class="font-semibold text-[11px] text-blue-900">
                {{ data.campaign_name }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="rounded bg-sky-50 text-sky-800 font-semibold p-5 text-[11px] mt-5 border border-sky-200"
            >
              {{
                data.selectedCreativesForAdding.filter((x) => x.selected).length
              }}
              creative(s) will be added and
              {{ data.selectedCreativesForDeleting.length }} creative(s) will be
              deleted.
            </div>
            <h1
              class="mt-4 mb-0 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
            >
              <span> 1. Select creatives to add </span>
            </h1>
            <div class="pt-3">
              <div class="w-full mb-5">
                <div class="grid grid-cols-4 gap-3">
                  <div
                    v-for="creative in data.selectedCreativesForAdding"
                    :key="creative.ad_id"
                    class="rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100"
                    :class="{
                      selected: creative.selected,
                    }"
                    @click="creative.selected = !creative.selected"
                  >
                    <div class="flex items-center justify-between mb-2">
                      <div class="flex items-center">
                        <span
                          class="text-[11px]"
                          :class="
                            creative.selected
                              ? 'text-gray-700 font-semibold'
                              : 'text-gray-400'
                          "
                        >
                          {{ creative.ad_name }}
                        </span>
                        <span
                          v-if="creative.improvement"
                          class="text-[10px] ml-2"
                          :class="{
                            'font-semibold': creative.selected,
                            'text-gray-400': !creative.selected,
                            'text-lime-700':
                              creative.improvement >= 0 && creative.selected,
                            'text-rose-700':
                              creative.improvement < 0 && creative.selected,
                          }"
                        >
                          {{ creative.improvement.toFixed(2) }}%
                        </span>
                      </div>
                      <span
                        class="text-[9px] uppercase font-semibold p-1 rounded-[3px] hover:bg-gray-300 cursor-pointer select-none"
                        @click="
                          handlePreviewBtn(
                            $event,
                            creative.gaia_id,
                            creative.selected_playable_id
                          )
                        "
                        :class="
                          creative.selected
                            ? 'text-gray-700'
                            : 'text-gray-400 pointer-events-none'
                        "
                      >
                        Preview
                      </span>
                    </div>

                    <div class="mt-2">
                      <v-checkbox
                        dense
                        v-model="creative.is_playable"
                        hide-details
                        label="Add playable asset"
                        class="text-[10px]"
                        @click.stop
                        :disabled="!creative.selected"
                      >
                      </v-checkbox>
                    </div>

                    <div class="mt-2" @click.stop>
                      <v-autocomplete
                        clearable
                        label="Choose an asset"
                        outlined
                        auto-select-first
                        dense
                        class="text-[12px]"
                        v-model="creative.selected_playable_id"
                        :items="unityAssetData"
                        hide-details
                        :disabled="!creative.is_playable"
                        item-text="title"
                        item-value="id"
                      >
                      </v-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider class="my-5"></v-divider>
              <h1
                class="mb-2 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
              >
                <span>2. Select Creatives to Replace </span>
              </h1>

              <h1 class="text-gray-800 font-semibold text-[11px] mb-3">
                Average CVR: {{ computedAverageCvr(data) }}
              </h1>

              <div>
                <v-data-table
                  :headers="unityCreativePackHeaders"
                  :items="sortedReportData(data)"
                  :items-per-page="-1"
                  v-model="data.selectedCreativesForDeleting"
                  :single-select="false"
                  show-select
                  item-key="creative_pack_id"
                >
                  <template v-slot:[`item.creative_pack_name`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{ item.creative_pack_name }}
                    </span>
                  </template>
                  <template v-slot:[`item.cvr`]="{ item }">
                    <span
                      class="text-[11px] font-semibold"
                      :class="
                        Math.round(item.cvr * 10000) / 10000 >=
                        computedAverageCvr(data)
                          ? 'text-lime-700'
                          : Math.round(item.cvr * 10000) / 10000
                          ? 'text-rose-600'
                          : '-'
                      "
                    >
                      {{ Math.round(item.cvr * 10000) / 10000 }}
                    </span>
                  </template>
                  <template v-slot:[`item.views`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{ item.views.toLocaleString("de-DE") }}
                    </span>
                  </template>
                  <template v-slot:[`item.clicks`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{ item.clicks.toLocaleString("de-DE") }}
                    </span>
                  </template>
                  <template v-slot:[`item.installs`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{ item.installs.toLocaleString("de-DE") }}
                    </span>
                  </template>
                  <template v-slot:[`item.spend`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      $ {{ item.spend.toFixed(2) }}
                    </span>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div v-else-if="unityReportsLoading">
      <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
    </div>

    <!-- <div class="mt-3">
        <h1 class="text-gray-700 font-bold">Summary</h1>
        <div>
          <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
            Selected creatives will be
            <span class="text-lime-600">added</span> into these adsets:
          </h1>
          <div class="mt-2">
            <div
              v-for="(data, idx) in computedImportedDataForSummary"
              :key="idx"
              class="mb-1.5"
            >
              <div class="font-semibold text-[11px] flex items-center">
                <span class="text-sky-600">
                  {{ data.creative_name }}
                </span>
                <span class="ml-2 mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-narrow-right"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    stroke-width="1.75"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l14 0"></path>
                    <path d="M15 16l4 -4"></path>
                    <path d="M15 8l4 4"></path>
                  </svg>
                </span>
                <span
                  v-for="adgroup in data.adgroup_data"
                  :key="adgroup.adgroup_id"
                  class="text-gray-700 p-1 rounded-[3px] bg-gray-100 ml-1 hover:bg-gray-200"
                >
                  {{ adgroup.adgroup_name }}
                </span>
              </div>
            </div>
          </div>
  
          <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
            These creatives will be completely
            <span class="text-rose-600">deleted</span> from these adsets:
          </h1>
  
          <div class="mt-2">
            <div v-for="adgroup in adgroupData" :key="adgroup.id">
              <div v-if="adgroup.selectedAdsForDeleting.length">
                <div
                  class="font-semibold text-[11px] flex items-center mb-1.5"
                  v-for="(ad, adIdx) in adgroup.selectedAdsForDeleting"
                  :key="adIdx"
                >
                  <span class="text-sky-600">
                    {{ ad.name }}
                  </span>
                  <span class="ml-2 mr-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-arrow-narrow-right"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      stroke-width="1.75"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l14 0"></path>
                      <path d="M15 16l4 -4"></path>
                      <path d="M15 8l4 4"></path>
                    </svg>
                  </span>
                  <span
                    class="text-gray-700 p-1 rounded-[3px] bg-gray-100 ml-1 hover:bg-gray-200"
                  >
                    {{ adgroup.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <div class="flex justify-end items-center my-3">
      <v-btn
        color="primary"
        depressed
        class="font-semibold"
        @click="showSummary = true"
        :loading="loadingBtn"
        :disabled="loadingBtn || unityReportsLoading"
      >
        Replace Creatives
      </v-btn>
    </div>

    <PreviewDialog
      :gaia_id="preview_selected_gaia_id"
      :playable_id="preview_selected_playable_id"
      :show="showPreview"
      @closed="handlePreviewDialogClose"
    />

    <SummaryDialog
      :data="modifiedData"
      :selectedCreatives="selectedCreatives"
      :show="showSummary"
      @closed="handleSummaryDialogClose"
      @submitted="replaceCreatives"
    />
  </div>
</template>

<script>
import _ from "lodash";
import PreviewDialog from "./PreviewDialog.vue";
import SummaryDialog from "./SummaryDialog.vue";

export default {
  props: {
    selectedCampaigns: {
      type: Array,
      default: () => [],
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    game_id: {
      type: Number,
      default: null,
    },
    platform: {
      type: String,
      default: null,
    },
  },
  watch: {
    selectedCampaigns: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.length && this.selectedCreatives.length) {
          this.modifiedData = JSON.parse(
            JSON.stringify(this.unityDataModifier(newValue))
          );
        }
      },
    },
  },
  data() {
    return {
      selectedAds: [],
      loading: false,
      fbAdsetData: [],
      adData: [],
      selectedAdsetPanel: null,
      unityCreativePackHeaders: [
        {
          text: "Name",
          value: "creative_pack_name",
        },
        {
          text: "CVR",
          value: "cvr",
          sortable: true,
        },
        {
          text: "Views",
          value: "views",
          sortable: false,
        },
        {
          text: "Clicks",
          value: "clicks",
          sortable: false,
        },
        {
          text: "Installs",
          value: "installs",
          sortable: false,
        },
        {
          text: "Spend",
          value: "spend",
          sortable: false,
        },
      ],
      modifiedData: [],
      unityAssetData: [],
      loadingBtn: false,
      showPreview: false,
      preview_selected_gaia_id: null,
      preview_selected_playable_id: null,
      showSummary: false,
      unityRequestTimeout: null,
      unityReportsData: [],
      unityReportsLoading: false,
    };
  },
  methods: {
    async fetchUnityReports() {
      try {
        const request = await this.$api.get(
          `campaign-manager/report/unity?game_id=${this.game_id}&platform=${this.platform}`
        );

        this.unityReportsData = request.data.map((x) => ({
          cvr: x.cvr ? parseFloat(x.cvr) : 0,
          creative_pack_id: x["creative pack id"]
            ? x["creative pack id"]
            : null,
          creative_pack_name: x["creative pack name"]
            ? x["creative pack name"]
            : null,
          views: x.views ? parseFloat(x.views) : 0,
          clicks: x.clicks ? parseFloat(x.clicks) : 0,
          installs: x.installs ? parseFloat(x.installs) : 0,
          spend: x.spend ? parseFloat(x.spend) : 0,
        }));
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.unityReportsLoading = false;
      }
    },
    handleSummaryDialogClose() {
      this.showSummary = false;
    },
    handlePreviewDialogClose() {
      this.showPreview = false;
      this.preview_selected_gaia_id = null;
      this.preview_selected_playable_id = null;
    },
    handlePreviewBtn($event, gaia_id, playable_id) {
      $event.stopPropagation();
      this.preview_selected_gaia_id = gaia_id;
      this.preview_selected_playable_id = playable_id;
      this.showPreview = true;
    },
    async replaceCreatives() {
      if (this.computedCreateData.length || this.computedDeleteData.length) {
        // this.showSummary = false;
        const requestData = {
          create_data: this.computedCreateData,
          delete_data: this.computedDeleteData,
          game_id: this.game_id,
          platform: this.platform,
        };
        await this.createUnityTask(requestData);
      }
    },
    async createUnityTask(data) {
      try {
        this.loadingBtn = true;
        const request = await this.$api.post(
          `burcha/unity/tasks/create/create-task`,
          data
        );

        if (request.status === 200) {
          this.$toast.success(
            "Tasks successfully created. You will be notified on Slack when tasks run.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          this.$router.push({ name: "home" });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingBtn = false;
      }
    },
    unityDataModifier(data) {
      if (!data.length) return [];
      var modifiedData = [];

      for (let i = 0; i < data.length; i++) {
        var campaign = data[i];
        campaign.selectedCreativesForAdding = JSON.parse(
          JSON.stringify(
            this.selectedCreatives.map((x) => ({
              ...x,
              selected: true,
              is_playable: false,
              selected_playable_id: null,
            }))
          )
        );

        campaign.selectedCreativesForDeleting = [];
        modifiedData.push(campaign);
      }

      return modifiedData;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    sortedReportData(data) {
      const creativePackIds = data.creative_packs.map((x) => x.id);
      if (!creativePackIds.length) return [];

      var filtered = this.unityReportsData.filter((x) =>
        creativePackIds.includes(x.creative_pack_id)
      );

      for (let y = 0; y < data.creative_packs.length; y++) {
        const creative_pack = data.creative_packs[y];
        const idx = filtered.findIndex(
          (x) => x.creative_pack_id === creative_pack.id
        );

        if (idx === -1) {
          filtered.push({
            cvr: 0,
            creative_pack_id: creative_pack.id,
            creative_pack_name: creative_pack.name,
            views: 0,
            clicks: 0,
            installs: 0,
            spend: 0,
          });
        }
      }

      const sortedData = _.orderBy(filtered, "cvr", "desc");
      return sortedData;
    },
    computedAverageCvr(data) {
      if (!data.creative_packs.length) return 0;
      const creativePackIds = data.creative_packs.map((x) => x.id);
      if (!creativePackIds.length) return 0;

      var total = 0;
      var length = 0;
      for (let i = 0; i < this.unityReportsData.length; i++) {
        const reportData = this.unityReportsData[i];
        if (creativePackIds.includes(reportData.creative_pack_id)) {
          total += reportData.cvr;
          length += 1;
        }
      }

      return length ? Math.round((total / length) * 10000) / 10000 : 0;
    },
    computedAverageImpression(adgroupData) {
      if (!adgroupData.ads.length) {
        return null;
      }

      var total = 0;
      for (let y = 0; y < adgroupData.ads.length; y++) {
        const ad = adgroupData.ads[y];
        total += ad.insights.impressions
          ? parseInt(ad.insights.impressions)
          : 0;
      }

      const length = adgroupData.ads.filter((x) =>
        x.insights.impressions ? true : false
      ).length;

      return length ? Math.round((total / length) * 100) / 100 : 0;
    },
    async fetchPlayableAssets() {
      try {
        const request = await this.$api.get(
          `burcha/playables/unity/all?game_id=${this.game_id}`
        );
        if (request) {
          this.unityAssetData = request.data;
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
  },
  async mounted() {
    await this.fetchPlayableAssets();
    this.unityReportsLoading = true;
    this.unityRequestTimeout = setTimeout(await this.fetchUnityReports, 2000);
  },
  beforeDestroy() {
    clearInterval(this.unityRequestTimeout);
  },
  components: {
    PreviewDialog,
    SummaryDialog,
  },
  computed: {
    computedCreateData() {
      var data = [];

      this.modifiedData.forEach((campaign) => {
        campaign.selectedCreativesForAdding.forEach((x) => {
          if (x.selected) {
            data.push({
              campaign_id: campaign.campaign_id,
              gaia_id: x.gaia_id,
              unity_playable_id: x.selected_playable_id,
              campaign_name: campaign.campaign_name,
            });
          }
        });
      });

      return data;
    },
    computedDeleteData() {
      var data = [];

      this.modifiedData.forEach((campaign) => {
        campaign.selectedCreativesForDeleting.forEach((x) => {
          data.push({
            campaign_id: campaign.campaign_id,
            creative_pack_id: x.creative_pack_id,
            campaign_name: campaign.campaign_name,
            creative_pack_name: x.creative_pack_name,
          });
        });
      });

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-footer {
  border-top: none !important;
}
::v-deep table {
  border: 1px solid rgba($color: #000000, $alpha: 0.15);
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.selectedAdSection {
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 2px 4px;
}

.selectedAdSection.selected {
  box-shadow: rgba(63, 216, 16, 0.4) 0px 0px 0px 1px,
    rgba(22, 93, 0, 0.1) 0px 2px 4px;
}

::v-deep .v-label {
  font-size: 10px !important;
  font-weight: 600;
}
</style>
