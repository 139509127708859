<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">
        Icon & Screenshoot Tester - Results
      </h1>
      <div>
        <v-autocomplete
          clearable
          dense
          outlined
          hide-details
          hide-selected
          auto-select-first
          item-text="title"
          return-object
          :loading="tests_loading"
          :disabled="tests_loading"
          v-model="selected_test"
          :items="tests"
          label="Choose a test"
          class="text-[13px]"
        >
        </v-autocomplete>
      </div>
      <div
        class="mt-8"
        v-if="loading_click_info || loading_view_info || tiktokInfoLoading"
      >
        <div>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
        <div>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
        <div>
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </div>
      </div>
      <div
        class="pt-8"
        v-if="
          selected_test &&
          !loading_click_info &&
          !loading_view_info &&
          !tiktokInfoLoading
        "
      >
        <v-tabs v-model="tab">
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1"> Variant Information </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <div class="flex items-center justify-end" v-if="test_detail_deep">
              <v-btn
                color="red darken-1"
                text
                @click="pauseAdgroup"
                :disabled="test_detail_deep.status_id !== 1"
                :loading="button_loading"
              >
                <v-icon left dark> mdi-stop </v-icon>
                Stop & Remove from TikTok
              </v-btn>
            </div>

            <div class="mt-5 flex items-center justify-end">
              <div class="mr-2">
                <v-menu
                  ref="show_invoice_date"
                  v-model="show_variant_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :value="selected_dates"
                      label="Choose Dates"
                      class="text-[13px]"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected_dates"
                    color="primary"
                    range
                    @change="handleDateChange"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div class="mr-2">
                <v-select
                  hide-details
                  class="text-[12px] max-w-[220px]"
                  :items="chart_includes"
                  label="Include in Chart"
                  v-model="selected_includes"
                  item-text="title"
                  item-value="value"
                  multiple
                  dense
                  outlined
                ></v-select>
              </div>

              <v-select
                hide-details
                class="text-[12px] max-w-[130px]"
                :items="chart_types"
                label="Chart Type"
                v-model="chart_type"
                item-text="title"
                item-value="value"
                dense
                outlined
              ></v-select>
            </div>
            <div class="mt-5">
              <apex-chart
                :type="chart_type"
                height="350"
                :options="computedOptions"
                :series="computedSeries"
              ></apex-chart>
            </div>
            <div class="mt-5">
              <v-data-table
                :headers="headers"
                :loading="table_loading"
                :items="test_detail"
                :items-per-page="10"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :expanded.sync="expanded"
                show-expand
                single-expand
              >
                <template v-slot:[`item.title`]="{ item }">
                  <span class="font-semibold text-[12px]">
                    Variant - {{ item.variant_group.toUpperCase() }}
                  </span>
                </template>

                <template v-slot:[`item.boundaries`]="{ item }">
                  <span
                    class="font-semibold text-[12px]"
                    v-if="item.variant_group.toLowerCase() !== 'a'"
                  >
                    <span
                      v-if="
                        boundaries.find(
                          (x) =>
                            x.variant_name ===
                            `${
                              selected_test.modified_title
                            }_${item.variant_group.toUpperCase()}`
                        )
                      "
                    >
                      {{
                        boundaries.find(
                          (x) =>
                            x.variant_name ===
                            `${
                              selected_test.modified_title
                            }_${item.variant_group.toUpperCase()}`
                        ).lower_bound
                      }}% -
                      {{
                        boundaries.find(
                          (x) =>
                            x.variant_name ===
                            `${
                              selected_test.modified_title
                            }_${item.variant_group.toUpperCase()}`
                        ).upper_bound
                      }}%
                    </span>
                  </span>
                  <span class="font-semibold text-[12px]" v-else> - </span>
                </template>

                <template v-slot:[`item.icon`]="{ item }">
                  <img :src="item.icon_signed_url" class="rounded h-[32px]" />
                </template>

                <template v-slot:[`item.screenshots`]="{ item }">
                  <div class="flex items-center justify-center gap-2 p-2.5">
                    <img
                      v-for="(screenshot, index) in item.screenshots_signed_url"
                      :key="index"
                      :src="screenshot"
                      class="rounded w-[32px]"
                    />
                  </div>
                </template>

                <template v-slot:[`item.created_at`]="{ item }">
                  <span class="text-[12px]">
                    {{ $formatUnix(item.created_at) }}
                  </span>
                </template>

                <template v-slot:[`item.cvr`]="{ item }">
                  <span class="text-[12px]">
                    {{ item.cvr }}
                  </span>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="p-4">
                      <div class="grid grid-cols-6">
                        <div class="font-semibold text-gray-700 text-[12px]">
                          Date
                        </div>
                        <div class="font-semibold text-gray-700 text-[12px]">
                          Page View
                        </div>
                        <div class="font-semibold text-gray-700 text-[12px]">
                          Click Count
                        </div>
                        <div class="font-semibold text-gray-700 text-[12px]">
                          Cvr
                        </div>
                        <div class="font-semibold text-gray-700 text-[12px]">
                          Impression
                        </div>
                        <div class="font-semibold text-gray-700 text-[12px]">
                          Spend
                        </div>
                      </div>
                      <div class="mt-3">
                        <div
                          class="grid grid-cols-6 mt-1 text-[12px]"
                          v-for="(info, index) in item.information"
                          :key="index"
                        >
                          <div>
                            {{ info.server_date }}
                          </div>
                          <div>
                            {{ info.view_count ? info.view_count : 0 }}
                          </div>
                          <div>
                            {{ info.click_count ? info.click_count : 0 }}
                          </div>
                          <div>
                            {{
                              info.view_count
                                ? (info.click_count / info.view_count).toFixed(
                                    3
                                  )
                                : "-"
                            }}
                          </div>
                          <div v-if="info.tiktokData">
                            {{
                              (
                                info.tiktokData.impressions / test_detail.length
                              ).toFixed(2)
                            }}
                          </div>
                          <div v-if="info.tiktokData">
                            {{
                              (
                                info.tiktokData.spend / test_detail.length
                              ).toFixed(2)
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ResultsView",
  data() {
    return {
      selected_test: null,
      boundaries_loading: false,
      boundaries: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Title", value: "title" },
        { text: "Icon", value: "icon" },
        { text: "Screenshots", value: "screenshots", align: "center" },
        { text: "Boundaries", value: "boundaries", align: "center" },
        { text: "Created At", value: "created_at", align: "center" },
        {
          text: "CVR",
          value: "cvr",
          align: "center",
        },
        { text: "", value: "data-table-expand" },
      ],
      tab: 0,
      tests: [],
      tests_loading: false,
      loading_click_info: false,
      loading_view_info: false,
      click_info: [],
      view_info: [],
      test_detail: [],
      table_loading: false,
      sortBy: "cvr",
      sortDesc: true,
      test_detail_deep: null,
      button_loading: false,
      chart_type: "bar",
      chart_types: [
        {
          title: "Bar",
          value: "bar",
        },
        {
          title: "Line",
          value: "line",
        },
        {
          title: "Area",
          value: "area",
        },
      ],
      chart_includes: [
        {
          title: "Page View",
          value: "page-view",
        },
        {
          title: "Click Count",
          value: "click-count",
        },
        {
          title: "CVR",
          value: "cvr",
        },
        {
          title: "Impressions",
          value: "impressions",
        },
        {
          title: "Spend",
          value: "spend",
        },
      ],
      selected_includes: ["page-view", "click-count"],
      selected_variant_dates: [],
      selected_dates: null,
      show_variant_date: false,
      temp_click_info: [],
      tiktokInsightInfo: [],
      tiktokInfoLoading: false,
      expanded: [],
    };
  },
  methods: {
    getDatesBetween(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      var temp_stopDate = moment(stopDate);
      while (currentDate <= temp_stopDate) {
        dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }
      return dateArray;
    },
    handleDateChange(date) {
      if (date) {
        if (date.length) {
          const sortedDates = date.sort();
          const dates = this.getDatesBetween(
            sortedDates[0],
            date.length === 1 ? sortedDates[0] : sortedDates[1]
          );

          this.click_info = this.temp_click_info;
          this.click_info = this.click_info.filter((x) =>
            dates.includes(x.server_date)
          );
        }
      }
    },
    computedCvr(variant) {
      const variant_group = variant.toUpperCase();

      if (this.click_info.length) {
        let total_click = 0;
        let total_view = 0;

        this.click_info.forEach((info) => {
          if (info.variant_name.split("_").pop() === variant_group) {
            console.log(info);
            total_click += info.click_count;
            total_view += info.view_count;
          }
        });

        if (total_view) {
          return (total_click / total_view).toFixed(4);
        }
        return 0;
      }
      return 0;
    },
    computedInformation(variant) {
      const variant_group = variant.toUpperCase();

      if (this.click_info && this.click_info.length) {
        let filteredData = this.click_info.filter(
          (x) => x.variant_name.split("_").pop() === variant_group
        );
        if (this.tiktokInsightInfo && this.tiktokInsightInfo.length) {
          filteredData = filteredData.map((x) => {
            return {
              ...x,
              tiktokData: this.tiktokInsightInfo.filter((y) =>
                y.dimensions.stat_time_day.includes(x.server_date)
              ).length
                ? this.tiktokInsightInfo.filter((y) =>
                    y.dimensions.stat_time_day.includes(x.server_date)
                  )[0].metrics
                : [],
            };
          });
        }
        return filteredData;
      }

      return [];
    },
    fetchTests() {
      this.tests_loading = true;
      this.$api
        .get(`icon-tester/tests/all`)
        .then((response) => {
          this.tests = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tests_loading = false;
        });
    },
    fetchVariantClickInfo() {
      if (this.selected_test) {
        this.loading_click_info = true;
        this.$api
          .get(`icon-tester/tests/info/${this.selected_test.id}`)
          .then(async (response) => {
            this.click_info = response.data;
            this.temp_click_info = response.data;

            await this.fetchTikTokInfo();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading_click_info = false;
          });
      }
    },
    fetchVariantViewInfo() {
      if (this.selected_test) {
        this.loading_view_info = true;
        this.$api
          .get(`icon-tester/tests/view-info/${this.selected_test.id}`)
          .then(async (response) => {
            this.view_info = response.data;
            await this.fetchTestDetail();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading_view_info = false;
          });
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchBoundaries() {
      try {
        this.boundaries_loading = true;
        const request = await this.$api.get(
          `icon-tester/tests/boundaries?test_id=${this.selected_test.id}`
        );
        this.boundaries = request.data;
      } catch (error) {
        this.erorrHandler(error);
      } finally {
        this.boundaries_loading = false;
      }
    },
    fetchVariantResultInfo() {
      if (this.selected_test) {
        this.loading_click_info = true;
        this.$api
          .get(`icon-tester/results/info/${this.selected_test.id}`)
          .then(async (response) => {
            this.click_info = JSON.parse(response.data.click_info);
            this.temp_click_info = this.click_info.slice();
            this.tiktokInsightInfo = JSON.parse(
              response.data.tiktok_insight_info
            );

            await this.fetchTestDetail();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading_click_info = false;
          });
      }
    },
    fetchTestDetail() {
      if (this.selected_test) {
        this.table_loading = true;
        this.$api
          .get(`icon-tester/tests/detail/${this.selected_test.id}`)
          .then((response) => {
            this.test_detail_deep = response.data;
            this.test_detail = response.data.variants;
            this.test_detail = this.test_detail.map((x) => {
              return {
                ...x,
                cvr: this.computedCvr(x.variant_group),
                information: this.computedInformation(x.variant_group),
              };
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.table_loading = false;
          });
      }
    },
    pauseAdgroup() {
      if (!this.click_info || !this.tiktokInsightInfo) return;
      this.button_loading = true;
      const requestData = {
        test_id: this.selected_test.id,
        click_info: JSON.stringify(this.click_info),
        tiktokInsightInfo: JSON.stringify(this.tiktokInsightInfo),
      };
      this.$api
        .post(`icon-tester/tests/stop`, requestData)
        .then((response) => {
          if (response && response.status === 200) {
            this.fetchTestDetail();
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Adgroup successfully paused & deleted from TikTok.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.button_loading = false;
        });
    },
    fetchTikTokInfo() {
      if (this.selected_test) {
        this.tiktokInfoLoading = true;
        this.$api
          .get(`icon-tester/tests/tiktok/${this.selected_test.id}`)
          .then(async (response) => {
            if (response && response.status === 200) {
              this.tiktokInsightInfo = response.data;

              await this.fetchTestDetail();
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.tiktokInfoLoading = false;
          });
      }
    },
  },
  mounted() {
    this.fetchTests();
  },
  computed: {
    computedVariantNames() {
      if (this.click_info.length) {
        return Array.from(new Set(this.click_info.map((x) => x.variant_name)));
      }
      return [];
    },
    computedVariantDates() {
      if (this.click_info.length) {
        return Array.from(new Set(this.click_info.map((x) => x.server_date)));
      }
      return [];
    },
    computedSeries() {
      if (!this.click_info.length) {
        return [];
      }

      let view_info_series = [];
      let click_info_series = [];
      let cvr_info_series = [];
      let impression_series = [];

      this.computedVariantNames.forEach((variant) => {
        const variantData = this.click_info.filter(
          (x) => x.variant_name === variant
        );

        let viewCountData = [];
        let clickCountData = [];
        let cvrData = [];

        variantData.forEach((data) => {
          if (data.view_count) {
            viewCountData.push({
              x: data.server_date,
              y: data.view_count,
            });
          }
          if (!data.view_count) {
            viewCountData.push({
              x: data.server_date,
              y: 0,
            });
          }

          if (data.click_count) {
            clickCountData.push({
              x: data.server_date,
              y: data.click_count,
            });
          }
          if (!data.click_count) {
            clickCountData.push({
              x: data.server_date,
              y: 0,
            });
          }
        });

        for (let i = 0; i < viewCountData.length; i++) {
          const view_count = viewCountData[i];
          const click_count = clickCountData[i];

          let cvr = 0;
          if (view_count && view_count.y) {
            cvr = (click_count.y / view_count.y).toFixed(2);
          }

          cvrData.push({
            x: view_count.x,
            y: cvr,
          });
        }

        if (this.selected_includes.includes("page-view")) {
          view_info_series.push({
            name: `Page View: Variant ${variant.split("_").pop()}`,
            data: viewCountData,
          });
        }

        if (this.selected_includes.includes("click-count")) {
          click_info_series.push({
            name: `Click: Variant ${variant.split("_").pop()}`,
            data: clickCountData,
          });
        }

        if (this.selected_includes.includes("cvr")) {
          cvr_info_series.push({
            name: `CVR: Variant ${variant.split("_").pop()}`,
            data: cvrData,
          });
        }
      });

      if (this.tiktokInsightInfo.length) {
        let impressionData = [];
        let costData = [];

        this.computedVariantDates.forEach((date) => {
          this.tiktokInsightInfo.forEach((insight) => {
            if (insight.dimensions.stat_time_day.includes(date)) {
              impressionData.push({
                x: date,
                y: parseInt(insight.metrics.impressions),
              });
              costData.push({
                x: date,
                y: parseFloat(insight.metrics.spend),
              });
            }
          });
        });

        if (this.selected_includes.includes("impressions")) {
          impression_series.push({
            name: `Impressions`,
            data: impressionData,
          });
        }

        if (this.selected_includes.includes("spend")) {
          impression_series.push({
            name: `Spend`,
            data: costData,
          });
        }
      }

      return [
        ...view_info_series,
        ...click_info_series,
        ...cvr_info_series,
        ...impression_series,
      ];
    },
    computedOptions() {
      return {
        chart: {
          height: 350,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
      };
    },
  },
  watch: {
    selected_test(newValue) {
      if (newValue) {
        switch (newValue.status_id) {
          case 1:
            this.fetchBoundaries();
            this.fetchVariantClickInfo();
            break;
          case 2:
            this.fetchBoundaries();
            this.fetchVariantResultInfo();
            break;

          default:
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
