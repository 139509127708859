<template>
  <div>
    <h1 class="font-semibold text-[12px] mt-3 text-gray-700">Campaigns</h1>
    <!-- $route.params.game_id -->
    <div class="flex items-center mt-5">
      <div class="w-full">
        <v-text-field
          placeholder="Search by title..."
          outlined
          dense
          class="text-[12px]"
          append-icon="mdi-magnify"
          v-model="campaignTitle"
          hide-details
        ></v-text-field>
      </div>
      <div class="pl-3">
        <v-combobox
          label="Pick countries"
          outlined
          dense
          class="text-[12px] w-[280px]"
          v-model="targetCountries"
          clearable
          :items="['data', 'test']"
          multiple
          hide-details
        ></v-combobox>
      </div>
      <div class="pl-3">
        <v-select
          label="Select a game"
          outlined
          dense
          class="text-[12px] w-[280px]"
          v-model="selectedGame"
          :items="games"
          item-text="title"
          item-value="id"
          hide-details
        ></v-select>
      </div>
      <div class="pl-3">
        <v-select
          label="Select a network"
          outlined
          dense
          class="text-[12px] w-[280px]"
          v-model="selectedNetwork"
          :items="networks"
          item-text="title"
          item-value="value"
          hide-details
        ></v-select>
      </div>
    </div>

    <div class="flex items-center justify-end mt-3">
      <v-btn
        color="primary"
        @click="fetchCampaigns"
        small
        depressed
        text
        class="font-semibold"
        :loading="refreshBtnLoading"
      >
        <v-icon left dark> mdi-refresh </v-icon>
        Refresh
      </v-btn>
      <span class="font-semibold text-rose-400 text-[11px] ml-2">
        Last Update:
        {{ latestUpdateTime ? latestUpdateTime : "-" }}
      </span>
    </div>
    <div class="mt-5">
      <v-data-table
        :headers="computedHeaders"
        :items="computedData"
        :items-per-page="15"
        v-model="selectedCampaigns"
        :single-select="false"
        :loading="loading"
        :disabled="loading"
        show-select
        item-key="campaign_id"
      >
        <template v-slot:[`item.target_roas`]="{ item }">
          <span class="text-gray-800 text-[12px] font-semibold">
            {{
              item.target_roas
                ? `${(item.target_roas * 100).toFixed(1)} %`
                : "-"
            }}
          </span>
        </template>

        <template v-slot:[`item.target_cpa`]="{ item }">
          <span class="text-gray-800 text-[12px] font-semibold">
            {{
              item.target_cpa
                ? (item.target_cpa / 1000000).toFixed(2) + " $"
                : "-"
            }}
          </span>
        </template>

        <template v-slot:[`item.campaign_name`]="{ item }">
          <span class="text-gray-800 text-[12px] font-semibold">
            {{ item.campaign_name }}
          </span>
        </template>

        <template v-slot:[`item.campaign_budget`]="{ item }">
          <span class="text-[10px] font-semibold" v-if="item.campaign_budget">
            {{ item.campaign_budget.toLocaleString("de-DE") }} $
          </span>
          <span class="text-[10px] font-semibold" v-else> - </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <span
            class="text-[10px] px-2 py-1 font-semibold rounded-full"
            :class="
              ['active', 'enabled'].includes(item.status.toLowerCase())
                ? 'bg-lime-100 text-lime-600'
                : 'bg-rose-100 text-rose-600'
            "
          >
            {{ item.status }}
          </span>
        </template>

        <template v-slot:[`item.geo_locations`]="{ item }">
          <div class="w-full" v-if="item.geo_locations.length">
            <v-chip
              v-for="(location, idx) in item.geo_locations"
              color="primary"
              class="mr-1"
              x-small
              :key="idx"
            >
              {{ location }}
            </v-chip>
          </div>
          <div class="w-full" v-else>-</div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="flex items-center">
            <span
              class="font-semibold text-[10px] text-gray-400 hover:underline cursor-pointer hover:text-gray-600"
              @click="showCampaignSettings(item)"
              >Settings</span
            >
          </div>
        </template>
      </v-data-table>
    </div>

    <div v-if="getBurchaSelectedNetwork === 'google'">
      <GoogleCampaignSettings
        :show="showSettings"
        :data="settingsData"
        @closed="handleOnSettingsClosed"
        :game_id="selectedGame"
      />
    </div>

    <div
      v-if="
        ['applovin-ios', 'applovin-android'].includes(getBurchaSelectedNetwork)
      "
    >
      <ApplovinCampaignSettings
        :show="showSettings"
        :data="settingsData"
        @closed="handleOnSettingsClosed"
        :game_id="selectedGame"
      />
    </div>

    <div v-if="['mintegral'].includes(getBurchaSelectedNetwork)">
      <MintegralCampaignSettings
        :show="showSettings"
        :data="settingsData"
        @closed="handleOnSettingsClosed"
        :game_id="selectedGame"
      />
    </div>
  </div>
</template>

<script>
import GoogleCampaignSettings from "@/components/tools/campaign-manager/networks/google/GoogleCampaignSettings.vue";
import ApplovinCampaignSettings from "@/components/tools/campaign-manager/networks/applovin/ApplovinCampaignSettings.vue";
import MintegralCampaignSettings from "@/components/tools/campaign-manager/networks/mintegral/MintegralCampaignSettings.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
moment.locale("en");

export default {
  props: {
    game_id: {
      type: Number,
      default: null,
    },
  },
  methods: {
    ...mapActions(["setBurchaSelectedNetwork"]),
    handleOnSettingsClosed() {
      this.showSettings = false;
      if (this.selectedNetwork === "google") {
        this.settingsData = null;
      } else if (
        ["applovin-android", "applovin-ios"].includes(this.selectedNetwork)
      ) {
        this.settingsData = null;
      }
    },
    showCampaignSettings(item) {
      this.showSettings = true;
      this.settingsData = item;
    },
    setDataFromStorage() {
      if (this.getBurchaSelectedNetwork && this.selectedGame) {
        const storageData = localStorage.getItem("campaignData") || null;
        if (storageData) {
          var storageDataJsonized = JSON.parse(storageData);
          storageDataJsonized = storageDataJsonized.find(
            (x) =>
              x.game_id == this.selectedGame &&
              x.channel == this.getBurchaSelectedNetwork
          );

          if (storageDataJsonized) {
            this.data = {
              data: storageDataJsonized.data.map((x) => ({
                ...x,
                isSelectable: true,
              })),
              channel: this.getBurchaSelectedNetwork,
              updated_at: storageDataJsonized.updated_at,
            };
          } else {
            this.data = null;
          }
        }

        return;
      }
    },
    async setData(data) {
      var storageCampaignData = localStorage.getItem("campaignData") || null;
      const key = "campaignData";
      const channel = this.selectedNetwork.toLowerCase();

      if (storageCampaignData) {
        localStorage.removeItem(key);
      }

      var requestData = storageCampaignData
        ? JSON.parse(storageCampaignData)
        : [];

      requestData = requestData.filter(
        (x) =>
          x.game_id !== this.selectedGame ||
          x.channel !== this.selectedNetwork.toLowerCase()
      );

      requestData.push({
        data,
        channel: channel,
        game_id: this.selectedGame,
        updated_at: Math.round(+new Date() / 1000),
      });

      localStorage.setItem(key, JSON.stringify(requestData));
      this.latestUpdateTime = moment
        .unix(Math.round(+new Date() / 1000))
        .fromNow();

      await this.fetchInUseCampaigns();
      this.data = {
        data: data.map((x) => ({
          ...x,
          isSelectable: !this.in_use_campaigns.some((y) => x.id === y),
        })),
        channel,
        updated_at: Math.round(+new Date() / 1000),
      };
    },
    async fetchCampaigns() {
      try {
        if (this.selectedGame && this.selectedNetwork) {
          this.refreshBtnLoading = true;
          this.loading = true;
          const request = await this.$api.get(
            `campaign-manager/campaigns/${this.selectedNetwork.toLowerCase()}?game_id=${
              this.selectedGame
            }`
          );
          if (request.status === 200) {
            this.setData(request.data.data);
          }
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
        this.refreshBtnLoading = false;
      }
    },
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchInUseCampaigns() {
      this.in_use_campaigns = [];
      //   try {
      //     var networkName = this.selectedNetwork.toLowerCase();

      //     if (["unity-ios", "unity-android"].includes(networkName)) {
      //       networkName = "unity";
      //     }

      //     const request = await this.$api.get(
      //       `burcha/${networkName}/tasks/in-use-campaigns`
      //     );
      //     this.in_use_campaigns = request.data;
      //   } catch (error) {
      //     console.log(error);
      //   } finally {
      //     this.loading = false;
      //     this.refreshBtnLoading = false;
      //   }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  data() {
    return {
      settingsData: null,
      headers: [
        { text: "Name", value: "campaign_name", width: "450px" },
        { text: "Budget", value: "campaign_budget", width: "220px" },
        { text: "Status", value: "status", width: "220px" },
        { text: "Target Countries", value: "geo_locations" },
      ],
      data: [],
      google_data: [],
      facebook_data: [],
      mintegral_data: [],
      unity_data: [],
      tiktok_data: [],
      targetCountries: [],
      campaignTitle: "",
      selectedCampaigns: [],
      loading: false,
      refreshBtnLoading: false,
      latestUpdateTime: null,
      timer: null,
      in_use_campaigns: null,
      selectedNetwork: null,
      networks: [
        { title: "Facebook", value: "facebook" },
        { title: "Mintegral", value: "mintegral" },
        { title: "Unity (iOS)", value: "unity-ios" },
        { title: "Unity (Android)", value: "unity-android" },
        { title: "Google", value: "google" },
        { title: "Applovin (iOS)", value: "applovin-ios" },
        { title: "Applovin (Android)", value: "applovin-android" },
        { title: "IronSource (iOS)", value: "ironsource-ios" },
        { title: "IronSource (Android)", value: "ironsource-android" },
      ],
      showSettings: false,
      games: [],
      selectedGame: null,
    };
  },
  computed: {
    ...mapGetters(["getBurchaSelectedNetwork"]),
    computedData() {
      return this.getData ? this.getData.data : [];
    },
    getData() {
      if (!this.getBurchaSelectedNetwork) return null;
      else {
        return this.data ? this.data : null;
      }
    },
    getLatestUpdateTime() {
      if (this.getData) {
        return moment.unix(this.getData.updated_at).fromNow();
      }
      return null;
    },
    computedHeaders() {
      const headers = JSON.parse(JSON.stringify(this.headers));
      if (["google"].includes(this.selectedNetwork)) {
        headers.push({ text: "Target CPA", value: "target_cpa" });
        headers.push({ text: "Target Roas", value: "target_roas" });
        headers.push({ text: "Actions", value: "actions", sortable: false });
      } else if (
        ["applovin-ios", "applovin-android"].includes(this.selectedNetwork)
      ) {
        headers.push({ text: "Actions", value: "actions", sortable: false });
      } else if (["mintegral"].includes(this.selectedNetwork)) {
        headers.push({ text: "Actions", value: "actions", sortable: false });
      }

      return headers;
    },
  },
  async mounted() {
    if (this.$route.params.game_id) {
      this.selectedGame = this.$route.params.game_id;
    }
    this.data = this.getData;
    this.latestUpdateTime = this.getLatestUpdateTime;

    if (this.getBurchaSelectedNetwork) {
      this.selectedNetwork = this.getBurchaSelectedNetwork;
    }

    this.fetchGames();
  },
  watch: {
    selectedCampaigns: {
      handler(newValue) {
        this.$emit("campaignsSelected", newValue);
      },
      deep: true,
    },
    selectedNetwork(newValue) {
      this.setBurchaSelectedNetwork(newValue);
      this.setDataFromStorage();
      this.latestUpdateTime = this.getLatestUpdateTime;
    },
    selectedGame() {
      this.setDataFromStorage();
    },
  },
  components: {
    MintegralCampaignSettings,
    GoogleCampaignSettings,
    ApplovinCampaignSettings,
  },
};
</script>

<style lang="scss" scoped></style>
