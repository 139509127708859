import moment from "moment";
import Vue from "vue";
import _ from "lodash";
import Papa from "papaparse";
import xlsxParser from "xls-parser";

function makeXMLRequest(url, file) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(true);
      } else {
        reject(false);
      }
    };
    xhr.onerror = function () {
      reject(false);
    };
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.send(file);
  });
}

function parseExcel(file) {
  return new Promise((resolve, reject) => {
    xlsxParser
      .onFileSelection(file, { hideEmptyRows: true, showNullProperties: true })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function formatUnix(unix) {
  return moment.unix(unix).format("DD.MM.YYYY HH:mm");
}

function formatUnixUtc(unix) {
  return moment.unix(unix).utc(false).format("DD.MM.YYYY HH:mm");
}

function formatLabelToKey(label) {
  return label.toLowerCase().trim().replace(/ /g, "_");
}

function makeModifiedTitle(title) {
  return _.startCase(title).replace(/[ ]+/g, "");
}

function isObj(item) {
  return Object.prototype.toString.call(item) == "[object Object]"
    ? true
    : false;
}

function isArr(item) {
  return Object.prototype.toString.call(item) == "[object Array]"
    ? true
    : false;
}

function sortObjByKey(object) {
  return Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
}

function unixToDate(unix, format) {
  return moment.unix(unix).format(format);
}

function dateToUnix(date) {
  return parseInt(moment(date).format("X"));
}

function sortArrayByProperty(array, properties, orders) {
  return _.orderBy(array, properties, orders);
}

async function csvFileToJSON(file, delimiter) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      delimiter: delimiter,
      header: true,
      complete: (results) => {
        if (results.data && results.data.length) {
          resolve(results.data);
        }
      },
      error: (err) => {
        reject(err.message);
      },
    });
    // try {
    //   var reader = new FileReader();
    //   reader.readAsBinaryString(file);
    //   reader.onload = function (e) {
    //     var jsonData = [];
    //     var headers = [];
    //     var rows = e.target.result.split("\r\n");
    //     console.log(rows);
    //     for (var i = 0; i < rows.length; i++) {
    //       var cells = rows[i].split(delimiter);
    //       var rowData = {};
    //       for (var j = 0; j < cells.length; j++) {
    //         if (i == 0) {
    //           var headerName = cells[j].trim();
    //           headers.push(headerName);
    //         } else {
    //           var key = headers[j];
    //           if (key) {
    //             rowData[key] = cells[j] ? cells[j].trim() : null;
    //           }
    //         }
    //       }
    //       //skip the first row (header) data
    //       if (i != 0) {
    //         jsonData.push(rowData);
    //       }
    //     }

    //     resolve(jsonData);
    //   };
    // } catch (e) {
    //   reject(e);
    // }
  });
}

function readJsonFile(file, include_filename) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const content = e.target.result;
      try {
        const jsonObject = JSON.parse(content);
        if (include_filename) {
          jsonObject.filename = file.name;
        }
        resolve(jsonObject);
      } catch (error) {
        reject("Error parsing JSON file. Please check the file format.");
      }
    };

    reader.readAsText(file);
  });
}

function isArrayStructureVerified(data, structure) {
  // Check if the data is an array and it's not empty
  if (!Array.isArray(data) || data.length === 0) {
    return false;
  }

  // Iterate through each object in the data array
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];

    for (let y = 0; y < structure.length; y++) {
      const key = structure[y];

      if (!(key in obj)) {
        return false;
      }
    }
  }
  // for (const obj of data) {
  //   // Iterate through each key in the structure array
  //   for (const key of structure) {
  //     // Check if the key is not present in the current object
  //     if (!(key in obj)) {
  //       console.log(key);
  //       console.log(obj);
  //       return false; // If any key is missing, return false immediately
  //     }
  //   }
  // }

  // If all objects in data contain all keys in structure, return true
  return true;
}

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

function base64ToFile(base64String, fileName, mimeType) {
  // Extracting the mime type and base64 data
  var mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
  var dataString = atob(base64String.split(",")[1]);

  // Convert the base64 data to a Uint8Array
  var dataArray = new Uint8Array(dataString.length);
  for (var i = 0; i < dataString.length; i++) {
    dataArray[i] = dataString.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  var blob = new Blob([dataArray], { type: mimeString });

  // Create a File object from the Blob
  var file = new File([blob], fileName, { type: mimeType });

  return file;
}

function generateRandomId(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export default {
  install() {
    Vue.prototype.$formatUnix = formatUnix;
    Vue.prototype.$formatLabelToKey = formatLabelToKey;
    Vue.prototype.$makeModifiedTitle = makeModifiedTitle;
    Vue.prototype.$isObj = isObj;
    Vue.prototype.$isArr = isArr;
    Vue.prototype.$sortObjByKey = sortObjByKey;
    Vue.prototype.$unixToDate = unixToDate;
    Vue.prototype.$dateToUnix = dateToUnix;
    Vue.prototype.$csvFileToJson = csvFileToJSON;
    Vue.prototype.$isArrayStructureVerified = isArrayStructureVerified;
    Vue.prototype.$sortArrayByProperty = sortArrayByProperty;
    Vue.prototype.$readJsonFile = readJsonFile;
    Vue.prototype.$parseExcel = parseExcel;
    Vue.prototype.$formatUnixUtc = formatUnixUtc;
    Vue.prototype.$fileToBase64 = fileToBase64;
    Vue.prototype.$base64ToFile = base64ToFile;
    Vue.prototype.$makeXMLRequest = makeXMLRequest;
    Vue.prototype.$generateRandomId = generateRandomId;
  },
};
