export default {
  state: {
    copiedEventData: null,
  },
  getters: {
    getCopiedEventData(state) {
      return state.copiedEventData;
    },
  },
  mutations: {
    handleOnSetCopiedEventData(state, payload) {
      state.copiedEventData = payload;
    },
  },
  actions: {
    setCopiedEventData({ commit }, payload) {
      commit("handleOnSetCopiedEventData", payload);
    },
  },
};
