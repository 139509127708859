import StreamlitView from "@/views/tools/utility/StreamlitView.vue";
import SoftLaunchLtvCalculator from "@/views/tools/utility/SoftLaunchLtvCalculator.vue";
import ApplovinWaterfallView from "@/views/tools/utility/ApplovinWaterfall.vue";
import ApplovinPlacementRefresh from "@/views/tools/utility/ApplovinPlacementRefresh.vue";
import ApplovinMaxWfOperator from "@/views/tools/utility/ApplovinMaxWfOperator.vue";
import ApplovinMaxWfOperatorXlsx from "@/views/tools/utility/ApplovinMaxWfOperatorXlsx.vue";

export const utility_router = [
  {
    path: "/utility/streamlit",
    name: "utility-streamlit",
    component: StreamlitView,
    meta: {
      title: "Streamlit Tool",
    },
  },
  {
    path: "/utility/soft-launch-ltv-calculator",
    name: "utility-soft-launch-ltv-calculator",
    component: SoftLaunchLtvCalculator,
    meta: {
      title: "LTV Calculator",
    },
  },
  {
    path: "/applovin/waterfall",
    name: "applovin-waterfall",
    component: ApplovinWaterfallView,
    meta: {
      title: "Applovin Waterfall Creator",
    },
  },
  {
    path: "/applovin/placement-refresh",
    name: "applovin-placement-refresher",
    component: ApplovinPlacementRefresh,
    meta: {
      title: "Applovin Placement Refresher",
    },
  },
  {
    path: "/applovin/max-wf-operator",
    name: "applovin-max-wf-operator",
    component: ApplovinMaxWfOperator,
    meta: {
      title: "Applovin Max WF Operator",
    },
  },
  {
    path: "/applovin/max-wf-operator-xlsx",
    name: "applovin-max-wf-operator-xlsx",
    component: ApplovinMaxWfOperatorXlsx,
    meta: {
      title: "Applovin Max WF Operator",
    },
  },
];
