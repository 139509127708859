<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">Fatura Oluştur</h1>

    <div>
      <h1>Fatura Oluşturma Yöntemi</h1>
      <div>
        <v-radio-group v-model="invoiceCreationMethod" row>
          <v-radio label="Klasik" value="klasik"></v-radio>
          <v-radio label="CSV" value="csv"></v-radio>
        </v-radio-group>
      </div>
    </div>

    <div v-if="invoiceCreationMethod === 'klasik'">
      <div
        v-if="selected_game && !selected_game.btsb_code"
        class="p-6 rounded bg-sky-50 text-sky-800 font-semibold text-[12px] mb-5"
      >
        {{ selected_game.title }} does not have any BTSB code. You can create an
        invoice, however it won't be created on Parasut without setting a BTSB
        code in Gaia.
      </div>
      <div class="mb-5">
        <v-autocomplete
          clearable
          label="Oyun Seç"
          :disabled="loading"
          outlined
          auto-select-first
          class="text-[12px]"
          v-model="selected_game"
          :items="games"
          item-text="title"
          hide-details
          return-object
        >
        </v-autocomplete>
      </div>
      <div class="mt-5">
        <v-autocomplete
          clearable
          flat
          label="Müşteri Seç"
          v-model="selected_customer"
          :disabled="input_loading"
          :loading="input_loading"
          outlined
          auto-select-first
          class="text-[12px]"
          :items="
            getParasutCustomers.map((x) => {
              return { ...x.attributes, id: x.id };
            })
          "
          item-text="name"
          item-value="id"
          hide-details
        >
        </v-autocomplete>
      </div>

      <div class="my-5">
        <v-select
          clearable
          label="KDV"
          outlined
          auto-select-first
          class="text-[12px]"
          v-model="selected_tax"
          :items="taxes"
          item-text="title"
          item-value="id"
          hide-details
        >
          <template v-slot:selection="{ item }">
            <span>% {{ item }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span class="text-[12px]">% {{ item }}</span>
          </template>
        </v-select>
      </div>

      <div class="mt-5" v-if="show_skeleton">
        <v-skeleton-loader
          type="list-item-two-line, list-item-three-line, list-item-two-line"
        ></v-skeleton-loader>
      </div>

      <v-divider v-if="selected_parasut_product"></v-divider>

      <div v-if="showInfo && !show_skeleton" class="mt-5">
        <div class="mt-5">
          <v-text-field
            clearable
            flat
            label="Hizmet / Ürün"
            :value="selected_parasut_product.attributes.name"
            disabled
            outlined
            class="text-[12px]"
            hide-details
          >
          </v-text-field>
        </div>
        <div class="mt-5">
          <v-textarea
            v-model="invoice_description"
            label="Fatura Başlığı"
            counter
            outlined
            maxlength="300"
            class="text-[12px]"
          ></v-textarea>
        </div>
        <div class="mt-3">
          <div>
            <v-select
              outlined
              label="Fatura Dövizi"
              v-model="invoice_currency"
              :items="available_currencies"
              dense
              hide-details
              class="text-[13px]"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </div>
        <div class="my-5" v-if="invoice_currency !== 'TRL'">
          <v-text-field
            outlined
            v-model="exchange_info.ForexBuying"
            label="Döviz Kuru"
            class="text-[13px]"
            type="number"
            :loading="currency_loading"
            :disabled="currency_loading"
            :step="0.01"
            hide-details
          ></v-text-field>
        </div>
        <div class="my-5">
          <v-menu
            ref="show_invoice_date"
            v-model="show_invoice_date"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="invoice_date_formatted"
                label="Düzenleme Tarihi"
                class="text-[13px]"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="invoice_date"
              color="primary"
              :allowed-dates="allowedDates"
              @input="show_invoice_date = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <v-divider></v-divider>
        <div class="mt-5">
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-3">
              <h1 class="text-gray-500 text-[12px]">Miktar</h1>
            </div>
            <div class="col-span-3">
              <h1 class="text-gray-500 text-[12px]">Birim</h1>
            </div>
            <div class="col-span-3">
              <h1 class="text-gray-500 text-[12px]">Br. Fiyat</h1>
            </div>
            <div class="col-span-3">
              <h1 class="text-gray-500 text-[12px]">Toplam</h1>
            </div>
          </div>
        </div>
        <div class="my-5">
          <div class="grid grid-cols-12 gap-3 items-center mb-3">
            <div class="col-span-3">
              <v-text-field
                outlined
                v-model="product.amount"
                dense
                hide-details
                type="number"
                :step="1"
                class="text-[13px]"
                @input="handleFormChange"
              ></v-text-field>
            </div>
            <div class="col-span-3">
              <v-select
                outlined
                v-model="product.unit"
                disabled
                :items="available_units"
                dense
                hide-details
                class="text-[13px]"
                item-text="text"
                item-value="value"
              ></v-select>
            </div>
            <div class="col-span-3">
              <v-text-field
                outlined
                v-model="product.price_per_unit"
                dense
                type="number"
                :step="0.1"
                hide-details
                :prefix="invoice_currency === 'USD' ? '$' : '₺'"
                class="text-[13px]"
                @input="handleFormChange"
              ></v-text-field>
            </div>
            <div class="col-span-3">
              <v-text-field
                outlined
                v-model="product.total"
                dense
                :prefix="invoice_currency === 'USD' ? '$' : '₺'"
                hide-details
                class="text-[13px]"
                @input="handleTotalChange"
              ></v-text-field>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="grid grid-cols-4 gap-5">
            <div class="flex flex-col">
              <h1 class="text-[11px] text-gray-800 font-semibold">
                1. Ara Toplam
              </h1>
              <span class="mt-2 text-[18px] text-gray-400">
                {{ computedPriceWithoutTaxes.toLocaleString("de-DE") }}
                {{ computedSelectedCurrencySymbol }}
              </span>
            </div>
            <div class="flex flex-col">
              <h1 class="text-[11px] text-gray-800 font-semibold">2. Vergi</h1>
              <span class="mt-2 text-[18px] text-gray-400">
                {{ computedTaxPrice.toLocaleString("de-DE") }}
                {{ computedSelectedCurrencySymbol }}
              </span>
            </div>
            <div class="flex flex-col">
              <h1 class="text-[11px] text-gray-800 font-semibold">
                3. Genel Toplam
              </h1>
              <span class="mt-2 text-[18px] text-gray-400">
                {{ computedTotalPrice.toLocaleString("de-DE") }}
                {{ computedSelectedCurrencySymbol }}
              </span>
            </div>
            <div
              class="flex flex-col"
              v-if="computedSelectedCurrency !== 'TRL'"
            >
              <h1 class="text-[11px] text-gray-800 font-semibold">
                4. TL Karşılığı
              </h1>
              <span class="mt-2 text-[18px] text-gray-400">
                {{
                  computedTotalPriceWithExchange
                    ? computedTotalPriceWithExchange.toLocaleString("de-DE")
                    : 0
                }}
                ₺
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex justify-between items-center"
        :class="selected_game ? 'mt-5' : ''"
      >
        <v-btn
          color="success"
          text
          :elevation="0"
          :ripple="false"
          @click="refreshData"
          :disabled="input_loading"
          :loading="input_loading"
        >
          Bilgileri Yenile
        </v-btn>

        <v-btn
          color="primary"
          :elevation="0"
          :ripple="false"
          :loading="createLoading"
          :disabled="isBtnDisabled || createLoading"
          @click="createInvoice"
        >
          Kaydet
        </v-btn>
      </div>
    </div>

    <div v-if="invoiceCreationMethod === 'csv'">
      <v-file-input
        show-size
        accept=".csv"
        label="CSV Dosyasını Seç"
        class="text-[12px]"
        outlined
        dense
        hide-details
        v-model="csv_file"
      >
      </v-file-input>

      <div class="flex items-center justify-end mt-3">
        <v-btn
          color="primary"
          :elevation="0"
          :ripple="false"
          :loading="createLoading"
          :disabled="!this.csv_file || createLoading"
          @click="createInvoiceByCsv(true)"
        >
          Devam Et
        </v-btn>
      </div>
    </div>
    <CsvInvoiceSummary
      :show="showSummary"
      :data="summaryData"
      @closed="handleDialogClose"
      @approved="handleDialogApprove"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import CsvInvoiceSummary from "@/components/tools/parasut/CsvInvoiceSummary.vue";

export default {
  data() {
    return {
      invoiceCreationMethod: "klasik",
      loading: false,
      input_loading: false,
      selected_game: null,
      games: [],
      customers: [],
      parasut_products: [],
      selected_parasut_product: null,
      selected_customer: null,
      invoice_template: null,
      invoice_description: null,
      selected_tax: null,
      taxes: [20, 10, 1, 0],
      show_skeleton: false,
      product: {
        amount: 0,
        unit: "Adet",
        price_per_unit: 0,
        total: 0,
      },
      available_units: ["Adet"],
      invoice_date: moment().format("YYYY-MM-DD"),
      invoice_date_formatted: null,
      show_invoice_date: false,
      exchange_info: null,
      invoice_currency: "USD",
      available_currencies: [
        {
          text: "$ - Abd Doları",
          value: "USD",
        },
        {
          text: "₺ - Türk Lirası",
          value: "TRL",
        },
        {
          text: "€ - Euro",
          value: "EUR",
        },
      ],
      currency_loading: false,
      csv_file: null,
      createLoading: false,
      showSummary: false,
      summaryData: [],
    };
  },
  methods: {
    ...mapActions(["setParasutCustomers"]),
    async createInvoiceByCsv(preview) {
      if (!this.csv_file) {
        return;
      }
      try {
        this.createLoading = true;
        let formData = new FormData();
        formData.append("csv_file", this.csv_file);
        var url = `parasut/invoices/create-by-csv`;
        if (!preview) {
          url += `?preview=False`;
        }
        const request = await this.$api.post(url, formData);
        // Here we need to show summary dialog.
        if (preview) {
          this.summaryData = request.data;
          this.showSummary = true;
        } else if (!preview) {
          this.$toast.success(
            `${this.summaryData.length} adet fatura oluşturuldu. "Faturaları Yönet" ekranından onaylamanız gerekmektedir!`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
          this.csv_file = null;
        }
      } catch (error) {
        console.log(error);
        this.errorHandler(error);
      } finally {
        this.createLoading = false;
      }
    },
    handleDialogClose() {
      this.showSummary = false;
    },
    handleDialogApprove() {
      // console.log("approved");
      this.createInvoiceByCsv(false);
    },
    fetchGames() {
      this.loading = true;
      this.$api
        .get("games/all")
        .then((response) => {
          this.games = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.errorHandler(err);
          this.loading = false;
        });
    },
    fetchClients() {
      this.input_loading = true;
      var url = "parasut/clients";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.customers = response.data;
            this.setParasutCustomers(this.customers);
          }
          this.input_loading = false;
        })
        .catch((err) => {
          this.input_loading = false;
          this.errorHandler(err);
        });
    },
    fetchProductInfo(id) {
      this.$api
        .get(`parasut/products/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.selected_parasut_product = response.data?.data;
            this.show_skeleton = false;

            if (this.selected_parasut_product?.attributes) {
              this.product.unit = this.selected_parasut_product.attributes.unit;
              this.product.price_per_unit =
                Math.round(
                  this.selected_parasut_product.attributes.list_price * 100
                ) / 100;
            }
          }
        })
        .catch((err) => {
          this.show_skeleton = false;
          this.errorHandler(err);
        });
    },
    fetchGameInvoiceTemplate() {
      this.show_skeleton = true;
      this.invoice_template = null;
      this.selected_parasut_product = null;
      this.invoice_description = null;

      if (
        this.selected_game &&
        this.selected_customer &&
        (this.selected_tax || this.selected_tax === 0)
      ) {
        this.$api
          .get(
            `parasut/templates/get?game_id=${this.selected_game.id}&customer_id=${this.selected_customer}&tax=${this.selected_tax}`
          )
          .then((response) => {
            if (response.status === 200) {
              this.invoice_template = response.data;
              this.fetchProductInfo(parseInt(response.data.parasut_product));
            } else {
              this.show_skeleton = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.show_skeleton = false;
            this.errorHandler(err);
          });
      }
    },
    handleTotalChange() {
      if (this.product) {
        const amount = this.product.amount;
        const total = this.product.total;
        const tax = this.selected_tax;
        const calc = (total / (1 + tax / 100)) * (1 / amount);
        this.product.price_per_unit = Math.round(calc * 10000) / 10000;
      }
    },
    handleFormChange() {
      if (this.product) {
        const price_per_unit = this.product.price_per_unit;
        const amount = this.product.amount;
        const tax = this.selected_tax;
        const base_total = price_per_unit * amount;
        const base_total_w_tax = base_total + (base_total * tax) / 100;
        this.product.total = Math.round(base_total_w_tax * 100) / 100;
      }
    },
    allowedDates(value) {
      const moment_date = moment(value);
      return (
        moment_date.diff(moment(), "days") < 1 &&
        moment().diff(moment_date, "days") < 8 &&
        moment().add(1, "days").format("YYYY/MM/DD") !==
          moment_date.format("YYYY/MM/DD")
      );
    },
    createInvoice() {
      if (
        !this.invoice_description &&
        !this.computedCustomer &&
        !this.computedCustomer.attributes
      ) {
        return;
      }

      this.createLoading = true;

      const data = {
        invoice_date: this.invoice_date,
        description: this.invoice_description,
        exchange_rate: this.exchange_info.ForexBuying,
        tax_amount: this.computedTaxPrice,
        total_amount_wout_tax: this.computedPriceWithoutTaxes,
        total_amount: this.computedTotalPrice,
        currency: this.invoice_currency,
        product: this.product,
      };
      const requestData = {
        data: JSON.stringify(data),
        template_id: this.invoice_template.id,
        is_abroad: this.computedCustomer.attributes.is_abroad,
      };
      this.$api
        .post("parasut/invoices/create", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.resetForm();
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Fatura başarıyla oluşturuldu.",
            });
          }
        })
        .catch((err) => {
          this.errorHandler(err);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    fetchCurrencyData(date) {
      this.currency_loading = true;
      this.$api
        .get(`utils/fetch-currencies?date=${date}`)
        .then((response) => {
          if (response.status === 200) {
            const filtered_data = response.data.data.Tarih_Date.Currency.filter(
              (x) => x["@CurrencyCode"] === this.invoice_currency
            );
            this.exchange_info =
              filtered_data.length > 0 ? filtered_data[0] : null;
          }
          this.currency_loading = false;
        })
        .catch((err) => {
          this.errorHandler(err);
          this.currency_loading = false;
        });
    },
    resetForm() {
      this.selected_game = null;
      this.selected_parasut_product = null;
      this.selected_customer = null;
      this.invoice_template = null;
      this.invoice_description = null;
      this.selected_tax = null;
      this.product = {
        amount: 0,
        unit: "Adet",
        price_per_unit: 0,
        total: 0,
      };
      this.invoice_date = moment().format("YYYY-MM-DD");
      this.invoice_date_formatted = null;
    },
    refreshData() {
      this.fetchClients();
      this.fetchCurrencyData(this.invoice_date);
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  mounted() {
    this.fetchGames();
    if (!this.getParasutCustomers) {
      this.fetchClients();
    }
    this.fetchCurrencyData(this.invoice_date);
  },
  watch: {
    selected_game(newValue) {
      if (
        newValue &&
        (this.selected_tax || this.selected_tax === 0) &&
        this.selected_customer
      ) {
        this.fetchGameInvoiceTemplate();
      }
    },
    selected_tax(newValue) {
      if (
        (newValue || newValue === 0) &&
        this.selected_game &&
        this.selected_customer
      ) {
        this.fetchGameInvoiceTemplate();
      }
    },
    selected_customer(newValue) {
      if (
        newValue &&
        this.selected_game &&
        (this.selected_tax || this.selected_tax === 0)
      ) {
        this.fetchGameInvoiceTemplate();
      }
    },
    invoice_date: {
      handler(newValue) {
        if (newValue) {
          this.invoice_date_formatted = moment(newValue).format("DD.MM.YYYY");

          // Need to fetch currency rates.
          this.fetchCurrencyData(this.invoice_date);
        }
      },
      immediate: true,
    },
    due_date: {
      handler(newValue) {
        if (newValue) {
          this.due_date_formatted = moment(newValue).format("DD.MM.YYYY");
        }
      },
      immediate: true,
    },
    due_date_picker(newValue) {
      this.due_date = moment().add(newValue, "days").format("YYYY-MM-DD");
    },
    invoice_template(newValue) {
      if (
        this.invoice_date &&
        this.selected_customer &&
        this.selected_game &&
        newValue
      ) {
        const gameTitle = this.selected_game.title
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .split(" ")
          .reduce((response, word) => (response += word.slice(0, 1)), "");

        this.invoice_description = `${
          this.computedCustomer.attributes.name
        } - ${this.computedMonth} - ${this.computedYear} - ${gameTitle} - ${
          this.computedCustomer.attributes.is_abroad ? "Yurtdışı" : "TR"
        }`;
      }
      if (!newValue) {
        this.invoice_description = null;
      }
    },
    invoice_currency(newValue) {
      if (newValue !== "TRL") {
        this.fetchCurrencyData(this.invoice_date);
      }
    },
  },
  computed: {
    ...mapGetters(["getParasutCustomers"]),
    computedCustomer() {
      if (this.getParasutCustomers && this.selected_customer) {
        const customer = this.getParasutCustomers.filter(
          (x) => x.id == this.selected_customer
        );

        if (customer.length > 0) {
          return customer[0];
        }

        return null;
      }
      return null;
    },
    showInfo() {
      return this.selected_parasut_product ? true : false;
    },
    computedPriceWithoutTaxes() {
      return (
        Math.round(this.product.amount * this.product.price_per_unit * 100) /
        100
      );
    },
    computedTaxPrice() {
      const price_wout_taxes =
        this.product.amount * this.product.price_per_unit;

      return (
        Math.round(((price_wout_taxes * this.selected_tax) / 100) * 100) / 100
      );
    },
    computedTotalPrice() {
      return this.computedPriceWithoutTaxes + this.computedTaxPrice;
    },
    computedTotalPriceWithExchange() {
      if (this.exchange_info) {
        return (
          Math.round(
            this.computedTotalPrice *
              parseFloat(this.exchange_info.ForexBuying) *
              100
          ) / 100
        );
      }
      return null;
    },
    computedSelectedCurrency() {
      return this.invoice_currency;
    },
    computedSelectedCurrencySymbol() {
      if (this.computedSelectedCurrency) {
        if (this.computedSelectedCurrency === "USD") return "$";
        if (this.computedSelectedCurrency === "TRL") return "₺";
        if (this.computedSelectedCurrency === "EUR") return "€";
      }
      return null;
    },
    isBtnDisabled() {
      if (this.computedTotalPrice === 0) {
        return true;
      }
      if (!this.selected_parasut_product) {
        return true;
      }
      if (!this.selected_game) {
        return true;
      }
      if (!this.selected_customer) {
        return true;
      }
      if (!this.invoice_template) {
        return true;
      }
      if (!this.invoice_description) {
        return true;
      }

      if (!this.selected_tax && this.selected_tax !== 0) {
        return true;
      }

      if (!this.exchange_info) {
        return true;
      }

      if (!this.computedCustomer) {
        return true;
      }

      if (!this.computedCustomer.attributes) {
        return true;
      }
      return false;
    },
    computedMonth() {
      return moment(this.invoice_date).format("MMMM");
    },
    computedYear() {
      return moment(this.invoice_date).format("YYYY");
    },
  },
  components: {
    CsvInvoiceSummary,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__title {
  font-size: 11px;
  line-height: normal;
}
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
