export default {
  state: {
    filename: null,
  },
  getters: {
    getFilename(state) {
      return state.filename;
    },
  },
  mutations: {
    handleSetFilename(state, payload) {
      state.filename = payload;
    },
  },
  actions: {
    setFilename({ commit }, payload) {
      commit("handleSetFilename", payload);
    },
  },
};
