<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">List Tests</h1>
    <div class="mb-3">
      <v-text-field
        placeholder="Search by title..."
        filled
        rounded
        dense
        v-model="keyword"
        class="text-[12px]"
        append-icon="mdi-magnify"
      ></v-text-field>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="forms"
        :loading="loading"
        :items-per-page="15"
        class="row-pointer"
        @click:row="handleOnRowClick"
      >
        <template v-slot:[`item.title`]="{ item }">
          <div class="flex items-center text-gray-600">
            <span class="font-semibold text-[11px] capitalize">
              {{ item.title }}
            </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="ml-2" v-bind="attrs" v-on="on">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-info-circle"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                    <path d="M12 9h.01"></path>
                    <path d="M11 12h1v4h1"></path>
                  </svg>
                </span>
              </template>
              {{ item.description }}
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.variants`]="{ item }">
          <div>
            <span
              class="inline-block mt-1 mr-1 px-2 py-1 text-[10px] bg-indigo-100 text-indigo-700 rounded-[3px] font-semibold hover:bg-indigo-200"
              v-for="(variant, index) in JSON.parse(item.variants)"
              :key="index"
            >
              {{ variant }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.game_title`]="{ item }">
          <span
            class="inline-block px-2 py-1 text-[10px] bg-gray-200 text-gray-700 rounded-[3px] font-semibold hover:bg-gray-300"
          >
            {{ item.game_title }}
          </span>
        </template>
        <template v-slot:[`item.start_at`]="{ item }">
          <span class="text-[11px] text-gray-600 font-semibold">
            {{ $unixToDate(item.start_at, "DD.MM.YYYY") }}
          </span>
        </template>
        <template v-slot:[`item.finish_at`]="{ item }">
          <span
            class="text-[11px] font-semibold"
            :class="
              isFinished(item.finish_at) ? 'text-lime-600' : 'text-rose-600'
            "
          >
            {{ $unixToDate(item.finish_at, "DD.MM.YYYY") }}
          </span>
        </template>
        <template v-slot:[`item.experiment_id`]="{ item }">
          <span class="text-[11px] text-gray-500">
            {{ item.experiment_id }}
          </span>
        </template>
      </v-data-table>
    </div>

    <UpdateDialog
      :show="showDialog"
      @closed="handleDialogOnClose"
      @updated="handleOnUpdate"
      @deleted="handleOnUpdate"
      :data="clickedRowData"
    />
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import UpdateDialog from "@/components/tools/firebase_ab_tests/UpdateDialog.vue";

export default {
  data() {
    return {
      headers: [
        { text: "Title", value: "title" },
        { text: "Experiment ID", value: "experiment_id" },
        { text: "Game", value: "game_title" },
        { text: "Variants", value: "variants", sortable: false },
        { text: "Start At", value: "start_at" },
        { text: "Finish At", value: "finish_at" },
      ],
      forms: [],
      loading: false,
      keyword: "",
      showDialog: false,
      clickedRowData: {},
    };
  },
  methods: {
    async fetchForms() {
      try {
        this.loading = true;
        var url = `ab-tests/all`;
        if (this.keyword) {
          url = `${url}?title=${this.keyword}`;
        }

        const response = await this.$api.get(url);
        if (response.status === 200) {
          this.forms = response.data;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    isFinished(finish_at) {
      return moment().unix() >= finish_at;
    },
    handleDialogOnClose() {
      this.showDialog = false;
    },
    handleOnRowClick($event) {
      this.clickedRowData = $event;
      this.showDialog = true;
    },
    async handleOnUpdate() {
      await this.fetchForms();
    },
  },
  mounted() {
    this.fetchForms();
  },
  watch: {
    keyword: _.debounce(function () {
      this.fetchForms();
    }, 1000),
  },
  components: {
    UpdateDialog,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .row-pointer {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
</style>
