<template>
  <div>
    <v-dialog v-model="visible" width="820">
      <v-card v-if="invoice_information && isBtnActivated">
        <v-card-title class="text-h5"> Fatura Detayı </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <div class="flex items-center justify-between">
              <h1 class="font-semibold text-[12px] text-gray-600">
                1. Taslak Detayları
              </h1>
              <span
                v-if="[1, 2].includes(row_data.status_id)"
                @click="deleteInvoice"
                class="text-[11px] text-red-600 font-semibold hover:underline cursor-pointer hover:text-red-500"
                >Faturayı Sil</span
              >
            </div>
            <div>
              <div class="mt-5">
                <v-autocomplete
                  clearable
                  flat
                  label="Oyun"
                  v-model="template_data.selected_game"
                  dense
                  outlined
                  class="text-[12px]"
                  hide-details
                  :items="games"
                  item-text="title"
                  item-value="id"
                >
                </v-autocomplete>
              </div>
              <div class="mt-3">
                <v-text-field
                  clearable
                  flat
                  label="ID"
                  :value="invoice_information.template.id"
                  disabled
                  dense
                  outlined
                  class="text-[12px]"
                  hide-details
                >
                </v-text-field>
              </div>
              <div class="mt-3">
                <v-text-field
                  flat
                  label="BTSB"
                  :value="template_data.btsb_code"
                  disabled
                  dense
                  outlined
                  class="text-[12px]"
                  hide-details
                >
                </v-text-field>
              </div>
              <div class="mt-3">
                <v-radio-group
                  dense
                  hide-details
                  v-model="template_data.is_dispatch"
                  row
                >
                  <v-radio label="İrsaliyeli Fatura" :value="true"></v-radio>
                  <v-radio label="İrsaliyesiz Fatura" :value="false"></v-radio>
                </v-radio-group>
              </div>
              <div class="mt-3">
                <v-radio-group
                  dense
                  hide-details
                  v-model="template_data.is_collected"
                  row
                >
                  <v-radio label="Tahsil Edilecek" :value="false"></v-radio>
                  <v-radio label="Tahsil Edildi" :value="true"></v-radio>
                </v-radio-group>
              </div>
              <div class="mt-3">
                <v-autocomplete
                  clearable
                  flat
                  label="Ürün / Hizmet"
                  v-model="template_data.parasut_product"
                  dense
                  outlined
                  :items="
                    parasut_products.map((x) => {
                      return { ...x.attributes, id: x.id };
                    })
                  "
                  class="text-[12px]"
                  hide-details
                  item-text="name"
                  item-value="id"
                >
                </v-autocomplete>
              </div>
              <div class="mt-3">
                <v-autocomplete
                  clearable
                  flat
                  label="Müşteri"
                  v-model="template_data.parasut_customer"
                  dense
                  outlined
                  :items="
                    customers.map((x) => {
                      return { ...x.attributes, id: x.id };
                    })
                  "
                  class="text-[12px]"
                  hide-details
                  item-text="name"
                  item-value="id"
                >
                </v-autocomplete>
              </div>
              <div class="mt-3">
                <v-select
                  clearable
                  label="KDV"
                  outlined
                  auto-select-first
                  class="text-[12px]"
                  v-model="template_data.selected_tax"
                  :items="taxes"
                  dense
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <span>% {{ item }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="text-[12px]">% {{ item }}</span>
                  </template>
                </v-select>
              </div>
              <div class="mt-3">
                <v-autocomplete
                  v-model="template_data.tags"
                  :items="
                    tags.map((x) => {
                      return { ...x.attributes, id: x.id };
                    })
                  "
                  attach
                  chips
                  outlined
                  label="Etiketler"
                  item-text="name"
                  small-chips
                  dense
                  multiple
                  deletable-chips
                  auto-select-first
                  hide-details
                  item-value="id"
                ></v-autocomplete>
              </div>
              <div class="mt-3">
                <v-autocomplete
                  v-model="template_data.categories"
                  :items="
                    categories.map((x) => {
                      return { ...x.attributes, id: x.id };
                    })
                  "
                  attach
                  dense
                  chips
                  outlined
                  label="Kategori"
                  item-text="name"
                  small-chips
                  deletable-chips
                  auto-select-first
                  hide-details
                  item-value="id"
                ></v-autocomplete>
              </div>
            </div>
            <h1 class="font-semibold text-[12px] text-gray-600 mt-5">
              2. Fatura Detayları
            </h1>
            <div>
              <div class="mt-5">
                <v-menu
                  ref="show_invoice_date"
                  v-model="show_invoice_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="invoice_date_formatted"
                      label="Düzenleme Tarihi"
                      class="text-[13px]"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice_data.invoice_date"
                    color="primary"
                    :allowed-dates="allowedDates"
                    @input="handleDateChange"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mt-3">
                <v-textarea
                  flat
                  label="Fatura Açıklaması"
                  v-model="invoice_data.description"
                  dense
                  outlined
                  class="text-[12px]"
                  hide-details
                >
                </v-textarea>
              </div>
              <div class="mt-3">
                <v-text-field
                  v-if="invoice_data.currency !== 'TRL'"
                  flat
                  label="Döviz Değeri"
                  v-model="invoice_data.exchange_rate"
                  dense
                  type="number"
                  :step="0.01"
                  outlined
                  :loading="currency_loading"
                  class="text-[12px]"
                  hide-details
                >
                </v-text-field>
              </div>
              <div class="mt-3">
                <v-select
                  flat
                  label="Döviz"
                  v-model="invoice_data.currency"
                  dense
                  outlined
                  class="text-[12px]"
                  hide-details
                  :items="['TRL', 'USD', 'EUR']"
                >
                </v-select>
              </div>
              <div class="mt-3 flex items-center justify-between gap-3">
                <div class="w-full">
                  <v-text-field
                    label="Ürün Miktarı"
                    v-model="invoice_data.product.amount"
                    type="number"
                    :step="1"
                    dense
                    outlined
                    class="text-[12px]"
                    hide-details
                    @input="handleFormChange"
                  >
                  </v-text-field>
                </div>
                <div class="w-full">
                  <v-text-field
                    label="Birim Fiyatı"
                    v-model="invoice_data.product.price_per_unit"
                    type="number"
                    :step="0.1"
                    :prefix="computedSelectedCurrencySymbol"
                    dense
                    outlined
                    class="text-[12px]"
                    hide-details
                    @input="handleFormChange"
                  >
                  </v-text-field>
                </div>
                <div class="w-full">
                  <v-text-field
                    label="Toplam Tutar (Vergi Dahil)"
                    v-model="invoice_data.product.total"
                    type="number"
                    :step="1"
                    :prefix="computedSelectedCurrencySymbol"
                    dense
                    outlined
                    class="text-[12px]"
                    hide-details
                    @input="handleTotalChange"
                  >
                  </v-text-field>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <div class="grid grid-cols-4 gap-5">
                <div class="flex flex-col">
                  <h1 class="text-[11px] text-gray-800 font-semibold">
                    1. Ara Toplam
                  </h1>
                  <span class="mt-2 text-[18px] text-gray-400">
                    {{ computedPriceWithoutTaxes.toLocaleString("de-DE") }}
                    {{ computedSelectedCurrencySymbol }}
                  </span>
                </div>
                <div class="flex flex-col">
                  <h1 class="text-[11px] text-gray-800 font-semibold">
                    2. Vergi
                  </h1>
                  <span class="mt-2 text-[18px] text-gray-400">
                    {{ computedTaxPrice }}
                    {{ computedSelectedCurrencySymbol }}
                  </span>
                </div>
                <div class="flex flex-col">
                  <h1 class="text-[11px] text-gray-800 font-semibold">
                    3. Genel Toplam
                  </h1>
                  <span class="mt-2 text-[18px] text-gray-400">
                    {{ computedTotalPrice.toLocaleString("de-DE") }}
                    {{ computedSelectedCurrencySymbol }}
                  </span>
                </div>
                <div
                  class="flex flex-col"
                  v-if="computedSelectedCurrency === 'USD'"
                >
                  <h1 class="text-[11px] text-gray-800 font-semibold">
                    4. TL Karşılığı
                  </h1>
                  <span class="mt-2 text-[18px] text-gray-400">
                    {{
                      computedTotalPriceWithExchange
                        ? computedTotalPriceWithExchange.toLocaleString("de-DE")
                        : 0
                    }}
                    ₺
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="flex items-center justify-between w-full">
            <div class="w-full">
              <v-btn
                color="error"
                :elevation="0"
                :ripple="false"
                @click="visible = false"
                :disabled="loading"
              >
                Kapat
              </v-btn>
            </div>
            <div class="flex items-center">
              <div class="mr-3">
                <v-btn
                  :disabled="isUpdateBtnDisabled"
                  :elevation="0"
                  :ripple="false"
                  color="success"
                  @click="updateInvoice"
                  :loading="update_loading"
                >
                  Güncelle
                </v-btn>
              </div>
              <div>
                <v-btn
                  :loading="loading"
                  :elevation="0"
                  :ripple="false"
                  :disabled="invoice_information.status_id !== 1"
                  color="primary"
                  @click="addToQueue"
                >
                  Sıraya Ekle
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("tr");

export default {
  data() {
    return {
      visible: false,
      loading: false,
      result: null,
      currency_loading: false,
      invoice_information: null,
      invoice_information_template_json_data: null,
      invoice_information_json_data: null,
      invoice_date_formatted: null,
      show_invoice_date: null,
      template_data: {
        selected_game: null,
        is_dispatch: null,
        is_collected: null,
        parasut_product: null,
        parasut_customer: null,
        selected_tax: null,
        tags: [],
        categories: null,
        btsb_code: null,
      },
      invoice_data: {
        invoice_date: null,
        description: null,
        currency: null,
        exchange_rate: null,
        product: {
          amount: 0,
          unit: "Adet",
          price_per_unit: 0,
          total: 0,
        },
      },
      taxes: [20, 10, 1, 0],
      update_loading: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_data: {
      type: Object,
      default: null,
    },
    games: {
      type: Array,
      default: () => [],
    },
    customers: {
      type: Array,
      default: () => [],
    },
    parasut_products: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      immediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    row_data: {
      handler(newValue) {
        this.defaultSetter(newValue);
      },
      immediate: false,
    },
    computedSelectedCurrency: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue !== "TRL") {
            this.fetchCurrencyData(this.invoice_data.invoice_date);
          }
        }
      },
    },
  },
  methods: {
    addToQueue() {
      if (this.invoice_information.status_id === 1) {
        const requestData = {
          selected_ids: [this.invoice_information.id],
        };
        this.$api
          .post("parasut/invoices/add-to-queue", requestData)
          .then((response) => {
            if (response.status === 200) {
              this.$emit("addToQueue");
            }
          });
      }
    },
    allowedDates(value) {
      const moment_date = moment(value);
      return (
        moment_date.diff(moment(), "days") < 1 &&
        moment().diff(moment_date, "days") < 8 &&
        moment().add(1, "days").format("YYYY/MM/DD") !==
          moment_date.format("YYYY/MM/DD")
      );
    },
    handleDateChange(date) {
      this.show_invoice_date = false;
      this.invoice_date_formatted = moment(date).format("DD.MM.YYYY");
      this.fetchCurrencyData(date);
    },
    updateInvoice() {
      this.update_loading = true;

      const computedProduct = {
        amount: this.invoice_data.product.amount,
        unit: "Adet",
        price_per_unit: this.invoice_data.product.price_per_unit,
        total: this.invoice_data.product.total,
        total_summary: this.computedTotalPrice,
      };

      const parasut_product_title = this.parasut_products.filter(
        (x) => x.id === this.template_data.parasut_product
      )[0].attributes.name;

      const parasut_customer_title = this.customers.filter(
        (x) => x.id === this.template_data.parasut_customer
      )[0].attributes.name;

      var tempInvoiceData = { ...this.invoice_data };
      tempInvoiceData["product"] = computedProduct;

      const requestData = {
        invoice_id: this.invoice_information.id,
        invoice_data: JSON.stringify(tempInvoiceData),
        template_data: JSON.stringify({
          ...this.template_data,
          parasut_customer_title,
          parasut_product_title,
        }),
        game_id: this.template_data.selected_game,
      };

      this.$api
        .put("parasut/invoices/update", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.defaultSetter(response.data);
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Fatura başarıyla güncellendi.",
            });
            this.$emit("updated");
          }
          this.update_loading = false;
        })
        .catch((err) => {
          this.update_loading = false;
          console.log(err);
        });
    },
    defaultSetter(newValue) {
      this.invoice_information = newValue;
      this.template_data.selected_game = parseInt(newValue.game.id);
      this.template_data.btsb_code = newValue.game.btsb_code;
      this.template_data.is_dispatch = newValue.is_dispatch;
      this.template_data.is_collected = newValue.is_collected;
      this.template_data.tags = JSON.parse(newValue.tags);
      this.template_data.categories = newValue.categories;
      this.template_data.parasut_product = newValue.parasut_product;
      this.template_data.parasut_customer = newValue.parasut_customer;
      this.template_data.selected_tax = newValue.parasut_tax;

      this.invoice_data.description = newValue.invoice_description;
      this.invoice_data.exchange_rate = newValue.exchange_rate;
      this.invoice_data.currency = newValue.product_currency;
      this.invoice_data.invoice_date = newValue.issue_date;
      this.invoice_date_formatted = moment(
        this.invoice_data.invoice_date
      ).format("DD.MM.YYYY");

      this.invoice_data.product.amount = newValue.product_amount;
      this.invoice_data.product.price_per_unit =
        newValue.product_price_per_unit;
      this.invoice_data.product.total = newValue.total_price;
    },
    handleTotalChange() {
      if (this.invoice_data.product) {
        const amount = this.invoice_data.product.amount;
        const total = this.invoice_data.product.total;
        const tax = this.template_data.selected_tax;
        const calc = (total / (1 + tax / 100)) * (1 / amount);
        this.invoice_data.product.price_per_unit =
          Math.round(calc * 10000) / 10000;
      }
    },
    handleFormChange() {
      if (this.invoice_data.product) {
        const price_per_unit = this.invoice_data.product.price_per_unit;
        const amount = this.invoice_data.product.amount;
        const tax = this.template_data.selected_tax;
        const base_total = price_per_unit * amount;
        const base_total_w_tax = base_total + (base_total * tax) / 100;
        this.invoice_data.product.total =
          Math.round(base_total_w_tax * 100) / 100;
      }
    },
    fetchCurrencyData(date) {
      this.currency_loading = true;
      this.$api
        .get(`utils/fetch-currencies?date=${date}`)
        .then((response) => {
          if (response.status === 200) {
            const filtered_data = response.data.data.Tarih_Date.Currency.filter(
              (x) => x["@CurrencyCode"] === this.invoice_data.currency
            );
            this.invoice_data.exchange_rate =
              filtered_data.length > 0 ? filtered_data[0].ForexBuying : null;
          }
          this.currency_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.currency_loading = false;
        });
    },
    deleteInvoice() {
      const requestData = {
        data: {
          invoice_id: this.row_data.id,
        },
      };

      this.$api
        .delete("parasut/invoices/delete", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("deleted");
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Invoice successfully deleted.",
            });
          }
        });
    },
  },
  computed: {
    isBtnActivated() {
      if (
        this.games.length === 0 ||
        this.customers.length === 0 ||
        this.parasut_products.length === 0 ||
        this.categories.length === 0 ||
        this.tags.length === 0
      )
        return false;

      return true;
    },
    isUpdateBtnDisabled() {
      // Check Game Data
      if (
        this.template_data.selected_game !==
        parseInt(this.invoice_information.game.id)
      ) {
        return false;
      }

      // Check Is Dispatch
      if (
        this.template_data.is_dispatch !== this.invoice_information.is_dispatch
      ) {
        return false;
      }

      if (
        this.template_data.is_collected !==
        this.invoice_information.is_collected
      ) {
        return false;
      }

      // Check Product
      if (
        this.template_data.parasut_product !==
        this.invoice_information.parasut_product
      ) {
        return false;
      }

      // Check Customer
      if (
        this.template_data.parasut_customer !==
        this.invoice_information.parasut_customer
      ) {
        return false;
      }

      // Check Tax Amount
      if (
        this.template_data.selected_tax !== this.invoice_information.parasut_tax
      ) {
        return false;
      }

      // Check Date
      if (
        this.invoice_data.invoice_date !== this.invoice_information.issue_date
      ) {
        return false;
      }

      // Check Description
      if (
        this.invoice_data.description !==
        this.invoice_information.invoice_description
      ) {
        return false;
      }

      // Check Currency
      if (
        this.invoice_data.currency !== this.invoice_information.product_currency
      ) {
        return false;
      }

      // Check Amount
      if (
        parseInt(this.invoice_data.product.amount) !==
        this.invoice_information.product_amount
      ) {
        return false;
      }

      // Check Price Per Unit
      if (
        parseFloat(this.invoice_data.product.price_per_unit) !==
        this.invoice_information.product_price_per_unit
      ) {
        return false;
      }

      // Check Total
      if (
        parseFloat(this.invoice_data.product.total) !==
        this.invoice_information.total_price
      ) {
        return false;
      }

      // Check Category

      if (
        this.template_data.categories !== this.invoice_information.categories
      ) {
        return false;
      }

      // Check Tags
      const orig_tags = JSON.parse(this.invoice_information.tags);
      const form_tags = [...this.template_data.tags];

      if (
        JSON.stringify(orig_tags.sort()) !== JSON.stringify(form_tags.sort())
      ) {
        return false;
      }

      return true;
    },
    computedPriceWithoutTaxes() {
      return (
        Math.round(
          this.invoice_data.product.amount *
            this.invoice_data.product.price_per_unit *
            100
        ) / 100
      );
    },
    computedTaxPrice() {
      const price_wout_taxes =
        this.invoice_data.product.amount *
        this.invoice_data.product.price_per_unit;

      return (
        Math.round(
          ((price_wout_taxes * this.template_data.selected_tax) / 100) * 100
        ) / 100
      );
    },
    computedTotalPrice() {
      return this.computedPriceWithoutTaxes + this.computedTaxPrice;
    },
    computedTotalPriceWithExchange() {
      if (this.invoice_data.exchange_rate) {
        return (
          Math.round(
            this.computedTotalPrice *
              parseFloat(this.invoice_data.exchange_rate) *
              100
          ) / 100
        );
      }
      return null;
    },
    computedSelectedCurrency() {
      return this.invoice_data.currency;
    },
    computedSelectedCurrencySymbol() {
      if (this.computedSelectedCurrency) {
        if (this.computedSelectedCurrency === "USD") return "$";
        if (this.computedSelectedCurrency === "TRL") return "₺";
        if (this.computedSelectedCurrency === "EUR") return "€";
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
