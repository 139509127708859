<!-- eslint-disable -->
<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">
        Burcha - Create Playable Asset
      </h1>

      <div class="mt-5">
        <div>
          <v-autocomplete
            hide-details
            class="text-[13px]"
            label="Choose a network"
            outlined
            clearable
            dense
            :items="['Facebook', 'Google', 'Unity', 'Applovin']"
            v-model="selected_network"
            :loading="loading"
          >
          </v-autocomplete>
        </div>
        <div class="mt-3" v-if="selected_network === 'Facebook'">
          <div>
            <v-autocomplete
              hide-details
              class="text-[13px]"
              label="Choose an ACT network"
              outlined
              clearable
              dense
              item-text="base_title"
              item-value="id"
              :items="act_networks"
              v-model="selected_act_network"
              :loading="loading"
            >
            </v-autocomplete>
          </div>
          <div class="mt-3">
            <v-text-field
              label="Title"
              outlined
              v-model="title"
              dense
              hide-details
              class="text-[13px]"
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-file-input
              show-size
              accept="application/zip"
              label="Asset"
              class="text-[13px]"
              outlined
              dense
              hide-details
              v-model="selected_asset"
              :disabled="!selected_network"
              :loading="loading"
            >
            </v-file-input>
          </div>
        </div>

        <div class="mt-3" v-if="['Unity', 'Google'].includes(selected_network)">
          <div>
            <v-autocomplete
              hide-details
              class="text-[13px]"
              label="Select a game"
              outlined
              clearable
              dense
              item-text="title"
              item-value="id"
              :items="games"
              v-model="selected_game"
              :loading="loading"
            >
            </v-autocomplete>
          </div>
          <div class="mt-3">
            <v-text-field
              label="Title"
              outlined
              v-model="title"
              dense
              hide-details
              class="text-[13px]"
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-file-input
              show-size
              :accept="
                selected_network === 'Google' ? 'application/zip' : '.html'
              "
              label="Playable HTML"
              class="text-[13px]"
              outlined
              dense
              hide-details
              v-model="selected_asset"
              :disabled="!selected_game"
              :loading="loading"
            >
            </v-file-input>
          </div>
        </div>

        <div class="mt-3" v-if="selected_network === 'Mintegral'">
          <div>
            <v-autocomplete
              hide-details
              class="text-[13px]"
              label="Select a game"
              outlined
              clearable
              dense
              item-text="title"
              item-value="id"
              :items="games"
              v-model="selected_game"
              :loading="loading"
            >
            </v-autocomplete>
          </div>
          <div class="mt-3">
            <v-text-field
              label="Title"
              outlined
              v-model="title"
              dense
              hide-details
              class="text-[13px]"
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-file-input
              show-size
              accept="application/zip"
              label="Asset"
              class="text-[13px]"
              outlined
              dense
              hide-details
              v-model="selected_asset"
              :disabled="!selected_game"
              :loading="loading"
            >
            </v-file-input>
          </div>
        </div>

        <div class="mt-3" v-if="selected_network === 'Applovin'">
          <div>
            <v-autocomplete
              hide-details
              class="text-[13px]"
              label="Select a game"
              outlined
              clearable
              dense
              item-text="title"
              item-value="id"
              :items="games"
              v-model="selected_game"
              :loading="loading"
            >
            </v-autocomplete>
          </div>
          <div v-if="selected_game">
            <div>
              <v-radio-group
                style="font-size: 12px"
                v-model="applovin_asset_type"
                row
                dense
                hide-details
              >
                <v-radio label="HTML (Playable)" value="html"></v-radio>
                <v-radio label="Image" value="image"></v-radio>
              </v-radio-group>
            </div>
            <div v-if="applovin_asset_type === 'html'">
              <div class="mt-3">
                <v-text-field
                  label="Title"
                  outlined
                  v-model="title"
                  dense
                  hide-details
                  class="text-[13px]"
                ></v-text-field>
              </div>
              <div class="mt-3">
                <v-file-input
                  show-size
                  accept=".html"
                  label="Asset"
                  class="text-[13px]"
                  outlined
                  dense
                  hide-details
                  v-model="selected_asset"
                  :disabled="!selected_game"
                  :loading="loading"
                >
                </v-file-input>
              </div>
            </div>
            <div v-if="applovin_asset_type === 'image'">
              <div class="mt-3">
                <v-file-input
                  show-size
                  accept="image/*"
                  label="Images"
                  class="text-[13px]"
                  outlined
                  dense
                  hide-details
                  v-model="selected_asset"
                  :disabled="!selected_game"
                  :loading="loading"
                  multiple
                >
                </v-file-input>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 flex items-center justify-end">
          <v-btn
            color="primary"
            @click="createAsset"
            depressed
            class="font-semibold"
            :disabled="
              selected_network !== 'Applovin'
                ? !selected_asset || !title
                : !isApplovinSubmitBtnEnabled
            "
            :loading="loading"
          >
            Submit
          </v-btn>
        </div>
      </div>

      <div v-if="selected_network">
        <h1 class="font-semibold text-[12px] mb-2">Uploaded Playables</h1>
        <div class="mb-2">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            dense
            outlined
            hide-details
            class="text-[12px]"
          ></v-text-field>
        </div>
        <div class="border rounded-[3px]">
          <v-data-table
            :headers="headers"
            :items="tableData"
            :items-per-page="15"
            :loading="tableLoading"
            :search="search"
          >
            <template v-slot:[`item.id`]="{ item }">
              <span class="text-gray-800 text-[12px] font-semibold">
                {{ item.id }}
              </span>
            </template>

            <template v-slot:[`item.title`]="{ item }">
              <span class="text-gray-800 text-[12px] font-semibold">
                {{ item.title }}
              </span>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      selected_network: null,
      selected_asset: null,
      selected_act_network: null,
      loading: false,
      act_networks: [],
      games: [],
      selected_game: null,
      title: "",
      applovin_asset_type: "html",
      headers: [
        { text: "ID", value: "id" },
        { text: "Title", value: "title" },
      ],
      tableData: [],
      tableLoading: false,
    };
  },
  methods: {
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async createAsset() {
      try {
        this.loading = true;
        if (this.selected_asset && this.title) {
          if (this.selected_network.toLowerCase() === "facebook") {
            if (!this.selected_act_network) return;

            let formData = new FormData();
            formData.append("asset", this.selected_asset);
            formData.append("title", this.title);
            formData.append("selected_act_network", this.selected_act_network);

            const request = await this.$api.post(
              "burcha/playables/fb/create",
              formData
            );

            if (request.status === 200) {
              this.$toast.success("Asset successfully created...", {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              });
              this.selected_asset = null;
              this.selected_network = null;
            }
          }

          if (
            ["unity", "google"].includes(this.selected_network.toLowerCase())
          ) {
            if (!this.selected_game) return;

            let formData = new FormData();
            formData.append("asset", this.selected_asset);
            formData.append("title", this.title);
            formData.append("selected_game", this.selected_game);

            const request = await this.$api.post(
              `burcha/playables/${this.selected_network.toLowerCase()}/create`,
              formData
            );

            if (request.status === 200) {
              this.$toast.success("Asset successfully created...", {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              });
              this.selected_asset = null;
              this.selected_game = null;
            }
          }

          if (this.selected_network.toLowerCase() === "mintegral") {
            if (!this.selected_game) return;

            let formData = new FormData();
            formData.append("asset", this.selected_asset);
            formData.append("title", this.title);
            formData.append("selected_game", this.selected_game);

            const request = await this.$api.post(
              "burcha/playables/mintegral/create",
              formData
            );

            if (request.status === 200) {
              this.$toast.success("Asset successfully created...", {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              });
              this.selected_asset = null;
              this.selected_game = null;
            }
          }

          if (this.selected_network.toLowerCase() === "applovin") {
            if (!this.selected_game) return;

            let formData = new FormData();

            formData.append("selected_game", this.selected_game);

            if (this.applovin_asset_type === "html") {
              formData.append("html", this.selected_asset);
              formData.append("title", this.title);
            }

            if (this.applovin_asset_type === "image") {
              for (let i = 0; i < this.selected_asset.length; i++) {
                const image = this.selected_asset[i];
                formData.append("images", image);
              }
            }
            const request = await this.$api.post(
              "burcha/applovin/assets/create",
              formData
            );

            if (request.status === 200) {
              this.$toast.success("Asset successfully created...", {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              });
              this.selected_asset = null;
              this.selected_game = null;
            }
          }
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchPlayables() {
      try {
        if (!this.selected_network) return;

        this.tableLoading = true;
        const request = await this.$api.get(
          `burcha/playables/${this.selected_network.toLowerCase()}/all`
        );
        this.tableData = request.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.tableLoading = false;
      }
    },
    async fetchActNetworks() {
      try {
        const request = await this.$api.get(`act/network/all?type=Facebook`);
        this.act_networks = request.data.map((x) => ({
          ...x,
          base_title: `${x.type} - ${x.game.title} - ${x.title}`,
        }));
      } catch (error) {
        this.errorHandler(error);
      }
    },
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    selected_network(newValue) {
      if (newValue === "Facebook") {
        this.fetchActNetworks();
      }

      if (newValue === "Unity") {
        this.fetchGames();
      }

      if (newValue === "Mintegral") {
        this.fetchGames();
      }

      if (newValue === "Applovin") {
        this.fetchGames();
      }

      if (newValue) {
        this.fetchPlayables();
      }
    },
    applovin_asset_type() {
      this.selected_asset = null;
    },
  },
  computed: {
    isApplovinSubmitBtnEnabled() {
      if (!this.selected_network) return false;
      if (!this.selected_game) return false;
      if (!this.selected_asset) return false;

      if (this.applovin_asset_type === "html") {
        if (!this.title) return false;
      }

      if (this.applovin_asset_type === "image") {
        if (this.selected_asset && !this.selected_asset.length) return false;
      }
      return true;
    },
  },
  mounted() {
    this.fetchGames();
  },
};
</script>

<style lang="scss" scoped></style>
