import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";

import HomeView from "../views/HomeView.vue";
import LoginView from "../views/shared/LoginView.vue";

// Admin
import { admin_router } from "./admin";

// CCC
import { ccc_router } from "./ccc-router";
// ACT
import { act_router } from "./act-router";
// ACG
import { acg_router } from "./acg-router";

// Facebook
import { facebook_router } from "./ccc-router/facebook/index";
// TikTok
import { tiktok_router } from "./ccc-router/tiktok/index";

// Utility Tools
import { utility_router } from "./utility-tools";
import { parasut_router } from "./parasut-router";
import { icon_tester_router } from "./icon-tester-router";
import { firebase_router } from "./firebase-ab-tests-router";

// Campaign Manager
import { campaign_manager_router } from "./campaign-manager-router";

// Logs
import { audit_logs_router } from "./audit-logs";

import { game_backend_router } from "./game-backend-router";
import { cpa_log_router } from "./cpa-logs";
import { iap_pricing_ui_router } from "./iap-pricing-ui";

// Store
// import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Dashboard",
    },
  },
  // Shared Router
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Login to continue",
    },
  },
  ...ccc_router,
  ...facebook_router,
  ...tiktok_router,
  ...admin_router,
  ...act_router,
  ...acg_router,
  ...utility_router,
  ...parasut_router,
  ...icon_tester_router,
  ...firebase_router,
  ...campaign_manager_router,
  ...audit_logs_router,
  ...game_backend_router,
  ...cpa_log_router,
  ...iap_pricing_ui_router,
];

let router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const cookie = Cookies.get("gaia_sid");

  if (to.meta.title) {
    document.title = `${to.meta.title} - APPS`;
  }

  if (!cookie && to.path !== "/login") {
    next("/login");
    // } else {
    //   const userProfile = store.getters.getUserProfile;
    //   if (!userProfile && !["/", "/login"].includes(to.path)) {
    //     window.location = "/";
    //   } else {
    //     if (to.meta?.departmentsAllowed && to.meta?.tool) {
    //       if (!to.meta.departmentsAllowed.includes(userProfile.department.id)) {
    //         return {
    //           name: "home",
    //         };
    //       } else if (!userProfile.tools.some((x) => x.id === to.meta.tool)) {
    //         return {
    //           name: "home",
    //         };
    //       }
    //     }
    //     next();
    //   }
  } else {
    next();
  }
});

export default router;
