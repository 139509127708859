import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Modules
import users from "./user/index";
import apps from "./apps/index";
import tools from "./tools/index";
import upload_assets from "./upload_assets/index";
import acg_preview_videos from "./acg_preview_videos/index";
import instances from "./instances/index";
import act from "./act/index";
import parasut from "./tools/parasut/index";
import burcha from "./tools/burcha/index";
import cpa_sheet from "./cpa_sheet";
import game_backend from "./game_backend";

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    apps,
    tools,
    upload_assets,
    acg_preview_videos,
    instances,
    act,
    parasut,
    burcha,
    cpa_sheet,
    game_backend,
  },
});
