<template>
  <div>
    <div class="bg-white p-6 w-full">
      <h1 class="font-semibold text-[18px] pb-6">Max Waterfall Operator</h1>
      <div v-if="networks.length">
        <h1 class="text-[12px] font-semibold">1. Select a game</h1>
        <div class="mt-3">
          <v-autocomplete
            hide-details
            class="text-[13px]"
            item-text="title"
            return-object
            label="Game"
            :loading="loadingGames"
            outlined
            clearable
            dense
            :items="games"
            v-model="selectedGame"
            hide-no-data
          >
          </v-autocomplete>
        </div>

        <div class="mt-4">
          <h1 class="text-[12px] font-semibold">2. Select a platform</h1>
          <v-radio-group
            row
            v-model="selectedPlatform"
            :disabled="!selectedGame"
            hide-details
          >
            <v-radio label="Android" value="android"></v-radio>
            <v-radio label="iOS" value="ios"></v-radio>
          </v-radio-group>
        </div>

        <!-- <div v-if="adUnitsLoading || adUnitLoading">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div> -->

        <div class="mt-4">
          <h1 class="text-[12px] font-semibold">3. Select Ad Format</h1>

          <div class="mt-3">
            <v-select
              hide-details
              class="text-[13px]"
              item-text="ad_format"
              label="Ad Format"
              return-object
              :loading="adUnitsLoading"
              :disabled="adUnitsLoading || !selectedGame || !selectedPlatform"
              outlined
              clearable
              dense
              :items="adUnits"
              v-model="selectedAdUnit"
              hide-no-data
            >
            </v-select>
          </div>
        </div>

        <div class="mt-4">
          <h1 class="text-[12px] font-semibold">4. Select Waterfall</h1>

          <div class="mt-3">
            <v-select
              hide-details
              class="text-[13px]"
              item-text="name"
              label="Waterfall"
              return-object
              :loading="adUnitsLoading"
              :disabled="!selectedAdUnit ? true : false"
              outlined
              clearable
              dense
              :items="computedWaterfallData"
              v-model="selectedWaterfall"
              hide-no-data
            >
            </v-select>
          </div>
        </div>

        <div class="my-4">
          <div v-if="adUnitLoading">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </div>
          <div v-else-if="!adUnitLoading && selectedAdUnit && networkSettings">
            <div>
              <h1 class="text-[12px] font-semibold">5. Operation Type</h1>
              <v-radio-group row v-model="operationType" hide-details>
                <v-radio label="With CSV" value="csv"></v-radio>
                <v-radio label="With Form" value="form"></v-radio>
              </v-radio-group>
            </div>
            <div v-if="operationType === 'form'">
              <div>
                <div class="flex items-center justify-between my-4">
                  <h1 class="text-[13px] text-indigo-600 font-semibold">
                    Bidder Networks
                  </h1>

                  <div>
                    <v-btn
                      :ripple="false"
                      color="primary"
                      text
                      @click="showBidderInsertForm = true"
                    >
                      New Placement
                    </v-btn>

                    <v-btn
                      class="ml-2"
                      :ripple="false"
                      color="success"
                      text
                      @click="fetchAdUnitDetail(false)"
                    >
                      Refresh Placements
                    </v-btn>
                  </div>
                </div>

                <v-data-table
                  :items-per-page="15"
                  :headers="formHeadersBidder"
                  :items="bidderNetworks"
                  sort-by="network"
                  :loading="tableLoading"
                >
                  <template v-slot:[`item.network`]="{ item }">
                    <div class="flex items-center">
                      <span
                        v-if="item.mode === 'add'"
                        class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-lime-100 text-lime-700"
                      >
                        ADD
                      </span>
                      <span
                        v-if="item.mode === 'delete'"
                        class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-rose-100 text-rose-700"
                      >
                        REMOVE
                      </span>
                      <div class="font-semibold text-[11px] flex items-center">
                        {{ mappedNetworkName(item.network) }}
                        <span
                          class="w-[8px] h-[8px] rounded-full ml-1.5"
                          :class="{
                            'bg-lime-500': !item.disabled,
                            'bg-rose-500': item.disabled,
                          }"
                        ></span>
                      </div>
                    </div>
                  </template>

                  <template v-slot:[`item.ad_network_app_id`]="{ item }">
                    <span
                      class="font-semibold text-[11px]"
                      v-if="!item.editable"
                      :class="{
                        'text-blue-600':
                          item.ad_network_app_id !==
                          item.originalData.ad_network_app_id,
                      }"
                    >
                      {{
                        item.ad_network_app_id ? item.ad_network_app_id : "-"
                      }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.ad_network_app_id"
                        dense
                        hide-details
                        class="text-[13px] w-[200px]"
                        placeholder="App ID"
                      ></v-text-field>
                    </div>
                  </template>

                  <template v-slot:[`item.ad_network_app_key`]="{ item }">
                    <span
                      class="font-semibold text-[11px]"
                      :class="{
                        'text-blue-600':
                          item.ad_network_app_key !==
                          item.originalData.ad_network_app_key,
                      }"
                      v-if="!item.editable"
                    >
                      {{
                        item.ad_network_app_key ? item.ad_network_app_key : "-"
                      }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.ad_network_app_key"
                        dense
                        hide-details
                        class="text-[13px] w-[200px]"
                        placeholder="App Key"
                      ></v-text-field>
                    </div>
                  </template>

                  <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                    <span
                      class="font-semibold text-[11px] max-w-[240px] overflow-hidden text-ellipsis inline-block"
                      v-if="!item.editable"
                      :class="{
                        'text-blue-600':
                          item.ad_network_ad_unit_id !==
                          item.originalData.ad_network_ad_unit_id,
                      }"
                    >
                      {{
                        item.ad_network_ad_unit_id
                          ? item.ad_network_ad_unit_id
                          : "-"
                      }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.ad_network_ad_unit_id"
                        dense
                        hide-details
                        class="text-[13px] w-[200px]"
                        placeholder="Ad Unit ID"
                      ></v-text-field>
                    </div>
                  </template>

                  <template
                    v-slot:[`item.ad_network_optional_placement_id`]="{ item }"
                  >
                    <span
                      class="font-semibold text-[11px] max-w-[140px] overflow-hidden text-ellipsis inline-block"
                      v-if="!item.editable"
                      :class="{
                        'text-blue-600':
                          item.ad_network_optional_placement_id !==
                          item.originalData.ad_network_optional_placement_id,
                      }"
                    >
                      {{
                        item.ad_network_optional_placement_id
                          ? item.ad_network_optional_placement_id
                          : "-"
                      }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.ad_network_optional_placement_id"
                        dense
                        hide-details
                        class="text-[13px] w-[200px]"
                        placeholder="Optional Placement ID"
                      ></v-text-field>
                    </div>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <div>
                      <v-icon
                        color="primary"
                        class="mr-3"
                        @click="item.editable = !item.editable"
                        :disabled="item.mode === 'delete'"
                      >
                        {{ !item.editable ? "mdi-pencil" : "mdi-check" }}
                      </v-icon>
                      <v-icon
                        color="error"
                        @click="item.mode = 'delete'"
                        v-if="item.mode !== 'delete'"
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                        v-if="item.mode === 'delete'"
                        color="error"
                        @click="item.mode = item.originalData.mode"
                      >
                        mdi-undo
                      </v-icon>
                    </div>
                  </template>
                </v-data-table>
              </div>

              <div>
                <h1 class="text-[13px] text-indigo-600 my-4 font-semibold">
                  Non Bidder Networks
                </h1>

                <v-data-table
                  :items-per-page="15"
                  :headers="formHeaders"
                  :items="nonbidderNetworks"
                  sort-by="network"
                  :loading="tableLoading"
                >
                  <template v-slot:[`item.network`]="{ item }">
                    <div class="flex items-center">
                      <span
                        v-if="item.mode === 'add'"
                        class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-lime-100 text-lime-700"
                      >
                        ADD
                      </span>
                      <span
                        v-if="item.mode === 'delete'"
                        class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-rose-100 text-rose-700"
                      >
                        REMOVE
                      </span>
                      <div class="font-semibold text-[11px] flex items-center">
                        {{ mappedNetworkName(item.network) }}
                        <span
                          class="w-[8px] h-[8px] rounded-full ml-1.5"
                          :class="{
                            'bg-lime-500': !item.disabled,
                            'bg-rose-500': item.disabled,
                          }"
                        ></span>
                      </div>
                    </div>
                  </template>

                  <template v-slot:[`item.ad_network_app_id`]="{ item }">
                    <span
                      class="font-semibold text-[11px]"
                      v-if="!item.editable"
                      :class="{
                        'text-blue-600':
                          item.ad_network_app_id !==
                          item.originalData.ad_network_app_id,
                      }"
                    >
                      {{
                        item.ad_network_app_id ? item.ad_network_app_id : "-"
                      }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.ad_network_app_id"
                        dense
                        hide-details
                        class="text-[13px] w-[200px]"
                        placeholder="App ID"
                      ></v-text-field>
                    </div>
                  </template>

                  <template v-slot:[`item.ad_network_app_key`]="{ item }">
                    <span
                      class="font-semibold text-[11px]"
                      :class="{
                        'text-blue-600':
                          item.ad_network_app_key !==
                          item.originalData.ad_network_app_key,
                      }"
                      v-if="!item.editable"
                    >
                      {{
                        item.ad_network_app_key ? item.ad_network_app_key : "-"
                      }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.ad_network_app_key"
                        dense
                        hide-details
                        class="text-[13px] w-[200px]"
                        placeholder="App Key"
                      ></v-text-field>
                    </div>
                  </template>

                  <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                    <span
                      class="font-semibold text-[11px] max-w-[240px] overflow-hidden text-ellipsis inline-block"
                      v-if="!item.editable"
                      :class="{
                        'text-blue-600':
                          item.ad_network_ad_unit_id !==
                          item.originalData.ad_network_ad_unit_id,
                      }"
                    >
                      {{
                        item.ad_network_ad_unit_id
                          ? item.ad_network_ad_unit_id
                          : "-"
                      }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.ad_network_ad_unit_id"
                        dense
                        hide-details
                        class="text-[13px] w-[200px]"
                        placeholder="Placement ID"
                      ></v-text-field>
                    </div>
                  </template>

                  <template
                    v-slot:[`item.ad_network_optional_placement_id`]="{ item }"
                  >
                    <span
                      class="font-semibold text-[11px] max-w-[140px] overflow-hidden text-ellipsis inline-block"
                      v-if="!item.editable"
                      :class="{
                        'text-blue-600':
                          item.ad_network_optional_placement_id !==
                          item.originalData.ad_network_optional_placement_id,
                      }"
                    >
                      {{
                        item.ad_network_optional_placement_id
                          ? item.ad_network_optional_placement_id
                          : "-"
                      }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.ad_network_optional_placement_id"
                        dense
                        hide-details
                        class="text-[13px] w-[200px]"
                        placeholder="Optional Placement ID"
                      ></v-text-field>
                    </div>
                  </template>

                  <template v-slot:[`item.cpm`]="{ item }">
                    <span
                      class="font-semibold text-[11px]"
                      v-if="!item.editable"
                      :class="{
                        'text-blue-600': item.cpm !== item.originalData.cpm,
                      }"
                    >
                      {{ item.cpm ? item.cpm : "-" }}
                    </span>
                    <div v-else>
                      <v-text-field
                        outlined
                        v-model="item.cpm"
                        dense
                        hide-details
                        class="text-[13px] w-[110px]"
                        placeholder="Cpm"
                        type="number"
                      ></v-text-field>
                    </div>
                  </template>

                  <template v-slot:[`item.country_type`]="{ item }">
                    <span
                      class="font-semibold text-[11px]"
                      v-if="!item.editable"
                      :class="{
                        'text-blue-600':
                          item.country_type !== item.originalData.country_type,
                      }"
                    >
                      {{ item.country_type ? item.country_type : "-" }}
                    </span>
                    <div v-else>
                      <v-select
                        outlined
                        v-model="item.country_type"
                        dense
                        hide-details
                        :items="['INCLUDE', 'EXCLUDE']"
                        class="text-[13px] w-[125px]"
                        placeholder="Country Type"
                      ></v-select>
                    </div>
                  </template>

                  <template v-slot:[`item.countries`]="{ item }">
                    <div v-if="!item.editable">
                      <div v-if="item.countries.length">
                        <span
                          class="font-semibold text-[11px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                          v-for="(country, index) in item.countries"
                          :key="index"
                        >
                          {{ country }}
                        </span>
                      </div>

                      <div v-else>
                        <span class="font-semibold text-[11px]"> - </span>
                      </div>
                    </div>

                    <div v-else>
                      <v-combobox
                        hide-details
                        class="text-[12px] w-[200px]"
                        :label="`Countries`"
                        outlined
                        multiple
                        dense
                        v-model="item.countries"
                        :items="
                          computedCountries.map((x) => x.code.toLowerCase())
                        "
                      >
                      </v-combobox>
                    </div>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <div>
                      <v-icon
                        color="primary"
                        class="mr-3"
                        @click="item.editable = !item.editable"
                        :disabled="item.mode === 'delete'"
                      >
                        {{ !item.editable ? "mdi-pencil" : "mdi-check" }}
                      </v-icon>
                      <v-icon
                        color="error"
                        @click="item.mode = 'delete'"
                        v-if="item.mode !== 'delete'"
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                        v-if="item.mode === 'delete'"
                        color="error"
                        @click="item.mode = item.originalData.mode"
                      >
                        mdi-undo
                      </v-icon>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>
            <div v-if="operationType === 'csv'" class="mt-5">
              <div class="mt-3">
                <v-file-input
                  show-size
                  accept=".csv"
                  label="Upload CSV"
                  class="text-[13px]"
                  outlined
                  dense
                  hide-details
                  v-model="csvFile"
                ></v-file-input>
              </div>
              <div v-if="csvData && csvData.length">
                <div>
                  <div class="flex items-center justify-between my-4">
                    <h1 class="text-[13px] text-indigo-600 font-semibold">
                      Bidder Networks
                    </h1>

                    <div>
                      <v-btn
                        :ripple="false"
                        color="primary"
                        text
                        @click="showBidderInsertForm = true"
                      >
                        New Placement
                      </v-btn>

                      <v-btn
                        class="ml-2"
                        :ripple="false"
                        color="success"
                        text
                        @click="fetchAdUnitDetail(true)"
                      >
                        Refresh Placements
                      </v-btn>
                    </div>
                  </div>

                  <v-data-table
                    :items-per-page="15"
                    :headers="formHeadersBidder"
                    :items="csvData.filter((x) => !x.cpm)"
                    sort-by="network"
                    :loading="tableLoading"
                  >
                    <template v-slot:[`item.network`]="{ item }">
                      <div class="flex items-center">
                        <span
                          v-if="item.mode === 'add'"
                          class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-lime-100 text-lime-700"
                        >
                          ADD
                        </span>
                        <span
                          v-if="item.mode === 'delete'"
                          class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-rose-100 text-rose-700"
                        >
                          REMOVE
                        </span>
                        <span
                          v-if="item.mode === 'off'"
                          class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-orange-100 text-orange-700"
                        >
                          OFF
                        </span>
                        <div
                          class="font-semibold text-[11px] flex items-center"
                        >
                          {{ mappedNetworkName(item.network) }}
                          <span
                            class="w-[8px] h-[8px] rounded-full ml-1.5"
                            :class="{
                              'bg-lime-500': !item.originalData.disabled,
                              'bg-rose-500': item.originalData.disabled,
                            }"
                          ></span>
                        </div>
                      </div>
                    </template>

                    <template v-slot:[`item.ad_network_app_id`]="{ item }">
                      <span
                        class="font-semibold text-[11px]"
                        v-if="!item.editable"
                        :class="{
                          'text-blue-600':
                            item.ad_network_app_id !==
                            item.originalData.ad_network_app_id,
                        }"
                      >
                        {{
                          item.ad_network_app_id ? item.ad_network_app_id : "-"
                        }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.ad_network_app_id"
                          dense
                          hide-details
                          class="text-[13px] w-[200px]"
                          placeholder="App ID"
                        ></v-text-field>
                      </div>
                    </template>

                    <template v-slot:[`item.ad_network_app_key`]="{ item }">
                      <span
                        class="font-semibold text-[11px]"
                        :class="{
                          'text-blue-600':
                            item.ad_network_app_key !==
                            item.originalData.ad_network_app_key,
                        }"
                        v-if="!item.editable"
                      >
                        {{
                          item.ad_network_app_key
                            ? item.ad_network_app_key
                            : "-"
                        }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.ad_network_app_key"
                          dense
                          hide-details
                          class="text-[13px] w-[200px]"
                          placeholder="App Key"
                        ></v-text-field>
                      </div>
                    </template>

                    <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                      <span
                        class="font-semibold text-[11px] max-w-[240px] overflow-hidden text-ellipsis inline-block"
                        v-if="!item.editable"
                        :class="{
                          'text-blue-600':
                            item.ad_network_ad_unit_id !==
                            item.originalData.ad_network_ad_unit_id,
                        }"
                      >
                        {{
                          item.ad_network_ad_unit_id
                            ? item.ad_network_ad_unit_id
                            : "-"
                        }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.ad_network_ad_unit_id"
                          dense
                          hide-details
                          class="text-[13px] w-[200px]"
                          placeholder="Placement ID"
                        ></v-text-field>
                      </div>
                    </template>

                    <template
                      v-slot:[`item.ad_network_optional_placement_id`]="{
                        item,
                      }"
                    >
                      <span
                        class="font-semibold text-[11px] max-w-[140px] overflow-hidden text-ellipsis inline-block"
                        v-if="!item.editable"
                        :class="{
                          'text-blue-600':
                            item.ad_network_optional_placement_id !==
                            item.originalData.ad_network_optional_placement_id,
                        }"
                      >
                        {{
                          item.ad_network_optional_placement_id
                            ? item.ad_network_optional_placement_id
                            : "-"
                        }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.ad_network_optional_placement_id"
                          dense
                          hide-details
                          class="text-[13px] w-[200px]"
                          placeholder="Optional Placement ID"
                        ></v-text-field>
                      </div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <div>
                        <v-icon
                          color="primary"
                          class="mr-3"
                          @click="item.editable = !item.editable"
                          :disabled="item.mode === 'delete'"
                        >
                          {{ !item.editable ? "mdi-pencil" : "mdi-check" }}
                        </v-icon>
                        <v-icon
                          color="error"
                          @click="item.mode = 'delete'"
                          v-if="item.mode !== 'delete'"
                        >
                          mdi-delete
                        </v-icon>
                        <v-icon
                          v-if="item.mode === 'delete'"
                          color="error"
                          @click="item.mode = item.originalData.mode"
                        >
                          mdi-undo
                        </v-icon>
                      </div>
                    </template>
                  </v-data-table>
                </div>

                <div>
                  <h1 class="text-[13px] text-indigo-600 my-4 font-semibold">
                    Non Bidder Networks
                  </h1>

                  <v-data-table
                    :items-per-page="15"
                    :headers="formHeaders"
                    :items="csvData.filter((x) => x.cpm)"
                    sort-by="network"
                    :loading="tableLoading"
                  >
                    <template v-slot:[`item.network`]="{ item }">
                      <div class="flex items-center">
                        <span
                          v-if="item.mode === 'add'"
                          class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-lime-100 text-lime-700"
                        >
                          ADD
                        </span>
                        <span
                          v-if="item.mode === 'delete'"
                          class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-rose-100 text-rose-700"
                        >
                          REMOVE
                        </span>
                        <span
                          v-if="item.mode === 'off'"
                          class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-orange-100 text-orange-700"
                        >
                          OFF
                        </span>
                        <div
                          class="font-semibold text-[11px] flex items-center"
                        >
                          {{ mappedNetworkName(item.network) }}
                          <span
                            class="w-[8px] h-[8px] rounded-full ml-1.5"
                            :class="{
                              'bg-lime-500': !item.originalData.disabled,
                              'bg-rose-500': item.originalData.disabled,
                            }"
                          ></span>
                        </div>
                      </div>
                    </template>

                    <template v-slot:[`item.ad_network_app_id`]="{ item }">
                      <span
                        class="font-semibold text-[11px]"
                        v-if="!item.editable"
                        :class="{
                          'text-blue-600':
                            item.ad_network_app_id !==
                            item.originalData.ad_network_app_id,
                        }"
                      >
                        {{
                          item.ad_network_app_id ? item.ad_network_app_id : "-"
                        }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.ad_network_app_id"
                          dense
                          hide-details
                          class="text-[13px] w-[200px]"
                          placeholder="App ID"
                        ></v-text-field>
                      </div>
                    </template>

                    <template v-slot:[`item.ad_network_app_key`]="{ item }">
                      <span
                        class="font-semibold text-[11px]"
                        :class="{
                          'text-blue-600':
                            item.ad_network_app_key !==
                            item.originalData.ad_network_app_key,
                        }"
                        v-if="!item.editable"
                      >
                        {{
                          item.ad_network_app_key
                            ? item.ad_network_app_key
                            : "-"
                        }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.ad_network_app_key"
                          dense
                          hide-details
                          class="text-[13px] w-[200px]"
                          placeholder="App Key"
                        ></v-text-field>
                      </div>
                    </template>

                    <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                      <span
                        class="font-semibold text-[11px] max-w-[240px] overflow-hidden text-ellipsis inline-block"
                        v-if="!item.editable"
                        :class="{
                          'text-blue-600':
                            item.ad_network_ad_unit_id !==
                            item.originalData.ad_network_ad_unit_id,
                        }"
                      >
                        {{
                          item.ad_network_ad_unit_id
                            ? item.ad_network_ad_unit_id
                            : "-"
                        }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.ad_network_ad_unit_id"
                          dense
                          hide-details
                          class="text-[13px] w-[200px]"
                          placeholder="Placement ID"
                        ></v-text-field>
                      </div>
                    </template>

                    <template
                      v-slot:[`item.ad_network_optional_placement_id`]="{
                        item,
                      }"
                    >
                      <span
                        class="font-semibold text-[11px] max-w-[140px] overflow-hidden text-ellipsis inline-block"
                        v-if="!item.editable"
                        :class="{
                          'text-blue-600':
                            item.ad_network_optional_placement_id !==
                            item.originalData.ad_network_optional_placement_id,
                        }"
                      >
                        {{
                          item.ad_network_optional_placement_id
                            ? item.ad_network_optional_placement_id
                            : "-"
                        }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.ad_network_optional_placement_id"
                          dense
                          hide-details
                          class="text-[13px] w-[200px]"
                          placeholder="Optional Placement ID"
                        ></v-text-field>
                      </div>
                    </template>

                    <template v-slot:[`item.cpm`]="{ item }">
                      <span
                        class="font-semibold text-[11px]"
                        v-if="!item.editable"
                        :class="{
                          'text-blue-600': item.cpm !== item.originalData.cpm,
                        }"
                      >
                        {{ item.cpm ? item.cpm : "-" }}
                      </span>
                      <div v-else>
                        <v-text-field
                          outlined
                          v-model="item.cpm"
                          dense
                          hide-details
                          class="text-[13px] w-[110px]"
                          placeholder="Cpm"
                          type="number"
                        ></v-text-field>
                      </div>
                    </template>

                    <template v-slot:[`item.country_type`]="{ item }">
                      <span
                        class="font-semibold text-[11px]"
                        v-if="!item.editable"
                        :class="{
                          'text-blue-600':
                            item.country_type !==
                            item.originalData.country_type,
                        }"
                      >
                        {{ item.country_type ? item.country_type : "-" }}
                      </span>
                      <div v-else>
                        <v-select
                          outlined
                          v-model="item.country_type"
                          dense
                          hide-details
                          :items="['INCLUDE', 'EXCLUDE']"
                          class="text-[13px] w-[125px]"
                          placeholder="Country Type"
                        ></v-select>
                      </div>
                    </template>

                    <template v-slot:[`item.countries`]="{ item }">
                      <div v-if="!item.editable">
                        <div v-if="item.countries.length">
                          <span
                            class="font-semibold text-[11px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                            v-for="(country, index) in item.countries"
                            :key="index"
                          >
                            {{ country }}
                          </span>
                        </div>

                        <div v-else>
                          <span class="font-semibold text-[11px]"> - </span>
                        </div>
                      </div>

                      <div v-else>
                        <v-combobox
                          hide-details
                          class="text-[12px] w-[200px]"
                          :label="`Countries`"
                          outlined
                          multiple
                          dense
                          v-model="item.countries"
                          :items="
                            computedCountries.map((x) => x.code.toLowerCase())
                          "
                        >
                        </v-combobox>
                      </div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <div>
                        <v-icon
                          color="primary"
                          class="mr-3"
                          @click="item.editable = !item.editable"
                          :disabled="item.mode === 'delete'"
                        >
                          {{ !item.editable ? "mdi-pencil" : "mdi-check" }}
                        </v-icon>
                        <v-icon
                          color="error"
                          @click="item.mode = 'delete'"
                          v-if="item.mode !== 'delete'"
                        >
                          mdi-delete
                        </v-icon>
                        <v-icon
                          v-if="item.mode === 'delete'"
                          color="error"
                          @click="item.mode = item.originalData.mode"
                        >
                          mdi-undo
                        </v-icon>
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-divider class="my-5"></v-divider>
        <div class="flex items-center justify-between mt-3">
          <div class="flex items-center">
            <div>
              <v-btn
                @click="showAbTest = true"
                :ripple="false"
                color="success"
                :loading="loading"
                :disabled="isAbTestBtnDisabled"
                text
              >
                Start A/B Test
              </v-btn>
            </div>
          </div>
          <div>
            <v-btn
              :ripple="false"
              color="primary"
              :loading="saveLoading"
              depressed
              @click="showPreview"
              :disabled="isSaveBtnDisabled"
            >
              Submit
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <ShowPlacementInsertForm
      :show="showBidderInsertForm"
      @closed="showBidderInsertForm = false"
      :countries="computedCountries"
      :networks="computedNetworkMaps"
      @submitted="insertNetwork"
    />
    <ShowABTest
      :data="computedNetworkData"
      :show="showAbTest"
      @closed="showAbTest = false"
      @submitted="handleAbTestSubmit"
      :testName="computedTestName"
      :networks="computedNetworkMaps"
    />

    <PreviewDialog
      :show="showPreviewDialog"
      @closed="showPreviewDialog = false"
      :data="previewData"
      @submitted="handlePreviewSubmit"
    />
  </div>
</template>

<script>
import { COUNTRIES } from "@/helper/utils";
import ShowABTest from "@/components/tools/umai/ShowABTest.vue";
import ShowPlacementInsertForm from "@/components/tools/umai/ShowPlacementInsertForm.vue";
import PreviewDialog from "@/components/tools/umai/PreviewDialog.vue";
import _ from "lodash";
import moment from "moment";

export default {
  data() {
    return {
      networks: [],
      showBidderInsertForm: false,
      selectedWaterfall: "default",
      searchInput: "",
      showCsvUpload: false,
      csvFile: null,
      loading: false,
      games: [],
      loadingGames: false,
      selectedGame: null,
      selectedPlatform: null,
      adUnitsLoading: false,
      adUnitLoading: false,
      adUnits: [],
      selectedAdUnit: null,
      csvData: [],
      networkSettings: null,
      bidderNetworks: [],
      nonbidderNetworks: [],
      operationType: "csv",
      originalNetworkData: null,
      saveLoading: false,
      csvHeaders: [
        { text: "Network", value: "network" },
        { text: "Operation", value: "operation" },
        { text: "App ID", value: "ad_network_app_id" },
        { text: "App Key", value: "ad_network_app_key" },
        { text: "Ad Unit ID", value: "ad_network_ad_unit_id" },
        {
          text: "Optional Placement ID",
          value: "ad_network_optional_placement_id",
          width: "220px",
        },
        { text: "CPM", value: "cpm" },
        { text: "Countries", value: "countries", sortable: false },
        { text: "Countries Type", value: "countries_type" },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      formHeaders: [
        { text: "Network", value: "network", width: "10%" },
        { text: "App ID", value: "ad_network_app_id" },
        { text: "App Key", value: "ad_network_app_key" },
        { text: "Ad Unit ID", value: "ad_network_ad_unit_id", width: "250px" },
        {
          text: "Optional Placement ID",
          value: "ad_network_optional_placement_id",
          width: "220px",
        },
        { text: "CPM", value: "cpm" },
        { text: "Countries", value: "countries", sortable: false },
        { text: "Country Type", value: "country_type" },
        { text: "Actions", value: "actions", sortable: false },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      formHeadersBidder: [
        { text: "Network", value: "network", width: "10%" },
        { text: "App ID", value: "ad_network_app_id" },
        { text: "App Key", value: "ad_network_app_key" },
        { text: "Ad Unit ID", value: "ad_network_ad_unit_id", width: "250px" },
        {
          text: "Optional Placement ID",
          value: "ad_network_optional_placement_id",
          width: "220px",
        },
        { text: "Actions", value: "actions", sortable: false },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      showAbTest: false,
      previewData: [],
      showPreviewDialog: false,
      unmodifiedCsvData: [],
      tableLoading: false,
    };
  },
  methods: {
    async fetchNetworks() {
      try {
        const response = await this.$api.get("umai-networks/all");
        this.networks = response.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    getAbTestLogs(groupB) {
      if (groupB && groupB.length) {
        var logs = [];

        for (let i = 0; i < groupB.length; i++) {
          const placement = groupB[i];

          // Find added placements.
          if (placement.mode === "add") {
            logs.push(
              `New placement added. Network: ${placement.network}, ID: ${
                placement.ad_network_ad_unit_id
              }, CPM: ${placement.cpm}, Countries: ${JSON.stringify(
                placement.countries
              )}, Country Type: ${placement.country_type}`
            );
          }

          // Find added but disabled placements.
          if (
            placement.mode === "off" &&
            placement.originalData.mode === "add"
          ) {
            logs.push(
              `New placement added. Network: ${placement.network}, ID: ${
                placement.ad_network_ad_unit_id
              }, CPM: ${placement.cpm}, Countries: ${JSON.stringify(
                placement.countries
              )}, Country Type: ${placement.country_type}`
            );
          }

          // Find deleted placements.
          if (
            placement.mode === "delete" &&
            placement.originalData.mode !== "add"
          ) {
            logs.push(
              `Placement (${placement.originalData.ad_network_ad_unit_id}) (${placement.originalData.network}) marked for deletion.`
            );
          }

          // Find off placements.
          // if (
          //   placement.mode === "off" &&
          //   placement.originalData.mode !== "off"
          // ) {
          //   logs.push(
          //     `Placement (${placement.originalData.ad_network_ad_unit_id}) (${placement.originalData.network}) marked to turn off.`
          //   );
          // }

          // Find updated placements
          if (
            placement.ad_network_app_id !==
            placement.originalData.ad_network_app_id
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) app id changed from ${placement.originalData.ad_network_app_id} to ${placement.ad_network_app_id}`
            );
          if (
            placement.ad_network_app_key !==
            placement.originalData.ad_network_app_key
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) app key changed from ${placement.originalData.ad_network_app_key} to ${placement.ad_network_app_key}`
            );
          if (placement.country_type !== placement.originalData.country_type)
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) country type changed from ${placement.originalData.country_type} to ${placement.country_type}`
            );
          if (placement.cpm !== placement.originalData.cpm)
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) CPM changed from ${placement.originalData.cpm} to ${placement.cpm}`
            );
          if (
            placement.ad_network_ad_unit_id !==
            placement.originalData.ad_network_ad_unit_id
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) ID changed from ${placement.originalData.ad_network_ad_unit_id} to ${placement.ad_network_ad_unit_id}`
            );
          if (
            !_.isEqual(
              placement.countries.sort().map((x) => x.toLowerCase()),
              placement.originalData.countries
                .sort()
                .map((x) => x.toLowerCase())
            )
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${
                placement.network
              }) countries changed from ${placement.originalData.countries.join()} to ${placement.countries.join()}`
            );
        }

        return logs;
      }
      return [];
    },
    mappedNetworkName(network) {
      const idx = this.computedNetworkMaps.findIndex(
        (x) => x.value === network
      );
      if (idx > -1) {
        return this.computedNetworkMaps[idx].label;
      }
      return network;
    },
    async handlePreviewSubmit() {
      var ad_network_settings = null;

      if (this.operationType === "csv") {
        const data = this.convertDataToModifiedData(
          this.csvData.filter((x) => x.mode !== "delete"),
          [
            ...new Set([
              ...this.unmodifiedCsvData
                .filter(
                  (x) => x.operation && x.operation.toLowerCase() === "off"
                )
                .map((x) => x.network),
              ...this.computedDefaultDisabledNetworks,
            ]),
          ]
        );
        ad_network_settings = data;
      } else {
        const temp = [...this.bidderNetworks, ...this.nonbidderNetworks].filter(
          (x) => x.mode !== "delete"
        );

        console.log(this.networkSettings);

        const data = this.convertDataToModifiedData(
          temp,
          this.computedDefaultDisabledNetworks
        );
        ad_network_settings = data;
      }

      if (!ad_network_settings) {
        this.$toast.error("Property ad_network_settings is empty.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }

      const requestData = {
        id: this.networkSettings.id,
        name: this.networkSettings.name,
        platform: this.networkSettings.platform,
        ad_format: this.networkSettings.ad_format,
        package_name: this.networkSettings.package_name,
        disabled: this.networkSettings.disabled,
        ad_network_settings: JSON.stringify(ad_network_settings),
        logs: this.computedLogs,
      };

      console.log(requestData);
      console.log(ad_network_settings);

      try {
        this.saveLoading = true;
        this.showPreviewDialog = false;
        const request = await this.$api.post(
          `applovin/ad-units/umai/update?waterfall=${
            this.networkSettings?.segment?.id
              ? this.networkSettings.segment.id
              : "default"
          }`,
          requestData
        );
        if (request.status === 200) {
          this.$toast.success(
            "Placements successfuly modified. Check them on Applovin panel...",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          this.selectedGame = null;
          this.selectedPlatform = null;
          this.networkSettings = null;
          this.selectedAdUnit = null;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.saveLoading = false;
      }
    },
    async handleAbTestSubmit(groupA, groupB, disabledNetworks, testTitle) {
      if (!groupA) return;
      if (!groupA.length) return;
      if (!groupB) return;
      if (!groupB.length) return;
      if (!testTitle) return;

      const temp = groupB.filter((x) => x.mode !== "delete");
      console.log(temp);
      const ad_network_settings = this.convertDataToModifiedData(temp, [
        ...this.computedDefaultDisabledNetworks,
        ...disabledNetworks,
      ]);
      var logs = this.getAbTestLogs(groupB);

      logs = [
        ...logs,
        ...disabledNetworks.map((x) => `Network ${x} set to disabled.`),
      ];

      const requestData = {
        id: this.networkSettings.id,
        title: testTitle,
        ad_network_settings: JSON.stringify(ad_network_settings),
        frequency_capping_settings: JSON.stringify(
          this.networkSettings?.frequency_capping_settings || []
        ),
        bid_floors: JSON.stringify(this.networkSettings?.bid_floors || []),
        logs: logs,
      };

      try {
        this.saveLoading = true;
        this.showAbTest = false;
        const request = await this.$api.post(
          `applovin/ad-units/umai/experiment/create?segment=${
            this.networkSettings?.segment?.id
              ? this.networkSettings.segment.id
              : "default"
          }`,
          requestData
        );
        if (request.status === 200) {
          this.$toast.success(
            "Test successfully created. Check them on Applovin panel...",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          // this.selectedGame = null;
          // this.selectedPlatform = null;
          // this.networkSettings = null;
          // this.selectedAdUnit = null;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.saveLoading = false;
      }
    },
    enableEditMode(item, mode) {
      const placement_id = item.ad_network_ad_unit_id;
      if (mode === "non-bidder") {
        const idx = this.nonbidderNetworks.findIndex(
          (x) => x.ad_network_ad_unit_id === placement_id
        );
        if (idx > -1) {
          this.nonbidderNetworks[idx].editable =
            !this.nonbidderNetworks[idx].editable;
        }
      }
      if (mode === "bidder") {
        const idx = this.bidderNetworks.findIndex(
          (x) => x.ad_network_ad_unit_id === placement_id
        );
        if (idx > -1) {
          this.bidderNetworks[idx].editable =
            !this.bidderNetworks[idx].editable;
        }
      }
    },
    getOriginalValue(placement_id) {
      const data = this.computedNetworkData.find(
        (x) => x.ad_network_ad_unit_id === placement_id
      );
      return data;
    },
    async handleCsvSubmit() {
      const csvData = await this.$csvFileToJson(this.csvFile, ",");
      const structure = [
        "operation",
        "network",
        "ad_network_app_id",
        "ad_network_app_key",
        "ad_network_ad_unit_id",
        "ad_network_optional_placement_id",
        "cpm",
        "countries",
        "country_type",
      ];

      const isStructureVerified = this.$isArrayStructureVerified(
        csvData,
        structure
      );
      if (!isStructureVerified) {
        this.$toast.error("Yüklenen CSV dosyasının formatı uyumlu değil.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        this.showCsvUpload = false;
        return;
      } else {
        this.showCsvUpload = false;
        for (let i = 0; i < csvData.length; i++) {
          const element = csvData[i];
          if (
            ["add", "remove"].includes(element.operation.toLowerCase()) &&
            !element.ad_network_ad_unit_id
          ) {
            this.$toast.error(
              `ad_network_ad_unit_id can not be empty on ${element.network} (${element.operation})`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );
            return;
          }

          csvData[i].countries = csvData[i].countries
            ? csvData[i].countries.split(",").map((x) => x.trim())
            : [];
        }

        this.unmodifiedCsvData = JSON.parse(JSON.stringify(csvData));

        const modifiedData = this.updateCsvData(csvData);
        this.csvData = modifiedData;

        const hasDuplicateNetworkAndCpm =
          this.hasDuplicateNetworkAndCpm(modifiedData);
        if (hasDuplicateNetworkAndCpm) {
          this.csvFile = null;
        }
      }
    },
    convertDataToModifiedData(data, disabledNetworks) {
      const modifiedData = [];

      // Create an object to store the mapping of network names to their corresponding data
      const networkDataMap = {};

      // Loop through the data array and group the data by network name
      data.forEach((item) => {
        const networkName = item.network;

        if (!networkDataMap[networkName]) {
          // Initialize the network data object if it doesn't exist
          networkDataMap[networkName] = {
            disabled: false,
            targets: this.computedNetworkMetadata
              ? this.computedNetworkMetadata[networkName]?.targets || null
              : null,
            ad_network_ad_units: [],
            extraParameters: this.computedNetworkMetadata
              ? this.computedNetworkMetadata[networkName]?.extraParameters ||
                null
              : null,
          };
        }

        if (networkName === "FACEBOOK_NATIVE_BIDDING") {
          networkDataMap[networkName].placement_type = "native";
        }

        if (disabledNetworks) {
          for (let i = 0; i < disabledNetworks.length; i++) {
            const network = disabledNetworks[i];
            if (networkDataMap[network]) {
              networkDataMap[network].disabled = true;
            }
          }
        }

        // Create an ad_network_ad_unit object for each item in data
        const adUnit = {
          ad_network_ad_unit_id: item.ad_network_ad_unit_id,
          extraParameters: null,
          disabled: networkDataMap[networkName].disabled
            ? true
            : item.disabled
            ? true
            : false,
        };

        if (item.ad_network_optional_placement_id) {
          adUnit.extraParameters = {
            ad_network_optional_placement_id:
              item.ad_network_optional_placement_id,
          };
        }

        if (item.cpm) {
          adUnit.cpm = String(item.cpm);
        }

        if (item.countries) {
          adUnit.countries = {
            type: item.country_type || "INCLUDE",
            values: item.countries || [],
          };
        }

        // Add the adUnit to the network's ad_network_ad_units array
        networkDataMap[networkName].ad_network_ad_units.push(adUnit);

        // Set the ad_network_app_id if it exists
        if (item.ad_network_app_id) {
          networkDataMap[networkName].ad_network_app_id =
            item.ad_network_app_id;
        }

        if (item.ad_network_app_key) {
          networkDataMap[networkName].ad_network_app_key =
            item.ad_network_app_key;
        }
      });

      // Convert the networkDataMap into the final modifiedData format
      for (const networkName in networkDataMap) {
        const networkData = {};
        networkData[networkName] = networkDataMap[networkName];
        modifiedData.push(networkData);
      }

      return modifiedData;
    },
    hasDuplicateNetworkAndCpm(data) {
      const seen = {};

      for (const entry of data) {
        const key = entry.network + "_" + entry.cpm;

        if (seen[key]) {
          // Duplicate found
          this.$toast.error(
            `${entry.network} üzerinde aynı CPM (${entry.cpm}) ile birden fazla placement mevcut.`,
            {
              timeout: 0,
              position: "bottom-center",
              icon: false,
            }
          );
          return true;
        }

        seen[key] = true;
      }

      // No duplicate found
      return false;
    },
    showPreview() {
      this.previewData = [];
      if (this.operationType === "csv") {
        this.previewData = this.csvData;
      } else {
        this.previewData = [...this.bidderNetworks, ...this.nonbidderNetworks];
      }

      const hasDuplicateNetworkAndCpm = this.hasDuplicateNetworkAndCpm(
        this.previewData
      );
      if (hasDuplicateNetworkAndCpm) {
        return;
      }
      this.showPreviewDialog = true;
    },
    deleteNetwork(type, index) {
      if (type === "bidder") {
        this.bidderNetworks[index].is_deleted =
          !this.bidderNetworks[index].is_deleted;
      }
      if (type === "non-bidder") {
        this.nonbidderNetworks[index].is_deleted =
          !this.nonbidderNetworks[index].is_deleted;
        // this.nonbidderNetworks.splice(index, 1);
      }
    },

    insertNetwork(data) {
      data.mode = "add";
      var temp = JSON.parse(JSON.stringify(data));
      temp.originalData = data;

      var previewData = JSON.parse(JSON.stringify(this.computedPreviewData));
      previewData.push(temp);

      const hasDuplicateNetworkAndCpm =
        this.hasDuplicateNetworkAndCpm(previewData);

      if (hasDuplicateNetworkAndCpm) {
        return;
      }

      if (this.operationType === "form") {
        if (data.cpm) {
          this.nonbidderNetworks.push(temp);
          this.showBidderInsertForm = false;
        } else {
          this.bidderNetworks.push(temp);
          this.showBidderInsertForm = false;
        }
      } else {
        this.csvData.push(temp);
        this.showBidderInsertForm = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchGames() {
      try {
        this.loadingGames = true;
        const response = await this.$api.get("games/all?only_actives=True");
        this.games = response.data.filter(
          (x) => x.applovin_android_package_name || x.applovin_ios_package_name
        );
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingGames = false;
      }
    },
    async fetchAdUnits() {
      if (!this.selectedGame || !this.selectedPlatform) {
        return;
      }
      try {
        this.adUnitsLoading = true;
        const response = await this.$api.get(
          `applovin/ad-units?game_id=${this.selectedGame.id}&platform=${this.selectedPlatform}`
        );
        if (this.selectedPlatform === "android") {
          this.adUnits = response.data.filter(
            (x) =>
              x.package_name ===
                this.selectedGame.applovin_android_package_name &&
              x.platform === this.selectedPlatform
          );
        }
        if (this.selectedPlatform === "ios") {
          this.adUnits = response.data.filter(
            (x) =>
              x.package_name === this.selectedGame.applovin_ios_package_name &&
              x.platform === this.selectedPlatform
          );
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.adUnitsLoading = false;
      }
    },
    async fetchAdUnitDetail(updateCsvData) {
      if (!this.selectedAdUnit) {
        return;
      }
      try {
        this.adUnitLoading = true;
        this.tableLoading = true;
        this.bidderNetworks = [];
        this.nonbidderNetworks = [];
        this.csvData = [];
        const response = await this.$api.get(
          `applovin/ad-units/detail?ad_unit_id=${this.selectedAdUnit.id}&waterfall=${this.selectedWaterfall.id}`
        );
        this.networkSettings = response.data;

        this.modifyNetworkData();

        if (updateCsvData && this.csvFile) {
          this.handleCsvSubmit();
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.adUnitLoading = false;
        this.tableLoading = false;
      }
    },
    modifyNetworkData() {
      this.nonbidderNetworks = JSON.parse(
        JSON.stringify(this.computedNetworkData.filter((x) => x.cpm))
      );
      this.bidderNetworks = JSON.parse(
        JSON.stringify(this.computedNetworkData.filter((x) => !x.cpm))
      );
    },
    updateCsvData(data) {
      if (!data.length) return [];
      else {
        var modifiedData = JSON.parse(JSON.stringify(this.computedNetworkData));

        for (let i = 0; i < data.length; i++) {
          var csvData = data[i];
          csvData.editable = false;
          csvData.mode = "";
          csvData.cpm = csvData.cpm ? parseFloat(csvData.cpm) : null;
          csvData.originalData = JSON.parse(JSON.stringify(csvData));

          const placementIndex = modifiedData.findIndex(
            (x) =>
              x.ad_network_ad_unit_id === csvData.ad_network_ad_unit_id &&
              x.network === csvData.network
          );

          if (csvData.operation.toLowerCase() === "add") {
            if (placementIndex > -1) {
              // Zaten böyle bir placement olduğu anlamına gelir.
              csvData.originalData = modifiedData[placementIndex].originalData;

              if (!csvData.originalData.ad_network_app_key) {
                csvData.originalData.ad_network_app_key = null;
              }

              if (!csvData.originalData.ad_network_app_id) {
                csvData.originalData.ad_network_app_id = null;
              }

              modifiedData[placementIndex] = {
                ...csvData,
                mode: "update",
              };
            } else {
              // Böyle bir placement olmadığı için modified data üzerine pushlanır.
              const temp = {
                ...csvData,
                mode: "add",
              };

              temp.originalData.mode = "add";

              modifiedData.push(temp);
            }
          }

          if (csvData.operation.toLowerCase() === "remove") {
            if (placementIndex > -1) {
              // Zaten böyle bir placement olduğu anlamına gelir.

              if (csvData.countries.length) {
                var countries = [];
                modifiedData[placementIndex].countries.forEach((country) => {
                  var isFound = false;
                  csvData.countries.forEach((csvCountry) => {
                    if (country.toLowerCase() === csvCountry.toLowerCase()) {
                      isFound = true;
                    }
                  });

                  if (!isFound) {
                    countries.push(country);
                  }
                });

                // Eğer ülkelerde bir değişiklik varsa update;
                if (
                  modifiedData[placementIndex].countries.length !==
                  countries.length
                ) {
                  modifiedData[placementIndex].countries = countries;
                  modifiedData[placementIndex].mode = "update";
                }
                // Yoksa delete olsun.
                else {
                  modifiedData[placementIndex].mode = "delete";
                }
              } else {
                modifiedData[placementIndex].mode = "delete";
              }
            } else {
              const err_msg = `Silmek istediğiniz placement, mevcut placementler arasında bulunamadı. \nNetwork: ${csvData.network} - Placement ID: ${csvData.ad_network_ad_unit_id}`;

              console.error(err_msg);
              this.$toast.error(err_msg, {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              });
              return;
            }
          }
        }

        // Off işlemi
        for (let i = 0; i < data.length; i++) {
          const csvData = data[i];
          if (csvData.operation.toLowerCase() === "off") {
            for (let y = 0; y < modifiedData.length; y++) {
              const element = modifiedData[y];
              if (
                csvData.network.toUpperCase() === element.network.toUpperCase()
              ) {
                modifiedData[y].disabled = true;
                modifiedData[y].mode = "off";
              }
            }
          }
        }

        return modifiedData;
      }
    },
  },
  computed: {
    computedPreviewData() {
      if (this.operationType === "csv") {
        return this.csvData;
      } else {
        return [...this.bidderNetworks, ...this.nonbidderNetworks];
      }
    },
    computedTestName() {
      if (!this.selectedGame) return null;
      if (!this.selectedPlatform) return null;
      if (!this.selectedAdUnit) return null;
      if (!this.selectedWaterfall) return null;

      return `${this.selectedAdUnit.ad_format.toLowerCase()}_${this.selectedPlatform.toLowerCase()}_${this.selectedWaterfall.name
        .replace(" ", "_")
        .toLowerCase()}_${moment().format("DDMM")}`;
    },
    isSaveBtnDisabled() {
      if (!this.selectedGame) return true;
      if (!this.selectedAdUnit) return true;
      if (!this.selectedWaterfall) return true;
      // if (!this.computedNetworkData.length) return true;

      if (this.operationType === "csv" && !this.csvFile) return true;

      return false;
    },

    isAbTestBtnDisabled() {
      if (!this.selectedGame) return true;
      if (!this.selectedAdUnit) return true;
      if (!this.selectedWaterfall) return true;
      if (!this.computedNetworkData.length) return true;
      if (!this.networkSettings) return true;
      if (this.networkSettings.has_active_experiment) return true;

      return false;
    },
    computedNetworkMaps() {
      return this.$sortArrayByProperty(this.networks, ["label"], ["asc"]);
    },
    computedCountries() {
      return COUNTRIES;
    },
    computedNetworkMetadata() {
      if (
        this.networkSettings &&
        this.networkSettings.ad_network_settings &&
        this.networkSettings.ad_network_settings.length
      ) {
        var modifiedData = {};

        this.networkSettings.ad_network_settings.forEach((item) => {
          const network = Object.keys(item)[0];
          if (!modifiedData[network]) {
            modifiedData[network] = {
              extraParameters: item[network]?.extraParameters || null,
              targets: item[network]?.targets || {},
            };
          }
        });

        return modifiedData;
      }
      return null;
    },
    computedNetworkData() {
      if (
        this.networkSettings &&
        this.networkSettings.ad_network_settings &&
        this.networkSettings.ad_network_settings.length
      ) {
        var modifiedData = [];

        this.networkSettings.ad_network_settings.forEach((item) => {
          const network = Object.keys(item)[0];
          const ad_unit_id = this.networkSettings.id;

          item[network].ad_network_ad_units.forEach((waterfall) => {
            const data = {
              network,
              ad_unit_id,
              countries: waterfall.countries?.values || [],
              country_type: waterfall.countries?.type || null,
              ad_network_ad_unit_id: waterfall.ad_network_ad_unit_id || null,
              ad_network_optional_placement_id:
                waterfall.ad_network_optional_placement_id || null,
              cpm: parseFloat(waterfall.cpm) || null,
              ad_network_app_id: item[network]?.ad_network_app_id || null,
              ad_network_app_key: item[network]?.ad_network_app_key || null,
              disabled: waterfall.disabled,
            };
            modifiedData.push({
              ...data,
              editable: false,
              mode: "",
              originalData: JSON.parse(JSON.stringify(data)),
            });
          });
        });

        return modifiedData;
      }
      return [];
    },
    computedDefaultDisabledNetworks() {
      var networks = [];
      this.networkSettings.ad_network_settings.forEach((item) => {
        const network = Object.keys(item)[0];
        if (item[network].disabled) {
          networks.push(network);
        }
      });

      return networks;
    },
    computedWaterfallData() {
      if (!this.selectedGame) return null;
      else if (!this.selectedPlatform) return null;
      else if (!this.selectedAdUnit) return null;

      const adUnit = this.adUnits.find((x) => x.id === this.selectedAdUnit.id);

      if (adUnit) {
        const segments =
          adUnit.segments?.map((x) => ({
            id: x.id,
            name: x.name,
          })) || [];

        return [{ id: "default", name: "Default Waterfall" }, ...segments];
      }

      return null;
    },
    computedLogs() {
      var data =
        this.operationType === "form"
          ? [...this.bidderNetworks, ...this.nonbidderNetworks]
          : this.csvData;

      if (data.length) {
        var logs = [];

        for (let i = 0; i < data.length; i++) {
          const placement = data[i];

          // Find added placements.
          if (placement.mode === "add") {
            logs.push(
              `New placement added. Network: ${placement.network}, ID: ${
                placement.ad_network_ad_unit_id
              }, CPM: ${placement.cpm}, Countries: ${JSON.stringify(
                placement.countries
              )}, Country Type: ${placement.country_type}`
            );
          }

          // Find deleted placements.
          if (
            placement.mode === "delete" &&
            placement.originalData.mode !== "add"
          ) {
            logs.push(
              `Placement (${placement.originalData.ad_network_ad_unit_id}) (${placement.originalData.network}) marked for deletion.`
            );
          }

          // Find off placements.
          // if (
          //   placement.mode === "off" &&
          //   placement.originalData.mode !== "off"
          // ) {
          //   logs.push(
          //     `Placement (${placement.originalData.ad_network_ad_unit_id}) (${placement.originalData.network}) marked for deletion.`
          //   );
          // }

          // Find added but disabled placements.
          if (
            placement.mode === "off" &&
            placement.originalData.mode === "add"
          ) {
            logs.push(
              `New placement added. Network: ${placement.network}, ID: ${
                placement.ad_network_ad_unit_id
              }, CPM: ${placement.cpm}, Countries: ${JSON.stringify(
                placement.countries
              )}, Country Type: ${placement.country_type}`
            );
          }

          // Find updated placements
          if (
            placement.ad_network_app_id !==
            placement.originalData.ad_network_app_id
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) app id changed from ${placement.originalData.ad_network_app_id} to ${placement.ad_network_app_id}`
            );
          if (
            placement.ad_network_app_key !==
            placement.originalData.ad_network_app_key
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) app key changed from ${placement.originalData.ad_network_app_key} to ${placement.ad_network_app_key}`
            );
          if (placement.country_type !== placement.originalData.country_type)
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) country type changed from ${placement.originalData.country_type} to ${placement.country_type}`
            );
          if (placement.cpm !== placement.originalData.cpm)
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) CPM changed from ${placement.originalData.cpm} to ${placement.cpm}`
            );
          if (
            placement.ad_network_ad_unit_id !==
            placement.originalData.ad_network_ad_unit_id
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${placement.network}) ID changed from ${placement.originalData.ad_network_ad_unit_id} to ${placement.ad_network_ad_unit_id}`
            );
          if (
            !_.isEqual(
              placement.countries.sort().map((x) => x.toLowerCase()),
              placement.originalData.countries
                .sort()
                .map((x) => x.toLowerCase())
            )
          )
            logs.push(
              `Placement's (${placement.originalData.ad_network_ad_unit_id} - ${
                placement.network
              }) countries changed from ${placement.originalData.countries.join()} to ${placement.countries.join()}`
            );
        }

        return logs;
      }
      return [];
    },
  },
  watch: {
    selectedPlatform() {
      this.fetchAdUnits();
      this.csvFile = null;
    },
    selectedGame() {
      this.fetchAdUnits();
      this.csvFile = null;
    },
    selectedAdUnit() {
      // this.fetchAdUnits();
      this.selectedWaterfall = null;
      this.csvFile = null;
    },
    selectedWaterfall(newValue) {
      this.csvData = [];
      this.bidderNetworks = [];
      this.nonbidderNetworks = [];
      this.csvFile = null;

      if (newValue) {
        this.fetchAdUnitDetail();
      }
    },
    csvFile(newValue) {
      if (!newValue) {
        this.csvData = [];
      } else {
        this.handleCsvSubmit();
      }
    },
  },
  components: {
    ShowABTest,
    ShowPlacementInsertForm,
    PreviewDialog,
  },
  mounted() {
    this.fetchNetworks();
    this.fetchGames();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}

::v-deep .v-input--selection-controls {
  margin-top: 5px !important;
}

.different {
  ::v-deep fieldset {
    border-color: #4db2ec !important;
  }
}

.table-input {
  outline: none;
  padding: 4px;
  display: inline-block;
}
</style>
