<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between pb-3">
        <div class="flex items-center">
          <h1 class="font-semibold text-[18px]">Game Live Events</h1>

          <div class="pl-4">
            <v-autocomplete
              hide-details
              class="text-[13px]"
              item-text="title"
              return-object
              label="Game"
              outlined
              dense
              :items="games"
              v-model="selected_game"
              style="width: 230px"
            >
            </v-autocomplete>
          </div>
          <div class="pl-4">
            <v-select
              hide-details
              class="text-[13px]"
              item-text="title"
              item-value="id"
              label="Deployment"
              outlined
              dense
              :items="deployments"
              v-model="selected_deployment"
              style="width: 230px"
              :disabled="loading || !selected_game || deploymentsLoading"
              :loading="deploymentsLoading"
            >
            </v-select>
          </div>
        </div>

        <div class="flex items-center justify-end">
          <div>
            <v-icon
              dense
              @click="deleteCalendarPage"
              size="20"
              style="padding: 3px; margin-right: 16px"
              color="red darken-4"
            >
              mdi-delete-outline
            </v-icon>
          </div>

          <div>
            <v-icon
              dense
              @click="copyCalendarPage"
              small
              style="padding: 5px; margin-right: 16px"
              color="grey darken-4"
            >
              mdi-content-copy
            </v-icon>
          </div>

          <div>
            <v-icon
              dense
              @click="handleOnEventInsert(getCopiedEventData, true)"
              small
              style="padding: 5px; margin-right: 16px"
              color="grey darken-4"
              :disabled="isPasteBtnDisabled"
            >
              mdi-content-paste
            </v-icon>
          </div>
          <v-btn
            color="primary"
            @click="deployEvents"
            depressed
            class="font-semibold"
            :disabled="isDeployBtnDisabled"
            :loading="deployBtnLoading"
          >
            Deploy
          </v-btn>
        </div>
      </div>

      <div class="mt-5" v-if="getUserProfile.game_backend_environment_url">
        <v-alert text type="success" prominent icon="mdi-check" border="left">
          <div class="text-[12px] p-2">
            Your Environment URL:
            <a
              :href="getUserProfile.game_backend_environment_url"
              target="_blank"
              >{{ getUserProfile.game_backend_environment_url }}</a
            >
          </div>
        </v-alert>
      </div>

      <div class="mt-5">
        <div class="flex items-center justify-between mb-3">
          <h1 class="font-semibold text-[14px] w-full">
            <span v-if="currentDate" class="mb-2.5 text-[14px] font-semibold">
              {{ returnMonth(currentDate.start.date) }}
            </span>
          </h1>
          <div
            class="flex items-center"
            v-if="
              selected_game &&
              selected_game.applovin_android_package_name &&
              !loading
            "
          >
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <v-calendar
          v-if="
            selected_game &&
            selected_game.applovin_android_package_name &&
            selected_deployment &&
            !loading
          "
          style="min-height: 600px"
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="
            liveEvents.map((x) => ({ ...x, end_date: minusDate(x.end_date) }))
          "
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          @click:event="handleOnCalendarEvent"
          @click:date="handleOnClickDay"
          :event-ripple="false"
          :event-text-color="''"
          event-end="end_date"
          event-start="start_date"
          @change="handleOnChangeCalendar"
        >
          <template v-slot:event="{ event }">
            <div>
              <div>
                <div
                  class="flex items-center px-1.5"
                  style="line-height: normal"
                >
                  <span
                    class="font-semibold text-[10px] mr-1"
                    v-if="isEventDeleted(event)"
                  >
                    (Delete)
                  </span>
                  <v-icon
                    size="18"
                    class="ml-1.5 mr-0"
                    :color="event.vuetify_color"
                  >
                    {{ `mdi-${event.type_icon}` }}
                  </v-icon>
                  <div
                    class="ml-1.5 font-semibold text-[11px] flex items-center"
                  >
                    <span class="capitalize">
                      {{ event.event_name }}
                    </span>
                    <span class="ml-1.5 capitalize" v-if="event.alias">
                      ({{ event.alias }})</span
                    >
                  </div>

                  <span class="ml-1.5 text-[11px]" v-if="!event.id">
                    (Draft)
                  </span>

                  <!-- <span
                    class="ml-1.5 text-[11px] font-semibold"
                    v-if="event.isRecurring"
                  >
                    Recurring - For each
                    {{ event.recurringOptions.period.value }}
                    {{ event.recurringOptions.period.mode.toLowerCase() }} -
                    {{ event.recurringOptions.amount }} times
                  </span> -->
                </div>
              </div>
            </div>
          </template>

          <!-- <template v-slot:day-label="{ day }">
            <div class="flex items-center justify-center">
              <div
                class="font-semibold text-[10px] hover:bg-gray-100 min-w-[40px] min-h-[40px] rounded-full leading-[40px]"
              >
                {{ day }}
              </div>
            </div>
          </template> -->
        </v-calendar>

        <div v-if="loading">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>

        <div
          v-if="!loading && !selected_game"
          class="text-[12px] p-4 rounded-[5px] bg-rose-50 text-rose-700 hover:bg-rose-100"
        >
          You need to choose a game first.
        </div>
      </div>
    </div>

    <!-- <UpdateGameEventDialog
      :show="showInsertUpdateDialog"
      @closed="showInsertUpdateDialog = false"
      :data="showInsertUpdateDialogData"
      :mode="showInsertUpdateDialogMode"
      :games="games"
      @update="handleOnEventUpdate"
      @insert="handleOnEventInsert"
      @delete="handleOnEventDelete"
    ></UpdateGameEventDialog> -->

    <EventPublishDialog
      :show="showInsertUpdateDialog"
      @closed="handleCloseEventPublishDialog"
      :data="showInsertUpdateDialogData"
      :mode="showInsertUpdateDialogMode"
      :games="games"
      @insert="handleOnEventInsert($event, false)"
      :eventTypes="eventTypes"
      :events="events"
      :env="'develop'"
    ></EventPublishDialog>

    <EventUpdateDialog
      :show="showUpdateDialog"
      @closed="showUpdateDialog = false"
      :data="showUpdateDialogData"
      :previewData="showUpdateDialogPreviewData"
      :mode="'update'"
      :games="games"
      @update="handleOnEventUpdate($event)"
      @delete="deleteLiveEventFromCalendar"
      :eventTypes="eventTypes"
      :events="events"
      :env="'develop'"
    ></EventUpdateDialog>
  </div>
</template>

<script>
import EventPublishDialog from "@/components/tools/game_backend/EventPublishDialog.vue";
import EventUpdateDialog from "@/components/tools/game_backend/EventUpdateDialog.vue";
import {
  LIVE_OPS_TYPE_COLORS,
  LIVE_OPS_DRAFT_TYPE_COLORS,
} from "@/helper/utils";

import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { departments } from "@/helper/departments";
export default {
  data() {
    return {
      showUpdateDialogPreviewData: null,
      deploymentsLoading: false,
      deployments: [],
      selected_deployment: null,
      currentMonth: null,
      currentDate: null,
      games: [],
      tableHeaders: [
        {
          text: "ID",
          value: "id",
          sortable: true,
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
        {
          text: "Type",
          value: "type_id",
          sortable: true,
        },
      ],
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      dragEvent: null,
      dragType: null,
      showInsertUpdateDialog: false,
      showUpdateDialog: false,
      showInsertUpdateDialogData: null,
      showUpdateDialogData: null,
      showInsertUpdateDialogMode: null,
      clickPositionX: null,
      clickPositionY: null,
      clickedEvent: null,
      events: [],
      selected_game: null,
      createLoading: false,
      loading: false,
      showEventInformationDialog: false,
      showEventInformationDialogData: null,
      liveEvents: [],
      deployBtnLoading: false,
      eventTypes: [],
      latestDeploymentId: null,
      liveEventsUnmodified: [],
    };
  },
  methods: {
    ...mapActions(["setCopiedEventData"]),
    minusDate(date) {
      return moment(date).subtract(1, "seconds").format("YYYY-MM-DDTHH:mm:ss");
    },
    plusDate(date) {
      return moment(date).add(1, "seconds").format("YYYY-MM-DDTHH:mm:ss");
    },

    handleCloseEventPublishDialog() {
      this.showInsertUpdateDialog = false;
      this.showInsertUpdateDialogData = null;
    },
    createNewExperiment() {
      this.showExperimentDialog = true;
    },
    deleteCalendarPage() {
      const start_date = moment(
        this.$refs.calendar.lastStart.date,
        "YYYY-MM-DD"
      );
      const end_date = moment(this.$refs.calendar.lastEnd.date, "YYYY-MM-DD");

      const events = this.liveEvents.filter((x) => {
        const eventStartDate = moment(x.start_date, "YYYY-MM-DD");
        if (eventStartDate >= start_date && eventStartDate <= end_date) {
          return true;
        }
        return false;
      });

      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        this.deleteLiveEventFromCalendar(event, false);
      }
    },

    copyCalendarPage() {
      const start_date = moment(
        this.$refs.calendar.lastStart.date,
        "YYYY-MM-DD"
      );
      const end_date = moment(this.$refs.calendar.lastEnd.date, "YYYY-MM-DD");

      const events = this.liveEvents.filter((x) => {
        const eventStartDate = moment(x.start_date, "YYYY-MM-DD");
        if (eventStartDate >= start_date && eventStartDate <= end_date) {
          return true;
        }
        return false;
      });

      if (events.length) {
        this.setCopiedEventData(JSON.parse(JSON.stringify(events)));

        this.$toast.success(`Current calendar page has been copied!`, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
      }
    },
    dateToUnix(date, hour, minute, second, mil) {
      return moment(date, "YYYY-MM-DD")
        .set({
          hour: hour,
          minute: minute,
          second: second,
          millisecond: mil,
        })
        .utc(false)
        .valueOf();
    },
    isEventDeleted(event) {
      const events = this.liveEvents.filter((x) => x.delete);
      for (let i = 0; i < events.length; i++) {
        const deletedEvent = events[i];
        if (deletedEvent.id === event.id) return true;
        if (
          deletedEvent.event_id === event.event_id &&
          deletedEvent.deleteWithRecurrings
        )
          return true;
      }
      return false;
    },
    handleOnClickDay(day) {
      this.showInsertUpdateDialogData = day;
      this.showInsertUpdateDialogMode = "insert";
      this.showInsertUpdateDialog = true;
    },
    returnMonth(dateString) {
      const date = new Date(dateString);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Get the month index from the date object (0-based index)
      const monthIndex = date.getMonth();

      // Return the month name corresponding to the month index
      return `${monthNames[monthIndex]} - ${date.getFullYear()}`;
    },
    handleOnChangeCalendar(event) {
      this.currentDate = event;
    },
    handleShowEventClosedDialog() {
      this.showEventInformationDialog = false;
    },
    setDeletedColor(index) {
      this.$set(
        this.liveEvents[index],
        "color",
        `bg-gray-100 border-gray-300 text-gray-900 border border-dashed rounded hover:bg-gray-200`
      );
      this.$set(this.liveEvents[index], "vuetify_color", `grey darken-4`);
    },
    async deleteLiveEventFromCalendar(data, withRecurrings) {
      this.showUpdateDialog = false;
      this.showUpdateDialogData = null;
      this.showUpdateDialogPreviewData = null;

      // Delete live event from deployment.
      if (data.id) {
        const index = this.liveEvents.findIndex((x) => x.id === data.id);
        if (index > -1) {
          if (withRecurrings) {
            for (let i = 0; i < this.liveEvents.length; i++) {
              const scheduleEvent = this.liveEvents[i];
              if (scheduleEvent.event_id === data.event_id) {
                this.setDeletedColor(i);
                this.$set(this.liveEvents[i], "delete", true);
              }
            }
          } else {
            this.$set(this.liveEvents[index], "delete", true);
            this.$set(
              this.liveEvents[index],
              "deleteWithRecurrings",
              withRecurrings
            );
            this.setDeletedColor(index);
          }
        }
        // try {
        //   const requestData = {
        //     package_name: this.selected_game.applovin_android_package_name,
        //     deployment_id: this.selected_deployment,
        //     event_id: data.event_id,
        //     env: "dev",
        //     withRecurrings,
        //     schedule_id: data.id,
        //   };
        //   const request = await this.$api.post(
        //     `game-backend/deployments/delete-event`,
        //     requestData
        //   );

        //   if (request.status === 200) {
        //     await this.fetchDeploymentSchedule();
        //     this.$toast.success(
        //       "You have successfully removed the live event from calendar.",
        //       {
        //         timeout: 5000,
        //         position: "bottom-center",
        //         icon: false,
        //       }
        //     );
        //   }
        // } catch (error) {
        //   this.errorHandler(error);
        // }
      } else {
        if (data.tempId) {
          this.liveEvents = this.liveEvents.filter(
            (x) => x.tempId != data.tempId
          );
          this.$toast.success(
            "You have successfully removed the live event from calendar.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      }
    },
    async fetchLiveEventTypes() {
      try {
        const request = await this.$api.get(
          `game-backend/live-event-types/all`
        );
        this.eventTypes = request.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },

    async fetchDeployments(setLatestDeployment) {
      try {
        this.deploymentsLoading = true;

        if (!this.selected_game) return;
        const response = await this.$api.get(
          `game-backend/deployments/all?package_name=${this.selected_game.applovin_android_package_name}&env=${this.env}`
        );
        if (response.data && response.data.length) {
          this.deployments = response.data.map((x) => ({
            ...x,
            title: `${x.id} - ${this.$formatUnix(x.created_at)}`,
          }));

          if (!this.selected_deployment || setLatestDeployment) {
            this.selected_deployment =
              this.deployments[this.deployments.length - 1].id;

            this.deployments[this.deployments.length - 1].title = `${
              this.deployments[this.deployments.length - 1].id
            } - Latest`;
          }
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.deploymentsLoading = false;
      }
    },

    async deployEvents() {
      const requestData = this.liveEvents
        .filter((x) => !x.delete)
        .filter((x) => !x.copied)
        .map((x) => {
          const event = this.events.find((event) => event.id === x.event_id);
          const liveEventUnmodified = this.liveEventsUnmodified.find(
            (event) => event.id === x.event_id
          );

          return {
            schedule_id: x.id ? x.id : null,
            event_id: x.event_id,
            start_date: x.start_date,
            end_date: x.end_date,
            isRecurring: x.isRecurring ? x.isRecurring : false,
            recurringOptions: x.recurringOptions ? x.recurringOptions : null,
            delete: false,
            deleteWithRecurrings: x.deleteWithRecurrings ? true : false,
            event_type: event ? event.type.slug : liveEventUnmodified.type,
            event_theme: event ? event.theme.slug : liveEventUnmodified.theme,
            payload: event
              ? event.payload
                ? JSON.stringify(event.payload)
                : null
              : liveEventUnmodified.payload,
            min_version: event
              ? event.min_version
              : liveEventUnmodified.min_version,
            event_name: x.event_name,
            event_alias: x.alias,
            event_description: event ? event.description : x.event_description,
            updated: x.updated ? x.updated : false,
          };
        });

      try {
        this.deployBtnLoading = true;
        const response = await this.$api.post(
          "game-backend/deployments/create",
          {
            data: requestData,
            env: this.env,
            package_name: this.selected_game.applovin_android_package_name,
            deployment_id: this.selected_deployment,
          }
        );
        if (response.status === 200) {
          await this.fetchDeployments(true);
          await this.fetchDeploymentSchedule();
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.deployBtnLoading = false;
      }
    },

    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },

    async handleOnEventInsert(tempEventData, isCopied) {
      let data = [];

      if (this.$isArr(tempEventData)) {
        data = [...tempEventData];
      } else if (
        this.$isObj(tempEventData) &&
        Object.keys(tempEventData).length
      ) {
        data = [tempEventData];
      }

      for (let i = 0; i < data.length; i++) {
        const event = data[i];
        try {
          const eventInstance = this.events.find(
            (instance) => instance.id === event.event_id
          );

          if (!eventInstance) {
            throw new Error("Selected event not found.");
          }
          // this.createLoading = true;
          if (event.isRecurring) {
            var periodLength = 0;
            var start_date = moment(event.start_date, "YYYY-MM-DD HH:mm");
            var end_date = moment(event.end_date, "YYYY-MM-DD HH:mm");

            const eventLength = end_date.diff(start_date, "seconds");
            const tempId = this.$generateRandomId(6);
            for (let i = 0; i < event.recurringOptions.amount + 1; i++) {
              if (event.recurringOptions.period.mode === "Days") {
                periodLength = event.recurringOptions.period.value * 86400;
              }

              this.liveEvents.push({
                id: null,
                event_id: event.event_id,
                start_date:
                  i === 0 ? start_date.format("YYYY-MM-DD HH:mm") : start_date,
                end_date:
                  i === 0 ? end_date.format("YYYY-MM-DD HH:mm") : end_date,
                color: this.computedDraftColors[eventInstance.type.slug].color,
                isRecurring: event.isRecurring,
                recurringOptions: event.recurringOptions,
                tempId: tempId,
                event_name: event.event_name,
                alias: event.alias,
                copied: i === 0 ? false : true,
              });

              start_date = moment(end_date, "YYYY-MM-DD HH:mm").add(
                periodLength,
                "seconds"
              );

              if (event.recurringOptions.period.mode === "Days") {
                const baseStartDate = moment(
                  event.start_date,
                  "YYYY-MM-DD HH:mm"
                );

                start_date = start_date.set({
                  hours: baseStartDate.hours(),
                  minutes: baseStartDate.minutes(),
                  seconds: baseStartDate.seconds(),
                });
              }

              start_date = start_date.format("YYYY-MM-DD HH:mm");

              end_date = moment(start_date, "YYYY-MM-DD HH:mm")
                .add(eventLength, "seconds")
                .format("YYYY-MM-DD HH:mm");
            }
          } else {
            if (!this.$isArr(event)) {
              this.liveEvents.push({
                id: null,
                event_id: event.event_id,
                start_date: event.start_date,
                end_date: event.end_date,
                color: this.computedDraftColors[eventInstance.type.slug].color,
                isRecurring: event.isRecurring,
                recurringOptions: event.recurringOptions,
                tempId: this.$generateRandomId(6),
                event_name: event.event_name,
                alias: event.alias,
                copied: false,
              });
            } else if (this.$isArr(event)) {
              this.liveEvents = [
                ...this.liveEvents,
                ...event.map((x) => ({
                  ...x,
                  id: null,
                  color:
                    this.computedDraftColors[eventInstance.type.slug].color,
                  copied: false,
                  tempId: this.$generateRandomId(6),
                })),
              ];
            }

            if (isCopied) {
              this.value = moment.unix(event.start_date).format("YYYY-MM-DD");
            }
          }

          this.$toast.success(
            "You have successfully inserted the live event.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.createLoading = false;
        }
      }
    },

    handleOnEventUpdate(data) {
      const index = this.liveEvents.findIndex((x) => x.id === data.id);

      const unmodifiedEvents = this.transformData(
        JSON.parse(JSON.stringify(this.liveEventsUnmodified))
      );

      const unmodifiedEvent = unmodifiedEvents.find((x) => x.id === data.id);

      if (!unmodifiedEvent) {
        console.error("No original event found!");
        return;
      }

      let isModified = false;
      if (unmodifiedEvent.start_date !== data.start_date) isModified = true;
      if (unmodifiedEvent.end_date !== data.end_date) isModified = true;

      this.$set(this.liveEvents[index], "start_date", data.start_date);
      this.$set(this.liveEvents[index], "end_date", data.end_date);
      this.$set(this.liveEvents[index], "updated", isModified);

      this.$toast.success(`Calendar event successfully updated.`, {
        timeout: 5000,
        position: "bottom-center",
        icon: false,
      });
    },

    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data.filter(
            (x) => x.applovin_android_package_name
          );
        })
        .catch((err) => {
          this.errorHandler(err);
        });
    },

    handleOnCalendarEvent({ event }) {
      this.showUpdateDialogData = JSON.parse(
        JSON.stringify({ ...event, end_date: this.plusDate(event.end_date) })
      );

      this.showUpdateDialogPreviewData = this.liveEventsUnmodified.find(
        (x) => x.id === event.event_id
      );

      this.showUpdateDialog = true;
    },

    transformData(data) {
      if (!this.events) {
        return;
      }

      const result = [];

      data.forEach((item) => {
        const event = this.events.find((x) => x.id === item.id);
        if (!event) {
          item.schedule.forEach((schedule) => {
            result.push({
              id: schedule.id,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              event_id: item.id,
              event_name: item.name ? item.name : item.type.replace("-", " "),
              alias: item.alias ? item.alias : item.theme.replace("-", " "),
              type_icon: this.computedColors[item.type].icon,
              color: this.computedColors[item.type].color,
              type_id: null,
              vuetify_color: this.computedColors[item.type].vuetify_color,
            });
          });
        } else {
          const type = event.type;

          item.schedule.forEach((schedule) => {
            result.push({
              id: schedule.id,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              event_id: event.id,
              event_name: event.name,
              alias: event.alias,
              type_icon: this.computedColors[type.slug].icon,
              color: this.computedColors[type.slug].color,
              type_id: type.id,
              vuetify_color: this.computedColors[type.slug].vuetify_color,
            });
          });
        }
      });

      return result;
    },

    //TODO: Package_name değişikliği applovin ?
    async fetchDeploymentSchedule() {
      try {
        if (!this.selected_deployment) return;
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/schedules/all?deployment_id=${this.selected_deployment}&package_name=${this.selected_game.applovin_android_package_name}&env=${this.env}`
        );

        this.liveEvents = this.transformData(request.data.events);
        this.liveEventsUnmodified = JSON.parse(
          JSON.stringify(request.data.events)
        );
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchLiveEvents() {
      try {
        if (!this.selected_game) return;
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/events/all?&package_name=${this.selected_game.applovin_android_package_name}`
        );

        this.events = request.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    getEventColor(event) {
      if (event.isClicked) {
        return "bg-rose-100 border border-rose-300 border-dashed text-rose-300";
      } else {
        return `border border-transparent ${event.customColor}`;
      }
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    getRandomDateTimestamp() {
      // Get the current month and year
      const currentMonth = moment().month();
      const currentYear = moment().year();
      // Generate a random day within the current month
      const randomDay =
        Math.floor(
          Math.random() *
            moment(
              `${currentYear}-${currentMonth + 1}`,
              "YYYY-MM"
            ).daysInMonth()
        ) + 1;
      // Create a moment object for the random date
      const randomDate = moment(
        `${currentYear}-${currentMonth + 1}-${randomDay}`,
        "YYYY-MM-DD"
      )
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .utc(true);
      // Return the timestamp of the random date
      return randomDate.valueOf();
    },

    getHoursFromDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("HH:mm");
    },
  },
  components: { EventPublishDialog, EventUpdateDialog },
  mounted() {
    this.fetchGames();
    this.fetchLiveEventTypes();
  },
  watch: {
    selected_game: {
      async handler(newValue) {
        if (newValue && this.env) {
          await this.fetchLiveEvents();
          await this.fetchDeployments(true);
        }
      },
      deep: true,
    },

    selected_deployment: {
      async handler(newValue) {
        if (newValue && this.env) {
          await this.fetchDeploymentSchedule();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["getCopiedEventData", "getUserProfile"]),
    isDeployBtnDisabled() {
      if (this.liveEvents.length) {
        if (
          !this.liveEvents.filter((x) => !x.id || x.delete || x.updated).length
        ) {
          return true;
        }
      }

      if (!this.selected_deployment) {
        return true;
      }

      if (
        this.selected_deployment !==
        this.deployments[this.deployments.length - 1].id
      ) {
        return true;
      }

      return false;
    },
    isDesigner() {
      if (this.getUserProfile) {
        return this.getUserProfile.department.id === departments.GAME_DESIGNERS;
      }
      return false;
    },
    isGameDeveloper() {
      if (this.getUserProfile) {
        return (
          this.getUserProfile.department.id === departments.GAME_DEVELOPERS
        );
      }
      return false;
    },
    env() {
      if (!this.getUserProfile) {
        return null;
      } else {
        return this.getUserProfile.game_backend_environment;
      }
    },
    computedColors() {
      return LIVE_OPS_TYPE_COLORS;
    },
    computedDraftColors() {
      return LIVE_OPS_DRAFT_TYPE_COLORS;
    },
    isPasteBtnDisabled() {
      if (!this.getCopiedEventData) return true;
      if (!this.selected_game) return true;

      return false;
    },
    computedTableData() {
      var data = [];
      if (this.liveEventsUnmodified.length && this.currentDate) {
        for (let i = 0; i < this.liveEventsUnmodified.length; i++) {
          const event = this.liveEventsUnmodified[i];
          const start_date = this.dateToUnix(
            this.currentDate.start.date,
            0,
            0,
            0,
            0
          );
          const end_date = this.dateToUnix(
            this.currentDate.end.date,
            0,
            0,
            0,
            0
          );

          const hasValidDate = event.schedule.some(
            (x) => start_date >= x.start_date && start_date <= end_date
          );
          if (hasValidDate) {
            data.push({
              id: event.live_event.id,
              name: event.live_event.name,
              description: event.live_event.description,
              type_id: event.live_event.type.id,
              type_name: event.live_event.type.name,
              icon: event.live_event.type.icon,
              color: event.live_event.type.color,
              vuetify_color: event.live_event.type.vuetify_color,
            });
          }
        }
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-calendar-weekly__day-label {
  margin: 0 !important;
}

::v-deep .v-calendar-weekly__day-label.draggable {
  background-color: rgba($color: #4a80de, $alpha: 0.1);
}
::v-deep .v-event {
  display: flex;
  align-items: center;
  .v-event-drag-bottom {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    top: 0;
    cursor: ew-resize;

    &::after {
      display: none;
      position: absolute;
      right: 4px;
      top: 50%;
      border-top: 2px solid white;
      border-bottom: 2px solid white;
      width: 8px;
      margin-left: -8px;
      height: 7px;
      transform: translate(0, -50%);
      opacity: 0.8;
      content: "";
    }

    &:hover::after {
      display: block;
    }
  }
}

::v-deep .v-event.primary {
  background-color: inherit !important;
}
</style>
