<template>
  <div>
    <v-dialog v-model="visible" width="auto">
      <v-card v-if="row" :loading="loading">
        <v-card-title class="text-h5">
          Creative Insights ({{ row.ad_name }})
        </v-card-title>

        <v-card-text class="pb-0 mb-0">
          <div v-if="combinations_loading">
            <div>
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
            </div>
            <div>
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
            </div>
            <div>
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </div>
          </div>
          <div
            class="mt-5"
            v-if="
              !combinations_loading && base_creative_data && combinations.length
            "
          >
            <div class="flex items-center justify-between mb-5">
              <h1 class="text-[13px] font-semibold">Combinations in Charts</h1>
              <div>
                <v-select
                  hide-details
                  multiple
                  class="text-[12px]"
                  :items="[
                    'Improvement',
                    'Impressions',
                    'CPI',
                    'CPM',
                    'CTR',
                    'IPM',
                  ]"
                  dense
                  v-model="chart_includes"
                  label="Include in Chart"
                  outlined
                ></v-select>
              </div>
            </div>
            <apex-chart
              type="line"
              height="350"
              :options="computedOptions"
              :series="computedSeries"
            ></apex-chart>
          </div>

          <div
            class="mt-5"
            v-if="
              !combinations_loading &&
              base_creative_data &&
              gaia_combinations.length
            "
          >
            <h1 class="text-[13px] mb-5 font-semibold">
              Combinations in Data Table
            </h1>

            <v-radio-group dense v-model="reference_choice" row>
              <v-radio label="Adset as a reference" value="adset"></v-radio>
              <v-radio
                label="Base creative as a reference"
                value="creative"
              ></v-radio>
            </v-radio-group>

            <v-data-table
              :loading="data_loading"
              :headers="headers"
              :items="combinations"
              :expanded.sync="expanded"
              show-expand
              single-expand
              item-key="ad_id"
              @item-expanded="handleOnItemExpanded"
            >
              <template v-slot:[`item.ad_name`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  {{ item.ad_name }}
                </span>
              </template>
              <template v-slot:[`item.impressions`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  {{ item.impressions.toLocaleString("de-DE") }}
                </span>
              </template>
              <template v-slot:[`item.cpi`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  $ {{ Math.round(item.cpi * 10000) / 10000 }}
                </span>
              </template>
              <template v-slot:[`item.cpm`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  $ {{ Math.round(item.cpm * 1000) / 1000 }}
                </span>
              </template>
              <template v-slot:[`item.ctr`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  {{ Math.round(item.ctr * 100) / 100 }} %
                </span>
              </template>
              <template v-slot:[`item.ipm`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  {{ Math.round(item.ipm * 100) / 100 }}
                </span>
              </template>
              <template v-slot:[`item.ref_impressions`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  {{ item.ref_impressions.toLocaleString("de-DE") }}
                </span>
              </template>
              <template v-slot:[`item.ref_cpi`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  $ {{ Math.round(item.ref_cpi * 10000) / 10000 }}
                </span>
              </template>
              <template v-slot:[`item.ref_cpm`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  $ {{ Math.round(item.ref_cpm * 1000) / 1000 }}
                </span>
              </template>
              <template v-slot:[`item.ref_ctr`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  {{ Math.round(item.ref_ctr * 100) / 100 }} %
                </span>
              </template>
              <template v-slot:[`item.ref_ipm`]="{ item }">
                <span class="text-gray-800 text-[12px] font-semibold">
                  {{ Math.round(item.ref_ipm * 100) / 100 }}
                </span>
              </template>
              <template v-slot:[`item.improvement`]="{ item }">
                <div
                  class="flex items-center"
                  :class="colorizeImprovement(item.improvement)"
                >
                  <span v-if="item.improvement > 0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-trending-up"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      stroke-width="1"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M3 17l6 -6l4 4l8 -8"></path>
                      <path d="M14 7l7 0l0 7"></path>
                    </svg>
                  </span>
                  <span v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-trending-down"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      stroke-width="1"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M3 7l6 6l4 -4l8 8"></path>
                      <path d="M21 10l0 7l-7 0"></path>
                    </svg>
                  </span>
                  <span class="text-[12px] font-semibold ml-2">
                    {{ Math.round(item.improvement * 100) / 100 }} %
                  </span>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div
                    class="grid grid-cols-2 gap-5"
                    v-if="
                      computedGameplayParams &&
                      getCombinationParameters(item.ad_id)
                    "
                  >
                    <div class="p-4 flex flex-col">
                      <h1 class="font-semibold text-[11px] text-rose-600 mb-3">
                        Default Parameters
                      </h1>
                      <!-- eslint-disable -->
                      <div class="flex flex-col">
                        <span
                          class="text-[11px]"
                          v-for="key in Object.keys(computedGameplayParams)"
                        >
                          "{{ key }}" : "{{ computedGameplayParams[key] }}"
                        </span>
                      </div>
                    </div>
                    <div class="p-4 flex flex-col">
                      <h1 class="font-semibold text-[11px] text-rose-600 mb-3">
                        Combination Parameters
                      </h1>
                      <!-- eslint-disable -->
                      <div class="flex flex-col">
                        <div
                          class="text-[11px]"
                          :class="
                            isDifferentThanOriginalValue(key, item.ad_id)
                              ? 'text-indigo-600'
                              : ''
                          "
                          v-for="key in Object.keys(
                            getCombinationParameters(item.ad_id)
                          )"
                        >
                          <span v-if="key !== 'assets'">
                            "{{ key }}" : "{{
                              getCombinationParameters(item.ad_id)[key]
                            }}"
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </template>
            </v-data-table>
          </div>

          <div
            v-if="
              !combinations_loading &&
              !base_creative_data &&
              !combinations.length
            "
            class="mt-5 bg-rose-50 text-rose-600 p-6 font-semibold text-[12px] rounded-[5px]"
          >
            Selected ad doesn't have any connected gameplay.
          </div>

          <div
            v-if="
              !combinations_loading &&
              base_creative_data &&
              !combinations.length
            "
            class="mt-5 bg-sky-50 text-sky-600 p-6 font-semibold text-[12px] rounded-[5px]"
          >
            There is no combination created for this creative or created
            combinations are still testing.
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-end">
            <v-btn
              color="blue darken-1"
              :loading="loading"
              text
              @click="visible = false"
            >
              Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      visible: false,
      loading: false,
      data_loading: false,
      combinations: [],
      combinations_loading: false,
      headers: [
        {
          text: "Title",
          value: "ad_name",
          class: "text-blue-400",
        },
        {
          text: "Impressions",
          value: "impressions",
          align: "center",
        },
        {
          text: "CPI",
          value: "cpi",
          align: "center",
        },
        {
          text: "CPM",
          value: "cpm",
          align: "center",
        },
        {
          text: "CTR",
          value: "ctr",
          align: "center",
        },
        {
          text: "IPM",
          value: "ipm",
          align: "center",
        },
        {
          text: "Ref. Impression",
          value: "ref_impressions",
          align: "center",
        },
        {
          text: "Ref. CPI",
          value: "ref_cpi",
          align: "center",
        },
        {
          text: "Ref. CPM",
          value: "ref_cpm",
          align: "center",
        },
        {
          text: "Ref. CTR",
          value: "ref_ctr",
          align: "center",
        },
        {
          text: "Ref. IPM",
          value: "ref_ipm",
          align: "center",
        },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      expanded_row_id: null,
      gaia_combinations: [],
      base_creative_data: null,
      chart_includes: ["Improvement"],
      reference_choice: "adset",
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.combinations = [];
        this.expanded = [];
        this.expanded_row_id = null;
        this.gaia_combinations = [];
        this.base_creative_data = null;
        this.reference_choice = "adset";
      }
    },
    row: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.fetchBaseCreative();
        }
      },
    },
    reference_choice() {
      this.fetchCombinationInsights();
    },
  },
  methods: {
    sortObject(obj) {
      return Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    isDifferentThanOriginalValue(key, ad_id) {
      const gameplayParams = this.computedGameplayParams;
      const combinationParams = this.getCombinationParameters(ad_id);

      if (Object.keys(gameplayParams).includes(key) === false) {
        return true;
      }

      if (gameplayParams[key] !== combinationParams[key]) {
        // Check type conversions
        // 1- Int - String
        if (
          typeof gameplayParams[key] === "string" &&
          typeof combinationParams[key] === "number"
        ) {
          return gameplayParams[key] === combinationParams[key].toString()
            ? false
            : true;
        }

        if (
          typeof gameplayParams[key] === "number" &&
          typeof combinationParams[key] === "string"
        ) {
          return gameplayParams[key].toString() === combinationParams[key]
            ? false
            : true;
        }

        return _.isEqual(gameplayParams[key], combinationParams[key])
          ? false
          : true;
      }

      return false;
    },
    getCombinationParameters(ad_id) {
      const combination = this.gaia_combinations.filter(
        (x) => x.general_creative.ad_fb_id === ad_id
      );
      return combination.length
        ? this.sortObject(JSON.parse(combination[0].params))
        : null;
    },
    handleOnItemExpanded(event) {
      this.expanded_row_id = event.item.ad_id;
    },
    fetchCombinationInsights() {
      if (this.row) {
        this.combinations_loading = true;
        this.data_loading = true;
        this.combinations = [];
        this.$api
          .get(
            `act/general-creatives/combinations/fb/${this.row.ad_id}?ref_type=${this.reference_choice}`
          )
          .then((response) => {
            this.combinations = response.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.combinations_loading = false;
            this.data_loading = false;
          });
      }
    },
    colorizeImprovement(improvement) {
      if (improvement > 0 && improvement < 15) {
        return "text-green-800";
      }
      if (improvement >= 15 && improvement < 30) {
        return "text-green-700";
      }
      if (improvement >= 30 && improvement < 50) {
        return "text-green-600";
      }
      if (improvement >= 50) {
        return "text-green-500";
      }
      if (improvement < 0 && improvement > -15) {
        return "text-red-800";
      }
      if (improvement <= 15 && improvement > -30) {
        return "text-red-700";
      }
      if (improvement <= 30 && improvement > -50) {
        return "text-red-600";
      }
      if (improvement <= 50) {
        return "text-red-500";
      }
    },
    fetchCombinationParams() {
      this.$api
        .get(`act/general-creatives/fb/${this.row.ad_id}/combinations`)
        .then((response) => {
          this.gaia_combinations = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchBaseCreative() {
      this.$api
        .get(`acg/base-creatives/general-creatives/${this.row.ad_id}`)
        .then((response) => {
          if (response.status === 200) {
            this.base_creative_data = response.data;
            this.fetchCombinationInsights();
            this.fetchCombinationParams();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    computedGameplayParams() {
      return this.base_creative_data.used_parameters
        ? this.sortObject(JSON.parse(this.base_creative_data.used_parameters))
        : null;
    },
    computedSeries() {
      var series = [];

      if (this.combinations && this.combinations.length > 0) {
        this.chart_includes.forEach((element) => {
          if (element === "Improvement") {
            series.push({
              name: "Creative Improvement",
              data: this.combinations.map(
                (x) => Math.round(x.improvement * 100) / 100
              ),
            });
          }
          if (element === "Impressions") {
            series.push({
              name: "Creative Impressions",
              data: this.combinations.map(
                (x) => Math.round(x.impressions * 10) / 10
              ),
            });

            series.push({
              name: "Ref. Impressions",
              data: this.combinations.map(
                (x) => Math.round(x.ref_impressions * 10) / 10
              ),
            });
          }

          if (element === "CPI") {
            series.push({
              name: "Creative CPI",
              data: this.combinations.map(
                (x) => Math.round(x.cpi * 10000) / 10000
              ),
            });

            series.push({
              name: "Ref. CPI",
              data: this.combinations.map(
                (x) => Math.round(x.ref_cpi * 10000) / 10000
              ),
            });
          }

          if (element === "CPM") {
            series.push({
              name: "Creative CPM",
              data: this.combinations.map(
                (x) => Math.round(x.cpm * 1000) / 1000
              ),
            });

            series.push({
              name: "Ref. CPM",
              data: this.combinations.map(
                (x) => Math.round(x.ref_cpm * 1000) / 1000
              ),
            });
          }

          if (element === "CTR") {
            series.push({
              name: "Creative CTR",
              data: this.combinations.map((x) => Math.round(x.ctr * 100) / 100),
            });

            series.push({
              name: "Ref. CTR",
              data: this.combinations.map(
                (x) => Math.round(x.ref_ctr * 100) / 100
              ),
            });
          }
          if (element === "IPM") {
            series.push({
              name: "Creative IPM",
              data: this.combinations.map((x) => Math.round(x.ipm * 100) / 100),
            });

            series.push({
              name: "Ref. IPM",
              data: this.combinations.map(
                (x) => Math.round(x.ref_ipm * 100) / 100
              ),
            });
          }
        });
      }

      // 'Impressions',
      // 'CPI',
      // 'CPM',
      // 'CTR',
      // 'IPM',

      return series;
    },
    computedOptions() {
      const options = {
        chart: {
          type: "line",
          height: 350,
          toolbar: {
            show: false,
          },
          fontFamily: '"Open Sans", sans-serif',
        },
        plotOptions: {},
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          categories: this.combinations.map((x) => x.ad_name.split("_").pop()),
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
      return options;
    },
  },
};
</script>

<style lang="scss" scoped></style>
