<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card v-if="data">
        <v-card-title class="text-h5"> Store Page Summary </v-card-title>
        <v-card-text>
          <div class="pt-4" v-if="data.selectedStorePage">
            <div class="font-semibold">Selected Game</div>
            <div>
              {{ data.selectedStorePage.game_name }}
            </div>
          </div>
          <div class="pt-2">
            <div class="font-semibold">iOS Subtitle</div>
            <div>{{ data.ios_subtitle }}</div>
          </div>
          <div class="pt-2">
            <div class="font-semibold">Long Description</div>
            <div>{{ data.long_desc }}</div>
          </div>
          <div class="pt-2" v-if="data.icon">
            <div class="font-semibold">Icon Preview</div>
            <img
              class="rounded mt-2"
              :src="computedSRC(data.icon)"
              width="90"
            />
          </div>
          <div
            class="pt-2"
            v-if="data.screenshots && data.screenshots.length > 0"
          >
            <div class="font-semibold">Screenshoots</div>
            <div class="grid grid-cols-5 mt-2 gap-3">
              <img
                v-for="(item, index) in data.screenshots"
                :src="computedSRC(item)"
                :key="index"
                class="rounded"
              />
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleApprove">
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      immediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    handleApprove() {
      this.$emit("approved");
      this.visible = false;
    },
    computedSRC(file) {
      return URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss" scoped></style>
