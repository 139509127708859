import ProjectsView from "@/views/tools/game_backend/ProjectsView.vue";
import TokensView from "@/views/tools/game_backend/TokensView.vue";
import EventsView from "@/views/tools/game_backend/EventsView.vue";
import EventCalendarDevView from "@/views/tools/game_backend/EventCalendarDevView.vue";
import EventCalendarProdView from "@/views/tools/game_backend/EventCalendarProdView.vue";
import EventCalendarTestView from "@/views/tools/game_backend/EventCalendarTestView.vue";
import ExperimentsView from "@/views/tools/game_backend/ExperimentsView.vue";

export const game_backend_router = [
  {
    path: "/game-backend/projects",
    name: "game-backend-projects",
    component: ProjectsView,
    meta: {
      title: "Projects",
    },
  },
  {
    path: "/game-backend/tokens",
    name: "tokens-view",
    component: TokensView,
    meta: {
      title: "Tokens",
    },
  },
  {
    path: "/game-backend/development/event-calendar",
    name: "event-calendar-dev-view",
    component: EventCalendarDevView,
    meta: {
      title: "Live Events",
    },
  },
  {
    path: "/game-backend/production/event-calendar",
    name: "event-calendar-prod-view",
    component: EventCalendarProdView,
    meta: {
      title: "Live Events",
    },
  },
  {
    path: "/game-backend/test/event-calendar",
    name: "event-calendar-test-view",
    component: EventCalendarTestView,
    meta: {
      title: "Live Events",
    },
  },
  {
    path: "/game-backend/events",
    name: "events-view",
    component: EventsView,
    meta: {
      title: "Events",
    },
  },
  {
    path: "/game-backend/experiments",
    name: "experiments-view",
    component: ExperimentsView,
    meta: {
      title: "Experiments",
    },
  },
];
