<template>
  <div>
    <div class="flex justify-end mb-4">
      <v-checkbox
        hide-details
        label="Include paused adgroups"
        v-model="showPausedAdgroups"
        style="margin-top: 0"
      ></v-checkbox>
    </div>
    <div class="border rounded">
      <v-expansion-panels accordion focusable flat v-model="selectedAdsetPanel">
        <v-expansion-panel
          v-for="(adgroup, index) in adgroupData.filter((x) =>
            showPausedAdgroups ? true : x.status === 'ACTIVE'
          )"
          :key="index"
        >
          <v-expansion-panel-header>
            <div class="flex items-center">
              <v-icon
                size="12"
                class="mr-2"
                :color="adgroup.status === 'PAUSED' ? 'red' : 'green'"
              >
                mdi-circle
              </v-icon>
              <span class="font-semibold text-[11px] text-blue-900">
                {{ adgroup.campaign.name }}
              </span>
              <span class="mx-2 font-semibold text-[10px]"> > </span>
              <span class="font-semibold text-[11px] text-rose-900">
                {{ adgroup.name }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="rounded bg-sky-50 text-sky-800 font-semibold p-5 text-[11px] mt-5 border border-sky-200"
            >
              {{
                adgroup.selectedAdsForAdding.filter((x) => x.selected).length
              }}
              creative(s) will be added and
              {{ adgroup.selectedAdsForDeleting.length }} creative(s) will be
              deleted.
            </div>
            <h1
              class="mt-4 mb-0 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
            >
              <span> 1. Select creatives to add </span>
            </h1>
            <div class="pt-3">
              <div class="w-full mb-5">
                <div class="grid grid-cols-4 gap-3">
                  <div
                    v-for="(ad, selectedIdx) in adgroup.selectedAdsForAdding"
                    :key="ad.ad_id"
                    class="rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100"
                    :class="{
                      selected: ad.selected,
                    }"
                    @click="selectAd(index, selectedIdx)"
                  >
                    <div class="flex items-center justify-between mb-2">
                      <div class="flex items-center">
                        <span
                          class="text-[11px]"
                          :class="
                            ad.selected
                              ? 'text-gray-700 font-semibold'
                              : 'text-gray-400'
                          "
                        >
                          {{ ad.ad_name }}
                        </span>
                        <span
                          class="text-[10px] ml-2"
                          v-if="ad.improvement"
                          :class="{
                            'font-semibold': ad.selected,
                            'text-gray-400': !ad.selected,
                            'text-lime-700': ad.improvement >= 0 && ad.selected,
                            'text-rose-700': ad.improvement < 0 && ad.selected,
                          }"
                        >
                          {{ ad.improvement.toFixed(2) }}%
                        </span>
                      </div>
                      <span
                        class="text-[9px] uppercase font-semibold p-1 rounded-[3px] hover:bg-gray-300 cursor-pointer select-none"
                        @click="
                          handlePreviewBtn(
                            $event,
                            ad.gaia_id,
                            ad.selected_playable_id
                          )
                        "
                        :class="
                          ad.selected
                            ? 'text-gray-700'
                            : 'text-gray-400 pointer-events-none'
                        "
                      >
                        Preview
                      </span>
                    </div>

                    <div class="mt-2">
                      <v-checkbox
                        dense
                        v-model="ad.is_playable"
                        hide-details
                        label="Create as playable"
                        class="text-[10px]"
                        @click.stop
                        :disabled="!ad.selected"
                      >
                      </v-checkbox>
                    </div>

                    <div class="mt-2" @click.stop>
                      <v-autocomplete
                        clearable
                        label="Choose an asset"
                        outlined
                        auto-select-first
                        dense
                        class="text-[12px]"
                        v-model="ad.selected_playable_id"
                        :items="facebookAssetData"
                        hide-details
                        :disabled="!ad.is_playable"
                        item-text="title"
                        item-value="id"
                      >
                      </v-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider class="my-5"></v-divider>
              <h1
                class="mb-2 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
              >
                <span>2. Select Creatives to Replace </span>
              </h1>

              <h1 class="text-gray-800 font-semibold text-[11px] mb-3">
                Average IPM: {{ computedAverageIpm(adgroup) }}
              </h1>
              <h1 class="text-gray-800 font-semibold text-[11px] mb-3">
                Average Impressions: {{ computedAverageImpression(adgroup) }}
              </h1>

              <div>
                <v-data-table
                  :headers="facebookAdHeaders"
                  :items="sortedAdData(adgroup.ads)"
                  :items-per-page="-1"
                  v-model="adgroup.selectedAdsForDeleting"
                  :single-select="false"
                  show-select
                  item-key="id"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:[`item.ipm`]="{ item }">
                    <span
                      class="text-[11px] font-semibold"
                      :class="
                        item.insights.ipm >= computedAverageIpm(adgroup)
                          ? 'text-lime-700'
                          : item.insights.ipm
                          ? 'text-rose-600'
                          : '-'
                      "
                    >
                      {{
                        item.insights && item.insights.ipm
                          ? item.insights.ipm
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.ctr`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{
                        item.insights && item.insights.ctr
                          ? item.insights.ctr
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.spend`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{
                        item.insights && item.insights.spend
                          ? item.insights.spend
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <span
                      class="text-[11px] font-semibold"
                      :class="{
                        'text-red-600': item.status === 'PAUSED',
                        'text-green-600': item.status === 'ACTIVE',
                      }"
                    >
                      {{ item.status }}
                    </span>
                  </template>
                  <template v-slot:[`item.impressions`]="{ item }">
                    <span
                      class="text-[11px] font-semibold"
                      :class="
                        item.insights &&
                        item.insights.impressions >=
                          computedAverageImpression(adgroup)
                          ? 'text-lime-700'
                          : item.insights.impressions
                          ? 'text-rose-600'
                          : '-'
                      "
                    >
                      {{
                        item.insights && item.insights.impressions
                          ? item.insights.impressions
                          : "-"
                      }}
                    </span>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- <div class="mt-3">
      <h1 class="text-gray-700 font-bold">Summary</h1>
      <div>
        <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
          Selected creatives will be
          <span class="text-lime-600">added</span> into these adsets:
        </h1>
        <div class="mt-2">
          <div
            v-for="(data, idx) in computedImportedDataForSummary"
            :key="idx"
            class="mb-1.5"
          >
            <div class="font-semibold text-[11px] flex items-center">
              <span class="text-sky-600">
                {{ data.creative_name }}
              </span>
              <span class="ml-2 mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-arrow-narrow-right"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  stroke-width="1.75"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M5 12l14 0"></path>
                  <path d="M15 16l4 -4"></path>
                  <path d="M15 8l4 4"></path>
                </svg>
              </span>
              <span
                v-for="adgroup in data.adgroup_data"
                :key="adgroup.adgroup_id"
                class="text-gray-700 p-1 rounded-[3px] bg-gray-100 ml-1 hover:bg-gray-200"
              >
                {{ adgroup.adgroup_name }}
              </span>
            </div>
          </div>
        </div>

        <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
          These creatives will be completely
          <span class="text-rose-600">deleted</span> from these adsets:
        </h1>

        <div class="mt-2">
          <div v-for="adgroup in adgroupData" :key="adgroup.id">
            <div v-if="adgroup.selectedAdsForDeleting.length">
              <div
                class="font-semibold text-[11px] flex items-center mb-1.5"
                v-for="(ad, adIdx) in adgroup.selectedAdsForDeleting"
                :key="adIdx"
              >
                <span class="text-sky-600">
                  {{ ad.name }}
                </span>
                <span class="ml-2 mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-narrow-right"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    stroke-width="1.75"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l14 0"></path>
                    <path d="M15 16l4 -4"></path>
                    <path d="M15 8l4 4"></path>
                  </svg>
                </span>
                <span
                  class="text-gray-700 p-1 rounded-[3px] bg-gray-100 ml-1 hover:bg-gray-200"
                >
                  {{ adgroup.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="flex justify-end items-center my-3">
      <v-btn
        color="primary"
        depressed
        class="font-semibold"
        @click="showSummary = true"
        :loading="loadingBtn"
        :disabled="loadingBtn"
      >
        Replace Creatives
      </v-btn>
    </div>

    <PreviewDialog
      :gaia_id="preview_selected_gaia_id"
      :playable_id="preview_selected_playable_id"
      :show="showPreview"
      @closed="handlePreviewDialogClose"
    />

    <SummaryDialog
      :adgroupData="
        showPausedAdgroups
          ? adgroupData
          : adgroupData.filter((x) => x.status === 'ACTIVE')
      "
      :selectedCreatives="selectedCreatives"
      :show="showSummary"
      @closed="handleSummaryDialogClose"
      @submitted="replaceCreatives"
    />
  </div>
</template>

<script>
import _ from "lodash";
import PreviewDialog from "./PreviewDialog.vue";
import SummaryDialog from "./SummaryDialog.vue";

export default {
  props: {
    selectedCampaigns: {
      type: Array,
      default: () => [],
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    game_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    selectedCampaigns: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.length && this.selectedCreatives.length) {
          this.adgroupData = this.facebookDataModifier(newValue);
        }
      },
    },
  },
  data() {
    return {
      showPausedAdgroups: true,
      selectedAds: [],
      loading: false,
      fbAdsetData: [],
      adData: [],
      selectedAdsetPanel: null,
      facebookAdHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "IPM",
          value: "ipm",
          sortable: false,
        },
        {
          text: "CTR",
          value: "ctr",
          sortable: false,
        },
        {
          text: "Spend",
          value: "spend",
          sortable: false,
        },
        {
          text: "Impressions",
          value: "impressions",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
      ],
      adgroupData: [],
      facebookAssetData: [],
      loadingBtn: false,
      showPreview: false,
      preview_selected_gaia_id: null,
      preview_selected_playable_id: null,
      showSummary: false,
    };
  },
  methods: {
    handleSummaryDialogClose() {
      this.showSummary = false;
    },
    handlePreviewDialogClose() {
      this.showPreview = false;
      this.preview_selected_gaia_id = null;
      this.preview_selected_playable_id = null;
    },
    handlePreviewBtn($event, gaia_id, playable_id) {
      $event.stopPropagation();
      this.preview_selected_gaia_id = gaia_id;
      this.preview_selected_playable_id = playable_id;
      this.showPreview = true;
    },
    selectAd(adgroupIndex, adIndex) {
      this.adgroupData[adgroupIndex].selectedAdsForAdding[adIndex].selected =
        !this.adgroupData[adgroupIndex].selectedAdsForAdding[adIndex].selected;
    },
    async replaceCreatives() {
      if (this.computedCreateData.length || this.computedDeleteData.length) {
        this.showSummary = false;
        const requestData = {
          create_data: this.computedCreateData,
          delete_data: this.computedDeleteData,
          game_id: this.game_id,
        };
        await this.createFacebookTask(requestData);
      }
    },
    async createFacebookTask(data) {
      try {
        this.loadingBtn = true;
        const request = await this.$api.post(
          `burcha/facebook/tasks/create/create-task`,
          data
        );

        if (request.status === 200) {
          this.$toast.success("Tasks successfully created in FB.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          this.$router.push({ name: "home" });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingBtn = false;
      }
    },
    facebookDataModifier(data) {
      if (!data.length) return [];
      var adgroups = [];

      data.forEach((campaign) => {
        campaign.adgroups.forEach((adgroup) => {
          adgroups.push({
            ...adgroup,
            campaign_id: campaign.campaign_id,
            selectedAdsForDeleting: [],
            selectedAdsForAdding: this.selectedCreatives.map((x) => ({
              ...x,
              selected: true,
              is_playable: false,
              selected_playable_id: null,
            })),
          });
        });
      });

      return adgroups;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    sortedAdData(data) {
      const sortedData = _.orderBy(data, "insights.impressions", "desc");
      return sortedData;
    },
    computedAverageIpm(adgroupData) {
      if (!adgroupData.ads.length) {
        return null;
      }

      var total = 0;
      for (let y = 0; y < adgroupData.ads.length; y++) {
        const ad = adgroupData.ads[y];
        total += ad.insights.ipm ? parseFloat(ad.insights.ipm) : 0;
      }

      const length = adgroupData.ads.filter((x) =>
        x.insights.ipm ? true : false
      ).length;
      return length ? Math.round((total / length) * 100) / 100 : 0;
    },
    computedAverageImpression(adgroupData) {
      if (!adgroupData.ads.length) {
        return null;
      }

      var total = 0;
      for (let y = 0; y < adgroupData.ads.length; y++) {
        const ad = adgroupData.ads[y];
        total += ad.insights.impressions
          ? parseInt(ad.insights.impressions)
          : 0;
      }

      const length = adgroupData.ads.filter((x) =>
        x.insights.impressions ? true : false
      ).length;

      return length ? Math.round((total / length) * 100) / 100 : 0;
    },
    async fetchPlayableAssets() {
      try {
        const request = await this.$api.get("burcha/playables/facebook/all");
        if (request) {
          this.facebookAssetData = request.data;
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
  },
  computed: {
    computedCreateData() {
      var data = [];

      this.adgroupData.forEach((adgroup) => {
        adgroup.selectedAdsForAdding.forEach((x) => {
          if (x.selected) {
            data.push({
              campaign_id: adgroup.campaign_id,
              campaign_name: adgroup.campaign.name,
              adset_id: adgroup.id,
              gaia_id: x.gaia_id,
              facebook_playable_id: x.selected_playable_id,
              adset_name: adgroup.name,
              general_creative_title: x.ad_name,
              status: adgroup.status,
            });
          }
        });
      });

      if (!this.showPausedAdgroups) {
        data = data.filter((x) => x.status === "ACTIVE");
      }

      return data;
    },
    computedDeleteData() {
      var data = [];

      this.adgroupData.forEach((adgroup) => {
        adgroup.selectedAdsForDeleting.forEach((x) => {
          data.push({
            campaign_id: x.campaign_id,
            campaign_name: x.campaign_name,
            adset_id: x.adset_id,
            ad_id: x.id,
            adset_name: adgroup.name,
            ad_name: x.name,
            status: adgroup.status,
          });
        });
      });

      if (!this.showPausedAdgroups) {
        data = data.filter((x) => x.status === "ACTIVE");
      }

      return data;
    },
  },
  async mounted() {
    await this.fetchPlayableAssets();
  },
  components: {
    PreviewDialog,
    SummaryDialog,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-footer {
  border-top: none !important;
}
::v-deep table {
  border: 1px solid rgba($color: #000000, $alpha: 0.15);
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.selectedAdSection {
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 2px 4px;
}

.selectedAdSection.selected {
  box-shadow: rgba(63, 216, 16, 0.4) 0px 0px 0px 1px,
    rgba(22, 93, 0, 0.1) 0px 2px 4px;
}

::v-deep .v-label {
  font-size: 10px !important;
  font-weight: 600;
}
</style>
