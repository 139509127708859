<template>
  <div>
    <v-navigation-drawer v-model="visible" absolute bottom right :width="1024">
      <div class="p-6">
        <h1 class="font-semibold text-[18px] mb-4">Variant {{ title }}</h1>

        <h1
          class="font-semibold text-[14px] w-full mb-2.5 flex items-center justify-between"
        >
          <span v-if="currentDate" class="text-[14px] font-semibold w-full">
            {{ returnMonth(currentDate.start.date) }}
          </span>

          <div class="flex items-center">
            <v-btn icon class="ma-2" @click="$refs.calendarVariant.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon class="ma-2" @click="$refs.calendarVariant.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </h1>

        <div class="flex items-center justify-end w-full mb-5">
          <div class="flex items-center w-full justify-end">
            <div>
              <v-icon
                dense
                @click="deleteCalendarPage"
                size="20"
                style="padding: 3px; margin-right: 16px"
                color="red darken-4"
              >
                mdi-delete-outline
              </v-icon>
            </div>

            <div>
              <v-icon
                dense
                @click="copyCalendarPage"
                small
                style="padding: 5px; margin-right: 16px"
                color="grey darken-4"
              >
                mdi-content-copy
              </v-icon>
            </div>

            <div>
              <v-icon
                dense
                @click="handleOnEventInsert(getCopiedEventData, true)"
                small
                style="padding: 5px; margin-right: 16px"
                color="grey darken-4"
                :disabled="isPasteBtnDisabled"
              >
                mdi-content-paste
              </v-icon>
            </div>
          </div>
        </div>

        <v-calendar
          style="min-height: 600px"
          ref="calendarVariant"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="
            liveEvents.map((x) => ({ ...x, end_date: minusDate(x.end_date) }))
          "
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          @click:event="handleOnCalendarEvent"
          @click:date="handleOnClickDay"
          :event-ripple="false"
          :event-text-color="''"
          event-end="end_date"
          event-start="start_date"
          @change="handleOnChangeCalendar"
        >
          <template v-slot:event="{ event }">
            <div>
              <div>
                <div
                  class="flex items-center px-1.5"
                  style="line-height: normal"
                >
                  <span
                    class="font-semibold text-[10px] mr-1"
                    v-if="isEventDeleted(event)"
                  >
                    (Delete)
                  </span>
                  <v-icon
                    size="18"
                    class="ml-1.5 mr-0"
                    :color="event.vuetify_color"
                  >
                    {{ `mdi-${event.type_icon}` }}
                  </v-icon>
                  <div
                    class="ml-1.5 font-semibold text-[11px] flex items-center"
                  >
                    <span class="capitalize">
                      {{ event.event_name }}
                    </span>
                    <span class="ml-1.5 capitalize" v-if="event.alias">
                      ({{ event.alias }})</span
                    >
                  </div>

                  <span class="ml-1.5 text-[11px]" v-if="!event.id">
                    (Draft)
                  </span>
                </div>
              </div>
            </div>
          </template>
        </v-calendar>
      </div>

      <div class="flex items-center justify-between px-6 pt-0">
        <v-btn
          color="error"
          depressed
          class="font-semibold"
          @click="visible = false"
          text
        >
          Cancel
        </v-btn>
        <v-btn
          color="secondary"
          depressed
          class="font-semibold"
          @click="submitVariant"
        >
          Submit
        </v-btn>
      </div>

      <EventPublishDialog
        :show="showInsertUpdateDialog"
        @closed="handleCloseEventPublishDialog"
        :data="showInsertUpdateDialogData"
        :mode="'insert'"
        :games="games"
        @insert="handleOnEventInsert($event, false)"
        :eventTypes="eventTypes"
        :events="events"
      ></EventPublishDialog>

      <EventUpdateDialog
        :show="showUpdateDialog"
        @closed="showUpdateDialog = false"
        :data="showUpdateDialogData"
        :previewData="showUpdateDialogPreviewData"
        :mode="'update'"
        :games="games"
        @update="handleOnEventUpdate($event)"
        @delete="deleteLiveEventFromCalendar"
        :eventTypes="eventTypes"
        :events="events"
        :env="'test'"
      ></EventUpdateDialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import EventPublishDialog from "./EventPublishDialog.vue";
import EventUpdateDialog from "./EventUpdateDialog.vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import {
  LIVE_OPS_DRAFT_TYPE_COLORS,
  LIVE_OPS_TYPE_COLORS,
} from "@/helper/utils";

export default {
  data() {
    return {
      visible: false,
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      liveEvents: [],
      currentDate: null,
      insertDay: null,
      showUpdateDialogPreviewData: null,
      showUpdateDialog: false,
      showUpdateDialogData: null,
      showInsertUpdateDialog: false,
      showInsertUpdateDialogData: null,
    };
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    games: {
      type: Array,
      default: () => [],
    },
    eventTypes: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    liveEventsUnmodified: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    data(newValue) {
      this.liveEvents = newValue ? JSON.parse(JSON.stringify(newValue)) : [];
    },
  },
  methods: {
    ...mapActions(["setCopiedEventData"]),
    handleCloseEventPublishDialog() {
      this.showInsertUpdateDialog = false;
      this.showInsertUpdateDialogData = null;
    },
    minusDate(date) {
      return moment(date).subtract(1, "seconds").format("YYYY-MM-DDTHH:mm:ss");
    },
    plusDate(date) {
      return moment(date).add(1, "seconds").format("YYYY-MM-DDTHH:mm:ss");
    },
    submitVariant() {
      if (this.readOnly) {
        this.$toast.error("You can not update calendar on Prod environment.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      }
      this.$emit("submit", this.index, this.liveEvents);
      this.visible = false;
      this.$toast.success("You have successfully submitted variant.", {
        timeout: 5000,
        position: "bottom-center",
        icon: false,
      });
    },

    setDeletedColor(index) {
      this.$set(
        this.liveEvents[index],
        "color",
        `bg-gray-100 border-gray-300 text-gray-900 border border-dashed rounded hover:bg-gray-200`
      );
      this.$set(this.liveEvents[index], "vuetify_color", `grey darken-4`);
    },

    async deleteLiveEventFromCalendar(data, withRecurrings) {
      this.showUpdateDialog = false;
      this.showUpdateDialogData = null;
      this.showUpdateDialogPreviewData = null;

      // Delete live event from deployment.
      if (data.id) {
        const index = this.liveEvents.findIndex((x) => x.id === data.id);
        if (index > -1) {
          if (withRecurrings) {
            for (let i = 0; i < this.liveEvents.length; i++) {
              const scheduleEvent = this.liveEvents[i];
              if (scheduleEvent.event_id === data.event_id) {
                this.setDeletedColor(i);
                this.$set(this.liveEvents[i], "delete", true);
              }
            }
          } else {
            this.$set(this.liveEvents[index], "delete", true);
            this.$set(
              this.liveEvents[index],
              "deleteWithRecurrings",
              withRecurrings
            );
            this.setDeletedColor(index);
          }
        }
      } else {
        if (data.tempId) {
          this.liveEvents = this.liveEvents.filter(
            (x) => x.tempId != data.tempId
          );
          this.$toast.success(
            "You have successfully removed the live event from calendar.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      }
    },

    deleteCalendarPage() {
      if (this.readOnly) {
        this.$toast.error("You can not update calendar on Prod environment.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      }
      const start_date = moment(
        this.$refs.calendarVariant.lastStart.date,
        "YYYY-MM-DD"
      );
      const end_date = moment(
        this.$refs.calendarVariant.lastEnd.date,
        "YYYY-MM-DD"
      );

      const events = this.liveEvents.filter((x) => {
        const eventStartDate = moment(x.start_date, "YYYY-MM-DD");
        if (eventStartDate >= start_date && eventStartDate <= end_date) {
          return true;
        }
        return false;
      });

      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        this.deleteLiveEventFromCalendar(event, false);
      }
    },

    copyCalendarPage() {
      const start_date = moment(
        this.$refs.calendarVariant.lastStart.date,
        "YYYY-MM-DD"
      );
      const end_date = moment(
        this.$refs.calendarVariant.lastEnd.date,
        "YYYY-MM-DD"
      );

      const events = this.liveEvents.filter((x) => {
        const eventStartDate = moment(x.start_date, "YYYY-MM-DD");
        if (eventStartDate >= start_date && eventStartDate <= end_date) {
          return true;
        }
        return false;
      });

      if (events.length) {
        this.setCopiedEventData(JSON.parse(JSON.stringify(events)));

        this.$toast.success(`Current calendar page has been copied!`, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
      }
    },

    async handleOnEventInsert(tempEventData, isCopied) {
      if (this.readOnly) {
        this.$toast.error("You can not update calendar on Prod environment.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      }
      let data = [];

      if (this.$isArr(tempEventData)) {
        data = [...tempEventData];
      } else if (
        this.$isObj(tempEventData) &&
        Object.keys(tempEventData).length
      ) {
        data = [tempEventData];
      }

      for (let i = 0; i < data.length; i++) {
        const event = data[i];
        try {
          const eventInstance = this.events.find(
            (instance) => instance.id === event.event_id
          );

          if (!eventInstance) {
            throw new Error("Selected event not found.");
          }
          // this.createLoading = true;
          if (event.isRecurring) {
            var periodLength = 0;
            var start_date = moment(event.start_date, "YYYY-MM-DD HH:mm");
            var end_date = moment(event.end_date, "YYYY-MM-DD HH:mm");

            const eventLength = end_date.diff(start_date, "seconds");
            const tempId = this.$generateRandomId(6);
            for (let i = 0; i < event.recurringOptions.amount + 1; i++) {
              if (event.recurringOptions.period.mode === "Days") {
                periodLength = event.recurringOptions.period.value * 86400;
              }

              this.liveEvents.push({
                id: null,
                event_id: event.event_id,
                start_date:
                  i === 0 ? start_date.format("YYYY-MM-DD HH:mm") : start_date,
                end_date:
                  i === 0 ? end_date.format("YYYY-MM-DD HH:mm") : end_date,
                color: this.computedDraftColors[eventInstance.type.slug].color,
                isRecurring: event.isRecurring,
                recurringOptions: event.recurringOptions,
                tempId: tempId,
                event_name: event.event_name,
                alias: event.alias,
                copied: i === 0 ? false : true,
              });

              start_date = moment(end_date, "YYYY-MM-DD HH:mm").add(
                periodLength,
                "seconds"
              );

              if (event.recurringOptions.period.mode === "Days") {
                const baseStartDate = moment(
                  event.start_date,
                  "YYYY-MM-DD HH:mm"
                );

                start_date = start_date.set({
                  hours: baseStartDate.hours(),
                  minutes: baseStartDate.minutes(),
                  seconds: baseStartDate.seconds(),
                });
              }

              start_date = start_date.format("YYYY-MM-DD HH:mm");

              end_date = moment(start_date, "YYYY-MM-DD HH:mm")
                .add(eventLength, "seconds")
                .format("YYYY-MM-DD HH:mm");
            }
          } else {
            if (!this.$isArr(event)) {
              this.liveEvents.push({
                id: null,
                event_id: event.event_id,
                start_date: event.start_date,
                end_date: event.end_date,
                color: this.computedDraftColors[eventInstance.type.slug].color,
                isRecurring: event.isRecurring,
                recurringOptions: event.recurringOptions,
                tempId: this.$generateRandomId(6),
                event_name: event.event_name,
                alias: event.alias,
                copied: false,
              });
            } else if (this.$isArr(event)) {
              this.liveEvents = [
                ...this.liveEvents,
                ...event.map((x) => ({
                  ...x,
                  id: null,
                  color:
                    this.computedDraftColors[eventInstance.type.slug].color,
                  copied: false,
                  tempId: this.$generateRandomId(6),
                })),
              ];
            }

            if (isCopied) {
              this.value = moment.unix(event.start_date).format("YYYY-MM-DD");
            }
          }

          this.$toast.success(
            "You have successfully inserted the live event.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.createLoading = false;
        }
      }
    },

    async handleOnEventDelete(data, withRecurrings) {
      this.showUpdateDialog = false;
      this.showUpdateDialogData = null;
      // Delete live event from deployment.
      if (data.id) {
        const index = this.liveEvents.findIndex((x) => x.id === data.id);
        if (index > -1) {
          if (withRecurrings) {
            this.liveEvents = this.liveEvents.filter(
              (x) => x.event_id !== data.event_id
            );
          } else {
            this.liveEvents.splice(index, 1);
          }
        }
      } else {
        if (data.tempId) {
          this.liveEvents = this.liveEvents.filter(
            (x) => x.tempId != data.tempId
          );
          this.$toast.success(
            "You have successfully removed the live event from calendar.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      }
    },
    transformData(data) {
      if (!this.events) {
        return;
      }

      const result = [];

      data.forEach((item) => {
        const event = this.events.find((x) => x.id === item.id);
        if (!event) {
          item.schedule.forEach((schedule) => {
            result.push({
              id: schedule.id,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              event_id: item.id,
              event_name: item.name ? item.name : item.type.replace("-", " "),
              alias: item.alias ? item.alias : item.theme.replace("-", " "),
              type_icon: this.computedColors[item.type].icon,
              color: this.computedColors[item.type].color,
              type_id: null,
              vuetify_color: this.computedColors[item.type].vuetify_color,
            });
          });
        } else {
          const type = event.type;

          item.schedule.forEach((schedule) => {
            result.push({
              id: schedule.id,
              start_date: schedule.start_date,
              end_date: schedule.end_date,
              event_id: event.id,
              event_name: event.name,
              alias: event.alias,
              type_icon: this.computedColors[type.slug].icon,
              color: this.computedColors[type.slug].color,
              type_id: type.id,
              vuetify_color: this.computedColors[type.slug].vuetify_color,
            });
          });
        }
      });

      return result;
    },
    handleOnEventUpdate(data) {
      const index = this.liveEvents.findIndex((x) => x.id === data.id);

      const unmodifiedEvents = this.transformData(
        JSON.parse(JSON.stringify(this.liveEventsUnmodified))
      );

      const unmodifiedEvent = unmodifiedEvents.find((x) => x.id === data.id);

      if (!unmodifiedEvent) {
        console.error("No original event found!");
        return;
      }

      let isModified = false;
      if (unmodifiedEvent.start_date !== data.start_date) isModified = true;
      if (unmodifiedEvent.end_date !== data.end_date) isModified = true;

      this.$set(this.liveEvents[index], "start_date", data.start_date);
      this.$set(this.liveEvents[index], "end_date", data.end_date);
      this.$set(this.liveEvents[index], "updated", isModified);

      this.$toast.success(`Calendar event successfully updated.`, {
        timeout: 5000,
        position: "bottom-center",
        icon: false,
      });
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    returnMonth(dateString) {
      const date = new Date(dateString);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Get the month index from the date object (0-based index)
      const monthIndex = date.getMonth();

      // Return the month name corresponding to the month index
      return `${monthNames[monthIndex]} - ${date.getFullYear()}`;
    },
    isEventDeleted(event) {
      const events = this.liveEvents.filter((x) => x.delete);
      for (let i = 0; i < events.length; i++) {
        const deletedEvent = events[i];
        if (deletedEvent.id === event.id) return true;
        if (
          deletedEvent.event_id === event.event_id &&
          deletedEvent.deleteWithRecurrings
        )
          return true;
      }
      return false;
    },

    handleOnCalendarEvent({ event }) {
      this.showUpdateDialogData = JSON.parse(
        JSON.stringify({ ...event, end_date: this.plusDate(event.end_date) })
      );

      this.showUpdateDialogPreviewData = this.liveEventsUnmodified.find(
        (x) => x.id === event.event_id
      );

      console.log(this.showUpdateDialogPreviewData);

      this.showUpdateDialog = true;
    },

    handleOnClickDay(day) {
      if (this.readOnly) {
        this.$toast.error("You can not update calendar on Prod environment.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      }
      this.showInsertUpdateDialogData = day;
      this.showInsertUpdateDialogMode = "insert";
      this.showInsertUpdateDialog = true;
    },
    handleOnChangeCalendar(event) {
      this.currentDate = event;
    },
    handleShowEventClosedDialog() {
      this.showEventInformationDialog = false;
    },
  },
  components: {
    EventUpdateDialog,
    EventPublishDialog,
  },
  computed: {
    ...mapGetters(["getCopiedEventData"]),
    computedColors() {
      return LIVE_OPS_TYPE_COLORS;
    },
    computedDraftColors() {
      return LIVE_OPS_DRAFT_TYPE_COLORS;
    },
    isPasteBtnDisabled() {
      if (!this.getCopiedEventData) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
