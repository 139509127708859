var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      [
        'Area1OreSpawnData[]',
        'SurfaceResourceData[]',
        'OrderData[]',
      ].includes(_vm.variableType)
    )?_c('SurfaceResourceEditor',{attrs:{"show":_vm.showDataEditor,"valueStructures":_vm.valueStructures,"variableValue":_vm.value,"defaultValue":_vm.defaultValue},on:{"closed":_vm.handleEditorClose}}):_vm._e(),_c('div',{staticClass:"mt-4"},[(
        [
          'Area1OreSpawnData[]',
          'SurfaceResourceData[]',
          'OrderData[]',
        ].includes(_vm.variableType)
      )?_c('div',[_c('div',{staticClass:"h-[40px] border-gray-400 border rounded w-full flex items-center justify-center text-[12px] cursor-pointer hover:text-sky-600 hover:border-sky-600",on:{"click":function($event){_vm.showDataEditor = true}}},[_vm._v(" Edit Data ")])]):(_vm.variableType === 'undergroundDesignCombinationEnum')?_c('div',[_c('v-file-input',{staticClass:"text-[13px]",attrs:{"multiple":"","chips":"","hide-details":"","small-chips":"","truncate-length":"32","outlined":"","dense":"","label":"Level Data (Json)","accept":"application/json","disabled":_vm.disabled},model:{value:(_vm.levelData),callback:function ($$v) {_vm.levelData=$$v},expression:"levelData"}})],1):(
        _vm.variableType !== 'int[]' &&
        _vm.variableType !== 'string[]' &&
        _vm.variableType !== 'int' &&
        _vm.variableType !== 'string' &&
        _vm.variableType !== 'Color[]' &&
        _vm.variableType !== 'float[]' &&
        _vm.variableType !== 'float' &&
        _vm.variableType.toLowerCase() !== 'color'
      )?_c('div',[_c('v-select',{staticClass:"text-[13px]",attrs:{"hide-details":"","label":"Choose Possibilities","outlined":"","multiple":"","small-chips":"","deletable-chips":"","items":_vm.choosableValues,"disabled":_vm.disabled},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_c('div',[_c('v-combobox',{staticClass:"text-[13px]",attrs:{"multiple":"","outlined":"","small-chips":"","dense":"","hide-details":"","deletable-chips":"","label":"Values","disabled":_vm.disabled},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),(['Color[]', 'Color', 'color'].includes(_vm.variableType))?_c('div',{staticClass:"mt-2 flex items-center"},_vm._l(([..._vm.value]),function(item,index){return _c('div',{key:index},[(_vm.variableType === 'Color[]' && _vm.isColorValid(item))?_c('div',{staticClass:"colors",style:({
            background: `linear-gradient(${_vm.firstColor(item)}, ${_vm.secondColor(
              item
            )})`,
          })}):_vm._e(),(_vm.variableType.toLowerCase() === 'color')?_c('div',{staticClass:"colors",style:({
            background: `#${item}`,
          })}):_vm._e()])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }