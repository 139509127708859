<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Settings </v-card-title>
        <v-card-text v-if="data">
          <div class="">
            <v-expansion-panels v-model="selectedPanels" multiple flat>
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><span class="font-semibold text-gray-800 text-[11px]"
                    >Campaign Settings</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div>
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign ID
                    </div>
                    <div class="mt-2">
                      <v-text-field
                        label="Campaign ID"
                        outlined
                        disabled
                        v-model="campaignSettingsData.id"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign Title
                    </div>
                    <div class="mt-2">
                      <v-text-field
                        label="Title"
                        outlined
                        v-model="campaignSettingsData.name"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign Status
                    </div>
                    <div>
                      <v-switch
                        v-model="campaignSettingsData.status"
                        :label="`${campaignSettingsData.status.toUpperCase()}`"
                        class="text-[13px]"
                        hide-details
                        true-value="Active"
                        false-value="Paused"
                        style="margin-top: 4px"
                      ></v-switch>
                    </div>
                  </div>

                  <div class="flex items-center justify-end w-full mt-3">
                    <v-btn
                      color="primary"
                      text
                      @click="updateCampaignInformation"
                      :loading="loadingCampaignData"
                    >
                      Save
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  ><span class="font-semibold text-gray-800 text-[11px]"
                    >Budget & Bidding</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div class="mb-1.5">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign Budget
                    </div>
                    <div class="mt-2">
                      <v-text-field
                        label="Budget"
                        outlined
                        v-model="campaignSettingsData.budget"
                        dense
                        type="number"
                        hide-details
                        class="text-[13px]"
                        suffix="$"
                        :disabled="!campaignSettingsData.budget_type"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="mb-1.5">
                    <v-text-field
                      placeholder="Search country"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      v-model="searchKeyword"
                      class="text-[12px]"
                      hide-details
                    ></v-text-field>
                  </div>
                  <div class="mb-1.5 flex justify-end">
                    <v-btn color="primary" text @click="insertNewRow">
                      New
                    </v-btn>
                  </div>
                  <div class="border rounded-[3px]">
                    <v-data-table
                      :headers="headers"
                      :items="bids"
                      :items-per-page="10"
                    >
                      <template v-slot:[`item.name`]="{ item }">
                        <span class="font-semibold" v-if="!item.editable">
                          {{
                            computedCountries.find((x) => x.code === item.code)
                              .name
                          }}
                        </span>
                        <div v-else>
                          <v-autocomplete
                            hide-details
                            class="text-[13px] w-[250px]"
                            item-text="name"
                            item-value="code"
                            outlined
                            clearable
                            dense
                            :items="computedCountries"
                            v-model="item.code"
                            hide-no-data
                          >
                          </v-autocomplete>
                        </div>
                      </template>

                      <template v-slot:[`item.bid`]="{ item }">
                        <div
                          v-if="!item.editable"
                          class="font-semibold p-[8px] w-[200px] rounded-[3px]"
                        >
                          {{ item.bid ? item.bid : "-" }}
                        </div>

                        <v-text-field
                          v-else
                          outlined
                          dense
                          v-model="item.bid"
                          class="text-[12px] w-[200px]"
                          hide-details
                          type="number"
                        ></v-text-field>
                      </template>

                      <template v-slot:[`item.budget`]="{ item }">
                        <div v-if="!campaignSettingsData.budget_type">
                          <div
                            v-if="!item.editable"
                            class="font-semibold p-[8px] w-[200px] rounded-[3px]"
                          >
                            {{ item.budget ? `${item.budget} $` : "-" }}
                          </div>

                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="item.budget"
                            class="text-[12px] w-[200px]"
                            hide-details
                            type="number"
                          ></v-text-field>
                        </div>

                        <div v-else>
                          <div
                            class="font-semibold p-[8px] w-[200px] rounded-[3px]"
                          >
                            {{ item.budget ? `${item.budget} $` : "-" }}
                          </div>
                        </div>
                      </template>

                      <template v-slot:[`item.status`]="{ item }">
                        <div class="inline-block">
                          <v-switch
                            :ripple="false"
                            v-model="item.status"
                            @change="changeStatus($event, item)"
                            :label="`${item.status ? 'On' : 'Off'}`"
                            class="text-[13px]"
                            hide-details
                            :style="{ marginTop: 0, paddingTop: 0 }"
                          ></v-switch>
                        </div>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <div class="flex items-center">
                          <span
                            v-if="!item.editable"
                            class="font-semibold text-[10px] text-gray-400 hover:underline cursor-pointer hover:text-gray-600"
                            @click="item.editable = true"
                            >Edit</span
                          >
                          <span
                            v-if="item.editable"
                            class="font-semibold text-[10px] text-lime-600 hover:underline cursor-pointer hover:text-lime-700"
                            @click="saveRow(item)"
                            >Save</span
                          >
                          <span
                            class="font-semibold text-[10px] text-rose-400 hover:underline cursor-pointer hover:text-rose-600 ml-1.5"
                            @click="deleteRow(item)"
                            >Delete</span
                          >
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                  <div class="flex items-center justify-end w-full mt-3">
                    <v-btn
                      color="primary"
                      text
                      @click="updateCampaignBudget"
                      :loading="loadingBudgetUpdateBtn"
                      :disabled="
                        bids.filter((x) => x.editable).length ? true : false
                      "
                    >
                      Save
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { COUNTRIES } from "@/helper/utils";
import _ from "lodash";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    game_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },

    data: {
      handler(newValue) {
        if (newValue) {
          this.campaignSettingsData.id = newValue.campaign_id;
          this.campaignSettingsData.name = newValue.campaign_name;
          this.campaignSettingsData.status = newValue.status;
          this.campaignSettingsData.budget = null;
          this.campaignSettingsData.budget_type = null;
          this.bids = [];

          if (newValue.target) {
            const defaultIndex = newValue.target.countries.findIndex(
              (x) => x["DEFAULT"]
            );

            if (
              defaultIndex > -1 &&
              newValue.target.countries[defaultIndex]["DEFAULT"].budget
            ) {
              this.campaignSettingsData.budget =
                newValue.target.countries[defaultIndex]["DEFAULT"].budget;

              this.campaignSettingsData.budget_type = "default_budget";
            }

            this.bids = JSON.parse(
              JSON.stringify(
                newValue.target.countries
                  .filter((x) => !x["DEFAULT"])
                  .map((item, index) => {
                    const [key, value] = Object.entries(item)[0];
                    const country = this.computedCountries.find(
                      (x) => x.code === key.toUpperCase()
                    );
                    return {
                      code: key.toUpperCase(),
                      name: country ? country.name : "-",
                      ...value,
                      status: item.disabled ? false : true,
                      editable: false,
                      mode: null,
                      original: true,
                      index,
                    };
                  })
              )
            );

            this.tempBids = JSON.parse(JSON.stringify(this.bids));
          }
        }
      },
      deep: true,
    },

    searchKeyword: _.debounce(function (newValue) {
      this.bids = JSON.parse(JSON.stringify(this.tempBids));

      if (newValue) {
        const regex = new RegExp(newValue, "gmi");
        this.bids = this.bids.filter((x) => regex.test(x.name));
      }
    }, 250),
  },
  data() {
    return {
      headers: [
        { text: "Country", value: "name" },
        { text: "Bid", value: "bid" },
        { text: "Budget", value: "budget" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      selectedPanels: [],
      visible: false,
      campaignSettings: null,
      loadingCampaignData: false,
      loadingBudgetUpdateBtn: false,
      campaignSettingsData: {
        id: null,
        name: null,
        budget: null,
        start_date: null,
        end_date: null,
        status: null,
        target_roas: null,
        target_cpa: null,
        budget_resource_name: null,
      },
      bids: [],
      tempBids: [],
      searchKeyword: "",
    };
  },
  methods: {
    async updateCampaignInformation() {
      const requestData = {
        campaign_id: this.campaignSettingsData.id,
        status: this.campaignSettingsData.status === "Active" ? true : false,
        name: this.campaignSettingsData.name,
      };

      if (!requestData.name) {
        this.$toast.error(`Kampanya ismi zorunludur.`, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      }

      try {
        this.loadingCampaignData = true;
        const request = await this.$api.post(
          `campaign-manager/applovin/information`,
          requestData
        );
        if (request.status === 200) {
          this.$toast.success(`Kampanya başarıyla düzenlendi.`, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingCampaignData = false;
      }
    },
    async updateCampaignBudget() {
      const requestData = {
        bid_type: this.data.target.bid_type,
        device_types:
          this.data.target && this.data.target.device_types
            ? this.data.target.device_types
            : null,
        os_major_version_min:
          this.data.target && this.data.target.os_major_version_min
            ? this.data.target.os_major_version_min
            : null,
        countries: [],
        campaign_id: this.campaignSettingsData.id,
      };

      let defaultData = this.data.target.countries.find((x) => x["DEFAULT"]);
      let defaultBudgetData = {};

      if (defaultData) {
        defaultBudgetData.click_url = defaultData["DEFAULT"].click_url;
        defaultBudgetData.impression_url =
          defaultData["DEFAULT"].impression_url;
      }

      if (this.campaignSettingsData.budget) {
        defaultBudgetData.budget = parseFloat(this.campaignSettingsData.budget);
      }

      requestData.countries.push({
        DEFAULT: defaultBudgetData,
      });

      if (this.campaignSettingsData.budget_type) {
        if (!this.campaignSettingsData.budget) {
          this.$toast.error(`Kampanyaya bütçe girmelisin.`, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          return;
        }

        if (parseFloat(this.campaignSettingsData.budget) < 500) {
          this.$toast.error(`Kampanya bütçesi en az 500 $ olmalıdır.`, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          return;
        }
      }

      for (let i = 0; i < this.tempBids.length; i++) {
        const bid = this.tempBids[i];
        let defaultData = this.data.target.countries.find(
          (x) => x[bid.code.toLowerCase()]
        );

        let countryBudgetData = {};
        if (defaultData) {
          if (defaultData[bid.code.toLowerCase()].click_url) {
            countryBudgetData.click_url =
              defaultData[bid.code.toLowerCase()].click_url;
          }

          if (defaultData[bid.code.toLowerCase()].impression_url) {
            countryBudgetData.impression_url =
              defaultData[bid.code.toLowerCase()].impression_url;
          }
        }

        if (bid.budget) {
          countryBudgetData.budget = parseFloat(bid.budget);
        }

        if (bid.bid) {
          countryBudgetData.bid = parseFloat(bid.bid);
        }

        countryBudgetData.disabled = !bid.status;

        if (
          !this.campaignSettingsData.budget_type &&
          !countryBudgetData.budget
        ) {
          this.$toast.error(`${bid.name} için bütçe girmelisin.`, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          return;
        }

        let x = {};
        x[bid.code.toLowerCase()] = countryBudgetData;
        requestData.countries.push(x);
      }

      requestData.countries = JSON.stringify(requestData.countries);
      try {
        this.loadingBudgetUpdateBtn = true;
        const request = await this.$api.post(
          `campaign-manager/applovin/budget-update`,
          requestData
        );
        if (request.status === 200) {
          this.$toast.success(`Bütçe başarıyla düzenlendi.`, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingBudgetUpdateBtn = false;
      }
    },
    changeStatus(value, item) {
      const index = this.tempBids.findIndex((x) => x.index === item.index);
      if (index > -1) {
        this.tempBids[index].status = value;
      }
    },
    saveRow(item) {
      const hasCountry = this.bids.find(
        (x) => x.editable === false && x.code === item.code
      );
      if (hasCountry) {
        this.$toast.error(
          `Eklemek istenilen ülke zaten mevcut. Tablodan mevcut ülkeyi düzenleyebilirsin.`,
          {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          }
        );
        return;
      }

      const country = this.computedCountries.find(
        (x) => x.code === item.code.toUpperCase()
      );

      const index = this.tempBids.findIndex((x) => x.index === item.index);
      if (index > -1) {
        this.bids[index].editable = false;
        this.bids[index].name = country.name;
        this.bids[index].bid = item.bid;
        this.bids[index].budget = item.budget;

        this.tempBids[index].editable = false;
        this.tempBids[index].code = item.code;
        this.tempBids[index].bid = item.bid;
        this.tempBids[index].budget = item.budget;
        this.tempBids[index].name = country.name;
      }
    },
    deleteRow(item) {
      const index = this.tempBids.findIndex((x) => x.index === item.index);
      if (index > -1) {
        this.bids.splice(index, 1);
        this.tempBids.splice(index, 1);
      }
    },
    insertNewRow() {
      const row = {
        code: null,
        name: null,
        bid: 0,
        disabled: false,
        status: true,
        editable: true,
        mode: null,
        original: false,
        index: this.tempBids.length + 1,
      };

      this.bids.splice(0, 0, row);
      this.tempBids.splice(0, 0, row);
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  computed: {
    computedCountries() {
      return COUNTRIES.map((x) => ({ ...x, code: x.code.toUpperCase() }));
    },

    isSaveBtnDisabled() {
      return true;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 11px !important;
  font-weight: 600;
}

::v-deep .v-expansion-panel {
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  border-radius: 3px;
  margin-top: 4px;
}
</style>
