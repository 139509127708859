<template>
  <div>
    <v-dialog v-model="visible" width="700">
      <v-card>
        <v-card-title> Edit Bundle Data </v-card-title>

        <v-card-text>
          <div>
            <div
              class="d-inline-block px-3 py-2 cursor-pointer hover:bg-blue-50 rounded hover:text-blue-700 text-[11px] mr-2 font-semibold mb-1"
              :class="
                getSelectedItem === index ? 'bg-blue-50 text-blue-700' : ''
              "
              v-for="(item, index) in surfaceValue.length"
              :key="index"
              @click="selectIndex(index)"
            >
              <div class="flex items-center">
                <span> Options {{ item }} </span>
                <span
                  class="ml-1 hover:text-red-700"
                  v-if="getSelectedItem === index"
                  @click="deleteItem(index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-trash"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    stroke-width="1.25"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 7l16 0"></path>
                    <path d="M10 11l0 6"></path>
                    <path d="M14 11l0 6"></path>
                    <path
                      d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                    ></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>

          <div class="flex justify-end items-center my-2">
            <v-btn
              color="primary"
              :ripple="false"
              depressed
              text
              @click="addNewSurface"
              :loading="addBtnLoading"
            >
              Add New
            </v-btn>
          </div>

          <div
            v-if="
              surfaceValue &&
              surfaceValue.length &&
              getSelectedItem !== null &&
              surfaceValue[getSelectedItem]
            "
          >
            <div
              v-for="key in Object.keys(surfaceValue[getSelectedItem])"
              :key="key"
              class="mt-3"
            >
              <h1 class="font-semibold text-[12px] text-sky-800 mb-3">
                {{ key }}
              </h1>
              <div>
                <div
                  v-if="
                    $isObj(surfaceValue[getSelectedItem][key]) &&
                    Object.keys(surfaceValue[getSelectedItem][key]).length
                  "
                >
                  <div
                    v-for="(subKey, subIndex) in Object.keys(
                      surfaceValue[getSelectedItem][key]
                    )"
                    :key="subIndex"
                    class="mb-2 w-full"
                  >
                    <div class="w-full">
                      <v-text-field
                        v-if="['botClothingChoiceIndex'].includes(key)"
                        class="text-[12px] w-full"
                        dense
                        :label="subKey"
                        outlined
                        hide-details
                        v-model="surfaceValue[getSelectedItem][key][subKey]"
                      />
                      <v-autocomplete
                        v-else-if="['selectionPositionTypes'].includes(key)"
                        class="text-[12px] w-full"
                        dense
                        :label="subKey"
                        outlined
                        :items="['ABC', 'ACB', 'BAC', 'BCA', 'CAB', 'CBA']"
                        hide-details
                        v-model="surfaceValue[getSelectedItem][key][subKey]"
                      />

                      <!-- Feedback order special... -->
                      <v-autocomplete
                        v-else-if="['customFeedbackOrder'].includes(key)"
                        class="text-[12px] w-full"
                        dense
                        :label="subKey"
                        outlined
                        :items="computedFeedbackValues"
                        hide-details
                        :disabled="
                          !surfaceValue[getSelectedItem]['showFeedback']
                        "
                        v-model="surfaceValue[getSelectedItem][key][subKey]"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="!$isObj(surfaceValue[getSelectedItem][key])">
                  <div class="w-full">
                    <v-text-field
                      v-if="['level'].includes(key)"
                      class="text-[12px] w-full"
                      dense
                      :label="key"
                      outlined
                      :type="['level'].includes(key) ? 'number' : 'text'"
                      hide-details
                      v-model="surfaceValue[getSelectedItem][key]"
                    />

                    <!-- camera stuff special... -->
                    <v-text-field
                      v-else-if="
                        [
                          'cameraPositionX',
                          'cameraPositionY',
                          'cameraPositionZ',
                          'cameraRotationX',
                          'cameraRotationY',
                          'cameraRotationZ',
                        ].includes(key)
                      "
                      class="text-[12px] w-full"
                      dense
                      :label="key"
                      outlined
                      :type="
                        [
                          'cameraPositionX',
                          'cameraPositionY',
                          'cameraPositionZ',
                          'cameraRotationX',
                          'cameraRotationY',
                          'cameraRotationZ',
                        ].includes(key)
                          ? 'number'
                          : 'text'
                      "
                      hide-details
                      v-model="surfaceValue[getSelectedItem][key]"
                      :disabled="
                        surfaceValue[getSelectedItem]['cameraAngleType'] !==
                        'Custom'
                      "
                    />

                    <v-checkbox
                      v-else-if="
                        ['showFeedback', 'playFeedbackSound'].includes(key)
                      "
                      dense
                      :label="key"
                      hide-details
                      :disabled="key === 'playFeedbackSound'"
                      v-model="surfaceValue[getSelectedItem][key]"
                    >
                    </v-checkbox>
                  </div>
                  <div class="w-full">
                    <v-autocomplete
                      v-if="['customConceptType'].includes(key)"
                      class="text-[12px] w-full"
                      dense
                      :label="key"
                      outlined
                      :items="computedOutfitData"
                      hide-details
                      v-model="surfaceValue[getSelectedItem][key]"
                    />
                    <v-select
                      v-else-if="['cameraAngleType'].includes(key)"
                      class="text-[12px] w-full"
                      dense
                      :label="key"
                      outlined
                      :items="['Front', 'Default', 'Custom']"
                      hide-details
                      v-model="surfaceValue[getSelectedItem][key]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="visible = false"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ACT_OUTFIT_DATA, ACT_FEEDBACK_ORDER_VALUES } from "@/helper/utils";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    variableValue: {
      type: Array,
      default: () => [],
    },
    valueStructures: {
      default: null,
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    variableValue: {
      handler(newValue) {
        this.surfaceValue = newValue;
      },
      deep: true,
      immediate: true,
    },
    surfaceValue: {
      handler(newValue) {
        this.$emit("surfaceValueChanged", newValue);
      },
      deep: true,
    },
  },
  data() {
    return {
      visible: false,
      surfaceValue: null,
      selectedItem: 0,
      addBtnLoading: false,
    };
  },
  methods: {
    selectIndex(idx) {
      this.selectedItem = idx;
    },
    addNewSurface() {
      this.addBtnLoading = true;
      if (this.defaultValue && this.defaultValue.length) {
        setTimeout(() => {
          const newVal = JSON.parse(JSON.stringify(this.defaultValue[0]));
          this.$set(
            this.surfaceValue,
            this.surfaceValue.length,
            Object.assign({}, newVal)
          );
          this.addBtnLoading = false;
          this.selectedItem = this.surfaceValue.length - 1;
        }, 1500);
      }
    },
    deleteItem(index) {
      this.$delete(this.surfaceValue, index);
      setTimeout(() => {
        this.selectedItem = this.surfaceValue.length - 1;
      });
    },
  },
  computed: {
    getSelectedItem() {
      return this.selectedItem;
    },
    computedOutfitData() {
      return ACT_OUTFIT_DATA;
    },
    computedFeedbackValues() {
      return ACT_FEEDBACK_ORDER_VALUES;
    },
  },
};
</script>

<style lang="scss" scoped></style>
