<template>
  <div>
    <div class="mt-4">
      <v-file-input
        show-size
        accept="application/zip"
        label="Aep Project (Zip)"
        class="text-[13px]"
        outlined
        dense
        hide-details
        v-model="data.asset_file"
      >
      </v-file-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        asset_file: null,
        type: "after-effects",
      },
    };
  },
  watch: {
    data: {
      handler(newValue) {
        this.$emit("changed", newValue);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
