<template>
  <div>
    <v-dialog v-model="visible" width="572">
      <v-card>
        <v-card-title class="text-h5">
          {{
            mode === "update" ? "Update Live Event" : "Create New Live Event"
          }}
        </v-card-title>

        <v-card-text>
          <div v-if="eventData" class="mt-5">
            <div>
              <v-autocomplete
                clearable
                flat
                label="Game"
                v-model="eventData.package_name"
                dense
                outlined
                class="text-[12px]"
                hide-details
                :items="games"
                item-text="title"
                item-value="applovin_android_package_name"
              >
              </v-autocomplete>
            </div>

            <div class="mt-2.5">
              <v-text-field
                label="Event Name"
                outlined
                v-model="eventData.name"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-2.5">
              <v-text-field
                label="Alias"
                outlined
                v-model="eventData.alias"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>

            <div class="mt-2.5">
              <v-textarea
                label="Description"
                v-model="eventData.description"
                dense
                outlined
                auto-grow
                :rows="3"
                hide-details
                class="text-[13px]"
              ></v-textarea>
            </div>
            <div class="mt-2.5">
              <v-select
                label="Type"
                outlined
                v-model="eventData.type.id"
                :items="eventTypes"
                item-text="name"
                item-value="id"
                dense
                hide-details
                class="text-[13px]"
                @change="handleOnTypeChange"
              ></v-select>
            </div>
            <div class="mt-2.5">
              <v-select
                label="Theme"
                outlined
                v-model="eventData.theme.id"
                :items="computedEventThemes"
                item-text="title"
                item-value="id"
                dense
                hide-details
                class="text-[13px]"
              ></v-select>
            </div>
            <div class="flex items-center gap-2 mt-2.5">
              <div class="w-full" v-if="mode === 'update'">
                <v-text-field
                  label="ID"
                  outlined
                  disabled
                  v-model="eventData.id"
                  dense
                  hide-details
                  class="text-[13px]"
                ></v-text-field>
              </div>
            </div>

            <div class="flex items-center gap-2 mt-2.5">
              <div class="w-full">
                <v-text-field
                  label="Min. Version"
                  outlined
                  v-model="eventData.min_version"
                  dense
                  hide-details
                  class="text-[13px]"
                  type="number"
                  :min="0"
                  @change="handleOnMinVersionChange"
                ></v-text-field>
              </div>
            </div>

            <div class="mt-2.5">
              <h1 class="font-semibold text-[13px] mb-1.5">Payload:</h1>
              <JsonEditorVue
                :mainMenuBar="false"
                :navigationBar="false"
                :mode="'text'"
                v-model="eventValue"
              />
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="error" text @click="visible = false"> Cancel </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="isBtnDisabled || !jsonToString"
              @click="submitChanges"
              :loading="loadingBtn"
            >
              {{ mode === "update" ? "Update" : "Submit" }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import JsonEditorVue from "json-editor-vue";

export default {
  data() {
    return {
      eventValue: null,
      visible: false,
      eventData: null,
      loadingBtn: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "insert",
    },
    games: {
      type: Array,
      default: () => [],
    },
    eventTypes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.eventData = null;
        this.eventValue = {};
      }
    },
    data: {
      handler(newValue) {
        if (newValue) {
          this.eventData = JSON.parse(JSON.stringify(newValue));

          if (newValue.payload) {
            console.log(newValue.payload);
            this.eventValue = newValue.payload;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    computedEventThemes() {
      if (this.eventData.type.id) {
        const eventType = this.eventTypes.find(
          (x) => x.id === this.eventData.type.id
        );
        if (!eventType) {
          return [];
        } else {
          return eventType.themes;
        }
      }

      return [];
    },
    isBtnDisabled() {
      if (!this.data) return true;
      if (!Object.keys(this.data).length) return true;
      if (!this.eventData) return true;
      if (this.eventData) {
        if (!this.eventData.name) return true;
        // if (!this.eventData.description) return true;
        if (!this.eventData.type.id) return true;
        if (!this.eventData.theme.id) return true;
        if (!this.eventData.package_name) return true;
      }

      return false;
    },
    jsonToString() {
      try {
        if (this.eventValue) {
          if (typeof this.eventValue === "string") {
            return JSON.stringify(JSON.parse(this.eventValue));
          } else {
            return JSON.stringify(this.eventValue);
          }
        } else {
          return false;
        }
      } catch {
        return false;
      }
    },
  },
  components: {
    JsonEditorVue,
  },
  methods: {
    handleOnMinVersionChange(value) {
      if (value < 0 || value === "" || !value) {
        this.eventData.min_version = 0;
      }
    },
    handleOnTypeChange() {
      this.eventData.theme.id = null;
    },
    deleteLiveEvent() {
      this.$emit("delete", this.eventData.id);
      this.visible = false;
    },
    submitChanges() {
      const requestData = {
        name: this.eventData.name,
        alias: this.eventData.alias,
        package_name: this.eventData.package_name,
        description: this.eventData.description,
        payload:
          Object.keys(JSON.parse(this.jsonToString ? this.jsonToString : {}))
            .length === 0
            ? null
            : this.jsonToString,
        type_id: this.eventData.type.id,
        theme_id: this.eventData.theme.id,
        min_version: this.eventData.min_version,
      };

      if (this.mode === "update")
        this.$emit("update", { id: this.eventData.id, ...requestData });
      if (this.mode === "insert") {
        this.visible = false;
        this.$emit("insert", requestData);
      }
    },
  },
};
</script>

<style lang="scss"></style>
