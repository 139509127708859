<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">
        Icon & Screenshoot Tester - Configuration
      </h1>

      <div>
        <v-autocomplete
          clearable
          dense
          outlined
          hide-details
          hide-selected
          auto-select-first
          item-value="id"
          item-text="title"
          v-model="selected_game"
          :items="games"
          label="Choose a game"
          class="text-[13px]"
        >
        </v-autocomplete>
      </div>

      <div class="mt-4">
        <v-autocomplete
          clearable
          dense
          outlined
          hide-details
          hide-selected
          auto-select-first
          item-value="id"
          item-text="title"
          :disabled="creatives_loading"
          :loading="creatives_loading"
          v-model="selected_general_creative"
          :items="general_creatives"
          label="Creative"
          class="text-[13px]"
        >
        </v-autocomplete>
      </div>

      <div v-if="selected_game">
        <div class="mt-4">
          <v-text-field
            label="Test Campaign ID"
            outlined
            v-model="test_campaign_id"
            dense
            hide-details
            :loading="input_loading"
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-4">
          <v-text-field
            label="Creative Text"
            outlined
            v-model="creative_text"
            dense
            hide-details
            :loading="input_loading"
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-4">
          <v-text-field
            label="Tiktok ACT ID"
            outlined
            v-model="tiktok_act_id"
            dense
            hide-details
            :loading="input_loading"
            class="text-[13px]"
          ></v-text-field>
        </div>
      </div>

      <div class="flex items-center justify-end mt-5">
        <v-btn
          color="primary"
          :disabled="isBtnDisabled"
          :ripple="false"
          depressed
          :loading="loading_btn"
          @click="saveConfiguration"
        >
          Save Configuration
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfigurationView",
  data() {
    return {
      selected_game: null,
      games: [],
      test_campaign_id: "",
      tiktok_act_id: "",
      creative_text: "",
      videos: [],
      loading_btn: false,
      config_data: null,
      input_loading: false,
      selected_general_creative: null,
      general_creatives: [],
      creatives_loading: false,
    };
  },
  methods: {
    fetchCreatives() {
      if (this.selected_game) {
        this.creatives_loading = true;
        this.$api
          .get(
            `act/general-creatives/all/for-gameplay?selected_game_id=${this.selected_game}&include_days=False`
          )
          .then((response) => {
            this.general_creatives = response.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.creatives_loading = false;
          });
      }
    },
    saveConfiguration() {
      this.loading_btn = true;

      const requestData = {
        game_id: this.selected_game,
        test_campaign_id: this.test_campaign_id,
        creative_text: this.creative_text,
        general_creative_id: this.selected_general_creative,
        tiktok_act_id: this.tiktok_act_id,
      };

      this.$api
        .post(`icon-tester/configuration/create`, requestData)
        .then((response) => {
          if (response.status === 201) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Configuration successfully created.",
            });
            this.resetForm();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_btn = false;
        });
    },
    fetchGames() {
      this.$api
        .get("games/all?platform=ios")
        .then((response) => {
          this.games = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleOnDeleteVideo(name) {
      this.videos = this.videos.filter((x) => x.name !== name);
    },
    fetchRelatedConfiguration() {
      if (this.selected_game) {
        this.input_loading = true;
        this.$api
          .get(`icon-tester/configuration/get?game_id=${this.selected_game}`)
          .then((response) => {
            if ("detail" in response.data) {
              this.config_data = null;
            } else {
              this.config_data = response.data;
              this.selected_general_creative =
                response.data.general_creative_id;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.input_loading = false;
          });
      }
    },
    resetForm() {
      this.selected_game = null;
      this.test_campaign_id = null;
      this.creative_text = null;
      this.selected_general_creative = null;
    },
  },
  computed: {
    isBtnDisabled() {
      if (!this.selected_game) {
        return true;
      }

      if (!this.selected_general_creative) {
        return true;
      }

      if (!this.test_campaign_id) {
        return true;
      }

      if (!this.creative_text) {
        return true;
      }

      return false;
    },
  },
  watch: {
    selected_game() {
      this.fetchRelatedConfiguration();
      this.fetchCreatives();
    },
    config_data(newValue) {
      if (newValue) {
        this.test_campaign_id = newValue.test_campaign_id;
        this.creative_text = newValue.creative_text;
        this.tiktok_act_id = newValue.tiktok_act_id;
      } else {
        this.test_campaign_id = "";
        this.creative_text = "";
      }
    },
  },
  mounted() {
    this.fetchGames();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
