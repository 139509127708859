<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between">
        <h1 class="font-semibold text-[18px] pb-6">Create & Edit Projects</h1>
        <div>
          <v-btn :ripple="false" color="secondary" depressed
            ><v-icon left dark> mdi-plus </v-icon> Create Project
          </v-btn>
        </div>
      </div>

      <div>
        <div class="max-w-[340px]">
          <v-autocomplete
            hide-details
            class="text-[13px]"
            item-text="title"
            item-value="id"
            label="Choose a project"
            outlined
            clearable
            dense
            :items="projects"
            v-model="selectedProject"
            hide-no-data
          >
          </v-autocomplete>
        </div>

        <div class="pt-5">
          <v-tabs v-model="tab">
            <v-tab>Overview</v-tab>
            <v-tab>Project Settings</v-tab>
          </v-tabs>
        </div>

        <div class="pt-5">
          <v-tabs-items v-model="tab">
            <v-tab-item :transition="false">
              <v-card flat>
                <div class="flex items-center">
                  <h1 class="font-semibold uppercase">Features</h1>
                  <div class="pl-4">
                    <v-btn small :ripple="false" color="primary" depressed text
                      ><v-icon left dark> mdi-plus </v-icon> New Feature
                    </v-btn>
                  </div>
                </div>
                <div class="pt-5">
                  <v-data-table
                    :headers="headers"
                    :items="features"
                    :loading="loading"
                    :items-per-page="10"
                    single-expand
                    :expanded.sync="expanded"
                    item-key="id"
                    show-expand
                  >
                    <template v-slot:[`item.is_development`]="{ item }">
                      <div>
                        <v-switch v-model="item.is_development"></v-switch>
                      </div>
                    </template>
                    <template v-slot:[`item.is_production`]="{ item }">
                      <div>
                        <v-switch v-model="item.is_production"></v-switch>
                      </div>
                    </template>
                    <template v-slot:[`item.enabled`]="{ item }">
                      <div>
                        <v-switch v-model="item.enabled"></v-switch>
                      </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                      <div
                        :class="{
                          'text-gray-400': !item.enabled,
                        }"
                      >
                        <span>{{ item.description }}</span>
                      </div>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                      <div
                        :class="{
                          'text-gray-400': !item.enabled,
                        }"
                      >
                        <span>{{ item.name }}</span>
                      </div>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      <div
                        :class="{
                          'text-gray-400': !item.enabled,
                        }"
                      >
                        <span>{{ $formatUnix(item.created_at) }}</span>
                      </div>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <h1 class="font-semibold">{{ item.name }} - Rules:</h1>
                        <div v-for="(rule, index) in item.rules" :key="index">
                          {{ rule }}
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: [],
      tab: 0,
      projects: [
        { id: 1, title: "Project 1" },
        { id: 2, title: "Project 2" },
        { id: 3, title: "Project 3" },
        { id: 4, title: "Project 4" },
        { id: 5, title: "Project 5" },
      ],
      selectedProject: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Created At", value: "created_at" },
        { text: "Development", value: "is_development" },
        { text: "Production", value: "is_production" },
        { text: "Description", value: "description" },
        { text: "Active", value: "enabled" },
        { text: "", value: "data-table-expand" },
      ],
      features: [
        {
          id: 1,
          name: "Feature 1",
          created_at: 1703746675,
          is_development: true,
          is_production: true,
          description: "Feature for users who passed tutorial in 15 minutes",
          enabled: true,
          rules: [
            {
              type: "model",
              data: {
                model_name: "cash_spent",
                operator: "greater",
                value: 10000,
                target: "user",
                extra: [
                  {
                    type: "last_x_hours",
                    value: 24,
                  },
                ],
              },
            },
            {
              type: "scheduled_start_at",
              value: "2023-01-01T00:00:00.0",
            },
            {
              type: "scheduled_stop_at",
              value: "2023-01-07T23:59:00.0",
            },
          ],
        },
        {
          id: 2,
          name: "Feature 2",
          created_at: 1703746675,
          is_development: true,
          is_production: false,
          description: "Feature for users who reached level 15 in 1 day",
          enabled: true,
        },
        {
          id: 3,
          name: "Feature 3",
          created_at: 1703746675,
          is_development: false,
          is_production: false,
          description:
            "Feature for users who spent at least 15.000 cash last 24 hours",
          enabled: false,
        },
      ],
    };
  },
  watch: {},
  methods: {},
  computed: {},
  components: {},
};
</script>

<style lang="scss" scoped></style>
