import AuditLogsView from "@/views/tools/audit-logs/AuditLogsView.vue";

export const audit_logs_router = [
  {
    path: "/audit-logs",
    name: "audit-logs-view",
    component: AuditLogsView,
    meta: {
      title: "Audit Logs",
    },
  },
];
