<template>
  <div>
    <v-navigation-drawer
      v-model="visible"
      absolute
      bottom
      temporary
      right
      width="660"
    >
      <div class="p-8" v-if="data">
        <div class="flex items-center text-[20px]">
          <v-icon color="black">mdi-pencil</v-icon>
          <h1 class="font-semibold ml-2">Edit - {{ data.title }}</h1>
        </div>
        <v-divider class="mt-5"></v-divider>
        <h1 class="mt-5 font-semibold text-[14px]">1. Facebook Information</h1>
        <div class="mt-5">
          <v-text-field
            label="Android App ID"
            outlined
            disabled
            v-model="gameData.android_app_id"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-3">
          <v-text-field
            label="iOS App ID"
            outlined
            disabled
            v-model="gameData.ios_app_id"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-3">
          <v-text-field
            label="Facebook ID"
            outlined
            disabled
            v-model="gameData.facebook_app_id"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
        <v-divider class="my-5"></v-divider>
        <h1 class="mt-5 font-semibold text-[14px]">2. TikTok Information</h1>
        <div class="mt-5">
          <v-text-field
            label="iOS Pixel ID"
            outlined
            v-model="gameData.ios_pixel_id"
            dense
            disabled
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-3">
          <v-text-field
            label="Android Pixel ID"
            outlined
            v-model="gameData.android_pixel_id"
            dense
            disabled
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-3">
          <v-text-field
            label="iOS Pixel Name"
            outlined
            v-model="gameData.ios_pixel_name"
            dense
            hide-details
            disabled
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-3">
          <v-text-field
            label="iOS Identity ID"
            outlined
            v-model="gameData.ios_identity_id"
            dense
            hide-details
            disabled
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-3">
          <v-text-field
            label="iOS Pixel Code"
            outlined
            v-model="gameData.ios_pixel_code"
            dense
            hide-details
            disabled
            class="text-[13px]"
          ></v-text-field>
        </div>
        <v-divider class="my-5"></v-divider>
        <h1 class="mt-5 font-semibold text-[14px]">3. Storage Information</h1>
        <div class="mt-5">
          <v-text-field
            label="Blob Info"
            outlined
            v-model="gameData.blob_info"
            dense
            disabled
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
        <v-divider class="my-5"></v-divider>
        <h1 class="mt-5 font-semibold text-[14px]">4. General Information</h1>
        <div class="mt-5">
          <v-text-field
            label="BTSB Code"
            outlined
            v-model="gameData.btsb_code"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>
        <div class="mt-5">
          <v-combobox
            dense
            multiple
            outlined
            hide-details
            v-model="gameData.computedGenres"
            :items="gameGenres"
            label="Genre(s)"
            class="text-[13px]"
          ></v-combobox>
        </div>
        <div class="flex items-center justify-end mt-2">
          <v-btn
            color="primary"
            :ripple="false"
            depressed
            @click="updateGeneralInformation"
            :loading="updateGeneralInformationBtnLoading"
          >
            Update General Information
          </v-btn>
        </div>
        <v-divider class="my-5"></v-divider>
        <h1 class="mt-5 font-semibold text-[14px]">5. ACT Tag Editor</h1>
        <div class="flex items-center justify-end mb-3">
          <v-btn
            color="teal darken-3"
            :ripple="false"
            depressed
            text
            @click="insertTag"
          >
            <v-icon left> mdi-plus </v-icon>
            New Tag
          </v-btn>
        </div>
        <div
          v-for="(act_tag, index) in gameData.computedTags"
          :key="index"
          class="mb-3"
        >
          <div class="mb-2 flex items-center justify-between">
            <h1 class="font-semibold text-gray-700 text-[13px]">
              {{ index + 1 }}. Tag
            </h1>
            <span
              class="text-rose-700 hover:text-rose-500 cursor-pointer text-[12px] ml-2"
              @click="deleteTag(index)"
              >Delete</span
            >
          </div>
          <div class="grid gap-2 grid-cols-3">
            <div>
              <v-text-field
                label="Tag Label"
                outlined
                v-model="act_tag.label"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div>
              <v-select
                label="Tag Type"
                outlined
                v-model="act_tag.type"
                :items="tagTypes"
                dense
                hide-details
                class="text-[13px]"
              ></v-select>
            </div>
            <div>
              <v-text-field
                label="Depends On"
                outlined
                v-model="act_tag.depends_on"
                dense
                placeholder="Enter labels with comma..."
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                label="Depends On"
                outlined
                v-model="act_tag.dependent_values"
                dense
                placeholder="Enter dependent values with comma..."
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div v-if="['string[]', 'boolean[]'].includes(act_tag.type)">
              <v-text-field
                label="Available Values"
                outlined
                v-model="act_tag.availableValues"
                dense
                placeholder="Parse values with comma!"
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div>
              <v-combobox
                label="Required"
                outlined
                v-model="act_tag.isRequired"
                :items="[true, false]"
                dense
                hide-details
                class="text-[13px]"
              ></v-combobox>
            </div>
            <div>
              <v-combobox
                label="Multiple"
                outlined
                v-model="act_tag.multipleAllowed"
                :items="[true, false]"
                dense
                hide-details
                class="text-[13px]"
              ></v-combobox>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end">
          <v-btn
            color="primary"
            :ripple="false"
            depressed
            @click="updateTags"
            :disabled="isUpdateBtnDisabled"
            :loading="updateTagBtnLoading"
          >
            Update Tags
          </v-btn>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      gameData: null,
      gameGenres: [
        "Action",
        "Adventure",
        "Arcade",
        "Board",
        "Card",
        "Casino",
        "Dice",
        "Educational",
        "Family",
        "Music",
        "Puzzle",
        "Racing",
        "Simulation",
        "Sports",
        "Strategy",
        "Trivia",
        "Word",
      ],
      tagTypes: ["string", "number", "string[]", "boolean"],
      availableValues: [],
      updateTagBtnLoading: false,
      updateGeneralInformationBtnLoading: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      immediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    data: {
      handler(newValue) {
        this.gameData = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    updateGeneralInformation() {
      this.updateGeneralInformationBtnLoading = true;
      const requestData = {
        id: this.gameData.id,
        btsb_code: this.gameData.btsb_code ? this.gameData.btsb_code : null,
        genres: JSON.stringify(this.gameData.computedGenres),
      };

      this.$api
        .post("games/update/general-information", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Successfully updated.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.updateGeneralInformationBtnLoading = false;
        });
    },
    updateTags() {
      this.updateTagBtnLoading = true;
      const requestData = {
        id: this.gameData.id,
        act_tags: JSON.stringify(this.gameData.computedTags),
      };

      this.$api
        .post("games/update/act-tags", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Successfully updated.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.updateTagBtnLoading = false;
        });
    },
    deleteTag(idx) {
      this.gameData.computedTags.splice(idx, 1);
    },
    insertTag() {
      const tag = {
        availableValues: null,
        type: "string",
        label: null,
        isRequired: false,
        multipleAllowed: false,
        depends_on: null,
        dependent_values: null,
      };

      this.gameData.computedTags.push(tag);
    },
  },
  computed: {
    isUpdateBtnDisabled() {
      const tags = this.gameData.computedTags;

      if (!tags.length) {
        return true;
      }

      for (let i = 0; i < tags.length; i++) {
        const element = tags[i];

        if (!element.label) return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
