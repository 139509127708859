<template>
  <div class="flex items-center gap-1.5">
    <span
      class="hover:text-lime-800 text-lime-600 text-[10px] cursor-pointer hover:underline"
      @click="handleOnDuplicate"
    >
      Duplicate
    </span>
    <span
      class="hover:text-rose-800 text-rose-600 text-[10px] cursor-pointer hover:underline"
      @click="handleOnDelete"
    >
      Delete
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    handleOnDuplicate() {
      this.params.duplicated(this.params.data.uuid);
    },
    handleOnDelete() {
      this.params.deleted(this.params.data.uuid);
    },
  },
};
</script>

<style lang="scss" scoped></style>
