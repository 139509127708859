<template>
  <div>
    <div class="bg-white p-6 w-full">
      <h1 class="font-semibold text-[18px] pb-6">Create Conversion Event</h1>
      <div class="mt-0">
        <v-autocomplete
          clearable
          flat
          label="Choose Game"
          v-model="conversionEvent.selected_game"
          dense
          outlined
          class="text-[12px]"
          hide-details
          :items="getGames"
          item-text="title"
          item-value="id"
        >
        </v-autocomplete>
      </div>
      <div class="mt-3" v-if="conversionEvent.selected_game">
        <div class="mb-3">
          <v-divider></v-divider>
        </div>

        <div class="mt-3">
          <div class="font-semibold text-[11px] mb-2">1. Core Event Name</div>
          <v-text-field
            hide-details
            class="text-[13px]"
            placeholder="Core event name"
            outlined
            clearable
            dense
            v-model="conversionEvent.core_event_name"
            hide-no-data
          >
          </v-text-field>
        </div>

        <div class="mt-3">
          <div class="font-semibold text-[11px] mb-2">2. Core Event Token</div>
          <v-text-field
            hide-details
            class="text-[13px]"
            placeholder="Core event token"
            outlined
            clearable
            dense
            v-model="conversionEvent.core_event_token"
            hide-no-data
          >
          </v-text-field>
        </div>

        <div class="mt-3">
          <div class="font-semibold text-[11px] mb-2">
            3. Core Event Tracked Field
          </div>
          <v-text-field
            hide-details
            class="text-[13px]"
            placeholder="Core event tracked field"
            outlined
            clearable
            dense
            v-model="conversionEvent.core_event_tracked_field"
            hide-no-data
          >
          </v-text-field>
        </div>

        <div class="mt-3">
          <div class="font-semibold text-[11px] mb-2">4. Adjust Event Name</div>
          <v-text-field
            hide-details
            class="text-[13px]"
            placeholder="Adjust event name"
            outlined
            clearable
            dense
            v-model="conversionEvent.adjust_event_name"
            hide-no-data
          >
          </v-text-field>
        </div>

        <div class="mt-3">
          <div class="font-semibold text-[11px] mb-2">
            5. Adjust Event Token
          </div>
          <v-text-field
            hide-details
            class="text-[13px]"
            placeholder="Adjust event name"
            outlined
            clearable
            dense
            v-model="conversionEvent.adjust_event_token"
            hide-no-data
          >
          </v-text-field>
        </div>

        <div class="mt-3">
          <div class="font-semibold text-[11px] mb-2">6. App Token</div>
          <v-text-field
            hide-details
            class="text-[13px]"
            placeholder="App Token"
            outlined
            clearable
            dense
            v-model="conversionEvent.app_token"
            hide-no-data
          >
          </v-text-field>
        </div>

        <div class="mt-3">
          <div class="font-semibold text-[11px] mb-2">7. Current Bid</div>
          <v-text-field
            hide-details
            class="text-[13px]"
            placeholder="Bid"
            outlined
            clearable
            dense
            type="number"
            v-model="conversionEvent.current_bid"
            hide-no-data
          >
          </v-text-field>
        </div>

        <div class="mt-3">
          <div class="font-semibold text-[11px] mb-2">
            8. Conversion Threshold
          </div>
          <v-text-field
            hide-details
            class="text-[13px]"
            placeholder="Conversion threshold"
            outlined
            clearable
            dense
            v-model="conversionEvent.conversion_threshold"
            hide-no-data
          >
          </v-text-field>
        </div>

        <div class="flex items-center justify-end mt-3">
          <v-btn
            color="primary"
            :ripple="false"
            depressed
            @click="createConversion"
            :loading="loading"
            :disabled="isBtnDisabled"
          >
            Create
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      conversionEvent: {
        core_event_name: null,
        core_event_token: null,
        core_event_tracked_field: null,
        adjust_event_name: null,
        adjust_event_token: null,
        conversion_threshold: null,
        selected_game: null,
        app_token: null,
        current_bid: null,
      },
      show_start_date: false,
      show_end_date: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["setNetworks", "setGames"]),
    async createConversion() {
      try {
        this.loading = true;
        const response = await this.$api.post(
          "utils/conversion-events/create",
          this.conversionEvent
        );
        if (response.status === 200) {
          this.$toast.success("Event successfully added to bigquery!", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchNetworks() {
      if (this.getNetworks.length) return;
      try {
        const response = await this.$api.get("cpa-sheet/networks");
        this.setNetworks(response.data);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    fetchGames() {
      if (this.getGames.length) return;
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.setGames(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["getNetworks", "getGames"]),
    isBtnDisabled() {
      const keys = Object.keys(this.conversionEvent);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!this.conversionEvent[key]) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.fetchNetworks();
    this.fetchGames();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
