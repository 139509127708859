<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card>
        <v-card-title> Update Test Detail </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <v-text-field
              label="Title"
              outlined
              dense
              hide-details
              v-model="testDetailData.title"
              class="text-[13px]"
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-textarea
              label="Description"
              v-model="testDetailData.description"
              dense
              outlined
              auto-grow
              :rows="3"
              hide-details
              class="text-[13px]"
            ></v-textarea>
          </div>
          <div class="mt-3">
            <v-text-field
              label="Experiment ID"
              outlined
              dense
              hide-details
              v-model="testDetailData.experiment_id"
              class="text-[13px]"
            ></v-text-field>
          </div>
          <div class="mt-3">
            <v-combobox
              clearable
              dense
              multiple
              outlined
              hide-details
              small-chips
              hide-selected
              v-model="testDetailData.variants"
              label="Variants"
              class="text-[13px]"
              deletable-chips
            ></v-combobox>
          </div>

          <div class="mt-3">
            <v-menu
              v-model="start_date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="text-[13px]"
                  v-model="testDetailData.start_at"
                  label="Start At"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="testDetailData.start_at"
                @input="start_date_menu = false"
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="mt-3">
            <v-menu
              v-model="end_date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="text-[13px]"
                  v-model="testDetailData.finish_at"
                  label="Finish At"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="testDetailData.finish_at"
                @input="end_date_menu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            depressed
            :ripple="false"
            :loading="deleteLoading"
            :disabled="isDeleteBtnDisabled"
            @click="deleteTestDetail"
          >
            Delete
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :ripple="false"
            :loading="loading"
            @click="updateTestDetail"
            :disabled="isBtnDisabled"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      detail: () => {
        return {};
      },
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
    data: {
      handler(newValue) {
        if (newValue && this.$isObj(newValue) && Object.keys(newValue).length) {
          this.testDetailData.title = newValue.title;
          this.testDetailData.description = newValue.description;
          this.testDetailData.experiment_id = newValue.experiment_id;
          this.testDetailData.finish_at = this.$unixToDate(
            newValue.finish_at,
            "YYYY-MM-DD"
          );
          this.testDetailData.start_at = this.$unixToDate(
            newValue.start_at,
            "YYYY-MM-DD"
          );
          this.testDetailData.variants = JSON.parse(newValue.variants);
        } else {
          this.testDetailData = {
            title: "",
            description: "",
            experiment_id: "",
            finish_at: null,
            start_at: null,
            variants: [],
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      deleteLoading: false,
      testDetailData: {
        description: "",
        experiment_id: "",
        finish_at: null,
        start_at: null,
        variants: [],
      },
      start_date_menu: false,
      end_date_menu: false,
    };
  },
  methods: {
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async updateTestDetail() {
      try {
        this.loading = true;
        const requestData = {
          id: this.data.id,
          title: this.testDetailData.title,
          experiment_id: this.testDetailData.experiment_id,
          start_at: this.$dateToUnix(this.testDetailData.start_at),
          finish_at: this.$dateToUnix(this.testDetailData.finish_at),
          variants: JSON.stringify(this.testDetailData.variants),
          description: this.testDetailData.description,
        };

        const response = await this.$api.put("ab-tests/update", requestData);

        if (response.status === 200) {
          this.$toast.success("Successfully updated.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
          this.$emit("updated");
          this.visible = false;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteTestDetail() {
      try {
        this.deleteLoading = true;
        const response = await this.$api.delete(
          `ab-tests/delete/${this.data.id}`
        );

        if (response.status === 200) {
          this.$toast.success("Successfully deleted.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
          this.$emit("deleted");
          this.visible = false;
          return;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.deleteLoading = false;
      }
    },
  },
  computed: {
    isDeleteBtnDisabled() {
      if (!Object.keys(this.data)) return true;
      return false;
    },
    isBtnDisabled() {
      if (!Object.keys(this.data).length) return true;
      if (!this.testDetailData.title) return true;
      if (!this.testDetailData.description) return true;
      if (!this.testDetailData.experiment_id) return true;
      if (!this.testDetailData.start_at) return true;
      if (!this.testDetailData.finish_at) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
