<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">Upload Build</h1>
    <div class="mt-5" v-if="file">
      <v-alert text prominent type="warning" icon="mdi-alert-circle-outline">
        <div class="text-[12px] font-semibold">
          Please be sure to choose the right build!
        </div>
      </v-alert>
    </div>
    <div class="mt-5" v-if="servers.length">
      <div
        v-for="(server, index) in servers"
        :key="server.id"
        class="border p-4 rounded border-gray-150 mb-2"
      >
        <div class="flex items-center justify-between">
          <div class="flex flex-col">
            <div class="flex items-center text-[12px] font-semibold">
              <span class="text-gray-700 mr-2">Server:</span>
              <span class="text-gray-500">{{ server.name }}</span>
            </div>
            <div class="flex items-center text-[12px] font-semibold mt-1">
              <span class="text-gray-700 mr-2">Status:</span>
              <span
                :class="
                  ['TERMINATED', 'STAGING', 'STOPPING'].includes(server.status)
                    ? 'text-red-500'
                    : 'text-green-500'
                "
                >{{ server.status }}</span
              >
            </div>
          </div>
          <v-btn
            color="primary"
            small
            depressed
            class="font-semibold"
            text
            :loading="server.loading"
            :disabled="server.loading || server.status === 'RUNNING'"
            @click="updateInstanceStatus(server.name, index)"
          >
            Turn on
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div>
        <v-select
          hide-details
          class="text-[13px]"
          item-text="title"
          item-value="id"
          label="Choose a game"
          :loading="is_uploading"
          :disabled="is_uploading"
          outlined
          dense
          :items="games"
          v-model="selected_game"
        >
        </v-select>
      </div>
    </div>
    <div class="mt-4">
      <v-text-field
        label="Build Title"
        outlined
        v-model="title"
        dense
        hide-details
        :loading="is_uploading"
        class="text-[13px]"
      ></v-text-field>
    </div>
    <div class="mt-5" v-if="selected_game">
      <v-file-input
        v-model="file"
        chips
        hide-details
        small-chips
        truncate-length="32"
        outlined
        class="text-[13px]"
        dense
        placeholder="Game build (zip)"
        accept="application/zip"
      ></v-file-input>
    </div>
    <div class="mt-5" v-if="selected_game">
      <v-file-input
        v-model="parameter_file"
        chips
        hide-details
        truncate-length="32"
        outlined
        class="text-[13px]"
        dense
        placeholder="Choose the JSON belongs this build"
        accept="application/JSON"
      ></v-file-input>
    </div>
    <div class="flex items-center justify-end mt-4">
      <v-btn
        depressed
        :disabled="
          is_uploading ||
          !file ||
          !selected_game ||
          !this.title ||
          isBtnDisabled
        "
        color="success"
        :loading="is_uploading"
        @click="uploadBuild"
      >
        Upload
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_uploading: false,
      file: null,
      selected_game: null,
      parameter_file: null,
      title: null,
      games: [],
      build_id: null,
      servers: [],
      loading_btn: false,
      servers_on_db: [],
    };
  },
  methods: {
    uploadBuild() {
      if (this.title && this.file && this.selected_game) {
        this.is_uploading = true;
        const formData = new FormData();
        formData.append("build", this.file);
        formData.append("parameter_file", this.parameter_file);
        formData.append("game", this.selected_game);
        formData.append("title", this.title);
        formData.append("modified_title", this.$makeModifiedTitle(this.title));

        this.$api
          .post("acg/game-builds/upload", formData)
          .then(async (response) => {
            if (response.status === 200) {
              this.build_id = response.data.id;
              this.$notify({
                type: "success_",
                group: "success_",
                text: "Successful",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.is_uploading = false;
          });
      }
    },
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // prepareBuildOnACG(blob_info, title, fb_game_id) {
    //   const requestData = {
    //     blob_info,
    //     title,
    //     fb_game_id,
    //   };
    //   this.$api
    //     .post(
    //       `${process.env.VUE_APP_ACG_BACKEND_URL_COMBINATION_SERVER}api/builds/prepare`,
    //       requestData
    //     )
    //     .catch((err) => {
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.is_uploading = false;
    //     });
    // },
    // prepareBuildOnACGMarketing(blob_info, title, fb_game_id) {
    //   const requestData = {
    //     blob_info,
    //     title,
    //     fb_game_id,
    //   };
    //   this.$api
    //     .post(
    //       `${process.env.VUE_APP_ACG_BACKEND_URL}api/builds/prepare`,
    //       requestData
    //     )
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.updateBuild(response.data.build_id);
    //         this.$router.push({
    //           name: "acg-create-play-record-view",
    //         });
    //       }
    //       this.is_uploading = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.is_uploading = false;
    //     });
    // },
    // updateBuild() {
    //   const requestData = {
    //     build_id: this.build_id,
    //   };

    //   this.$api
    //     .put("acg/game-builds/update", requestData)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.$notify({
    //           type: "success_",
    //           group: "success_",
    //           text: "You can create gameplay now!",
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    fetchServers() {
      if (this.servers_on_db && this.servers_on_db.length) {
        this.$api
          .get("utils/instance/all")
          .then((response) => {
            const titles = this.servers_on_db.map((x) => x.instance_name);
            this.servers = response.data.items
              ? response.data.items
                  .map((x) => ({ ...x, loading: false }))
                  .filter((x) => titles.includes(x.name))
              : [];
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fetchServersOnDB() {
      this.$api
        .get("utils/instance/db/all")
        .then(async (response) => {
          this.servers_on_db = response.data;
          await this.fetchServers();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateInstanceStatus(name, index) {
      this.servers[index].loading = true;
      this.$api
        .post(`utils/instance/${name}/run`)
        .then((response) => {
          if (response && response.status === 200) {
            this.fetchServers();
          }
        })
        .finally(() => {
          this.servers[index].loading = false;
        });
    },
  },
  mounted() {
    this.fetchGames();
    this.fetchServersOnDB();
  },
  computed: {
    isBtnDisabled() {
      for (let i = 0; i < this.servers.length; i++) {
        const element = this.servers[i];

        if (element.status !== "RUNNING") return true;
      }

      if (!this.servers.length) return true;

      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
