<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Summary </v-card-title>

        <v-card-text>
          <div>
            <div v-for="(item, index) in data" :key="index">
              <div class="border rounded-[3px] border-gray-200 p-2.5 mb-2">
                <h1 class="mb-2 text-gray-800 font-semibold text-[12px]">
                  {{ item.campaign }}
                </h1>
                <div
                  v-for="(batch, batchIdx) in item.batches"
                  :key="batchIdx"
                  class="p-4 bg-gray-50 rounded-[3px] hover:bg-gray-100"
                >
                  <h1 class="text-blue-800 font-semibold text-[11px] mb-2">
                    {{ batchIdx + 1 }}. {{ batch.batch_name }}
                  </h1>
                  <div
                    v-for="(difference, differenceIndex) in batch.differences"
                    :key="differenceIndex"
                  >
                    <div
                      v-if="
                        ['description', 'headline'].includes(difference.type)
                      "
                      class="mb-0.5"
                    >
                      <span class="text-gray-800 capitalize">
                        {{ differenceIndex + 1 }}. {{ difference.type }}
                      </span>
                      value changed from
                      <span class="text-rose-700"
                        >"{{ difference.originalValue }}"</span
                      >
                      to
                      <span class="text-rose-700"
                        >"{{ difference.value }}".</span
                      >
                    </div>

                    <div v-else class="mb-0.5">
                      <span class="text-blue-700">{{ difference.add }}</span>
                      {{ difference.type }} will be
                      <span class="text-lime-700"> added. </span>
                      <span class="text-blue-700">{{ difference.delete }}</span>
                      {{ difference.type }} will be
                      <span class="text-red-700"> deleted. </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submitSummaryForTasks">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    submitSummaryForTasks() {
      this.$emit("submitted");
    },
  },
};
</script>

<style lang="scss" scoped></style>
