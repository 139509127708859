export default {
  state: {
    networks: [],
    countries: [],
    games: [],
    conversionEvents: [],
  },
  getters: {
    getGames(state) {
      return state.games;
    },
    getNetworks(state) {
      return state.networks;
    },
    getCountries(state) {
      return state.countries;
    },
    getConversionEvents(state) {
      return state.conversionEvents;
    },
  },
  mutations: {
    handleSetGames(state, payload) {
      state.games = payload;
    },
    handleSetNetworks(state, payload) {
      state.networks = payload;
    },
    handleSetCountries(state, payload) {
      state.countries = payload;
    },
    handleSetConversionEvents(state, payload) {
      state.conversionEvents = payload;
    },
  },
  actions: {
    setGames({ commit }, payload) {
      commit("handleSetGames", payload);
    },
    setNetworks({ commit }, payload) {
      commit("handleSetNetworks", payload);
    },
    setCountries({ commit }, payload) {
      commit("handleSetCountries", payload);
    },
    setConversionEvents({ commit }, payload) {
      commit("handleSetConversionEvents", payload);
    },
  },
};
