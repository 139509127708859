<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          {{ mode === "insert" ? "Create new token" : "Update token" }}
        </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <div>
              <v-text-field
                label="Adjust ID"
                outlined
                v-model="data.adjust_id"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                label="IDFA"
                outlined
                v-model="data.idfa"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                label="IDFV"
                outlined
                v-model="data.idfv"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-select
                label="Platform"
                outlined
                v-model="data.platform"
                dense
                :items="platforms"
                item-text="title"
                item-value="value"
                hide-details
                class="text-[13px]"
              ></v-select>
            </div>
            <div class="mt-3">
              <v-text-field
                label="OS Version"
                outlined
                v-model="data.os_version"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-autocomplete
                label="Country"
                outlined
                v-model="data.country"
                dense
                :items="computedCountries"
                item-text="name"
                item-value="code"
                hide-details
                class="text-[13px]"
              ></v-autocomplete>
            </div>
            <div class="mt-3">
              <v-text-field
                label="Language"
                outlined
                v-model="data.language"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                label="Initial Version"
                outlined
                v-model="data.initial_version"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                label="Current Version"
                outlined
                v-model="data.current_version"
                dense
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn
              color="red darken-1"
              :disabled="loading"
              text
              @click="visible = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              :loading="loading"
              text
              @click="createNewToken"
              :disabled="
                !data.initial_version ||
                !data.current_version ||
                !data.country ||
                !data.language ||
                !data.platform ||
                !data.os_version ||
                !data.idfa ||
                !data.idfv
              "
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { COUNTRIES } from "@/helper/utils";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      data: {
        initial_version: "",
        current_version: "",
        country: null,
        language: "",
        platform: null,
        os_version: "",
        idfa: "",
        idfv: "",
        adjust_id: "",
      },
      platforms: [
        {
          title: "Android",
          value: "android",
        },
        {
          title: "iOS",
          value: "ios",
        },
      ],
    };
  },
  computed: {
    computedCountries() {
      return COUNTRIES;
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "insert",
    },
    rowData: {
      type: Object,
      default: () => {},
    },
    package_name: {
      type: String,
      default: null,
    },
    env: {
      type: String,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.data = {
          initial_version: "",
          current_version: "",
          country: null,
          language: "",
          platform: null,
          os_version: "",
          idfa: "",
          idfv: "",
        };
      }
    },
    rowData: {
      handler(newValue) {
        if (newValue) {
          this.data.initial_version = newValue.initial_version;
          this.data.current_version = newValue.current_version;
          this.data.country = newValue.country;
          this.data.language = newValue.language;
          this.data.platform = newValue.platform;
          this.data.os_version = newValue.os_version;
          this.data.idfa = newValue.idfa;
          this.data.idfv = newValue.idfv;
          this.data.adjust_id = newValue.adjust_id;
        }
      },
      deep: true,
    },
  },
  methods: {
    async createNewToken() {
      try {
        this.loading = true;
        const response = await this.$api.post(
          `game-backend/auth/register?env=${this.env}`,
          {
            ...this.data,
            package_name: this.package_name,
          }
        );
        if (response.data.token) {
          this.$emit("created");
          this.visible = false;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
