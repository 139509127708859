import { render, staticRenderFns } from "./CustomSidebar.vue?vue&type=template&id=f85a104c&scoped=true"
import script from "./CustomSidebar.vue?vue&type=script&lang=js"
export * from "./CustomSidebar.vue?vue&type=script&lang=js"
import style0 from "./CustomSidebar.vue?vue&type=style&index=0&id=f85a104c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f85a104c",
  null
  
)

export default component.exports