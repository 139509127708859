<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card v-if="data">
        <v-card-title class="text-h5"> Campaign Summary </v-card-title>

        <v-card-text>
          <div class="pt-4">
            <span class="font-semibold">Game Name:</span>
            <span> {{ computedGameName }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Campaign Name:</span>
            <span> {{ computedCampaignName }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Adgroup Name:</span>
            <span> {{ computedAdGroupName }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Platform:</span>
            <span> iOS </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Daily Budget:</span>
            <span> {{ data.budget }}$ </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Start Time:</span>
            <span> {{ computedStartDate }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">End Time:</span>
            <span> {{ computedEndDate }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Videos will be used :</span>
            <div v-for="(item, index) in videos" :key="index">
              {{ item.name }}
            </div>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Ad(s) will be created :</span>
            <div v-for="(item, index) in videos" :key="index">
              {{ item.name.split(".")[0] }}
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleApprove">
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      visible: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    videos: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  computed: {
    computedCampaignName() {
      if (this.computedGameName) {
        return `${this.computedGameName} - iOS - Lowest - Soft Launch`;
      }
      return null;
    },
    computedAdGroupName() {
      if (this.data && this.computedGameName) {
        return `${this.computedGameName.replace(" ", "")}-US-iOS-Soft Launch`;
      }
      return null;
    },
    computedGameName() {
      if (this.data && this.data.selectedStorePage) {
        return this.data.selectedStorePage.game_name;
      }
      return null;
    },
    computedStartDate() {
      let date = moment()
        .utcOffset("+0100")
        .add("1", "day")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      return date;
    },
    computedEndDate() {
      if (this.computedStartDate) {
        let end_date = moment(this.computedStartDate);
        end_date.add("5", "days");
        return end_date;
      }
      return null;
    },
  },
  methods: {
    handleApprove() {
      if (!this.videos || this.videos.length === 0) {
        this.$notify({
          text: "There is no video selected.",
          group: "error",
        });
      } else {
        this.visible = false;
        const requestData = {
          campaign_title: this.computedCampaignName,
          adgroup_title: this.computedAdGroupName,
          store_page_id: this.data.selectedStorePage.id,
          budget: this.data.budget,
          videos: this.videos,
        };
        this.$emit("approved", requestData);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
