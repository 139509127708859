<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">Taslak Oluştur</h1>

    <div>
      <v-select
        clearable
        label="Oyun Seç"
        :loading="loading"
        :disabled="loading"
        outlined
        auto-select-first
        class="text-[12px]"
        v-model="selected_game"
        :items="games"
        item-text="title"
        item-value="id"
        hide-details
      >
      </v-select>
      <div class="mt-5">
        <v-autocomplete
          clearable
          flat
          label="Müşteri Seç"
          v-model="selected_customer"
          :disabled="clients_loading"
          :loading="clients_loading"
          outlined
          auto-select-first
          class="text-[12px]"
          :items="
            getParasutCustomers.map((x) => {
              return { ...x.attributes, id: x.id };
            })
          "
          item-text="name"
          hide-details
          return-object
        >
        </v-autocomplete>
      </div>
      <div class="mt-2 grid grid-cols-2 gap-4" v-if="selected_customer">
        <div class="p-2 border border-white hover:border-gray-200">
          <h1 class="font-semibold text-[12px] flex items-center">
            <span class="mr-1"
              ><v-icon size="16"> mdi-map-marker-outline </v-icon></span
            >
            1. Adres
          </h1>
          <div class="text-gray-500 text-[11px] hover:text-gray-800 mt-2">
            {{ selected_customer.address }}
          </div>
        </div>
        <div class="p-2 border border-white hover:border-gray-200">
          <h1 class="font-semibold text-[12px] flex items-center">
            <span class="mr-1"
              ><v-icon size="16">
                mdi-card-account-details-outline
              </v-icon></span
            >
            2. Vergi Numarası / T.C No
          </h1>
          <div class="text-gray-500 text-[11px] hover:text-gray-800 mt-2">
            {{ selected_customer.tax_number }}
          </div>
        </div>
        <div class="p-2 border border-white hover:border-gray-200">
          <h1 class="font-semibold text-[12px] flex items-center">
            <span class="mr-1"><v-icon size="16"> mdi-help </v-icon></span>
            3. Adres Yurt Dışında mı?
          </h1>
          <div class="text-gray-500 text-[11px] hover:text-gray-800 mt-2">
            {{ selected_customer.is_abroad ? "Evet" : "Hayır" }}
          </div>
        </div>
        <div class="p-2 border border-white hover:border-gray-200">
          <h1 class="font-semibold text-[12px] flex items-center">
            <span class="mr-1"
              ><v-icon size="16"> mdi-filter-outline </v-icon></span
            >4. Tür
          </h1>
          <div class="text-gray-500 text-[11px] hover:text-gray-800 mt-2">
            {{
              selected_customer.contact_type === "person" ? "Şahıs" : "Şirket"
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="my-5">
      <v-autocomplete
        clearable
        flat
        label="Ürün / Hizmet"
        v-model="selected_parasut_product"
        :disabled="products_loading"
        :loading="products_loading"
        outlined
        auto-select-first
        class="text-[12px]"
        :items="
          getParasutProducts.map((x) => {
            return { ...x.attributes, id: x.id };
          })
        "
        item-text="name"
        return-object
        hide-details
      >
      </v-autocomplete>
    </div>

    <div class="mt-5">
      <v-select
        clearable
        label="KDV"
        outlined
        auto-select-first
        class="text-[12px]"
        v-model="selected_tax"
        :items="taxes"
        item-text="title"
        item-value="id"
        hide-details
      >
        <template v-slot:selection="{ item }">
          <span>% {{ item }}</span>
        </template>
        <template v-slot:item="{ item }">
          <span class="text-[12px]">% {{ item }}</span>
        </template>
      </v-select>
    </div>

    <div class="mb-5">
      <h1 class="text-gray-600 font-semibold mt-5 text-[12px] uppercase">
        İRSALİYE
      </h1>
      <v-radio-group dense hide-details v-model="is_dispatch" row>
        <v-radio label="İrsaliyeli Fatura" :value="true"></v-radio>
        <v-radio label="İrsaliyesiz Fatura" :value="false"></v-radio>
      </v-radio-group>
    </div>

    <div class="mb-5">
      <h1 class="text-gray-600 font-semibold mt-5 text-[12px] uppercase">
        TAHSİLAT DURUMU
      </h1>
      <v-radio-group dense hide-details v-model="is_collected" row>
        <v-radio label="Tahsil Edilecek" :value="false"></v-radio>
        <v-radio label="Tahsil Edildi" :value="true"></v-radio>
      </v-radio-group>
    </div>
    <div class="mb-5">
      <v-autocomplete
        v-model="selected_categories"
        :items="
          getParasutCategories.map((x) => {
            return { ...x.attributes, id: x.id };
          })
        "
        attach
        chips
        outlined
        label="Kategori"
        item-text="name"
        small-chips
        deletable-chips
        auto-select-first
        :loading="categories_loading"
        hide-details
        item-value="id"
      ></v-autocomplete>
    </div>
    <div class="mb-5">
      <v-autocomplete
        v-model="selected_tags"
        :items="
          getParasutTags.map((x) => {
            return { ...x.attributes, id: x.id };
          })
        "
        attach
        chips
        outlined
        label="Etiketler"
        item-text="name"
        small-chips
        multiple
        deletable-chips
        auto-select-first
        hide-details
        item-value="id"
        :loading="tags_loading"
      ></v-autocomplete>
    </div>

    <v-divider></v-divider>
    <div class="flex justify-between mt-5">
      <v-btn
        color="success"
        text
        :elevation="0"
        :ripple="false"
        @click="refreshData"
        :disabled="products_loading || clients_loading"
        :loading="products_loading || clients_loading"
      >
        Bilgileri Yenile
      </v-btn>
      <v-btn
        color="primary"
        :elevation="0"
        :ripple="false"
        @click="createTemplate"
        :disabled="isBtnDisabled"
      >
        Kaydet
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  watch: {
    selected_customer(newValue) {
      this.is_new_customer = !this.customers.includes(newValue);
    },
  },
  data() {
    return {
      loading: false,
      selected_game: null,
      is_dispatch: false,
      is_collected: false,
      parasut_products: [],
      games: [],
      selected_parasut_product: null,
      selected_customer: null,
      customers: [],
      selected_tax: null,
      taxes: [20, 10, 1, 0],
      products_loading: false,
      clients_loading: false,
      tags: [],
      categories: [],
      selected_tags: [],
      selected_categories: [],
      categories_loading: false,
      tags_loading: false,
    };
  },
  methods: {
    ...mapActions([
      "setParasutCustomers",
      "setParasutProducts",
      "setParasutTags",
      "setParasutCategories",
    ]),
    fetchClients() {
      this.clients_loading = true;
      var url = "parasut/clients";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.customers = response.data;
            this.setParasutCustomers(this.customers);
          }
          this.clients_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.clients_loading = false;
        });
    },
    fetchGames() {
      this.loading = true;
      this.$api
        .get("games/all")
        .then((response) => {
          this.games = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    fetchProducts() {
      this.products_loading = true;
      var url = "parasut/products";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.parasut_products = response.data;
            this.setParasutProducts(this.parasut_products);
          }
          this.products_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.products_loading = false;
        });
    },
    createTemplate() {
      const data = {
        is_dispatch: this.is_dispatch,
        is_collected: this.is_collected,
        tags: this.selected_tags,
        categories: this.selected_categories,
        parasut_product: this.selected_parasut_product.id,
        parasut_product_title: this.selected_parasut_product.name,
        parasut_customer: this.selected_customer.id,
        parasut_customer_title: this.selected_customer.name,
        parasut_tax: this.selected_tax,
      };

      const requestData = {
        data: JSON.stringify(data),
        game_id: this.selected_game,
        parasut_product: this.selected_parasut_product.id,
        parasut_product_title: this.selected_parasut_product.name,
        parasut_customer: this.selected_customer.id,
        parasut_customer_title: this.selected_customer.name,
        parasut_tax: this.selected_tax,
      };

      this.$api
        .post("parasut/templates/create", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.selected_game = null;
            this.selected_parasut_product = null;
            this.selected_tax = null;
            this.selected_customer = null;
            this.selected_tags = [];

            this.$notify({
              type: "success_",
              group: "success_",
              text: "Taslak başarıyla oluşturuldu.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshData() {
      this.fetchClients();
      this.fetchProducts();
    },
    fetchCategories() {
      this.categories_loading = true;
      var url = "parasut/categories";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.categories = response.data.filter(
              (x) => x.attributes.category_type === "SalesInvoice"
            );
            const ad_revenue_category = this.categories.filter(
              (x) => x.id === "6718541"
            );

            if (ad_revenue_category.length > 0) {
              this.selected_categories = ad_revenue_category[0].id;
            }

            this.setParasutCategories(this.categories);
          }
          this.categories_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.categories_loading = false;
        });
    },
    fetchTags() {
      this.tags_loading = true;
      var url = "parasut/tags";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.tags = response.data;
            this.setParasutTags(this.tags);
          }
          this.tags_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.tags_loading = false;
        });
    },
  },
  computed: {
    ...mapGetters([
      "getParasutCustomers",
      "getParasutProducts",
      "getParasutTags",
      "getParasutCategories",
    ]),
    isBtnDisabled() {
      if (!this.selected_game) {
        return true;
      }
      if (!this.selected_parasut_product) {
        return true;
      }
      if (!this.selected_customer) {
        return true;
      }

      if (!this.selected_tax && this.selected_tax !== 0) {
        return true;
      }

      if (this.selected_categories.length === 0) {
        return true;
      }

      return false;
    },
  },
  async mounted() {
    // this.fetchCurrencyData();
    if (!this.getParasutCustomers) {
      this.fetchClients();
    }
    if (!this.getParasutProducts) {
      this.fetchProducts();
    }
    if (!this.getParasutCategories) {
      this.fetchCategories();
    }
    if (!this.getParasutTags) {
      this.fetchTags();
    }
    this.fetchGames();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__title {
  font-size: 11px;
  line-height: normal;
}
::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
