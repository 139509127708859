var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getUserProfile)?_c('div',[_c('div',{staticClass:"bg-white p-6"},[_c('div',{staticClass:"flex items-center justify-between pb-6"},[_c('h1',{staticClass:"font-semibold text-[18px]"},[_vm._v("Automatized Creative Testing")]),_c('div',[_c('v-btn',{attrs:{"disabled":_vm.computedUserCredential,"color":"primary","depressed":""},on:{"click":_vm.oauthSignIn}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-google-drive ")]),_vm._v("Connect Drive")],1)],1)]),(
        [1, 5].includes(_vm.getUserProfile.department.id) &&
        _vm.computedUserCredential
      )?_c('div',[((_vm.result && _vm.result.success) || (_vm.result && _vm.result.id))?_c('div',{staticClass:"p-6 rounded border border-green-200 bg-green-50 text-[12px] mb-5 text-green-800 font-semibold"},[_vm._v(" Creative has been successfully created. ")]):_vm._e(),_c('div',[_c('v-select',{staticClass:"text-[13px]",attrs:{"hide-details":"","item-text":"title","label":"Choose a game","loading":_vm.loading,"disabled":_vm.loading || _vm.selected_base_creative !== null,"outlined":"","dense":"","items":_vm.games,"return-object":""},model:{value:(_vm.artist_selected_game),callback:function ($$v) {_vm.artist_selected_game=$$v},expression:"artist_selected_game"}})],1),_c('div',{staticClass:"mt-5"},[_c('v-select',{staticClass:"text-[13px]",attrs:{"hide-details":"","disabled":_vm.loading || _vm.selected_base_creative !== null,"item-text":"title","item-value":"id","label":"Upload type","loading":_vm.loading,"outlined":"","dense":"","items":_vm.artist_upload_types},model:{value:(_vm.artist_upload_type),callback:function ($$v) {_vm.artist_upload_type=$$v},expression:"artist_upload_type"}})],1),_c('div',[_c('div',{staticClass:"mt-5"},[_c('v-text-field',{staticClass:"text-[13px]",attrs:{"label":"Creative Name","hide-details":"","outlined":"","loading":_vm.loading,"disabled":_vm.loading || _vm.selected_base_creative !== null,"dense":""},model:{value:(_vm.creative_name),callback:function ($$v) {_vm.creative_name=$$v},expression:"creative_name"}})],1),_c('div',{staticClass:"mt-5"},[_c('v-autocomplete',{staticClass:"text-[13px]",attrs:{"clearable":"","label":"Choose a language","outlined":"","auto-select-first":"","dense":"","loading":_vm.loading,"disabled":_vm.loading || _vm.selected_base_creative !== null,"items":_vm.references,"item-text":"name","item-value":"iso_lower","hide-details":""},model:{value:(_vm.selected_language),callback:function ($$v) {_vm.selected_language=$$v},expression:"selected_language"}})],1),(
            _vm.artist_selected_game &&
            _vm.artist_selected_game.title.toLowerCase() !== 'drill and collect'
          )?_c('div',{staticClass:"mt-5"},[_c('v-file-input',{staticClass:"text-[13px]",attrs:{"show-size":"","accept":".inputtrace","label":"Choose trace file","outlined":"","loading":_vm.loading,"disabled":_vm.loading || _vm.selected_base_creative !== null,"dense":"","hide-details":""},model:{value:(_vm.trace_file),callback:function ($$v) {_vm.trace_file=$$v},expression:"trace_file"}})],1):_vm._e(),_c('div',{staticClass:"mt-5"},[_c('v-file-input',{staticClass:"text-[13px]",attrs:{"show-size":"","accept":"application/json","label":"Parameters json file","outlined":"","loading":_vm.loading,"disabled":_vm.loading || _vm.selected_base_creative !== null,"dense":"","hide-details":""},model:{value:(_vm.parameters_file),callback:function ($$v) {_vm.parameters_file=$$v},expression:"parameters_file"}})],1),_c('div',{staticClass:"mt-5"},[_c('v-file-input',{staticClass:"text-[13px]",attrs:{"multiple":"","show-size":"","accept":"video/mp4","label":"Choose video(s)","outlined":"","loading":_vm.loading,"disabled":_vm.loading || _vm.selected_base_creative !== null,"dense":"","hide-details":""},model:{value:(_vm.marketing_artist_videos),callback:function ($$v) {_vm.marketing_artist_videos=$$v},expression:"marketing_artist_videos"}})],1),_c('div',{staticClass:"mt-5"},[_c('VideoSlider',{attrs:{"videos":_vm.marketing_artist_videos},on:{"delete_video":_vm.handleOnDeleteVideo}})],1)]),(
          _vm.artist_selected_game &&
          _vm.artist_upload_type &&
          _vm.creative_name &&
          _vm.selected_language &&
          _vm.marketing_artist_videos.length > 0 &&
          _vm.computedErrors.length === 0 &&
          !_vm.selected_base_creative
        )?_c('div',[_c('div',{staticClass:"my-5"},[_c('v-divider')],1),_c('h1',{staticClass:"font-semibold text-[13px] mb-5"},[_vm._v("1. General Metadata")]),_c('StandartTags',{attrs:{"defaultGameGenre":_vm.artist_selected_game.computedGenres,"tags":this.tags},on:{"formChanged":_vm.handleStandartTagFormChange}})],1):_vm._e(),_c('div',{staticClass:"my-5 flex items-center justify-end"},[_c('v-btn',{attrs:{"disabled":(!_vm.selected_base_creative &&
              (_vm.isMarketingBtnDisabled || _vm.loading)) ||
            (_vm.selected_base_creative && _vm.isMarketingBtnDisabled),"loading":_vm.loading,"color":"primary"},nativeOn:{"click":function($event){return _vm.handleSubmitButton.apply(null, arguments)}}},[_vm._v("Upload")])],1)]):_c('div',[_c('v-alert',{attrs:{"text":"","type":"error","prominent":"","icon":"mdi-alert-circle-outline","border":"left"}},[_c('div',{staticClass:"text-[12px] p-2 font-semibold"},[_vm._v(" You do not have required permission or you need to connect your drive account (if you are a marketing artist). ")])])],1)]),_c('ErrorDialog',{attrs:{"videos":_vm.marketing_artist_videos,"show":_vm.computedErrors.length > 0,"errors":_vm.computedErrors},on:{"closed":_vm.handleErrorDialogClosed}}),_c('UploadPointOfView',{attrs:{"show":_vm.show_upload_point_of_view},on:{"closed":_vm.handleUploadPointOfViewClosed,"submitted":_vm.handleUploadPointOfViewSubmitted}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }