<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Fatura Yönetim Ekranı</h1>

      <div class="filter-panel flex items-center justify-end">
        <div>
          <v-select
            style="width: 180px"
            outlined
            v-model="selected_month"
            :items="months"
            dense
            clearable
            label="Ay"
            hide-details
            class="text-[13px]"
            item-text="title"
            item-value="id"
          ></v-select>
        </div>
        <div v-if="getParasutCustomers" class="ml-2">
          <v-select
            clearable
            flat
            dense
            label="Müşteri Seç"
            v-model="selected_customer"
            outlined
            auto-select-first
            class="text-[12px]"
            :items="
              getParasutCustomers.map((x) => {
                return { ...x.attributes, id: x.id };
              })
            "
            item-text="name"
            item-value="id"
            hide-details
            style="width: 180px"
          ></v-select>
        </div>
        <div class="ml-2">
          <v-select
            style="width: 220px"
            outlined
            v-model="filter_game"
            :items="games"
            dense
            clearable
            label="Oyun"
            hide-details
            class="text-[13px]"
            item-text="title"
            item-value="id"
          ></v-select>
        </div>
        <div class="ml-2">
          <v-select
            style="width: 220px"
            outlined
            v-model="selected_status"
            :items="status_list"
            dense
            label="Durum"
            hide-details
            class="text-[13px]"
            item-text="title"
            item-value="id"
          ></v-select>
        </div>
        <div class="ml-2">
          <v-btn
            @click="exportAsCSV"
            height="40"
            text
            depressed
            class="font-semibold"
          >
            <span> Dışarı Aktar </span>
            <v-icon size="20" class="ml-2">mdi-file-export</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="mt-3">
        <div class="flex flex-col">
          <div class="flex items-center">
            <span class="text-gray-700 font-semibold text-[12px]">
              Toplam Tutar (USD Faturalar):
            </span>
            <span class="text-[12px] font-semibold text-indigo-700 ml-2">
              {{ computedUsdInvoices.toLocaleString("de-DE") }} $
            </span>
          </div>
          <div class="flex items-center mt-1">
            <span class="text-gray-700 font-semibold text-[12px]">
              Toplam Tutar (TRY Faturalar):
            </span>
            <span class="text-[12px] font-semibold text-indigo-700 ml-2">
              {{ computedTrlInvoices.toLocaleString("de-DE") }} ₺
            </span>
          </div>
          <div class="flex items-center mt-1">
            <span class="text-gray-700 font-semibold text-[12px]">
              Toplam Tutar (EUR Faturalar):
            </span>
            <span class="text-[12px] font-semibold text-indigo-700 ml-2">
              {{ computedEuroInvoices.toLocaleString("de-DE") }} €
            </span>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <v-data-table
          show-select
          class="row-pointer"
          v-model="selectedRows"
          :headers="headers"
          :items="invoices.items"
          :loading="loading"
          loading-text="Please wait..."
          :items-per-page="limit"
          :page="currentPage"
          @pagination="handlePaginationChange"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="handleSortBy"
          @update:sort-desc="handleSortDesc"
          :server-items-length="invoices.count"
          @click:row="handleOnRowClick"
          :footer-props="{
            itemsPerPageOptions: [15, 25, 50, -1],
          }"
        >
          <template v-slot:[`item.id`]="{ item }">
            {{ item.id }}
          </template>
          <template v-slot:[`item.game_title`]="{ item }">
            {{ item.game.title }}
          </template>
          <template v-slot:[`item.customer_title`]="{ item }">
            {{ item.parasut_customer_title }}
          </template>
          <template v-slot:[`item.tax_percentage`]="{ item }">
            {{ item.parasut_tax }} %
          </template>
          <template v-slot:[`item.tax_amount`]="{ item }">
            {{
              (
                item.total_price -
                calculatedTaxAmount(item.total_price, item.parasut_tax)
              ).toLocaleString("de-DE")
            }}
            {{ computedCurrency(item.product_currency) }}
          </template>
          <template v-slot:[`item.price_without_tax`]="{ item }">
            {{
              calculatedTaxAmount(
                item.total_price,
                item.parasut_tax
              ).toLocaleString("de-DE")
            }}
            {{ computedCurrency(item.product_currency) }}
          </template>
          <template v-slot:[`item.issue_date`]="{ item }">
            {{ formattedDate(item.issue_date) }}
          </template>
          <template v-slot:[`item.total_price`]="{ item }">
            {{ item.total_price.toLocaleString("de-DE") }}
            {{ computedCurrency(item.product_currency) }}
          </template>
          <template v-slot:[`item.status_text`]="{ item }">
            <span
              v-if="item.status_id !== 5 && !item.parasut_invoice_id"
              class="text-[11px] font-semibold"
              :style="{ color: status_id_colorize(item.status_id).color }"
            >
              {{ item.status_text }}
            </span>
            <span
              v-if="item.parasut_invoice_id"
              class="text-[11px] font-semibold hover:underline"
              :style="{ color: status_id_colorize(item.status_id).color }"
              @click.stop="openInTab(item.parasut_invoice_id)"
            >
              {{ item.status_text }}
            </span>
          </template>
        </v-data-table>
      </div>

      <div class="d-flex items-center justify-between mt-5">
        <v-btn
          color="error"
          depressed
          class="font-semibold"
          :disabled="selectedRows.length === 0 || loadingDelete"
          @click="deleteSelectedInvoices"
          :loading="loadingDelete"
        >
          Seçilmişleri Sil
        </v-btn>

        <v-btn
          color="primary"
          @click="addToQueue"
          depressed
          class="font-semibold"
          :disabled="selectedRows.filter((x) => x.status_id === 1).length === 0"
          :loading="queueLoading"
        >
          Fatura Oluştur
        </v-btn>
      </div>
    </div>
    <ParasutInvoiceInfo
      v-if="games && customers && parasut_products && categories && tags"
      @closed="handleModalClose"
      @updated="handleModalUpdate"
      @deleted="handleModalDelete"
      :show="show_information"
      :row_data="clicked_row_data"
      @addToQueue="addToQueueHandler"
      :games="games"
      :customers="getParasutCustomers"
      :parasut_products="getParasutProducts"
      :categories="getParasutCategories"
      :tags="getParasutTags"
    />
  </div>
</template>

<script>
import moment from "moment";
moment.locale("tr");
import ParasutInvoiceInfo from "@/components/tools/parasut/ParasutInvoiceInfo.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AllInvoicesView",
  data() {
    return {
      queueLoading: false,
      loadingDelete: false,
      loading: false,
      keyword: null,
      limit: 25,
      currentPage: 1,
      sortDesc: false,
      sortBy: null,
      selectedRows: [],
      invoices: {
        count: 0,
        items: [],
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Oyun", value: "game_title" },
        { text: "Müşteri", value: "customer_title" },
        { text: "Vergi Oranı (%)", value: "tax_percentage" },
        { text: "Tutar", value: "price_without_tax", sortable: false },
        { text: "KDV", value: "tax_amount", sortable: false },
        { text: "Toplam Tutar", value: "total_price", sortable: false },
        { text: "Düzenlenme Tarihi", value: "issue_date" },
        { text: "Durum", value: "status_text" },
      ],
      show_information: null,
      clicked_row_data: null,
      games: [],
      games_loading: false,
      filter_game: null,
      customers: [],
      parasut_products: [],
      categories: [],
      tags: [],
      categories_loading: false,
      products_loading: false,
      clients_loading: false,
      months: [
        {
          title: "Ocak",
          id: 1,
        },
        {
          title: "Şubat",
          id: 2,
        },
        {
          title: "Mart",
          id: 3,
        },
        {
          title: "Nisan",
          id: 4,
        },
        {
          title: "Mayıs",
          id: 5,
        },
        {
          title: "Haziran",
          id: 6,
        },
        {
          title: "Temmuz",
          id: 7,
        },
        {
          title: "Ağustos",
          id: 8,
        },
        {
          title: "Eylül",
          id: 9,
        },
        {
          title: "Ekim",
          id: 10,
        },
        {
          title: "Kasım",
          id: 11,
        },
        {
          title: "Aralık",
          id: 12,
        },
      ],
      selected_month: null,
      selected_status: 1,
      status_list: [
        {
          title: "Kontrol Bekliyor",
          id: 1,
        },
        {
          title: "Sıraya Eklendi",
          id: 2,
        },
        {
          title: "E-Arşiv Oluşturulacak",
          id: 3,
        },
        {
          title: "E-Arşiv Kontrol Ediliyor",
          id: 4,
        },
        {
          title: "Tamamlandı",
          id: 5,
        },
        {
          title: "Hata",
          id: 50,
        },
        {
          title: "Tümü",
          id: -1,
        },
      ],
      selected_customer: null,
    };
  },
  watch: {
    selected_month() {
      this.fetchInvoices();
    },
    filter_game() {
      this.fetchInvoices();
    },
    selected_status() {
      this.fetchInvoices();
    },
    selected_customer() {
      this.fetchInvoices();
    },
  },
  methods: {
    ...mapActions([
      "setParasutCustomers",
      "setParasutProducts",
      "setParasutTags",
      "setParasutCategories",
    ]),
    handlePaginationChange(event) {
      if (
        this.limit !== event.itemsPerPage ||
        this.currentPage !== event.page
      ) {
        this.limit = event.itemsPerPage;
        this.currentPage = event.page;
        this.fetchInvoices();
      }
    },
    openInTab(id) {
      window.open(
        `https://uygulama.parasut.com/${process.env.VUE_APP_PARASUT_COMPANY_ID}/satislar/${id}`,
        "_blank"
      );
    },
    handleSortBy(sortBy) {
      this.sortBy = sortBy;
      this.fetchInvoices();
    },
    handleSortDesc(sortDesc) {
      this.sortDesc = sortDesc;
      this.fetchInvoices();
    },
    computedCurrency(currency) {
      if (currency === "USD") return "$";
      if (currency === "TRL") return "₺";
      if (currency === "EUR") return "€";
    },
    fetchInvoices() {
      this.invoices.items = [];
      this.invoices.count = 0;

      var url = `parasut/invoices/all?limit=${this.limit}&page=${this.currentPage}`;

      var query_data = {};
      var params = null;

      if (this.selected_month) {
        query_data.month = this.selected_month;
      }

      if (this.filter_game) {
        query_data.game_id = this.filter_game;
      }

      if (this.selected_customer) {
        query_data.customer_id = this.selected_customer;
      }

      if (this.selected_status && this.selected_status !== -1) {
        query_data.status_id = this.selected_status;
      }

      if (Object.keys(query_data).length !== 0) {
        params = new URLSearchParams(query_data).toString();
      }

      if (params) {
        url = `${url}&${params}`;
      }

      if (this.sortBy) {
        url = `${url}&sortBy=${this.sortBy}`;
      }

      if (this.sortDesc) {
        url = `${url}&sortDesc=${this.sortDesc}`;
      }

      this.loading = true;
      this.$api
        .get(`${url}`)
        .then((response) => {
          if (response.status === 200) {
            this.invoices.count = response.data.count;
            this.invoices.items = response.data.items;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    formattedDate(date) {
      return moment(date).format("DD MMMM, YYYY");
    },
    status_id_colorize(status_id) {
      var data = {
        color: null,
      };

      if (status_id === 1) {
        data.color = "#2979FF";
      }
      if (status_id === 2) {
        data.color = "#512DA8";
      }
      if (status_id === 3) {
        data.color = "#00796B";
      }
      if (status_id === 4) {
        data.color = "#00796B";
      }
      if (status_id === 5) {
        data.color = "#2E7D32";
      }
      if (status_id >= 100) {
        data.color = "#C62828";
      }

      return data;
    },
    handleOnRowClick(data) {
      this.clicked_row_data = data;
      this.show_information = true;
    },
    handleModalClose() {
      this.show_information = false;
    },
    addToQueueHandler() {
      this.fetchInvoices();
      this.show_information = false;
    },
    addToQueue() {
      if (this.selectedRows.length > 0) {
        const requestData = {
          selected_ids: this.selectedRows
            .filter((x) => x.status_id === 1)
            .map((x) => x.id),
        };
        if (requestData.selected_ids.length > 0) {
          this.queueLoading = true;
          this.$api
            .post("parasut/invoices/add-to-queue", requestData)
            .then((response) => {
              if (response.status === 200) {
                this.selectedRows = [];
                this.queueLoading = false;
                this.fetchInvoices();
              }
            })
            .catch(() => {
              this.queueLoading = false;
            });
        }
      }
    },
    fetchGames() {
      this.games_loading = true;
      this.$api
        .get("games/all")
        .then((response) => {
          this.games = response.data;
          this.games_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.games_loading = false;
        });
    },
    fetchClients() {
      this.clients_loading = true;
      var url = "parasut/clients";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.customers = response.data;
            this.setParasutCustomers(this.customers);
          }
          this.clients_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.clients_loading = false;
        });
    },
    fetchProducts() {
      this.products_loading = true;
      var url = "parasut/products";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.parasut_products = response.data;
            this.setParasutProducts(this.parasut_products);
          }
          this.products_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.products_loading = false;
        });
    },
    fetchCategories() {
      this.categories_loading = true;
      var url = "parasut/categories";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.categories = response.data.filter(
              (x) => x.attributes.category_type === "SalesInvoice"
            );
            const ad_revenue_category = this.categories.filter(
              (x) => x.id === "6718541"
            );

            if (ad_revenue_category.length > 0) {
              this.selected_categories = ad_revenue_category[0].id;
            }
            this.setParasutCategories(this.categories);
          }
          this.categories_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.categories_loading = false;
        });
    },
    fetchTags() {
      this.tags_loading = true;
      var url = "parasut/tags";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.tags = response.data;
            this.setParasutTags(this.tags);
          }
          this.tags_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.tags_loading = false;
        });
    },
    calculatedTaxAmount(total_price, tax_percentage) {
      const base_price = (total_price * 100) / (tax_percentage + 100);
      return Math.round(base_price * 100) / 100;
    },
    handleModalUpdate() {
      this.clicked_row_data = null;
      this.show_information = false;
      this.fetchInvoices();
    },
    exportAsCSV() {
      const beautifulArray = this.invoices.items.map((x) => {
        return {
          id: x.id,
          game: x.game.title,
          customer: x.parasut_customer_title,
          product: x.parasut_product_title,
          issue_date: x.issue_date,
          tax_percentage: x.parasut_tax,
          tax_amount: `${
            Math.round(
              (x.total_price -
                this.calculatedTaxAmount(x.total_price, x.parasut_tax)) *
                100
            ) / 100
          }`,
          total_price_without_tax: `${this.calculatedTaxAmount(
            x.total_price,
            x.parasut_tax
          )}`,
          total_price: `${x.total_price}`,
          parasut_invoice_id: x.parasut_invoice_id ? x.parasut_invoice_id : "-",
          product_price_per_unit: x.product_price_per_unit,
          product_amount: x.product_amount,
          currency: x.product_currency,
          invoice_title: x.invoice_description,
          status_text: x.status_text,
        };
      });
      const titleKeys = Object.keys(beautifulArray[0]);
      const refinedData = [];

      refinedData.push(titleKeys);

      beautifulArray.forEach((item) => {
        refinedData.push(Object.values(item));
      });

      let csvContent = "";

      refinedData.forEach((row) => {
        csvContent += row.join(";") + "\n";
      });

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objUrl);
      link.setAttribute("download", this.getFileName());
      link.click();
    },
    handleModalDelete() {
      this.clicked_row_data = null;
      this.show_information = false;
      this.fetchInvoices();
    },
    getFileName() {
      return `Parasut_${moment().format("DD-MM-YYYY_HHmm")}.csv`;
    },
    async deleteSelectedInvoices() {
      const data = this.selectedRows
        .filter((x) => [1, 2].includes(x.status_id))
        .map((x) => x.id);

      const requestData = {
        ids: data,
      };
      try {
        this.loadingDelete = true;
        const request = await this.$api.post(
          "parasut/invoices/delete-bulk",
          requestData
        );
        if (request.status === 200) {
          this.$toast.success(`${data.length} adet fatura başarıyla silindi!`, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
          this.selectedRows = [];
          this.fetchInvoices();
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingDelete = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  mounted() {
    this.fetchInvoices();
    this.fetchGames();
    if (!this.getParasutCustomers) {
      this.fetchClients();
    }
    if (!this.getParasutProducts) {
      this.fetchProducts();
    }
    if (!this.getParasutCategories) {
      this.fetchCategories();
    }
    if (!this.getParasutTags) {
      this.fetchTags();
    }
  },
  components: {
    ParasutInvoiceInfo,
  },
  computed: {
    ...mapGetters([
      "getParasutCustomers",
      "getParasutProducts",
      "getParasutTags",
      "getParasutCategories",
    ]),
    computedUsdInvoices() {
      if (this.invoices && this.invoices.items.length) {
        const filtered = this.invoices.items.filter(
          (x) => x.product_currency === "USD"
        );
        var sum = 0;

        for (let i = 0; i < filtered.length; i++) {
          const element = filtered[i];
          sum += element.total_price;
        }

        return sum;
      }
      return 0;
    },
    computedTrlInvoices() {
      if (this.invoices && this.invoices.items.length) {
        const filtered = this.invoices.items.filter(
          (x) => x.product_currency === "TRL"
        );
        var sum = 0;

        for (let i = 0; i < filtered.length; i++) {
          const element = filtered[i];
          sum += element.total_price;
        }

        return sum;
      }
      return 0;
    },
    computedEuroInvoices() {
      if (this.invoices && this.invoices.items.length) {
        const filtered = this.invoices.items.filter(
          (x) => x.product_currency === "EUR"
        );
        var sum = 0;

        for (let i = 0; i < filtered.length; i++) {
          const element = filtered[i];
          sum += element.total_price;
        }

        return sum;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}

::v-deep .v-btn {
  letter-spacing: normal !important;
  text-transform: capitalize;
}
</style>
