import UploadAssetsView from "@/views/tools/act/marketing-artist/UploadAssetsView.vue";
import ManageAssetsView from "@/views/tools/act/marketing-artist/ManageAssetsView.vue";
import ManageAssetsDetail from "@/views/tools/act/marketing-artist/ManageAssetsDetail.vue";
import CombinationDetail from "@/views/tools/act/marketing-artist/CombinationDetail.vue";
import NetworkConfigurationView from "@/views/tools/act/ua-specialist/NetworkConfigurationView.vue";
import CreativeInsightsView from "@/views/tools/act/ua-specialist/CreativeInsightsView.vue";
import ExternalDriveSettingsView from "@/views/tools/act/ua-specialist/ExternalDriveSettingsView.vue";
import AssetsView from "@/views/tools/act/AssetsView.vue";

export const act_router = [
  {
    path: "/act/upload-assets",
    name: "act-upload-assets-view",
    component: UploadAssetsView,
    meta: {
      title: "Upload Assets",
    },
  },
  {
    path: "/act/manage-assets",
    name: "act-manage-assets-view",
    component: ManageAssetsView,
    meta: {
      title: "Manage Assets",
    },
  },
  {
    path: "/act/creative-insights",
    name: "act-creative-insights-view",
    component: CreativeInsightsView,
    meta: {
      title: "Creative Insights",
    },
  },
  {
    path: "/act/manage-assets/:id",
    component: ManageAssetsDetail,
    name: "act-manage-assets-detail",
    meta: {
      title: "Asset Detail",
    },
  },
  {
    path: "/act/manage-assets/combination/:id",
    component: CombinationDetail,
    name: "act-combination-detail",
    meta: {
      title: "Combination Detail",
    },
  },
  {
    path: "/act/network-configuration",
    component: NetworkConfigurationView,
    name: "act-network-configuration",
    meta: {
      title: "Network Configuration",
    },
  },
  {
    path: "/act/drive-settings",
    component: ExternalDriveSettingsView,
    name: "act-drive-settings",
    meta: {
      title: "External Drive Settings",
    },
  },
  {
    path: "/act/assets",
    component: AssetsView,
    name: "act-assets",
    meta: {
      title: "Assets",
    },
  },
];
