var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-white p-6"},[_c('h1',{staticClass:"font-semibold text-[18px] pb-6"},[_vm._v("Burcha")]),_c('div',[(_vm.computedSelectedRows)?_c('div',[_c('h1',{staticClass:"font-semibold text-[13px] text-gray-800 mb-3"},[_vm._v(" Selected Creatives ")]),_c('div',{staticClass:"flex items-center gap-1"},_vm._l((_vm.computedSelectedRows),function(row,index){return _c('div',{key:index,staticClass:"text-[10px] mb-1 font-semibold bg-rose-50 text-rose-600 px-2 py-1 rounded-[3px] hover:bg-rose-200 cursor-pointer"},[_vm._v(" "+_vm._s(row.ad_name)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"mt-4"},[(this.step === 1)?_c('NetworkCampaignManager',{on:{"campaignsSelected":_vm.handleCampaignsSelected}}):_vm._e(),(_vm.getBurchaSelectedNetwork === 'facebook')?_c('div',[(this.step === 2)?_c('FacebookCreativeManager',{attrs:{"selectedCampaigns":_vm.selectedCampaigns,"selectedCreatives":_vm.computedSelectedRows,"game_id":_vm.$route.params.game_id}}):_vm._e()],1):_vm._e(),(_vm.getBurchaSelectedNetwork === 'google')?_c('div',[(this.step === 2)?_c('GoogleCreativeManager',{attrs:{"selectedCampaigns":_vm.selectedCampaigns,"selectedCreatives":_vm.computedSelectedRows,"game_id":_vm.$route.params.game_id}}):_vm._e()],1):_vm._e(),(
            ['unity-ios', 'unity-android'].includes(_vm.getBurchaSelectedNetwork)
          )?_c('div',[(this.step === 2)?_c('UnityCreativeManager',{attrs:{"selectedCampaigns":_vm.selectedCampaigns,"selectedCreatives":_vm.computedSelectedRows,"game_id":_vm.$route.params.game_id,"platform":_vm.getBurchaSelectedNetwork === 'unity-ios' ? 'ios' : 'android'}}):_vm._e()],1):_vm._e(),(_vm.getBurchaSelectedNetwork === 'mintegral')?_c('div',[(this.step === 2)?_c('MintegralCreativeManager',{attrs:{"selectedCampaigns":_vm.selectedCampaigns,"selectedCreatives":_vm.computedSelectedRows,"game_id":_vm.$route.params.game_id}}):_vm._e()],1):_vm._e(),(
            ['applovin-ios', 'applovin-android'].includes(
              _vm.getBurchaSelectedNetwork
            )
          )?_c('div',[(this.step === 2)?_c('ApplovinCreativeManager',{attrs:{"selectedCampaigns":_vm.selectedCampaigns,"selectedCreatives":_vm.computedSelectedRows,"game_id":_vm.$route.params.game_id,"platform":_vm.getBurchaSelectedNetwork === 'applovin-ios' ? 'ios' : 'android'}}):_vm._e()],1):_vm._e(),(
            ['ironsource-ios', 'ironsource-android'].includes(
              _vm.getBurchaSelectedNetwork
            )
          )?_c('div',[(this.step === 2)?_c('IronSourceCreativeManager',{attrs:{"game_id":_vm.$route.params.game_id,"selectedCampaigns":_vm.selectedCampaigns,"selectedCreatives":_vm.computedSelectedRows,"platform":_vm.getBurchaSelectedNetwork === 'ironsource-ios'
                ? 'ios'
                : 'android'}}):_vm._e()],1):_vm._e()],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"flex items-center justify-between"},[_c('div',[_c('v-btn',{attrs:{"text":"","disabled":this.step === 1},on:{"click":_vm.decreaseStep}},[_vm._v(" Back ")])],1),_c('div',[_c('v-btn',{attrs:{"text":"","disabled":_vm.step === 2 || !_vm.selectedCampaigns.length || !_vm.computedSelectedRows},on:{"click":_vm.increaseStep}},[_vm._v(" Next ")])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }