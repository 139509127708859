var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"820"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.template_information)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Taslak Detayı ")]),_c('v-card-text',[_c('div',{staticClass:"mt-5"},[_c('div',[_c('div',{staticClass:"mt-5"},[_c('v-select',{staticClass:"text-[12px]",attrs:{"dense":"","clearable":"","label":"Oyun Seç","outlined":"","auto-select-first":"","items":_vm.games,"item-text":"title","item-value":"id","hide-details":""},model:{value:(_vm.selected_game),callback:function ($$v) {_vm.selected_game=$$v},expression:"selected_game"}})],1),_c('div',{staticClass:"mt-5"},[_c('v-autocomplete',{staticClass:"text-[12px]",attrs:{"clearable":"","dense":"","flat":"","label":"Müşteri Seç","outlined":"","auto-select-first":"","items":_vm.customers.map((x) => {
                    return { ...x.attributes, id: x.id };
                  }),"item-text":"name","hide-details":"","return-object":""},model:{value:(_vm.selected_customer),callback:function ($$v) {_vm.selected_customer=$$v},expression:"selected_customer"}})],1),_c('div',{staticClass:"my-5"},[_c('v-autocomplete',{staticClass:"text-[12px]",attrs:{"clearable":"","flat":"","label":"Ürün / Hizmet","dense":"","outlined":"","auto-select-first":"","items":_vm.products.map((x) => {
                    return { ...x.attributes, id: x.id };
                  }),"item-text":"name","return-object":"","hide-details":""},model:{value:(_vm.selected_parasut_product),callback:function ($$v) {_vm.selected_parasut_product=$$v},expression:"selected_parasut_product"}})],1),_c('div',{staticClass:"mt-5"},[_c('v-select',{staticClass:"text-[12px]",attrs:{"dense":"","clearable":"","label":"KDV","outlined":"","auto-select-first":"","items":_vm.taxes,"item-text":"title","item-value":"id","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('span',[_vm._v("% "+_vm._s(item))])]}},{key:"item",fn:function({ item }){return [_c('span',{staticClass:"text-[12px]"},[_vm._v("% "+_vm._s(item))])]}}],null,false,2278503847),model:{value:(_vm.selected_tax),callback:function ($$v) {_vm.selected_tax=$$v},expression:"selected_tax"}})],1),_c('div',{staticClass:"mb-5"},[_c('h1',{staticClass:"text-gray-600 font-semibold mt-5 text-[12px] uppercase"},[_vm._v(" İRSALİYE ")]),_c('v-radio-group',{attrs:{"dense":"","hide-details":"","row":""},model:{value:(_vm.is_dispatch),callback:function ($$v) {_vm.is_dispatch=$$v},expression:"is_dispatch"}},[_c('v-radio',{attrs:{"label":"İrsaliyeli Fatura","value":true}}),_c('v-radio',{attrs:{"label":"İrsaliyesiz Fatura","value":false}})],1)],1),_c('div',{staticClass:"mb-5"},[_c('h1',{staticClass:"text-gray-600 font-semibold mt-5 text-[12px] uppercase"},[_vm._v(" TAHSİLAT DURUMU ")]),_c('v-radio-group',{attrs:{"dense":"","hide-details":"","row":""},model:{value:(_vm.is_collected),callback:function ($$v) {_vm.is_collected=$$v},expression:"is_collected"}},[_c('v-radio',{attrs:{"label":"Tahsil Edilecek","value":false}}),_c('v-radio',{attrs:{"label":"Tahsil Edildi","value":true}})],1)],1),_c('div',{staticClass:"mb-5"},[_c('v-autocomplete',{attrs:{"items":_vm.categories.map((x) => {
                    return { ...x.attributes, id: x.id };
                  }),"attach":"","chips":"","outlined":"","label":"Kategori","item-text":"name","small-chips":"","deletable-chips":"","auto-select-first":"","hide-details":"","dense":"","item-value":"id"},model:{value:(_vm.selected_categories),callback:function ($$v) {_vm.selected_categories=$$v},expression:"selected_categories"}})],1),_c('div',{staticClass:"mb-5"},[_c('v-autocomplete',{attrs:{"items":_vm.tags.map((x) => {
                    return { ...x.attributes, id: x.id };
                  }),"attach":"","chips":"","outlined":"","label":"Etiketler","item-text":"name","small-chips":"","multiple":"","deletable-chips":"","auto-select-first":"","hide-details":"","item-value":"id","dense":""},model:{value:(_vm.selected_tags),callback:function ($$v) {_vm.selected_tags=$$v},expression:"selected_tags"}})],1)])])]),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"flex items-center justify-between w-full"},[_c('v-btn',{attrs:{"elevation":0,"ripple":false,"color":"error","loading":_vm.loadingDeleteButton,"disabled":_vm.loadingDeleteButton},on:{"click":_vm.deleteTemplate}},[_vm._v(" Sil ")])],1),_c('div',[_c('v-btn',{attrs:{"elevation":0,"ripple":false,"color":"success","disabled":_vm.isUpdateBtnDisabled || _vm.loadingUpdateButton,"loading":_vm.loadingUpdateButton},on:{"click":_vm.updateTemplate}},[_vm._v(" Güncelle ")])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }