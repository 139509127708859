<!-- eslint-disable -->
<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Burcha</h1>

      <div>
        <div v-if="computedSelectedRows">
          <h1 class="font-semibold text-[13px] text-gray-800 mb-3">
            Selected Creatives
          </h1>
          <div class="flex items-center gap-1">
            <div
              class="text-[10px] mb-1 font-semibold bg-rose-50 text-rose-600 px-2 py-1 rounded-[3px] hover:bg-rose-200 cursor-pointer"
              v-for="(row, index) in computedSelectedRows"
              :key="index"
            >
              {{ row.ad_name }}
            </div>
          </div>
        </div>
        <div class="mt-4">
          <NetworkCampaignManager
            v-if="this.step === 1"
            @campaignsSelected="handleCampaignsSelected"
          />

          <div v-if="getBurchaSelectedNetwork === 'facebook'">
            <FacebookCreativeManager
              v-if="this.step === 2"
              :selectedCampaigns="selectedCampaigns"
              :selectedCreatives="computedSelectedRows"
              :game_id="$route.params.game_id"
            />
          </div>

          <div v-if="getBurchaSelectedNetwork === 'google'">
            <GoogleCreativeManager
              v-if="this.step === 2"
              :selectedCampaigns="selectedCampaigns"
              :selectedCreatives="computedSelectedRows"
              :game_id="$route.params.game_id"
            />
          </div>

          <div
            v-if="
              ['unity-ios', 'unity-android'].includes(getBurchaSelectedNetwork)
            "
          >
            <UnityCreativeManager
              v-if="this.step === 2"
              :selectedCampaigns="selectedCampaigns"
              :selectedCreatives="computedSelectedRows"
              :game_id="$route.params.game_id"
              :platform="
                getBurchaSelectedNetwork === 'unity-ios' ? 'ios' : 'android'
              "
            />
          </div>

          <div v-if="getBurchaSelectedNetwork === 'mintegral'">
            <MintegralCreativeManager
              v-if="this.step === 2"
              :selectedCampaigns="selectedCampaigns"
              :selectedCreatives="computedSelectedRows"
              :game_id="$route.params.game_id"
            />
          </div>

          <div
            v-if="
              ['applovin-ios', 'applovin-android'].includes(
                getBurchaSelectedNetwork
              )
            "
          >
            <ApplovinCreativeManager
              v-if="this.step === 2"
              :selectedCampaigns="selectedCampaigns"
              :selectedCreatives="computedSelectedRows"
              :game_id="$route.params.game_id"
              :platform="
                getBurchaSelectedNetwork === 'applovin-ios' ? 'ios' : 'android'
              "
            />
          </div>

          <div
            v-if="
              ['ironsource-ios', 'ironsource-android'].includes(
                getBurchaSelectedNetwork
              )
            "
          >
            <IronSourceCreativeManager
              v-if="this.step === 2"
              :game_id="$route.params.game_id"
              :selectedCampaigns="selectedCampaigns"
              :selectedCreatives="computedSelectedRows"
              :platform="
                getBurchaSelectedNetwork === 'ironsource-ios'
                  ? 'ios'
                  : 'android'
              "
            />
          </div>
        </div>

        <v-divider class="my-5"></v-divider>
        <div class="flex items-center justify-between">
          <div>
            <v-btn text :disabled="this.step === 1" @click="decreaseStep">
              Back
            </v-btn>
          </div>

          <div>
            <v-btn
              text
              @click="increaseStep"
              :disabled="
                step === 2 || !selectedCampaigns.length || !computedSelectedRows
              "
            >
              Next
            </v-btn>
          </div>
        </div>
      </div>
      <!-- <div v-else>
        <v-alert type="info" prominent shaped text>
          <div class="text-[13px] font-bold">No Creative Selected</div>
          <div class="text-[12px] mt-2">
            You did not choose any creative to use Burcha. Nagivate
            <router-link class="font-semibold" to="/act/creative-insights"
              >this page</router-link
            >
            to choose some creatives!
          </div>
        </v-alert>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import NetworkCampaignManager from "@/components/tools/campaign-manager/NetworkCampaignManager.vue";
import FacebookCreativeManager from "@/components/tools/campaign-manager/networks/facebook/FacebookCreativeManager.vue";
import GoogleCreativeManager from "@/components/tools/campaign-manager/networks/google/GoogleCreativeManager.vue";
import UnityCreativeManager from "@/components/tools/campaign-manager/networks/unity/UnityCreativeManager.vue";
import MintegralCreativeManager from "@/components/tools/campaign-manager/networks/mintegral/MintegralCreativeManager.vue";
import ApplovinCreativeManager from "@/components/tools/campaign-manager/networks/applovin/ApplovinCreativeManager.vue";
import IronSourceCreativeManager from "@/components/tools/campaign-manager/networks/ironsource/IronSourceCreativeManager.vue";

export default {
  data() {
    return {
      networks: [
        "Facebook",
        "TikTok",
        "Unity",
        "Mintegral",
        "IronSource",
        "Google",
      ].sort(),
      selected_network: "Facebook",
      data: [],
      creatives: [],
      selectedCreatives: [],
      searchKeyword: "",
      loading: false,
      step: 1,
      selectedCampaigns: [],
      campaignNetwork: "Facebook",
    };
  },
  watch: {
    selected_network() {
      if (this.selected_network !== "Unity") {
        this.fetchCampaigns();
      }
    },
    searchKeyword: _.debounce(function () {
      this.fetchCreatives();
    }, 1500),

    step: {
      handler(newValue) {
        if (newValue === 1) {
          this.selectedCampaigns = [];
        }
      },
    },
  },
  methods: {
    handleNetworkSelection(network) {
      this.campaignNetwork = network;
    },
    handleCampaignsSelected(data) {
      this.selectedCampaigns = data;
    },
    increaseStep() {
      this.step = this.step + 1;
    },
    decreaseStep() {
      this.step = this.step - 1;
    },
    fetchCreatives() {
      if (this.searchKeyword) {
        this.loading = true;
        var url = `campaign-manager/creatives?title=${this.searchKeyword}`;

        this.$api
          .get(url)
          .then((response) => {
            this.creatives = response.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    fetchCreativesWithoutKeyword() {
      this.loading = true;
      var url = `campaign-manager/creatives`;

      this.$api
        .get(url)
        .then((response) => {
          this.creatives = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async fetchCampaigns() {
      try {
        const request = await this.$api.get(
          `campaign-manager/campaigns?network=${this.selected_network.toLowerCase()}`
        );
        this.data = request.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["getBurchaSelectedNetwork"]),
    computedSelectedRows() {
      return this.$route.params.selectedCreatives;
    },
  },
  components: {
    NetworkCampaignManager,
    FacebookCreativeManager,
    GoogleCreativeManager,
    UnityCreativeManager,
    MintegralCreativeManager,
    ApplovinCreativeManager,
    IronSourceCreativeManager,
  },
};
</script>

<style lang="scss" scoped></style>
