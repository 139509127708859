<template>
  <div>
    <v-dialog v-model="visible" width="900">
      <v-card>
        <v-card-title class="text-h5"> Copy to External Drive </v-card-title>

        <v-card-text>
          <h1 class="text-[12px] font-semibold mt-5">Choose Channels</h1>
          <div class="mt-2">
            <v-autocomplete
              hide-details
              class="text-[13px]"
              item-text="title"
              return-object
              outlined
              clearable
              dense
              :items="settings"
              v-model="selectedSettings"
              hide-no-data
              multiple
              placeholder="Choose settings"
            >
            </v-autocomplete>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="submit"
              :disabled="!selectedSettings.length"
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      selectedSettings: [],
      settings: [],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedGame: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
        this.selectedSettings = [];
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed", this.selectedSettings);
      }
    },

    selectedGame(newValue) {
      if (newValue && newValue.external_drive_settings) {
        this.settings = JSON.parse(newValue.external_drive_settings);
      } else {
        this.settings = [];
      }
    },
  },
  methods: {
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    submit() {
      this.$emit("submitted", this.selectedSettings);
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
