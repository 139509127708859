<template>
  <div>
    <div class="border rounded" v-if="!unityReportsLoading">
      <v-expansion-panels
        accordion
        focusable
        flat
        v-model="selectedAdsetPanel"
        v-if="unityReportsData"
      >
        <v-expansion-panel v-for="(data, index) in modifiedData" :key="index">
          <v-expansion-panel-header>
            <div class="flex items-center">
              <span class="font-semibold text-[11px] text-blue-900">
                {{ data.campaign_name }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="rounded bg-sky-50 text-sky-800 font-semibold p-5 text-[11px] mt-5 border border-sky-200"
            >
              {{
                data.selectedCreativesForAdding.filter((x) => x.selected).length
              }}
              creative(s) will be added and
              {{ data.selectedCreativesForDeleting.length }} creative(s) will be
              deleted.
            </div>
            <div class="flex items-center justify-between mt-4 mb-0">
              <h1 class="font-semibold text-[13px] capitalize text-gray-900">
                <span> 1. Select creatives to add </span>
              </h1>
            </div>

            <div class="pt-3">
              <div class="w-full mb-5">
                <div class="grid grid-cols-4 gap-3">
                  <div
                    v-for="creative in data.selectedCreativesForAdding"
                    :key="creative.ad_id"
                    class="rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100"
                    :class="{
                      selected: creative.selected,
                    }"
                    @click="creative.selected = !creative.selected"
                  >
                    <div class="flex items-center justify-between mb-2">
                      <div class="flex items-center">
                        <span
                          class="text-[11px]"
                          :class="
                            creative.selected
                              ? 'text-gray-700 font-semibold'
                              : 'text-gray-400'
                          "
                        >
                          {{ creative.ad_name }}
                        </span>
                        <span
                          v-if="creative.improvement"
                          class="text-[10px] ml-2"
                          :class="{
                            'font-semibold': creative.selected,
                            'text-gray-400': !creative.selected,
                            'text-lime-700':
                              creative.improvement >= 0 && creative.selected,
                            'text-rose-700':
                              creative.improvement < 0 && creative.selected,
                          }"
                        >
                          {{ creative.improvement.toFixed(2) }}%
                        </span>
                      </div>
                      <span
                        class="text-[9px] uppercase font-semibold p-1 rounded-[3px] hover:bg-gray-300 cursor-pointer select-none"
                        @click="
                          handlePreviewBtn(
                            $event,
                            creative.gaia_id,
                            creative.selected_playable_id
                          )
                        "
                        :class="
                          creative.selected
                            ? 'text-gray-700'
                            : 'text-gray-400 pointer-events-none'
                        "
                      >
                        Preview
                      </span>
                    </div>

                    <div class="flex items-center">
                      <div>
                        <v-radio-group
                          dense
                          v-model="creative.selectedAdType"
                          row
                          :disabled="!creative.selected"
                        >
                          <v-radio
                            @click.stop
                            label="Image & Video"
                            value="image+video"
                          ></v-radio>
                          <v-radio
                            @click.stop
                            label="Playable & Video"
                            value="playable+video"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <div
                      class="mt-2"
                      v-if="creative.selectedAdType === 'playable+video'"
                    >
                      <div class="mt-2" @click.stop>
                        <v-autocomplete
                          clearable
                          label="Choose an asset"
                          outlined
                          auto-select-first
                          dense
                          class="text-[12px]"
                          v-model="creative.selected_playable_id"
                          :items="assetData.filter((x) => x.type === 'html')"
                          hide-details
                          item-text="title"
                          item-value="id"
                          :disabled="!creative.selected"
                        >
                        </v-autocomplete>
                      </div>
                    </div>
                    <div
                      class="mt-2"
                      v-if="creative.selectedAdType === 'image+video'"
                    >
                      <div class="mt-2" @click.stop>
                        <v-autocomplete
                          clearable
                          label="Choose an image"
                          outlined
                          auto-select-first
                          dense
                          class="text-[12px]"
                          v-model="creative.image"
                          :items="assetData"
                          hide-details
                          item-text="title"
                          item-value="id"
                          :disabled="!creative.selected"
                        >
                        </v-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider class="my-5"></v-divider>
              <h1
                class="mb-2 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
              >
                <span>Creatives </span>
              </h1>

              <!-- <h1 class="text-gray-800 font-semibold text-[11px] mb-3">
                  Average CVR: {{ computedAverageCvr(data) }}
                </h1> -->

              <div class="mt-3">
                <v-data-table
                  :headers="applovinCreativeSetHeaders"
                  :items="data.creative_sets"
                  :items-per-page="15"
                  item-key="id"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span
                      class="text-gray-800 text-[11px] font-semibold uppercase"
                    >
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:[`item.ads`]="{ item }">
                    <div v-if="item.ads.length">
                      <v-chip
                        v-for="ad in item.ads"
                        :key="ad.id"
                        small
                        label
                        color="#E3F2FD"
                        text-color="#0D47A1"
                        class="ma-1"
                      >
                        <span class="font-semibold text-[10px]">
                          {{ ad.name }}
                        </span>
                      </v-chip>
                    </div>
                    <div v-else>-</div>
                  </template>

                  <template v-slot:[`item.assets`]="{ item }">
                    <div v-if="item.assets.length">
                      <v-chip
                        v-for="(asset, assetIdx) in item.assets
                          .map((x) => x.type)
                          .sort()"
                        :key="assetIdx"
                        small
                        label
                        color="#E0E0E0"
                        text-color="#424242"
                        class="ma-1"
                      >
                        <span class="font-semibold text-[10px]">
                          {{ asset }}
                        </span>
                      </v-chip>
                    </div>
                    <div v-else>-</div>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div class="flex justify-end items-center my-3">
      <v-btn
        color="primary"
        depressed
        class="font-semibold"
        @click="replaceCreatives"
        :loading="loadingBtn"
        :disabled="loadingBtn || !computedRequestData.length"
      >
        Replace Creatives
      </v-btn>
    </div>

    <!-- <PreviewDialog
        :gaia_id="preview_selected_gaia_id"
        :playable_id="preview_selected_playable_id"
        :show="showPreview"
        @closed="handlePreviewDialogClose"
      />
  
      <SummaryDialog
        :data="modifiedData"
        :selectedCreatives="selectedCreatives"
        :show="showSummary"
        @closed="handleSummaryDialogClose"
        @submitted="replaceCreatives"
      /> -->
  </div>
</template>

<script>
import _ from "lodash";
//   import PreviewDialog from "./PreviewDialog.vue";
//   import SummaryDialog from "./SummaryDialog.vue";

export default {
  props: {
    selectedCampaigns: {
      type: Array,
      default: () => [],
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    game_id: {
      type: Number,
      default: null,
    },
    platform: {
      type: String,
      default: null,
    },
  },
  watch: {
    selectedCampaigns: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.length && this.selectedCreatives.length) {
          this.modifiedData = JSON.parse(
            JSON.stringify(this.applovinDataModifier(newValue))
          );
        }
      },
    },
  },
  data() {
    return {
      selectedAds: [],
      loading: false,
      fbAdsetData: [],
      adData: [],
      selectedAdsetPanel: null,
      applovinCreativeSetHeaders: [
        {
          text: "Name",
          value: "name",
          sortable: true,
        },
        {
          text: "Ads",
          value: "ads",
        },
        {
          text: "Assets",
          value: "assets",
        },
      ],
      modifiedData: [],
      assetData: [],
      loadingBtn: false,
      showPreview: false,
      preview_selected_gaia_id: null,
      preview_selected_playable_id: null,
      showSummary: false,
      unityRequestTimeout: null,
      unityReportsData: [],
      unityReportsLoading: false,
    };
  },
  methods: {
    handleSummaryDialogClose() {
      this.showSummary = false;
    },
    handlePreviewDialogClose() {
      this.showPreview = false;
      this.preview_selected_gaia_id = null;
      this.preview_selected_playable_id = null;
    },
    handlePreviewBtn($event, gaia_id, playable_id) {
      $event.stopPropagation();
      this.preview_selected_gaia_id = gaia_id;
      this.preview_selected_playable_id = playable_id;
      this.showPreview = true;
    },
    async replaceCreatives() {
      if (!this.computedRequestData.length) return;

      const requestData = {
        data: this.computedRequestData.map((x) => ({
          ...x,
          inserted_creatives: JSON.stringify(x.inserted_creatives),
          deleted_ads: JSON.stringify(x.deleted_ads),
        })),
      };

      try {
        const response = await this.$api.post(
          "burcha/applovin/tasks/create",
          requestData
        );
        if (response.status === 200) {
          this.$toast.success(
            "Tasks successfully created. You will be notified on Slack when tasks run.",
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          this.$router.push({ name: "home" });
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    // async createUnityTask(data) {
    //   try {
    //     this.loadingBtn = true;
    //     const request = await this.$api.post(
    //       `burcha/unity/tasks/create/create-task`,
    //       data
    //     );

    //     if (request.status === 200) {
    //       this.$toast.success(
    //         "Tasks successfully created. You will be notified on Slack when tasks run.",
    //         {
    //           timeout: 5000,
    //           position: "bottom-center",
    //           icon: false,
    //         }
    //       );

    //       this.$router.push({ name: "home" });
    //     }
    //   } catch (error) {
    //     this.errorHandler(error);
    //   } finally {
    //     this.loadingBtn = false;
    //   }
    // },
    applovinDataModifier(data) {
      if (!data.length) return [];
      var modifiedData = [];

      for (let i = 0; i < data.length; i++) {
        var campaign = data[i];
        campaign.selectedCreativesForAdding = JSON.parse(
          JSON.stringify(
            this.selectedCreatives.map((x) => ({
              ...x,
              selected: true,
              is_playable: false,
              is_image: false,
              selected_playable_id: null,
              image: null,
              selectedAdType: "playable+video",
            }))
          )
        );

        campaign.selectedCreativesForDeleting = [];
        modifiedData.push(campaign);
      }

      return modifiedData;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    sortedReportData(data) {
      const creativePackIds = data.creative_packs.map((x) => x.id);
      if (!creativePackIds.length) return [];

      var filtered = this.unityReportsData.filter((x) =>
        creativePackIds.includes(x.creative_pack_id)
      );

      for (let y = 0; y < data.creative_packs.length; y++) {
        const creative_pack = data.creative_packs[y];
        const idx = filtered.findIndex(
          (x) => x.creative_pack_id === creative_pack.id
        );

        if (idx === -1) {
          filtered.push({
            cvr: 0,
            creative_pack_id: creative_pack.id,
            creative_pack_name: creative_pack.name,
            views: 0,
            clicks: 0,
            installs: 0,
            spend: 0,
          });
        }
      }

      const sortedData = _.orderBy(filtered, "cvr", "desc");
      return sortedData;
    },
    computedAverageCvr(data) {
      if (!data.creative_packs.length) return 0;
      const creativePackIds = data.creative_packs.map((x) => x.id);
      if (!creativePackIds.length) return 0;

      var total = 0;
      var length = 0;
      for (let i = 0; i < this.unityReportsData.length; i++) {
        const reportData = this.unityReportsData[i];
        if (creativePackIds.includes(reportData.creative_pack_id)) {
          total += reportData.cvr;
          length += 1;
        }
      }

      return length ? Math.round((total / length) * 10000) / 10000 : 0;
    },
    computedAverageImpression(adgroupData) {
      if (!adgroupData.ads.length) {
        return null;
      }

      var total = 0;
      for (let y = 0; y < adgroupData.ads.length; y++) {
        const ad = adgroupData.ads[y];
        total += ad.insights.impressions
          ? parseInt(ad.insights.impressions)
          : 0;
      }

      const length = adgroupData.ads.filter((x) =>
        x.insights.impressions ? true : false
      ).length;

      return length ? Math.round((total / length) * 100) / 100 : 0;
    },
    async fetchPlayableAssets() {
      try {
        const request = await this.$api.get(
          `burcha/applovin/assets/all?game_id=${this.game_id}`
        );
        if (request) {
          this.assetData = request.data;
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
  },
  async mounted() {
    await this.fetchPlayableAssets();
    //   this.unityReportsLoading = true;
    //   this.unityRequestTimeout = setTimeout(await this.fetchUnityReports, 2000);
  },
  components: {
    //   PreviewDialog,
    //   SummaryDialog,
  },
  computed: {
    computedRequestData() {
      var data = [];
      for (let i = 0; i < this.modifiedData.length; i++) {
        const campaign = this.modifiedData[i];

        var temp = {
          game_id: this.game_id,
          campaign_id: campaign.campaign_id,
          campaign_name: campaign.campaign_name,
          inserted_creatives: [],
          deleted_ads: [],
          platform: campaign.platform,
        };

        for (let y = 0; y < campaign.selectedCreativesForAdding.length; y++) {
          const x = campaign.selectedCreativesForAdding[y];

          var insertedCreativeData = {
            type: null,
            asset_id: null,
            gaia_id: null,
          };

          if (x.selected) {
            if (x.selectedAdType === "playable+video") {
              if (!x.selected_playable_id) {
                return [];
              }

              insertedCreativeData.type = "playable+video";
              insertedCreativeData.asset_id = x.selected_playable_id;
              insertedCreativeData.gaia_id = x.gaia_id;
              insertedCreativeData.title = x.ad_name;
            }

            if (x.selectedAdType === "image+video") {
              if (!x.selected_image_id) return [];

              insertedCreativeData.type = "image+video";
              insertedCreativeData.asset_id = x.selected_image_id;
              insertedCreativeData.gaia_id = x.gaia_id;
              insertedCreativeData.title = x.ad_name;
            }

            if (
              insertedCreativeData.type &&
              insertedCreativeData.asset_id &&
              insertedCreativeData.gaia_id
            ) {
              temp.inserted_creatives.push(insertedCreativeData);
            }
          }
        }

        if (temp.inserted_creatives.length) {
          data.push(temp);
        }
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-footer {
  border-top: none !important;
}
::v-deep table {
  border: 1px solid rgba($color: #000000, $alpha: 0.15);
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.selectedAdSection {
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 2px 4px;
}

.selectedAdSection.selected {
  box-shadow: rgba(63, 216, 16, 0.4) 0px 0px 0px 1px,
    rgba(22, 93, 0, 0.1) 0px 2px 4px;
}

::v-deep .v-label {
  font-size: 10px !important;
  font-weight: 600;
}
</style>
