<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Settings </v-card-title>
        <v-card-text v-if="loading">
          <div>
            <div>
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
            </div>
            <div>
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
            </div>
            <div>
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </div>
          </div>
        </v-card-text>
        <v-card-text v-if="campaignSettings && !loading">
          <div class="">
            <v-expansion-panels v-model="selectedPanels" multiple flat>
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><span class="font-semibold text-gray-800 text-[11px]"
                    >Campaign Settings</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div>
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign ID
                    </div>
                    <div class="mt-2">
                      <v-text-field
                        label="Campaign ID"
                        outlined
                        disabled
                        v-model="campaignSettingsData.id"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign Title
                    </div>
                    <div class="mt-2">
                      <v-text-field
                        label="Title"
                        outlined
                        v-model="campaignSettingsData.name"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Start & End Date
                    </div>
                    <div class="mt-2 flex items-center gap-5">
                      <div class="w-full">
                        <v-menu
                          v-model="start_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="text-[13px]"
                              label="Campaign Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              dense
                              hide-details
                              v-bind="attrs"
                              disabled
                              v-on="on"
                              v-model="campaignSettingsData.start_date"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            @input="start_date_menu = false"
                            v-model="campaignSettingsData.start_date"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      <div class="w-full">
                        <v-menu
                          v-model="end_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="text-[13px]"
                              label="Campaign End Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              dense
                              hide-details
                              :disabled="has_no_end_date"
                              v-bind="attrs"
                              v-on="on"
                              v-model="campaignSettingsData.end_date"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            @input="end_date_menu = false"
                            v-model="campaignSettingsData.end_date"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                    <div>
                      <v-checkbox
                        style="margin-top: 6px"
                        hide-details
                        label="Has no end date"
                        v-model="has_no_end_date"
                      ></v-checkbox>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign Status
                    </div>
                    <div>
                      <v-switch
                        v-model="campaignSettings.status"
                        :label="`${campaignSettings.status.toUpperCase()}`"
                        class="text-[13px]"
                        hide-details
                        true-value="ENABLED"
                        false-value="PAUSED"
                        style="margin-top: 4px"
                      ></v-switch>
                    </div>
                  </div>

                  <div class="flex items-center justify-end w-full mt-3">
                    <v-btn
                      color="primary"
                      text
                      @click="updateCampaignData"
                      :loading="loadingSettingsBtn"
                    >
                      Save
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  ><span class="font-semibold text-gray-800 text-[11px]"
                    >Budget</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign Budget
                    </div>
                    <div class="mt-2">
                      <v-text-field
                        label="Budget"
                        outlined
                        v-model="campaignSettingsData.budget"
                        dense
                        type="number"
                        hide-details
                        class="text-[13px]"
                        suffix="$"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="flex items-center justify-end w-full mt-3">
                    <v-btn
                      color="primary"
                      text
                      @click="updateCampaignBudgetData"
                      :loading="loadingSettingsBudgetBtn"
                    >
                      Save
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  ><span class="font-semibold text-gray-800 text-[11px]"
                    >Countries</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Targeted Countries
                    </div>
                    <div class="mt-2">
                      <v-autocomplete
                        label="Targeted Countries"
                        outlined
                        v-model="campaignSettingsData.targeted_countries"
                        :items="computedCountries"
                        item-text="name"
                        item-value="code"
                        multiple
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-autocomplete>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Excluded Countries
                    </div>
                    <div class="mt-2">
                      <v-autocomplete
                        label="Excluded Countries"
                        outlined
                        v-model="campaignSettingsData.excluded_countries"
                        :items="computedCountries"
                        item-text="name"
                        item-value="code"
                        multiple
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-autocomplete>
                    </div>
                  </div>

                  <div class="flex items-center justify-end w-full mt-3">
                    <v-btn color="primary" text disabled> Save </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  ><span class="font-semibold text-gray-800 text-[11px]"
                    >Bidding</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <!-- 
            <div class="mt-3">
              <span class="text-[11px] font-semibold"
                >What do you want to focus on?</span
              >
              <div class="w-[320px] mt-1.5">
                <v-select
                  outlined
                  :items="[
                    'Install Volume',
                    'In-App Actions',
                    'In-App Action Value',
                  ]"
                  dense
                  hide-details
                  clearable
                  class="text-[13px]"
                ></v-select>
              </div>
            </div>

            <div class="mt-3">
              <span class="text-[11px] font-semibold text-gray-700"
                >How do you want to track install volume?</span
              >
              <div class="w-[320px] mt-1.5">
                <v-select
                  outlined
                  :items="['App First Open', 'App Installs']"
                  dense
                  hide-details
                  clearable
                  class="text-[13px]"
                ></v-select>
              </div>
            </div>

            <div class="mt-3">
              <span class="text-[11px] font-semibold text-gray-700"
                >Which actions are most important to you?</span
              >
              <div>
                <v-checkbox hide-details label="Ad Impression"></v-checkbox>
              </div>
              <div class="mt-1.5">
                <v-checkbox
                  hide-details
                  label="Ad Impression Revenue"
                ></v-checkbox>
              </div>

              <div class="mt-1.5">
                <v-checkbox hide-details label="In-App Purchase"></v-checkbox>
              </div>

              <div class="mt-1.5">
                <v-checkbox hide-details label="Store View"></v-checkbox>
              </div>
            </div> -->

                  <div
                    class="mt-3"
                    v-if="campaignSettingsData.target_roas !== null"
                  >
                    <span class="text-[11px] font-semibold text-gray-700"
                      >Target return on ad spend</span
                    >
                    <div class="w-[220px] mt-1.5">
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        hide-details
                        class="text-[13px]"
                        suffix="%"
                        v-model="campaignSettingsData.target_roas"
                      ></v-text-field>
                    </div>
                  </div>

                  <div
                    class="mt-3"
                    v-if="campaignSettingsData.target_cpa !== null"
                  >
                    <span class="text-[11px] font-semibold text-gray-700"
                      >Target cost per action / install</span
                    >
                    <div class="w-[220px] mt-1.5">
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        hide-details
                        class="text-[13px]"
                        suffix="$"
                        v-model="campaignSettingsData.target_cpa"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="flex items-center justify-end w-full mt-3">
                    <v-btn
                      color="primary"
                      text
                      @click="updateCampaignBidData"
                      :loading="loadingSettingsBidBtn"
                    >
                      Save
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { COUNTRIES } from "@/helper/utils";
import _ from "lodash";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    game_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },

    data(newValue) {
      if (newValue) {
        this.campaignSettings = JSON.parse(JSON.stringify(newValue));
        this.fetchCampaignSettings();
      } else {
        this.campaignSettings = null;
      }
    },

    // campaignSettingsData: {
    //   deep: true,
    //   handler: _.debounce(function () {
    //     if (
    //       this.campaignSettingsData.target_cpa &&
    //       typeof this.campaignSettingsData.target_cpa !== "number"
    //     ) {
    //       try {
    //         this.campaignSettingsData.target_cpa = parseFloat(
    //           this.campaignSettingsData.target_cpa
    //         );
    //       } catch (error) {
    //         this.campaignSettingsData.target_cpa =
    //           this.campaignSettingsDataTemp.target_cpa;
    //       }
    //     }

    //     if (
    //       this.campaignSettingsData.target_roas &&
    //       typeof this.campaignSettingsData.target_roas !== "number"
    //     ) {
    //       try {
    //         this.campaignSettingsData.target_roas = parseFloat(
    //           this.campaignSettingsData.target_roas
    //         );
    //       } catch (error) {
    //         this.campaignSettingsData.target_roas =
    //           this.campaignSettingsDataTemp.target_roas;
    //       }
    //     }

    //     if (
    //       this.campaignSettingsData.budget &&
    //       typeof this.campaignSettingsData.budget !== "number"
    //     ) {
    //       try {
    //         this.campaignSettingsData.budget = parseFloat(
    //           this.campaignSettingsData.budget
    //         );
    //       } catch (error) {
    //         this.campaignSettingsData.budget =
    //           this.campaignSettingsDataTemp.budget;
    //       }
    //     }
    //   }, 1000),
    // },
  },
  data() {
    return {
      selectedPanels: [],
      visible: false,
      start_date_menu: false,
      end_date_menu: false,
      campaignSettings: null,
      campaignSettingsData: {
        id: null,
        name: null,
        budget: null,
        targeted_countries: [],
        excluded_countries: [],
        start_date: null,
        end_date: null,
        status: null,
        target_roas: null,
        target_cpa: null,
        budget_resource_name: null,
      },
      campaignSettingsDataTemp: null,
      has_no_end_date: false,
      loading: false,
      loadingSettingsBtn: false,
      loadingSettingsBudgetBtn: false,
      loadingSettingsBidBtn: false,
    };
  },
  methods: {
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchCampaignSettings() {
      try {
        this.loading = true;
        const request = await this.$api.get(
          `campaign-manager/settings/google?campaign_id=${this.campaignSettings.campaign_id}&game_id=${this.game_id}`
        );
        this.campaignSettingsData.id = request.data.campaign.id;
        this.campaignSettingsData.name = request.data.campaign.name;
        this.campaignSettingsData.budget = (
          request.data.campaign_budget.amount_micros / 1000000
        ).toString();

        const start_date = moment(
          request.data.campaign.start_date,
          "YYYY-MM-DD"
        );
        const end_date = moment(request.data.campaign.end_date, "YYYY-MM-DD");

        this.campaignSettingsData.start_date = request.data.campaign.start_date;
        this.campaignSettingsData.end_date = request.data.campaign.end_date;

        if (end_date.diff(start_date, "years") >= 10) {
          this.has_no_end_date = true;
          this.campaignSettingsData.end_date = null;
        }
        this.campaignSettingsData.status = request.data.campaign.status;
        this.campaignSettingsData.target_roas = request.data.campaign
          .target_roas
          ? (request.data.campaign.target_roas?.target_roas * 100).toString()
          : null;
        this.campaignSettingsData.target_cpa = request.data.campaign.target_cpa
          ? (
              request.data.campaign.target_cpa?.target_cpa_micros / 1000000
            ).toString()
          : null;

        this.campaignSettingsData.budget_resource_name =
          request.data.campaign.campaign_budget;

        if (request.data.geoTarget) {
          this.campaignSettingsData.targeted_countries = JSON.parse(
            JSON.stringify(
              request.data.geoTarget
                .filter((x) => !x.campaign_criterion.negative)
                .map((x) => x.country.country_code)
            )
          );

          this.campaignSettingsData.excluded_countries = JSON.parse(
            JSON.stringify(
              request.data.geoTarget
                .filter((x) => x.campaign_criterion.negative)
                .map((x) => x.country.country_code)
            )
          );
        }

        this.campaignSettingsDataTemp = JSON.parse(
          JSON.stringify(this.campaignSettingsData)
        );
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async updateCampaignData() {
      try {
        this.loadingSettingsBtn = true;
        const requestData = {
          id: this.campaignSettingsData.id,
          name: this.campaignSettingsData.name,
          start_date: this.campaignSettingsData.start_date,
          end_date: this.campaignSettingsData.end_date,
          status: this.campaignSettingsData.status,
          game_id: this.game_id,
        };

        const request = await this.$api.post(
          `campaign-manager/google/update`,
          requestData
        );

        if (request.status === 200) {
          this.$toast.success("You have successfully updated the campaign.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          this.visible = false;
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingSettingsBtn = false;
      }
    },

    async updateCampaignBudgetData() {
      try {
        this.loadingSettingsBudgetBtn = true;
        const requestData = {
          id: this.campaignSettingsData.id,
          game_id: this.game_id,
          budget: this.campaignSettingsData.budget * 1000000,
          budget_resource_name: this.campaignSettingsData.budget_resource_name,
        };

        const request = await this.$api.post(
          `campaign-manager/google/budget-update`,
          requestData
        );

        if (request.status === 200) {
          this.$toast.success("You have successfully updated the campaign.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingSettingsBudgetBtn = false;
      }
    },

    async updateCampaignBidData() {
      try {
        this.loadingSettingsBidBtn = true;
        const requestData = {
          id: this.campaignSettingsData.id,
          game_id: this.game_id,
          target_cpa: this.campaignSettingsData.target_cpa * 1000000,
          target_roas: this.campaignSettingsData.target_roas / 100,
        };

        console.log(requestData);

        const request = await this.$api.post(
          `campaign-manager/google/bid-update`,
          requestData
        );

        if (request.status === 200) {
          this.$toast.success("You have successfully updated the campaign.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingSettingsBidBtn = false;
      }
    },
  },
  computed: {
    computedCountries() {
      return COUNTRIES.map((x) => ({ ...x, code: x.code.toUpperCase() }));
    },

    isSaveBtnDisabled() {
      return _.isEqual(
        this.campaignSettingsData,
        this.campaignSettingsDataTemp
      );
    },
  },
  mounted() {
    // setInterval(() => {
    //   this.fetchCampaignSettings();
    // }, 15000);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 11px !important;
  font-weight: 600;
}

::v-deep .v-expansion-panel {
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  border-radius: 3px;
  margin-top: 4px;
}
</style>
