<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card v-if="data && data.name">
        <v-card-title class="text-h5"> Campaign Summary </v-card-title>

        <v-card-text>
          <div class="pt-4">
            <span class="font-semibold">Game Name:</span>
            <span> {{ data.name }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Campaign Name:</span>
            <span> {{ computedCampaignName }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Adset Name:</span>
            <span> {{ computedAdsetName }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Platform:</span>
            <span> iOS </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Daily Budget:</span>
            <span> {{ (daily_budget / 100).toLocaleString() }}$ </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Start Time:</span>
            <span> {{ computedStartDate }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">End Time:</span>
            <span> {{ computedEndDate }} </span>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Videos will be used :</span>
            <div v-for="(item, index) in videos" :key="index">
              {{ item.name }}
            </div>
          </div>
          <div class="pt-2">
            <span class="font-semibold">Ad(s) will be created :</span>
            <div v-for="(item, index) in videos" :key="index">
              {{ item.name.split(".")[0] }}
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleApprove">
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      visible: false,
      daily_budget: 5000,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    videos: {
      type: Array,
      default: () => [],
    },
    selected_gaia_game: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  computed: {
    computedCampaignName() {
      if (this.data && this.data.name) {
        return `${this.data.name} - US - iOS - Lowest - Soft Launch`;
      }
      return null;
    },
    computedAdsetName() {
      if (this.data && this.data.name && this.computedGameName) {
        return `${this.computedGameName}-US-iOS-Soft Launch`;
      }
      return null;
    },
    computedName() {
      if (this.data && this.data.name && this.computedGameName) {
        return `${this.computedGameName}-Bells`;
      }
      return null;
    },
    computedGameName() {
      if (this.data && this.data.name) {
        const name = this.data.name
          .replace(/[^a-zA-Z0-9 ]/gi, "")
          .replace(/ +(?= )/g, "");
        let splitted_game_name = name.split(" ");
        let game_name_with_letters = "";
        splitted_game_name.forEach((element) => {
          game_name_with_letters += element[0];
        });
        return game_name_with_letters.trim().toUpperCase();
      }
      return null;
    },
    computedStartDate() {
      let date = moment()
        .utcOffset("+0100")
        .add("1", "day")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      return date;
    },
    computedEndDate() {
      if (this.computedStartDate) {
        let end_date = moment(this.computedStartDate);
        end_date.add("6", "days");
        return end_date;
      }
      return null;
    },
  },
  methods: {
    handleApprove() {
      if (
        !this.data.object_store_urls.itunes ||
        !this.videos ||
        this.videos.length === 0
      ) {
        this.$notify({
          text: "Seçilen oyuna ait iTunes linki bulunmuyor.",
          group: "error",
        });
      } else {
        this.visible = false;
        const requestData = {
          campaign_name: this.computedCampaignName,
          adset_name: this.computedAdsetName,
          ad_name: this.computedName,
          game_name: this.data.name,
          selected_gaia_game: this.selected_gaia_game,
          object_store_url: this.data.object_store_urls.itunes,
          android_url:
            this.data.object_store_urls &&
            this.data.object_store_urls.google_play
              ? this.data.object_store_urls.google_play
              : "none",
          application_id: this.data.id,
          videos: this.videos,
        };
        this.$emit("approved", requestData);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
