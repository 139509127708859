<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between">
        <h1 class="font-semibold text-[18px] w-full">Experiments</h1>
        <div class="flex items-center gap-2">
          <v-select
            clearable
            flat
            label="Game"
            v-model="selectedGame"
            dense
            outlined
            class="text-[12px]"
            hide-details
            :items="games"
            item-text="title"
            item-value="applovin_android_package_name"
            style="width: 270px"
          >
          </v-select>

          <v-select
            clearable
            flat
            label="Choose Environment"
            v-model="selectedEnv"
            dense
            outlined
            class="text-[12px]"
            hide-details
            :items="environments"
            item-text="title"
            item-value="value"
            style="width: 270px"
          >
          </v-select>
        </div>
      </div>
      <div class="mt-3">
        <v-data-table
          :headers="headers"
          :items="experiments"
          :loading="loading"
          :items-per-page="20"
        >
          <template v-slot:[`item.id`]="{ item }">
            <span class="font-semibold text-[11px]">
              {{ item.id }}
            </span>
          </template>

          <template v-slot:[`item.title`]="{ item }">
            <div class="font-semibold text-[11px] flex items-center">
              {{ item.title }}

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="pl-1" v-bind="attrs" v-on="on">
                    <v-icon size="16">mdi-information</v-icon>
                  </span>
                </template>
                <span>{{ item.description }}</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.rollout`]="{ item }">
            <span class="font-semibold text-[11px]">
              {{ item.rollout }} %
            </span>
          </template>

          <template v-slot:[`item.platform`]="{ item }">
            <span class="font-semibold text-[11px] capitalize">
              {{ item.platform }}
            </span>
          </template>

          <template v-slot:[`item.start_date`]="{ item }">
            <span class="font-semibold text-[11px]">
              {{ item.start_date ? formattedDate(item.start_date) : "N/A" }}
            </span>
          </template>

          <template v-slot:[`item.end_date`]="{ item }">
            <span class="font-semibold text-[11px]">
              {{ formattedDate(item.end_date) }}
            </span>
          </template>

          <template v-slot:[`item.status_text`]="{ item }">
            <span
              class="font-semibold text-[11px] uppercase inline-block px-3 py-1 rounded-full"
              :class="{
                'bg-yellow-100 text-yellow-700':
                  getDynamicStatusText(
                    item.start_date,
                    item.end_date,
                    item.discard
                  ) === 'pending',
                'bg-green-100 text-green-700':
                  getDynamicStatusText(
                    item.start_date,
                    item.end_date,
                    item.discard
                  ) === 'active',
                'bg-rose-100 text-rose-700':
                  getDynamicStatusText(
                    item.start_date,
                    item.end_date,
                    item.discard
                  ) === 'discard',
                'bg-orange-100 text-orange-700':
                  getDynamicStatusText(
                    item.start_date,
                    item.end_date,
                    item.discard
                  ) === 'over',
              }"
            >
              {{
                getDynamicStatusText(
                  item.start_date,
                  item.end_date,
                  item.discard
                )
              }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="flex items-center">
              <v-btn
                color="secondary"
                text
                small
                class="text-[11px] font-semibold"
                @click="previewExperiment(item)"
              >
                <v-icon size="14">mdi-magnify</v-icon>
              </v-btn>
              <div v-if="selectedEnv === 'test'">
                <v-btn
                  v-if="!item.discard"
                  color="error"
                  text
                  small
                  class="text-[11px] font-semibold"
                  @click="
                    showConfirmationDialog(
                      'Selected experiment will be discarded. Are you sure?',
                      'discard',
                      item
                    )
                  "
                  :loading="item.loadingDiscardButton"
                >
                  <v-icon size="14">mdi-stop</v-icon>
                </v-btn>

                <v-btn
                  color="success"
                  text
                  small
                  class="text-[11px] font-semibold"
                  @click="
                    showConfirmationDialog(
                      'Selected experiment will be deployed to production. Are you sure?',
                      'publish',
                      item
                    )
                  "
                  :loading="item.loadingDeployButton"
                >
                  <v-icon size="14">mdi-rocket-launch-outline</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <PreviewExperimentDialog
      :show="showExperimentDetails"
      :experiment_id="selectedExperimentId"
      :env="selectedEnv"
      @closed="handleOnExperimentDialogPreviewClose"
      @success="handleOnExperimentUpdateSuccess"
      :events="events"
      :eventTypes="eventTypes"
      :games="games"
    />

    <ConfirmationDialog
      :show="showEventUpdateWarning"
      @closed="closeConfirmationDialog"
      :message="confirmationDialogMessage"
      @submit="handleOnConfirmationSubmit"
      :trigger="confirmationDialogTrigger"
      :title="'Warning!'"
    />
  </div>
</template>

<script>
import moment from "moment";
import PreviewExperimentDialog from "@/components/tools/game_backend/PreviewExperimentDialog.vue";
import ConfirmationDialog from "@/components/tools/game_backend/ConfirmationDialog.vue";

export default {
  data() {
    return {
      games: [],
      selectedGame: null,
      environments: [
        {
          title: "Test",
          value: "test",
        },
        {
          title: "Production",
          value: "prod",
        },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          sortable: true,
        },
        {
          text: "Start Date",
          value: "start_date",
          sortable: true,
        },
        {
          text: "End Date",
          value: "end_date",
          sortable: true,
        },
        {
          text: "Status",
          value: "status_text",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      experiments: [],
      loading: false,
      selectedEnv: null,
      showExperimentDetails: false,
      selectedExperimentId: null,
      events: [],
      eventTypes: [],
      confirmationDialogMessage: null,
      confirmationDialogTrigger: null,
      confirmationDialogData: null,
      showEventUpdateWarning: false,
    };
  },
  methods: {
    closeConfirmationDialog() {
      this.showEventUpdateWarning = false;
    },
    async handleOnConfirmationSubmit() {
      this.closeConfirmationDialog();

      if (this.confirmationDialogTrigger === "discard") {
        await this.discardExperiment(this.confirmationDialogData);
      } else if (this.confirmationDialogTrigger === "retain") {
        await this.retainExperiment(this.confirmationDialogData);
      } else if (this.confirmationDialogTrigger === "publish") {
        await this.deployTestToProd(this.confirmationDialogData);
      }

      this.confirmationDialogMessage = null;
      this.confirmationDialogTrigger = null;
      this.confirmationDialogData = null;
    },
    showConfirmationDialog(message, trigger, data) {
      this.confirmationDialogMessage = message;
      this.confirmationDialogTrigger = trigger;
      this.confirmationDialogData = data;
      this.showEventUpdateWarning = true;
    },
    async deployTestToProd(item) {
      if (!this.selectedGame) {
        this.$toast.error("You must select a game first.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }

      if (item.discard) {
        this.$toast.error("Discarded experiments can not be deployed.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }

      try {
        item.loadingDeployButton = true;

        const requestData = {
          experiment_id: item.id,
          package_name: this.selectedGame,
        };

        await this.$api.post(`game-backend/experiments/publish`, requestData);

        this.$toast.success("Experiment successfully published to PROD.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
      } catch (error) {
        this.errorHandler(error);
      } finally {
        item.loadingDeployButton = false;
      }
    },
    async fetchLiveEvents() {
      try {
        if (!this.selectedGame) return;
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/events/all?&package_name=${this.selectedGame}`
        );

        this.events = request.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchLiveEventTypes() {
      try {
        const request = await this.$api.get(
          `game-backend/live-event-types/all`
        );
        this.eventTypes = request.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    areDatesOverlapping(
      currentStart,
      currentEnd,
      experimentStart,
      experimentEnd
    ) {
      // Eğer bir tarih aralığı tamamen diğerinin öncesinde ya da sonrasındaysa ayrık olurlar
      return !(
        currentEnd.isBefore(experimentStart) ||
        experimentEnd.isBefore(currentStart)
      );
    },
    async retainExperiment(item) {
      if (!item.discard) {
        this.$toast.error(
          "Only experiments in status 'discard' can be retained.",
          {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          }
        );
        return;
      }

      const experiments = this.experiments.filter(
        (x) => x.package_name === this.selectedGame && x.id !== item.id
      );

      // Tarihleri çakışan başka deneyler var mı kontrol et.
      for (let i = 0; i < experiments.length; i++) {
        const experiment = experiments[i];

        // Discard olmuş bir deneyi yapmak istiyorsak ve deneyin başlangıç ve bitiş tarihi ileri tarihli ise ya da mevcut zaman diliminde ise, canlı durumdaki deneyleri kontrol etmek gerekiyor.
        const currentEventStartDate = moment(item.start_date);
        const currentEventEndDate = moment(item.end_date);
        const currentDate = moment();

        // Eğer Retain yapılmak istenen deney zaten bitmiş ise sorun yok.
        if (
          currentEventStartDate < currentDate &&
          currentEventEndDate < currentDate
        ) {
          break;
        }

        // Değilse
        else {
          // Eğer aynı zaman aralığında çakışan pending bir event varsa retain edilemez.;
          if (!experiment["discard"]) {
            const experimentStartDate = moment(experiment.start_date);
            const experimentEndDate = moment(experiment.end_date);

            if (
              this.areDatesOverlapping(
                currentEventStartDate,
                currentEventEndDate,
                experimentStartDate,
                experimentEndDate
              )
            ) {
              this.$toast.error(
                `You can not retain selected experiment. Experiment: ${experiment["title"]} start date and end date is conflicting.`,
                {
                  timeout: 10000,
                  position: "bottom-center",
                  icon: false,
                }
              );

              return;
            }
          }
        }
      }

      try {
        item.loadingRetainExperiment = true;
        await this.$api.post(
          `game-backend/experiments/retain?experiment_id=${
            item.id
          }&package_name=${
            item.package_name
          }&env=${this.selectedEnv.toLowerCase()}`
        );

        await this.fetchExperiments();

        this.$toast.success("Experiment successfully updated.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
      } catch (error) {
        this.errorHandler(error);
      } finally {
        item.loadingRetainExperiment = false;
      }
    },
    async handleOnExperimentUpdateSuccess() {
      this.showExperimentDetails = false;
      await this.fetchExperiments();
      this.$toast.success("Experiment successfully updated.", {
        timeout: 5000,
        position: "bottom-center",
        icon: false,
      });
    },
    async discardExperiment(item) {
      if (item.discard) {
        this.$toast.error("Selected experiment is already discarded.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      }

      try {
        item.loadingDiscardButton = true;
        await this.$api.post(
          `game-backend/experiments/discard?experiment_id=${
            item.id
          }&package_name=${
            item.package_name
          }&env=${this.selectedEnv.toLowerCase()}`
        );

        await this.fetchExperiments();

        this.$toast.success("Experiment successfully updated.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
      } catch (error) {
        this.errorHandler(error);
      } finally {
        item.loadingDiscardButton = false;
      }
    },
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data.filter(
            (x) => x.applovin_android_package_name
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDynamicStatusText(start_date, end_date, discard) {
      if (discard) {
        return "discard";
      }

      const start = moment(start_date);
      const end = moment(end_date);
      const current = moment();
      if (start <= current && current <= end) {
        return "active";
      } else if (current < start && current < end) {
        return "pending";
      } else if (current > start && current >= end) {
        return "over";
      }

      return "-";
    },
    handleOnExperimentDialogPreviewClose() {
      this.selectedExperimentId = null;
      this.showExperimentDetails = false;
    },
    previewExperiment(data) {
      this.selectedExperimentId = data.id;
      this.showExperimentDetails = true;
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchExperiments() {
      try {
        if (!this.selectedEnv) return;
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/experiments/all?env=${this.selectedEnv}`
        );
        this.experiments = request.data
          .map((x) => ({
            ...x,
            number_of_users: null,
            loadingDiscardButton: false,
            loadingRetainExperiment: false,
            loadingDeployButton: false,
          }))
          .filter((x) => x.package_name === this.selectedGame);

        this.experiments = this.$sortArrayByProperty(
          this.experiments,
          ["created_at"],
          ["desc"]
        );
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    formattedDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  watch: {
    async selectedGame(newValue) {
      if (newValue && this.selectedEnv) {
        await this.fetchExperiments();
        if (this.events.length === 0) {
          await this.fetchLiveEvents();
        }
      }
    },
    async selectedEnv(newValue) {
      if (newValue && this.selectedGame) {
        await this.fetchExperiments();
        if (this.events.length === 0) {
          await this.fetchLiveEvents();
        }
      }
    },
  },
  async mounted() {
    this.fetchGames();
    await this.fetchLiveEventTypes();
  },
  components: {
    PreviewExperimentDialog,
    ConfirmationDialog,
  },
};
</script>

<style lang="scss" scoped></style>
