<template>
  <div>
    <div class="p-6">
      <div>
        <div class="flex lg:flex-row flex-col gap-4">
          <div class="lg:w-3/12">
            <div class="bg-white p-4 rounded">
              <div
                @click="setSelectedMenu(item.id)"
                v-for="item in menu_list"
                :key="item.id"
                :class="selected_menu === item.id ? 'selected' : ''"
                class="menu-item"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="lg:w-9/12">
            <div class="bg-white p-4 rounded">
              <GamesConfig v-if="selected_menu === 0" />
              <DepartmentsConfig v-if="selected_menu === 1" />
              <SettingsConfig v-if="selected_menu === 2" />
              <UmaiNetworkConfig v-if="selected_menu === 3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentsConfig from "@/components/admin/config/DepartmentsConfig.vue";
import GamesConfig from "@/components/admin/config/GamesConfig.vue";
import SettingsConfig from "@/components/admin/config/SettingsConfig.vue";
import UmaiNetworkConfig from "@/components/admin/config/UmaiNetworkConfig.vue";

export default {
  data() {
    return {
      menu_list: [
        {
          name: "Games",
          id: 0,
        },
        {
          name: "Departments",
          id: 1,
        },
        {
          name: "Settings",
          id: 2,
        },
        {
          name: "Umai Networks",
          id: 3,
        },
      ],
      selected_menu: 0,
    };
  },
  methods: {
    setSelectedMenu(id) {
      this.selected_menu = id;
    },
  },
  components: {
    DepartmentsConfig,
    GamesConfig,
    SettingsConfig,
    UmaiNetworkConfig,
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  font-size: 12px;
  margin-bottom: 4px;
  padding: 12px;
  color: #757575;
  cursor: pointer;
  border-radius: 4px;
  &.selected {
    font-weight: 600;
    color: #424242;
    background-color: #eeeeee;
  }

  &:hover {
    background-color: #eeeeee;
    color: #424242;
  }
}
</style>
