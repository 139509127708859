<template>
  <div>
    <div class="bg-white p-6">
      <h1 class="font-semibold text-[18px] pb-6">Upload New Level</h1>

      <div>
        <div class="mt-3">
          <v-file-input
            show-size
            accept="application/JSON"
            label="Choose JSON"
            class="text-[13px]"
            outlined
            dense
            hide-details
            v-model="level_file"
          ></v-file-input>
        </div>
        <div class="mt-3 flex items-center justify-end">
          <v-btn
            color="primary"
            @click="uploadLevel"
            :disabled="!level_file || loading"
            :ripple="false"
          >
            Submit
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      level_file: null,
      loading: false,
    };
  },
  methods: {
    uploadLevel() {
      this.loading = true;
      let formData = new FormData();
      formData.append("level_json_file", this.level_file);

      this.$api
        .post("acg/dnc-levels/create", formData)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              type: "success_",
              group: "success_",
              text: "Successful.",
            });
            this.level_file = null;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((this.loading = false));
    },
  },
};
</script>
