<template>
  <div>
    <v-dialog v-model="visible" width="1400">
      <v-card>
        <v-card-title class="text-h5"> Create A/B Test </v-card-title>

        <v-card-text>
          <div v-if="currentPage === 1">
            <div class="mt-5">
              <div class="mb-3">
                <v-text-field
                  outlined
                  v-model="testTitle"
                  dense
                  hide-details
                  class="text-[13px]"
                  placeholder="Title (Min 5 chars)"
                ></v-text-field>
              </div>
              <v-file-input
                show-size
                accept=".csv"
                label="Upload CSV"
                class="text-[13px]"
                outlined
                dense
                hide-details
                :disabled="testTitle.length < 5"
                v-model="file"
              ></v-file-input>
            </div>

            <div class="mt-5" v-if="errorMessage">
              <div
                class="bg-rose-100 text-rose-700 font-semibold p-3 rounded-[3px] text-[11px]"
              >
                {{ errorMessage }}
              </div>
            </div>

            <div class="flex gap-3 mt-5" v-if="$isArr(groupB) && !errorMessage">
              <div class="w-full rounded-[3px] p-2">
                <div class="font-semibold text-center">Group A</div>
                <div class="mt-2">
                  <div
                    class="p-2 rounded-[3px] mt-1 bg-gray-50 text-[10px] hover:bg-gray-100 cursor-pointer text-gray-400 hover:text-gray-700"
                    :class="{
                      '!text-gray-700 !bg-gray-100': placement.showDetails,
                    }"
                    v-for="(placement, index) in computedPlacementData"
                    :key="index"
                    @click="placement.showDetails = !placement.showDetails"
                  >
                    <div class="flex items-center justify-between w-full">
                      <div class="font-semibold w-full flex flex-col">
                        <div class="flex items-center">
                          <span
                            v-if="placement.mode === 'add'"
                            class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-lime-100 text-lime-700"
                          >
                            ADD
                          </span>
                          <span
                            v-if="placement.mode === 'delete'"
                            class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-rose-100 text-rose-700"
                          >
                            REMOVE
                          </span>
                          <div
                            class="font-semibold text-[11px] flex items-center"
                          >
                            {{ mappedNetworkName(placement.network) }}
                            <span
                              class="w-[8px] h-[8px] rounded-full ml-1.5"
                              :class="{
                                'bg-lime-500': !placement.disabled,
                                'bg-rose-500': placement.disabled,
                              }"
                            ></span>
                          </div>
                        </div>

                        <span class="text-[8px] text-gray-800">
                          CPM:
                          {{
                            placement.cpm
                              ? parseFloat(placement.cpm).toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div class="w-full">
                        <span
                          class="font-semibold text-[9px] uppercase inline-block px-1.5 rounded-[3px] bg-blue-500 text-blue-50 mx-0.5 py-0 my-0 leading-[16px]"
                          v-for="(country, index) in placement.countries"
                          :key="index"
                          >{{ country }}</span
                        >
                        <span v-if="!placement.countries.length">
                          No countries
                        </span>
                        <span
                          class="ml-2 font-semibold"
                          v-if="placement.country_type"
                        >
                          ({{ placement.country_type }})
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="placement.showDetails"
                      class="grid grid-cols-2 gap-1 mt-3"
                    >
                      <div class="flex items-center">
                        <span class="text-gray-600">App ID: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.ad_network_app_id
                            ? placement.ad_network_app_id
                            : "-"
                        }}</span>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">App Key: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.ad_network_app_key
                            ? placement.ad_network_app_key
                            : "-"
                        }}</span>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">Placement ID: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.ad_network_ad_unit_id
                        }}</span>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">Countries: </span>
                        <div
                          class="ml-1"
                          v-if="
                            placement.countries && placement.countries.length
                          "
                        >
                          <span
                            class="font-semibold text-[9px] uppercase inline-block px-1.5 rounded-[3px] bg-blue-500 text-blue-50 mx-0.5 py-0 my-0 leading-[16px]"
                            v-for="(country, index) in placement.countries"
                            :key="index"
                            >{{ country }}</span
                          >
                        </div>

                        <div v-else class="ml-1">
                          <span class="ml-1 font-semibold text-gray-700"
                            >-</span
                          >
                        </div>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">Country Type: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.country_type ? placement.country_type : "-"
                        }}</span>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">CPM: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.cpm ? placement.cpm : "-"
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full rounded-[3px] p-2">
                <div class="font-semibold text-center">Group B</div>
                <div class="mt-2">
                  <div
                    class="p-2 rounded-[3px] mt-1 bg-gray-50 text-[10px] hover:bg-gray-100 cursor-pointer text-gray-400 hover:text-gray-700"
                    v-for="(placement, index) in computedGroupB"
                    :key="index"
                    @click="placement.showDetails = !placement.showDetails"
                  >
                    <div class="flex items-center justify-between w-full">
                      <div class="font-semibold w-full flex flex-col">
                        <div class="flex items-center">
                          <span
                            v-if="placement.mode === 'add'"
                            class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-lime-100 text-lime-700"
                          >
                            ADD
                          </span>
                          <span
                            v-if="placement.mode === 'delete'"
                            class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-rose-100 text-rose-700"
                          >
                            REMOVE
                          </span>
                          <span
                            v-if="
                              placement.mode === 'off' &&
                              !placement.originalData.disabled
                            "
                            class="mr-1.5 px-1.5 py-0 text-[9px] font-semibold bg-rose-100 text-rose-700"
                          >
                            OFF
                          </span>
                          <div
                            class="font-semibold text-[11px] flex items-center"
                          >
                            {{ mappedNetworkName(placement.network) }}
                            <span
                              class="w-[8px] h-[8px] rounded-full ml-1.5"
                              :class="{
                                'bg-lime-500': !placement.originalData.disabled,
                                'bg-rose-500': placement.originalData.disabled,
                              }"
                            ></span>
                          </div>
                        </div>

                        <span class="text-[8px] text-gray-800">
                          CPM:
                          {{
                            placement.cpm
                              ? parseFloat(placement.cpm).toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div class="w-full">
                        <span
                          class="font-semibold text-[9px] uppercase inline-block px-1.5 rounded-[3px] bg-blue-500 text-blue-50 mx-0.5 py-0 my-0 leading-[16px]"
                          v-for="(country, index) in placement.countries"
                          :key="index"
                          >{{ country }}</span
                        >
                        <span v-if="!placement.countries.length">
                          No countries
                        </span>
                        <span
                          class="ml-2 font-semibold"
                          v-if="placement.country_type"
                        >
                          ({{ placement.country_type }})
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="placement.showDetails"
                      class="grid grid-cols-2 gap-1 mt-3"
                    >
                      <div class="flex items-center">
                        <span class="text-gray-600">App ID: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.ad_network_app_id
                            ? placement.ad_network_app_id
                            : "-"
                        }}</span>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">App Key: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.ad_network_app_key
                            ? placement.ad_network_app_key
                            : "-"
                        }}</span>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">Placement ID: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.ad_network_ad_unit_id
                        }}</span>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">Countries: </span>
                        <div
                          class="ml-1"
                          v-if="
                            placement.countries && placement.countries.length
                          "
                        >
                          <span
                            class="font-semibold text-[9px] uppercase inline-block px-1.5 rounded-[3px] bg-blue-500 text-blue-50 mx-0.5 py-0 my-0 leading-[16px]"
                            v-for="(country, index) in placement.countries"
                            :key="index"
                            >{{ country }}</span
                          >
                        </div>

                        <div v-else class="ml-1">
                          <span class="ml-1 font-semibold text-gray-700"
                            >-</span
                          >
                        </div>
                      </div>
                      <div class="flex items-center">
                        <span class="text-gray-600">Country Type: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.country_type ? placement.country_type : "-"
                        }}</span>
                      </div>

                      <div class="flex items-center">
                        <span class="text-gray-600">CPM: </span>
                        <span class="ml-1 font-semibold text-gray-700">{{
                          placement.cpm ? placement.cpm : "-"
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="summary-section" v-if="currentPage === 2">
            <h1 class="font-semibold text-[13px] text-sky-800 mt-5">Summary</h1>
            <div
              class="summary-section"
              v-if="
                this.groupB &&
                (computedAddedPlacements.length ||
                  computedDeletedPlacements.length ||
                  computedDisabledPlacements.length ||
                  computedUpdatedPlacements.length)
              "
            >
              <div
                v-if="computedAddedPlacements.length"
                class="p-4 border rounded mt-3"
              >
                <div
                  class="font-semibold text-green-900 p-3 rounded-[3px] bg-green-100 text-[11px]"
                >
                  {{ computedAddedPlacements.length }} placements will be added.
                </div>
                <div class="mt-3">
                  <v-data-table
                    :items-per-page="-1"
                    :headers="summaryHeaders"
                    :items="computedAddedPlacements"
                    sort-by="network"
                    :hide-default-footer="true"
                    dense
                  >
                    <template v-slot:[`item.network`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ mappedNetworkName(item.network) }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_app_id`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_app_id ? item.ad_network_app_id : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_app_key`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_app_key
                            ? item.ad_network_app_key
                            : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_ad_unit_id
                            ? item.ad_network_ad_unit_id
                            : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.cpm`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ item.cpm ? item.cpm : "-" }}
                      </span>
                    </template>
                    <template v-slot:[`item.countries`]="{ item }">
                      <div v-if="item.countries.length">
                        <span
                          class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                          v-for="(country, index) in item.countries"
                          :key="index"
                        >
                          {{ country }}
                        </span>
                      </div>

                      <div v-else>
                        <span class="font-semibold text-[11px]"> - </span>
                      </div>
                    </template>
                    <template v-slot:[`item.country_type`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ item.country_type ? item.country_type : "-" }}
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </div>

              <div
                v-if="computedDeletedPlacements.length"
                class="p-4 border rounded mt-3"
              >
                <div
                  class="font-semibold text-rose-900 p-3 rounded-[3px] bg-rose-100 text-[11px]"
                >
                  {{ computedDeletedPlacements.length }} placements will be
                  deleted.
                </div>
                <div class="mt-3">
                  <v-data-table
                    :items-per-page="-1"
                    :headers="summaryHeaders"
                    :items="computedDeletedPlacements"
                    sort-by="network"
                    :hide-default-footer="true"
                    dense
                  >
                    <template v-slot:[`item.network`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ mappedNetworkName(item.network) }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_app_id`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_app_id ? item.ad_network_app_id : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_app_key`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_app_key
                            ? item.ad_network_app_key
                            : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_ad_unit_id
                            ? item.ad_network_ad_unit_id
                            : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.cpm`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ item.cpm ? item.cpm : "-" }}
                      </span>
                    </template>
                    <template v-slot:[`item.countries`]="{ item }">
                      <div v-if="item.countries.length">
                        <span
                          class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                          v-for="(country, index) in item.countries"
                          :key="index"
                        >
                          {{ country }}
                        </span>
                      </div>

                      <div v-else>
                        <span class="font-semibold text-[11px]"> - </span>
                      </div>
                    </template>
                    <template v-slot:[`item.country_type`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ item.country_type ? item.country_type : "-" }}
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </div>

              <div
                v-if="computedDisabledPlacements.length"
                class="p-4 border rounded mt-3"
              >
                <div
                  class="font-semibold text-yellow-900 p-3 rounded-[3px] bg-yellow-100 text-[11px]"
                >
                  {{ computedDisabledPlacements.length }} placements will be
                  disabled.
                </div>
                <div class="mt-3">
                  <v-data-table
                    :items-per-page="-1"
                    :headers="summaryHeaders"
                    :items="computedDisabledPlacements"
                    sort-by="network"
                    :hide-default-footer="true"
                    dense
                  >
                    <template v-slot:[`item.network`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ mappedNetworkName(item.network) }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_app_id`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_app_id ? item.ad_network_app_id : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_app_key`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_app_key
                            ? item.ad_network_app_key
                            : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_ad_unit_id
                            ? item.ad_network_ad_unit_id
                            : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.cpm`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ item.cpm ? item.cpm : "-" }}
                      </span>
                    </template>
                    <template v-slot:[`item.countries`]="{ item }">
                      <div v-if="item.countries.length">
                        <span
                          class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                          v-for="(country, index) in item.countries"
                          :key="index"
                        >
                          {{ country }}
                        </span>
                      </div>

                      <div v-else>
                        <span class="font-semibold text-[11px]"> - </span>
                      </div>
                    </template>
                    <template v-slot:[`item.country_type`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ item.country_type ? item.country_type : "-" }}
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </div>

              <div
                v-if="computedUpdatedPlacements.length"
                class="p-4 border rounded mt-3"
              >
                <div
                  class="font-semibold text-sky-900 p-3 rounded-[3px] bg-sky-100 text-[11px]"
                >
                  {{ computedUpdatedPlacements.length }} placements will be
                  updated.
                </div>
                <div class="mt-3">
                  <v-data-table
                    :items-per-page="-1"
                    :headers="summaryHeaders"
                    :items="computedUpdatedPlacements"
                    sort-by="network"
                    :hide-default-footer="true"
                    dense
                  >
                    <template v-slot:[`item.network`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ mappedNetworkName(item.network) }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_app_id`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_app_id ? item.ad_network_app_id : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_app_key`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_app_key
                            ? item.ad_network_app_key
                            : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.ad_network_ad_unit_id`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{
                          item.ad_network_ad_unit_id
                            ? item.ad_network_ad_unit_id
                            : "-"
                        }}
                      </span>
                    </template>
                    <template v-slot:[`item.cpm`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ item.cpm ? item.cpm : "-" }}
                      </span>
                    </template>
                    <template v-slot:[`item.countries`]="{ item }">
                      <div v-if="item.countries.length">
                        <span
                          class="font-semibold text-[10px] uppercase inline-block px-1.5 rounded-[3px] bg-yellow-100 text-yellow-700 mx-0.5"
                          v-for="(country, index) in item.countries"
                          :key="index"
                        >
                          {{ country }}
                        </span>
                      </div>

                      <div v-else>
                        <span class="font-semibold text-[11px]"> - </span>
                      </div>
                    </template>
                    <template v-slot:[`item.country_type`]="{ item }">
                      <span class="font-semibold text-[10px]">
                        {{ item.country_type ? item.country_type : "-" }}
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
            <div class="summary-section mt-5 text-center" v-else>
              There is no change in data.
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <div>
              <v-btn
                color="secondary"
                text
                :disabled="currentPage === 1"
                @click="currentPage -= 1"
              >
                Prev
              </v-btn>
            </div>

            <div v-if="currentPage === 1">
              <v-btn
                color="secondary"
                text
                :disabled="currentPage === 2 || !groupB || errorMessage"
                @click="currentPage += 1"
              >
                Next
              </v-btn>
            </div>

            <div v-if="currentPage === 2">
              <v-btn color="blue darken-1" text @click="submitTest">
                Submit
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      currentPage: 1,
      summaryHeaders: [
        { text: "Network", value: "network" },
        { text: "App ID", value: "ad_network_app_id" },
        { text: "App Key", value: "ad_network_app_key" },
        { text: "Ad Unit ID", value: "ad_network_ad_unit_id" },
        {
          text: "Optional Placement ID",
          value: "ad_network_optional_placement_id",
        },
        { text: "CPM", value: "cpm" },
        { text: "Countries", value: "countries", sortable: false },
        { text: "Country Type", value: "country_type" },
      ],
      visible: false,
      file: null,
      csvData: [],
      placementData: [],
      tempPlacementData: [],
      groupB: null,
      errorMessage: null,
      testTitle: "",
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    testName: {
      type: String,
      default: null,
    },
    networks: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.currentPage = 1;
        this.file = null;
        this.groupB = null;
      }
    },
    testName(newValue) {
      this.testTitle = newValue;
    },
    file(newValue) {
      if (!newValue) {
        this.errorMessage = null;
        this.groupB = null;
        this.csvData = [];
      } else {
        this.handleCsvSubmit(newValue);
      }
    },
    data: {
      deep: true,
      handler(newValue) {
        this.placementData = newValue.map((x) => ({
          ...x,
          showDetails: false,
        }));
        this.tempPlacementData = JSON.parse(JSON.stringify(this.placementData));
      },
    },
  },
  methods: {
    mappedNetworkName(network) {
      const idx = this.networks.findIndex((x) => x.value === network);
      if (idx > -1) {
        return this.networks[idx].label;
      }
      return network;
    },
    async handleCsvSubmit($event) {
      const csvData = await this.$csvFileToJson($event, ",");
      const structure = [
        "operation",
        "network",
        "ad_network_app_id",
        "ad_network_app_key",
        "ad_network_ad_unit_id",
        "ad_network_optional_placement_id",
        "cpm",
        "countries",
        "country_type",
      ];

      const isStructureVerified = this.$isArrayStructureVerified(
        csvData,
        structure
      );
      if (!isStructureVerified) {
        this.$toast.error("Yüklenen CSV dosyasının formatı uyumlu değil.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        this.showCsvUpload = false;
        return;
      } else {
        this.showCsvUpload = false;
        for (let i = 0; i < csvData.length; i++) {
          const element = csvData[i];
          if (
            ["add", "remove"].includes(element.operation.toLowerCase()) &&
            !element.ad_network_ad_unit_id
          ) {
            this.$toast.error(
              `Placement ID can not be empty on ${element.network} (${element.operation})`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );
            return;
          }
        }

        this.csvData = csvData.map((x) => ({
          ...x,
          showDetails: false,
          countries: x.countries ? x.countries.split(",") : [],
        }));

        const data = this.setGroupB(this.csvData);
        this.groupB = data;
      }
    },
    setGroupB(data) {
      if (!data.length) return [];
      else {
        var modifiedData = JSON.parse(JSON.stringify(this.tempPlacementData));

        for (let i = 0; i < data.length; i++) {
          var csvData = data[i];
          csvData.editable = false;
          csvData.mode = "";
          csvData.cpm = csvData.cpm ? parseFloat(csvData.cpm) : null;
          csvData.originalData = JSON.parse(JSON.stringify(csvData));

          const placementIndex = modifiedData.findIndex(
            (x) =>
              x.ad_network_ad_unit_id === csvData.ad_network_ad_unit_id &&
              x.network === csvData.network
          );

          if (csvData.operation.toLowerCase() === "add") {
            if (placementIndex > -1) {
              // Zaten böyle bir placement olduğu anlamına gelir.
              csvData.originalData = modifiedData[placementIndex].originalData;

              if (!csvData.originalData.ad_network_app_key) {
                csvData.originalData.ad_network_app_key = null;
              }

              if (!csvData.originalData.ad_network_app_id) {
                csvData.originalData.ad_network_app_id = null;
              }

              modifiedData[placementIndex] = {
                ...csvData,
                mode: "update",
              };
            } else {
              // Böyle bir placement olmadığı için modified data üzerine pushlanır.
              const temp = {
                ...csvData,
                mode: "add",
              };

              temp.originalData.mode = "add";

              modifiedData.push(temp);
            }
          }

          if (csvData.operation.toLowerCase() === "remove") {
            if (placementIndex > -1) {
              // Zaten böyle bir placement olduğu anlamına gelir.
              modifiedData[placementIndex].mode = "delete";
            } else {
              const err_msg = `Silmek istediğiniz placement, mevcut placementler arasında bulunamadı. \nNetwork: ${csvData.network} - Placement ID: ${csvData.ad_network_ad_unit_id}`;

              console.error(err_msg);
              this.$toast.error(err_msg, {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              });
              return;
            }
          }
        }

        // Off işlemi
        for (let i = 0; i < data.length; i++) {
          const csvData = data[i];
          if (csvData.operation.toLowerCase() === "off") {
            for (let y = 0; y < modifiedData.length; y++) {
              const element = modifiedData[y];
              if (
                csvData.network.toUpperCase() === element.network.toUpperCase()
              ) {
                modifiedData[y].disabled = true;
                modifiedData[y].mode = "off";
              }
            }
          }
        }

        return modifiedData;
      }
    },

    submitTest() {
      const tempCsv = this.csvData
        .filter((x) => x.operation && x.operation.toLowerCase() === "off")
        .map((x) => x.network);

      this.$emit(
        "submitted",
        this.placementData,
        this.groupB,
        tempCsv,
        this.testTitle
      );
    },
  },
  computed: {
    computedAddedPlacements() {
      return this.groupB
        ? this.groupB.filter(
            (x) =>
              x.mode === "add" ||
              (x.mode === "off" && x.originalData.mode === "add")
          )
        : [];
    },
    computedUpdatedPlacements() {
      const data = Array.from(this.groupB);
      var returnData = [];

      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        // Check differences
        if (!["delete"].includes(element.mode)) {
          if (
            element.ad_network_app_id !== element.originalData.ad_network_app_id
          )
            returnData.push(element);
          else if (
            element.ad_network_app_key !==
            element.originalData.ad_network_app_key
          )
            returnData.push(element);
          else if (element.country_type !== element.originalData.country_type)
            returnData.push(element);
          else if (element.cpm !== element.originalData.cpm)
            returnData.push(element);
          else if (
            element.ad_network_ad_unit_id !==
            element.originalData.ad_network_ad_unit_id
          )
            returnData.push(element);
          else if (
            !_.isEqual(
              element.countries.sort().map((x) => x.toLowerCase()),
              element.originalData.countries.sort().map((x) => x.toLowerCase())
            )
          )
            returnData.push(element);
        }
      }

      return returnData;
    },
    computedDeletedPlacements() {
      return this.groupB ? this.groupB.filter((x) => x.mode === "delete") : [];
    },
    computedDisabledPlacements() {
      return this.groupB
        ? this.groupB.filter(
            (x) => x.disabled && x.mode !== "delete" && !x.originalData.disabled
          )
        : [];
    },
    computedPlacementData() {
      return this.$sortArrayByProperty(this.placementData, ["cpm"], ["desc"]);
    },
    computedGroupB() {
      return this.$sortArrayByProperty(this.groupB, ["cpm"], ["desc"]);
    },
  },
};
</script>

<style lang="scss">
.summary-section {
  td {
    border-bottom: none !important;
  }
}
</style>
