<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">Taslakları Yönet</h1>
    <v-data-table
      :headers="table_headers"
      :items="templates"
      :items-per-page="25"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, -1],
      }"
      :loading="loading"
      loading-text="Please wait..."
      @click:row="handleOnRowClick"
    >
      <template v-slot:[`item.game_id`]="{ item }">
        <span
          class="px-2 py-1 text-[11px] font-semibold rounded bg-sky-700 text-white hover:bg-sky-600"
        >
          {{ item.game.title }}
        </span>
      </template>
    </v-data-table>

    <ParasutTemplateInfo
      @closed="handleModalClose"
      @deleted="handleTemplateDelete"
      @updated="handleTemplateUpdate"
      :show="show_detail"
      :row_data="clicked_row_data"
      :games="games"
      :customers="getParasutCustomers"
      :products="getParasutProducts"
      :categories="getParasutCategories"
      :tags="getParasutTags"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ParasutTemplateInfo from "@/components/tools/parasut/ParasutTemplateInfo.vue";

export default {
  components: {
    ParasutTemplateInfo,
  },
  data() {
    return {
      templates: [],
      table_headers: [
        { text: "ID", value: "id" },
        { text: "Oyun", value: "game_id" },
        { text: "Müşteri", value: "parasut_customer_title" },
        { text: "Ürün / Hizmet", value: "parasut_product_title" },
        { text: "Vergi", value: "parasut_tax" },
      ],
      loading: false,
      show_detail: false,
      games: [],
      clicked_row_data: null,
    };
  },
  methods: {
    ...mapActions([
      "setParasutCustomers",
      "setParasutProducts",
      "setParasutTags",
      "setParasutCategories",
    ]),
    fetchTemplates() {
      this.loading = true;
      this.$api
        .get("parasut/templates/all")
        .then((response) => {
          if (response.status === 200) {
            this.templates = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleOnRowClick(data) {
      this.clicked_row_data = data;
      this.show_detail = true;
    },
    fetchClients() {
      this.clients_loading = true;
      var url = "parasut/clients";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.customers = response.data;
            this.setParasutCustomers(this.customers);
          }
          this.clients_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.clients_loading = false;
        });
    },
    fetchGames() {
      this.loading = true;
      this.$api
        .get("games/all")
        .then((response) => {
          this.games = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    fetchProducts() {
      this.products_loading = true;
      var url = "parasut/products";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.parasut_products = response.data;
            this.setParasutProducts(this.parasut_products);
          }
          this.products_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.products_loading = false;
        });
    },
    createTemplate() {
      const data = {
        is_dispatch: this.is_dispatch,
        is_collected: this.is_collected,
        tags: this.selected_tags,
        categories: this.selected_categories,
        parasut_product: this.selected_parasut_product.id,
        parasut_product_title: this.selected_parasut_product.name,
        parasut_customer: this.selected_customer.id,
        parasut_customer_title: this.selected_customer.name,
        parasut_tax: this.selected_tax,
      };

      const requestData = {
        data: JSON.stringify(data),
        game_id: this.selected_game,
        parasut_product: this.selected_parasut_product.id,
        parasut_product_title: this.selected_parasut_product.name,
        parasut_customer: this.selected_customer.id,
        parasut_customer_title: this.selected_customer.name,
        parasut_tax: this.selected_tax,
      };

      this.$api
        .post("parasut/templates/create", requestData)
        .then((response) => {
          if (response.status === 200) {
            this.selected_game = null;
            this.selected_parasut_product = null;
            this.selected_tax = null;
            this.selected_customer = null;
            this.selected_tags = [];

            this.$notify({
              type: "success_",
              group: "success_",
              text: "Taslak başarıyla oluşturuldu.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshData() {
      this.fetchClients();
      this.fetchProducts();
    },
    fetchCategories() {
      this.categories_loading = true;
      var url = "parasut/categories";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.categories = response.data.filter(
              (x) => x.attributes.category_type === "SalesInvoice"
            );
            const ad_revenue_category = this.categories.filter(
              (x) => x.id === "6718541"
            );

            if (ad_revenue_category.length > 0) {
              this.selected_categories = ad_revenue_category[0].id;
            }

            this.setParasutCategories(this.categories);
          }
          this.categories_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.categories_loading = false;
        });
    },
    fetchTags() {
      this.tags_loading = true;
      var url = "parasut/tags";
      this.$api
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            this.tags = response.data;
            this.setParasutTags(this.tags);
          }
          this.tags_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.tags_loading = false;
        });
    },
    handleModalClose() {
      this.show_detail = false;
      this.clicked_row_data = null;
    },
    handleTemplateDelete() {
      this.show_detail = false;
      this.clicked_row_data = null;
      this.fetchTemplates();

      this.$notify({
        type: "success_",
        group: "success_",
        text: "Successfull",
      });
    },
    handleTemplateUpdate() {
      this.show_detail = false;
      this.clicked_row_data = null;
      this.fetchTemplates();

      this.$notify({
        type: "success_",
        group: "success_",
        text: "Successfull",
      });
    },
  },
  computed: {
    ...mapGetters([
      "getParasutCustomers",
      "getParasutProducts",
      "getParasutTags",
      "getParasutCategories",
    ]),
  },
  mounted() {
    this.fetchTemplates();
    this.fetchGames();
    if (!this.getParasutCustomers) {
      this.fetchClients();
    }
    if (!this.getParasutProducts) {
      this.fetchProducts();
    }
    if (!this.getParasutCategories) {
      this.fetchCategories();
    }
    if (!this.getParasutTags) {
      this.fetchTags();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .text-start {
  font-size: 11px !important;
  font-weight: 600;
}
</style>
