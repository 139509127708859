<template>
  <div class="bg-white menu-section py-3 pr-6" v-if="getUserProfile">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <div
          class="p-2 rounded-full hover:bg-gray-200 cursor-pointer text-gray-600 mr-4 lg:hidden"
          @click="$emit('menu_clicked')"
        >
          <v-icon color="black">mdi-menu</v-icon>
        </div>
        <!-- <div>
          <v-text-field
            placeholder="Ara..."
            filled
            rounded
            hide-details
            append-icon="mdi-magnify"
            dense
            class="text-[13px]"
          ></v-text-field>
        </div> -->
      </div>
      <div class="flex items-center justify-end">
        <div class="sm:block hidden">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on" color="primary" size="36">
                <span class="text-white font-semibold">
                  {{
                    computedProfileName
                      .split(" ")
                      .map((x) => x[0])
                      .join("")
                  }}
                </span>
              </v-avatar>
            </template>
            <v-list dense>
              <v-list-item link>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "CustomMenu",
  data() {
    return {
      active: false,
    };
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
    computedProfileName() {
      if (this.getUserProfile) {
        return `${this.getUserProfile.first_name} ${this.getUserProfile.last_name}`;
      }
      return null;
    },
  },
  methods: {
    logout() {
      if (Cookies.get("gaia_sid")) {
        Cookies.remove("gaia_sid");

        const web_url =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8080"
            : "https://gaia.apps.com.tr";

        window.location = web_url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-section {
  box-shadow: 2px 0 6px rgb(0 21 41 / 45%);
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding-left: 280px;
  @media screen and (max-width: 1024px) {
    padding-left: 16px;
  }
}
</style>
