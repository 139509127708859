var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Summary ")]),_c('v-card-text',[_c('div',[_c('div',[_c('h1',{staticClass:"mt-3 text-gray-800 text-[12px] font-semibold"},[_vm._v(" Selected creatives will be "),_c('span',{staticClass:"text-lime-600"},[_vm._v("added")]),_vm._v(" to these offers: ")]),_c('div',{staticClass:"mt-2"},_vm._l((_vm.data),function(item){return _c('div',{key:item.id},[(
                    item.selectedCreativesForAdding.filter((x) => x.selected)
                      .length
                  )?_c('div',{staticClass:"border rounded-[3px] border-gray-200 p-2.5 mb-2"},[_c('div',{staticClass:"font-semibold text-[11px] text-sky-600 mb-2.5"},[_vm._v(" "+_vm._s(item.campaign_name)+" (Campaign) -> "+_vm._s(item.offer_name)+" (Offer) ")]),_vm._l((item.selectedCreativesForAdding.filter(
                      (x) => x.selected
                    )),function(ad,adIdx){return _c('v-chip',{key:adIdx,staticClass:"mr-1.5",attrs:{"small":"","color":"default"}},[_c('span',{staticClass:"text-[11px] font-semibold"},[_vm._v(" "+_vm._s(ad.is_playable && ad.selected_playable ? `${ad.ad_name} (Video + Playable)` : ad.ad_name)+" ")])])})],2):_vm._e()])}),0),_c('h1',{staticClass:"mt-3 text-gray-800 text-[12px] font-semibold"},[_vm._v(" Selected videos will be "),_c('span',{staticClass:"text-rose-600"},[_vm._v("deleted")]),_vm._v(" from these offers: ")]),_c('div',{staticClass:"mt-2"},_vm._l((_vm.data),function(item){return _c('div',{key:item.id},[(
                    item.creativesModified.videos.filter((x) => !x.selected)
                      .length
                  )?_c('div',{staticClass:"border rounded-[3px] border-gray-200 p-2.5 mb-2"},[_c('div',{staticClass:"font-semibold text-[11px] text-sky-600 mb-2.5"},[_vm._v(" "+_vm._s(item.campaign_name)+" (Campaign) -> "+_vm._s(item.offer_name)+" (Offer) ")]),_vm._l((item.creativesModified.videos.filter(
                      (x) => !x.selected
                    )),function(video,videoIdx){return _c('v-chip',{key:videoIdx,staticClass:"mr-1.5",attrs:{"small":"","color":"default"}},[_c('span',{staticClass:"text-[11px] font-semibold"},[_vm._v(" "+_vm._s(video.creative_name)+" ")])])})],2):_vm._e()])}),0),_c('h1',{staticClass:"mt-3 text-gray-800 text-[12px] font-semibold"},[_vm._v(" Selected playables will be "),_c('span',{staticClass:"text-rose-600"},[_vm._v("deleted")]),_vm._v(" from these offers: ")]),_c('div',{staticClass:"mt-2"},_vm._l((_vm.data),function(item){return _c('div',{key:item.id},[(
                    item.creativesModified.playables.filter(
                      (x) => !x.selected
                    ).length
                  )?_c('div',{staticClass:"border rounded-[3px] border-gray-200 p-2.5 mb-2"},[_c('div',{staticClass:"font-semibold text-[11px] text-sky-600 mb-2.5"},[_vm._v(" "+_vm._s(item.campaign_name)+" (Campaign) -> "+_vm._s(item.offer_name)+" (Offer) ")]),_vm._l((item.creativesModified.playables.filter(
                      (x) => !x.selected
                    )),function(playable,playableIdx){return _c('v-chip',{key:playableIdx,staticClass:"mr-1.5",attrs:{"small":"","color":"default"}},[_c('span',{staticClass:"text-[11px] font-semibold"},[_vm._v(" "+_vm._s(playable.creative_name)+" ")])])})],2):_vm._e()])}),0)])])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.submitSummaryForTasks}},[_vm._v(" Confirm ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }