<template>
  <div>
    <!-- <div class="mb-2 pb-2 px-4">
      <v-autocomplete
        hide-details
        class="text-[13px]"
        item-text="title"
        item-value="id"
        label="Choose a level"
        :loading="loading_levels || loading_level_data"
        outlined
        clearable
        dense
        :items="levels"
        v-model="selected_level"
        hide-no-data
      >
      </v-autocomplete>
    </div> -->
    <div class="apply-button px-3 pt-3 pb-3">
      <v-btn
        :ripple="false"
        color="primary"
        class="w-full"
        @click="createGameplay"
        :loading="loadingPlayRecord"
        :disabled="loadingPlayRecord"
      >
        Create
      </v-btn>
    </div>
    <div class="px-3 pt-0 pb-3 w-full">
      <div class="flex items-center justify-center mt-2 gap-1">
        <div class="w-full">
          <v-btn
            :disabled="!signedURL"
            color="primary"
            :ripple="false"
            text
            class="w-full"
            @click="previewGameplay"
          >
            <v-icon dark left> mdi-download </v-icon>
            Download
          </v-btn>
        </div>
        <div class="w-full">
          <v-btn
            :disabled="!signedURL"
            color="success"
            :ripple="false"
            text
            class="w-full"
            @click="$emit('save')"
          >
            <v-icon dark left> mdi-content-save-outline </v-icon>
            Save
          </v-btn>
        </div>
      </div>
    </div>
    <v-divider class="mb-3"></v-divider>

    <h1
      class="p-4 text-[12px] font-semibold text-gray-700 text-sky-700 mb-2 hover:bg-gray-100 cursor-pointer select-none"
      @click="showParameters = !showParameters"
    >
      {{ !showParameters ? "Show" : "Hide" }} Parameters
    </h1>

    <div v-if="showParameters">
      <div
        v-for="(item, index) in Object.keys(parameters)"
        :key="index"
        class="mb-2 py-2 px-4"
      >
        <component
          v-bind:is="display"
          v-bind="componentProperties(item)"
          @changed="handleChangeParameters"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicComponent from "./DC_DynamicComponent.vue";

export default {
  data() {
    return {
      preview_mode: true,
      levels: [],
      loading_levels: false,
      selected_level: false,
      level_data: null,
      loading_level_data: false,
      showParameters: false,
    };
  },
  watch: {
    preview_mode(newValue) {
      this.$emit("previewModeChanged", newValue);
    },
    selected_level(newValue) {
      this.level_data = null;
      if (newValue) {
        this.fetchLevelData();
      }
    },
    level_data(newValue) {
      console.log(newValue);
      this.$emit("levelChanged", newValue);
    },
  },
  props: {
    parameters: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canCreateGameplay: {
      type: Boolean,
      default: false,
    },
    loadingPlayRecord: {
      type: Boolean,
      default: false,
    },
    signedURL: {
      type: String,
      default: null,
    },
  },
  methods: {
    previewGameplay() {
      if (this.signedURL) {
        try {
          window.open(this.signedURL, "_blank");
        } catch (error) {
          console.error(error);
        }
      }
    },
    fetchLevelData() {
      this.loading_level_data = true;
      this.$api
        .get(`acg/dnc-levels/detail/${this.selected_level}`)
        .then((response) => {
          this.level_data = response.data;
        })
        .finally(() => {
          this.loading_level_data = false;
        });
    },
    fetchLevels() {
      this.loading_levels = true;
      this.$api
        .get("acg/dnc-levels/all")
        .then((response) => {
          this.levels = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading_levels = false;
        });
    },
    resetParameters() {
      this.$emit("resetParameters");
      this.selected_level = null;
    },
    applyParameters() {
      this.$emit("applyParameters");
    },
    createGameplay() {
      this.$emit("createGameplay");
    },
    componentProperties(key) {
      return {
        type: this.parameters[key].variableType,
        parameterLabel: key,
        defaultValue: this.parameters[key].variableValue,
        availableValues: this.parameters[key].variablePossibilities,
        maxLength: this.parameters[key].maxLength
          ? this.parameters[key].maxLength
          : null,
        valueStructure: this.parameters[key].valueStructures
          ? this.parameters[key].valueStructures
          : null,
      };
    },
    handleChangeParameters(item) {
      this.$emit("parametersChanged", item);
    },
  },
  computed: {
    display() {
      return DynamicComponent;
    },
  },
  mounted() {
    this.fetchLevels();
  },
};
</script>

<style lang="scss" scoped></style>
