<template>
  <div>
    <div class="border rounded">
      <v-expansion-panels
        accordion
        focusable
        flat
        v-model="selectedCreativePackPanel"
        v-if="!loading"
      >
        <v-expansion-panel
          v-for="(creativePack, index) in creativePackData"
          :key="index"
        >
          <v-expansion-panel-header>
            <div class="flex items-center">
              <span class="font-semibold text-[11px] text-blue-900">
                {{ creativePack.campaign_name }}
              </span>
              <!-- <span class="mx-2 font-semibold text-[10px]"> > </span>
              <span class="font-semibold text-[11px] text-rose-900">
                {{ creativePack.name }}
              </span> -->
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- <div
                class="rounded bg-sky-50 text-sky-800 font-semibold p-5 text-[11px] mt-5 border border-sky-200"
              >
                {{
                  creativePack.selectedCreativesForAdding.filter((x) => x.selected).length
                }}
                creative(s) will be added and
                {{ creativePack.selectedAdsForDeleting.length }} creative(s) will be
                deleted.
              </div> -->
            <h1
              class="mt-4 mb-0 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
            >
              <span> 1. Select creatives to add </span>
            </h1>
            <div class="pt-3">
              <div class="w-full mb-5">
                <div class="grid grid-cols-4 gap-3">
                  <div
                    v-for="ad in creativePack.selectedCreativesForAdding"
                    :key="ad.ad_id"
                    class="rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100"
                    :class="{
                      selected: ad.selected,
                    }"
                    @click="ad.selected = !ad.selected"
                  >
                    <div class="flex items-center justify-between mb-2">
                      <div class="flex items-center">
                        <span
                          class="text-[11px]"
                          :class="
                            ad.selected
                              ? 'text-gray-700 font-semibold'
                              : 'text-gray-400'
                          "
                        >
                          {{ ad.ad_name }}
                        </span>
                        <span
                          class="text-[10px] ml-2"
                          v-if="ad.improvement"
                          :class="{
                            'font-semibold': ad.selected,
                            'text-gray-400': !ad.selected,
                            'text-lime-700': ad.improvement >= 0 && ad.selected,
                            'text-rose-700': ad.improvement < 0 && ad.selected,
                          }"
                        >
                          {{ ad.improvement.toFixed(2) }}%
                        </span>
                      </div>
                      <span
                        class="text-[9px] uppercase font-semibold p-1 rounded-[3px] hover:bg-gray-300 cursor-pointer select-none"
                        @click="
                          handlePreviewBtn(
                            $event,
                            ad.gaia_id,
                            ad.selected_playable_id
                          )
                        "
                        :class="
                          ad.selected
                            ? 'text-gray-700'
                            : 'text-gray-400 pointer-events-none'
                        "
                      >
                        Preview
                      </span>
                    </div>

                    <div class="mt-2" @click.stop>
                      <v-radio-group
                        style="font-size: 12px"
                        v-model="ad.creative_type"
                        row
                        dense
                        hide-details
                      >
                        <v-radio
                          label="Playable Only"
                          value="playable-only"
                        ></v-radio>
                        <v-radio
                          label="Video & Int. End Card"
                          value="video-playable"
                        ></v-radio>
                      </v-radio-group>
                    </div>

                    <!-- BURADA PLAYABLE HER KOŞULDA ZORUNLU! -->
                    <div class="mt-2" @click.stop>
                      <v-autocomplete
                        clearable
                        label="Choose an asset"
                        outlined
                        auto-select-first
                        dense
                        class="text-[12px]"
                        v-model="ad.selected_playable_id"
                        :items="assets"
                        :loading="assetsLoading"
                        hide-details
                        item-text="name"
                        item-value="id"
                      >
                      </v-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider class="my-5"></v-divider>
              <h1
                class="mb-2 font-semibold text-[13px] capitalize text-gray-900 flex items-center"
              >
                <span>2. Select Creatives to Replace </span>
              </h1>
              <!-- 
              <h1 class="text-gray-800 font-semibold text-[11px] mb-3">
                Average IPM: {{ computedAverageIpm(adgroup) }}
              </h1>
              <h1 class="text-gray-800 font-semibold text-[11px] mb-3">
                Average Impressions: {{ computedAverageImpression(adgroup) }}
              </h1> -->

              <div>
                <v-data-table
                  :headers="creativeHeaders"
                  :items="creativePack.creatives"
                  :items-per-page="-1"
                  v-model="creativePack.selectedCreativePacksForDeleting"
                  :single-select="false"
                  show-select
                  item-key="id"
                >
                  <template v-slot:[`item.id`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{ item.id }}
                    </span>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <span class="text-gray-800 text-[11px] font-semibold">
                      {{ item.name }}
                    </span>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div v-else>
        <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
      </div>
    </div>

    <!-- <div class="mt-3">
        <h1 class="text-gray-700 font-bold">Summary</h1>
        <div>
          <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
            Selected creatives will be
            <span class="text-lime-600">added</span> into these adsets:
          </h1>
          <div class="mt-2">
            <div
              v-for="(data, idx) in computedImportedDataForSummary"
              :key="idx"
              class="mb-1.5"
            >
              <div class="font-semibold text-[11px] flex items-center">
                <span class="text-sky-600">
                  {{ data.creative_name }}
                </span>
                <span class="ml-2 mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-narrow-right"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    stroke-width="1.75"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l14 0"></path>
                    <path d="M15 16l4 -4"></path>
                    <path d="M15 8l4 4"></path>
                  </svg>
                </span>
                <span
                  v-for="adgroup in data.adgroup_data"
                  :key="adgroup.adgroup_id"
                  class="text-gray-700 p-1 rounded-[3px] bg-gray-100 ml-1 hover:bg-gray-200"
                >
                  {{ adgroup.adgroup_name }}
                </span>
              </div>
            </div>
          </div>
  
          <h1 class="mt-3 text-gray-800 text-[12px] font-semibold">
            These creatives will be completely
            <span class="text-rose-600">deleted</span> from these adsets:
          </h1>
  
          <div class="mt-2">
            <div v-for="adgroup in adgroupData" :key="adgroup.id">
              <div v-if="adgroup.selectedAdsForDeleting.length">
                <div
                  class="font-semibold text-[11px] flex items-center mb-1.5"
                  v-for="(ad, adIdx) in adgroup.selectedAdsForDeleting"
                  :key="adIdx"
                >
                  <span class="text-sky-600">
                    {{ ad.name }}
                  </span>
                  <span class="ml-2 mr-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-arrow-narrow-right"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      stroke-width="1.75"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l14 0"></path>
                      <path d="M15 16l4 -4"></path>
                      <path d="M15 8l4 4"></path>
                    </svg>
                  </span>
                  <span
                    class="text-gray-700 p-1 rounded-[3px] bg-gray-100 ml-1 hover:bg-gray-200"
                  >
                    {{ adgroup.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <div class="flex justify-end items-center my-3">
      <v-btn
        color="primary"
        depressed
        class="font-semibold"
        @click="showSummary = true"
        :disabled="!computedRequestData"
      >
        Replace Creatives
      </v-btn>
    </div>

    <SummaryDialog
      :requestData="computedRequestData"
      :selectedCreatives="selectedCreatives"
      :show="showSummary"
      @closed="showSummary = false"
      @submitted="replaceCreatives"
    />
  </div>
</template>

<script>
import SummaryDialog from "./SummaryDialog.vue";

export default {
  props: {
    selectedCampaigns: {
      type: Array,
      default: () => [],
    },
    selectedCreatives: {
      type: Array,
      default: () => [],
    },
    game_id: {
      type: Number,
      default: null,
    },
    platform: {
      type: String,
      default: null,
    },
  },
  watch: {
    selectedCampaigns: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.length && this.selectedCreatives.length) {
          console.log("hello");
        }
      },
    },
  },
  data() {
    return {
      showSummary: false,
      loading: false,
      selectedCreativePackPanel: null,
      creativePackData: [],
      creativeHeaders: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
      ],
      assets: [],
      assetsLoading: false,
      createLoading: false,
    };
  },
  methods: {
    async fetchAssets() {
      try {
        this.assetsLoading = true;
        const response = await this.$api.get(
          `campaign-manager/assets/ironsource?game_id=${this.game_id}&platform=${this.platform}&type=html`
        );
        this.assets = response.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.assetsLoading = false;
      }
    },
    async replaceCreatives() {
      const requestData = this.computedRequestData;
      if (!requestData) return;

      try {
        this.createLoading = true;
        const response = await this.$api.post(
          "burcha/ironsource/tasks/create",
          requestData
        );
        if (response.status === 200) {
          this.$toast.success("Tasks have been successfully created...", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          this.showSummary = false;
          this.$router.push({ name: "home" });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.createLoading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    async fetchCampaignCreatives() {
      try {
        this.loading = true;
        const campaign_ids = this.selectedCampaigns
          .map((x) => x.campaign_id)
          .join(",");
        const response = await this.$api.get(
          `campaign-manager/creative-packs/ironsource?campaign_ids=${campaign_ids}`
        );

        this.creativePackData = JSON.parse(
          JSON.stringify(this.selectedCampaigns)
        );

        for (let i = 0; i < this.creativePackData.length; i++) {
          const campaign = this.creativePackData[i];
          this.$set(this.creativePackData[i], "creatives", []);
          this.$set(
            this.creativePackData[i],
            "selectedCreativesForAdding",
            this.selectedCreatives.map((x) => ({
              ...x,
              selected: true,
              creative_type: "playable-only",
              selected_playable_id: null,
            }))
          );
          this.$set(
            this.creativePackData[i],
            "selectedCreativePacksForDeleting",
            []
          );

          for (let y = 0; y < response.data.length; y++) {
            const creative = response.data[y];

            if (creative.campaign_id === campaign.campaign_id) {
              this.creativePackData[i].creatives.push(creative);
            }
          }
        }

        // for (let i = 0; i < this.creativePackData.length; i++) {
        //   const campaign_data = this.selectedCampaigns.find(
        //     (x) => x.campaign_id === this.creativePackData[i].campaign_id
        //   );
        //   if (campaign_data) {
        //     this.creativePackData[i].campaign_name =
        //       campaign_data.campaign_name;
        //   }
        // }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    computedRequestData() {
      if (this.creativePackData && this.creativePackData.length) {
        var data = [];

        for (let i = 0; i < this.creativePackData.length; i++) {
          var requestData = {
            game_id: this.game_id,
            platform: this.platform,
            campaign_id: null,
            campaign_name: null,
            deleted_creatives: [],
            inserted_creatives: [],
          };

          const creativePack = this.creativePackData[i];
          if (!creativePack.selectedCreativesForAdding.length) return null;

          for (
            let j = 0;
            j < creativePack.selectedCreativesForAdding.length;
            j++
          ) {
            requestData.campaign_id = creativePack.campaign_id;
            requestData.campaign_name = creativePack.campaign_name;

            const selectedCreativeForAdding =
              creativePack.selectedCreativesForAdding[j];
            if (!selectedCreativeForAdding.selected) continue;
            if (!selectedCreativeForAdding.selected_playable_id) return null;

            var tempInsert = {
              gaia_id: selectedCreativeForAdding.gaia_id,
              creative_name: selectedCreativeForAdding.ad_name,
              playable_id: selectedCreativeForAdding.selected_playable_id,
              type: selectedCreativeForAdding.creative_type,
            };

            requestData.inserted_creatives.push(tempInsert);
          }

          for (
            let j = 0;
            j < creativePack.selectedCreativePacksForDeleting.length;
            j++
          ) {
            const selectedCreativePackForDeleting =
              creativePack.selectedCreativePacksForDeleting[j];

            var tempDelete = {
              ironsource_id: selectedCreativePackForDeleting.id,
              creative_name: selectedCreativePackForDeleting.name,
            };

            requestData.deleted_creatives.push(tempDelete);
          }

          requestData.inserted_creatives = JSON.stringify(
            requestData.inserted_creatives
          );
          requestData.deleted_creatives = JSON.stringify(
            requestData.deleted_creatives
          );

          data.push(requestData);
        }

        return data;
      }
      return null;
    },
  },
  async mounted() {
    this.fetchCampaignCreatives();
    this.fetchAssets();
  },
  components: {
    SummaryDialog,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-footer {
  border-top: none !important;
}
::v-deep table {
  border: 1px solid rgba($color: #000000, $alpha: 0.15);
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.selectedAdSection {
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 2px 4px;
}

.selectedAdSection.selected {
  box-shadow: rgba(63, 216, 16, 0.4) 0px 0px 0px 1px,
    rgba(22, 93, 0, 0.1) 0px 2px 4px;
}

::v-deep .v-label {
  font-size: 10px !important;
  font-weight: 600;
}
</style>
