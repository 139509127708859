<template>
  <div class="bg-white p-6">
    <h1 class="font-semibold text-[18px] pb-6">Enter Test Details</h1>

    <div class="mt-5">
      <div>
        <v-select
          hide-details
          class="text-[13px]"
          item-text="title"
          return-object
          label="Choose a game"
          :loading="loading"
          :disabled="loading"
          outlined
          dense
          :items="game_list"
          v-model="selected_game"
        >
        </v-select>
      </div>
      <div v-if="selected_game">
        <v-divider class="my-5"></v-divider>
        <div>
          <v-text-field
            label="Title"
            outlined
            v-model="title"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>

        <div class="mt-3">
          <v-text-field
            label="Experiment ID"
            outlined
            v-model="experiment_id"
            dense
            hide-details
            class="text-[13px]"
          ></v-text-field>
        </div>

        <div class="mt-3">
          <v-textarea
            label="Long Description"
            v-model="description"
            dense
            outlined
            auto-grow
            :rows="3"
            hide-details
            class="text-[13px]"
          ></v-textarea>
        </div>

        <div class="mt-3">
          <v-menu
            v-model="start_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="text-[13px]"
                v-model="start_at"
                label="Start At"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="start_at"
              @input="start_date_menu = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="mt-3">
          <v-menu
            v-model="end_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="text-[13px]"
                v-model="finish_at"
                label="Finish At"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="finish_at"
              @input="end_date_menu = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div class="mt-3">
          <v-combobox
            clearable
            dense
            multiple
            outlined
            hide-details
            small-chips
            hide-selected
            v-model="variants"
            label="Variants"
            class="text-[13px]"
            deletable-chips
          ></v-combobox>
        </div>

        <div class="flex items-center justify-end mt-5">
          <v-btn
            color="primary"
            :ripple="false"
            depressed
            :loading="loading"
            :disabled="
              !title ||
              !experiment_id ||
              !start_at ||
              !finish_at ||
              !variants.length ||
              !selected_game ||
              !description
            "
            @click="submitTestSummary"
          >
            Submit
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      title: "",
      experiment_id: "",
      test_dates: [],
      variants: [],
      description: "",
      game_list: [],
      selected_game: null,
      loading: false,
      start_date_menu: false,
      end_date_menu: false,
      start_at: "",
      finish_at: "",
    };
  },
  methods: {
    fetchGameList() {
      this.$api
        .get("/games/all")
        .then((response) => {
          this.game_list = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetForm() {
      this.title = "";
      this.experiment_id = "";
      this.test_dates = [];
      this.variants = [];
      this.description = "";
      this.selected_game = null;
      this.start_date_menu = false;
      this.end_date_menu = false;
      (this.start_at = ""), (this.finish_at = "");
    },

    async submitTestSummary() {
      console.log(this.$dateToUnix(this.finish_at));
      try {
        this.loading = true;
        const requestData = {
          title: this.title,
          experiment_id: this.experiment_id,
          variants: JSON.stringify(this.variants),
          description: this.description,
          game_id: this.selected_game.id,
          start_at: moment(this.start_at)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .utc(true)
            .toISOString(),
          finish_at: moment(this.finish_at)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 0,
            })
            .utc(true)
            .toISOString(),
        };

        const response = await this.$api.post("ab-tests/create", requestData);
        if (response.status === 200) {
          this.$notify({
            type: "success_",
            group: "success_",
            text: "Summary successfully created.",
          });
          this.resetForm();
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  mounted() {
    this.fetchGameList();
  },
};
</script>

<style lang="scss" scoped></style>
