<template>
  <div>
    <v-dialog v-model="visible" width="1200">
      <v-card>
        <v-card-title> Settings </v-card-title>
        <v-card-text v-if="data">
          <div class="">
            <v-expansion-panels v-model="selectedPanels" :multiple="false" flat>
              <!-- <v-expansion-panel>
                <v-expansion-panel-header
                  ><span class="font-semibold text-gray-800 text-[11px]"
                    >Campaign Settings</span
                  ></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div>
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign ID
                    </div>
                    <div class="mt-2">
                      <v-text-field
                        label="Campaign ID"
                        outlined
                        disabled
                        v-model="campaignSettingsData.id"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign Title
                    </div>
                    <div class="mt-2">
                      <v-text-field
                        label="Title"
                        outlined
                        v-model="campaignSettingsData.name"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="text-gray-700 font-semibold text-[11px]">
                      Campaign Status
                    </div>
                    <div>
                      <v-switch
                        v-model="campaignSettingsData.status"
                        :label="`${campaignSettingsData.status.toUpperCase()}`"
                        class="text-[13px]"
                        hide-details
                        true-value="Active"
                        false-value="Paused"
                        style="margin-top: 4px"
                      ></v-switch>
                    </div>
                  </div>

                  <div class="flex items-center justify-end w-full mt-3">
                    <v-btn
                      color="primary"
                      text
                      @click="updateCampaignInformation"
                      :loading="loadingCampaignData"
                    >
                      Save
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel> -->

              <v-expansion-panel
                v-for="(offer, offerIdx) in campaignSettingsData.offers"
                :key="offerIdx"
              >
                <v-expansion-panel-header>
                  <span class="font-semibold text-gray-800 text-[11px]">{{
                    offer.offer_name
                  }}</span></v-expansion-panel-header
                >

                <v-expansion-panel-content>
                  <div>
                    <div>
                      <v-text-field
                        label="Offer ID"
                        outlined
                        disabled
                        v-model="offer.offer_id"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>
                    <div class="mt-3">
                      <v-text-field
                        label="Offer Name"
                        outlined
                        v-model="offer.offer_name"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>

                    <div class="mt-3">
                      <v-select
                        hide-details
                        class="text-[13px]"
                        :items="[
                          'RUNNING',
                          'STOPPED',
                          'PENDING',
                          'OVER_CAP',
                          'OVER_DAILY_CAP',
                          'INSUFFICIENT_ACCOUNT_BALANCE',
                          'PARTIALLY_OVER_CAP',
                          'CAMPAIGN_STOPPED',
                        ]"
                        label="Status"
                        v-model="offer.status"
                        dense
                        outlined
                      ></v-select>
                    </div>

                    <div class="mt-3">
                      <v-text-field
                        label="Bid Type"
                        outlined
                        disabled
                        v-model="offer.bid_type"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>

                    <div class="mt-3">
                      <v-text-field
                        label="Bid Goal"
                        outlined
                        disabled
                        v-model="offer.bid_goal"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>

                    <div class="mt-3" v-if="offer.target_goal_window">
                      <v-text-field
                        label="Target Goal Window"
                        outlined
                        disabled
                        v-model="offer.target_goal_window"
                        dense
                        hide-details
                        class="text-[13px]"
                      ></v-text-field>
                    </div>

                    <div class="flex items-center justify-end w-full mt-3">
                      <v-btn
                        color="primary"
                        text
                        @click="saveCampaignData(offerIdx)"
                        :loading="loadingCampaignData"
                      >
                        Save
                      </v-btn>
                    </div>

                    <div class="mt-3 border rounded">
                      <div class="p-2 flex items-center justify-between">
                        <div>
                          <h1
                            class="text-[11px] font-semibold m-0 p-0 text-gray-700 pl-2 mb-1.5"
                          >
                            Budget For
                          </h1>
                          <v-radio-group
                            row
                            dense
                            hide-details
                            style="margin-top: 0"
                            v-model="offer.budget_type"
                          >
                            <v-radio
                              label="All Countries"
                              value="ALL"
                              hide-details
                            ></v-radio>
                            <v-radio
                              label="Specific Countries"
                              value="BY_COUNTRY"
                              hide-details
                            ></v-radio>
                          </v-radio-group>
                        </div>

                        <div>
                          <v-btn
                            color="primary"
                            text
                            small
                            :disabled="
                              offer.budget_type === 'ALL' ? true : false
                            "
                            @click="insertBudgetByCountry(offerIdx)"
                          >
                            New
                          </v-btn>
                        </div>
                      </div>

                      <div v-if="offer.budget_type === 'ALL'" class="mt-3">
                        <v-data-table
                          :headers="budgetHeaders"
                          :items="[offer.allBudgetData]"
                          :items-per-page="-1"
                          hide-default-footer
                        >
                          <template v-slot:[`item.country_code`]="{ item }">
                            <v-text-field
                              hide-details
                              class="text-[13px] w-[260px]"
                              outlined
                              dense
                              v-model="item.country_code"
                              disabled
                            >
                            </v-text-field>
                          </template>

                          <template v-slot:[`item.daily_cap`]="{ item }">
                            <v-text-field
                              outlined
                              dense
                              v-model="item.daily_cap"
                              class="text-[12px] w-[260px]"
                              hide-details
                              type="number"
                            ></v-text-field>
                          </template>
                        </v-data-table>
                      </div>

                      <div
                        v-else-if="offer.budget_type === 'BY_COUNTRY'"
                        class="mt-3"
                      >
                        <v-data-table
                          :headers="[
                            ...budgetHeaders,
                            { text: 'Actions', value: 'actions' },
                          ]"
                          :items="offer.countriesBudgetData"
                          :items-per-page="-1"
                          hide-default-footer
                        >
                          <template v-slot:[`item.country_code`]="{ item }">
                            <v-autocomplete
                              hide-details
                              class="text-[13px] w-[260px]"
                              item-text="name"
                              item-value="code"
                              outlined
                              clearable
                              dense
                              :items="computedCountries"
                              v-model="item.country_code"
                              hide-no-data
                            >
                            </v-autocomplete>
                          </template>

                          <template v-slot:[`item.daily_cap`]="{ item }">
                            <v-text-field
                              outlined
                              dense
                              v-model="item.daily_cap"
                              class="text-[12px] w-[260px]"
                              hide-details
                              type="number"
                            ></v-text-field>
                          </template>
                          <!-- eslint-disable-next-line -->
                          <template v-slot:[`item.actions`]="{ item, index }">
                            <div class="flex items-center">
                              <span
                                class="font-semibold text-[10px] text-rose-400 hover:underline cursor-pointer hover:text-rose-600 ml-1.5"
                                @click="
                                  deleteBudgetFromCountry(offerIdx, index)
                                "
                                >Delete</span
                              >
                            </div>
                          </template>
                        </v-data-table>
                      </div>

                      <div class="p-2 flex items-center justify-end">
                        <v-btn
                          color="primary"
                          @click="saveBudget(offerIdx)"
                          :loading="loadingBudgetUpdateBtn"
                        >
                          Save
                        </v-btn>
                      </div>
                    </div>

                    <div
                      class="mt-3 border rounded"
                      v-if="offer.bid_rate_by_location"
                    >
                      <div class="flex items-center justify-between p-2">
                        <h1
                          class="text-[11px] font-semibold m-0 p-0 text-gray-700 pl-2"
                        >
                          Bid Rate By Country
                        </h1>
                        <v-btn
                          color="primary"
                          text
                          small
                          @click="insertBidRateByCountry(offerIdx)"
                        >
                          New
                        </v-btn>
                      </div>

                      <div class="my-3 px-2">
                        <v-text-field
                          placeholder="General Bid Rate"
                          outlined
                          v-model="offer.bid_rate"
                          prefix="$"
                          type="number"
                          dense
                          hide-details
                          class="text-[13px]"
                        ></v-text-field>
                      </div>

                      <v-data-table
                        :headers="offerBidRateByLocation"
                        :items="offer.bid_rate_by_location"
                        :items-per-page="-1"
                        hide-default-footer
                      >
                        <template v-slot:[`item.country_code`]="{ item }">
                          <v-autocomplete
                            hide-details
                            class="text-[13px] w-[260px]"
                            item-text="name"
                            item-value="code"
                            outlined
                            clearable
                            dense
                            :items="computedCountries"
                            v-model="item.country_code"
                            hide-no-data
                          >
                          </v-autocomplete>
                        </template>

                        <template v-slot:[`item.bid_rate`]="{ item }">
                          <v-text-field
                            outlined
                            dense
                            v-model="item.bid_rate"
                            class="text-[12px] w-[260px]"
                            hide-details
                            type="number"
                            prefix="$"
                          ></v-text-field>
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:[`item.actions`]="{ item, index }">
                          <div class="flex items-center">
                            <span
                              class="font-semibold text-[10px] text-rose-400 hover:underline cursor-pointer hover:text-rose-600 ml-1.5"
                              @click="deleteBidRateByCountry(offerIdx, index)"
                              >Delete</span
                            >
                          </div>
                        </template>
                      </v-data-table>
                      <div class="p-2 flex items-center justify-end">
                        <v-btn
                          color="primary"
                          @click="saveBidRateByCountry(offerIdx)"
                          :loading="loadingBidRateByCountryBtn"
                        >
                          Save
                        </v-btn>
                      </div>
                    </div>

                    <div
                      class="mt-3 border rounded"
                      v-if="offer.bid_rate_by_location"
                    >
                      <div class="flex items-center justify-between p-2">
                        <h1
                          class="text-[11px] font-semibold m-0 p-0 text-gray-700 pl-2"
                        >
                          Target Goal
                        </h1>
                        <v-btn
                          color="primary"
                          text
                          small
                          @click="insertTargetGoalByGeo(offerIdx)"
                        >
                          New
                        </v-btn>
                      </div>

                      <div class="my-3 px-2">
                        <v-text-field
                          placeholder="General Target Goal"
                          outlined
                          v-model="offer.target_goal"
                          prefix="%"
                          type="number"
                          dense
                          hide-details
                          class="text-[13px]"
                        ></v-text-field>
                      </div>

                      <v-data-table
                        :headers="offerTargetGoalByGeoHeaders"
                        :items="offer.target_goal_edited.target_goal_modified"
                        :items-per-page="-1"
                        hide-default-footer
                      >
                        <template v-slot:[`item.geo`]="{ item }">
                          <v-autocomplete
                            hide-details
                            class="text-[13px] w-[260px]"
                            item-text="name"
                            item-value="code"
                            outlined
                            clearable
                            dense
                            :items="computedCountries"
                            v-model="item.geo"
                            hide-no-data
                          >
                          </v-autocomplete>
                        </template>

                        <template v-slot:[`item.target_goal`]="{ item }">
                          <v-text-field
                            outlined
                            dense
                            v-model="item.target_goal"
                            class="text-[12px] w-[260px]"
                            hide-details
                            type="number"
                            prefix="%"
                          ></v-text-field>
                        </template>

                        <template v-slot:[`item.cold_start_price`]="{ item }">
                          <v-text-field
                            outlined
                            dense
                            v-model="item.cold_start_price"
                            class="text-[12px] w-[260px]"
                            hide-details
                            type="number"
                            prefix="$"
                          ></v-text-field>
                        </template>
                        <!-- eslint-disable-next-line -->
                        <template v-slot:[`item.actions`]="{ item, index }">
                          <div class="flex items-center">
                            <span
                              class="font-semibold text-[10px] text-rose-400 hover:underline cursor-pointer hover:text-rose-600 ml-1.5"
                              @click="
                                deleteTargetGoalByCountry(offerIdx, index)
                              "
                              >Delete</span
                            >
                          </div>
                        </template>
                      </v-data-table>
                      <div class="p-2 flex items-center justify-end">
                        <v-btn
                          color="primary"
                          @click="saveTargetGoalByCountry(offerIdx)"
                          :loading="targetGoalBtnLoading"
                        >
                          Save
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { COUNTRIES } from "@/helper/utils";
import _ from "lodash";
import moment from "moment";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    game_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },

    data: {
      handler(newValue) {
        if (newValue) {
          this.campaignSettingsData = JSON.parse(JSON.stringify(newValue));

          // Configure Budget to Edit
          for (let i = 0; i < this.campaignSettingsData.offers.length; i++) {
            this.$set(this.campaignSettingsData.offers[i], "budget_type", null);
            this.$set(
              this.campaignSettingsData.offers[i],
              "target_goal_edited",
              null
            );

            this.campaignSettingsData.offers[i].allBudgetData = {
              country_code: "ALL",
              total_budget: "OPEN",
              total_budget_effective_time: moment().format("YYYY-MM-DD"),
              total_budget_effective_timezone: 3,
              daily_cap_type: "BUDGET",
              daily_cap: 0,
            };

            this.$set(
              this.campaignSettingsData.offers[i],
              "countriesBudgetData",
              []
            );

            if (this.campaignSettingsData.offers[i].budget.length) {
              const allData = this.campaignSettingsData.offers[i].budget.find(
                (x) => x.country_code === "ALL"
              );

              if (allData) {
                this.campaignSettingsData.offers[i].budget_type = "ALL";
                this.campaignSettingsData.offers[i].allBudgetData = JSON.parse(
                  JSON.stringify(allData)
                );
              } else {
                this.campaignSettingsData.offers[i].budget_type = "BY_COUNTRY";
                this.campaignSettingsData.offers[i].countriesBudgetData =
                  this.expandBudget(
                    JSON.parse(
                      JSON.stringify(
                        this.campaignSettingsData.offers[i].budget.filter(
                          (x) => x.country_code !== "ALL"
                        )
                      )
                    )
                  );
              }
            }

            this.campaignSettingsData.offers[i].bid_rate_by_location =
              this.expandBudget(
                JSON.parse(
                  JSON.stringify(
                    this.campaignSettingsData.offers[i].bid_rate_by_location
                  )
                )
              );

            this.campaignSettingsData.offers[i]["target_goal_edited"] =
              this.mergeTargetGoalWithColdStart({
                offer_id: this.campaignSettingsData.offers[i].offer_id,
                target_goal: parseFloat(
                  this.campaignSettingsData.offers[i].target_goal
                ),
                target_goal_by_geo: this.campaignSettingsData.offers[
                  i
                ].target_goal_by_geo.map((x) => ({
                  ...x,
                  target_goal: x.target_goal ? parseFloat(x.target_goal) : null,
                })),
                cold_start_price: parseFloat(
                  this.campaignSettingsData.offers[i].cold_start_price
                ),
                cold_start_price_by_geo: this.campaignSettingsData.offers[
                  i
                ].cold_start_price_by_geo.map((x) => ({
                  ...x,
                  cold_start_price: x.cold_start_price
                    ? parseFloat(x.cold_start_price)
                    : null,
                })),
              });
          }
        } else {
          this.campaignSettingsData = null;
        }
      },
      deep: true,
    },

    searchKeyword: _.debounce(function (newValue) {
      this.bids = JSON.parse(JSON.stringify(this.tempBids));

      if (newValue) {
        const regex = new RegExp(newValue, "gmi");
        this.bids = this.bids.filter((x) => regex.test(x.name));
      }
    }, 250),
  },
  data() {
    return {
      budgetHeaders: [
        { text: "Country", value: "country_code" },
        { text: "Daily Cap", value: "daily_cap" },
      ],
      offerBidRateByLocation: [
        { text: "Country", value: "country_code" },
        { text: "Bid Rate", value: "bid_rate" },
        { text: "Actions", value: "actions" },
      ],
      offerTargetGoalByGeoHeaders: [
        { text: "Country", value: "geo" },
        { text: "Target Goal (%)", value: "target_goal" },
        { text: "Cold Start Price", value: "cold_start_price" },
        { text: "Actions", value: "actions" },
      ],
      selectedPanels: [],
      visible: false,
      campaignSettings: null,
      loadingCampaignData: false,
      loadingBudgetUpdateBtn: false,
      campaignSettingsData: null,
      bids: [],
      tempBids: [],
      searchKeyword: "",
      loadingBidRateByCountryBtn: false,
      targetGoalBtnLoading: false,
    };
  },
  methods: {
    mergeTargetGoalWithColdStart(data) {
      const output = {
        target_goal: data.target_goal,
        offer_id: data.offer_id,
        cold_start_price: data.cold_start_price,
        target_goal_modified: data.target_goal_by_geo.map((geoData) => {
          const coldStartPriceData = data.cold_start_price_by_geo.find(
            (priceData) => priceData.geo === geoData.geo
          );
          return {
            ...geoData,
            cold_start_price: coldStartPriceData
              ? coldStartPriceData.cold_start_price
              : null,
          };
        }),
      };

      return output;
    },

    async saveCampaignData(offerIdx) {
      const offer = this.campaignSettingsData.offers[offerIdx];
      try {
        this.loadingCampaignData = true;
        const requestData = {
          offer_id: offer.offer_id,
          offer_name: offer.offer_name,
          promote_timezone: offer.promote_timezone,
          start_time: offer.start_time,
          target_geo: offer.target_geo,
          // status: offer.status,
          // target_goal: parseFloat(offer.target_goal),
          // cold_start_price: parseFloat(offer.cold_start_price),
        };

        const response = await this.$api.post(
          "campaign-manager/mintegral/offer-update",
          requestData
        );

        if (response.status === 200) {
          this.$toast.success(`${offer.offer_name} başarıyla güncellendi.`, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingCampaignData = false;
      }
    },
    hasDuplicatedKeyValue(arr, key) {
      const count = {};

      for (let obj of arr) {
        if (count[obj[key]]) {
          return true;
        } else {
          count[obj[key]] = 1;
        }
      }

      return false;
    },

    expandBudget(budget) {
      let expandedBudget = [];

      budget.forEach((item) => {
        const countries = item.country_code.split(",");
        countries.forEach((country) => {
          let newItem = { ...item, country_code: country };
          expandedBudget.push(newItem);
        });
      });

      return expandedBudget;
    },
    async saveBudget(offerIdx) {
      const offer = this.campaignSettingsData.offers[offerIdx];
      let data = null;

      try {
        // Validate Request Data
        if (offer.budget_type === "ALL") {
          data = [];
          data.push(JSON.parse(JSON.stringify(offer.allBudgetData)));
          data[0].daily_cap = parseFloat(data[0].daily_cap);
        } else if (offer.budget_type === "BY_COUNTRY") {
          data = offer.countriesBudgetData.map((x) => ({
            ...x,
            daily_cap: parseFloat(x.daily_cap),
          }));
        }

        if (!data) return;

        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          if (!element.daily_cap) {
            this.$toast.error(
              `${offer.offer_name} için 'Budget' alanında Daily Cap boş bırakılamaz ya da 0 girilemez.`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );

            return;
          }

          if (!element.country_code) {
            this.$toast.error(
              `${offer.offer_name} için 'Budget' alanında ülke boş bırakılamaz.`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );

            return;
          }
        }

        if (this.hasDuplicatedKeyValue(data, "country_code")) {
          this.$toast.error(
            `${offer.offer_name} 'Budget' alanında aynı iki ülke girilemez.`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          return;
        }

        this.loadingBudgetUpdateBtn = true;
        // End Validate
        const request = await this.$api.post(
          "campaign-manager/mintegral/budget-update",
          {
            offer_id: offer.offer_id,
            budget: data,
          }
        );

        if (request.status === 200) {
          this.$toast.success(
            `${offer.offer_name} için bütçe bilgisi başarıyla güncellendi.`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingBudgetUpdateBtn = false;
      }
    },
    async saveBidRateByCountry(offerIdx) {
      const offer = this.campaignSettingsData.offers[offerIdx];

      try {
        const data = {
          offer_id: offer.offer_id,
          bid_rate: parseFloat(offer.bid_rate),
          bid_rate_by_location: offer.bid_rate_by_location.map((x) => ({
            ...x,
            bid_rate: x.bid_rate ? parseFloat(x.bid_rate) : null,
          })),
        };

        if (!data.bid_rate) {
          this.$toast.error(
            `${offer.offer_name} için Bid güncellerken 'General Bid Rate' alanı boş bırakılamaz ya da 0 girilemez.`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          return;
        }

        for (let i = 0; i < data.bid_rate_by_location.length; i++) {
          const element = data.bid_rate_by_location[i];
          if (!element.country_code) {
            this.$toast.error(
              `${offer.offer_name} için Bid güncellerken ülke boş bırakılamaz.`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );

            return;
          }

          if (!element.bid_rate) {
            this.$toast.error(
              `${offer.offer_name} için lokasyona göre bid güncellerken bid oranı boş bırakılamaz ya da 0 girilemez.`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );

            return;
          }
        }

        if (
          this.hasDuplicatedKeyValue(data.bid_rate_by_location, "country_code")
        ) {
          this.$toast.error(
            `${offer.offer_name} için lokasyona göre bid güncellerken her ülke 1 defa girilebilir.`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          return;
        }

        this.loadingBidRateByCountryBtn = true;
        const response = await this.$api.post(
          "campaign-manager/mintegral/bid-update",
          data
        );
        if (response.status === 200) {
          this.$toast.success(
            `${offer.offer_name} için bid bilgisi başarıyla güncellendi.`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingBidRateByCountryBtn = false;
      }
    },
    deleteBudgetFromCountry(index, rowIndex) {
      this.campaignSettingsData.offers[index].countriesBudgetData.splice(
        rowIndex,
        1
      );
    },
    deleteTargetGoalByCountry(index, rowIndex) {
      this.campaignSettingsData.offers[
        index
      ].target_goal_edited.target_goal_modified.splice(rowIndex, 1);
    },
    async saveTargetGoalByCountry(offerIdx) {
      const offer = this.campaignSettingsData.offers[offerIdx];

      try {
        const data = {
          offer_id: offer.offer_id,
          target_goal: parseFloat(offer.target_goal_edited.target_goal),
          target_goal_by_geo: offer.target_goal_edited.target_goal_modified.map(
            (x) => ({
              geo: x.geo,
              target_goal: x.target_goal ? parseFloat(x.target_goal) : null,
            })
          ),
          cold_start_price: parseFloat(offer.cold_start_price),
          cold_start_price_by_geo:
            offer.target_goal_edited.target_goal_modified.map((x) => ({
              geo: x.geo,
              cold_start_price: x.cold_start_price
                ? parseFloat(x.cold_start_price)
                : null,
            })),
        };

        if (!data.target_goal) {
          this.$toast.error(
            `${offer.offer_name} için Target güncellerken 'General Target Goal' alanı boş bırakılamaz ya da 0 girilemez.`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          return;
        }

        for (let i = 0; i < data.target_goal_by_geo.length; i++) {
          const element = data.target_goal_by_geo[i];
          if (!element.geo) {
            this.$toast.error(
              `${offer.offer_name} için "Target" güncellerken ülke boş bırakılamaz.`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );

            return;
          }

          if (!element.target_goal) {
            this.$toast.error(
              `${offer.offer_name} için lokasyona göre "Target" güncellerken "Target" oranı boş bırakılamaz ya da 0 girilemez.`,
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );

            return;
          }
        }

        if (this.hasDuplicatedKeyValue(data.target_goal_by_geo, "geo")) {
          this.$toast.error(
            `${offer.offer_name} için lokasyona göre bid güncellerken her ülke 1 defa girilebilir.`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );

          return;
        }

        if (!data.target_goal_by_geo.length) {
          delete data.target_goal_by_geo;
        }

        if (!data.cold_start_price_by_geo.length) {
          delete data.cold_start_price_by_geo;
        }

        this.targetGoalBtnLoading = true;
        const response = await this.$api.post(
          "campaign-manager/mintegral/target-update",
          data
        );
        if (response.status === 200) {
          this.$toast.success(
            `${offer.offer_name} için target bilgisi başarıyla güncellendi.`,
            {
              timeout: 5000,
              position: "bottom-center",
              icon: false,
            }
          );
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.targetGoalBtnLoading = false;
      }
    },
    insertBudgetByCountry(offerIdx) {
      const data = {
        country_code: null,
        total_budget: "OPEN",
        total_budget_effective_time: moment().format("YYYY-MM-DD"),
        total_budget_effective_timezone: 0,
        daily_cap_type: "BUDGET",
        daily_cap: 0,
      };

      this.campaignSettingsData.offers[offerIdx].countriesBudgetData.splice(
        0,
        0,
        data
      );
    },
    handleBudgetTypeChanged($event, offerIdx) {
      this.$set(
        this.campaignSettingsData.offers[offerIdx],
        "budget_type",
        $event
      );
    },
    insertBidRateByCountry(offerIdx) {
      const data = {
        country_code: null,
        bid_rate: "",
      };

      this.campaignSettingsData.offers[offerIdx].bid_rate_by_location.splice(
        0,
        0,
        data
      );
    },
    insertTargetGoalByGeo(offerIdx) {
      const data = {
        geo: null,
        target_goal: "",
        cold_start_price: "",
      };

      this.campaignSettingsData.offers[
        offerIdx
      ].target_goal_edited.target_goal_modified.splice(0, 0, data);
    },
    async updateCampaignInformation() {
      const requestData = {
        campaign_id: this.campaignSettingsData.id,
        status: this.campaignSettingsData.status === "Active" ? true : false,
        name: this.campaignSettingsData.name,
      };

      if (!requestData.name) {
        this.$toast.error(`Kampanya ismi zorunludur.`, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      }

      try {
        this.loadingCampaignData = true;
        const request = await this.$api.post(
          `campaign-manager/applovin/information`,
          requestData
        );
        if (request.status === 200) {
          this.$toast.success(`Kampanya başarıyla düzenlendi.`, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loadingCampaignData = false;
      }
    },
    deleteRow(index, rowIndex) {
      this.campaignSettingsData.offers[index].target_goal_by_geo.splice(
        rowIndex,
        1
      );
    },

    deleteBidRateByCountry(index, rowIndex) {
      this.campaignSettingsData.offers[index].bid_rate_by_location.splice(
        rowIndex,
        1
      );
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }

          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });

        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  computed: {
    computedCountries() {
      return COUNTRIES.map((x) => ({ ...x, code: x.code.toUpperCase() }));
    },

    isSaveBtnDisabled() {
      return true;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 11px !important;
  font-weight: 600;
}

::v-deep .v-expansion-panel {
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  border-radius: 3px;
  margin-top: 4px;
}
</style>
