import AllCampaignsView from "@/views/tools/campaign-manager/AllCampaignsView.vue";
import CreatePlayableAssetView from "@/views/tools/campaign-manager/CreatePlayableAssetView.vue";
import UploadGoogleImageView from "@/views/tools/campaign-manager/UploadGoogleImageView.vue";

export const campaign_manager_router = [
  {
    path: "/burcha/all-campaigns",
    name: "campaign-manager-all-campaigns-view",
    component: AllCampaignsView,
    meta: {
      title: "All Campaigns",
    },
  },
  {
    path: "/burcha/create-playable",
    name: "campaign-manager-create-playable-view",
    component: CreatePlayableAssetView,
    meta: {
      title: "Create a playable asset",
    },
  },
  {
    path: "/burcha/upload-google-image",
    name: "campaign-manager-upload-google-image-view",
    component: UploadGoogleImageView,
    meta: {
      title: "Upload Google Image to Library",
    },
  },
];
