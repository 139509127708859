<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card>
        <v-card-title class="text-h5"> Remove Event Confirmation </v-card-title>

        <v-card-text>
          <div class="mt-5 text-[13px]">
            Are you sure you want to remove this event from the calendar?
          </div>
          <div class="mt-3" v-if="hasId">
            <v-checkbox
              dense
              label="With all recurrings"
              v-model="deleteWithRecurrings"
              class="text-[12px]"
              hide-details
            >
            </v-checkbox>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn color="red darken-1" text @click="visible = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitConfirmationDialog">
              Confirm
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      deleteWithRecurrings: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hasId: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    submitConfirmationDialog() {
      this.$emit("submit", this.deleteWithRecurrings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
