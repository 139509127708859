<template>
  <div>
    <h1 class="font-semibold text-[18px]">Departments</h1>
    <div>
      <div class="pt-5">
        <div class="flex items-center justify-between pb-5">
          <h1 class="text-[13px] font-semibold">
            Department: {{ departments.length }}
          </h1>
          <div>
            <v-btn color="primary" small depressed class="font-semibold">
              Add Department
            </v-btn>
          </div>
        </div>
        <v-text-field
          v-if="departments.length > 0"
          placeholder="Search department"
          filled
          rounded
          dense
          @change="handleInputChange"
          append-icon="mdi-magnify"
        ></v-text-field>
        <div>
          <div>
            <v-data-table
              :headers="headers"
              :items="departments"
              :loading="loading"
              :items-per-page="10"
            >
              <template v-slot:[`item.title`]="{ item }">
                <span class="font-semibold">
                  {{ item.title }}
                </span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="edit(item.id)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      departments: [],
      loading: false,
      keyword: "",
      headers: [
        { text: "Department", value: "title" },
        { text: "Number of Employees", value: "count" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    fetchDepartments(title) {
      this.loading = true;
      let url = "admin/departments/all";
      if (title) {
        url += `?title=${title}`;
      }
      this.$api
        .get(url)
        .then((response) => {
          if (response.data && response.data.data) {
            this.departments = response.data.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleInputChange(event) {
      this.fetchDepartments(event);
    },
    edit(id) {
      console.log(id);
    },
  },
  mounted() {
    this.fetchDepartments();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__slot {
  input {
    font-size: 13px;
  }
}

::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
</style>
