<template>
  <div>
    <h1 class="font-semibold text-[18px]">Games</h1>
    <div>
      <div class="pt-5">
        <div class="flex items-center justify-between pb-5">
          <h1 class="text-[13px] font-semibold">
            Total Games: {{ games.length }}
          </h1>
          <div>
            <v-btn
              color="primary"
              @click="connectFacebookGames"
              small
              depressed
              class="font-semibold"
            >
              Connect Facebook Games
            </v-btn>
          </div>
        </div>
        <div class="mb-4" v-if="show_success_message">
          <v-alert
            text
            type="success"
            prominent
            icon="mdi-check"
            border="left"
            dismissible
          >
            <div class="text-[12px] p-2">Games successfully updated.</div>
          </v-alert>
        </div>
        <v-text-field
          placeholder="Search game"
          filled
          rounded
          dense
          @change="handleInputChange"
          append-icon="mdi-magnify"
          v-if="games.length > 0"
        ></v-text-field>
        <div>
          <div>
            <v-data-table
              :headers="headers"
              :items="games"
              :loading="loading"
              :items-per-page="10"
            >
              <template v-slot:[`item.title`]="{ item }">
                <span class="font-semibold">
                  {{ item.title }}
                </span>
              </template>
              <template v-slot:[`item.is_active`]="{ item }">
                <v-checkbox
                  dense
                  :input-value="item.is_active"
                  @change="updateGameStatus($event, item.id)"
                  hide-details
                >
                </v-checkbox>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <div
                  class="text-blue-700 hover:text-blue-500 cursor-pointer"
                  @click="edit(item)"
                >
                  Edit
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
    <GamesConfigUpdateDialog
      @closed="show = false"
      @updated="handleUpdate"
      :show="show"
      :data="selected_game_data"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GamesConfigUpdateDialog from "./GamesConfigUpdateDialog.vue";
export default {
  data() {
    return {
      games: [],
      loading: false,
      keyword: "",
      headers: [
        { text: "Game", value: "title" },
        { text: "Facebook App ID", value: "facebook_app_id" },
        { text: "Status", value: "is_active", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      show_add_games: false,
      selected_games: [],
      default_selected_games: [],
      show_success_message: false,
      selected_game_data: null,
      show: false,
    };
  },
  methods: {
    handleUpdate() {
      this.fetchGames();
    },
    fetchGames(title) {
      this.loading = true;
      let url = "games/all";
      if (title) {
        url += `?title=${title}`;
      }
      this.$api
        .get(url)
        .then((response) => {
          if (response.data) {
            this.games = response.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleInputChange(event) {
      this.fetchGames(event);
    },
    edit(item) {
      this.selected_game_data = item;
      this.show = true;
    },
    connectFacebookGames() {
      if (this.computedGames.length > 0) {
        this.loading = true;
        const request_data = {
          games: this.computedGames.map((x) => {
            return {
              id: x.id,
              title: x.name,
              itunes: x.object_store_urls.itunes
                ? x.object_store_urls.itunes
                : null,
              google_play: x.object_store_urls.google_play
                ? x.object_store_urls.google_play
                : null,
            };
          }),
        };
        this.$api
          .put("admin/games/connect-facebook-games", request_data)
          .then((response) => {
            this.loading = false;
            this.games = response.data;
            this.show_success_message = true;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    updateGameStatus(event, id) {
      this.loading = true;
      const requestData = {
        id: id,
        is_active: event,
      };
      this.$api
        .put(`admin/games/status/update`, requestData)
        .then((response) => {
          this.games = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["getApps"]),
    computedGames() {
      if (this.getApps) {
        let data = this.getApps.filter((x) => {
          if ("object_store_urls" in x) {
            if ("itunes" in x.object_store_urls) {
              return true;
            }
            if ("google_play" in x.object_store_urls) {
              return true;
            }
          }
        });
        return data;
      }
      return [];
    },
  },
  mounted() {
    this.fetchGames();
  },
  components: { GamesConfigUpdateDialog },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__slot {
  input {
    font-size: 13px;
  }
}

::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

::v-deep .v-list-item__title {
  font-size: 12px;
}

::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba($color: #000, $alpha: 0.12);
}
</style>
