export default {
  state: {
    userProfile: null,
  },
  getters: {
    getUserProfile(state) {
      return state.userProfile;
    },
  },
  mutations: {
    handleUserProfile(state, payload) {
      state.userProfile = payload;
    },
  },
  actions: {
    setUserProfile({ commit }, payload) {
      commit("handleUserProfile", payload);
    },
  },
};
