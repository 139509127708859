<template>
  <div v-if="tools && detail">
    <div class="p-6 bg-white">
      <h1 class="font-semibold text-[18px]">User Detail</h1>
      <div class="pt-4">
        <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
      </div>
    </div>
    <div class="p-6">
      <div class="p-4 bg-white">
        <div v-if="loading">
          <v-skeleton-loader
            type="list-item-avatar-three-line, article"
          ></v-skeleton-loader>
        </div>
        <div v-if="loading" class="pt-6">
          <v-skeleton-loader
            type="list-item-two-line, article"
          ></v-skeleton-loader>
        </div>
        <div v-else>
          <div>
            <div>
              <v-text-field
                label="First Name"
                outlined
                v-model="first_name"
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                label="Last Name"
                outlined
                v-model="last_name"
                hide-details
                class="text-[13px]"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-text-field
                hide-details
                label="Email"
                outlined
                class="text-[13px]"
                v-model="email"
                disabled
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-select
                hide-details
                class="text-[13px]"
                :items="departments"
                item-text="title"
                item-value="id"
                label="User Group"
                v-model="department_id"
                outlined
              ></v-select>
            </div>
            <div class="mt-3">
              <v-checkbox
                v-model="is_active"
                :label="`Status: ${is_active ? 'Active' : 'Passive'}`"
              ></v-checkbox>
            </div>
          </div>
          <h1 class="font-semibold text-[14px] py-4">Access Control</h1>
          <div>
            <v-data-table
              :headers="headers"
              :items="tools"
              :items-per-page="10"
            >
              <template v-slot:[`item.title`]="{ item }">
                <span class="font-semibold">
                  {{ item.title }}
                </span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-checkbox v-model="item.active"></v-checkbox>
              </template>
            </v-data-table>
          </div>
          <div class="flex justify-end items-center mt-3">
            <v-btn
              :disabled="loading_btn"
              :loading="loading_btn"
              depressed
              color="primary"
              @click="updateUser"
            >
              Save
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AdminUsersView",
  data() {
    return {
      detail: null,
      headers: [
        { text: "Tool", value: "title" },
        { text: "Has Access", value: "actions", sortable: false },
      ],
      loading: false,
      departments: null,
      first_name: "",
      last_name: "",
      department_id: "",
      email: "",
      is_active: false,
      tools: null,
      loading_btn: false,
      crumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: "/",
          exact: true,
        },
        {
          text: "Users",
          disabled: false,
          to: "/users",
          exact: true,
        },
        {
          text: "Edit User",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    async fetchUserDetail() {
      this.loading = true;
      await this.$api
        .get(`admin/users/${this.$route.params.id}`)
        .then((response) => {
          this.detail = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async fetchDepartments() {
      this.loading = true;
      await this.$api
        .get(`admin/departments/detail/all`)
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    setDefaultData() {
      if (this.detail && this.departments) {
        this.email = this.detail.email;
        this.first_name = this.detail.first_name;
        this.last_name = this.detail.last_name;
        this.department_id = this.detail.department.id;
        this.is_active = this.detail.is_active;
      }
    },
    async fetchTools() {
      await this.$api
        .get("admin/tools/all")
        .then((response) => {
          if (response.data && response.data.length > 0) {
            let temp = [...response.data.map((x) => ({ ...x, active: false }))];
            temp.forEach((element) => {
              this.detail.tools.forEach((tool) => {
                if (element.id === tool.id) {
                  element.active = true;
                }
              });
              this.tools = temp;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateUser() {
      this.loading_btn = true;

      const requestData = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        is_active: this.is_active,
        department_id: this.department_id,
        tool: this.tools,
      };

      await this.$api
        .put("admin/users/update", requestData)
        .then((response) => {
          this.detail = response.data;
          this.fetchTools();
          this.setDefaultData();
          this.loading_btn = false;

          this.$notify({
            text: "User information has successfully updated.",
            group: "success_",
            type: "success_",
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading_btn = false;
        });
    },
  },
  async mounted() {
    await this.fetchUserDetail();
    await this.fetchDepartments();
    await this.fetchTools();
    await this.setDefaultData();
  },
  computed: {
    ...mapGetters(["getTools"]),
  },
};
</script>

<style lang="scss" scoped>
::v-deep label {
  font-size: 13px !important;
}

::v-deep
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #e0e0e0;
}

::v-deep .v-btn__content {
  font-size: 13px !important;
  font-weight: 600;
  text-transform: capitalize;
}

::v-deep .v-list-item__title {
  font-size: 13px;
}

::v-deep .v-application ul,
.v-application ol {
  padding-left: 0;
}

::v-deep .v-breadcrumbs {
  padding: 0;
  li {
    font-size: 12px !important;
    font-weight: 600;
  }
}
</style>
