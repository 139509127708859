<template>
  <div>
    <div class="bg-white p-6">
      <div class="pb-6">
        <h1 class="font-semibold text-[18px]">Adjust IAP Prices</h1>
      </div>
      <div class="mt-5">
        <v-file-input
          show-size
          accept=".csv"
          label="CSV File"
          class="text-[13px]"
          outlined
          dense
          hide-details
          v-model="selectedCsvFile"
        >
        </v-file-input>
      </div>
      <div class="mt-3 flex items-center justify-end">
        <v-btn
          color="primary"
          @click="changePrices"
          depressed
          class="font-semibold"
          :disabled="!selectedCsvFile || loading"
          :loading="loading"
        >
          Submit Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedCsvFile: null,
      loading: false,
    };
  },
  methods: {
    async changePrices() {
      this.loading = true;
      let formData = new FormData();
      formData.append("csvFile", this.selectedCsvFile);
      try {
        const request = await this.$api.post(
          "utils/inappproducts/adjust/price",
          formData
        );
        if (request.status === 200) {
          if (request.data.success.ios.length) {
            this.$toast.success(
              `Following products has successfully updated on iOS: ${request.data.success.ios
                .map((x) => `"${x}"`)
                .join(", ")}`,
              {
                timeout: 0,
                position: "bottom-center",
                icon: false,
              }
            );
          }

          if (request.data.success.android.length) {
            this.$toast.success(
              `Following products has successfully updated on Android: ${request.data.success.android
                .map((x) => `"${x}"`)
                .join(", ")}`,
              {
                timeout: 0,
                position: "bottom-center",
                icon: false,
              }
            );
          }

          if (request.data.fail.ios.length) {
            this.$toast.error(
              `Could not update following products on iOS: ${request.data.fail.ios
                .map((x) => `"${x}"`)
                .join(", ")}`,
              {
                timeout: 0,
                position: "bottom-center",
                icon: false,
              }
            );
          }

          if (request.data.fail.android.length) {
            this.$toast.error(
              `Could not update following products on Android: ${request.data.fail.android
                .map((x) => `"${x}"`)
                .join(", ")}`,
              {
                timeout: 0,
                position: "bottom-center",
                icon: false,
              }
            );
          }
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 0,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 0,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 0,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn__content {
  font-size: 13px !important;
  font-weight: 600;
  text-transform: capitalize;
}

::v-deep .v-list-item__title {
  font-size: 13px;
}

::v-deep .v-input--selection-controls.v-input {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.color-container {
  width: 40px;
  border-radius: 4px;
  height: 40px;
  margin-right: 12px;
}

::v-deep .v-list-item__action {
  margin-right: 0 !important;
}
</style>
