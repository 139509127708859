var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-end mb-4"},[_c('v-checkbox',{staticStyle:{"margin-top":"0"},attrs:{"hide-details":"","label":"Include paused adgroups"},model:{value:(_vm.showPausedAdgroups),callback:function ($$v) {_vm.showPausedAdgroups=$$v},expression:"showPausedAdgroups"}})],1),_c('div',{staticClass:"border rounded"},[_c('v-expansion-panels',{attrs:{"accordion":"","focusable":"","flat":""},model:{value:(_vm.selectedAdsetPanel),callback:function ($$v) {_vm.selectedAdsetPanel=$$v},expression:"selectedAdsetPanel"}},_vm._l((_vm.adgroupData.filter((x) =>
          _vm.showPausedAdgroups ? true : x.status === 'ACTIVE'
        )),function(adgroup,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('div',{staticClass:"flex items-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"12","color":adgroup.status === 'PAUSED' ? 'red' : 'green'}},[_vm._v(" mdi-circle ")]),_c('span',{staticClass:"font-semibold text-[11px] text-blue-900"},[_vm._v(" "+_vm._s(adgroup.campaign.name)+" ")]),_c('span',{staticClass:"mx-2 font-semibold text-[10px]"},[_vm._v(" > ")]),_c('span',{staticClass:"font-semibold text-[11px] text-rose-900"},[_vm._v(" "+_vm._s(adgroup.name)+" ")])],1)]),_c('v-expansion-panel-content',[_c('div',{staticClass:"rounded bg-sky-50 text-sky-800 font-semibold p-5 text-[11px] mt-5 border border-sky-200"},[_vm._v(" "+_vm._s(adgroup.selectedAdsForAdding.filter((x) => x.selected).length)+" creative(s) will be added and "+_vm._s(adgroup.selectedAdsForDeleting.length)+" creative(s) will be deleted. ")]),_c('h1',{staticClass:"mt-4 mb-0 font-semibold text-[13px] capitalize text-gray-900 flex items-center"},[_c('span',[_vm._v(" 1. Select creatives to add ")])]),_c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"w-full mb-5"},[_c('div',{staticClass:"grid grid-cols-4 gap-3"},_vm._l((adgroup.selectedAdsForAdding),function(ad,selectedIdx){return _c('div',{key:ad.ad_id,staticClass:"rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100",class:{
                    selected: ad.selected,
                  },on:{"click":function($event){return _vm.selectAd(index, selectedIdx)}}},[_c('div',{staticClass:"flex items-center justify-between mb-2"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"text-[11px]",class:ad.selected
                            ? 'text-gray-700 font-semibold'
                            : 'text-gray-400'},[_vm._v(" "+_vm._s(ad.ad_name)+" ")]),(ad.improvement)?_c('span',{staticClass:"text-[10px] ml-2",class:{
                          'font-semibold': ad.selected,
                          'text-gray-400': !ad.selected,
                          'text-lime-700': ad.improvement >= 0 && ad.selected,
                          'text-rose-700': ad.improvement < 0 && ad.selected,
                        }},[_vm._v(" "+_vm._s(ad.improvement.toFixed(2))+"% ")]):_vm._e()]),_c('span',{staticClass:"text-[9px] uppercase font-semibold p-1 rounded-[3px] hover:bg-gray-300 cursor-pointer select-none",class:ad.selected
                          ? 'text-gray-700'
                          : 'text-gray-400 pointer-events-none',on:{"click":function($event){return _vm.handlePreviewBtn(
                          $event,
                          ad.gaia_id,
                          ad.selected_playable_id
                        )}}},[_vm._v(" Preview ")])]),_c('div',{staticClass:"mt-2"},[_c('v-checkbox',{staticClass:"text-[10px]",attrs:{"dense":"","hide-details":"","label":"Create as playable","disabled":!ad.selected},on:{"click":function($event){$event.stopPropagation();}},model:{value:(ad.is_playable),callback:function ($$v) {_vm.$set(ad, "is_playable", $$v)},expression:"ad.is_playable"}})],1),_c('div',{staticClass:"mt-2",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-autocomplete',{staticClass:"text-[12px]",attrs:{"clearable":"","label":"Choose an asset","outlined":"","auto-select-first":"","dense":"","items":_vm.facebookAssetData,"hide-details":"","disabled":!ad.is_playable,"item-text":"title","item-value":"id"},model:{value:(ad.selected_playable_id),callback:function ($$v) {_vm.$set(ad, "selected_playable_id", $$v)},expression:"ad.selected_playable_id"}})],1)])}),0)]),_c('v-divider',{staticClass:"my-5"}),_c('h1',{staticClass:"mb-2 font-semibold text-[13px] capitalize text-gray-900 flex items-center"},[_c('span',[_vm._v("2. Select Creatives to Replace ")])]),_c('h1',{staticClass:"text-gray-800 font-semibold text-[11px] mb-3"},[_vm._v(" Average IPM: "+_vm._s(_vm.computedAverageIpm(adgroup))+" ")]),_c('h1',{staticClass:"text-gray-800 font-semibold text-[11px] mb-3"},[_vm._v(" Average Impressions: "+_vm._s(_vm.computedAverageImpression(adgroup))+" ")]),_c('div',[_c('v-data-table',{attrs:{"headers":_vm.facebookAdHeaders,"items":_vm.sortedAdData(adgroup.ads),"items-per-page":-1,"single-select":false,"show-select":"","item-key":"id"},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('span',{staticClass:"text-gray-800 text-[11px] font-semibold"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.ipm`,fn:function({ item }){return [_c('span',{staticClass:"text-[11px] font-semibold",class:item.insights.ipm >= _vm.computedAverageIpm(adgroup)
                        ? 'text-lime-700'
                        : item.insights.ipm
                        ? 'text-rose-600'
                        : '-'},[_vm._v(" "+_vm._s(item.insights && item.insights.ipm ? item.insights.ipm : "-")+" ")])]}},{key:`item.ctr`,fn:function({ item }){return [_c('span',{staticClass:"text-gray-800 text-[11px] font-semibold"},[_vm._v(" "+_vm._s(item.insights && item.insights.ctr ? item.insights.ctr : "-")+" ")])]}},{key:`item.spend`,fn:function({ item }){return [_c('span',{staticClass:"text-gray-800 text-[11px] font-semibold"},[_vm._v(" "+_vm._s(item.insights && item.insights.spend ? item.insights.spend : "-")+" ")])]}},{key:`item.status`,fn:function({ item }){return [_c('span',{staticClass:"text-[11px] font-semibold",class:{
                      'text-red-600': item.status === 'PAUSED',
                      'text-green-600': item.status === 'ACTIVE',
                    }},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:`item.impressions`,fn:function({ item }){return [_c('span',{staticClass:"text-[11px] font-semibold",class:item.insights &&
                      item.insights.impressions >=
                        _vm.computedAverageImpression(adgroup)
                        ? 'text-lime-700'
                        : item.insights.impressions
                        ? 'text-rose-600'
                        : '-'},[_vm._v(" "+_vm._s(item.insights && item.insights.impressions ? item.insights.impressions : "-")+" ")])]}}],null,true),model:{value:(adgroup.selectedAdsForDeleting),callback:function ($$v) {_vm.$set(adgroup, "selectedAdsForDeleting", $$v)},expression:"adgroup.selectedAdsForDeleting"}})],1)],1)])],1)}),1)],1),_c('div',{staticClass:"flex justify-end items-center my-3"},[_c('v-btn',{staticClass:"font-semibold",attrs:{"color":"primary","depressed":"","loading":_vm.loadingBtn,"disabled":_vm.loadingBtn},on:{"click":function($event){_vm.showSummary = true}}},[_vm._v(" Replace Creatives ")])],1),_c('PreviewDialog',{attrs:{"gaia_id":_vm.preview_selected_gaia_id,"playable_id":_vm.preview_selected_playable_id,"show":_vm.showPreview},on:{"closed":_vm.handlePreviewDialogClose}}),_c('SummaryDialog',{attrs:{"adgroupData":_vm.showPausedAdgroups
        ? _vm.adgroupData
        : _vm.adgroupData.filter((x) => x.status === 'ACTIVE'),"selectedCreatives":_vm.selectedCreatives,"show":_vm.showSummary},on:{"closed":_vm.handleSummaryDialogClose,"submitted":_vm.replaceCreatives}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }