<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card :loading="loading">
        <v-card-title class="text-h5"> Create New Network </v-card-title>

        <v-card-text>
          <h1 class="font-semibold text-[11px] mt-5">1. Network Label</h1>
          <div class="mt-2">
            <v-text-field
              outlined
              v-model="network_label"
              dense
              hide-details
              class="text-[13px]"
              placeholder="Label"
            ></v-text-field>
          </div>

          <h1 class="font-semibold text-[11px] mt-3">2. Network Value</h1>
          <div class="mt-2">
            <v-text-field
              outlined
              v-model="network_value"
              dense
              hide-details
              class="text-[13px]"
              placeholder="Value"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex items-center justify-between">
            <v-btn
              color="red darken-1"
              :disabled="loading"
              text
              @click="visible = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              :loading="loading"
              text
              :disabled="isSubmitBtnDisabled"
              @click="createNewNetwork"
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      network_label: "",
      network_value: "",
      loading: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  computed: {
    isSubmitBtnDisabled() {
      if (!this.network_label) return true;
      if (!this.network_value) return true;
      return false;
    },
  },
  methods: {
    async createNewNetwork() {
      try {
        this.loading = true;
        const requestData = {
          value: this.network_value,
          label: this.network_label,
        };

        const response = await this.$api.post(
          "umai-networks/create",
          requestData
        );
        if (response.status === 200) {
          this.network_label = "";
          this.network_value = "";
          this.$emit("inserted");
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
