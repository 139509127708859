import AdminUsersView from "../../views/admin/UsersView.vue";
import AdminEditUserView from "../../views/admin/EditUserView.vue";
import AdminConfigView from "../../views/admin/ConfigView.vue";

export const admin_router = [
  {
    path: "/users",
    name: "users",
    component: AdminUsersView,
    meta: {
      title: "Admin - Users",
    },
  },
  {
    path: "/users/detail/:id",
    name: "edit-user",
    component: AdminEditUserView,
    meta: {
      title: "Admin - Edit User",
    },
  },
  {
    path: "/config",
    name: "config",
    component: AdminConfigView,
    meta: {
      title: "Admin - Config",
    },
  },
];
