<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card>
        <v-card-title> Validate Your Process </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <div class="font-semibold text-[11px]">
              There will be
              <span class="text-rose-600">{{ number_of_combinations }}</span>
              creative(s) generated unless any problem occurs.
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="handleSubmit"
            :disabled="!number_of_combinations"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    number_of_combinations: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    show(newValue) {
      this.visible = newValue;
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
      }
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    handleSubmit() {
      this.visible = false;
      this.$emit("submitted");
    },
  },
};
</script>

<style lang="scss" scoped>
.color-container {
  width: 40px;
  border-radius: 4px;
  height: 40px;
  margin-right: 12px;
}

::v-deep .v-dialog {
  box-shadow: none !important;
}
</style>
