<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between">
        <h1 class="font-semibold text-[18px]">Live Events</h1>

        <div>
          <v-btn
            :ripple="false"
            color="secondary"
            depressed
            @click="clickCreateEventBtn"
            :disabled="
              !selected_game || !selected_game.applovin_android_package_name
            "
            ><v-icon left dark> mdi-plus </v-icon> Create Live Event
          </v-btn>
        </div>
      </div>

      <div class="mt-5 flex items-center w-full">
        <div class="w-[270px]">
          <v-autocomplete
            clearable
            flat
            label="Choose Game"
            v-model="selected_game"
            dense
            outlined
            class="text-[12px]"
            hide-details
            :items="games"
            item-text="title"
            return-object
          >
          </v-autocomplete>
        </div>

        <div class="pl-4 flex items-center">
          <v-checkbox
            style="margin: 0"
            dense
            label="Include Archive"
            v-model="include_archive"
            hide-details
          >
          </v-checkbox>
        </div>
      </div>

      <div class="mt-3">
        <v-data-table
          :headers="eventTableHeaders"
          :items="include_archive ? events : events.filter((x) => !x.removed)"
          :loading="tableLoading"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.id`]="{ item }">
            <span
              class="font-semibold text-[11px]"
              :class="{
                'text-gray-400': item.removed,
              }"
            >
              {{ item.id }}
            </span>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <span
              class="font-semibold text-[11px]"
              :class="{
                'text-gray-400': item.removed,
              }"
            >
              {{ item.name }}
            </span>
          </template>

          <template v-slot:[`item.theme_name`]="{ item }">
            <span
              class="font-semibold text-[11px]"
              :class="{
                'text-gray-400': item.removed,
              }"
            >
              {{ item.theme_name }}
            </span>
          </template>

          <template v-slot:[`item.alias`]="{ item }">
            <span
              class="font-semibold text-[11px]"
              :class="{
                'text-gray-400': item.removed,
              }"
            >
              {{ item.alias }}
            </span>
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <span
              class="font-semibold text-[11px]"
              :class="{
                'text-gray-400': item.removed,
              }"
            >
              {{ item.description }}
            </span>
          </template>

          <template v-slot:[`item.type_name`]="{ item }">
            <div class="inline-block">
              <div
                v-if="!item.removed"
                class="font-semibold text-[11px] px-1.5 py-1 flex items-center"
                :class="computedLiveOpsType[item.type.slug].color"
              >
                <v-icon style="color: inherit" class="mr-1" small
                  >mdi-{{ computedLiveOpsType[item.type.slug].icon }}</v-icon
                >
                {{ item.type_name }}
              </div>

              <div
                v-else
                class="font-semibold text-[11px] px-1.5 py-1 flex items-center text-gray-400"
              >
                <v-icon style="color: inherit" class="mr-1" small
                  >mdi-{{ computedLiveOpsType[item.type.slug].icon }}</v-icon
                >
                {{ item.type_name }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="flex items-center">
              <v-btn
                color="primary"
                text
                @click="
                  showEventUpdateWarningDialog(
                    'warningFirst',
                    item,
                    'Editing an event can have serious consequences. If you are sure that the event you want to edit is one that has not yet been published into production, you can continue. Otherwise, it is recommended to only change the event name or description. If fields such as type, theme, payload are to be changed, it is safer to create a new event and use it.'
                  )
                "
                small
                class="text-[11px] font-semibold"
              >
                Edit
              </v-btn>
              <v-btn
                v-if="!item.removed"
                color="warning"
                text
                @click="deleteEventBtn(item)"
                small
                class="text-[11px] font-semibold"
              >
                Archive
              </v-btn>

              <v-btn
                v-else-if="item.removed"
                color="success"
                text
                @click="unarchiveEvent(item)"
                small
                class="text-[11px] font-semibold"
              >
                Unarchive
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>

    <UpdateGameEventDialog
      :show="showInsertUpdateDialog"
      @closed="handleOnUpdateDialogOnClose"
      :data="showInsertUpdateDialogData"
      :mode="showInsertUpdateDialogMode"
      :games="games"
      @update="
        showEventUpdateWarningDialog(
          $event,
          item,
          'In order for the edit to affect the calendars, a new deployment must be sent to the relevant environments. Otherwise, it will not be possible to serve the edited event because the calendar is kept statically.'
        )
      "
      @insert="handleOnEventInsert"
      :eventTypes="eventTypes"
    ></UpdateGameEventDialog>

    <DeleteEventDialog
      :show="showDeleteEventConfirmation"
      :eventData="deleteEventConfirmationData"
      @closed="closeDeleteEventDialog"
      @submit="handleOnEventDelete"
    />

    <ConfirmationDialogEventUpdate
      :show="showEventUpdateWarning"
      @closed="showEventUpdateWarning = false"
      :message="confirmationDialogMessage"
      @submit="handleOnConfirmationSubmit"
      :trigger="confirmationDialogTrigger"
      :title="'Warning!'"
    />
  </div>
</template>

<script>
import UpdateGameEventDialog from "@/components/tools/game_backend/UpdateGameEventDialog.vue";
import DeleteEventDialog from "@/components/tools/game_backend/DeleteEventDialog.vue";
import ConfirmationDialogEventUpdate from "@/components/tools/game_backend/ConfirmationDialog.vue";
import { LIVE_OPS_TYPE_COLORS } from "@/helper/utils";

export default {
  data() {
    return {
      include_archive: false,
      tableLoading: false,
      confirmationDialogTrigger: null,
      confirmationDialogMessage: "",
      updateEventData: null,
      showEventUpdateWarning: false,
      games: [],
      events: [],
      selected_game: null,
      loading: false,
      eventTableHeaders: [
        {
          text: "ID",
          value: "id",
          sortable: true,
        },
        {
          text: "Type",
          value: "type_name",
          sortable: true,
        },
        {
          text: "Theme",
          value: "theme_name",
          sortable: true,
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
        },
        {
          text: "Alias",
          value: "alias",
          sortable: true,
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      showInsertUpdateDialogData: null,
      showInsertUpdateDialogMode: null,
      showInsertUpdateDialog: false,
      eventTypes: [],
      showDeleteEventConfirmation: false,
      deleteEventConfirmationData: null,
    };
  },
  methods: {
    async unarchiveEventSubmit(event) {
      try {
        this.tableLoading = true;
        const response = await this.$api.delete(
          `game-backend/events/unarchive?id=${event.id}`
        );
        if (response.status === 200) {
          this.$toast.success("Event successfully updated.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          await this.fetchLiveEvents();
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.tableLoading = false;
        this.resetConfirmationDialog();
      }
    },
    async handleOnConfirmationSubmit() {
      if (this.confirmationDialogTrigger === "warningFirst") {
        this.clickUpdateEventBtn(this.updateEventData);
      } else if (this.confirmationDialogTrigger === "unarchive-event") {
        if (this.updateEventData) {
          await this.unarchiveEventSubmit(this.updateEventData);
        }
      } else {
        if (this.confirmationDialogTrigger) {
          this.handleOnEventUpdate(this.confirmationDialogTrigger);
          this.updateEventData = null;
          this.confirmationDialogMessage = null;
          this.confirmationDialogTrigger = null;
          this.showEventUpdateWarning = false;
          this.showInsertUpdateDialog = false;
        }
      }
    },

    showEventUpdateWarningDialog(trigger, item, message) {
      this.updateEventData = item;
      this.confirmationDialogMessage = message;
      this.confirmationDialogTrigger = trigger;
      this.showEventUpdateWarning = true;
    },

    closeDeleteEventDialog() {
      this.deleteEventConfirmationData = null;
      this.showDeleteEventConfirmation = false;
    },

    deleteEventBtn(item) {
      this.deleteEventConfirmationData = item;
      this.showDeleteEventConfirmation = true;
    },

    unarchiveEvent(item) {
      this.confirmationDialogMessage = `Selected event will be unarchived. Are you sure?`;
      this.confirmationDialogTrigger = "unarchive-event";
      this.updateEventData = item;
      this.showEventUpdateWarning = true;
    },

    resetConfirmationDialog() {
      this.showEventUpdateWarning = false;
      this.confirmationDialogMessage = null;
      this.confirmationDialogTrigger = null;
      this.updateEventData = null;
    },

    handleOnUpdateDialogOnClose() {
      this.showInsertUpdateDialog = false;
      this.showInsertUpdateDialogData = null;
    },

    async handleOnEventUpdate(data) {
      const requestData = { ...data, env: "dev" };
      try {
        this.tableLoading = true;
        const response = await this.$api.put(
          "game-backend/events/update",
          requestData
        );

        if (response.status === 200) {
          this.$toast.success("Event successfully updated.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
          await this.fetchLiveEvents();
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.tableLoading = false;
      }
    },
    async handleOnEventInsert(data) {
      const requestData = { ...data, env: "dev" };
      try {
        this.tableLoading = true;
        const response = await this.$api.post(
          "game-backend/events/create",
          requestData
        );
        if (response.status === 200) {
          this.$toast.success("Event successfully updated.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
          await this.fetchLiveEvents();
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.tableLoading = false;
      }
    },
    async handleOnEventDelete(id) {
      this.closeDeleteEventDialog();
      try {
        this.tableLoading = true;
        const response = await this.$api.delete(
          `game-backend/events/delete?id=${id}`
        );
        if (response.status === 200) {
          this.$toast.success("Event successfully updated.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
          await this.fetchLiveEvents();
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.tableLoading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    clickCreateEventBtn() {
      this.showInsertUpdateDialogData = {
        name: "",
        description: "",
        payload: {},
        package_name: this.selected_game.applovin_android_package_name,
        type: { id: null },
        theme: { id: null },
        min_version: 0,
      };
      this.showInsertUpdateDialogMode = "insert";
      this.showInsertUpdateDialog = true;
    },
    clickUpdateEventBtn(item) {
      this.showEventUpdateWarning = false;
      this.showInsertUpdateDialogData = item;
      this.showInsertUpdateDialogMode = "update";
      this.showInsertUpdateDialog = true;
    },

    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data.filter(
            (x) => x.applovin_android_package_name
          );
        })
        .catch((err) => {
          this.errorHandler(err);
        });
    },

    async fetchLiveEvents() {
      try {
        if (!this.selected_game) return;
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/events/all?package_name=${this.selected_game.applovin_android_package_name}`
        );
        this.events = request.data.map((x) => ({
          ...x,
          type_name: x.type.name,
          theme_name: x.theme.title,
        }));
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchLiveEventTypes() {
      try {
        this.loading = true;

        const request = await this.$api.get(
          `game-backend/live-event-types/all`
        );
        this.eventTypes = request.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    UpdateGameEventDialog,
    DeleteEventDialog,
    ConfirmationDialogEventUpdate,
  },
  mounted() {
    this.fetchGames();
    this.fetchLiveEventTypes();
  },
  watch: {
    selected_game: {
      handler() {
        this.fetchLiveEvents();
      },
      deep: true,
    },
  },
  computed: {
    computedLiveOpsType() {
      return LIVE_OPS_TYPE_COLORS;
    },
  },
};
</script>

<style lang="scss" scoped></style>
