var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.unityReportsLoading)?_c('div',{staticClass:"border rounded"},[(_vm.unityReportsData)?_c('v-expansion-panels',{attrs:{"accordion":"","focusable":"","flat":""},model:{value:(_vm.selectedAdsetPanel),callback:function ($$v) {_vm.selectedAdsetPanel=$$v},expression:"selectedAdsetPanel"}},_vm._l((_vm.modifiedData),function(data,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"font-semibold text-[11px] text-blue-900"},[_vm._v(" "+_vm._s(data.campaign_name)+" ")])])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"rounded bg-sky-50 text-sky-800 font-semibold p-5 text-[11px] mt-5 border border-sky-200"},[_vm._v(" "+_vm._s(data.selectedCreativesForAdding.filter((x) => x.selected).length)+" creative(s) will be added and "+_vm._s(data.selectedCreativesForDeleting.length)+" creative(s) will be deleted. ")]),_c('h1',{staticClass:"mt-4 mb-0 font-semibold text-[13px] capitalize text-gray-900 flex items-center"},[_c('span',[_vm._v(" 1. Select creatives to add ")])]),_c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"w-full mb-5"},[_c('div',{staticClass:"grid grid-cols-4 gap-3"},_vm._l((data.selectedCreativesForAdding),function(creative){return _c('div',{key:creative.ad_id,staticClass:"rounded-[3px] p-3 selectedAdSection cursor-pointer hover:bg-gray-100",class:{
                    selected: creative.selected,
                  },on:{"click":function($event){creative.selected = !creative.selected}}},[_c('div',{staticClass:"flex items-center justify-between mb-2"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"text-[11px]",class:creative.selected
                            ? 'text-gray-700 font-semibold'
                            : 'text-gray-400'},[_vm._v(" "+_vm._s(creative.ad_name)+" ")]),(creative.improvement)?_c('span',{staticClass:"text-[10px] ml-2",class:{
                          'font-semibold': creative.selected,
                          'text-gray-400': !creative.selected,
                          'text-lime-700':
                            creative.improvement >= 0 && creative.selected,
                          'text-rose-700':
                            creative.improvement < 0 && creative.selected,
                        }},[_vm._v(" "+_vm._s(creative.improvement.toFixed(2))+"% ")]):_vm._e()]),_c('span',{staticClass:"text-[9px] uppercase font-semibold p-1 rounded-[3px] hover:bg-gray-300 cursor-pointer select-none",class:creative.selected
                          ? 'text-gray-700'
                          : 'text-gray-400 pointer-events-none',on:{"click":function($event){return _vm.handlePreviewBtn(
                          $event,
                          creative.gaia_id,
                          creative.selected_playable_id
                        )}}},[_vm._v(" Preview ")])]),_c('div',{staticClass:"mt-2"},[_c('v-checkbox',{staticClass:"text-[10px]",attrs:{"dense":"","hide-details":"","label":"Add playable asset","disabled":!creative.selected},on:{"click":function($event){$event.stopPropagation();}},model:{value:(creative.is_playable),callback:function ($$v) {_vm.$set(creative, "is_playable", $$v)},expression:"creative.is_playable"}})],1),_c('div',{staticClass:"mt-2",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-autocomplete',{staticClass:"text-[12px]",attrs:{"clearable":"","label":"Choose an asset","outlined":"","auto-select-first":"","dense":"","items":_vm.unityAssetData,"hide-details":"","disabled":!creative.is_playable,"item-text":"title","item-value":"id"},model:{value:(creative.selected_playable_id),callback:function ($$v) {_vm.$set(creative, "selected_playable_id", $$v)},expression:"creative.selected_playable_id"}})],1)])}),0)]),_c('v-divider',{staticClass:"my-5"}),_c('h1',{staticClass:"mb-2 font-semibold text-[13px] capitalize text-gray-900 flex items-center"},[_c('span',[_vm._v("2. Select Creatives to Replace ")])]),_c('h1',{staticClass:"text-gray-800 font-semibold text-[11px] mb-3"},[_vm._v(" Average CVR: "+_vm._s(_vm.computedAverageCvr(data))+" ")]),_c('div',[_c('v-data-table',{attrs:{"headers":_vm.unityCreativePackHeaders,"items":_vm.sortedReportData(data),"items-per-page":-1,"single-select":false,"show-select":"","item-key":"creative_pack_id"},scopedSlots:_vm._u([{key:`item.creative_pack_name`,fn:function({ item }){return [_c('span',{staticClass:"text-gray-800 text-[11px] font-semibold"},[_vm._v(" "+_vm._s(item.creative_pack_name)+" ")])]}},{key:`item.cvr`,fn:function({ item }){return [_c('span',{staticClass:"text-[11px] font-semibold",class:Math.round(item.cvr * 10000) / 10000 >=
                      _vm.computedAverageCvr(data)
                        ? 'text-lime-700'
                        : Math.round(item.cvr * 10000) / 10000
                        ? 'text-rose-600'
                        : '-'},[_vm._v(" "+_vm._s(Math.round(item.cvr * 10000) / 10000)+" ")])]}},{key:`item.views`,fn:function({ item }){return [_c('span',{staticClass:"text-gray-800 text-[11px] font-semibold"},[_vm._v(" "+_vm._s(item.views.toLocaleString("de-DE"))+" ")])]}},{key:`item.clicks`,fn:function({ item }){return [_c('span',{staticClass:"text-gray-800 text-[11px] font-semibold"},[_vm._v(" "+_vm._s(item.clicks.toLocaleString("de-DE"))+" ")])]}},{key:`item.installs`,fn:function({ item }){return [_c('span',{staticClass:"text-gray-800 text-[11px] font-semibold"},[_vm._v(" "+_vm._s(item.installs.toLocaleString("de-DE"))+" ")])]}},{key:`item.spend`,fn:function({ item }){return [_c('span',{staticClass:"text-gray-800 text-[11px] font-semibold"},[_vm._v(" $ "+_vm._s(item.spend.toFixed(2))+" ")])]}}],null,true),model:{value:(data.selectedCreativesForDeleting),callback:function ($$v) {_vm.$set(data, "selectedCreativesForDeleting", $$v)},expression:"data.selectedCreativesForDeleting"}})],1)],1)])],1)}),1):_vm._e()],1):(_vm.unityReportsLoading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1):_vm._e(),_c('div',{staticClass:"flex justify-end items-center my-3"},[_c('v-btn',{staticClass:"font-semibold",attrs:{"color":"primary","depressed":"","loading":_vm.loadingBtn,"disabled":_vm.loadingBtn || _vm.unityReportsLoading},on:{"click":function($event){_vm.showSummary = true}}},[_vm._v(" Replace Creatives ")])],1),_c('PreviewDialog',{attrs:{"gaia_id":_vm.preview_selected_gaia_id,"playable_id":_vm.preview_selected_playable_id,"show":_vm.showPreview},on:{"closed":_vm.handlePreviewDialogClose}}),_c('SummaryDialog',{attrs:{"data":_vm.modifiedData,"selectedCreatives":_vm.selectedCreatives,"show":_vm.showSummary},on:{"closed":_vm.handleSummaryDialogClose,"submitted":_vm.replaceCreatives}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }