var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Crop & Edit Image ")]),_c('v-card-text',[(_vm.data)?_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"max-w-[550px] w-[550px] flex flex-col items-center justify-center"},[(_vm.imageData && !_vm.imageDataLoading)?_c('div',[_c('cropper',{style:({
                    width: `${
                      _vm.adjustNumbers(
                        _vm.imageData.baseImageWidth,
                        _vm.imageData.baseImageHeight
                      ).x
                    }px`,
                    height: `${
                      _vm.adjustNumbers(
                        _vm.imageData.baseImageWidth,
                        _vm.imageData.baseImageHeight
                      ).y
                    }px`,
                  }),attrs:{"src":_vm.imageData.baseImageData,"stencil-props":{
                    aspectRatio: _vm.imageData.variants[_vm.selectedVariant].ratio,
                  },"resizeImage":{ wheel: false },"defaultPosition":{
                    top: _vm.imageData.variants[_vm.selectedVariant].coords.top,
                    left: _vm.imageData.variants[_vm.selectedVariant].coords.left,
                  },"defaultSize":_vm.calculatedDefaultSize(
                      _vm.imageData.baseImageWidth,
                      _vm.imageData.baseImageHeight,
                      _vm.imageData.variants[_vm.selectedVariant].ratio
                    )},on:{"change":_vm.updateCoords}})],1):(_vm.imageDataLoading)?_c('div',{staticClass:"w-[550px]",style:({
                  height: `${
                    _vm.adjustNumbers(
                      _vm.tempImageData.baseImageWidth,
                      _vm.tempImageData.baseImageHeight
                    ).y
                  }px`,
                })},[_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1),_c('div',{staticClass:"mt-1"},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}})],1),_c('div',{staticClass:"mt-1"},[_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1)]):_vm._e(),(_vm.tempImageData)?_c('div',{staticClass:"mt-3 flex items-center justify-between rounded-[3px] w-full"},_vm._l((_vm.tempImageData.variants),function(variant,variantIdx){return _c('div',{key:variantIdx,staticClass:"p-4 hover:bg-gray-50 w-full cursor-pointer flex items-center justify-center flex-col",class:{
                    'bg-lime-100':
                      variant.isIncluded && variantIdx !== _vm.selectedVariant,
                    'bg-gray-200': variantIdx === _vm.selectedVariant,
                  },on:{"click":function($event){return _vm.updateImageData(variantIdx)}}},[_c('img',{staticClass:"mb-1.5 rounded-[3px]",attrs:{"src":variant.image,"height":40,"width":40 * variant.ratio}}),_c('span',{staticClass:"font-semibold text-[11px]"},[_vm._v(" "+_vm._s(variant.title)+" ")]),_c('div',{staticClass:"mt-0.5",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":"Include"},model:{value:(variant.isIncluded),callback:function ($$v) {_vm.$set(variant, "isIncluded", $$v)},expression:"variant.isIncluded"}})],1)])}),0):_vm._e()]),_c('div',{staticClass:"pl-4"},[_c('h1',{staticClass:"text-[13px] font-semibold"},[_vm._v("Filename")]),_c('div',{staticClass:"text-[12px] text-gray-600"},[_vm._v(" "+_vm._s(_vm.data.baseImage)+" ")]),_c('h1',{staticClass:"mt-2.5 text-[13px] font-semibold"},[_vm._v("Dimensions")]),_c('div',{staticClass:"text-[12px] text-gray-600"},[_vm._v(" "+_vm._s(_vm.data.baseImageWidth)+"x"+_vm._s(_vm.data.baseImageHeight)+" ")])])])]):_vm._e()]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveCrop}},[_vm._v(" Save ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }