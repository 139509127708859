<template>
  <div>
    <v-dialog v-model="visible" width="600">
      <v-card v-if="rows" :loading="loading">
        <v-card-title class="text-h5"> Summary </v-card-title>

        <v-card-text v-if="network && !result">
          <div>
            <div class="flex items-center text-[12px]">
              <span class="font-semibold text-gray-800">Selected Network:</span>
              <span class="text-capitalize ml-2">{{ network.type }}</span>
            </div>
            <div class="flex items-center text-[12px] mt-2">
              <span class="font-semibold text-gray-800">Adset ID:</span>
              <span class="ml-2">{{ network.adset_id }}</span>
            </div>
            <div class="flex items-center text-[12px] mt-2">
              <span class="font-semibold text-gray-800"
                >Comparison Adset ID:</span
              >
              <span class="ml-2">{{ network.comparison_adset_id }}</span>
            </div>
            <div
              class="flex items-center text-[12px] mt-2"
              v-if="network.ad_text"
            >
              <span class="font-semibold text-gray-800">AD Text:</span>
              <span class="ml-2">{{ network.ad_text }}</span>
            </div>
            <div
              class="flex items-center text-[12px] mt-2"
              v-if="['Facebook', 'Tiktok'].includes(network.type)"
            >
              <span class="font-semibold text-gray-800">CTA Text:</span>
              <span class="ml-2">{{ network.cta_text }}</span>
            </div>
            <div class="flex items-center text-[12px] mt-2">
              <span class="font-semibold text-gray-800">Selected Game:</span>
              <span class="ml-2">{{ network.game_title }}</span>
            </div>
            <div class="flex items-center text-[12px] mt-2">
              <span class="font-semibold text-gray-800">ACT ID:</span>
              <span
                class="ml-2"
                v-if="['Facebook', 'Tiktok'].includes(network.type)"
                >{{ network.act_id }}</span
              >
              <span class="ml-2" v-if="['unity'].includes(network.type)">{{
                network.extra_data.campaign_set_id
              }}</span>
            </div>
            <div class="text-[12px] mt-2" v-if="!from_manage_assets">
              <span class="font-semibold text-gray-800"
                >Selected Creatives:</span
              >
              <div
                v-for="item in rows"
                :key="item.id"
                class="mt-2 text-capitalize"
              >
                {{ item.general_creative.id }} -
                {{ item.general_creative.title }}
              </div>
            </div>
            <div class="text-[12px] mt-2" v-if="from_manage_assets">
              <span class="font-semibold text-gray-800"
                >Selected Creatives:</span
              >
              <div
                v-for="item in rows"
                :key="item.id"
                class="mt-2 text-capitalize"
              >
                {{ item.id }} - {{ item.title }}
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions v-if="!result">
          <div class="flex items-center justify-between">
            <v-btn
              color="red darken-1"
              :disabled="loading"
              text
              @click="visible = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              :loading="loading"
              text
              @click="createTest"
              :disabled="isSubmitBtnDisabled"
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
        <v-card-actions v-if="result">
          <div class="flex items-center justify-end">
            <v-btn
              color="blue darken-1"
              :loading="loading"
              text
              @click="visible = false"
            >
              Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      result: null,
      test_type: "first",
      configLoading: false,
      networks: [],
      selected_network: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    network: {
      type: Object,
      default: null,
    },
    from_manage_assets: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        this.visible = newValue;
        if (newValue) {
          this.fetchConfigurations();
        }
      },
      inmediate: true,
    },
    visible(newValue) {
      if (!newValue) {
        this.$emit("closed");
        this.result = null;
        this.test_type = "first";
      }
    },
  },
  computed: {
    isSubmitBtnDisabled() {
      if (!this.network) return true;
      // if (
      //   this.network.type === "unity" &&
      //   this.rows &&
      //   this.rows.length &&
      //   this.network.extra_data
      // ) {
      //   const rowsLength = this.rows.filter(
      //     (x) => x.unity_status_id === 2
      //   ).length;
      //   if (
      //     rowsLength !==
      //     parseFloat(this.network.extra_data.number_of_max_creative_numbers)
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      return false;
    },
  },
  methods: {
    async createTest() {
      this.loading = true;
      const requestData = {
        selected_creatives: this.from_manage_assets
          ? this.rows.map((x) => x.id)
          : this.rows.map((x) => x.general_creative.id),
        selected_game_id: this.network.game_id
          ? this.network.game_id
          : this.network.game
          ? this.network.game.id
          : null,
        selected_network_id: this.network.id,
      };

      try {
        const request = await this.$api.post(
          `act/assets/create-test?channel=${this.network.type.toLowerCase()}`,
          requestData
        );

        if (request.status === 200) {
          this.$toast.success("You have successfully created test.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });

          this.visible = false;
          this.$emit("testCreated");
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchConfigurations() {
      if (this.network) {
        try {
          this.configLoading = true;
          const response = await this.$api.get(
            `act/network/all?type=Facebook&selected_game=${this.network.game_id}`
          );
          this.networks = response.data;
        } catch (error) {
          this.errorHandler(error);
        } finally {
          this.configLoading = false;
        }
      }
    },

    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
