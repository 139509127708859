export default {
  state: {
    apps: null,
  },
  getters: {
    getApps(state) {
      return state.apps;
    },
  },
  mutations: {
    handleSetApps(state, payload) {
      state.apps = payload;
    },
  },
  actions: {
    setApps({ commit }, payload) {
      commit("handleSetApps", payload);
    },
  },
};
